import React from "react";
import BASE_URL from "../../_constant/index";
const Onmobile = () => {
  return (
    <>
      <section className="vynksafe_on_mobile_v pt-5 pb-0 dark-gray-bg-v">
        <div className="container">
          <div className="row row-cols-1 row-cols-lg-2 row-cols-md-1 row-cols-sm-1 pt-lg-5 align-items-center gap-v-4">
            <div className="col order-lg-2-v">
              <img
                src={BASE_URL.BASE_URL + "img/images/vynksafe_mobile.svg"}
                className="img-fluid"
                alt="banner"
                width="560"
              />
            </div>
            <div className="col pl-lg-5 mb-4 mb-lg-0">
              <div className="banner_main_heading_v">
                <h1 className="mb-4">
                  Finance on <span className="text-orange fw-1"> Fingertips </span>
                </h1>
              </div>
              <p className="my-4">
                Managing your funds on our mobile app is a terrific user experience when you use VYNKSAFE application. Everything you need to trade, purchase, sell, or invest is right at your fingertips.
              </p>
              <a href="https://apps.apple.com/in/app/vynksafe-crypto-app/id1626971774" target="_blank"><button className="btn btn-primary-v btn-custom px-4">Download App</button></a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Onmobile;
