import React, { useState } from "react";

import Introducing from "./pages/Introducing";
import Investment from "./pages/Investment";
import Pricing from "./pages/Pricing";
import Onmobile from "./pages/Onmobile";
import Security from "./pages/Security";
import OurApps from "./pages/OurApps";
import SecurityTow from "./pages/SecurityTow";
import Featured from "./pages/Featured";
import Footer from "./pages/Footer";
import Subscribe from "./pages/Subscribe";
import Register from "./pages/Register";
const Home = () => {
  const [userMail, setUserMail] = useState("");

  return (
    <div className="vynksafe_new_style d-flex flex-column h-100">
      <div id="content">
        <Introducing />
        {/* <Investment /> */}
        <Pricing />
        <Onmobile />
        {/* <Security /> */}
        <OurApps />
        <SecurityTow />
        <Featured />
        <Register userMail={userMail} setUserMail={setUserMail} />
        {/* <Subscribe /> */}
        <Footer />
      </div>
    </div>
  );
};

export default Home;
