import React, { useState, useCallback, useEffect, useRef } from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "../Toast/Toast";
import Select from "react-select";
import QRCode from "react-qr-code";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import { CoinNetworksListAction } from "../../redux/apiActions/user.action";
import utill from "../../_constant/utill";


const Deposit = ({ userCoinList, hideToptab, topActiveTab }) => {


    const dispatch = useDispatch();
    const [networksList, setNetworkList] = useState([]);
    const [coinData, setCoinData] = React.useState(null);
    const [selectedNetwork, setNetwork] = useState(null);
    const [urlCoin, setUrlCoin] = useState(null)
    const params = new URLSearchParams(window.location.search);
    const toParam = params?.get("to");
    const style = {
        control: base => ({
            ...base,
            border: 0,
            // This line disable the blue border
            boxShadow: "none"
        })
    };

    useEffect(() => {
        setCoinData(null)
        setNetwork(null)
    }, [topActiveTab])

    const getCoinNetworks = useCallback(
        async (data) => {
            const networksResponse = await CoinNetworksListAction(data, dispatch);

            if (networksResponse?.status === 200) {
                setNetworkList(
                    networksResponse?.data.map((val) => {
                        val.value = val.id;
                        val.label = val.network_text;
                        return val;
                    })
                );
            }
        },
        [dispatch]
    );
    const setSelectValue = (val) => {
        setNetwork(val);
    };

    useEffect(() => {
        const coinValue = toParam?.split("=")[1];
        setUrlCoin(coinValue)
        if (urlCoin) {
            if (userCoinList) {
                const selectUrlCoin = async () => {
                    setNetwork(null)
                    const seletedCoin = userCoinList?.find((coin) => coin?.symbol === urlCoin)
                    setCoinData(seletedCoin)
                    let data = new FormData();
                    data.append("coin", seletedCoin?.coin);
                    data.append("type", 1);
                    await getCoinNetworks(data);
                }
                selectUrlCoin();
            }

        }
    }, [toParam, urlCoin, userCoinList]);

    const setSearchCoinValue = async (val) => {
        // alert(val)

        const seletedCoin = userCoinList?.find((coin) => coin?.symbol === val)
        setCoinData(seletedCoin)
        let data = new FormData();
        data.append("coin", seletedCoin?.coin);
        data.append("type", 1);

        await getCoinNetworks(data);
    }

    const setCoinValue = async (val) => {
        setCoinData(val)
        setNetwork(null);
        let data = new FormData();
        data.append("coin", val?.coin);
        data.append("type", 1);
        if (val) {
            await getCoinNetworks(data);
        }
        // setNetwork(val);
    };

    return (
        <div className="card mtb15">
            <div className="card-body">
                {/* <button onClick={pasteButton}>Paste</button> */}

                <h6 className="card-title">
                    <i className="ion ion-ios-arrow-back back_icon" style={{ cursor: "pointer" }} onClick={hideToptab}></i> Deposit Crypto
                </h6>
                <div className="row">
                    <div className="col-xl-8 col-lg-10 col-md-10 wallet-address">
                        <div className="row">
                            <div className="col-md-3 mb-2 mt-2 pl-4">
                                <h5 className="card-small-title pl-4">Select Coin</h5>
                            </div>

                            <div className="col-md-9 mb-2">
                                <Select
                                    className="wallet_network_select"
                                    classNamePrefix="custom_select"
                                    isSearchable={true}
                                    name="network"
                                    placeholder="Select a coin" s
                                    value={coinData}
                                    onChange={(e) => {
                                        setCoinValue(e);
                                    }}
                                    styles={style}
                                    options={userCoinList}
                                    getOptionValue={(option) => option.coin}
                                    getOptionLabel={(option) => (
                                        <div className="d-flex align-items-center">
                                            <img src={option.image} alt={option.symbol} width="25" className="mr-2" />
                                            {option.symbol}
                                        </div>
                                    )}
                                />

                                {coinData?.balance ? (<>
                                    <div className="elect-coin-avaialble-balance mt-2">
                                        <div className="d-sm-flex align-items-start flex-wrap justify-content-sm-between">
                                            <p className="text-secondary">Balance<br /><span className="card-avaialble-balance mt-1">{utill.beautifyNumber(coinData?.balance, 4)} {coinData?.symbol} <span className="text-secondary">≈ ${utill.beautifyNumber(coinData.balance_currency, 2)}</span></span></p>
                                            <p className="text-sm-right text-secondary">Price<br /><span className="card-avaialble-balance mt-1">$ {utill.valueFix(parseFloat(coinData?.rate_currency), 4)}</span></p>
                                        </div>
                                    </div>
                                </>) :
                                    < ul className="select-main-coins-wallet d-flex align-items-center flex-wrap justify-content-md-between mt-3">
                                        {
                                            userCoinList?.slice(0, 4)?.map((coin, index) => {
                                                return <li key={index} onClick={() => setSearchCoinValue(coin?.symbol)} className="coin_select"><img src={coin.image} alt={coin?.symbol} width="25" className="mr-2" />{coin?.symbol}</li>
                                            })
                                        }
                                        {/* <li onClick={() => setSearchCoinValue("VYNC")}><img src={`${BASE_URL.BASE_URL}img/coin/VYNC.png`} alt="VYNC" width="30" className="mr-2" /> VYNC</li>
                                        <li onClick={() => setSearchCoinValue("BNB")}> <img src={`${BASE_URL.BASE_URL}img/coin/bnb.png`} alt="BNB" width="30" className="mr-2" /> BNB</li>
                                        <li onClick={() => setSearchCoinValue("ETH")}><img src={`${BASE_URL.BASE_URL}img/coin/ETH.png`} alt="ETH" width="30" className="mr-2" /> ETH</li>
                                        <li onClick={() => setSearchCoinValue("CMQ")}><img src={`${BASE_URL.BASE_URL}img/coin/CMQ.png`} alt="CMQ" width="30" className="mr-2" /> CMQ</li>
                                        <li onClick={() => setSearchCoinValue("USDT")}><img src={`${BASE_URL.BASE_URL}img/coin/USDT.png`} alt="USDT" width="30" className="mr-2" /> USDT</li> */}
                                    </ul>
                                }
                            </div>
                        </div>
                        {coinData?.balance &&
                            <div className="row wallet-address pt-4" style={{ borderTop: "1px solid rgba(94, 114, 143, 0.3)" }}>

                                <div className="col-md-3 mb-2 mt-2 pl-4">
                                    <h5 className="card-small-title ml-4">Deposit To</h5>
                                </div>

                                <div className="col-md-9 mb-2">
                                    <Select
                                        className="wallet_network_select"
                                        classNamePrefix="custom_select"
                                        isSearchable={true}
                                        name="network"
                                        placeholder="Select a network"
                                        value={selectedNetwork}
                                        onChange={(e) => {
                                            setSelectValue(e);
                                        }}
                                        options={networksList}
                                    />
                                    <p className="mt-2 text-secondary">
                                        Deposits to this address are unlimited.
                                        Note that you may not be able to withdraw
                                        all of your funds at once if you deposit
                                        more than your daily withdrawal limit.
                                    </p>
                                </div>
                            </div>
                        }

                        {selectedNetwork &&
                            selectedNetwork.address ? (
                            <>

                                <Row className="pt-4" style={{ borderTop: "1px solid rgba(94, 114, 143, 0.3)" }}>
                                    <Col md={3} className="mt-2 pl-4">
                                        <h6 className="card-small-title ml-4">
                                            Address
                                        </h6>
                                    </Col>
                                    <Col md={9}>
                                        <div className="d-flex">
                                            <h6 className="card-small-title" style={{ fontSize: "14px" }}>
                                                {selectedNetwork.address}
                                                <span className="ml-2">
                                                    <CopyToClipboard
                                                        text={
                                                            selectedNetwork &&
                                                                selectedNetwork.address
                                                                ? selectedNetwork?.address
                                                                : ""
                                                        }
                                                        onCopy={() =>
                                                            toast.success("copied")
                                                        }
                                                    >
                                                        <span className="copy_btn">
                                                            <i
                                                                title="Copy address to clipboard"
                                                                className="icon ion-md-copy"
                                                            ></i>
                                                        </span>
                                                        {/* .copy_btn */}
                                                    </CopyToClipboard>
                                                </span>
                                            </h6>
                                            <OverlayTrigger
                                                key="right"
                                                placement="right"
                                                overlay={
                                                    <Tooltip id={`tooltip-right`} className="Qr_mytooltip">
                                                        <div className="mywallet-qrcode text-center card-body card">
                                                            <p className="text-secondary pb-2">Scan the code on the withdrawal page of the trading platform APP or wallet APP</p>

                                                            <QRCode
                                                                size={256}
                                                                style={{
                                                                    height: "auto",
                                                                    maxWidth: "100%",
                                                                    width: "100%",
                                                                    border: "5px solid #f5f5f5",
                                                                }}
                                                                value={selectedNetwork?.address}
                                                                viewBox={`0 0 256 256`}
                                                            />
                                                        </div>
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="qr_show">
                                                    {/* <img src={`${BASE_URL.BASE_URL}img/coin/qrcode.png`} style={{ height: "24px", padding: "0px", marginLeft: "5px", marginTop: "6px" }} /> */}
                                                </div>

                                            </OverlayTrigger>

                                        </div>

                                        <div className="elect-coin-avaialble-balance mt-2">
                                            <div className="d-flex align-items-start flex-wrap justify-content-between">
                                                <p className="text-secondary">Minimim Deposit<br /><span className="card-avaialble-balance mt-1">{" "}
                                                    {selectedNetwork?.min_deposit +
                                                        " " +
                                                        selectedNetwork?.symbol}</span></p>
                                                <p className="text-right text-secondary">Selected Wallet<br /><span className="card-avaialble-balance mt-1">Spot Wallet</span></p>
                                            </div>
                                        </div>

                                        {/* <p>
                                            Minimum Deposit Amount :{" "}
                                            {selectedNetwork?.min_deposit +
                                                " " +
                                                selectedNetwork?.symbol}
                                        </p> */}

                                        <ul className="mywallet-address-liststyle">
                                            <li className="text-secondary">Send only {selectedNetwork?.symbol} to this deposit address</li>
                                            <li className="text-secondary">It will take upto 30 minutes to reflect on Txn.</li>
                                            <li className="text-secondary">It will process after 12 block confirmation.</li>
                                            <li className="text-secondary">Ensure the network is <span>{selectedNetwork?.network_text}</span></li>
                                        </ul>
                                    </Col>
                                </Row>
                            </>
                        ) : null}

                        <div className="col-md-4">
                            {/* <h2>Address :</h2> */}
                        </div>

                        <div className="col-md-3">

                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};


export default Deposit;


