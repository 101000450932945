import React from 'react'
import BASE_URL from "../../_constant/index";

const FeedBack = ({ userFeedBack, }) => {

    return (
        <div>
            <div className="row">
                {userFeedBack?.length > 0 ? (<>{
                    userFeedBack?.map((item, index) => {
                        return (
                            <div className="col-12 mt-3 mt-lg-0" key={index}>
                                <div className="d-flex">
                                    <img className="mr-4" src={`${BASE_URL.BASE_URL}img/images/user.png`} height="40px"></img>
                                    <div>
                                        <h6 className="mt-2">{item?.full_name}
                                            {item?.feedback_type == "1" ?

                                                <img className="ml-2" src={`${BASE_URL.BASE_URL}img/images/p2p_p_like.svg`} height="25px" />
                                                :
                                                <img className="ml-2" src={`${BASE_URL.BASE_URL}img/images/p2p_p_dislike.svg`} height="25px" />
                                            }
                                        </h6>
                                        <div>
                                            <span className="d-block line_height_p2p_user_payment mt-3 white_space_nowrap">
                                                {item?.created_date}
                                            </span>
                                        </div>
                                        <div className="align-items-center">
                                            <p>{item?.feedback_text}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="border my-4"></div>
                            </div>
                        )
                    })
                }</>) :
                    <div className="col-12 mb-5 d-flex justify-content-center mt-5" style={{ height: "100px" }}>
                        <div className="no-data" style={{ color: "#9ea4ab" }}>
                            <i className="icon ion-md-document" style={{ color: "#9ea4ab" }}></i>
                            No data
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

export default FeedBack