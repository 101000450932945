import React from "react";
import HeroSection from "./HeroSection";
import Connectivity from "./Connectivity";
import MarkitData from "./MarkitData";
import InvestmentAndService from "./InvestmentAndService";
import VipInstitutional from "./VipInstitutional";
import LinkProgram from "./LinkProgram";
import MadeForDeveloper from "./MadeForDeveloper";
import FAQ from "./FAQ";
import Footer from "../../VynksafeNew/pages/Footer";
const VynkPayApi = () => {
  return (
    <div className="vynksafe_api">
      <HeroSection />
      <Connectivity/>
      <MarkitData/>
      <InvestmentAndService/>
      <VipInstitutional/>
      <LinkProgram/>
      <MadeForDeveloper/>
      <FAQ/>
      <Footer/>
    </div>
  );
};

export default VynkPayApi;
