import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/ionicons.min.css";
import "./assets/scss/style.scss";
import "./assets/fonts/style.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import HttpsRedirect from "react-https-redirect";
// import { GoogleOAuthProvider } from "@react-oauth/google";
let persistor = persistStore(store);
ReactDOM.render(
  <HttpsRedirect>
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </HttpsRedirect>,
  document.getElementById("root")
);

export const storeInstance = store;

// "start": "export HTTPS=true&&SSL_CRT_FILE=privkey.pem&&SSL_KEY_FILE=key.pem react-scripts start",
