import React, { useEffect, useState } from 'react'

import { Modal, Button, Container, Tab, Row, Nav, Col, Form, Clearfix } from 'react-bootstrap'
import { useSelector } from "react-redux";
import BASE_URL from "../../_constant/index";
import { get_TradeDetails_By_ID, cancel_Trade, get_Payment_Details_ByTradeID, getTradePaymentDetails, payment_Released, create_Appeal, getTradeAppeal, coin_Released, trade_Feedback, get_Trade_Status } from '../../redux/apiActions/p2p.action';
import { otpSendPrivate, otpVerifyPrivate, } from "../../redux/apiActions/user.action";
import { useHistory, useLocation } from 'react-router-dom'
import moment from "moment";
import { toast } from "../../components/Toast/Toast";
import Chat from './Chat';
import BASE_URL_Tow from '../../_constant/index'


const CreateOffer = () => {
    let user_Data = useSelector((state) => state.user.userData);

    function isNumberKey(value) {
        value = value ? value : "";
        // alert(value)
        var validNumber = new RegExp(/^[0-9]*(\.[0-9]*)?$/);
        if (validNumber.test(value)) {
            return value;
        } else {
            return false;
        }
    }
    const outputFormat = 'DD MMM YYYY HH:mm a';
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tradeid = queryParams.get('tradeid');
    const [tradeData, setTradeData] = useState(null);
    const [show, setShow] = useState(false);
    const [cancelReason, setCancelReason] = useState("")
    const [releaseCoin, setReleaseCoin] = useState(false)
    const [appeal, setAppeal] = useState(false)
    const [appealForm, setAppealForm] = useState(false)
    const [error, setError] = useState("")
    const [paymentMethod, setPaymentMethod] = useState(null)
    const [time, setTime] = useState({ minutes: 0, seconds: 0 });
    const [selectedPayment, setselectedPayment] = useState("");
    const [loading, setLoading] = useState(false)
    const [tradeAppeal, setTradeAppeal] = useState()
    const [cancelData, setCancelData] = useState({
        trade_id: "",
        cancel_by: "",
    })

    const handleSelectedPayment = (key) => {
        setselectedPayment(key)
    }
    useEffect(() => {
        // Set default active tab to the first paymentMethod item
        if (paymentMethod?.length > 0) {
            setselectedPayment(paymentMethod[0]?.payment_type);
            handleSelectedPayment(paymentMethod[0]?.payment_type)

        }
    }, [paymentMethod]);


    const handlePaymentReleased = async () => {
        try {
            setLoading(true)
            const data = new FormData();
            data.append("trade_id", tradeid)
            data.append("selected_payment_method", selectedPayment)
            const response = await payment_Released(data);
            if (response.status == 200) {
                toast.successes(response?.message)
                tradeDetails()
                setReleaseCoin(false)
                setLoading(false)
            } else {
                toast.errors(response?.message)
                setLoading(false)
                setReleaseCoin(false)
            }
        } catch (error) {
            console.log(error)
        }

    }
    const handleTradeAppeal = async () => {
        try {
            const data = new FormData();
            data.append("trade_id", tradeid)
            const response = await getTradeAppeal(data)
            if (response.status == 200) {
                setTradeAppeal(response?.data)
            }
        } catch (error) {
            console.log(error)
        }

    }
    const [errors, setErrors] = useState({});

    const [appealData, setAppealData] = useState({
        name: "",
        phone_no: "",
        reason: "",
        notes: "",
        proof: null,

    })


    const [fileError, setFileError] = useState("")

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        if (name === "proof" && files?.length > 0) {
            const file = files[0];

            // Check if the file type is JPG or PNG
            if (!file.type.includes("image/jpeg") && !file.type.includes("image/png")) {
                setFileError("Please upload a valid JPG or PNG file.");
            } else {
                setFileError("");
                setAppealData((prevData) => ({
                    ...prevData,
                    [name]: file,
                }));
            }
        } else if (name === "phone_no") {
            // Validate if the input is a number
            const isValidNumber = isNumberKey(value);

            if (isValidNumber || value === "") {
                setAppealData((prevData) => ({
                    ...prevData,
                    [name]: isValidNumber,
                }));
            }
        } else {
            setAppealData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };


    const handleAppealReleased = async () => {
        try {

            const validationErrors = {};
            if (!appealData.name) {
                validationErrors.fullName = 'Name is required.';
            }
            if (!appealData.phone_no) {
                validationErrors.phone_no = 'Phone no is required.';
            }
            if (!appealData.reason) {
                validationErrors.reason = 'Reason is required.';
            }
            if (!appealData.notes) {
                validationErrors.notes = 'Notes is required.';
            }
            if (!appealData.proof) {
                validationErrors.proof = 'file is required.';
            }

            if (Object.keys(validationErrors).length > 0) {
                // If there are validation errors, update the errors state and return
                setErrors(validationErrors);
                return;
            }

            setLoading(true)
            const data = new FormData();
            data.append("trade_id", tradeid)
            data.append("name", appealData?.name)
            data.append("phone_no", appealData?.phone_no)
            data.append("reason", appealData?.reason)
            data.append("notes", appealData?.notes)
            data.append("proof", appealData?.proof)
            const response = await create_Appeal(data)
            if (response.status == 200) {
                handleTradeAppeal();
                toast.successes(response?.message || response?.data?.message);
                setLoading(false)
                setAppeal(false)
            } else {
                handleTradeAppeal();
                toast.errors(response?.message)
                setLoading(false);
                setAppeal(false)
            }
        } catch (error) {
            setLoading(false)
            console.log(error)
        }

    }

    const tradeDetails = async () => {
        const data = new FormData();
        data.append('trade_id', tradeid)
        try {
            const response = await get_TradeDetails_By_ID(data)
            if (response?.status == 200) {
                setTradeData(response?.data[0])
            }
        } catch (error) {
            console.error(error)
        }
    }
    const getTradeStatus = async () => {

        try {
            const data = new FormData();
            data.append('trade_id', tradeid);

            const response = await get_Trade_Status(data);
            if (tradeData && tradeData?.status !== (response?.data[0]?.status || response?.data?.status)) {
                tradeDetails();
            }
        } catch (error) {
            console.log(error);
        }

    };

    useEffect(() => {
        if (tradeData?.status !== undefined) {
            getTradeStatus();

            const intervalId = setInterval(() => {
                getTradeStatus();
            }, 5000);

            return () => clearInterval(intervalId);
        }
    }, [tradeData]);

    const handleCoinReleased = async () => {
        try {
            setLoading(true)
            const data = new FormData();
            data.append("trade_id", tradeid);
            const response = await coin_Released(data)
            if (response.status == 200) {
                tradeDetails()
                toast?.successes(response?.message || response?.data?.message);
                setAddFeedBack(true)
                setLoading(false)
            } else {
                toast?.errors(response?.message || response?.data?.message);
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }
    useEffect(() => {

        const initialTimeArray = tradeData?.remain_time?.split(':');
        if (initialTimeArray) {
            const initialMinutes = parseInt(initialTimeArray[0], 10);
            const initialSeconds = parseInt(initialTimeArray[1], 10);

            setTime({ minutes: initialMinutes, seconds: initialSeconds })
        }

    }, [tradeData])


    useEffect(() => {
        const timer = setInterval(() => {
            if (time.minutes === 0 && time.seconds === 0) {
                // Timer has reached zero, call the function without clearing the interval
                // tradeDetails();
            } else {
                // Decrease the time by one second
                setTime((prevTime) => {
                    if (prevTime.seconds === 0) {
                        return { minutes: prevTime.minutes - 1, seconds: 59 };
                    } else {
                        return { ...prevTime, seconds: prevTime.seconds - 1 };
                    }
                });
            }
        }, 1000);

        // Clear the interval when the component unmounts
        return () => clearInterval(timer);
    }, [time]);


    const formattedMinutes = String(time.minutes)?.padStart(2, '0');
    const formattedSeconds = String(time.seconds)?.padStart(2, '0');


    const handleClose = () => {
        setShow(false)
    };
    const handleShow = (id, cancel_by) => {
        setCancelData({
            trade_id: id,
            cancel_by: cancel_by,
        })
        setShow(true)

    };
    const cancelTrade = async () => {
        if (!cancelReason || cancelReason === "") {
            setError("Please Select Reason");
        } else {
            setLoading(true)
            const data = new FormData();
            data.append("trade_id", cancelData?.trade_id || "")
            data.append("cancel_by", cancelData?.cancel_by || "") //1: Seller, 2:Buyer
            data.append("reason", cancelReason || "")
            try {
                const response = await cancel_Trade(data);

                if (response.status == 200) {
                    toast?.successes(response?.message || response?.data?.message)
                    tradeDetails();
                    setShow(false)
                    setError("")
                    setLoading(false)
                } else {
                    toast?.errors(response?.message || response?.data?.message)
                    setShow(false)
                    setError("")
                    setLoading(false)
                }
            } catch (error) {
                console.log(error)
                setLoading(false)
                setError("")
            }
        }

    }


    const paymentDetailsByTradeID = async (id) => {

        try {
            if (id) {
                const data = new FormData();
                data.append('offer_id', id)
                const response = await get_Payment_Details_ByTradeID(data);
                if (response?.status == 200) {
                    setPaymentMethod(response?.data)
                    setselectedPayment(response?.data[0]?.payment_type);
                    handleSelectedPayment(paymentMethod[0]?.payment_type)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    const getTrade_Payment_Details = async () => {
        try {
            const data = new FormData();
            data.append('trade_id', tradeid)
            const response = await getTradePaymentDetails(data);
            if (response?.status == 200) {
                setPaymentMethod(response?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (tradeData?.offer_user_id !== "" && tradeData?.offer_user_id !== user_Data?.id) {
            paymentDetailsByTradeID(tradeData?.offer_id);
        } else {
            getTrade_Payment_Details();
        }
    }, [user_Data?.id, tradeData]);


    useEffect(() => {
        tradeDetails();
        handleTradeAppeal();
    }, [tradeid])




    var separatedStrings = tradeData?.payment_type.split(",");
    const [second, setSecond] = useState(59);
    const [minutes, setMinutes] = useState(0);
    const [otp, setOtp] = useState("");
    const [releaseCoinOTP, setReleaseCoinOTP] = useState(false)
    const [otpError, setOtpError] = useState("");


    useEffect(() => {
        const interval = setInterval(() => {
            if (second > 0) {
                setSecond(second - 1)
            }
            if (second === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSecond(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000)
        return () => {
            clearInterval(interval);
        };
    }, [minutes, second])


    const sendOtp = async () => {
        setLoading(true)
        setOtpError("")
        setOtp("")
        const response = await otpSendPrivate();
        if (response) {
            setLoading(false)
            if (response?.status === 200) {
                setReleaseCoinOTP(true);
                // setSecond(59);
            } else {
                setLoading(false)
                toast.errors(response?.message, response?.message)
            }
        }
    }


    const verifyPrivateOtp = async () => {
        try {
            if (!otp || otp == "") {
                setOtpError("Please Enter OTP")
            } else if (otp?.length !== 6) {
                setOtpError("Please Provide Valid OTP")
            } else {
                setLoading(true)
                let formData = new FormData();
                formData.append("otp", otp)
                const response = await otpVerifyPrivate(formData);
                if (response?.status === 200) {
                    handleCoinReleased();
                    setReleaseCoinOTP(false)
                } else {
                    setOtpError(response.message || "Invalid OTP");
                    toast.errors("Invalid OTP", response.message || "Invalid OTP")
                    setLoading(false)
                }
            }
        } catch (error) {
            setLoading(false)
        }
    }
    const reSendOtp = async () => {
        setLoading(true)
        const response = await otpSendPrivate();
        if (response) {
            setLoading(false)
            if (response?.status === 200) {
                setSecond(59);
                toast.successes("OTP resend", response?.message)
            }
        }
    }
    const [FeedbackError, setFeedbackErrors] = useState({});
    const [addFeedBack, setAddFeedBack] = useState(false)
    const [reviewType, setReviewType] = useState("1"); // State to store the selected radio button value
    const [reviewText, setReviewText] = useState("");

    useEffect(() => {
        if (tradeData) {
            if (tradeData?.status_msg == "Success" && !tradeData?.is_feedback_given) {
                setAddFeedBack(true)
            }
        }
    }, [tradeData,])


    const handleReviewTypeChange = (e) => {
        setReviewType(e.target.value);
    };


    const addTradeFeedback = async () => {
        try {

            const validationErrors = {};
            if (!reviewType) {
                validationErrors.reviewType = 'Field is required.';
            }

            if (!reviewText) {
                validationErrors.reviewText = 'Field is required.';
            }

            if (Object.keys(validationErrors).length > 0) {
                // If there are validation errors, update the errors state and return
                setFeedbackErrors(validationErrors);
                return;
            }

            const data = new FormData()
            data.append("trade_id", tradeid)
            data.append("text", reviewType)
            data.append("type", reviewType) //1-positive, 0- negativeF
            const response = await trade_Feedback(data);
            if (response.status == 200) {
                setAddFeedBack(false)
                toast.successes(response?.message)
            } else {
                toast.errors(response?.message)
            }
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div>
            <Modal show={addFeedBack} backdrop="static" className="p2pOfferViewModal" centered onHide={() => { setAddFeedBack(false) }} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Feedback</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row">

                        <div className="col-md-12 col-12 text-left d-flex">
                            <label>How's your review:</label>

                            <div className="radio_group radio_group_positive">
                                <input
                                    type="radio"
                                    name="review_type"
                                    className="review_type"
                                    value="1"
                                    checked={reviewType === "1"} // Check if the value matches the state
                                    onChange={handleReviewTypeChange}
                                />
                                <label htmlFor="review_type">
                                    <i className="fa fa-thumbs-up"></i>
                                </label>
                                <p className="ml-4 pl-2">Positive</p>
                            </div>

                            <div className="radio_group radio_group_negative">
                                <input
                                    type="radio"
                                    name="review_type"
                                    className="review_type"
                                    value="0"
                                    checked={reviewType === "0"} // Check if the value matches the state
                                    onChange={handleReviewTypeChange}
                                />
                                <label htmlFor="review_type">
                                    <i className="fa fa-thumbs-down"></i>
                                </label>
                                <p className="ml-4 pl-2">Negative</p>
                            </div>
                            <p className="text-danger" id="p2p_location_error">{FeedbackError?.reviewType}</p>
                        </div>

                        <div className="col-md-12 col-12 text-left">
                            <label>Please share your experience:</label>
                            <textarea
                                value={reviewText}
                                onChange={(e) => { setReviewText(e.target.value) }}
                                className="form-control"
                                id="feedback_text"
                                name="feedback_text"
                                rows="5"
                            />
                            <p className="text-danger" id="p2p_location_error">{FeedbackError?.reviewText}</p>
                        </div>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div className="mt-3 p2p_paymentReleaseModal_btns w-100">

                        <Button disabled={loading ? true : false} onClick={addTradeFeedback} className="p2p_buy_btn_bg theme-btn-2 theme-btn">
                            {loading ? "Loading...." : `Confirm`}
                        </Button>

                        <Button variant="secondary" onClick={() => { setAddFeedBack(false) }} className="btn disable_btn_bg harvest_btn_pools theme-btn-2 theme-btn">
                            Cancel
                        </Button>
                    </div>

                </Modal.Footer>
            </Modal>
            <Modal show={releaseCoinOTP} backdrop="static" className="p2pOfferViewModal" centered onHide={() => { setReleaseCoinOTP(false) }} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Release Coin</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mt-3 text-center">
                        <span className="text-center mb-3">Please confirm that you have successfully transferred the
                            coin to the  buyer.
                        </span>
                        <p className='withdraw_conf_otp pb-3'>An OTP code has been sent to your registered email address. Please enter the code in the box below to proceed.</p>
                        <div className='d-flex justify-content-between'>
                            <label className='mt-2'>Enter Your OTP :</label>
                            <Form.Control
                                placeholder="Enter your OTP"
                                aria-label="otp"
                                aria-describedby="basic-addon"
                                className="Withdraw_otp_conf"
                                value={otp || ""}
                                maxLength="6"
                                minLength="6"
                                onChange={(e) => {
                                    setOtp(e.target.value.replace(/\D/g, ""));
                                    setOtpError("")
                                }}
                            />

                        </div>
                        <small className="red mt-1" style={{ textAlign: "right", display: "flex", justifyContent: "flex-end" }}>
                            {otpError}
                        </small>
                        <div className="countdown-text mt-2 mb-3">
                            {second > 0 || minutes > 0 ? (
                                <p style={{ textAlign: "right" }}>
                                    Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}
                                    :{second < 10 ? `0${second}` : second}
                                </p>
                            ) : (
                                <div style={{ textAlign: "right", display: "flex", justifyContent: "flex-end" }} >
                                    <p style={{ lineHeight: "2.2" }}>Didn't receive code?</p>
                                    <button className="btn" onClick={reSendOtp}>
                                        Resend OTP
                                    </button>
                                </div>
                            )}

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="mt-3 p2p_paymentReleaseModal_btns w-100">

                        <Button variant="primary" disabled={loading ? true : false} onClick={verifyPrivateOtp} className="p2p_buy_btn_bg theme-btn-2 theme-btn">
                            {loading ? "Loading...." : `Confirm, Release ${tradeData?.coin.toUpperCase()}`}
                        </Button>

                        <Button variant="secondary" onClick={() => { setReleaseCoinOTP(false) }} className="btn disable_btn_bg harvest_btn_pools theme-btn-2 theme-btn">
                            Cancel
                        </Button>
                    </div>

                </Modal.Footer>
            </Modal>
            <Modal show={releaseCoin} backdrop="static" className="p2pOfferViewModal" centered onHide={() => { setReleaseCoin(false) }} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Release Coin</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mt-3 text-center">
                        {tradeData?.type == "Selling" ? (<>
                            <span className="text-center mb-3">Please confirm that you have successfully transferred the
                                coin to the  buyer.
                            </span>
                        </>) : (<>
                            <span className="text-center mb-3">Please confirm that you have successfully transferred the
                                coin to the  Seller.
                            </span>
                        </>)}

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="mt-3 p2p_paymentReleaseModal_btns w-100">
                        {tradeData?.status == 2 && tradeData?.type == "Selling" ? (<>
                            <Button variant="primary" disabled={loading ? true : false} onClick={handleCoinReleased} className="p2p_buy_btn_bg theme-btn-2 theme-btn">
                                {loading ? "Loading...." : `Confirm, Release ${tradeData?.coin.toUpperCase()}`}

                            </Button>
                        </>) : (<>
                            <Button variant="primary" disabled={loading ? true : false} onClick={handlePaymentReleased} className="p2p_buy_btn_bg theme-btn-2 theme-btn">
                                {loading ? "Loading...." : " Transferred, Notify seller"}

                            </Button>
                        </>)}
                        <Button variant="secondary" onClick={() => { setReleaseCoin(false) }} className="btn disable_btn_bg harvest_btn_pools theme-btn-2 theme-btn">
                            Cancel
                        </Button>
                    </div>

                </Modal.Footer>
            </Modal>
            <Modal show={appeal} backdrop="static" className="p2pOfferViewModal" centered onHide={() => { setAppeal(false) }} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Appeal</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!appealForm ? (<>
                        <div className="mt-3 text-center">
                            <span className="text-center mb-3">
                                Do you fill any issue with this trade? Please raise appeal only when you fill any unusual activity with your trade.
                            </span>
                            <br />
                            <span>Note :- Use this button in case of any trouble</span>
                        </div>
                    </>) : (<>
                        <div className="form-group text-left">

                            <input
                                type="text"
                                value={appealData.name}
                                name="name"
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Name"
                                autoComplete="off"
                            />
                            <p className="text-danger" id="p2p_location_error">{errors?.fullName}</p>
                        </div>
                        <div className="form-group text-left">

                            <input
                                type="tel"
                                pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                value={appealData.phone_no}
                                name="phone_no"
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Phone no"
                                autoComplete="off"
                            />
                            <p className="text-danger" id="p2p_location_error">{errors?.phone_no}</p>
                        </div>
                        <div className="form-group text-left">

                            <input
                                type="text"
                                value={appealData.reason}
                                name="reason"
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Reason of appeal"
                                autoComplete="off"
                            />
                            <p className="text-danger" id="p2p_location_error">{errors?.reason}</p>
                        </div>
                        <div className="form-group text-left">

                            <textarea

                                value={appealData.notes}
                                name="notes"
                                onChange={handleChange}
                                className="form-control"
                                id="feedback_text"
                                rows='4'
                                placeholder="Extra Notes"
                            // autoComplete="off"
                            />
                            <p className="text-danger" id="p2p_location_error">{errors?.notes}</p>
                        </div>
                        <div className="form-group text-left">
                            <input
                                type="file"
                                // value={appealData.proof}
                                name="proof"
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Reason of appeal"
                                autoComplete="off"
                            />

                            <p className="text-danger" id="p2p_location_error">{fileError}</p>
                            <p className="text-danger" id="p2p_location_error">{errors?.proof}</p>
                        </div>


                    </>)}
                </Modal.Body>
                <Modal.Footer>
                    {/* handleAppealReleased */}
                    <div className="mt-3 p2p_paymentReleaseModal_btns w-100">
                        {!appealForm ?
                            <Button variant="primary" onClick={() => setAppealForm(true)} className="p2p_buy_btn_bg theme-btn-2 theme-btn">
                                Yes, I Need
                            </Button> :
                            <Button variant="primary" disabled={loading ? true : false} onClick={handleAppealReleased} className="p2p_buy_btn_bg theme-btn-2 theme-btn">
                                {loading ? "Loading...." : " Appeal"}
                            </Button>
                        }
                        <Button variant="secondary" onClick={() => { setAppeal(false) }} className="btn disable_btn_bg harvest_btn_pools theme-btn-2 theme-btn">
                            Cancel
                        </Button>
                    </div>

                </Modal.Footer>
            </Modal>

            <Modal show={show} backdrop="static" className="p2pOfferViewModal" centered onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Cancel Order</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="tips_section_modal">
                        <input type="hidden" id="cancel_by" name="cancel_by" value="2" />
                        <input type="hidden" id="trade_id" name="trade_id" value="1348" />
                        <h6 className="text-left">
                            <img className="mr-2" src="https://mlm.pixelsoftwares.com/vynksafe/vynksafe.com/assets/img/tips.png" width="20" /> Tips</h6>
                        <ul className="tips_section_modal_ul_pedding text-left pl-4 ml-2">
                            <li>1. If you have already paid by the buyer, please do not cancel the order.</li>

                            <li>2. If the buyer does not reply to chat within 15 mins, you will be unaccountable for
                                this order's cancellation. It will not affect your completion rate. You can make up
                                to 5 unaccountable cancellations in a day.</li>

                        </ul>
                    </div>
                    <div className="cancle_the_order_radio mt-3">
                        <h6 className="text-left mb-3">Why do you want to cancel the order?</h6>
                        <form className="text-left">
                            <ul>
                                <li className="d-flex align-items-baseline">
                                    <input className="mr-2 position-relative"
                                        type="radio" id="order1"
                                        name="fav_language"
                                        value="I do not want to trade anymore"
                                        onChange={(e) => {
                                            setCancelReason(e.target.value);
                                            setError("")
                                        }}
                                    />
                                    <label htmlFor="order1"> I do not want to
                                        trade anymore</label>
                                </li>
                                <li className="d-flex align-items-baseline">
                                    <input className="mr-2 position-relative"
                                        onChange={(e) => {
                                            setCancelReason(e.target.value);
                                            setError("")
                                        }}
                                        type="radio"
                                        id="order2"
                                        name="fav_language"

                                        value="I do not meet the requirements or the advertiser's trading terms and conditions" />
                                    <label htmlFor="order2"> I do not meet the requirements or the advertiser's trading
                                        terms and conditions</label>
                                </li>
                                <li className="d-flex align-items-baseline">
                                    <input
                                        onChange={(e) => {
                                            setCancelReason(e.target.value);
                                            setError("")
                                        }}
                                        className="mr-2 position-relative" type="radio" id="order3" name="fav_language" value="Seller Is asking for extra fee" />
                                    <label htmlFor="order3"> Buyer Is asking
                                        for extra fee</label>
                                </li>

                            </ul>
                            {error &&
                                <label id="cancel_error" className="text-danger">{error}</label>
                            }
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="p2p_paymentReleaseModal_btns w-100">
                        <Button variant="primary" disabled={loading ? true : false} onClick={cancelTrade} className="p2p_buy_btn_bg theme-btn-2 theme-btn">
                            {loading ? "Loading...." : "Confirm"}
                        </Button>
                        {/* <Button variant="primary"

                            disabled={loading ? true : false} onClick={cancelTrade} className="p2p_buy_btn_bg theme-btn-2 theme-btn">
                            {loading ? "Loading...." : "Confirm"}

                        </Button> */}
                        <Button variant="secondary" onClick={handleClose}
                            className="btn disable_btn_bg harvest_btn_pools theme-btn-2 theme-btn">
                            Cancel
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>



            <section className="p2p_main_banner_section pt-5 p2p_mainTable mb-4">
                <Container>
                    <div className="p2p_my_offers_page_main w-100 p-0">
                        <div className="row">
                            {tradeData?.status == 6 ? (<>
                                <div className="col-lg-7 col-12 pr-lg-5">
                                    <div className="row p2p_offer_cancelled d-md-flex justify-content-between align-items-start pt-3">
                                        <div className="col-md-12 col-12">
                                            <ul className="d-sm-flex align-items-start align-items-center">

                                                <li>
                                                    <div className="countdown_order_status_head d-flex align-items-center">
                                                        <h5 id="order_status_head">{tradeData?.status_msg == "Success" ? "Order Completed" : tradeData?.status_msg}</h5>
                                                        <div className="ml-2 countdown"></div>
                                                    </div>
                                                    <p id="order_status_subhead">Sucessfully sold {tradeData?.value_total} {tradeData?.coin.toUpperCase()}<img src={`${BASE_URL.BASE_URL}img/images/tick.svg`} height="15px" className="ml-1"></img>
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <hr />
                                    <h6 className="mt-3">Order info</h6>
                                    <div className="p2p_offer_cancelled_buying_section">
                                        <ul className="d-flex justify-content-between pt-2 mb-0">
                                            <li>
                                                <p>Order number</p>
                                                <h6 title="1">{tradeData?.uid}</h6>
                                            </li>
                                            <li>
                                                <p>Time created</p>
                                                <h6 title="1993.4934">
                                                    {moment(tradeData?.created_date, 'YYYY-MM-DD HH:mm:ss').format(outputFormat)}
                                                </h6>
                                            </li>
                                            <li>
                                                <p>Trade partner</p>
                                                <h6>
                                                    {tradeData?.trade_nick_name
                                                        ? tradeData?.trade_nick_name
                                                        : tradeData?.trade_full_name}
                                                </h6>
                                            </li>
                                        </ul>
                                    </div>
                                    <hr />
                                    <h6 className="mt-3">Order info</h6>
                                    <div className="p2p_offer_cancelled_buying_section">
                                        <ul className="d-flex justify-content-between pt-2 mb-0">
                                            <li>
                                                <p>Amount</p>
                                                <h6 title="1">{tradeData?.currency_symbol}{parseFloat(tradeData?.value_currency).toFixed()}</h6>
                                            </li>
                                            <li>
                                                <p>Price</p>

                                                <h6 title={parseFloat(tradeData?.coin_rate)}>
                                                    coin_rate

                                                    {tradeData?.currency_symbol}
                                                    {parseFloat(tradeData?.coin_rate)}
                                                    {/* {parseFloat(tradeData?.coin_rate).toFixed(4)} */}

                                                </h6>
                                            </li>
                                            <li>
                                                <p>Quantity</p>
                                                <h6 title={parseFloat(tradeData?.value_total)}>{
                                                    parseFloat(tradeData?.value_total).toFixed(6)}  {tradeData?.coin.toUpperCase()}</h6>
                                            </li>
                                        </ul>
                                    </div>
                                    <hr />

                                    <div className="order_completed_payment_method">
                                        <h6 className="mb-3">Payment method</h6>
                                        <div className="d-flex justify-content-between flex-wrap">
                                            {separatedStrings?.map((item) => {
                                                return (
                                                    <label className="basic-label" htmlhtmlFor="payment_method_1">{item}</label>
                                                )
                                            })}

                                            <div className="d-flex align-items-center reference_message_section flex-wrap">
                                                {/* <div className="d-flex align-items-center">
                                                    <p className="white_space_nowrap">Reference Message</p>
                                                    <div className="tooltip ml-1"> <i className="icon ion-md-information-circle-outline tooltip" aria-hidden="true"></i>
                                                        <span className="tooltiptext">text</span>
                                                    </div>
                                                </div>
                                                <p className="white_space_nowrap text-white">12345678925845656466 <a href="#" className="ml-1 text-orange"><i className="icon ion-md-copy"></i></a></p> */}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 mt-sm-5">
                                        {/* <div className="order_completed_right_btn d-sm-flex">
                                            <button className="p2p_dlt_confirm_btn theme-btn-2 theme-btn text-center mb-3 mt-1 " type="button">Reorder</button>
                                            <button className="p2p_cancel_btn_bg theme-btn-2 theme-btn text-center  mb-3 mt-1" type="button">Have a Question?</button>
                                        </div> */}
                                        {/* <hr /> */}
                                        {/* <div className="">
                                            <h5 className="mb-3">My f</h5>
                                            <div className="d-flex">
                                                <img className="mr-4" src={`${BASE_URL.BASE_URL}img/images/user.png`} height="40px"></img>
                                                <div className="">
                                                    <div className="d-flex align-items-baseline justify-content-between">
                                                        <h6>DjBravo</h6>
                                                        <p className="white_space_nowrap">2023-08-07 10:24:58</p>
                                                    </div>
                                                    <p className="pr-sm-5 mr-sm-5">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                    <ul className="d-flex align-items-center my_feedback_edit_section mt-3">
                                                        <li><a href=""> <img src={`${BASE_URL.BASE_URL}img/images/like_icon.png`} width="15"></img></a></li>
                                                        <li><a href=""> <img src={`${BASE_URL.BASE_URL}img/images/delete_icon.png`} width="15"></img></a></li>
                                                        <li><a href=""> <img src={`${BASE_URL.BASE_URL}img/images/edit_icon.png`} width="15"></img></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <hr />
                                        <div className="mt-4">
                                            <h6>Counterparty,s Feedback</h6>
                                            <div className="mt-2">
                                                <p>No Feedback</p>
                                            </div>
                                        </div> */}

                                    </div>

                                </div>
                            </>) : (<>
                                <div className="col-lg-7 col-12 pr-lg-5">
                                    <>
                                        <h4>{tradeData?.type} {tradeData?.coin?.toUpperCase()} {tradeData?.type == "Selling" ? "to" : "from"}  <a href="#" target="_blank"><b>{tradeData?.trade_nick_name}</b></a></h4>

                                        <div className="row p2p_offer_cancelled mt-4 d-md-flex justify-content-between align-items-start pt-3">
                                            <div className="col-md-12 col-12">
                                                <ul className="d-sm-flex align-items-start align-items-center">

                                                    <li className="mr-sm-3 mb-2 mb-sm-0 p2p_img_org_circle">
                                                        {tradeData?.status == "4" || tradeData?.status == "5" || tradeData?.status == "7" || tradeData?.status == "8" ? <>
                                                            <img src={`${BASE_URL.BASE_URL}img/images/p2p_trade_cancelled.svg`} id="order_status_icon" width="50" />
                                                        </> : <>
                                                            <img src={`${BASE_URL.BASE_URL}img/images/p2p_trade_escrow.png`} id="order_status_icon" width="50" />
                                                        </>}
                                                    </li>
                                                    <li>
                                                        <div className="countdown_order_status_head d-flex align-items-center">
                                                            <h5 id="order_status_head">{tradeData?.status_msg}</h5>
                                                            {/* {tradeData?.status &&
                                                                (!tradeData?.status == "1" &&
                                                                    !tradeData?.status_msg == "Coin on Escrow")
                                                                     ? (
                                                                <> */}

                                                            {(formattedMinutes !== null && tradeData?.status == "1")
                                                                ?
                                                                <div className="ml-2 countdown">
                                                                    {formattedMinutes}:{formattedSeconds}
                                                                </div>
                                                                : null}


                                                            {/* </>
                                                            ) : null} */}
                                                        </div>

                                                        <p id="order_status_subhead">
                                                            {tradeData !== null
                                                                ? tradeData?.status == "0"
                                                                    ? "The order is in pendiing list"
                                                                    : tradeData?.status == "1"
                                                                        ? tradeData?.type == "Buying"
                                                                            ? "Seller payment details are displaying below please complete payment for further processing."
                                                                            : "Successfully placed an order, please wait for Buyer to pay."
                                                                        : tradeData?.status == "2" && tradeData?.type == "Buying"
                                                                            ? "please Wait for seller to release coins"
                                                                            : tradeData?.status == "2"
                                                                                ? "The order payment made by the buyer"
                                                                                : tradeData?.status == "3"
                                                                                    ? "The order coin transfer is on progress"
                                                                                    : tradeData?.status == "4"
                                                                                        ? "The order cancelled by seller"
                                                                                        : tradeData?.status == "5"
                                                                                            ? "The order cancelled by buyer"
                                                                                            : tradeData?.status == "6"
                                                                                                ? "The order completed successfully"
                                                                                                : tradeData?.status == "7"
                                                                                                    ? "The order cancelled due to timeout"
                                                                                                    : tradeData?.status == "8"
                                                                                                        ? "The order cancelled by vynksafe team"
                                                                                                        : ""
                                                                : null}
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-md-12 col-12 mt-4">
                                                <ul className="p2p_offer_cancelled_order_details text-md-right mt-3 mt-md-0 mb-0 d-flex flex-wrap justify-content-between">
                                                    <li className="text-left">Order number<br />{tradeData?.uid}</li>
                                                    <li className="text-left">
                                                        Time created<br />
                                                        {/* {tradeData?.created_date} */}
                                                        {moment(tradeData?.created_date, 'YYYY-MM-DD HH:mm:ss').format(outputFormat)}
                                                        {/* 18 Nov 2023 18:14 pm */}

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <hr />
                                        <h6 className="mt-3">Order info</h6>
                                        <div className="p2p_offer_cancelled_buying_section">
                                            <ul className="d-flex justify-content-between pt-2 mb-0">
                                                <li>
                                                    <p>Amount</p>
                                                    <h6 title="1">{tradeData?.currency_symbol}{parseFloat(tradeData?.value_currency).toFixed()}</h6>
                                                </li>
                                                <li>
                                                    <p>Price</p>
                                                    <h6 title={parseFloat(tradeData?.coin_rate)}>
                                                        {tradeData?.currency_symbol}
                                                        {parseFloat(tradeData?.coin_rate)}
                                                        {/* {parseFloat(tradeData?.coin_rate).toFixed(4)} */}
                                                    </h6>
                                                </li>
                                                <li>
                                                    <p>Quantity</p>
                                                    <h6 title={tradeData?.value_total}>{parseFloat(tradeData?.value_total).toFixed(6)}  {tradeData?.coin.toUpperCase()}</h6>
                                                </li>
                                            </ul>
                                        </div>
                                        <hr />
                                        <div className="d-none" id="paymentDetailsDiv">
                                            <h6 className="my-3">Payment method</h6>
                                            <div className="p2p_my_trade_tab_payment_main">
                                                <div className="p2p_my_trade_tab_payment tab" id="payment_method_tab">
                                                </div>
                                            </div>
                                        </div>
                                        {(tradeData?.status == 1 && tradeData?.type == "Buying") &&
                                            <div className="">
                                                <h6 className="mb-3">Payment method</h6>
                                                <Tab.Container id="left-tabs-example"
                                                    defaultActiveKey="1"
                                                    activeKey={selectedPayment}

                                                    onSelect={handleSelectedPayment}
                                                >
                                                    <Row className="p2p_my_trade_tab_payment_main">
                                                        <Col className="col-3 p2p_my_trade_tab_payment tab pr-0">
                                                            <Nav variant="pills" className="flex-column">
                                                                {paymentMethod?.map((item, index) => (
                                                                    <Nav.Item key={index}>
                                                                        <Nav.Link
                                                                            eventKey={item?.payment_type}
                                                                        // onClick={handleSelectedPayment(item?.payment_typ)}
                                                                        >
                                                                            <img
                                                                                className="mr-2"
                                                                                src={
                                                                                    `${BASE_URL_Tow.BASE_URL_Tow}${item?.payment_image}`
                                                                                }
                                                                                width="30"
                                                                                alt={`Icon for ${item?.name}`}
                                                                            />
                                                                            {item?.name}
                                                                        </Nav.Link>
                                                                    </Nav.Item>

                                                                ))}
                                                            </Nav>
                                                        </Col>
                                                        <Col className="col-9">
                                                            <Tab.Content className="py-3 px-2">
                                                                {paymentMethod?.map((item, index) => (
                                                                    <Tab.Pane key={index} eventKey={item?.payment_type}>
                                                                        <Row>
                                                                            <Col sm={6}>
                                                                                {item?.full_name &&
                                                                                    <div className="name">
                                                                                        <h6>Name</h6>
                                                                                        <p>{item?.full_name}</p>
                                                                                    </div>
                                                                                }
                                                                                {item?.upi_id &&
                                                                                    <div className="user_payment_id mt-3">
                                                                                        <h6>UPI ID</h6>
                                                                                        <p className="d-flex align-items-center">
                                                                                            {item?.upi_id}<i className="ml-2 icon ion-md-copy"></i></p>
                                                                                    </div>
                                                                                }
                                                                            </Col>
                                                                            <Col sm={6}>
                                                                                {item?.bank_account_no &&
                                                                                    <div className="name">
                                                                                        <h6>Account Number</h6>
                                                                                        <p>{item?.bank_account_no}</p>
                                                                                    </div>
                                                                                }
                                                                                {item?.bank_ifsc_code &&
                                                                                    <div className="user_payment_id mt-3">
                                                                                        <h6>Bank IFSC Code</h6>
                                                                                        <p className="d-flex align-items-center">
                                                                                            {item?.bank_ifsc_code}
                                                                                            <i className="ml-2 icon ion-md-copy"></i></p>
                                                                                    </div>
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                    </Tab.Pane>
                                                                ))}

                                                            </Tab.Content>
                                                        </Col>
                                                    </Row>
                                                </Tab.Container>
                                            </div>
                                        }
                                        <h6 className="mt-3">Terms of Trade</h6>
                                        <div className="mt-2">
                                            <p>{tradeData?.terms_trade}</p>
                                        </div>
                                        <hr />
                                        {tradeAppeal &&
                                            <>
                                                < h6 className="mt-3">Your Appeal</h6>
                                                <div className="p2p_offer_cancelled_buying_section">
                                                    <ul className="d-flex justify-content-between pt-2">
                                                        <li>
                                                            <p>Date</p>
                                                            <h6 title="1">{moment(tradeAppeal?.created_date, 'YYYY-MM-DD HH:mm:ss').format(outputFormat)}</h6>
                                                        </li>
                                                        <li>
                                                            <p>Appeal Name</p>
                                                            <h6 title="230.19617">
                                                                {tradeAppeal?.name}
                                                            </h6>
                                                        </li>
                                                        <li>
                                                            <p>Status</p>
                                                            <h6 className='text-warning' title="0.004344120929553259">
                                                                {tradeAppeal?.status}</h6>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </>
                                        }

                                        <div className="cancelled_trades_right_btn d-sm-flex">

                                            {tradeData?.status == 2 && tradeData?.status_msg == "Payment made" ?
                                                <>

                                                    <>
                                                        {tradeData?.status == 2 && tradeData?.type == "Selling" &&

                                                            <button className="p2p_dlt_confirm_btn theme-btn-2 theme-btn text-center mb-3 mt-1 "
                                                                type="button"
                                                                id="payment_released"
                                                                data-id="1290"
                                                                onClick={sendOtp}
                                                            >
                                                                Release {tradeData?.coin.toUpperCase()}
                                                            </button>
                                                        }
                                                        {!tradeAppeal &&
                                                            <button className="p2p_limited_btn_bg theme-btn-2 theme-btn text-center mb-3 mt-1" type="button" id="appealBtn" data-id="1290" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="" data-original-title="Raise Appeal if find any unusual activity with your trade" onClick={() => { setAppeal(true) }}>Appeal</button>
                                                        }
                                                    </>
                                                    {/* } */}

                                                    {/* <>

                                                        <button className="p2p_limited_btn_bg theme-btn-2 theme-btn text-center mb-3 mt-1" type="button" id="appealBtn" data-id="1290" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="" data-original-title="Raise Appeal if find any unusual activity with your trade" onClick={() => { setAppeal(true) }}>Appeal</button>
                                                    </> */}
                                                </>

                                                : tradeData?.status == 2 ?
                                                    <>
                                                        {/* <button className="p2p_dlt_confirm_btn theme-btn-2 theme-btn text-center mb-3 mt-1 " type="button" id="payment_released" data-id="1290">Transferred, Notify
                                                        seller</button> */}


                                                        <button className="p2p_limited_btn_bg theme-btn-2 theme-btn text-center mb-3 mt-1" type="button" id="appealBtn" data-id="1290" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="" data-original-title="Raise Appeal if find any unusual activity with your trade">Appeal</button>
                                                    </> : tradeData?.status == 1 && tradeData?.type == "Buying" ?
                                                        (<>
                                                            <button className="p2p_dlt_confirm_btn theme-btn-2 theme-btn text-center mb-3 mt-1 " type="button" id="payment_released" data-id="1290"
                                                                onClick={() => setReleaseCoin(true)}
                                                            >
                                                                Transferred, Notify seller</button>
                                                            {/* <button className="p2p_limited_btn_bg theme-btn-2 theme-btn text-center mb-3 mt-1" type="button" id="appealBtn" data-id="1290" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="" data-original-title="Raise Appeal if find any unusual activity with your trade" onClick={() => { setAppeal(true) }}>Appeal</button> */}
                                                            <button className="p2p_cancel_btn_bg theme-btn-2 theme-btn text-center  mb-3 mt-1" type="button" id="cancel_trade"
                                                                data-id="1290"
                                                                onClick={() => handleShow(tradeData?.id, 2)}>
                                                                Cancel Trade
                                                            </button>
                                                        </>)

                                                        : tradeData?.status == 1 && tradeData?.type == "Selling" ?
                                                            <button className="p2p_cancel_btn_bg theme-btn-2 theme-btn text-center  mb-3 mt-1" type="button" id="cancel_trade"
                                                                data-id="1290"
                                                                onClick={() => handleShow(tradeData?.id, 1)}>
                                                                Cancel Trade
                                                            </button>
                                                            :
                                                            ""
                                            }
                                        </div>
                                        <hr />
                                        <div className="">
                                            <h6 className="mb-3">Payment method</h6>
                                            <ul className="p2p_cancelled_seller_feedback_right d-flex flex-wrap">

                                                <li className="p2p_list_style p2p_list_style_banktransfer">
                                                    {separatedStrings?.map((item) => {
                                                        return (
                                                            <label data-toggle="tooltip" data-placement="top" title="" data-original-title="Bank transfer">{item}</label>
                                                        )
                                                    })}

                                                </li>
                                            </ul>
                                        </div>


                                    </>


                                    {/* new page code */}

                                    {/* <div>

                                    <h4>Buying BNB dhdfrom <a href="#" target="_blank"><b>Tester105</b></a>
                                    </h4>



                                    <Row className="p2p_offer_cancelled mt-4 d-md-flex justify-content-between align-items-start pt-3">
                                        <div className="col-md-12 col-12">
                                            <ul className="d-sm-flex align-items-start align-items-center">
                                                <li className="mr-sm-3 mb-2 mb-sm-0"><img src={`${BASE_URL.BASE_URL}img/images/p2p_trade_escrow.png`} id="order_status_icon" width="50"></img></li>
                                                <li>
                                                    <div className="countdown_order_status_head d-flex align-items-center">
                                                        <h5 id="order_status_head">Make the Payment</h5>
                                                        <div className="ml-2 countdown">17:30</div>
                                                    </div>
                                                    <p id="order_status_subhead">Seller payment details are displaying below please
                                                        Complete payment for further processing.</p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-12 col-12 mt-4">
                                            <ul className="p2p_offer_cancelled_order_details text-md-right mt-3 mt-md-0 d-flex flex-wrap justify-content-between">
                                                <li className="text-left">Order number<br /><span>trade_eGS5DJ_655af93a98748_5533</span></li>
                                                <li className="text-left">Time
                                                    created<br /><span>20 Nov 2023 11:44 am</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </Row>

                                    <hr />

                                    <h6 className="mt-3">Order info</h6>
                                    <div className="p2p_offer_cancelled_buying_section">
                                        <ul className="d-flex justify-content-between pt-2">
                                            <li>
                                                <p>Amount</p>
                                                <h6 title="1"> $1</h6>
                                            </li>
                                            <li>
                                                <p>Price</p>
                                                <h6 title="230.19617">
                                                    $230.19617</h6>
                                            </li>
                                            <li>
                                                <p>Quantity</p>
                                                <h6 title="0.004344120929553259">0.004344 BNB </h6>
                                            </li>
                                        </ul>
                                    </div>

                                    <hr />

                                    <div className="" id="paymentDetailsDiv">
                                        <h6 className="my-3">Payment method</h6>



                                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                            <Row className="p2p_my_trade_tab_payment_main">
                                                <Col className="col-3 p2p_my_trade_tab_payment tab pr-0">
                                                    <Nav variant="pills" className="flex-column">
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="first"><img className="mr-2" src={`${BASE_URL.BASE_URL}img/images/upi.png`} width="30"></img> UPI</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="second"><img className="mr-2" src={`${BASE_URL.BASE_URL}img/images/imps.png`} width="30"></img> IMPS</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </Col>
                                                <Col className="col-9">
                                                    <Tab.Content className="py-3 px-2">
                                                        <Tab.Pane eventKey="first">
                                                            <Row>
                                                                <Col sm={6}>
                                                                    <div className="name">
                                                                        <h6>Name</h6>
                                                                        <p>105</p>
                                                                    </div>
                                                                    <div className="user_payment_id mt-3">
                                                                        <h6>UPI ID</h6>
                                                                        <p id="upi_id53" className="d-none">105@Upi</p>
                                                                        <p className="d-flex align-items-center">105@Upi <i className="ml-2 icon ion-md-copy"></i></p>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="second">
                                                            <Row>
                                                                <Col sm={6}>
                                                                    <div className="name">
                                                                        <h6>Name</h6>
                                                                        <p>105</p>
                                                                    </div>
                                                                    <div className="user_payment_id mt-3">
                                                                        <h6>UPI ID</h6>
                                                                        <p className="d-flex align-items-center">105@Upi <i className="ml-2 icon ion-md-copy"></i></p>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <div className="name">
                                                                        <h6>Account Number</h6>
                                                                        <p>123456</p>
                                                                    </div>
                                                                    <div className="user_payment_id mt-3">
                                                                        <h6>Bank IFSC Code</h6>
                                                                        <p className="d-flex align-items-center">1234567890 <i className="ml-2 icon ion-md-copy"></i></p>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>









                                        <h6 className="mt-3">Terms of Trade</h6>
                                        <div className="mt-2">

                                            <p> 1</p>
                                        </div>


                                        <div className="cancelled_trades_right_btn d-sm-flex">

                                            <button className="p2p_dlt_confirm_btn theme-btn-2 theme-btn text-center mb-3 mt-1 " type="button" id="payment_released" data-id="1290">Transferred, Notify
                                                seller</button>

                                            <button className="p2p_cancel_btn_bg theme-btn-2 theme-btn text-center  mb-3 mt-1" type="button" id="cancel_trade" data-id="1290">Cancel Trade</button>


                                            <button className="p2p_limited_btn_bg theme-btn-2 theme-btn text-center  d-none mb-3 mt-1" type="button" id="appealBtn" data-id="1290" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="" data-original-title="Raise Appeal if find any unusual activity with your trade">Appeal</button>

                                        </div>


                                        <hr />

                                        <div className="">
                                            <h6 className="mb-3">Payment method</h6>
                                            <ul className="p2p_cancelled_seller_feedback_right d-flex flex-wrap">

                                                <li className="p2p_list_style p2p_list_style_banktransfer">
                                                    <label data-toggle="tooltip" data-placement="top" title="" data-original-title="Bank transfer">Bank transfer</label>

                                                    <label data-toggle="tooltip" data-placement="top" title="" data-original-title="Cash deposit">Cash deposit</label>

                                                    <label data-toggle="tooltip" data-placement="top" title="" data-original-title="UPI">UPI</label>

                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div> */}




                                </div>
                            </>)}
                            <Chat tradeData={tradeData} tradeid={tradeid} />
                        </div>
                    </div>
                </Container>
            </section>

        </div >
    )
}

export default CreateOffer