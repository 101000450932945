import React from "react";
import BASE_URL from "../_constant/index";
import { Link } from 'react-router-dom';

const Commingsoon = () => {
  return (
    <div className="cooming_soon_main d-flex justify-content-center align-items-center">
      <section className="hero">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 text-center cooming_soon_main_inner">
              <h2 className="text-white">Get Notified When we Launch</h2>

              <div style={{ width: "100%" }} className="d-flex justify-content-center">
                <p className="my-3">
                  Don't miss on the amazing platform & opportunity that we have
                  for you. Get notified on our launch by clicking the 'Notify'
                  button below. We're eagerly waiting for you to become a part
                  of our family!
                </p>
              </div>

              <div className="mb-4 mt-5" style={{ width: "100%" }}>
                <Link to={BASE_URL.BASE_URL + "home"}>
                  <button className="btn btn-primary-v btn-custom px-4" type="button">
                    Notify Me
                  </button>
                </Link>
              </div>

              <img
                src={BASE_URL.BASE_URL + "img/images/coming_soon.svg"}
                className="img-fluid mt-5 dark-theme-img mx-auto"
                alt="img"
                width="600"
              />
              <img
                src={BASE_URL.BASE_URL + "img/images/coming_soon_dark.svg"}
                className="img-fluid mt-5 light-theme-img mx-auto"
                alt="img"
                width="600"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Commingsoon;
