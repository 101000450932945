import React from "react";
import BASE_URL from "../../_constant/index";
import { ThemeConsumer } from "../../context/ThemeContext";
const Introducing = () => {
  return (
    <>
      <section className="home_banner_section_v">
        <div className="container">
          <div className="row row-cols-1 row-cols-lg-2 row-cols-md-1 row-cols-sm-1 align-items-center gap-v-4">
            <div className="col pr-6rem">
              <div className="heading_v mb-4">
                <div className="banner_main_heading_v">
                  <h1>
                    World's First{" "}
                    <span className="text-orange fw-1"> Hybrid Crypto </span>
                    <span className="text-orange fw-1"> Trading Platform & Bank with Fixed Trading Fee of <br /> $0.25</span>
                  </h1>
                </div>
                <p className="my-4">
                  VYNKSAFE is a crypto exchange which has both centralised and decentralised crypto services along with crypto banking features. The services include trading, crypto savings account, p2p, launchpad, airdrops, swap & staking of crypto assets. 
                </p>
              </div>
              <div className="mt-5">
                <p>Download our app</p>
                <ul className="d-flex">
                <li>
                    <a className="btn btn-gray-v mr-3" href="https://apps.apple.com/in/app/vynksafe-crypto-app/id1626971774" target="_blank">
                      <img
                        src={BASE_URL.BASE_URL + "img/images/app_store.svg"}
                        // src="images/app_store.svg"
                        className="img-fluid"
                        alt="icon"
                        width="34"
                        height="34"
                      />
                    </a>
                  </li>
                  <li>
                    <a className="btn btn-gray-v" href="https://play.google.com/store/apps/details?id=com.vynksafe.app" target="_blank">
                      <img
                        src={BASE_URL.BASE_URL + "img/images/android_icon.svg"}
                        // src="images/android_icon.svg"
                        className="img-fluid"
                        alt="icon"
                        width="34"
                        height="34"
                      />
                    </a>
                  </li>
                  
                </ul>
              </div>
            </div>

            <div className="col pl-lg-5">
              <img
                src={BASE_URL.BASE_URL + "img/images/home_vector.svg"}
                // src="images/home_vector.svg"
                className="img-fluid dark-theme-img vert-move"
                alt="banner"
                width="560"
              />
              <img
                src={
                  BASE_URL.BASE_URL + "img/images/light-theme/home_vector.svg"
                }
                // src=""
                className="img-fluid light-theme-img"
                alt="banner"
                width="560"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Introducing;
