import React from "react";

const ShortFooter = () => {
  return (
    <>
      <div className="row py-3 footer_v_bottom">
        <div className="col">
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            {/* <ul className="d-flex flex-wrap align-items-center text-center justify-content-center">
              <li>
                <a href="javascript:void(0);">Terms & Conditions</a>
              </li>
              <li className="mx-2">|</li>
              <li>
                <a href="javascript:void(0);">Privacy Policy</a>
              </li>
              <li className="mx-2">|</li>
              <li>
                <a href="javascript:void(0);">FAQ's</a>
              </li>
            </ul> */}

            <p className="mb-0 mt-3 mt-lg-0 text-center">
              Copyright © 2023. VYNKSAFE. All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShortFooter;
