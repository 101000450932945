import React, { useState, useEffect } from 'react'
import { Accordion, Card, Button, Modal } from 'react-bootstrap'
import { deleteNotification } from '../../redux/apiActions/user.action'
import { useDispatch, useSelector, } from "react-redux";
import Moment from "react-moment";
import { useHistory } from 'react-router-dom'
import BASE_URL from '../../_constant/index'


const Notifaction = ({ notificationDataa, notification, readNotifications }) => {

    useEffect(() => {
        setNotificationData(() => {
            setNotificationData(notificationDataa?.notification)
        })
    }, [notificationDataa])

    const history = useHistory();
    const dispatch = useDispatch();
    let userData = useSelector((state) => state.user.userData);
    const params = new URLSearchParams(window.location.search);
    const toParam = params?.get("to");
    const [notificationData, setNotificationData] = useState()
    const [activeId, setActiveId] = useState(toParam ? toParam : null)
    const [show, setShow] = useState(false)
    const [id, setId] = useState(null)
    const deleteNotificationPopUp = (id) => {
        setShow(true)
        setId(id)
    }
    useEffect(() => {
        if (userData.access_token && notification) {
            notification();
        }
        setActiveId(toParam || null);
    }, [toParam])

    const readNotification = async (item) => {
        try {
            if (item?.read_status !== 0) {
                setActiveId(item?.id);
            }
            if (activeId == item?.id) {
                setActiveId(null);
            }
            readNotifications(item?.id)
            // history.push(BASE_URL.BASE_URL + "profile/notification")
        } catch (error) {
            console.log(error);
        }
    };


    const deleteNotificationFn = async () => {
        try {
            const data = new FormData();
            data.append('notification_id', id);
            const response = await deleteNotification(data);
            if (response?.data?.status === 200) {
                const index = notificationData?.findIndex((item) => item.id === id);
                const newNotificationData = [...notificationData?.slice(0, index), ...notificationData?.slice(index + 1),];
                setNotificationData(newNotificationData);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setShow(false);
        }
    };
    return (
        <>
            <div className="settings_top_title d-flex flex-wrap justify-content-between align-items-center mt-4">
                <div>
                    <h5 className="card-title mb-3">Notifications</h5>
                    {/* <p>Track your Notifications like Read, Notifications Activity, and others. </p> */}
                </div>
            </div>
            <div className="notifications">
                <Accordion defaultActiveKey={toParam || activeId}>
                    {notificationData && notificationData?.map((item, index) => {
                        return <Card className="pt-2 mb-0 pb-1" key={index}>
                            <Card.Header className="p-0">
                                <Accordion.Toggle as={Card.Header} variant="link" onClick={() => {
                                    readNotification(item)
                                    history.push(BASE_URL.BASE_URL + "profile/notification")
                                }} className={item?.read_status == 0 ? `white_accordian d-flex justify-content-between p-0` : `text-secondary text_decoration d-flex justify-content-between p-0`} eventKey={item?.id}>
                                    <div>
                                        <p style={{ fontSize: "16px" }}> <img src={`${BASE_URL.BASE_URL}img/dashboard/Advertising.png`} alt="" width="25" className="mt-0" /> {item?.subject}</p>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <Moment format="DD/MM/YYYY hh:mm A">
                                            {item?.create_date}
                                        </Moment>
                                        {activeId == item?.id ?
                                            <i className="icon ion-ios-arrow-up mt-1 ml-2 show"></i>
                                            :
                                            <i className="icon ion-ios-arrow-down mt-1 ml-2 show"></i>
                                        }
                                    </div>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={item?.id} className="mt-0">
                                <Card.Body className="pt-0 pb-0">
                                    <div style={{ position: "relative" }}>
                                        <img onClick={() => deleteNotificationPopUp(item.id)} src={`${BASE_URL.BASE_URL}img/dashboard/deleteicon.png`} style={{ position: "absolute", right: "-10px", cursor: "pointer" }} alt="Delete notification" width="20" />
                                    </div>
                                    <p className="text-secondary mt-0 pt-0 pl-3 pr-4">{item?.body}</p>
                                    {item?.image && <img src={item?.image} className="ml-3 mb-3" width="500px" style={{ borderRadius: "30px" }} />}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    })}
                    {notificationData?.length == 0 ? (<>
                        <div className="overview_no_open_orders text-center" style={{ height: "75vh" }}>
                            <div>
                                <img src={`${BASE_URL.BASE_URL}img/settings/notifications.png`} alt="no-open-order" />
                                <p className="mt-2">Notification Not Available Currently.</p>
                            </div>
                        </div>
                    </>) : null}
                </Accordion>
            </div>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                backdrop="static"
                keyboard={false}
                centered={true}
                className="withdrawal_confirmation_modal"
            >

                <Modal.Body className="text-center">
                    <h4 className="mt-4 mb-4">Are you sure ?</h4>
                    <p className="text-secondary"> Do you really want to delete this Notification ? <br /> This process cannot be
                        undone.</p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={deleteNotificationFn}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Notifaction