import React, { useState, useEffect, useMemo } from "react";
import BASE_URL from "../_constant/index";
import Marketswatch from "./pages/markets/Marketswatch";
import Footer from "./pages/Footer";
import { trending_Pairs_List } from "../redux/apiActions/user.action";
import utill from "../_constant/utill";
import { useHistory, generatePath } from "react-router-dom";
import { useSelector } from "react-redux";


const Market = ({ socket }) => {


  const history = useHistory();
  let userData = useSelector((state) => state.user.userData);
  const [loading , setLoading]= useState(false)

  let redirect_url = userData?.access_token
    ? `${BASE_URL.BASE_URL}Mywallet/Overview`
    : `${BASE_URL.BASE_URL}login?return_to=${btoa(
      BASE_URL.BASE_URL + "Mywallet/Overview"
    )}`;


  const [trendingList, TrendingList] = useState(null)
  const [topGainers, setTopGainers] = useState(null)

  const getTrending_Pairs_List = async () => {
    try {
      setLoading(true)
      const response = await trending_Pairs_List();
      if(response){
        setTimeout(()=>{
          setLoading(false)
        },500)
    
      }
      if (response.status == 200) {
        TrendingList(response?.trending_pair);
        setTopGainers(response?.gainer_list);
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getTrending_Pairs_List()
  }, []);



  return (
    <div className="content vynksafe_new_style mt-5 d-flex flex-column h-100">
      <section className="markets_overview_section_v pt-3">
        <div className="container mt-4">
          <div className="row mb-3">
            <div className="col">
              <h3>Markets Overview</h3>
            </div>
          </div>

          <div className="row row-cols-1 row-cols-xl-2 row-cols-md-1 row-cols-sm-1 align-items-center gap-v-4">
            <div className="col pr-lg-2">
              <div className="gray_bg_v p-3 pricing_table_v boxShadowforMarket">
                <h4>Trending</h4>
                {loading ?
                <div>
                  <div className="card">
                    {/* <div className="image-placeholder placeholder"></div>
                    <div className="title-placeholder placeholder"></div> */}
                    <div className="text-placeholder placeholder"></div>
                    <div className="text-placeholder placeholder"></div>
                    <div className="text-placeholder placeholder"></div>
                    <div className="text-placeholder placeholder"></div>
                  </div>
                </div>:
                 <table className="mt-2" style={{ width: "100%" }}>
                  <tbody>
                    {trendingList?.length > 0 &&
                      trendingList?.map((item, index) => {
                        return (
                          < tr className="text-white my-2" key={index}>
                            <td>
                              <img
                                src={item?.image}
                                className="img-fluid"
                                alt="coin"
                                width="25"
                              />{" "}
                              {item?.basename}
                              <span className="text-gray-v fs-5v ml-1">{item?.symbol}</span>
                            </td>
                            <td>
                              {parseFloat(item?.pair_price).toFixed(Math.abs(parseFloat(item?.pair_price)) < 1 ? 6 : 2)}
                            </td>
                            <td className="text-center">
                              {parseFloat(item?.per_changes) < 0 ?
                                <div style={{ color: "#FE4845", width: "50px" }}>
                                  {utill.beautifyNumber(parseFloat(parseFloat(item?.per_changes)), 2)}%
                                </div> :
                                <div style={{ width: "50px", color: "#04b98e" }}>
                                  +{utill.beautifyNumber(parseFloat(parseFloat(item?.per_changes)), 2)}%
                                </div>
                              }
                            </td>
                            <td className="text-right">
                              <button
                                className="btn text-white px-4 mr-1 fs-4v deposit-btn-v deposit_button"
                                onClick={() => {
                                  userData?.access_token ?
                                    history.push(generatePath(`${redirect_url}?to=deposit?coin=${item?.symbol}`)) :
                                    history.push(generatePath(`${redirect_url}`))
                                }}
                              >
                                Deposit
                              </button>
                              <button
                                className="btn text-white px-4 fs-4v withdraw-btn-v withdraw_button"
                                onClick={(e) => {
                                  userData?.access_token ?
                                    history.push(generatePath(`${redirect_url}?to=withdraw?coin=${item?.symbol}`)) :
                                    history.push(generatePath(`${redirect_url}`))
                                }}
                              >
                                Withdraw
                              </button>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table> }
              </div>
            </div>

            <div className="col pl-lg-2">
              <div className="gray_bg_v p-3 pricing_table_v boxShadowforMarket">
                <h4>Top Gainers</h4>
                {loading ?
                <div>
                  <div className="card">
                    {/* <div className="image-placeholder placeholder"></div>
                    <div className="title-placeholder placeholder"></div> */}
                    <div className="text-placeholder placeholder"></div>
                    <div className="text-placeholder placeholder"></div>
                    <div className="text-placeholder placeholder"></div>
                    <div className="text-placeholder placeholder"></div>
                  </div>
                </div>:
                <table className="mt-2" style={{ width: "100%" }}>
                  <tbody>
                    {topGainers?.length > 0 &&
                      topGainers?.map((item, index) => {
                        return (

                          < tr className="text-white my-2" key={index}>
                            <td>
                              <img
                                src={item?.image}
                                className="img-fluid"
                                alt="coin"
                                width="25"
                              />{" "}
                              {item?.basename}
                              <span className="text-gray-v fs-5v ml-1">{item?.symbol}</span>
                            </td>
                            <td>
                              {parseFloat(item?.pair_price).toFixed(Math.abs(parseFloat(item?.pair_price)) < 1 ? 6 : 2)}
                              {/* {utill.beautifyNumber(parseFloat(parseFloat(item?.pair_price)), parseFloat(item?.pair_price) < 0 ? 6 : 2)} */}
                            </td>
                            <td className="text-center">
                              {parseFloat(item?.per_changes) < 0 ?
                                <div style={{ color: "#FE4845", width: "50px" }}>
                                  {utill.beautifyNumber(parseFloat(parseFloat(item?.per_changes)), 2)}%
                                </div> :
                                <div style={{ width: "50px", color: "#04b98e" }}>
                                  +{utill.beautifyNumber(parseFloat(parseFloat(item?.per_changes)), 2)}%
                                </div>
                              }
                            </td>
                            <td className="text-right">
                              <button
                                className="btn text-white px-4 mr-1 fs-4v deposit-btn-v deposit_button"
                                onClick={() => {
                                  userData?.access_token ?
                                    history.push(generatePath(`${redirect_url}?to=deposit?coin=${item?.symbol}`)) :
                                    history.push(generatePath(`${redirect_url}`))
                                }}
                              >
                                Deposit
                              </button>
                              <button
                                className="btn text-white px-4 fs-4v withdraw-btn-v withdraw_button"
                                onClick={(e) => {
                                  userData?.access_token ?
                                    history.push(generatePath(`${redirect_url}?to=withdraw?coin=${item?.symbol}`)) :
                                    history.push(generatePath(`${redirect_url}`))
                                }}
                              >
                                Withdraw
                              </button>
                            </td>
                          </tr>
                        )
                      })

                    }
                  </tbody>
                </table>}
              </div>
            </div>
          </div>
        </div>
      </section >
      <Marketswatch socket={socket} />
      <hr className="mb-0 dark-gray-bg-v" />
      <Footer logo="Show" />
    </div >
  );
};

export default Market;
