import React, { useState } from 'react'
import { Badge } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from "react-redux";
import InputGroup from 'react-bootstrap/InputGroup';
import BASE_URL from "../../_constant/index";
import BASE_URL_Tow from "../../_constant/index";

import { Link } from 'react-router-dom'
const RightMenu = ({ dashBoardUserData }) => {
    let userData = useSelector((state) => state.user.userData);
    const NickName = dashBoardUserData?.nick_name ? dashBoardUserData?.nick_name : userData?.nick_name
    return (

        <div>
            <div className="card">
                <div className="card-body profile-card-body p-3">
                    <div className="profile_card">
                        <div className="d-flex  justify-content-between">
                            <div className="d-flex align-items-center justify-content-center">
                                {userData?.profile_pic_path ?
                                    <img
                                        src={BASE_URL_Tow.BASE_URL_Tow + userData?.profile_pic_path}
                                        alt="Avtar"
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            borderRadius: '50%',
                                            background: "transparent",
                                            border: "1px solid #13172200 ",
                                        }}
                                    /> :
                                    <img src={BASE_URL.BASE_URL + "img/avatar.svg"} width="50px" style={{ borderRadius: "50%" }} />
                                }
                                <div className="p-2 ml-1">
                                    <h5 className="card-title mb-0" style={{ fontSize: "16px" ,marginBottom:"0px" }}>{userData.full_name ? userData.full_name : userData?.username}</h5>
                                    <Badge bg="secondary" className="profile-status-personal" style={{ fontSize: "12px", fontWeight: 100 }}>Personal</Badge>
                                </div>
                            </div>
                            <div>
                                <img src={`${BASE_URL.BASE_URL}img/dashboard/profile-verify.png`} alt="" className="mr-2 mt-2" width="20" />
                                <Link to={BASE_URL.BASE_URL + "profile/settings"} className="dashboard-profile-edit"><img src={`${BASE_URL.BASE_URL}img/dashboard/edit.png`} alt="" className="mt-2" width="15" /></Link>
                            </div>
                        </div>
                        <hr />
                        <div>
                            <ul style={{ color: "#6e7277", fontSize: "12px" }}>
                                <li >
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            User ID
                                        </div>
                                        <div className="d-flex justify-content-center light_text">

                                            {userData?.username}
                                        </div>
                                    </div>
                                </li>
                                {userData?.nick_name &&
                                    <li>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                                Nick Name
                                            </div>
                                            <div className="d-flex justify-content-center light_text">
                                                {NickName}
                                            </div>
                                        </div>
                                    </li>
                                }
                                {/* <li>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            Last login time
                                        </div>
                                        <div className="d-flex justify-content-center text-light">
                                            123/45/34 (1322)
                                        </div>
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="explore mt-4">
                <h5 className="card-title mb-2">Explore</h5>
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Recipient's username"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                    />
                    <InputGroup.Text id="basic-addon2" className="explore_search_bar" style={{ borderRadius: "0px 4px 4px 0px" }}><img src={`${BASE_URL.BASE_URL}img/dashboard/search.png`} alt="" width="18" /></InputGroup.Text>
                </InputGroup>
            </div> */}
            <div className="Announcement mt-4">
                {/* <h5 className="card-title mb-2">Announcement</h5>
                <div className="announcements-section mt15">
                    <div className="d-flex align-items-start pr-3">
                        <img src={`${BASE_URL.BASE_URL}img/dashboard/announcements.png`} alt="" width="32" className="mt-1" />
                        <div className="pl-3 settings-notification">
                            <p>Aliquam nulla metus, commodo sit amet metus sit amet, viverra scelerisque tellus.</p>
                            <span>2023-02-10</span>
                        </div>
                    </div>
                    <i className="ion ion-ios-arrow-forward"></i>
                </div> */}
                {/* 
                <div className="announcements-section mt15">
                    <div className="d-flex align-items-start pr-3">
                        <img src={`${BASE_URL.BASE_URL}img/dashboard/announcements.png`} alt="" width="32" className="mt-1" />
                        <div className="pl-3 settings-notification">
                            <p>Aliquam nulla metus, commodo sit amet metus sit amet, viverra scelerisque tellus.</p>
                            <span>2023-02-10</span>
                        </div>
                    </div>
                    <i className="ion ion-ios-arrow-forward"></i>
                </div> */}

            </div >
        </div >
    )
}

export default RightMenu