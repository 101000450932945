import React, { useState, useEffect } from 'react'
import {
    Row, Container, Modal, Button, OverlayTrigger, Popover
} from 'react-bootstrap'
import BASE_URL from "../../_constant/index";
import utill from "../../_constant/utill";
import { walletBalance_transfer, offerDetailsByID, getFundWalletBalance, gtePaymentMethods, create_Offer, user_Payment_Details, get_payment_TimeList, edit_offer, add_Payment_Details } from '../../redux/apiActions/p2p.action'
import Select from "react-select";
import { useSelector } from "react-redux";
import { toast } from "../../components/Toast/Toast";
import { useHistory, Link, useLocation, } from 'react-router-dom';
import BASE_URL_Tow from '../../_constant/index'
import TransferFind from './TransferFind';


const UpdatingPopover = React.forwardRef(
    ({ popper, children, show: _, ...props }, ref) => {
        useEffect(() => {
            popper.scheduleUpdate();
        }, [children, popper]);

        return (
            <Popover ref={ref} content {...props}>
                {children}
            </Popover>
        );
    },
);

const longContent = `
set margin percentage for orders above/below market price
`;
const shortContent = `
set margin percentage for orders above/below market price
`;


const CreateNewOrder = ({ countryList, currency, addNewPaymentMethods }) => {


    let userData = useSelector((state) => state.user.userData);
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const userValueId = queryParams.get('id');
    const userBank = queryParams.get('bank');

    const [step, setStep] = useState(1);
    const [selectedCountryList, setSelectedCountryList] = useState(null);
    const [selectedCurrency, setSelectedCurrency] = useState(null)
    const [coinList, setCoinList] = useState(null);
    const [coinData, setCoinData] = useState();
    const [isChecked, setChecked] = useState(false);
    const [amount, setAmount] = useState("");
    const [balance, setBalance] = useState("")
    const [paymentMethod, setPaymentMethod] = useState();
    const [selectedMethod, setSelectedMethod] = useState([])
    const [selectedMethodForSell, setSelectedMethodForSell] = useState([])
    const [selectedMethodChecked, setSelectedMethodChecked] = useState([])
    const [percentage, setPercentage] = useState("1");
    const [margin, setMargin] = useState();
    const [maxLimit, setMaxLimit] = useState();
    const [minLimit, setMinLimit] = useState();
    const [timeLimit, setTimeLimit] = useState()
    // const [TimesLimit, setTimesLimitValue] = useState("")
    const [name, setName] = useState("")
    const [terms, setTerms] = useState("")
    const [loading, setLoading] = useState(false);
    const [bankList, setBankList] = useState()
    const [show, setShow] = useState(false);
    const [selectedBank, setSelectedBank] = useState();
    const [offerDetails, setOfferDetails] = useState(null);
    const [timeLimitList, setTimeLimitList] = useState(null)
    const [selectedBankData, setSelectedBankData] = useState([]);

    const [CoinPrice, setCoinPrice] = useState()
    const [newCoinPrice, setNewCoinPrice] = useState()


    useEffect(() => {
        if (currency) {
            setSelectedCurrency(currency[0])
        }
    }, [currency])

    useEffect(() => {
        if (userValueId) {

            if (offerDetails && coinList && countryList && currency) {
                if (offerDetails?.offer_price) {
                    setTimeout(() => {
                        setNewCoinPrice(offerDetails?.offer_price)
                    }, 200);
                }

                const defaultCoin = coinList?.find((coin) => coin.code === offerDetails.coin);
                if (defaultCoin) {
                    setCoinData(defaultCoin);
                }

                const defaultLocation = countryList?.find((country) => country.label === offerDetails.location);
                if (defaultLocation) {
                    setSelectedCountryList(defaultLocation);
                }

                const defaultCurrency = currency?.find((curr) => curr.value === offerDetails.currency_value);
                if (defaultCurrency) {
                    setSelectedCurrency(defaultCurrency);
                }
                const defaultTime = timeLimitList?.find((time) => time.value === offerDetails.payment_time_limit);
                if (defaultTime) {
                    setTimeLimit(defaultTime);
                }
                // ()
                const initialSelectedMethods = offerDetails?.payment_type?.split(',');


                if (offerDetails?.payment_details && offerDetails?.trade_type == "2") {
                    const paymentDetailsArray = JSON.parse(offerDetails?.payment_details);

                    // Extract payment_type and payment_method_id values from paymentDetailsArray
                    const selectedMethods = paymentDetailsArray?.map((item) => ({
                        payment_type: item?.payment_type,
                        payment_method_id: item?.payment_method_id,
                    }));

                    // Update selectedMethodForSell state with the extracted data
                    setSelectedMethodForSell(selectedMethods);
                }
                if (offerDetails?.payment_details) {
                    const initialSelectedMethod = offerDetails.payment_ids.split(',');
                    setSelectedMethod(initialSelectedMethod.map(String));
                }

                // setSelectedMethod([offerDetails?.payment_ids])

                // (offerDetails?.payment_type)

                setChecked(offerDetails?.trade_type == "2" ? true : false)
                setMargin(offerDetails?.margin_percentage);
                setPercentage(offerDetails?.margin_type);
                setMaxLimit(offerDetails?.max_trade_size);
                setMinLimit(offerDetails?.min_trade_size);
                setName(offerDetails?.nick_name)
                setTerms(offerDetails?.terms_trade);

                setAmount(parseFloat(offerDetails?.total_amount))
                setSelectedMethodChecked(initialSelectedMethods)
            }
        }
        // Check if offerDetails is available and set default values if present


    }, [offerDetails, coinList, countryList, currency]);

    const handleTimeChange = (selectedOption) => {
        setTimeLimit(selectedOption); // Update the selected currency state
    };


    const paymentTimeList = async () => {
        try {
            const response = await get_payment_TimeList()
            if (response.status == 200) {
                const formattedPaymentMethods = response?.data?.map((Methods) => ({
                    label: Methods.payment_time,
                    value: `${Methods.id}`,
                }));
                setTimeLimitList(formattedPaymentMethods)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        paymentTimeList()
    }, [])


    const getOfferDetailsByID = async (userValueId) => {
        try {
            const formData = new FormData()
            formData.append("offer_id", userValueId)
            const response = await offerDetailsByID(formData);

            if (response.status == 200) {
                setOfferDetails(response.data[0])
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (userValueId) {
            getOfferDetailsByID(userValueId)
        }
    }, [userValueId])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const selectBank = (e) => {
        setSelectedBank(e.target.value)
    }
    useEffect(() => {
        setName(userData?.nick_name)
    }, [userData])


    const addPaymentMethod = () => {

        const selectedData = bankList.find((item) => item.id == selectedBank)

        const existingIndex = selectedBankData.findIndex((data) => data.payment_type === selectedData?.payment_type);

        if (existingIndex !== -1) {
            // If payment_type exists, replace the old data
            const updatedData = [...selectedBankData];
            updatedData[existingIndex] = { payment_type: selectedData?.payment_type, user_id: selectedData?.user_id, id: selectedData?.id };
            setSelectedBankData(updatedData);
        } else {
            // If payment_type does not exist, add a new entry
            setSelectedBankData([...selectedBankData, { payment_type: selectedData?.payment_type, user_id: selectedData?.user_id, id: selectedData?.id }]);
        }
        handleClose();
    }

    const handlePrevious = () => {
        setStep(step - 1);
    };

    const handlePercentage = (event) => {
        setPercentage(event.target.value);
    };


    const getUserBalance = async (coin) => {
        try {
            const formData = new FormData()
            formData.append("coin", coin)
            const response = await getFundWalletBalance(formData);
            if (response.status == 200) {
                setBalance(response?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const handlePaymentCheckbox = async (event) => {

        const { name, value, checked } = event.target;
        const stringValue = String(value);

        if (!isChecked) {

            if (checked && !selectedMethod.includes(stringValue)) {
                setSelectedMethodChecked([...selectedMethodChecked, name]);
                setSelectedMethod([...selectedMethod, stringValue]);
            } else {
                // If the checkbox is unchecked, remove the value from the state
                const updatedCheckedMethods = selectedMethodChecked?.filter(method => method !== name);
                setSelectedMethodChecked(updatedCheckedMethods);
                const updatedMethods = selectedMethod?.filter(method => method !== value);
                setSelectedMethod(updatedMethods);
            }
        } else {

            if (checked && !selectedMethod.includes(stringValue)) {
                const formData = new FormData();
                formData.append("payment_id", value);
                const response = await user_Payment_Details(formData);

                if (response.status === 200) {
                    setBankList(response?.data);
                    setSelectedBank(response?.data[0]?.id);
                    handleShow();
                }

                setSelectedMethod([...selectedMethod, value]);
                setSelectedMethodChecked([...selectedMethodChecked, name]);
                setSelectedMethodForSell([...selectedMethodForSell, { payment_type: value }]);

            } else {
                const updatedCheckedMethods = selectedMethodChecked.filter(method => method !== name);
                const updatedMethodsForSell = selectedMethodForSell.filter((method) => method.payment_type !== value);
                const updatedMethods = selectedMethod.filter(method => method !== value);

                setSelectedMethod(updatedMethods);
                setSelectedMethodChecked(updatedCheckedMethods);
                setSelectedMethodForSell(updatedMethodsForSell);
            }
        }
    };


    useEffect(() => {
        const updateData = () => {
            const combinedData = selectedBankData?.map((selectedItem) => {
                const matchingItem = selectedMethodForSell.find((newItem) => newItem.payment_type === selectedItem.payment_type);

                if (matchingItem) {
                    return {
                        payment_type: selectedItem.payment_type,
                        payment_method_id: matchingItem.id || selectedItem.id, // Use id from matchingItem, or fallback to id from selectedItem
                    };
                }

                return null;
            }).filter(Boolean);

            const missingItems = selectedMethodForSell.filter((newItem) => !combinedData.some((item) => item.payment_type === newItem.payment_type));
            const updatedData = [...combinedData, ...missingItems];

            setSelectedMethodForSell(updatedData);
        };

        // Call the updateData function, but only if the dependency arrays have changed
        updateData();
    }, [selectedBankData]); // Only run when these dependencies change

    const paymentMethods = async () => {
        try {
            setPaymentMethod()
            const formData = new FormData()
            formData.append("get_all", isChecked ? 2 : 1)
            const response = await gtePaymentMethods(formData);

            if (response.status == 200) {
                setPaymentMethod(response?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        paymentMethods();
    }, [isChecked])

    const handleCheckboxChange = () => {
        setChecked(!isChecked); // Toggle the checkbox value
        setSelectedMethod([]);
        setSelectedMethodForSell([]);
        setSelectedMethodChecked([])
    };

    const [errorsMsg, setErrorMsg] = useState({
        coinDataError: "",
        countryError: "",
        currencyError: "",
        amountError: "",
        selectedMethodError: "",
        marginError: "",
        maxLimitError: "",
        minLimitError: "",
        minTimeLimitError: "",
        nameError: "",
        termsError: "",
    });


    const handleNext = () => {
        if (step === 1) {
            if (!coinData?.code || !selectedCurrency || !selectedCountryList) {
                setErrorMsg({
                    coinDataError: !coinData.code ? "Please select any assets." : "",
                    countryError: !selectedCountryList ? "Please select any location." : "",
                    currencyError: !selectedCurrency ? "Please select any currency." : "",
                });
                return; // No step increment here
            }
        }
        else if (step === 2) {
            const errors = {

                amountError: isChecked
                    ? !amount
                        ? "Value required and should not be 0."
                        : parseFloat(amount) > parseFloat(balance?.available_coin)
                            ? `Total Amount should not exceed ${balance.available_coin} ${balance?.coin} or ${coinData?.max_trade_amount} USD`
                            : parseFloat(amount) * parseFloat(coinData?.price) < 1
                                ? `Total Amount should be greater than or equal to ${parseFloat(coinData?.min_trade_amount)} USD`
                                : ""
                    : !amount ? "Value required and should not be 0." : "",

                // amountError: !amount ? "Value required and should not be 0." : "",
                selectedMethodError: !selectedMethod || selectedMethod.length === 0 ? "Choose at least one payment method." : "",
                // marginError: !margin ? "Margin field is required." : "",
                maxLimitError: !maxLimit ? "Field is required" : minLimit > maxLimit ? "Minimum order limit should not be greater than maximum order limit" : "",
                minLimitError: !minLimit ? "Field is required." : minLimit > maxLimit ? "Minimum order limit should not be greater than maximum order limit" : "",
            };

            setErrorMsg(errors);

            // Check if there are any errors before proceeding to the next step
            if (Object.values(errors).some((error) => !!error)) {
                return;
            }
        }
        setStep(step + 1);
    };


    const createOffer = async () => {
        const url = !userValueId ? create_Offer : edit_offer
        const payment_method = isChecked ? JSON.stringify(selectedMethodForSell) : selectedMethod;
        setLoading(true)
        try {
            if (!timeLimit || !name || !terms) {
                setErrorMsg({
                    minTimeLimitError: !timeLimit ? "please select payment time limit" : "",
                    nameError: !name ? "Field is required." : name?.length < 3 ? "Nickname should be at least 3 and max 10 characters" : "",
                    termsError: !terms ? "Field is required." : "",
                });
                setLoading(false)
                return;
            }
            const formData = new FormData()
            formData.append("trade_type", isChecked ? 2 : 1) //buy = 1 sell =2
            formData.append("coin", coinData?.code);
            // formData.append("network_type",)
            formData.append("currency_id", selectedCurrency?.value);
            formData.append("location", selectedCountryList?.label);
            formData.append("country_id", selectedCountryList?.value);
            formData.append("payment_methods", payment_method);
            formData.append("margin_percentage", margin ? margin : "0");
            formData.append("margin_type", percentage);
            formData.append("nick_name", name || "");
            formData.append("terms_trade", terms);
            formData.append("min_trade_size", minLimit);
            formData.append("max_trade_size", maxLimit);
            formData.append("payment_time_limit", timeLimit?.value);
            formData.append("total_amount", amount);
            {
                userValueId &&
                    formData.append("offer_id", userValueId);
            }

            const response = await url(formData);

            if (response?.status == 200) {
                setLoading(false);
                toast?.successes(response?.message || response?.data?.message);
                history.push(`${BASE_URL.BASE_URL}p2p/My_offer`);
                setStep(1);
                setChecked(false);
                setCoinData(null);
                setSelectedCurrency(null);
                setSelectedCountryList(null);
                setSelectedMethod([]);
                setSelectedMethodChecked([]);
                setPaymentMethod();
                setMargin("");
                setPercentage("1");
                setMaxLimit("");
                setMinLimit("");
                setTerms("");
                setTimeLimit("");
                setAmount("");
                setSelectedMethodForSell([]);
            } else {
                toast?.errors(response?.message || response?.data?.message)
                setLoading(false)
                // {
                //     userValueId &&
                //         history.push(`${BASE_URL.BASE_URL}p2p/My_offer`);
                // }
            }
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(async () => {
        const fetchData = async () => {
            try {
                const response = await walletBalance_transfer();
                if (response.status === 200) {
                    const formattedPaymentMethods = response?.data?.map((Methods) => ({
                        value: Methods?.code,
                        code: Methods?.code,
                        image: Methods?.image,
                        type: Methods?.coin_type,
                        min_trade_amount: Methods?.min_trade_amount,
                        max_trade_amount: Methods?.max_trade_amount,
                        price: Methods?.price,
                        type: Methods?.coin_type,
                        label: Methods?.symbol,
                        coin_currency_price: Methods?.coin_currency_price,
                        contract_address: Methods?.contract_address,
                        network_type: Methods?.network_type,
                        symbol: Methods?.symbol
                    }));

                    setCoinList(formattedPaymentMethods);
                    // setCoinList(response.data);

                    if (formattedPaymentMethods) {
                        setCoinData(formattedPaymentMethods[0])
                    }

                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);



    useEffect(() => {
        const selectedCurrencyPrice = coinData?.coin_currency_price.find(
            (currency) => currency.currency_id === selectedCurrency?.value
        );
        setCoinPrice(selectedCurrencyPrice)
        if (margin) {
            const percentageDecimal = parseFloat(margin) / 100;
            const sign = percentage === "1" ? 1 : -1;
            const newPrice = CoinPrice?.price * (1 + sign * percentageDecimal);
            setNewCoinPrice(newPrice);
        } else {
            setNewCoinPrice(selectedCurrencyPrice?.price)
        }

    }, [coinData, selectedCurrency, margin, percentage, amount])

    const setCoinValue = async (val) => {
        setCoinData(val);
        getUserBalance(val?.code);
        setAmount("");
        setMaxLimit("");
    };

    const handleCountryListChange = (selectedOption) => {
        setSelectedCountryList(selectedOption); // Update the selected currency state
    };
    const handleCurrencyChange = (selectedOption) => {
        setSelectedCurrency(selectedOption); // Update the selected currency state
    };

    function isNumberKey(value) {
        value = value ? value : "";
        // alert(value)
        var validNumber = new RegExp(/^[0-9]*(\.[0-9]*)?$/);
        if (validNumber.test(value)) {
            return value;
        } else {
            return false;
        }
    }
    const handleInputChange = (e) => {
        let value = isNumberKey(e.target.value);
        if (value !== false) {
            setAmount(value);
            setMaxLimit(utill.beautifyNumber(newCoinPrice * value), 2);
        }

    };

    const handleMarginChange = (e) => {
        let value = isNumberKey(e.target.value);
        if (value !== false) {
            setMargin(value)
        }
    };

    const [content, setContent] = useState(shortContent);

    useEffect(() => {
        const timerId = setInterval(() => {
            setContent(content === shortContent ? longContent : shortContent);
        }, 3000);

        return () => clearInterval(timerId);
    });
    // const [path, setPath] = useState("");
    const [addBank, setAddBank] = useState(false);
    const [errors, setErrors] = useState({});
    const [addNewBankSelectedMethod, setAddNewBankSelectedMethod] = useState();
    const [upiId, setUpiId] = useState("")
    const [BankData, setBankData] = useState({
        name: "",
        bankName: "",
        accountNo: "",
        ifscCode: "",
        // upiID: ""
    })


    // const [AddPaymentMethod, setAddPaymentMethod] = useState();

    const setAddCoinValue = async (val) => {
        setAddNewBankSelectedMethod(val)
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setBankData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const addNewBankPaymentMethod = async () => {
        var reg = /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/;
        try {
            const validationErrors = {};
            if (!addNewBankSelectedMethod) {
                validationErrors.paymentMethod = 'Please select a payment method.';
            }

            if (!BankData.name) {
                validationErrors.fullName = 'Please enter your full name.';
            }
            if (addNewBankSelectedMethod?.label === 'UPI') {
                // Only validate UPI-related fields if the selected method is UPI
                if (!upiId) {
                    validationErrors.upiId = 'Please enter your UPI id.';
                } else if (!/^[\w.-]+@[\w.-]+$/.test(upiId)) {
                    // Validate UPI ID using a regex pattern
                    validationErrors.upiId = 'Invalid UPI ID format.';
                }
            } else {
                // Validate other bank-related fields if the selected method is not UPI
                if (!BankData?.bankName) {
                    validationErrors.bankName = 'Please enter your bank name.';
                }
                if (!BankData?.accountNo) {
                    validationErrors.accountNo = 'Please enter your account no';
                }
                if (!BankData?.ifscCode) {
                    validationErrors.ifscCode = 'Please enter IFSC code.';
                } else if (!BankData?.ifscCode.match(reg)) {
                    // Validate IFSC code using a regex pattern
                    validationErrors.ifscCode = 'Invalid IFSC Code format.';
                }
            }

            if (Object.keys(validationErrors)?.length > 0) {
                // If there are validation errors, update the errors state and return
                setErrors(validationErrors);
                return;
            }


            setLoading(true)
            const data = new FormData();
            data.append("payment_type", addNewBankSelectedMethod?.type || "")
            data.append("bank_name", BankData?.bankName || "")
            data.append("bank_account_no", BankData?.accountNo || "")
            data.append("bank_ifsc_code", BankData?.ifscCode || "")
            data.append("full_name", BankData?.name)
            data.append("upi_id", upiId || "")

            const response = await add_Payment_Details(data)
            if (response.status == 200) {
                toast?.successes(response?.message || response?.data?.message);
                paymentMethods();
                // history.push(`${BASE_URL.BASE_URL}p2p/Orders?to=p2p_create_offer&bank=bankdata`);
                // paymentDetails();
                setUpiId("");
                setAddNewBankSelectedMethod(null);
                setBankData({
                    name: "",
                    bankName: "",
                    accountNo: "",
                    ifscCode: "",
                });
                setErrors({})
                setLoading(false)
                setAddBank(false)
            } else {
                toast?.errors(response?.message || response?.data?.message)

                setErrors({})
                setLoading(false)
                setAddBank(false)
            }
            handleClose()
        } catch (error) {
            console.log(error)

        }
    }


    const [showTransfer, setShowTransfer] = useState(false);

    return (
        <div className="create_new_order_page">
            <Modal
                centered
                show={showTransfer}
                onHide={() => setShowTransfer(false)}
                className="p2pOfferViewModal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Transfer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TransferFind
                        getUserBalance={getUserBalance}
                        coinList={coinList}
                        selectCoinData={coinData}
                        setShowTransfer={setShowTransfer}
                        currency={currency}
                        selectedCurrency={selectedCurrency}
                    />
                </Modal.Body>

            </Modal>
            <Modal show={addBank} centered onHide={() => { setAddBank(false) }} animation={false} className="p2pOfferViewModal">
                <Modal.Header closeButton>
                    <Modal.Title><h5>Add payment Method</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group text-left">
                        <label>Select Payment Method</label>
                        <Select
                            placeholder='Select Payment Method'
                            className="wallet_network_select custom_Curency-box"
                            classNamePrefix="custom_select"
                            isSearchable={true}
                            name='Assets'
                            options={addNewPaymentMethods}
                            value={addNewBankSelectedMethod}
                            onChange={(e) => {
                                setAddCoinValue(e);
                            }}

                            getOptionValue={(option) => option?.value}
                            getOptionLabel={(option) => (
                                <div className='d-flex align-items-center'>
                                    <img src={`${BASE_URL_Tow.BASE_URL_Tow}${option?.image}`} alt={option?.label} width='25' className='mr-2' />
                                    {option.label}
                                </div>
                            )}

                        />
                        <p className="text-danger" id="p2p_location_error">{errors?.paymentMethod}</p>
                        {addNewBankSelectedMethod &&
                            <>
                                <div className="border my-4"></div>
                                <div className="form-group text-left">
                                    <label>Full Name</label>
                                    <input
                                        type="text"
                                        value={BankData.name}
                                        name="name"
                                        onChange={handleChange}
                                        className="form-control"
                                        placeholder="Full Name"
                                        autoComplete="off"
                                        maxLength="30" />
                                    <p className="text-danger" id="p2p_location_error">{errors?.fullName}</p>
                                </div>
                                {addNewBankSelectedMethod?.label == "UPI" ? (<>
                                    <div className="form-group text-left">
                                        <label>UPI ID</label>
                                        <input
                                            type="text"
                                            value={upiId}
                                            name="upiID"
                                            onChange={(e) => { setUpiId(e.target.value) }}
                                            className="form-control"
                                            id="edit_upi_id" placeholder="UPI ID" autoComplete="off" maxLength="45" />
                                        <p className="text-danger" id="p2p_location_error">{errors?.upiId}</p>
                                    </div>
                                </>) : (<>
                                    <div className="form-group text-left">
                                        <label>Bank Name</label>
                                        <input type="text"
                                            name="bankName"
                                            onChange={handleChange}
                                            value={BankData.bankName}
                                            className="form-control" placeholder="Bank Name" autoComplete="off" maxLength="30" />
                                        <p className="text-danger" id="p2p_location_error">{errors?.bankName}</p>
                                    </div>

                                    <div className="form-group text-left">
                                        <label>Bank Account Number</label>
                                        <input type="text"
                                            name="accountNo"
                                            onChange={handleChange}
                                            value={BankData.accountNo}
                                            className="form-control"

                                            id="edit_upi_id"
                                            placeholder="Bank Account Number"
                                            autoComplete="off"
                                            maxLength="45" />
                                        <p className="text-danger" id="p2p_location_error">{errors?.accountNo}</p>
                                    </div>
                                    <div className="form-group text-left">
                                        <label>IFSC Code</label>
                                        <input
                                            type="text"
                                            name="ifscCode"
                                            onChange={handleChange}
                                            value={BankData.ifscCode}
                                            className="form-control"
                                            id="edit_upi_id"
                                            placeholder="IFSC Code"
                                            autoComplete="off"
                                            maxLength="45"
                                        />
                                        <p className="text-danger" id="p2p_location_error">{errors?.ifscCode}</p>
                                    </div>

                                </>)}
                            </>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary"
                        disabled={loading ? true : false}
                        onClick={addNewBankPaymentMethod}
                        className="p2p_dlt_confirm_btn theme-btn-2 theme-btn">
                        {loading ? "Please wait" : "Add"}
                    </Button>

                    <Button variant="secondary" onClick={() => { setAddBank(false) }} className="btn disable_btn_bg harvest_btn_pools theme-btn-2 theme-btn">
                        Cancel
                    </Button>

                </Modal.Footer>
            </Modal>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="withdrawal_confirmation_modal"
                centered
            >

                <Modal.Body>
                    <div className="set_amount_payment_method_section w-100 float-left">
                        <h6>Select Your Payment Account</h6>
                        <div className="p2p_create_offer_select_location mt-3 w-100">
                            <label>
                                <select
                                    name="payment_time_limit"
                                    value={selectedBank}
                                    onChange={selectBank}
                                    id="payment_time_limit" required="">
                                    {bankList?.length > 0 &&
                                        bankList?.map((item, index) => {
                                            return (
                                                <option key={index} value={item?.id} data-name={item?.name}>
                                                    {item?.bank_name || item?.upi_id} {item?.bank_account_no && (item?.bank_account_no)}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        disabled={loading ? true : false}
                        variant="primary"
                        onClick={(e) => { addPaymentMethod() }}
                    // onClick={handleClose}
                    >
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>

            <section className="p2p_main_banner_section py-5 p2p_mainTable">
                <Container>
                    <Row className="mt-3 p2p_buy_create_offer_main">

                        <div className="card">
                            <div className="form main_signin">
                                <div className="col-lg-7 col-12">
                                    <div className="left-side">
                                        <div claisCheckeBuyingssName="">
                                            <ul className="steps">
                                                <li className="li-active d-md-flex align-items-center text-center text-md-left">Offer
                                                    category</li>
                                                <li className={`${step >= 2 && "li-active "} d-md-flex align-items-center text-center text-md-left`}> Payment details
                                                </li>
                                                <li className={`${step >= 3 && "li-active "} d-md-flex align-items-center text-center text-md-left`}>Ad details</li>
                                            </ul>
                                            {step === 1 && (
                                                <div className="main step_1_btn mt-3">
                                                    <h6 className="mb-3">Type of offer</h6>
                                                    <div className="switch6 mb-4">
                                                        <label className="switch6-light" >
                                                            <input type="checkbox"
                                                                id="trade_type"
                                                                checked={isChecked}
                                                                onChange={handleCheckboxChange}
                                                            />

                                                            <span>
                                                                <span>Buy</span>
                                                                <span>Sell</span>
                                                            </span>
                                                            <a className="btn btn-primary"></a>
                                                        </label>
                                                    </div>
                                                    <hr />



                                                    <Row className="mb-3" data-select2-id="9">
                                                        <div className="col-md-9 mb-2" data-select2-id="8">
                                                            <h6 className="my-3">Choose Assets</h6>
                                                            <ul className="p2p_search_refresh p2p_create_offer_location_opetion d-flex align-items-center mt-3 mb-0" data-select2-id="7">
                                                                <li className="mt-0" data-select2-id="6">
                                                                    <Select
                                                                        placeholder='Choose Assets'
                                                                        className="wallet_network_select custom_Curency-box"
                                                                        classNamePrefix="custom_select"
                                                                        isSearchable={true}
                                                                        name='Assets'
                                                                        options={coinList}
                                                                        value={coinData}
                                                                        onChange={setCoinValue}
                                                                        getOptionValue={(option) => option?.value}
                                                                        getOptionLabel={(option) => (
                                                                            <div className='d-flex align-items-center'>
                                                                                <img src={`${option.image}`}
                                                                                    alt={option?.label || option?.symbol} width='25' className='mr-2' />
                                                                                {option.label || option?.symbol}
                                                                            </div>
                                                                        )}
                                                                    />
                                                                </li>
                                                            </ul>

                                                            <p className="text-danger" id="p2p_location_error">{errorsMsg?.coinDataError}</p>
                                                        </div>
                                                        <div className="col-md-9 mb-2" data-select2-id="8">
                                                            <h6 className="my-3">What location do you want to display?</h6>
                                                            <ul className="p2p_search_refresh p2p_create_offer_location_opetion d-flex align-items-center mt-3 mb-0" data-select2-id="7">
                                                                <li className="mt-0" data-select2-id="6">
                                                                    <Select
                                                                        placeholder="Available Region(s)"
                                                                        className="wallet_network_select custom_Curency-box"
                                                                        classNamePrefix="custom_select"
                                                                        isSearchable={true}
                                                                        name="selectPaymentMethod"
                                                                        options={countryList}
                                                                        value={selectedCountryList}
                                                                        onChange={handleCountryListChange}
                                                                        styles={{
                                                                            control: (baseStyles, state) => ({
                                                                                ...baseStyles,
                                                                                borderColor: state.isFocused ? "none" : "none",
                                                                            }),
                                                                        }}
                                                                    />

                                                                </li>
                                                            </ul>
                                                            <p className="text-danger" id="p2p_location_error">{errorsMsg?.countryError}</p>
                                                        </div>
                                                        <div className="col-md-9 mb-2" data-select2-id="266">
                                                            <h6 className="my-3">Which local currency do you want to trade with?</h6>
                                                            <ul className="p2p_search_refresh p2p_create_offer_location_opetion d-md-flex align-items-center mt-3 mb-0" data-select2-id="265">
                                                                <li className="mt-0" data-select2-id="264">
                                                                    <Select
                                                                        // defaultValue={currency[0]}
                                                                        placeholder=" Select currency"
                                                                        className="wallet_network_select custom_Curency-box"
                                                                        classNamePrefix="custom_select"
                                                                        isSearchable={true}
                                                                        name="selectCurrency"
                                                                        options={currency}
                                                                        value={selectedCurrency}
                                                                        onChange={handleCurrencyChange}
                                                                        styles={{
                                                                            control: (baseStyles, state) => ({
                                                                                ...baseStyles,
                                                                                borderColor: state.isFocused ? "none" : "none",
                                                                            }),
                                                                        }}
                                                                    />
                                                                </li>
                                                            </ul>
                                                            <p className="text-danger" id="p2p_currency_error">{errorsMsg?.currencyError}</p>
                                                        </div>
                                                    </Row>

                                                    <div className="p2p_create_offer_step_btn buttons float-left mt-3">
                                                        <button className="p2p_buy_btn_bg theme-btn-2 theme-btn next_button" onClick={handleNext} id="step_1_btn">Next</button>
                                                    </div>
                                                </div>
                                            )}
                                            {step === 2 && (
                                                <div className="main step_2_btn">

                                                    <div className="set_amount_payment_method_section w-100 float-left mt-3 col-md-9 p-0">
                                                        <div className="p2p_create_offer_assets">
                                                            <div className="my-3 d-flex flex-wrap justify-content-between align-items-center saving-transactions-form p2p_create_offer_form">
                                                                <h6>Choose a percentage margin
                                                                    <OverlayTrigger
                                                                        trigger="hover"
                                                                        overlay={
                                                                            <UpdatingPopover id="popover-contained">
                                                                                <p>
                                                                                    {content}
                                                                                </p>
                                                                            </UpdatingPopover>
                                                                        }
                                                                    >
                                                                        <i className="fa fa-info-circle ml-2" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="" data-original-title="Set margin percentage for orders above/below market price"></i>
                                                                    </OverlayTrigger>

                                                                </h6>
                                                                <div className="p2p_create_offer_type p2p_create_offer_percentage_type transaction-type">

                                                                    <input
                                                                        type="radio"
                                                                        className="btn-check"
                                                                        name="margin_type"
                                                                        id="p2p_margin_type_above"
                                                                        value="1"
                                                                        onChange={handlePercentage}
                                                                        checked={percentage == "1"}
                                                                    />
                                                                    <label className="basic-label" for="p2p_margin_type_above">Above</label>
                                                                    <input
                                                                        type="radio"
                                                                        className="btn-check"
                                                                        name="margin_type"
                                                                        id="p2p_margin_type_below"
                                                                        value="2"
                                                                        onChange={handlePercentage}
                                                                        checked={percentage == "2"}
                                                                    /><label className="free-label" for="p2p_margin_type_below">Below</label>
                                                                </div>
                                                            </div>
                                                            <div className="saving-transactions-form p2p_create_offer_form mb-3 d-inline-block w-100 ">
                                                                <div className="p2p_create_offer_input">
                                                                    <input
                                                                        type="text"
                                                                        placeholder="e.g. 1.5%"
                                                                        maxLength="7"
                                                                        name="margin_percentage"
                                                                        id="margin_percentage"
                                                                        value={margin}
                                                                        onChange={(e) => { handleMarginChange(e) }}
                                                                        min="0"
                                                                        required=""
                                                                        autoComplete="off"
                                                                    />
                                                                </div>
                                                                <p className="text-danger" id="margin_percentage_error">{errorsMsg?.marginError}</p>

                                                            </div>
                                                        </div>

                                                        <div className="set_amount_payment_method_section w-100 float-left mt-3">
                                                            <h6 className="mb-3">Total Amount in
                                                                <span className="coin_code_ord_limit"> {coinData?.symbol}</span></h6>
                                                            <div className="p2p_create_offer_input p2p_create_offer_input_orderlimit">
                                                                <input
                                                                    type="text"
                                                                    placeholder="0.00"
                                                                    name="total_amount"
                                                                    id="total_amount"
                                                                    value={amount}
                                                                    onChange={(e) => handleInputChange(e)}
                                                                    autoComplete="off"
                                                                />
                                                            </div>

                                                            <p className="text-danger" id="total_amount_error">{errorsMsg?.amountError}</p>
                                                            <Row>
                                                                <div id="usd_div" className="col-md-6 mb-2">
                                                                    {amount && <span className="w-100 text-left small">
                                                                        ~ ${utill.beautifyNumber(
                                                                            parseFloat(newCoinPrice
                                                                            ) * parseFloat(amount), 2
                                                                        )} { }
                                                                    </span>}
                                                                </div>

                                                                {isChecked && (
                                                                    <div id="bal_div" className=" col-md-6 mb-2" style={{ textAlign: 'right' }}>
                                                                        {balance?.available_coin &&
                                                                            <>
                                                                                <span className="w-100 text-right small"
                                                                                    style={{ fontSize: "14px" }}>

                                                                                    Balance:{" "}
                                                                                    {utill.beautifyNumber(parseFloat(balance?.available_coin), 6)}
                                                                                    {/* {parseFloat(balance?.available_coin)} */}
                                                                                    {balance?.coin.toUpperCase()}

                                                                                </span>
                                                                                <br />

                                                                                <a
                                                                                    style={{ color: "#fa501c", textDecoration: "none" }}
                                                                                    className="transfer_btn"
                                                                                    onClick={() => setShowTransfer(true)}>
                                                                                    Transfer
                                                                                </a>
                                                                                {/* </Link> */}
                                                                            </>
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Row>
                                                        </div>
                                                        <div className="p2p_create_offer_assets pb-3 pt-2">
                                                            <h6 className="py-3">Which payment methods do you want to accept?</h6>
                                                            <div className="saving-transactions-form p2p_create_offer_form d-flex flex-wrap align-items-center justify-content-between gap-v-5">
                                                                <div className="p2p_create_offer_type transaction-type payment_methods_you_accept d-flex flex-wrap align-items-center">
                                                                    {paymentMethod?.length > 0 ? (
                                                                        <>
                                                                            {paymentMethod?.map((item, index) => {
                                                                                return (
                                                                                    <div key={index}>
                                                                                        <input
                                                                                            key={index}
                                                                                            type="checkbox"
                                                                                            className="btn-check payment_method_chk"
                                                                                            name={item?.name}
                                                                                            value={item?.id}
                                                                                            checked={selectedMethod.includes(item?.id)}
                                                                                            onChange={handlePaymentCheckbox}
                                                                                            id={`payment_method_${item?.name}`}
                                                                                            data-name={item?.name}
                                                                                        />
                                                                                        <label
                                                                                            className="basic-label"
                                                                                            for={`payment_method_${item?.name}`}
                                                                                        >
                                                                                            {item?.name}
                                                                                        </label>

                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </>
                                                                    ) :
                                                                        null
                                                                    }
                                                                </div>
                                                                <h6>
                                                                    {isChecked &&

                                                                        <a onClick={() => { setAddBank(true) }}>
                                                                            <h3 className="btn btn-gray-v mr-2 add_payment_methods_btn_v">
                                                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                                                            </h3>
                                                                            Add Payment Method
                                                                        </a>
                                                                    }
                                                                </h6>

                                                            </div>

                                                            <p className="text-danger" id="payment_method_error">
                                                                {errorsMsg?.selectedMethodError}
                                                            </p>
                                                        </div>

                                                        <div className="d-flex flex-wrap justify-content-between my-3">
                                                            <h6>Do you want to set any limit?</h6>
                                                            <i className="fs-6v">(Minimum USD value should be more than $1)</i>
                                                        </div>
                                                        <div className="saving-transactions-form p2p_create_offer_form d-sm-flex">
                                                            <div className="p2p_create_offer_input p2p_create_offer_input_orderlimit w-100">
                                                                <span className="currency_code_ord_limit fs-4v">USD</span>
                                                                <input type="text"
                                                                    placeholder="Minimum"
                                                                    value={minLimit}
                                                                    onChange={(e) => {
                                                                        let value = isNumberKey(e.target.value);
                                                                        if (value !== false) {
                                                                            setMinLimit(value)
                                                                        }
                                                                    }}
                                                                    name="min_trade_size" id="min_trade_size" maxLength="7" min="0" required="" autoComplete="off" />
                                                            </div>
                                                            <div className="p2p_create_offer_input p2p_create_offer_input_orderlimit mx-md-4 mx-2 w-auto justify-content-center">
                                                                <h4>-</h4>
                                                            </div>
                                                            <div className="p2p_create_offer_input w-100">
                                                                <span className="currency_code_ord_limit fs-4v">USD</span>
                                                                <input
                                                                    type="text"
                                                                    value={maxLimit}
                                                                    onChange={(e) => {
                                                                        let value = isNumberKey(e.target.value);
                                                                        if (value !== false) {
                                                                            setMaxLimit(value)
                                                                        }
                                                                    }}
                                                                    placeholder="Maximum" name="max_trade_size" id="max_trade_size" maxLength="7" min="0" required="" autoComplete="off" />

                                                            </div>

                                                        </div>

                                                        <p className="text-danger mb-3" id="min_max_error">
                                                            {errorsMsg?.maxLimitError || errorsMsg?.minLimitError}
                                                        </p>
                                                    </div>

                                                    <div className="p2p_create_offer_step_btn buttons float-left mt-3">
                                                        <button className="p2p_buy_btn_bg theme-btn-2 theme-btn previous_button" onClick={handlePrevious}>Previous</button>
                                                        <button className="p2p_buy_btn_bg theme-btn-2 theme-btn next_button" onClick={handleNext} id="step_2_btn">Next</button>
                                                    </div>
                                                </div>

                                            )}
                                            {step === 3 && (
                                                <div className="main createOfferBtn col-md-9">

                                                    <div className="set_amount_payment_method_section w-100 float-left">
                                                        <h6 className='mb-2'>Payment Time Limit</h6>
                                                        <Select
                                                            placeholder="Select time limit"
                                                            className="wallet_network_select custom_Curency-box"
                                                            classNamePrefix="custom_select"
                                                            isSearchable={true}
                                                            name="selectPaymentMethod"
                                                            options={timeLimitList}
                                                            value={timeLimit}
                                                            onChange={handleTimeChange}
                                                            styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    borderColor: state.isFocused ? "none" : "none",
                                                                }),
                                                            }}
                                                        />

                                                        <p className="text-danger" id="payment_time_limit_error">{errorsMsg?.minTimeLimitError}</p>
                                                        {/* </div> */}
                                                    </div>
                                                    <div className="p2p_offer_create_remarks_conditions_form">
                                                        {!userData?.nick_name &&
                                                            <div className="set_amount_payment_method_section w-100 float-left mt-3">
                                                                <h6>Nick Name</h6>
                                                                <div className="p2p_create_offer_input mt-3">
                                                                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} name="nick_name" id="nick_name" placeholder="Enter your nickname (3-10 characters)" required="" autoComplete="off" />
                                                                </div>
                                                            </div>
                                                        }
                                                        <p className="text-danger" id="nick_name_error">{errorsMsg?.nameError}</p>
                                                        <div className="set_amount_payment_method_section w-100 float-left mt-3">
                                                            <h6>Terms</h6>
                                                            <div className="p2p_create_offer_input mt-3">
                                                                <textarea name="terms_trade" value={terms} onChange={(e) => setTerms(e.target.value)} id="terms_trade" placeholder="Enter terms of trade" required="" autoComplete="off"></textarea>
                                                            </div>
                                                            <p className="text-danger" id="nick_name_error">{errorsMsg?.termsError}</p>
                                                        </div>

                                                    </div>
                                                    <div className="p2p_create_offer_step_btn buttons float-left final_button mt-3">
                                                        <button className="p2p_buy_btn_bg theme-btn-2 theme-btn previous_button" onClick={handlePrevious}>Previous</button>
                                                        <button
                                                            onClick={createOffer}
                                                            disabled={loading ? true : false}
                                                            className="p2p_buy_btn_bg theme-btn-2 theme-btn next_button"
                                                            id="createOfferBtn"
                                                        >
                                                            {loading ? "Loading..." : (!userValueId ? "Submit" : "Update")}
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>


                                <div className="col-lg-5 col-12 p-3 justify-content-start justify-content-lg-end">
                                    <div className="offer_status_summary_section p-4">
                                        <div className='d-flex justify-content-between'>
                                            <h5>Summary</h5>
                                            {!isChecked ? <div className='summary_showbuy_text showBuy'>Buy</div> : <div className='showSell'>Sell</div>}
                                        </div>

                                        <div className="border my-3"></div>
                                        <div className="p2p_offer_category">
                                            <h6>Offer category</h6>
                                            <ul id="summary_ul">
                                                <li id="summ_trade_type" >
                                                    <p>Type</p>
                                                    <span id="summ_trade_type_span">{!isChecked ? "Buying" : "Selling"} {coinData?.symbol}</span>
                                                </li>

                                                <li id="summ_location" className={`${selectedCountryList ? '' : 'd-none'}`}>
                                                    <p>Location</p>
                                                    <span id="summ_location_span">{selectedCountryList?.label}</span>
                                                </li>
                                                <li id="summ_currency" className={`${selectedCurrency ? '' : 'd-none'}`}>
                                                    <p>Currency</p>
                                                    <span id="summ_currency_span">{selectedCurrency?.label}</span>
                                                </li>

                                                <hr className={`${amount ? 'mb-3' : 'mb-3 d-none'}`} id="step_1_border" />

                                                <li id="summ_total_amount" className={`${amount ? '' : 'd-none'}`}>
                                                    <p>Quantity</p>
                                                    <span id="summ_total_amount_span">{parseFloat(amount)}  {coinData?.symbol}</span>
                                                </li>
                                                <li id="summ_payment_method" className={`${selectedMethodChecked?.length > 0 ? '' : 'd-none'}`}>
                                                    <p>Payment methods</p>
                                                    <div>
                                                        {selectedMethodChecked?.map((item, index) => {
                                                            return (
                                                                <span key={index} id="summ_payment_method_span">{item},{" "}</span>
                                                            )
                                                        })}
                                                    </div>
                                                </li>
                                                <li id="summ_margin_percentage" className={`${amount ? '' : 'd-none'}`}>
                                                    <p>Rate</p>
                                                    <span id="summ_margin_percentage_span">{coinData?.symbol} {percentage == "1" ? "+" : "-"} {margin}%</span>
                                                </li>
                                                <li id="summ_limits" className={`${amount ? '' : 'd-none'}`}>
                                                    <p>Limits</p>
                                                    <span id="summ_limits_span">{minLimit} to {maxLimit} USD</span>
                                                </li>

                                                <div className="p2p_create_offer_border mb-3 d-none" id="step_2_border"></div>

                                                <li id="summ_payment_limit" className={`${timeLimit?.value ? '' : 'd-none'}`}>
                                                    <p>Payment Time Limit</p>
                                                    <span id="summ_payment_limit_span">{timeLimit?.label}</span>
                                                </li>
                                                {!userData?.nick_name &&
                                                    <li id="summ_nick_name" className={`${timeLimit ? '' : 'd-none'}`}>
                                                        <p>Nick Name</p>
                                                        <span id="summ_nick_name_span">{name}</span>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </section>

        </div >
    )
}

export default CreateNewOrder