import React, { useEffect, useState } from "react";
import { Tabs, Tab, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Moment from "react-moment";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Loader from "../loader";
import { useLocation } from 'react-router-dom';
import {
  cancelAllOrdersAction,
  cancelOrdersAction,
  getAllOrdersAction,
  getCloseOrdersAction,
  getOpenOrderAction,
  getOrderBalanceAction,
  getUserbalanceAction,
} from "../../redux/apiActions/api.action";
// import _constants from "../_constant";
import { toast } from "../Toast/Toast";
import utill from "../../_constant/utill";
import LoginFirst from "../loginFirst";
import moment from "moment";
import BASE_URL from "../../_constant/index";
import CustomDateRangePicker from "../CustomDateRangePicker";
export default function HistoryOrder({
  pair,
  updateQuoteBalance,
  updateBaseBalance,
  updateOrderHistory,
  openOrders,
  openOrderCount,
  updateOpenOrderCount,
  order_Price,
}) {
  const dispatch = useDispatch();

  // const { SYMBOL_ID } = _constants;
  const [allorderHistory, setallOrderhistory] = useState([]);
  const [closeOrders, setCloseOrders] = useState([]);
  // const [openOrders, updateOrderHistory] = useState([]);
  const [balance, setBalance] = useState([]);
  const [showCalender, toggleCalender] = useState(false);
  const [activeTab, _activeTab] = useState("Open Orders");
  let userData = useSelector((state) => state.user.userData);

  // Cancel All Orders
  const returnUrl = window?.location.href;
  // const returnToUrl = returnUrl?.split("/v1/");


  const location = useLocation();
  const routeName = location.pathname;
  const returnToUrl = `${BASE_URL.BASE_URL.slice(0, -1)}${routeName}`;

  const cancelAllOrders = async (pair) => {

    if (userData?.access_token) {
      if (openOrders) {
        await cancelAllOrdersAction(pair?.id, dispatch);
        const response = await getOpenOrderAction(Number(pair?.id), 1);
        updateOrderHistory(response?.data,);
        updateOpenOrderCount(response?.count);
        const baseResponse = await getUserbalanceAction(
          pair?.base.toLowerCase(), dispatch
        );
        updateBaseBalance(baseResponse?.data?.balance);
        const quoteResponse = await getUserbalanceAction(
          pair?.quote.toLowerCase(), dispatch
        );
        updateQuoteBalance(quoteResponse?.data?.balance);
      } else {
        toast.error("There is no open orders.");
      }
    }
  };

  // Cancel Orders
  const cancelOrdersFun = async (pair_id) => {
    if (userData?.access_token) {
      await cancelOrdersAction({ id: String(pair_id) }, dispatch);
      const response = await getOpenOrderAction(Number(pair?.id), 1);
      updateOrderHistory(response?.data);
      updateOpenOrderCount(response?.count);

      // Update Balance whenever cancel any order
      const baseResponse = await getUserbalanceAction(pair?.base.toLowerCase(), dispatch);
      updateBaseBalance(baseResponse?.data?.balance);
      const quoteResponse = await getUserbalanceAction(
        pair?.quote.toLowerCase()
      );
      updateQuoteBalance(quoteResponse?.data?.balance);
    }
  };

  // Limit Order Load more
  const [openLimitPage, setOpenLimitPage] = useState(2);
  const [CloseLimitPage, setCloseLimitPage] = useState(2);
  const [historyLimitPage, setHistoryLimitPage] = useState(2);

  const [OpenOrderDisplaybtn, setOpenOrderDisplaybtn] = useState("block");
  const [HistoryOrderDisplaybtn, setHistoryOrderDisplaybtn] = useState("block");
  const [closeOrderDisplaybtn, setCloseOrderDisplaybtn] = useState("block");

  // Loader
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const updateDateRange = (dateRange) => {
    setDateRange(dateRange);
  };
  // const [endDate, setEndDate] = useState(new Date());

  // Load more
  const loadMore = async () => {
    const filterDate =
      dateRange != null
        ? {
          startDate: moment(dateRange[0]).format("YYYY-M-D"),
          endDate: moment(dateRange[1]).format("YYYY-M-D"),
        }
        : null;
    if (activeTab === "Order history") {
      if (userData?.access_token) {
        const response = await getAllOrdersAction(
          pair?.id,
          historyLimitPage,
          filterDate, dispatch
        );
        setHistoryLimitPage(historyLimitPage + 1);
        if (response?.data?.length > 0) {
          setallOrderhistory([...allorderHistory, ...response?.data]);
        }
        let displayHistoryBtn =
          response?.data?.length === 10 ? "block" : "none";
        setHistoryOrderDisplaybtn(displayHistoryBtn);
      }
    } else if (activeTab === "Closed Orders") {
      if (userData?.access_token) {
        const response = await getCloseOrdersAction(
          Number(pair?.id),
          CloseLimitPage,
          filterDate, dispatch
        );
        setCloseLimitPage(CloseLimitPage + 1);
        if (response?.data?.length > 0) {
          setCloseOrders([...closeOrders, ...response?.data]);
        }
        let displayCloseBtn = response?.data?.length === 10 ? "block" : "none";
        setCloseOrderDisplaybtn(displayCloseBtn);
      }
    } else if (activeTab === "Open Orders") {
      if (userData?.access_token) {
        const response = await getOpenOrderAction(
          Number(pair?.id),
          openLimitPage,
          filterDate, dispatch
        );
        setOpenLimitPage(openLimitPage + 1);
        if (response?.data?.length > 0) {
          updateOrderHistory([...openOrders, ...response?.data]);
        }
        updateOpenOrderCount(response?.count);
        let displayOpenBtn = response?.data?.length === 10 ? "block" : "none";
        setOpenOrderDisplaybtn(displayOpenBtn);
      }
    } else {
      return null;
    }
  };

  useEffect(async () => {
    const filterDate = dateRange
      ? {
        startDate: moment(dateRange[0]).format("YYYY-M-D"),
        endDate: moment(dateRange[1]).format("YYYY-M-D"),
      }
      : null;
    if (activeTab === "Order history") {
      if (userData?.access_token) {
        setLoading(true);
        const response = await getAllOrdersAction(pair?.id, 1, filterDate, dispatch);
        setallOrderhistory(response?.data);
        setHistoryLimitPage(2);
        setLoading(false);
        setHistoryOrderDisplaybtn("block");
      }
    } else if (activeTab === "Closed Orders") {
      if (userData?.access_token) {
        setLoading(true);
        const response = await getCloseOrdersAction(
          Number(pair?.id),
          1,
          filterDate, dispatch
        );
        setCloseOrders(response?.data);
        setCloseLimitPage(2);
        setLoading(false);
        setCloseOrderDisplaybtn("block");
      }
    } else if (activeTab === "Balance") {
      if (userData?.access_token) {
        setLoading(true);
        const response = await getOrderBalanceAction(pair, dispatch);
        setBalance(response?.data);
        setLoading(false);
      }
    } else {
      if (userData?.access_token) {
        setLoading(true);
        const response = await getOpenOrderAction(
          Number(pair?.id),
          1,
          filterDate, dispatch
        );
        updateOrderHistory(response?.data);
        updateOpenOrderCount(response?.count);
        setOpenLimitPage(2);
        setOpenOrderDisplaybtn("block");
        setLoading(false);
      }
    }
  }, [pair, activeTab, dateRange]);

  // Cancel orders Modal
  const [ConfirmModalShow, setConfirmModalShow] = useState(false);
  const handleConfirmModalClose = () => setConfirmModalShow(false);
  const handleConfirmModalShow = () => setConfirmModalShow(true);

  const [cancelID, setCancelId] = useState();
  const cancelIdHandler = (order_id) => {
    setCancelId(order_id);
  };

  // Cancel All orders Modal
  const [AllConfirmModalShow, setAllConfirmModalShow] = useState(false);
  const handleAllConfirmModalClose = () => setAllConfirmModalShow(false);
  const handleAllConfirmModalShow = () => setAllConfirmModalShow(true);


  const [decimalBase, setDecimalBase] = useState(6);
  useEffect(() => {
    setDecimalBase(order_Price?.base_dc > 6 ? 6 : order_Price?.base_dc);
  });
  return (
    <>
      <div className="market-order mt15">
        <Tabs
          defaultActiveKey="open-orders"
          onClick={(e) => {
            _activeTab(e.target.innerText);
            setDateRange(null);
          }}
        >
          <Tab
            eventKey="open-orders"
            title={`Open Orders(${openOrderCount ? openOrderCount : 0})`}
          >
            {loading ? (
              <Loader />
            ) : (
              <div className="table_wrapper">
                {userData?.access_token ? (
                  <>
                    {/* <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center ml-2">
                        <span className="pr-2 text-secondary font-weight-bold">
                          Filter Date :
                        </span>
                        <CustomDateRangePicker
                          dateRange={dateRange}
                          updateDateRange={updateDateRange}
                        />
                      </div>
                    </div> */}
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Pairs</th>
                          <th>Type</th>
                          <th>Buy/Sell</th>
                          <th>Price</th>
                          <th>Amount </th>
                          <th>Remain Qty.</th>
                          <th>Total</th>
                          <th>Trigger Condition</th>
                          <th>Status</th>
                          <th>
                            {openOrders?.length == 0 ? (<>
                              <button
                                className="cancel_all_orders"

                                disabled
                                onClick={() => {
                                  handleAllConfirmModalShow();
                                }}
                              >
                                Cancel All
                              </button>
                            </>) : (<>
                              <button
                                className="cancel_all_orders"
                                // disabled={ }
                                disabled={
                                  userData?.access_token && openOrders
                                    ? false
                                    : true
                                }
                                onClick={() => {
                                  handleAllConfirmModalShow();
                                }}
                              >
                                Cancel All
                              </button>
                            </>)}

                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {openOrders && openOrders?.length !== 0 ? (
                          openOrders?.map((order, key) => (
                            <tr key={key}>
                              <td>
                                {" "}
                                <Moment format="DD/MM/YYYY hh:mm A">
                                  {order?.created_date}
                                </Moment>
                              </td>
                              <td>{order?.pair_symbol.toUpperCase()}</td>
                              <td>
                                {order?.order_type == 2
                                  ? "STOP LIMIT"
                                  : order?.order_type == 1
                                    ? "LIMIT"
                                    : order?.order_type == 0
                                      ? "Market"
                                      : ""}
                              </td>
                              <td
                                className={
                                  order?.side === 0
                                    ? "text-danger font-weight-bold"
                                    : "text-success font-weight-bold"
                                }
                              >
                                {order?.side === 0 ? "SELL" : "BUY"}
                              </td>
                              <td>{utill.beautifyNumber(order?.price)}</td>
                              <td>
                                {utill.beautifyNumber(
                                  order?.quantity,
                                  decimalBase
                                )}{" "}
                                {openOrders
                                  ? openOrders[0]?.pair_symbol
                                    .toUpperCase()
                                    .split("/")[0]
                                  : ""}
                              </td>
                              <td>
                                {utill.beautifyNumber(
                                  order?.remain_quantity,
                                  decimalBase
                                )}{" "}
                                {openOrders
                                  ? openOrders[0]?.pair_symbol
                                    .toUpperCase()
                                    .split("/")[0]
                                  : ""}
                              </td>
                              <td>
                                {utill.beautifyNumber(
                                  order?.order_value,
                                  decimalBase
                                )}{" "}
                                {openOrders
                                  ? openOrders[0]?.pair_symbol
                                    .toUpperCase()
                                    .split("/")[1]
                                  : ""}
                              </td>
                              <td className="text-center">
                                {order?.stop_condition}{" "}
                                {order?.order_type == 2
                                  ? order?.stop_price
                                  : null}
                              </td>
                              <td
                                className={
                                  order?.status === 0
                                    ? "text_primary_open font-weight-bold"
                                    : order?.status === 1
                                      ? "text-warning font-weight-bold"
                                      : order?.status === 2
                                        ? "text-success font-weight-bold"
                                        : order?.status === 3
                                          ? "text-danger font-weight-bold"
                                          : order?.status === 4
                                            ? "text-info font-weight-bold"
                                            : "text-info font-weight-bold"
                                }
                              >
                                {order?.status === 0
                                  ? "OPEN"
                                  : order?.status === 1
                                    ? "PARTIALLY FILLED"
                                    : order?.status === 2
                                      ? "COMPLETED"
                                      : order?.status === 3
                                        ? "CANCELLED"
                                        : order?.status === 4
                                          ? "PENDING"
                                          : ""}
                              </td>
                              <td>
                                <button
                                  className="order_cancel_btn"
                                  onClick={() => {
                                    handleConfirmModalShow();
                                    cancelIdHandler(order?.id);
                                  }}
                                >
                                  <i className="icon ion-md-close"></i>
                                  Cancel
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="no-data">
                              <i className="icon ion-md-document"></i>
                              No data
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <div className="login_first_block">
                    <LoginFirst
                      title="to trade"
                      return_to={btoa(`${returnToUrl}`)}
                    />
                  </div>
                )}

                {loading ? (
                  <Loader />
                ) : userData?.access_token ? (
                  openOrders &&
                    openOrders?.length !== 0 &&
                    openOrders?.length % 10 === 0 ? (
                    <div
                      className="loadmore_data"
                      style={{ display: OpenOrderDisplaybtn }}
                    >
                      <button
                        disabled={loading ? true : false}
                        onClick={() => {

                          loadMore(openLimitPage);
                        }}
                      >
                        {loading ? "Loading" : "Load More"}{" "}
                        {loading ? (
                          <Spinner
                            size="sm"
                            animation="border"
                            role="status"
                          ></Spinner>
                        ) : (
                          <i className="icon ion-md-arrow-down"></i>
                        )}
                      </button>
                    </div>
                  ) : (
                    ""
                  )
                ) : null}
              </div>
            )}
          </Tab>
          <Tab eventKey="closed-orders" title="Closed Orders">
            {loading ? (
              <Loader />
            ) : (
              <div className="table_wrapper">
                {userData?.access_token ? (
                  <>

                    <div className="d-flex justify-content-between mt-2">
                      <div className="d-flex align-items-center ml-2">
                        <span className="pr-2 text-secondary font-weight-bold">
                          Filter Date :
                        </span>
                        <CustomDateRangePicker
                          dateRange={dateRange}
                          updateDateRange={updateDateRange}
                        />
                      </div>
                    </div>

                    <table className="table">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Pairs</th>
                          <th>Type</th>
                          <th>Buy/Sell</th>
                          <th>Price</th>
                          <th>Amount</th>
                          <th>Total</th>
                          <th>Trigger Condition</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {closeOrders && closeOrders?.length !== 0 ? (
                          closeOrders?.map((order, key) => (
                            <tr key={key}>
                              <td>
                                {" "}
                                <Moment format="DD/MM/YYYY hh:mm A">
                                  {order?.created_date}
                                </Moment>
                              </td>
                              <td>{order?.pair_symbol.toUpperCase()}</td>
                              <td>
                                {order?.order_type == 2
                                  ? "STOP LIMIT"
                                  : order?.order_type == 1
                                    ? "LIMIT"
                                    : order?.order_type == 0
                                      ? "Market"
                                      : ""}
                              </td>
                              <td
                                className={
                                  order?.side === 0
                                    ? "text-danger font-weight-bold"
                                    : "text-success font-weight-bold"
                                }
                              >
                                {order?.side === 0 ? "SELL" : "BUY"}
                              </td>
                              <td>{utill.beautifyNumber(order?.price)}</td>
                              <td>
                                {utill.beautifyNumber(
                                  order?.quantity,
                                  decimalBase
                                )}{" "}
                                {closeOrders
                                  ? closeOrders[0]?.pair_symbol
                                    .toUpperCase()
                                    .split("/")[0]
                                  : ""}
                              </td>
                              <td>
                                {utill.beautifyNumber(
                                  order?.order_value,
                                  decimalBase
                                )}{" "}
                                {closeOrders
                                  ? closeOrders[0]?.pair_symbol
                                    .toUpperCase()
                                    .split("/")[1]
                                  : ""}
                              </td>
                              <td className="text-center">
                                {order?.stop_condition}{" "}
                                {order?.order_type == 2
                                  ? order?.stop_price
                                  : null}
                              </td>
                              <td
                                className={
                                  order?.status === 0
                                    ? "text_primary_open font-weight-bold"
                                    : order?.status === 1
                                      ? "text-warning font-weight-bold"
                                      : order?.status === 2
                                        ? "text-success font-weight-bold"
                                        : order?.status === 3
                                          ? "text-danger font-weight-bold"
                                          : order?.status === 4
                                            ? "text-info font-weight-bold"
                                            : "text-info font-weight-bold"
                                }
                              >
                                {order?.status === 0
                                  ? "OPEN"
                                  : order?.status === 1
                                    ? "PARTIALLY FILLED"
                                    : order?.status === 2
                                      ? "COMPLETED"
                                      : order?.status === 3
                                        ? "CANCELLED"
                                        : "PENDING"}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="no-data">
                              <i className="icon ion-md-document"></i>
                              No data
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <div className="login_first_block">
                    <LoginFirst
                      title="to trade"
                      return_to={btoa(`${returnToUrl}`)}
                    />
                  </div>
                )}
                {userData?.access_token ? (
                  closeOrders &&
                    closeOrders?.length !== 0 &&
                    closeOrders?.length % 10 === 0 ? (
                    <div
                      className="loadmore_data"
                      style={{ display: closeOrderDisplaybtn }}
                    >
                      <button
                        disabled={loading ? true : false}
                        onClick={() => {
                          loadMore(CloseLimitPage);
                        }}
                      >
                        {loading ? "Loading" : "Load More"}{" "}
                        {loading ? (
                          <Spinner
                            size="sm"
                            animation="border"
                            role="status"
                          ></Spinner>
                        ) : (
                          <i className="icon ion-md-arrow-down"></i>
                        )}{" "}
                      </button>
                    </div>
                  ) : (
                    ""
                  )
                ) : null}
              </div>
            )}
          </Tab>
          <Tab eventKey="order-history" title="Order history">
            {loading ? (
              <Loader />
            ) : (
              <div className="table_wrapper">
                {userData?.access_token ? (
                  <>

                    <div className="d-flex justify-content-between mt-2">
                      <div className="d-flex align-items-center ml-2">
                        <span className="pr-2 text-secondary font-weight-bold">
                          Filter Date :
                        </span>
                        <CustomDateRangePicker
                          dateRange={dateRange}
                          updateDateRange={updateDateRange}
                        />
                      </div>
                    </div>

                    <table className="table">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Pairs</th>
                          <th>Type</th>
                          <th>Buy/Sell</th>
                          <th>Price</th>
                          <th>Amount</th>
                          <th>Total</th>
                          <th>Trigger Condition</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allorderHistory && allorderHistory?.length !== 0 ? (
                          allorderHistory?.map((order, key) => (
                            <tr key={key}>
                              <td>
                                {" "}
                                <Moment format="DD/MM/YYYY hh:mm A">
                                  {order?.created_date}
                                </Moment>
                              </td>
                              <td>{order?.pair_symbol.toUpperCase()}</td>
                              <td>
                                {order?.order_type == 2
                                  ? "STOP LIMIT"
                                  : order?.order_type == 1
                                    ? "LIMIT"
                                    : order?.order_type == 0
                                      ? "Market"
                                      : ""}
                              </td>
                              <td
                                className={
                                  order?.side === 0
                                    ? "text-danger font-weight-bold"
                                    : "text-success font-weight-bold"
                                }
                              >
                                {order?.side === 0 ? "SELL" : "BUY"}
                              </td>
                              <td>{utill.beautifyNumber(order?.price)}</td>
                              <td>
                                {utill.beautifyNumber(
                                  order?.quantity,
                                  decimalBase
                                )}{" "}
                                {allorderHistory
                                  ? allorderHistory[0]?.pair_symbol
                                    .toUpperCase()
                                    .split("/")[0]
                                  : ""}
                              </td>
                              <td>
                                {utill.beautifyNumber(
                                  order?.order_value,
                                  decimalBase
                                )}{" "}
                                {allorderHistory
                                  ? allorderHistory[0]?.pair_symbol
                                    .toUpperCase()
                                    .split("/")[1]
                                  : ""}
                              </td>
                              <td className="text-center">
                                {order?.stop_condition}{" "}
                                {order?.order_type == 2
                                  ? order?.stop_price
                                  : null}
                              </td>
                              <td
                                className={
                                  order?.status === 0
                                    ? "text_primary_open font-weight-bold"
                                    : order?.status === 1
                                      ? "text-warning font-weight-bold"
                                      : order?.status === 2
                                        ? "text-success font-weight-bold"
                                        : order?.status === 3
                                          ? "text-danger font-weight-bold"
                                          : order?.status === 4
                                            ? "text-info font-weight-bold"
                                            : "text-info font-weight-bold"
                                }
                              >
                                {order?.status === 0
                                  ? "OPEN"
                                  : order?.status === 1
                                    ? "PARTIALLY FILLED"
                                    : order?.status === 2
                                      ? "COMPLETED"
                                      : order?.status === 3
                                        ? "CANCELLED"
                                        : "PENDING"}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="no-data">
                              <i className="icon ion-md-document"></i>
                              No data
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <div className="login_first_block">
                    <LoginFirst
                      title="to trade"
                      return_to={btoa(`${returnToUrl}`)}
                    />
                  </div>
                )}
                {userData?.access_token ? (
                  allorderHistory && allorderHistory?.length % 10 === 0 ? (
                    <div
                      className="loadmore_data"
                      style={{ display: HistoryOrderDisplaybtn }}
                    >
                      <button
                        disabled={loading ? true : false}
                        onClick={() => {
                          loadMore(historyLimitPage);
                        }}
                      >
                        {loading ? "Loading" : "Load More"}{" "}
                        {loading ? (
                          <Spinner
                            size="sm"
                            animation="border"
                            role="status"
                          ></Spinner>
                        ) : (
                          <i className="icon ion-md-arrow-down"></i>
                        )}
                      </button>
                    </div>
                  ) : (
                    ""
                  )
                ) : null}
              </div>
            )}
          </Tab>
          <Tab eventKey="order-balance" title="Balance">
            {loading ? (
              <Loader />
            ) : userData?.access_token ? (
              <table className="table">
                <thead className="table_mobile">
                  <tr>
                    <th>Coin</th>
                    <th>Total Balance</th>
                    <th>Available Balance</th>
                    <th>In Order</th>
                    <th>BTC Value</th>
                  </tr>
                </thead>
                <tbody>
                  {balance ? (
                    balance?.map((order, key) => (
                      <tr key={key}>
                        <td>{order?.coin}</td>
                        <td>
                          {utill.beautifyNumber(
                            order?.total_balance,
                            decimalBase
                          )}
                        </td>
                        <td>
                          {utill.beautifyNumber(
                            order?.available_balance,
                            decimalBase
                          )}
                        </td>
                        <td>
                          {utill.beautifyNumber(order?.in_order, decimalBase)}
                        </td>
                        <td>
                          {utill.beautifyNumber(order?.amount_btc, decimalBase)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="no-data">
                        <i className="icon ion-md-document"></i>
                        No data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : (
              <div className="login_first_block">
                <LoginFirst
                  title="to trade"
                  return_to={btoa(`${returnToUrl}`)}
                />
              </div>
            )}
          </Tab>
        </Tabs>

        {/* Cancel Orders Confirmation Modal  */}
        <Modal
          show={ConfirmModalShow}
          onHide={handleConfirmModalClose}
          backdrop="static"
          keyboard={false}
          centered={true}
          className="withdrawal_confirmation_modal"
        >

          <Modal.Body className="text-center">
            <h4 className="mt-4 mb-4">Are you sure ?</h4>
            <p className="text-secondary"> Do you really want to delete the order ? <br /> This process cannot be
              undone.</p>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button variant="secondary" onClick={handleConfirmModalClose}>
              Cancel
            </Button>
            <Button variant="danger"
              style={{ maxHeight: "44px" }}
              onClick={() => {
                handleConfirmModalClose();
                cancelOrdersFun(cancelID);
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        {/* <Modal
          show={ConfirmModalShow}
          aria-labelledby="contained-modal-title-vcenter"
          onHide={handleConfirmModalClose}
          backdrop="static"
          keyboard={false}
          centered={true}
          size="sm"
        >
          <div className="modal_body">
            <h5>Are you sure ?</h5>
            <p>
              Do you really want to delete the order ? <br /> This process cannot be
              undone.
            </p>
          </div>
          <div className="button_bar  mb-3">
            <button
              // className="modal_cancel_btn"
              className="modal_cancel_btn"
              onClick={handleConfirmModalClose}
            >
              {" "}
              Cancel{" "}
            </button>
            <button
              // className="modal_cancel_btn"
              className="modal_delete_btn"
              onClick={() => {
                handleConfirmModalClose();
                cancelOrdersFun(cancelID);
              }}
            >
              {" "}
              Delete{" "}
            </button>
          </div>
        </Modal> */}

        {/* Cancel All Order Confirmation Modal  */}
        <Modal
          show={AllConfirmModalShow}
          onHide={handleAllConfirmModalClose}
          backdrop="static"
          keyboard={false}
          centered={true}
          className="withdrawal_confirmation_modal"
        >

          <Modal.Body className="text-center">
            <h4 className="mt-4 mb-4">Are you sure ?</h4>
            <p className="text-secondary"> Do you really want to delete the order ? <br /> This process cannot be
              undone.</p>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button variant="secondary" onClick={handleAllConfirmModalClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => {
              handleAllConfirmModalClose();
              cancelAllOrders(pair);
            }}
            >
              Delete All
            </Button>
          </Modal.Footer>
        </Modal>
        {/* <Modal

          aria-labelledby="contained-modal-title-vcenter"
          show={AllConfirmModalShow}
          onHide={handleAllConfirmModalClose}
          backdrop="static"
          keyboard={false}
          centered={true}
          size="sm"
        >

          <div className="modal_body">
            <h5>Are you sure ?</h5>
            <p>
              Do you really want to delete the order ? <br /> This process cannot be
              undone.
            </p>
          </div>

          <div className="button_bar mb-3">
            <button
              className="modal_cancel_btn"
              onClick={handleAllConfirmModalClose}
            >
              {" "}
              Cancel{" "}
            </button>
            <button
              className="modal_delete_btn"
              onClick={() => {
                handleAllConfirmModalClose();
                cancelAllOrders(pair);
              }}
            >
              {" "}
              Delete All{" "}
            </button>
          </div>

        </Modal> */}
      </div>
    </>
  );
}
