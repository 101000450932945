import cogoToast from "cogo-toast";
import BASE_URL from '../../_constant/index'
class Toaster {
  success = (message) => {
    let options = { position: "bottom-right" };
    cogoToast.success(message, options);
  };

  error = (message) => {
    let options = { position: "bottom-right" };
    cogoToast.error(message, options);
  };

  info = (message) => {
    let options = { position: "bottom-right" };
    cogoToast.info(message, options);
  };

  successes = (header, message) => {
    let options = { position: "bottom-right" };
    cogoToast.success(<>
      <div style={{ minWidth: "200px" }}>
        <b>{header}</b>
        <div> {message}</div>
      </div>
    </>, options);
  };
  notificationSuccessesTost = (header, message, id) => {
    let options = { position: "bottom-right" };
    cogoToast.success(<>
      <div
        onClick={() => {
          window.location.href = `${BASE_URL.BASE_URL}p2p/Orders?to=p2p_my_trade&tradeid=${id}`
        }}
        style={{ minWidth: "200px" }}>
        <b>{header}</b>
        <div> {message}</div>
      </div >
    </>, options);
  };
  errors = (header, message) => {
    let options = { position: "bottom-right" };
    cogoToast.error(<>
      <div style={{ minWidth: "200px" }}>
        <b>{header}</b>
        <div> {message}</div>
      </div>
    </>, options);
  };


}

export const toast = new Toaster();
