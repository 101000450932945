import { parseFullSymbol } from "./helpers.js";
import io from "socket.io-client";
import _constant from "../../_constant";

const channelToSubscription = new Map();

let socket = null;
let lastPayload = null;

export function subscribeOnStream(
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscriberUID,
  onResetCacheNeededCallback,
  lastDailyBar
) {
  // const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
  const parsedSymbol = parseFullSymbol(
    `${symbolInfo.exchange}:${symbolInfo.name}`
  );

  const channelString = `0~${parsedSymbol.exchange}~${parsedSymbol.fromSymbol}~${parsedSymbol.toSymbol}`;

  // const handler = {
  //   id: subscriberUID,
  //   callback: onRealtimeCallback,
  // };

  const payload = {
    pair_id: symbolInfo?.id,
    interval: resolution,
    k_join: symbolInfo?.id + "-" + resolution,
  };

  if (!socket || JSON.stringify(lastPayload) !== JSON.stringify(payload)) {
    if (socket) {
      socket.disconnect();
      socket = null;
    }

    socket = io(_constant.ApiUrl);
    socket.on("connect", () => {
      console.log("[subscribeOnStream]: Connected to socket");
      socket.emit("get_kline", payload);
    });

    socket.on("disconnect", () => {
      console.log("[subscribeOnStream]: Disconnected from socket");
    });

    socket.on("connect_error", (error) => {
      console.error("[subscribeOnStream]: Socket connection error:", error);
    });

    socket?.emit("get_pairprice", {
      pair_id: "ALL",
    });

    socket?.on("received_pairs_price", (socketData) => {
      socket.emit("get_kline", payload);
    });
    socket.on("received_kline", (data) => {
      const time = data.c_time * 1000;
      const open = data.open;
      const high = data.high;
      const low = data.low;
      const close = data.close;
      const volume = data.volume;

      const bar = {
        time,
        close,
        open,
        high,
        low,
        volume,
      };

      onRealtimeCallback(bar);

      const handler = {
        id: subscriberUID,
        callback: onRealtimeCallback,
      };

      let subscriptionItem = channelToSubscription.get(channelString);

      if (subscriptionItem) {
        subscriptionItem.handlers.push(handler);
        return;
      }
      subscriptionItem = {
        subscriberUID,
        resolution,
        lastDailyBar,
        handlers: [handler],
      };
      // channelToSubscription.set(channelString, subscriptionItem);
      channelToSubscription.set(channelString, subscriptionItem);
      lastPayload = payload;
      socket.emit('SubAdd', { subs: [channelString] });
    });
  } else {
  }
}

export function unsubscribeFromStream(subscriberUID) {
  for (const channelString of channelToSubscription.keys()) {
    const subscriptionItem = channelToSubscription.get(channelString);
    const handlerIndex = subscriptionItem.handlers.findIndex(
      (handler) => handler.id === subscriberUID
    );
    if (handlerIndex !== -1) {
      subscriptionItem.handlers.splice(handlerIndex, 1);

      console.log(
        "[unsubscribeBars]: Unsubscribe from streaming. Channel:",
        channelString
      );
      if (subscriptionItem.handlers.length === 0) {
        channelToSubscription.delete(channelString);
        break;
      }
    }
  }
}

// import { parseFullSymbol } from "./helpers.js";
// import io from 'socket.io-client';
// import _constant from '../../_constant';
// // import moment from 'moment';
// // const socket = io("wss://streamer.cryptocompare.com");
// const channelToSubscription = new Map();
// // socket.emit('get_kline', { pair_id: 1 }, (response) => {
// //   console.log('Get/ kline:', response);
// // });

// // socket.on('received_kline', (data) => {
// //   console.log('Received kline:', data);
// // });

// // socket.on('recieved_kline', (data) => {
// //   // console.log("recieved_kline",data)
// //   if (typeof data !== "string") {
// //     return;
// //   }

// //   const [
// //     eventTypeStr,
// //     exchange,
// //     fromSymbol,
// //     toSymbol,
// //     ,
// //     ,
// //     tradeTimeStr,
// //     ,
// //     tradePriceStr,
// //   ] = data.split("~");

// //   if (parseInt(eventTypeStr) !== 0) {
// //     // skip all non-TRADE events
// //     return;
// //   }

// //   const tradePrice = parseFloat(tradePriceStr);
// //   const tradeTime = parseInt(tradeTimeStr);
// //   const channelString = `0~${exchange}~${fromSymbol}~${toSymbol}`;
// //   const subscriptionItem = channelToSubscription.get(channelString);

// //   if (subscriptionItem === undefined) {
// //     return;
// //   }

// //   const lastDailyBar = subscriptionItem.lastDailyBar;
// //   const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time);
// //   let bar;

// //   if (tradeTime >= nextDailyBarTime) {
// //     bar = {
// //       time: nextDailyBarTime,
// //       open: tradePrice,
// //       high: tradePrice,
// //       low: tradePrice,
// //       close: tradePrice,
// //     };
// //   } else {
// //     bar = {
// //       ...lastDailyBar,
// //       high: Math.max(lastDailyBar.high, tradePrice),
// //       low: Math.min(lastDailyBar.low, tradePrice),
// //       close: tradePrice,
// //     };
// //   }

// //   subscriptionItem.lastDailyBar = bar;

// //   // send data to every subscriber of that symbol
// //   subscriptionItem.handlers.forEach((handler) => {
// //     const update = {
// //       time: bar.time,
// //       close: bar.close
// //     };
// //     handler.callback(update);
// //   });
// // });

// // function getNextDailyBarTime(barTime) {
// //   const date = new Date(barTime * 1000);
// //   date.setDate(date.getDate() + 1);
// //   return date.getTime() / 1000;
// // }
// // let payload;
// // export function subscribeOnStream(
// //   symbolInfo,
// //   resolution,
// //   onRealtimeCallback,
// //   subscriberUID,
// //   onResetCacheNeededCallback,
// //   lastDailyBar, socket
// // ) {
// //   const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
// //   const channelString = `0~${parsedSymbol.exchange}~${parsedSymbol.fromSymbol}~${parsedSymbol.toSymbol}`;
// //   payload = { pair_id: symbolInfo?.id, interval: resolution, k_join: symbolInfo?.id + "-" + resolution };

// //   console.log(payload, 'payload');
// //   socket.emit('get_kline', payload);
// //   console.log(lastDailyBar, 'lastDailyBar');
// //   socket.on('received_kline', (data) => {
// //     const time = data.c_time * 1000;
// //     const open = data.open;
// //     const high = data.high;
// //     const low = data.low;
// //     // const low = Math.floor(100 + Math.random() * 600);
// //     const close = data.close;

// //     // const close = Math.floor(100 + Math.random() * 600);
// //     const volume = data.volume;

// //     const bar = {
// //       time,
// //       close,
// //       open,
// //       high,
// //       low,
// //       volume,
// //     };
// //     console.log(`received_kline@@`, bar);

// //     // if (lastDailyBar !== null && bar.time < lastDailyBar.time) {
// //     //   return;
// //     onRealtimeCallback(bar)
// //     const handler = {
// //       id: subscriberUID,
// //       callback: onRealtimeCallback,
// //     };
// //     let subscriptionItem = channelToSubscription.get(channelString);
// //     if (subscriptionItem) {
// //       // already subscribed to the channel, use the existing subscription
// //       subscriptionItem.handlers.push(handler);
// //       // return;
// //     }
// //     subscriptionItem = {
// //       subscriberUID,
// //       resolution,
// //       lastDailyBar,
// //       handlers: [handler],
// //     };
// //     channelToSubscription.set(channelString, subscriptionItem);
// //     // onRealtimeCallback(bar)
// //     console.log(`received_kline`, bar);
// //   });
// //   console.log(
// //     "[subscribeBars]: Subscribe to streaming. Channel:",
// //     channelString
// //   );

// // }

// // export function unsubscribeFromStream(subscriberUID, socket) {
// //   // find a subscription with id === subscriberUID
// //   // console.log(subscriberUID, channelToSubscription);
// //   // console.log(subscriberUID, channelToSubscription);

// //   // console.log(channelToSubscription.keys(), 'channel');

// //   for (const channelString of channelToSubscription.keys()) {
// //     const subscriptionItem = channelToSubscription.get(channelString);
// //     const handlerIndex = subscriptionItem.handlers.findIndex(
// //       (handler) => handler.id === subscriberUID
// //     );
// //     console.log(handlerIndex, 'handlerIndex');
// //     // socket?.close("received_pairprice", payload);

// //     if (handlerIndex !== -1) {
// //       // remove from handlers
// //       socket.close();
// //       subscriptionItem.handlers.splice(handlerIndex, 1);

// //       // if (payload) socket.off('received_kline', payload);

// //       console.log(payload,
// //         "[unsubscribeBars]: Unsubscribe from streaming. Channel:",
// //         channelString
// //       );
// //       if (subscriptionItem.handlers.length === 0) {
// //         // unsubscribe from the channel, if it was the last handler
// //         channelToSubscription.delete(channelString);
// //         break;
// //       }
// //     }
// //   }
// // }

// let socket = null;
// let lastPayload = null;

// export function subscribeOnStream(
//   symbolInfo,
//   resolution,
//   onRealtimeCallback,
//   subscriberUID,
//   onResetCacheNeededCallback,
//   lastDailyBar
// ) {
//   const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
//   const channelString = `0~${parsedSymbol.exchange}~${parsedSymbol.fromSymbol}~${parsedSymbol.toSymbol}`;
//   const payload = {
//     pair_id: symbolInfo?.id,
//     interval: resolution,
//     k_join: symbolInfo?.id + "-" + resolution,
//   };

//   if (!socket || JSON.stringify(lastPayload) !== JSON.stringify(payload)) {
//     if (socket) {
//       socket.disconnect();
//       socket = null;
//     }

//     socket = io(_constant.ApiUrl);
//     socket.on('connect', () => {
//       console.log('[subscribeOnStream]: Connected to socket');
//       socket.emit('get_kline', payload);
//     });

//     socket.on('disconnect', () => {
//       console.log('[subscribeOnStream]: Disconnected from socket');
//     });

//     socket.on('connect_error', (error) => {
//       console.error('[subscribeOnStream]: Socket connection error:', error);
//     });

//     socket?.emit("get_pairprice", {
//       pair_id: "ALL",
//     });

//    socket?.on("received_pairs_price", (socketData) => {
//     console.log("This is me Pardeep Verma")
//       // Create a copy of symbolsPriceData to modify before updating state
//       console.log("socketData", socketData)
//       socket.emit("get_kline", payload);
//     });
//     socket.on('received_kline', (data) => {
//       // const time = moment.utc(data.c_time * 1000).tz('Asia/Kolkata').unix();
//       const time = data.c_time * 1000;
//       const open = data.open;
//       const high = data.high;
//       const low = data.low;
//       const close = data.close;
//       const volume = data.volume;

//       const bar = {
//         time,
//         close,
//         open,
//         high,
//         low,
//         volume,
//       };

//       onRealtimeCallback(bar);

//       const handler = {
//         id: subscriberUID,
//         callback: onRealtimeCallback,
//       };
//       let subscriptionItem = channelToSubscription.get(channelString);
//       if (subscriptionItem) {
//         // already subscribed to the channel, use the existing subscription
//         subscriptionItem.handlers.push(handler);
//       } else {
//         subscriptionItem = {
//           subscriberUID,
//           resolution,
//           lastDailyBar,
//           handlers: [handler],
//         };
//         channelToSubscription.set(channelString, subscriptionItem);
//       }
//     });

//     // console.log(
//     //   "[subscribeBars]: Subscribe to streaming. Channel:",
//     //   channelString
//     // );
//     lastPayload = payload;
//   } else {
//     // console.log('[subscribeOnStream]: Socket connection and payload have not changed, reusing existing socket connection');
//   }
// }

// export function unsubscribeFromStream(subscriberUID) {
//   for (const channelString of channelToSubscription.keys()) {
//     const subscriptionItem = channelToSubscription.get(channelString);
//     const handlerIndex = subscriptionItem.handlers.findIndex(
//       (handler) => handler.id === subscriberUID
//     );
//     if (handlerIndex !== -1) {
//       // remove from handlers
//       subscriptionItem.handlers.splice(handlerIndex, 1);

//       console.log(
//         "[unsubscribeBars]: Unsubscribe from streaming. Channel:",
//         channelString
//       );
//       if (subscriptionItem.handlers.length === 0) {
//         // unsubscribe from the channel, if it was the last handler
//         channelToSubscription.delete(channelString);
//         break;
//       }
//     }
//   }
// }
