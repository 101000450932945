import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { recentTradeAction } from "../../redux/apiActions/api.action";
import { useSelector } from "react-redux";
import _constants from "../../_constant";
import Moment from "react-moment";
import utill from "../../_constant/utill";
import Loader from "../loader";

export default function MarketHistory({ pair, setTradePrice }) {
  const [recentTrade, setrecentTrade] = useState([]);
  const [loading, setLoading] = useState(false);

  let userData = useSelector((state) => state.user.userData);

  useEffect(async () => {
    if (userData?.access_token) {
      setLoading(true);
      if (pair?.id) {
        let result = await recentTradeAction(pair?.id);
        if (result !== false) {
          setrecentTrade(result?.data);
        }
        setLoading(false);
      }
    }
  }, [pair]);

  return (
    <>
      <div className="market-history recent">
        <Tabs defaultActiveKey="recent-trades">
          <Tab eventKey="recent-trades" title="Recent Trades">
            {loading ? (
              <Loader />
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th>Price({pair?.quote})</th>
                    <th>Amount({pair?.base})</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  {recentTrade.length > 0 ? (
                    recentTrade?.map((trade, key) => (
                      <tr key={key}>
                        <td className={trade?.side === 0 ? `red` : "green"}>
                          {utill.valueFix(trade?.price)}</td>
                        <td>
                          {utill.beautifyNumber(trade?.amount, 5)}
                        </td>
                        <td>
                          {" "}
                          <Moment format="HH:mm:ss">
                            {trade?.created_date}
                          </Moment>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr colSpan={3}>
                      <td className="no-data">
                        <i className="icon ion-md-document"></i>
                        No data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
