import React, { useEffect } from 'react';
import BASE_URL from "../_constant/index";
import { Link } from 'react-router-dom';
import { useHistory, generatePath } from "react-router-dom";
import {
  resetPasswordConformMail
} from "../redux/apiActions/otp.verify.action";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";



export default function Reset() {
  const history = useHistory();

  const dispatch = useDispatch();
  const [loading, setLoader] = React.useState(false);
  const [email, setemail] = React.useState("")
  const [error, seterror] = React.useState(false)
  const [errorMsg, seterrorMsg] = React.useState("")

  const userData = useSelector((state) => state.user.userData);
  useEffect(() => {
    if (userData?.access_token) {
      history.push(generatePath(BASE_URL.BASE_URL));
    }
  }, [userData]);

  const handleChange = (e) => {
    setemail(e.target.value)
    seterror(false)
  }


  const resendOtp = async (e) => {
    setLoader(true);
    e.preventDefault();

    if (!email && !validator.isEmail(email)) {
      seterror(true)
      setLoader(false);
      seterrorMsg("Invalid email address");
    }
    else if (!validator.isEmail(email)) {
      seterror(true)
      setLoader(false);
      seterrorMsg("Invalid email address");
    }
    else {
      if (email && validator.isEmail(email)) {
        localStorage.setItem("E-mail", email)
        let formData = new FormData();
        formData.append("email", email);
        await dispatch(resetPasswordConformMail(formData, history));
        localStorage.setItem("E-mail", email)
        setLoader(false);
      }
    }
  };

  return (
    <>
      <div className="basic_pages">

        <div className="row row-cols-1 row-cols-md-2 row-cols-md-1 row-cols-sm-1 align-items-center gap-v-4" style={{ height: "100%" }}>
          <div className="col d-flex align-items-center justify-content-center">
            <div className="form-access">
              <form >
                <h3 className="mb-3 Welcome" >Forgot <span className="text-orange" >Password</span></h3>
                {/* <span>Reset password</span> */}
                <p className="text-left mb-4">
                  Please enter your registered email ID to send a code
                </p>
                <input
                  type="email"
                  required
                  className="form-control"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={handleChange}
                />
                {error && <small className="error-message text-danger text-wrap m-1">{errorMsg}</small>}
                <div className="d-flex flex-wrap align-items-center justify-content-sm-between justify-content-center mt-4 gap-v-5">
                  <button
                    className="btn btn-primary signinButton mt-0"
                    disabled={
                      loading && (email !== "") ? true : false
                    }
                    onClick={resendOtp}
                  >
                    {loading ? "Please wait..." : "Send OTP"}
                  </button>
                  <h2 className=''>
                    Remember Password?
                    <Link to={BASE_URL.BASE_URL + "login"} className="text-orange"> Sign in here</Link>
                  </h2>
                </div>

               
              </form>
            </div>
          </div>
          <div className="col px-lg-5 px-3 login-background d-flex align-items-center justify-content-center" style={{ height: "100%" }}>
            <div className="p-4 d-flex align-items-center justify-content-center w-100 login_page_right_img">
              <img
                src={`${BASE_URL.BASE_URL}img/images/SignUp.svg`}
                className="img-fluid light-theme-img"
                alt="banner"
                width="450"
              />
              <img
                src={`${BASE_URL.BASE_URL}img/images/Sign.png`}
                className="img-fluid dark-theme-img"
                alt="banner"
                width="450"
              />
            
            </div>
          </div>
        </div>

      </div>
    </>
  );
}
