import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BASE_URL from "../../_constant/index";
import { Link } from "react-router-dom";

const InvestmentAndService = () => {
  return (
    <Container>
      <div className="d-flex justify-content-center mt-5 mb-5">
        <div style={{ width: "80%" }} className="investment_Services p-4">
          <Row>
            <Col md={7}>
              <div className="d-flex flex-wrap">
                <Link className="Spot_Trading_link mr-3 mb-3 mb-3 d-flex align-items-center">
                 <div className="image_background">
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/Wallet.png"}
                    width={"40px"}
                    className="img-fluid img-fluid dark-theme-img"
                  />
                  <img
                    src={
                      BASE_URL.BASE_URL + "img/Vynksafe_Api/Wallet light.png"
                    }
                    width={"40px"}
                    className="img-fluid img-fluid light-theme-img"
                  />
                  </div>
                  Wallet
                </Link>
                <Link className="Spot_Trading_link mr-3 mb-3 d-flex align-items-center">
                <div className="image_background">
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/Saving.png"}
                    width={"40px"}
                    className="img-fluid img-fluid dark-theme-img"
                  />
                  <img
                    src={
                      BASE_URL.BASE_URL + "img/Vynksafe_Api/Saving light.png"
                    }
                    width={"40px"}
                    className="img-fluid img-fluid light-theme-img"
                  />
                  </div>
                  Saving
                </Link>
                <Link className="Spot_Trading_link mr-3 mb-3 d-flex align-items-center">
                <div className="image_background">
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/Mining.png"}
                    width={"40px"}
                    className="img-fluid img-fluid dark-theme-img"
                  />
                  <img
                    src={
                      BASE_URL.BASE_URL + "img/Vynksafe_Api/Mining light.png"
                    }
                    width={"40px"}
                    className="img-fluid img-fluid light-theme-img"
                  />
                  </div>
                  Mining
                </Link>
                <Link className="Spot_Trading_link mr-3 mb-3 d-flex align-items-center">
                <div className="image_background">
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/BSwap.png"}
                    width={"40px"}
                    className="img-fluid img-fluid dark-theme-img"
                  />
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/BSwap light.png"}
                    width={"40px"}
                    className="img-fluid img-fluid light-theme-img"
                  />
                  </div>
                  BSwap
                </Link>
                <Link className="Spot_Trading_link mr-3 mb-3 d-flex align-items-center">
                <div className="image_background">
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/C2C.png"}
                    width={"40px"}
                    className="img-fluid img-fluid dark-theme-img"
                  />
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/C2C light.png"}
                    width={"40px"}
                    className="img-fluid img-fluid light-theme-img"
                  />
                  </div>
                  C2C
                </Link>
                <Link className="Spot_Trading_link mr-3 mb-3 d-flex align-items-center">
                <div className="image_background">
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/NFT.png"}
                    width={"40px"}
                    className="img-fluid img-fluid dark-theme-img"
                  />
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/NFT light.png"}
                    width={"40px"}
                    className="img-fluid img-fluid light-theme-img"
                  />
                  </div>
                  NFT
                </Link>
                <Link className="Spot_Trading_link mr-3 mb-3 d-flex align-items-center">
                <div className="image_background">
                  <img
                    src={
                      BASE_URL.BASE_URL + "img/Vynksafe_Api/Binance Code.png"
                    }
                    width={"40px"}
                    className="img-fluid img-fluid dark-theme-img"
                  />
                  <img
                    src={
                      BASE_URL.BASE_URL +
                      "img/Vynksafe_Api/Binance Code light.png"
                    }
                    width={"40px"}
                    className="img-fluid img-fluid light-theme-img"
                  />
                  </div>
                  Binance Code
                </Link>
                <Link className="Spot_Trading_link mr-3 mb-3 d-flex align-items-center">
                <div className="image_background">
                  
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/Pay.png"}
                    width={"40px"}
                    className="img-fluid img-fluid dark-theme-img"
                  />
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/Pay light.png"}
                    width={"40px"}
                    className="img-fluid img-fluid light-theme-img"
                  />
                  </div>
                  Pay
                </Link>
                <Link className="Spot_Trading_link mr-3 mb-3 d-flex align-items-center">
                <div className="image_background">
                  
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/Fiat.png"}
                    width={"40px"}
                    className="img-fluid img-fluid dark-theme-img"
                  />
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/Fiat light.png"}
                    width={"40px"}
                    className="img-fluid img-fluid light-theme-img ml-1 mr-2"
                  />
                  </div>
                  Fiat
                </Link>
                <Link className="Spot_Trading_link mr-3 mb-3 d-flex align-items-center">
                <div className="image_background">
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/Staking.png"}
                    width={"40px"}
                    className="img-fluid img-fluid dark-theme-img ml-1 mr-2"
                  />
                  <img
                    src={
                      BASE_URL.BASE_URL + "img/Vynksafe_Api/Staking light.png"
                    }
                    width={"40px"}
                    className="img-fluid img-fluid light-theme-img ml-1 mr-2"
                  />
                  </div>
                  Staking
                </Link>
                <Link className="Spot_Trading_link mr-3 mb-3 d-flex align-items-center">
                <div className="image_background">
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/Crypto Loan.png"}
                    width={"40px"}
                    className="img-fluid img-fluid dark-theme-img"
                  />
                  <img
                    src={
                      BASE_URL.BASE_URL +
                      "img/Vynksafe_Api/Crypto Loan light.png"
                    }
                    width={"40px"}
                    className="img-fluid img-fluid light-theme-img"
                  />
                  </div>
                  Crypto Loan
                </Link>
              </div>
            </Col>
            <Col md={5}>
              <div className="d-flex">
                <img
                  src={
                    BASE_URL.BASE_URL +
                    "img/Vynksafe_Api/Investment & Services Icon.png"
                  }
                  className="img-fluid dark-theme-img ml-0 mb-0"
                  alt="investment and Services"
                />
                <img
                  src={
                    BASE_URL.BASE_URL +
                    "img/Vynksafe_Api/Investment & Services Icon light.png"
                  }
                  className="img-fluid light-theme-img ml-0"
                  alt="investment and Services"
                />
              </div>
              <div>
              <h1 className="mb-2 text-center"  style={{ fontSize: "2rem" }}>
                Investment &{" "}
                <span
                  className="text-orange fw-1"
                  style={{ fontSize: "2rem" }}
                >
                  {" "}
                  Services{" "}
                </span>
              </h1>
              <p className="pl-4">
              Simplify your wealth by managing your digital assets in one location.
              </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default InvestmentAndService;
