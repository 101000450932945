import React from "react";
import { Link } from "react-router-dom";
import BASE_URL from "../../_constant/index";

const Register = ({ userMail, setUserMail }) => {
  return (
    <div>
      <section className="subscribe_section_v dark-gray-bg-v pt-5 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto getstarted_inner_section_v p-4">
              <div className="row align-items-center gap-v-4">
                <div className="col-xl-6  col-lg-8 px-md-5 mx-auto py-4">
                  <div className="heading_v mb-4">
                    <div className="banner_main_heading_v text-center">
                      <h1>
                        Register now and get up to{" "}
                        <span className="text-orange fw-1"> $100 </span>
                      </h1>
                    </div>
                  </div>

                  <form>
                    <div className="d-none d-sm-block">
                      <div className="input-group mb-3 ">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Email"
                          value={userMail}
                          onChange={(e) => setUserMail(e.target.value.trim())}
                        />
                        <div className="input-group-append">
                          <Link
                            to={
                              BASE_URL.BASE_URL +
                              "signup" +
                              `?email=${userMail}`
                            }
                            className="btn btn-primary-v text-capitalize"
                          >
                            {/* <a href="https://exchange.vynksafe.com/signup"> */}
                            Get Started
                          </Link>
                          {/* </a> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Register;
