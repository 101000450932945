import * as userServices from "../../services/API/api.service";
import { toast } from "../../components/Toast/Toast";
import BASE_URL from "../../_constant/index";

export const registerAction = (userData, history) => async () => {
  try {
    const response = await userServices.register(userData);
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
      // history.push(BASE_URL.BASE_URL + "otp-verify");
      history.push({
        pathname: BASE_URL.BASE_URL + "otp-verify",
        state: response?.data,
      });
    } else {
      toast.error(response?.data?.message);
    }
  } catch (err) {
    console.log("ERROR FROM RegisterAction", err);
  }
};
