import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
// import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/entry.nostyle";
import "react-calendar/dist/Calendar.css";

const CustomDateRangePicker = ({ dateRange, updateDateRange }) => {
  return (
    <div>
      <DateRangePicker
        onChange={(value) => {
          updateDateRange(value);
        }}
        value={dateRange}
        closeCalendar={true}
        format="dd-MM-y"
        maxDate={new Date()}
        minDate={new Date("01-01-2020")}
        className="date-picker-custom"
        dayPlaceholder="DD"
        monthPlaceholder="MM"
        yearPlaceholder="YYYY"
        rangeDivider="To"
        clearIcon="Clear"
        calendarIcon={<i className="icon ion-md-calendar"></i>}
      />
    </div>
  );
};

export default CustomDateRangePicker;
