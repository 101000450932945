import React from "react";
import BASE_URL from "../../_constant/index";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
const VipInstitutional = () => {
  return (
    <div className="VIPInstitutional">
      <Container>
        <div className="d-flex justify-content-center">
          <div>
            <div className="d-flex justify-content-center">
              <img
                src={
                  BASE_URL.BASE_URL +
                  "/img/Vynksafe_Api/VIP & Institutional  Icon.png"
                }
                className="img-fluid dark-theme-img"
                alt="VIP and Instititional"
              />
              <img
                src={
                  BASE_URL.BASE_URL +
                  "/img/Vynksafe_Api/VIP & Institutional  Icon light.png"
                }
                className="img-fluid light-theme-img"
                alt="VIP and Instititional"
              />
            </div>
            <div className="d-flex justify-content-center">
              <h1 className="mb-2" style={{ fontSize: "2rem" }}>
                VIP &{" "}
                <span className="text-orange fw-1" style={{ fontSize: "2rem" }}>
                  {" "}
                  Institutional{" "}
                </span>
              </h1>
            </div>
            <div className="d-flex justify-content-center">
              <div  className="VIPInstitutionalcontent">
                <Row>
                  <Col md={6}>
                    <div className="VipInstitude">
                      <div>
                        <div className="d-flex justify-content-end mt-3">
                          <p>
                          VYNK SAFE offers VIP & Institutional services, including better fees, larger trading limits, flexible account management, and OTC services, backed by knowledgeable staff and collaboration with various market players who offer customized trading solutions.
                          </p>
                        </div>
                        <div className="d-flex justify-content-start mt-3">
                          <Link className="Spot_Trading_link mr-4 mb-3 d-flex align-items-center">
                           <div className="image_background">
                            <img
                              src={
                                BASE_URL.BASE_URL + "img/Vynksafe_Api/BLVT.png"
                              }
                              width={"40px"}
                              className="img-fluid dark-theme-img"
                            />
                            <img
                              src={
                                BASE_URL.BASE_URL +
                                "img/Vynksafe_Api/BLVT light.png"
                              }
                              width={"40px"}
                              className="img-fluid light-theme-img"
                            />
                            </div>
                            Sub Account
                          </Link>
                          <Link className="Spot_Trading_link mr-4 mb-3 d-flex align-items-center">
                            <div className="image_background">
                            <img
                              src={
                                BASE_URL.BASE_URL + "img/Vynksafe_Api/BLVT.png"
                              }
                              width={"40px"}
                              className="img-fluid dark-theme-img"
                            />
                            <img
                              src={
                                BASE_URL.BASE_URL +
                                "img/Vynksafe_Api/BLVT light.png"
                              }
                              width={"40px"}
                              className="img-fluid light-theme-img"
                            />
                            </div>
                            TAX Report API
                          </Link>
                        </div>
                        <div className="d-flex justify-content-Center mt-3 mb-5">
                          <Button className="">Learn More</Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <img 
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/VIP & Institutional Vector.png"}
                    className="img-fluid dark-theme-img ml-5"
                    alt="VIP and Instititional"
                    width={"270px"}
                 />
                 <img 
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/VIP & Institutional Vector light.png"}
                    className="img-fluid light-theme-img ml-5"
                    alt="VIP and Instititional"
                    width={"270px"}
                 />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default VipInstitutional;
