import { fetch } from "../axios.services";
import _constant from "../../_constant";

const CONSTNAT = _constant.TOKEN;

const BASE_URL = `${_constant.ApiUrl}/exchange`;

const BASE_URL_2 = _constant.BASE_URL_Tow;

export const currencyPair = (crypto) => {
  return fetch("get", `${BASE_URL}/currency_pair/${crypto}`);
};

export const orderBook = (pair) => {
  return fetch("get", `${BASE_URL}/spot/orderbook/${pair}`);
};

export const login = (data) => {
  return fetch("post", `${BASE_URL_2}/api/login`, data, {
    Token: CONSTNAT.LOGINTOKEN,
  });
};

export const loginwith = (data) => {
  return fetch("post", `${BASE_URL_2}/api/login_v1`, data, {
    Token: CONSTNAT.LOGINTOKEN,
  });
};

export const loginwithQrscane = (data) => {
  return fetch("post", `${BASE_URL_2}/api/generate_qr_code`, data, {
    Token: CONSTNAT.LOGINTOKEN,
  });
};

export const loginwithQr = (data) => {
  return fetch("post", `${BASE_URL_2}/api/check_qr_code`, data, {
    Token: CONSTNAT.LOGINTOKEN,
  });
};



export const logOutUser = (headerData) => {
  return fetch("post", `${BASE_URL_2}/api/logout`, {}, headerData);
};

export const notificationDeviceId = (data, headerData) => {
  return fetch("post", `${BASE_URL_2}/api/updateDeviceToken`, data, headerData);
};
//
export const register = (data) => {
  return fetch("post", `${BASE_URL_2}/api/register`, data, {
    Token: CONSTNAT.LOGINTOKEN,
  });
};

export const otpVerifyRegister = (data) => {
  return fetch("post", `${BASE_URL_2}/api/otp_verify_register`, data, {
    Token: CONSTNAT.LOGINTOKEN,
  });
};
export const otpSend = (data) => {
  return fetch("post", `${BASE_URL_2}/api/otp_send`, data, {
    Token: CONSTNAT.LOGINTOKEN,
  });
};
export const resetpassword = (data) => {
  return fetch("post", `${BASE_URL_2}/api/ForgetPass`, data, {
    Token: CONSTNAT.LOGINTOKEN,
  });
};

export const verifyForgetPassword = (data) => {
  return fetch("post", `${BASE_URL_2}api/forgot_otp_verification`, data, {
    Token: CONSTNAT.LOGINTOKEN,
  });
};

export const change_forgot_password = (data) => {
  return fetch("post", `${BASE_URL_2}api/change_forgot_password`, data, {
    Token: CONSTNAT.LOGINTOKEN,
  });
};

export const phoneUpdate = (data, headerData) => {
  return fetch("post", `${BASE_URL_2}/api/mobile_update`, data, headerData);
};
export const updateUserDetails = (data, headerData) => {
  return fetch("post", `${BASE_URL_2}/api/update_user_details`, data, headerData);
};

export const phoneOtpVerify = (data, headerData) => {
  return fetch("post", `${BASE_URL_2}/api/mobile_otp_verify`, data, headerData);
};

export const updateUserOtpVerify = (data, headerData) => {
  return fetch("post", `${BASE_URL_2}/api/update_otp_verify`, data, headerData);
};

export const All_Countries = (data) => {
  return fetch("post", `${BASE_URL_2}/api/country_list`, data, {
    Token: CONSTNAT.LOGINTOKEN,
  });
};

export const All_Currency = (data) => {
  return fetch("post", `${BASE_URL_2}/api/currency_list`, data, {
    Token: CONSTNAT.LOGINTOKEN,
  });
};

export const allSymbols = () => {
  return fetch("get", `${BASE_URL}/symbols`);
};

export const placeOrder = (pair, data, headerData) => {
  return fetch("post", `${BASE_URL}/spot/${pair}`, data, headerData);
};

export const confirmplaceOrder = (pair, data, headerData) => {
  return fetch("post", `${BASE_URL}/spot/confirm/${pair}`, data, headerData);
};

export const recentTrade = (pair) => {
  return fetch("get", `${BASE_URL}/spot/trades/${pair}`);
};

export const getUserbalance = (coin, headerData) => {
  return fetch("get", `${BASE_URL}/user/balance/${coin}`, {}, headerData);
};

export const getAllorders = (coin, page, headerData, date = null) => {
  let url;
  if (date != null) {
    url = `${BASE_URL}/user/all_order/${coin}?page=${page}&start_date=${date?.startDate}&end_date=${date?.endDate}`;
  } else {
    url = `${BASE_URL}/user/all_order/${coin}?page=${page}`;
  }
  return fetch("get", url, {}, headerData);
};

export const getCloseOrders = (pair, page, headerData, date = null) => {
  let url;
  if (date != null) {
    url = `${BASE_URL}/user/get_order/${pair}/3/?page=${page}&start_date=${date?.startDate}&end_date=${date?.endDate}`;
  } else {
    url = `${BASE_URL}/user/get_order/${pair}/3/?page=${page}`;
  }
  return fetch("get", url, {}, headerData);
};

export const getOpenOrders = (pair, page, headerData, date = null) => {
  let url;
  if (date != null) {
    url = `${BASE_URL}/user/get_order/${pair}/0/?page=${page}&start_date=${date?.startDate}&end_date=${date?.endDate}`;
  } else {
    url = `${BASE_URL}/user/get_order/${pair}/0/?page=${page}`;
  }
  return fetch(
    "get",
    // `${BASE_URL}/user/get_order/${pair}/0/?page=${page}`,
    url,
    {},
    headerData
  );
};

export const getFavPairs = (headerData) => {
  return fetch("get", `${BASE_URL}/user/get_pair`, {}, headerData);
};

export const AddRemoveFavPairs = (data, headerData) => {
  return fetch("post", `${BASE_URL}/user/user_fav_coin`, data, headerData);
};

export const cancelOrders = (data, headerData) => {
  return fetch("post", `${BASE_URL}/user/orders/cancel`, data, headerData);
};

export const cancelAllOrders = (pair, headerData) => {
  return fetch(
    "post",
    `${BASE_URL}/user/orders/cancel_all/${pair}`,
    {},
    headerData
  );
};

export const getOrderBalance = (data, headerData) => {
  return fetch("get", `${BASE_URL}/user/fund`, data, headerData);
};

export const allCoins = () => {
  return fetch("get", `${BASE_URL}/coins`);
};
export const getAccountCoinList = (headerData) => {
  return fetch(
    "get",
    `${BASE_URL_2}api_main/block_accounts_coin_list`,
    {},
    headerData
  );
};

export const disable_account_api = (headerData) => {
  return fetch("post", `${BASE_URL_2}api/disable_account`, {}, headerData);
};

export const loginActivity = (headerData) => {
  return fetch("post", `${BASE_URL_2}api/login_activity`, {}, headerData);
};

export const user_Activity = (headerData) => {
  return fetch("get", `${BASE_URL_2}api/user_activity`, {}, headerData);
};

export const user_userInfo = (headerData) => {
  return fetch("get", `${BASE_URL_2}api/userInfo`, {}, headerData);
};


export const getCoinTxnList = (data, headerData) => {
  return fetch(
    "post",
    `${BASE_URL_2}api_main/block_account_transactions`,
    data,
    headerData
  );
};

export const getCoinNetworks = (data, headerData) => {
  return fetch(
    "post",
    `${BASE_URL_2}api_main/block_coin_network_list`,
    data,
    headerData
  );
};
export const getUserAddress = (data, headerData) => {
  return fetch(
    "post",
    `${BASE_URL_2}api_main/get_user_address`,
    data,
    headerData
  );
};
export const getWithdrawGasFee = (url, data, headerData) => {
  return fetch("post", `${url}`, data, headerData);
};
export const WithdrawCoin = (url, data, headerData) => {
  return fetch("post", `${url}`, data, headerData);
};
export const userKyc = (data, headerData) => {
  return fetch("post", `${BASE_URL_2}/api/kyc_details`, data, headerData);
};
export const notification = (headerData) => {
  return fetch("post", `${BASE_URL_2}/api/notification`, {}, headerData);
};



export const nickName = (data, headerData) => {
  return fetch(
    "post",
    `${BASE_URL_2}/api_p2p/update_user_nickname`,
    data,
    headerData
  );
};
export const getnickName = (data, headerData) => {
  return fetch(
    "post",
    `${BASE_URL_2}/api_p2p/Get_user_nickname`,
    data,
    headerData
  );
};
export const notificationDisable = (data, headerData) => {
  return fetch(
    "post",
    `${BASE_URL_2}/api/setUserNotification`,
    data,
    headerData
  );
};
export const manageTradingFee = (data, headerData) => {
  return fetch(
    "post",
    `${BASE_URL_2}/api/manage_trading_fee`,
    data,
    headerData
  );
};
export const change_password = (data, headerData) => {
  return fetch("post", `${BASE_URL_2}/api/changePassword`, data, headerData);
};
export const fundingCoin = (data, headerData) => {
  return fetch(
    "post",
    `${BASE_URL_2}/api_fund/fund_coin_list`,
    data,
    headerData
  );
};
export const fund_coin_transfer_list = (data, headerData) => {
  return fetch(
    "post",
    `${BASE_URL_2}/api_fund/fund_coin_transfer_list`,
    data,
    headerData
  );
};

export const fund_coin_list = (data, headerData) => {
  return fetch(
    "post",
    `${BASE_URL_2}/api_fund/fund_wallet_balance`,
    data,
    headerData
  );
};
export const main_coin_list = (data, headerData) => {
  return fetch(
    "post",
    `${BASE_URL_2}/api_main/block_accounts_balance`,
    data,
    headerData
  );
};
export const fund_transfer = (data, headerData) => {
  return fetch(
    "post",
    `${BASE_URL_2}/api_fund/fund_transfer`,
    data,
    headerData
  );
};

export const reactivateAccount = (data, headerData) => {
  return fetch(
    "post",
    `${BASE_URL_2}api/reactive_account_otp`,
    data,
    headerData
  );
};

export const activationOtp = (data, headerData) => {
  return fetch(
    "post",
    `${BASE_URL_2}api/reactive_account_otp_verification`,
    data,
    headerData
  );
};
export const readNotification = (data, headerData) => {
  return fetch("post", `${BASE_URL_2}api/notification`, data, headerData);
};
export const fund_wallet_history = (data, headerData) => {
  return fetch(
    "post",
    `${BASE_URL_2}api_fund/fund_wallet_transactions`,
    data,
    headerData
  );
};

export const notificationDelete = (data, headerData) => {
  return fetch(
    "post",
    `${BASE_URL_2}api/notification_delete`,
    data,
    headerData
  );
};

export const googleAuth = (data, headerData) => {
  return fetch("post", `${BASE_URL_2}api/googleAuthGetQR`, data, headerData);
};

export const googleAuthVerify = (data, headerData) => {
  return fetch("post", `${BASE_URL_2}api/googleAuthVerifyQR`, data, headerData);
};

export const googleAuthResetRequest = (data, headerData) => {
  return fetch(
    "post",
    `${BASE_URL_2}api/googleAuthResetRequest`,
    data,
    headerData
  );
};
export const googleAuthVerifyEnable = (data, headerData) => {
  return fetch(
    "post",
    `${BASE_URL_2}api/googleAuthVerifyQR_disable`,
    data,
    headerData
  );
};
export const dashboard = (data, headerData) => {
  return fetch("post", `${BASE_URL_2}api/dashboard`, data, headerData);
};
export const googleAuthVerifyQR_enable = (data, headerData) => {
  return fetch(
    "post",
    `${BASE_URL_2}api/googleAuthVerifyQR_enable`,
    data,
    headerData
  );
};
export const otp_send_private = (data, headerData) => {
  return fetch("post", `${BASE_URL_2}api/otp_send_private`, data, headerData);
};
export const otp_verify_private = (data, headerData) => {
  return fetch("post", `${BASE_URL_2}api/otp_verify_private`, data, headerData);
};
export const user_daily_withdraw_limit = (data, headerData) => {
  return fetch(
    "post",
    `${BASE_URL_2}api_main/user_daily_withdraw_limit`,
    data,
    headerData
  );
};
export const wallet_balance_transfer = (data, headerData) => {
  return fetch(
    "post",
    `${BASE_URL_2}api_main/wallet_balance_transfer`,
    data,
    headerData
  );
};

export const subscribe = (data) => {
  return fetch("post", `${BASE_URL}/user/subscribe`, data, {
    Token: CONSTNAT.LOGINTOKEN,
  });
};
export const contactUs = (data) => {
  return fetch("post", `${BASE_URL}/user/contact_us`, data, {
    Token: CONSTNAT.LOGINTOKEN,
  });
}
export const APY_balance = (headerData) => {
  return fetch("get", `${BASE_URL_2}/api_account/get_APY_balance`, {}, headerData);
};
export const calculate_APY = (data, headerData) => {
  return fetch("post", `${BASE_URL_2}/api_account/calculate_APY`, data, headerData);
};
export const apyInterestTransaction = (data, headerData) => {
  return fetch("post", `${BASE_URL_2}/api_account/apy_interest_transaction`, data, headerData);
};

export const trendingPairsList = (headerData) => {
  return fetch("get", `${BASE_URL}/trending_pairs`, {}, headerData);
};



// export const APY_balance = (headerData) => {
//   return fetch("post", `${BASE_URL_2}/api_account/get_APY_balance`, {}, headerData);
// };