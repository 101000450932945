import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory, generatePath } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import BASE_URL from "../../_constant/index";
import {
  // currencyPairAction,
  // orderBookAction,
  allSymbolsAction,
  getFavPairsAction,
  AddRemoveFavPairAction,
} from "../../redux/apiActions/api.action";
// import Cookies from "universal-cookie";
import utill from "../../_constant/utill";
import LoginFirst from "../loginFirst";
import Loader from "../loader";

export default function MarketPairs({
  changeCurrentPair,
  socket,
  setOrderPrice,
  urlPair,
}) {
  const history = useHistory();
  let active_pair = urlPair ? urlPair?.split("/")[1]?.toLowerCase() : "usdt";
  let userData = useSelector((state) => state.user.userData);
  const [currentPair, setCurrentPair] = useState(urlPair);
  const [activeTabPair, setActiveTabPair] = useState(active_pair);
  const [symbols, setSymbols] = useState({});
  const [symbolsPriceData, setSymbolsPriceData] = useState([]);
  const [currPairList, setCurrPairList] = useState([]);
  const [searchSym, setSearchSym] = useState("");
  const [loading, setLoading] = useState(false);
  const [favPairs, setFavPairs] = useState([]);



  // const [orderPrice, setOrderPrice] = useState({});

  // Coin pair list

  // Coin pair list
  const coinPairList = async () => {
    const result = await allSymbolsAction();
    if (result) {
      setSymbols(result);
      const current_dta = result.data.find((p_air) => p_air.symbol === urlPair);
      const prices = result.data.map((ele) => {
        return { symbol: ele.symbol, price: ele.price, last_price: ele.last_price, usd_price: ele.usd_price, changes: ele.changes };
      });
      setSymbolsPriceData(prices);

      if (!current_dta) {
        history.push(generatePath(`${BASE_URL.BASE_URL}notfound`));
      } else {
        setActiveTabPair(current_dta.quote);
        setCurrentPair(current_dta.symbol)
        setOrderPrice(prices.find((p_air) => p_air.symbol === urlPair));
        changeCurrentPair(current_dta);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getUserFavPair();
  }, []);

  const getUserFavPair = async () => {
    if (userData.access_token) {
      const result = await getFavPairsAction();
      setFavPairs(result?.data);
    }
  };

  // Add Remove Fav Pairs :
  const selectFavorite = async (pair_id, status) => {
    if (userData.access_token) {
      const bodyData = { pair_id, status };
      const response = await AddRemoveFavPairAction(bodyData);
      if (response) {
        getUserFavPair();
      }
    }
  };

  useEffect(() => {
    const activeTabPairs = symbols?.data?.filter((pair) => pair.quote === activeTabPair);

    if (activeTabPairs) {
      const activePair = activeTabPairs.find((pair) => pair.symbol === urlPair);
      if (activePair) {
        changeCurrentPair(activePair);
        setOrderPrice(activePair);
        setCurrentPair(activePair.symbol);
      }

      const filteredResponse = activeTabPairs.filter((val) =>
        val.symbol.toLowerCase().includes(searchSym.toLowerCase().trim())
      );
      setCurrPairList(filteredResponse);
    }

  }, [activeTabPair, symbols, searchSym]);
  // const coinPairList = async () => {
  //   let result = await allSymbolsAction();
  //   if (result !== false) {
  //     setSymbols(result);
  //     // setSymbolsData(result?.data);
  //     let current_dta = result?.data?.filter((p_air) => {
  //       if (p_air?.symbol === urlPair) {
  //         return p_air;
  //       }
  //     });
  //     if (!current_dta || current_dta?.length === 0) {
  //       // history.push(generatePath(`${BASE_URL.BASE_URL}notfound`));
  //       window.open(BASE_URL.BASE_URL + "notfound", "_self");
  //     } else {
  //       // setCurrentpair(current_dta[0]);
  //       _activeTabPair(current_dta[0].quote);
  //       setOrderPrice(current_dta[0]);
  //       changeCurrentPair(current_dta[0]);
  //       setLoading(false);
  //     }
  //   }
  //   //  getUserFavPair();
  // };
  // Cookie set for new visitor
  // React.useMemo(() => {
  //   if (cookies.get("FAV_KEY") == undefined) cookies.set("FAV_KEY", []);
  //   setFavorite(cookies.get("FAV_KEY"));
  // }, []);

  // Set favourite key
  // const selectFavoriteCache = (event) => {
  //   let favoritelist = cookies.get("FAV_KEY");
  //   let filtered = favoritelist.indexOf(event);
  //   if (filtered && filtered < 0) {
  //     setFavorite([...favoritelist, event]);
  //     cookies.set("FAV_KEY", [...favoritelist, event]);
  //   } else {
  //     favoritelist.splice(filtered, 1);
  //     setFavorite([...favoritelist]);
  //     cookies.set("FAV_KEY", [...favoritelist]);
  //   }
  // };

  // const [favPairs, setFavPairs] = useState([]);

  // React.useEffect(() => {
  //   getUserFavPair();
  // }, []);

  // const getUserFavPair = async () => {
  //   if (userData?.access_token) {
  //     let result = await getFavPairsAction();
  //     setFavPairs(result?.data);
  //   }
  // };

  // Add Remove Fav Pairs :
  // const selectFavorite = async (pair_id, status) => {
  //   if (userData?.access_token) {
  //     let bodyData = { pair_id: pair_id, status: status };
  //     let response = await AddRemoveFavPairAction(bodyData);
  //     if (response) {
  //       getUserFavPair();
  //     }
  //   }
  // };


  // const pushPairURL = (pair_symbol) => {
  //   history.push(generatePath(`${BASE_URL}exchange/${pair_symbol.replaceAll("/", "_")}`));
  // };

  const pushPairURL = (pair_symbol) => {
    history.push(
      generatePath(
        BASE_URL.BASE_URL + "exchange/" + pair_symbol.replaceAll("/", "_")
      )
    );
  };

  const changeCurrentPairFunc = (pair) => {
    changeCurrentPair(pair);
    setOrderPrice(pair);
    setCurrentPair(pair.symbol)
    // console.log(pair.symbol, "@@@")
    pushPairURL(pair.symbol);
  };



  let tempPriceData = symbolsPriceData;

  useEffect(() => {
    // Emit get_pairprice event to the server to get the initial data
    socket?.emit("get_pairprice", {
      pair_id: "ALL",
    });
  
    // Listen to received_pairprice event from the server
    socket?.on("received_pairs_price", (socketData) => {
      // Create a copy of symbolsPriceData to modify before updating state
      const updatedPriceData = symbolsPriceData.map((item) => ({ ...item }));
  
      socketData.forEach((data) => {
        const index = updatedPriceData.findIndex((item) => item.symbol === data.symbol);
  
        if (index !== -1 && parseFloat(data.price) > 0) {
          updatedPriceData[index].price = data.price;
          updatedPriceData[index].usd_price = data.usd_price;
          updatedPriceData[index].last_price = data.last_price;
          updatedPriceData[index].changes = data.changes;
        }
  
        if (data.symbol === currentPair) {
          setOrderPrice(data);
        }
      });
  
      // Update the state with the modified price data
      setSymbolsPriceData(updatedPriceData);
    });
  
    // Cleanup: Remove event listener when component unmounts
    return () => {
      socket?.off("received_pairs_price");
    };
  }, [socket, currentPair, symbolsPriceData, setOrderPrice]);
  
  /*
  useEffect(() => {
    // Emit get_pairprice event to the server to get the initial data
    socket?.emit("get_pairprice", {
      pair_id: "ALL",
    });


    // Listen to received_pairprice event from the server
    socket?.on("received_pairs_price", (socketData) => {
      if (tempPriceData?.length > 0) {
        // let tempPriceData = symbolsPriceData;
        tempPriceData.map((item, index) => {
          socketData.map((data) => {
            if (item.symbol === data.symbol && parseFloat(data.price) > 0) {
              tempPriceData[index].price = data.price;
              // tempPriceData[index].price = 76725267;
              tempPriceData[index].usd_price = data.usd_price;
              tempPriceData[index].last_price = data.last_price;
              tempPriceData[index].changes = data.changes;
            }
            if (data.symbol === currentPair) {
              setOrderPrice(data);
              // setOrderPrice({ ...data, price: Math.random(1) * 1000 });
            }
          })
        })
      }
      setSymbolsPriceData(tempPriceData)

      return () => {
        socket?.off("received_pairs_price");
      };
      // return false
      // if (data.symbol === currentPair) {
      //   setOrderPrice(data);
      //   // setOrderPrice({ ...data, price: Math.random(1) * 1000 });
      // }
      // setSymbolsPriceData((prevState) => {
      //   const updatedData = prevState?.map((obj) => {
      //     if (obj.symbol === data.symbol) {
      //       obj.price = data.price;
      //       obj.usd_price = data.usd_price;
      //       obj.last_price = data.last_price;
      //       obj.changes = data.changes;
      //     }
      //     return obj;
      //   });
      //   return updatedData;
      // });
    });
    // socket?.on("received_pairprice", (data) => {
    //   if (data.symbol === currentPair) {
    //     setOrderPrice(data);
    //     // setOrderPrice({ ...data, price: Math.random(1) * 1000 });
    //   }
    //   setSymbolsPriceData((prevState) => {
    //     const updatedData = prevState?.map((obj) => {
    //       if (obj.symbol === data.symbol) {
    //         // return {
    //         //   ...obj,
    //         obj.price = data.price;
    //         // obj.price = Math.random(1) * 100;
    //         obj.usd_price = data.usd_price;
    //         obj.last_price = data.last_price;
    //         obj.changes = data.changes;
    //         // };
    //       }
    //       return obj;
    //     });

    //     // console.log("Updated:", updatedData.find((obj) => obj.symbol === data.symbol));
    //     return updatedData;

    //   });


    // });

    // Clean up the event listener
    // return () => {
    //   socket?.off("received_pairprice");
    // };
  }, [socket, currentPair, tempPriceData, setSymbolsPriceData]);
*/
  // React.useMemo(() => {
  //   let activeTabPairs = symbols?.data?.filter((pair) => {
  //     return pair?.quote === activeTabPair;
  //   });


  //   if (activeTabPairs !== undefined) {

  //     let activePair = activeTabPairs.filter((pair) => { return pair.symbol === urlPair; });
  //     if (activePair.length > 0) {
  //       changeCurrentPair(activePair[0])
  //       setOrderPrice(activePair[0]);
  //     }

  //     let filteredResponse = activeTabPairs.filter((val) => {
  //       if (searchSym === " ") {
  //         return val;
  //       } else if (
  //         val?.symbol
  //           ?.toLowerCase()
  //           .includes(searchSym.toLowerCase().trim())
  //       ) {
  //         return val;
  //       }
  //     });
  //     if (filteredResponse !== undefined) {
  //       setCurrPairList(filteredResponse);
  //     }
  //   }
  // }, [activeTabPair, symbols, searchSym]);

  // // const [pairId, setPairId] = useState();

  // const pushPairURL = (pair_symbol) => {
  //   let myPairPath = history.push(
  //     generatePath(
  //       BASE_URL.BASE_URL + "exchange/" + pair_symbol.replaceAll("/", "_")
  //     )
  //   );
  // };

  // // To change current pair
  // const changeCurrentPairFunc = (pair) => {
  //   // setCurrentpair(pair);
  //   changeCurrentPair(pair);
  //   setOrderPrice(pair);
  //   // setPairId(pair);
  //   pushPairURL(pair?.symbol);
  //   // history.push(
  //   //   generatePath(
  //   //     BASE_URL.BASE_URL + "exchange/" + (pair?.symbol).replaceAll("/", "_")
  //   //   )
  //   // );
  // };

  // useEffect(() => {
  //   socket?.emit("get_pairprice", {
  //     pair_id: "ALL",
  //   });

  //   socket?.on("received_pairprice", (data) => {
  //     console.log("received_pairprice", data);
  //     setSymbols((prevState) => {
  //       let prevData = prevState;

  //       let idx = prevData?.data?.findIndex(function (object) {
  //         return object?.symbol === data?.symbol;
  //       });
  //       if (idx > 0) {

  //         const existOrders = [...prevData];
  //         existOrders.data[idx] = {
  //           // id: data?.id,
  //           // base: data?.base,
  //           // symbol: data?.symbol,
  //           // quote: data?.quote,
  //           // isBuyAllowed: data?.isBuyAllowed,
  //           // isSellAllowed: data?.isSellAllowed,
  //           // price: Math.random(0, 100),
  //           price: data?.price,
  //           usd_price: data?.usd_price,
  //           // last_price: Math.random(0, 100),
  //           last_price: data?.last_price,
  //           changes: data?.changes,
  //         };
  //         console.log("Updated:", existOrders?.data[idx])
  //         return existOrders;
  //       } else {
  //         return prevState;
  //       }
  //     });


  //     console.log("Updated order")
  //   });
  // }, []);

  // React.useMemo(() => {
  //   let idx = symbolsData?.findIndex(function (object) {
  //     return object?.id === currentPair?.id;
  //   });
  //   setCurrentpair(symbolsData[idx]);
  //   // setOrderPrice(symbolsData[idx]);
  // }, [symbolsData]);

  let scroll = React.useRef(null);
  const [scrollX, setScrollX] = useState(0);
  const [scrollEnd, setScrollEnd] = useState(false);

  const scrollCheck = () => {
    setScrollX(scroll.current.scrollLeft);
    if (
      Math.floor(scroll.current.scrollWidth - scroll.current.scrollLeft) <=
      scroll.current.offsetWidth
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };
  const slide = (shift) => {
    scroll.current.scrollLeft += shift;
    setScrollX(scrollX + shift);
    if (
      Math.floor(scroll.current.scrollWidth - scroll.current.scrollLeft) <=
      scroll.current.offsetWidth
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  React.useEffect(() => {
    setLoading(true);
    coinPairList();
  }, []);


  return (
    <>
      <div className="market-pairs">
        <div className="input-group">


          <input
            type="text"
            className="form-control"
            placeholder="Search"
            aria-describedby="inputGroup-sizing-sm"
            onChange={(e) => {
              setSearchSym(e.target.value);
            }}
          />
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-sm">
              <i className="icon ion-md-search"></i>
            </span>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={activeTabPair.toLowerCase()}
            >
              <div className="pair_tab_block">
                <div className="" style={{ position: "relative" }}>
                  {scrollX !== 0 && (
                    <button
                      className="custom_tab_btn prev"
                      onClick={() => slide(-50)}
                    >
                      <svg
                        width="7"
                        height="44"
                        viewBox="0 0 25 44.5"
                        version="1.1"
                      >
                        <path
                          d="M22.5 2.5L2.5 22.5L22 42"
                          id="Vector"
                          fill="none"
                          fillRule="evenodd"
                          stroke="#fff"
                          strokeWidth="5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  )}
                  <div className="mytabs_block">
                    <Nav variant="pills" ref={scroll} onScroll={scrollCheck}>
                      <Nav.Item>
                        <Nav.Link eventKey="star" title="★" value="star">
                          ★
                        </Nav.Link>
                      </Nav.Item>
                      {symbols?.pair?.map((pair, key) => {
                        return (
                          <Nav.Item key={key}>
                            <Nav.Link
                              eventKey={pair?.symbol?.toLocaleLowerCase()}
                              title={pair?.symbol}
                              value={pair?.symbol}
                              onClick={(e) => {
                                setActiveTabPair(e.target.innerText);
                              }}
                            >
                              {pair?.symbol}
                            </Nav.Link>
                          </Nav.Item>
                        );
                      })}
                    </Nav>
                  </div>
                  {!scrollEnd ? (
                    <button
                      className="custom_tab_btn next"
                      onClick={() => slide(+50)}
                    >
                      <svg
                        width="7"
                        height="44"
                        viewBox="0 0 25.000092 44.50006"
                        version="1.1"
                      >
                        <path
                          d="M2.5 42L22.5001 22.0001L3.00009 2.5"
                          id="Vector"
                          fill="none"
                          fillRule="evenodd"
                          stroke="#fff"
                          strokeWidth="5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  ) : (
                    null
                  )}
                </div>
                <div className="mytab_content_block">
                  <Tab.Content>
                    <Tab.Pane eventKey="star">
                      {userData?.access_token ? (<>
                        {
                          favPairs.length > 0 ? (<>
                            <table className="table star-active">
                              <thead>
                                <tr>
                                  <th>Pairs</th>
                                  <th>Price</th>
                                  <th>Change</th>
                                </tr>
                              </thead>

                              <tbody>
                                {favPairs
                                  ?.filter((val) => {
                                    if (searchSym === " ") {
                                      return val;
                                    } else if (
                                      val?.symbol
                                        ?.toLowerCase()
                                        .includes(searchSym.toLowerCase().trim())
                                    ) {
                                      return val;
                                    }
                                  })
                                  .map((val, key) => {
                                    let dta = symbols?.data?.filter((p_air) => {
                                      return p_air?.symbol === val?.symbol;
                                    });
                                    let change = String(val?.changes).charAt(0);
                                    let priceObj = symbolsPriceData.find((obj) => obj.symbol === val.symbol)
                                    return (
                                      <tr
                                        onClick={() => {
                                          changeCurrentPairFunc(dta[0]);
                                        }}
                                        key={key}
                                      >
                                        <td>
                                          <i
                                            className="icon ion-md-star"
                                            onClick={(e) => {
                                              selectFavorite(val?.pair_id, "0");
                                              e.stopPropagation();
                                            }}
                                          ></i>{" "}
                                          {val?.symbol}
                                        </td>
                                        <td>
                                          {utill.valueFix(Number(priceObj?.price))}
                                        </td>
                                        <td
                                          className={
                                            change === "-" ? `red` : "green"
                                          }
                                        >
                                          {change === "-" ? "" : "+"}
                                          {utill.beautifyNumber(
                                            Number(priceObj?.changes),
                                            2
                                          )}
                                          %
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </>)
                            : (
                              <table className="table star-active" style={{ minHeight: "300px" }}>
                                <thead>
                                  <tr>
                                    <th>Pairs</th>
                                    <th>Price</th>
                                    <th>Change</th>
                                  </tr>
                                </thead>
                                <div className="no-data" >
                                  <i className="icon ion-md-document"></i>
                                  No data
                                </div>
                              </table>
                            )
                        }
                      </>) : (
                        <LoginFirst return_to={btoa(window.location.href)} />
                      )}
                    </Tab.Pane>
                    {symbols?.pair?.map((pair, idx) => {
                      return (
                        <Tab.Pane
                          key={idx}
                          eventKey={pair?.symbol?.toLowerCase()}
                        >
                          {loading ? (
                            <Loader />
                          ) : (
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Pairs</th>
                                  <th>Price</th>
                                  <th>Change</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* // Db Current List */}
                                {currPairList
                                  .map((pair, key) => {
                                    let change = String(pair?.changes).charAt(
                                      0
                                    );
                                    let isFav = favPairs?.filter((dta) => {
                                      return dta.symbol === pair.symbol;
                                    });
                                    let priceObj = symbolsPriceData.find((obj) => obj.symbol === pair.symbol)
                                    return (
                                      <tr
                                        onClick={() => {
                                          changeCurrentPairFunc(pair);
                                        }}
                                        key={key}
                                      >
                                        {userData?.access_token ? (
                                          <td className="star_icon_td">
                                            <i
                                              className={`icon ion-md-star ${isFav?.length > 0
                                                ? "star_active"
                                                : ""
                                                }`}
                                              // className="icon ion-md-star"
                                              onClick={(e) => {
                                                selectFavorite(
                                                  pair?.id,
                                                  isFav.length > 0 ? "0" : "1"
                                                );
                                                e.stopPropagation();
                                              }}
                                            ></i>{" "}
                                            {pair?.symbol}
                                          </td>
                                        ) : (
                                          <td className="star_icon_td">
                                            {pair?.symbol}
                                          </td>
                                        )}
                                        <td>
                                          {utill.beautifyNumber(utill.valueFix(Number(priceObj?.price)), 10)}
                                          {/* {utill.valueFix(Number(priceObj?.price))} */}
                                        </td>
                                        <td
                                          className={
                                            change === "-" ? `red` : "green"
                                          }
                                        >
                                          {change === "-" ? "" : "+"}
                                          {utill.beautifyNumber(
                                            Number(priceObj?.changes),
                                            2
                                          )}
                                          %
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          )}
                        </Tab.Pane>
                      );
                    })}
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </>
        )}
      </div>
    </>
  );
}

// <Tabs
//   className="tabbar_block_scroll"
//   defaultActiveKey={activeTabPair.toLowerCase()}
//   onClick={(e) => {
//     _activeTabPair(e.target.innerText);
//   }}
// >
//   <Tab eventKey="star" title="★" value="star">
//     {userData?.access_token ? (
//       <table className="table star-active">
//         <thead>
//           <tr>
//             <th>Pairs</th>
//             <th>Price</th>
//             <th>Change</th>
//           </tr>
//         </thead>
//         <tbody>
//           {favPairs
//             ?.filter((val) => {
//               if (searchSym === " ") {
//                 return val;
//               } else if (
//                 val?.symbol
//                   ?.toLowerCase()
//                   .includes(searchSym.toLowerCase().trim())
//               ) {
//                 return val;
//               }
//             })
//             .map((val, key) => {
//               let dta = symbolsData?.filter((p_air) => {
//                 return p_air?.symbol === val?.symbol;
//               });
//               let change = String(val?.changes).charAt(0);
//               return (
//                 <tr
//                   onClick={() => {
//                     changeCurrentPairFunc(dta[0]);
//                   }}
//                   key={key}
//                 >
//                   <td>
//                     <i
//                       className="icon ion-md-star"
//                       onClick={(e) => {
//                         selectFavorite(val?.pair_id, "0");
//                         e.stopPropagation();
//                       }}
//                     ></i>{" "}
//                     {val?.symbol}
//                   </td>
//                   <td>{utill.valueFix(Number(val?.price))}</td>
//                   <td className={change === "-" ? `red` : "green"}>
//                     {change === "-" ? "" : "+"}
//                     {utill.beautifyNumber(Number(val?.changes), 2)}%
//                   </td>
//                 </tr>
//               );
//             })}

//           {/* // Cache stored favorite Pairs */}
//           {favorite
//             .filter((val) => {
//               if (searchSym === " ") {
//                 return val;
//               } else if (
//                 val
//                   .toLowerCase()
//                   .includes(searchSym.toLowerCase().trim())
//               ) {
//                 return val;
//               }
//             })
//             .map((pair, key) => {
//               let dta = symbolsData?.filter((p_air) => {
//                 return p_air?.symbol === pair;
//               });
//               if (dta != undefined) {
//                 let change = String(dta[0]?.changes).charAt(0);
//                 return (
//                   <tr
//                     className="Cachee"
//                     onClick={() => {
//                       changeCurrentPairFunc(dta[0]);
//                     }}
//                     key={key}
//                   >
//                     <td>
//                       <i
//                         className="icon ion-md-star"
//                         onClick={(e) => {
//                           selectFavoriteCache(pair);
//                           e.stopPropagation();
//                         }}
//                       ></i>{" "}
//                       {dta[0]?.symbol}
//                     </td>
//                     <td>{utill.valueFix(Number(dta[0]?.price))}</td>
//                     <td className={change === "-" ? `red` : "green"}>
//                       {change === "-" ? "" : "+"}
//                       {utill.beautifyNumber(
//                         Number(dta[0]?.changes),
//                         2
//                       )}
//                       %
//                     </td>
//                   </tr>
//                 );
//               }
//             })}
//         </tbody>
//       </table>
//     ) : (
//       <LoginFirst />
//     )}
//   </Tab>
//   {symbols?.pair?.map((pair, key) => (
//     <Tab
//       eventKey={pair?.symbol?.toLocaleLowerCase()}
//       title={pair?.symbol}
//       value={pair?.symbol}
//       key={key}
//     >
//       {loading ?
//         <Loader />
//         :
//         <table className="table">
//           <thead>
//             <tr>
//               <th>Pairs</th>
//               <th>Price</th>
//               <th>Change</th>
//             </tr>
//           </thead>
//           <tbody>
//             {/* // Db Current List */}
//             {currPairList
//               .filter((val) => {
//                 if (searchSym === " ") {
//                   return val;
//                 } else if (
//                   val?.symbol
//                     ?.toLowerCase()
//                     .includes(searchSym.toLowerCase().trim())
//                 ) {
//                   return val;
//                 }
//               })
//               .map((pair, key) => {
//                 let change = String(pair?.changes).charAt(0);
//                 let isFav = favPairs?.filter((dta) => {
//                   return dta.symbol === pair.symbol;
//                 });
//                 return (
//                   <tr
//                     onClick={() => {
//                       changeCurrentPairFunc(pair);
//                     }}
//                     key={key}
//                   >
//                     {userData?.access_token ? (
//                       <td className="star_icon_td">
//                         <i
//                           className={`icon ion-md-star ${isFav?.length > 0 ? "star_active" : ""
//                             }`}
//                           className="icon ion-md-star"
//                           onClick={(e) => {
//                             selectFavorite(
//                               pair?.id,
//                               isFav.length > 0 ? "0" : "1"
//                             );
//                             e.stopPropagation();
//                           }}
//                         ></i>{" "}
//                         {pair?.symbol}
//                       </td>
//                     ) : (
//                       <td className="star_icon_td">{pair?.symbol}</td>
//                     )}
//                     <td>{utill.valueFix(Number(pair?.price))}</td>
//                     <td className={change === "-" ? `red` : "green"}>
//                       {change === "-" ? "" : "+"}
//                       {utill.beautifyNumber(Number(pair?.changes), 2)}%
//                     </td>
//                   </tr>
//                 );
//               })}
//             // Cache Current list block
//             {currPairList
//               .filter((val) => {
//                 if (searchSym === " ") {
//                   return val;
//                 } else if (
//                   val?.symbol
//                     ?.toLowerCase()
//                     .includes(searchSym.toLowerCase().trim())
//                 ) {
//                   return val;
//                 }
//               })
//               .map((pair, key) => {
//                 console.log(favorite);
//                 let filtered = favorite.indexOf(pair?.symbol);
//                 let change = String(pair?.changes).charAt(0);
//                 return (
//                   <tr
//                     className=""
//                     onClick={() => {
//                       changeCurrentPairFunc(pair);
//                     }}
//                     key={key}
//                   >
//                     <td className="star_icon_td">
//                       <i
//                         className={`icon ion-md-star ${filtered === -1 ? `` : "star_active"
//                           }`}
//                         onClick={(e) => {
//                           selectFavoriteCache(pair?.symbol);
//                           e.stopPropagation();
//                         }}
//                       ></i>{" "}
//                       {pair?.symbol}
//                     </td>
//                     <td>
//                       {utill.beautifyNumber(Number(pair?.price), 8)}
//                     </td>
//                     <td className={change === "-" ? `red` : "green"}>
//                       {change === "-" ? "" : "+"}
//                       {utill.beautifyNumber(Number(pair?.changes), 2)}
//                       %
//                     </td>
//                   </tr>
//                 );
//               })}
//           </tbody>
//         </table>
//       }
//     </Tab>
//   ))}
// </Tabs>
