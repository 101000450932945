import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import BASE_URL from "../../_constant/index";
import { Link } from "react-router-dom";
const LinkProgram = () => {
  return (
    <Container>
      <Row>
        <Col md={6} className="d-flex align-items-center">
          <img
            src={
              BASE_URL.BASE_URL + "/img/Vynksafe_Api/Link Program Vector.png"
            }
            className="img-fluid dark-theme-img"
            alt="Link Program"
            width={"520px"}
          />
          <img
            src={
              BASE_URL.BASE_URL +
              "/img/Vynksafe_Api/Link Program Vector light.png"
            }
            className="img-fluid light-theme-img"
            alt="Link Program"
            width={"500px"}
          />
        </Col>
        <Col md={6}>
          <div className="linkkprogram">
            <div className="mt-3 linkkprogram_conent">
              <img
                src={
                  BASE_URL.BASE_URL + "/img/Vynksafe_Api/Link Program Icon.png"
                }
                className="ml-0 dark-theme-img"
                alt="Link Program"
                width={"100px"}
              />
              <img
                src={
                  BASE_URL.BASE_URL +
                  "/img/Vynksafe_Api/Link Program Icon light.png"
                }
                className="ml-0 light-theme-img"
                alt="Link Program"
                width={"100px"}
              />
              <h1 className="mb-2" style={{ fontSize: "2rem" }}>
                Link{" "}
                <span className="text-orange fw-1" style={{ fontSize: "2rem" }}>
                  {" "}
                  Program{" "}
                </span>
              </h1>
            </div>
            <div>
              <div className="d-flex justify-content-end mt-3">
                <p>
                Take Advantage of the VYNKSAFE Link Program to Expand Your Business Potential: Using our liquidity and market depth, you can leverage our technology to grow your business and earn commissions from trading fees!

                </p>
              </div>
              <div className="d-flex flex-wrap justify-content-start mt-3">
                <Link className="Spot_Trading_link mr-4 mb-3 d-flex align-items-center">
                  <div className="image_background">
                    <img
                      src={BASE_URL.BASE_URL + "img/Vynksafe_Api/Liquidity.png"}
                      width={"40px"}
                      className="img-fluid dark-theme-img"
                    />
                    <img
                      src={
                        BASE_URL.BASE_URL +
                        "img/Vynksafe_Api/Liquidity light.png"
                      }
                      width={"40px"}
                      className="img-fluid light-theme-img"
                    />
                  </div>
                  Liqudity
                </Link>
                <Link className="Spot_Trading_link mr-4 mb-3 d-flex align-items-center">
                  <div className="image_background">
                    <img
                      src={
                        BASE_URL.BASE_URL + "img/Vynksafe_Api/Fiat Gateway.png"
                      }
                      width={"40px"}
                      className="img-fluid dark-theme-img"
                    />
                    <img
                      src={
                        BASE_URL.BASE_URL +
                        "img/Vynksafe_Api/Fiat Gateway light.png"
                      }
                      width={"40px"}
                      className="img-fluid light-theme-img"
                    />
                  </div>
                  Fiat Gateway
                </Link>
                <Link className="Spot_Trading_link mr-4 mb-3 d-flex align-items-center">
                  <div className="image_background">
                    <img
                      src={
                        BASE_URL.BASE_URL +
                        "img/Vynksafe_Api/Link Sub Account.png"
                      }
                      width={"40px"}
                      className="img-fluid dark-theme-img"
                    />
                    <img
                      src={
                        BASE_URL.BASE_URL +
                        "img/Vynksafe_Api/Link Sub Account light.png"
                      }
                      width={"40px"}
                      className="img-fluid light-theme-img"
                    />
                  </div>
                  Link Sub Account
                </Link>
                <Link className="Spot_Trading_link mr-4 mb-3 d-flex align-items-center">
                  <div className="image_background">
                    <img
                      src={BASE_URL.BASE_URL + "img/Vynksafe_Api/Rebates.png"}
                      width={"40px"}
                      className="img-fluid dark-theme-img"
                    />
                    <img
                      src={
                        BASE_URL.BASE_URL + "img/Vynksafe_Api/Rebates light.png"
                      }
                      width={"40px"}
                      className="img-fluid light-theme-img"
                    />
                  </div>
                  Rebates
                </Link>
              </div>
              <div className="d-flex justify-content-Center mt-3 mb-5">
                <Button className="">Learn More</Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LinkProgram;
