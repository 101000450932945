import { Nav } from "react-bootstrap";
import utill from "../../_constant/utill";
const CoinListItem = ({ coin, active, handelClick }) => {
  return (
    <Nav.Item>
      <Nav.Link
        eventKey={`wallet_` + coin?.symbol}
        className={`d-flex justify-content-between align-items-center ${active}`}
        onClick={() => {
          handelClick(coin);
        }}
      >
        <div className="d-flex">
          <img src={coin?.image} alt={coin?.code} />
          <div>
            <h2>{coin?.symbol}</h2>
            <p>{coin?.name}</p>
          </div>
        </div>
        <div>
          <h3 style={{fontSize:"16px"}}>
            {utill.beautifyNumber(
              coin?.balance,
              coin?.decimal > 5 ? 6 : coin?.decimal
            )}
          </h3>
          <p className="text-right">
            ${utill.beautifyNumber(coin?.balance_currency, 2)}
          </p>
        </div>
      </Nav.Link>
    </Nav.Item>
  );
};

export default CoinListItem;
