import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BASE_URL from "../../_constant/index";

const DisablePop = ({ reactivateAccount, show, handleClose, handleShow }) => {

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                className="withdrawal_confirmation_modal"
                contentClassName="content_Size_disable"
            >
                <Modal.Body className="text-center">
                    <i className="icon ion-md-warning d-flex align-items-center justify-content-center mb-3" style={{ color: "#f85238", fontSize: "65px" }}>
                    </i>
                    {/* <div><img src={`${BASE_URL.BASE_URL}img/security/disable.png`} alt="withdrawal confirmation" width="65" className="mb-3" /></div> */}
                    Your account has been blocked. Please unlock your account by logging in via password, or contact Customer Support.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={reactivateAccount}>UnLock</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DisablePop