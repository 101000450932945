import React, { useState } from "react";
import { Accordion, Card, Row, Container, Col } from "react-bootstrap";
import BASE_URL from "../../_constant/index";

const FAQ = () => {
  const [accordionOpen, setAccordionOpen] = useState(null);

  const handleAccordionToggle = (eventKey) => {
    setAccordionOpen((prevState) => (prevState === eventKey ? null : eventKey));
  };

  return (
    <Container>
    <div className="mt-5 mb-5">
      <div className="d-flex justify-content-center">
        <h1 className="mb-2 text-center" style={{ fontSize: "2rem" }}>
          Frequently Asked{" "}
          <span className="text-orange fw-1" style={{ fontSize: "2rem" }}>
            Question{" "}
          </span>
        </h1>
      </div>
      <div>
       
          <Row>
            <Col className="d-flex justify-content-center">
              <div className="linkkprogram">
                <Accordion className="w-100">
                  <Card style={{ borderRadius: "0px" }} className="mt-3 cardBorder">
                    <Accordion.Toggle
                      as={Card.Header}
                      className="FAQs"
                      eventKey="0"
                      onClick={() => handleAccordionToggle("0")}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <h6>What trading services does the VYNKSAFE API offer?</h6>
                        <img
                          src={
                            BASE_URL.BASE_URL +
                            "img/Vynksafe_Api/" +
                            (accordionOpen === "0"
                              ? "Open FAQ"
                              : "Dropdown FAQ") +
                            ".png"
                          }
                          width={"33px"}
                          className="img-fluid dark-theme-img ml-1 mr-2"
                        />
                        <img
                          src={
                            BASE_URL.BASE_URL +
                            "img/Vynksafe_Api/" +
                            (accordionOpen === "0"
                              ? "Open FAQ light"
                              : "Dropdown FAQ light") +
                            ".png"
                          }
                          width={"33px"}
                          className="img-fluid light-theme-img ml-1 mr-2"
                        />
                      </div>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="FAQs">
                      <p className="pt-2"> The VYNK SAFE API provides connectivity for Spot trading across 300+ digital and fiat currencies. It offers a testing environment, comprehensive API documentation, and sample code in various programming languages for an easy and accessible trading experience.</p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card style={{ borderRadius: "0px" }} className="mt-3 cardBorder">
                    <Accordion.Toggle
                      as={Card.Header}
                      className="FAQs"
                      eventKey="1"
                      onClick={() => handleAccordionToggle("1")}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                      <h6> What are the benefits of VIP & Institutional services offered by VYNKSAFE?</h6>
                        <img
                          src={
                            BASE_URL.BASE_URL +
                            "img/Vynksafe_Api/" +
                            (accordionOpen === "1"
                              ? "Open FAQ"
                              : "Dropdown FAQ") +
                            ".png"
                          }
                          width={"33px"}
                          className="img-fluid dark-theme-img ml-1 mr-2"
                        />
                        <img
                          src={
                            BASE_URL.BASE_URL +
                            "img/Vynksafe_Api/" +
                            (accordionOpen === "1"
                              ? "Open FAQ light"
                              : "Dropdown FAQ light") +
                            ".png"
                          }
                          width={"33px"}
                          className="img-fluid light-theme-img ml-1 mr-2"
                        />
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body className="FAQs">
                      <p className="pt-2"> VIP & Institutional services include better fees, larger trading limits, flexible account management, and OTC services. Our knowledgeable staff collaborates with various market players to offer customized trading solutions.</p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card style={{ borderRadius: "0px" }} className="mt-3 cardBorder">
                    <Accordion.Toggle
                      as={Card.Header}
                      className="FAQs"
                      eventKey="2"
                      onClick={() => handleAccordionToggle("2")}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                      <h6> What historical data services are provided by the VYNK SAFE API?</h6>
                        <img
                          src={
                            BASE_URL.BASE_URL +
                            "img/Vynksafe_Api/" +
                            (accordionOpen === "2"
                              ? "Open FAQ"
                              : "Dropdown FAQ") +
                            ".png"
                          }
                          width={"33px"}
                          className="img-fluid dark-theme-img ml-1 mr-2"
                        />
                        <img
                          src={
                            BASE_URL.BASE_URL +
                            "img/Vynksafe_Api/" +
                            (accordionOpen === "2"
                              ? "Open FAQ light"
                              : "Dropdown FAQ light") +
                            ".png"
                          }
                          width={"33px"}
                          className="img-fluid light-theme-img ml-1 mr-2"
                        />
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body className="FAQs">
                      <p className="pt-2"> 
                        VYNK SAFE API offers historical data services to help you analyze past market trends and make informed trading decisions.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card style={{ borderRadius: "0px" }} className="mt-3 cardBorder">
                    <Accordion.Toggle
                      as={Card.Header}
                      className="FAQs"
                      eventKey="3"
                      onClick={() => handleAccordionToggle("2")}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <h6>How can one expand business potential with the VYNK SAFE Link Program?</h6>
                        <img
                          src={
                            BASE_URL.BASE_URL +
                            "img/Vynksafe_Api/" +
                            (accordionOpen === "2"
                              ? "Open FAQ"
                              : "Dropdown FAQ") +
                            ".png"
                          }
                          width={"33px"}
                          className="img-fluid dark-theme-img ml-1 mr-2"
                        />
                        <img
                          src={
                            BASE_URL.BASE_URL +
                            "img/Vynksafe_Api/" +
                            (accordionOpen === "2"
                              ? "Open FAQ light"
                              : "Dropdown FAQ light") +
                            ".png"
                          }
                          width={"33px"}
                          className="img-fluid light-theme-img ml-1 mr-2"
                        />
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body className="FAQs">
                        <p className="pt-2"> 
                        Leverage VYNK SAFE's liquidity and market depth through the Link Program to grow your business and earn commissions from trading fees.
                        </p>
                        
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </Col>
          </Row>
       
      </div>
    </div>
    </Container>
  );
};

export default FAQ;
