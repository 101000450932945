import React from "react";
import BASE_URL from "../../_constant/index";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
const MadeForDeveloper = () => {
  return (
    <div className="VIPInstitutional">
      <Container>
        <div className="d-flex justify-content-center">
          <div>
            <div className="d-flex justify-content-center">
              <img
                src={BASE_URL.BASE_URL + "/img/Vynksafe_Api/Icon.png"}
                className="img-fluid dark-theme-img"
                alt="VIP and Instititional"
              />
              <img
                src={BASE_URL.BASE_URL + "/img/Vynksafe_Api/Icon light.png"}
                className="img-fluid light-theme-img"
                alt="VIP and Instititional"
              />
            </div>
            <div className="d-flex justify-content-center">
              <h1 className="mb-2" style={{ fontSize: "2rem" }}>
                Made for{" "}
                <span className="text-orange fw-1" style={{ fontSize: "2rem" }}>
                  {" "}
                  Developers{" "}
                </span>
              </h1>
            </div>
            <div className="d-flex justify-content-center">
              <div className="VIPInstitutionalcontent pt-3 pb-3">
                <Row>
                  <Col md={6}>
                    <div className="VipInstitude">
                      <div>
                        <div className="d-flex justify-content-end mt-3">
                          <p>
                          Have you faced any difficulties with development? For help, get in touch with our active developer community. Your observation is valuable! Let us know what you think, and together, we can help build a better tomorrow.
                          </p>
                        </div>
                        <div className="d-flex flex-wrap justify-content-start mt-3">
                          <Button className="developerbutton mb-3 mr-3">
                            Dev Forum
                          </Button>
                          <Button className="developerbutton mb-3 mr-3">
                            Dev Telegram
                          </Button>
                          <Button className="developerbutton mb-3 mr-3">
                            Feedback Survey
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <img
                      src={
                        BASE_URL.BASE_URL +
                        "img/Vynksafe_Api/Made for Developers Vector.png"
                      }
                      className="img-fluid dark-theme-img ml-5"
                      alt="VIP and Instititional"
                      width={"270px"}
                    />
                    <img
                      src={
                        BASE_URL.BASE_URL +
                        "img/Vynksafe_Api/Made for Developers Vector light.png"
                      }
                      className="img-fluid light-theme-img ml-5"
                      alt="VIP and Instititional"
                      width={"270px"}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MadeForDeveloper;
