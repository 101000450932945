import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import BASE_URL from "../_constant/index";
import { toast } from "../components/Toast/Toast";
import {
  updatePhoneNumber,
  mobileOtpVerifyAction,
  UpdateUserInfoVerifyAction
} from "../redux/apiActions/otp.verify.action";
import { user_Kyc } from '../redux/apiActions/user.action'
// update_otp_verify

const OtpNumber = ({ phone, setOTPShow, history, dispatch ,fieldsData ,handleUserDataUpdate}) => {
  // const history = useHistory();
  // const dispatch = useDispatch();

  const [loading, setLoader] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);

  const [otp, setOtp] = useState("");
  const [Error, setError] = useState(false);
  const [ErrorMsg, setErrorMsg] = useState("");

  const handleError = () => {
    if (otp.length == 0) {
      setError(true)
      setErrorMsg("OTP is required")
    } else if (otp.length < 5) {
      setError(true)
      setErrorMsg("Min 6 Digit")
    } else {
      setError(false)

    }
  }


  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setOtp(value);
  };


  useEffect(() => {
    if (!phone) {
      setOTPShow(false);
      return false;
    }

    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes, history, phone]);

  const resendOTP = async () => {
    setMinutes(0);
    setSeconds(30);
    if (phone === "") {
      toast.error("Update your phone number");
      history.push(BASE_URL?.BASE_URL + "phone-verify");
      return false;
    }

    let formData = new FormData();
    formData.append("phone", phone);
    formData.append("register", 1);
    dispatch(updatePhoneNumber(formData, history));
  };

  const resendOTPWhenUpdateCountry = async () => {
    setMinutes(0);
    setSeconds(30);
    handleUserDataUpdate();
  };

  const user_kyc_Details = async (formData) => {
    await (user_Kyc(formData));
  }

  return (
    <>
      <div className="basic_pages">
        <div className="row row-cols-1 row-cols-md-2 row-cols-md-1 row-cols-sm-1 align-items-center gap-v-4" style={{ height: "100%" }}>
          <div className="col d-flex align-items-center justify-content-center">
            <div className="form-access">
              {fieldsData ?(<>
                <Formik
                initialValues={{
                  phone: history?.location?.state?.request?.phone,
                  otpNumber: otp,
                  device_type: "1",
                  device_token: "deviceTokenForNotificationLogin",
                }}

                validationSchema={Yup.object().shape({
                  otpNumber: Yup.string()

                    .min(6, "Min 6 Digit")
                    .max(6, "Otp Not valid"),
                })}

                onSubmit={async (data) => {
                  try {
                    handleError();
                    setOtp("");
                    if (otp) {

                      let formData = new FormData();
                      formData.append("otp", otp);
                      // dispatch(mobileOtpVerifyAction(formData, history));
                      dispatch(UpdateUserInfoVerifyAction(formData, history));
                      user_kyc_Details();
                      setLoader(false);
                    } else {
                      setOtp("");
                    }
                  } catch (err) {
                    setOtp("");
                  }
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                     <h3 className="mb-3 Welcome" style={{ fontSize: "2rem" }}>
                     OTP <span className="text-orange">Verification</span>
             </h3>
                  
                    <p className="mb-4">
                      One time password has been sent to your email
                    </p>
                    <div className="form-group mb-0">
                      <Field
                        placeholder="Enter code here"
                        name="otpNumber"
                        type="text"
                        maxLength="6"
                        value={otp}
                        onChange={handleChange}
                        className={
                          "form-control" +
                          (errors.otpNumber && touched.otpNumber
                            ? " is-invalid"
                            : "")
                        }
                      />
                      {Error ? (<p className="text-danger small mt-2">{ErrorMsg}</p>) : null}
                      <ErrorMessage
                        name="otpNumber"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <button
                      type={loading ? "button" : "submit"}
                      className="btn btn-primary signinButton"
                      disabled={(otp.length < 6) || loading}
                    >
                      {loading ? "Please wait..." : "Submit"}
                    </button>
                    <div className="countdown-text">
                      <div className="c_inner">
                        {seconds > 0 || minutes > 0 ? (
                          <p>
                            Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}
                            :{seconds < 10 ? `0${seconds}` : seconds}
                          </p>
                        ) : (
                          <>
                            <p>Didn't receive code?</p>
                            <button className="btn" onClick={resendOTPWhenUpdateCountry}>
                              Resend OTP
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              </>):(<>
                <Formik
                initialValues={{
                  phone: history?.location?.state?.request?.phone,
                  otpNumber: otp,
                  device_type: "1",
                  device_token: "deviceTokenForNotificationLogin",
                }}

                validationSchema={Yup.object().shape({
                  otpNumber: Yup.string()

                    .min(6, "Min 6 Digit")
                    .max(6, "Otp Not valid"),
                })}

                onSubmit={async (data) => {
                  try {
                    handleError();
                    setOtp("");
                    if (otp) {

                      let formData = new FormData();
                      formData.append("otp", otp);
                      dispatch(mobileOtpVerifyAction(formData, history));
                      // dispatch(UpdateUserInfoVerifyAction(formData, history));
                      user_kyc_Details();
                      setLoader(false);
                    } else {
                      setOtp("");
                    }
                  } catch (err) {
                    setOtp("");
                  }
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <h1 className="mb-2 text-center" style={{fontSize:"2rem"}}>OTP Verification</h1>
                    <p className="text-center mb-4">
                      One time password has been sent to your email
                    </p>
                    <div className="form-group mb-0">
                      <Field
                        placeholder="Enter code here"
                        name="otpNumber"
                        type="text"
                        maxLength="6"
                        value={otp}
                        onChange={handleChange}
                        className={
                          "form-control" +
                          (errors.otpNumber && touched.otpNumber
                            ? " is-invalid"
                            : "")
                        }
                      />
                      {Error ? (<p className="text-danger small mt-2">{ErrorMsg}</p>) : null}
                      <ErrorMessage
                        name="otpNumber"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <button
                      type={loading ? "button" : "submit"}
                      className="btn btn-primary signinButton"
                      disabled={(otp.length < 6) || loading}
                    >
                      {loading ? "Please wait..." : "Submit"}
                    </button>
                    <div className="countdown-text">
                      <div className="c_inner">
                        {seconds > 0 || minutes > 0 ? (
                          <p>
                            Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}
                            :{seconds < 10 ? `0${seconds}` : seconds}
                          </p>
                        ) : (
                          <>
                            <p>Didn't receive code?</p>
                            <button className="btn" onClick={resendOTP}>
                              Resend OTP
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              </>)}
             
            </div>
          </div>
          <div className="col px-lg-5 px-3 login-background d-flex align-items-center justify-content-center" style={{ height: "100%" }}>
            <div className="p-4 d-flex align-items-center justify-content-center w-100" >
              <img
                src={`${BASE_URL.BASE_URL}img/images/SignUp.svg`}
                className="img-fluid light-theme-img"
                alt="banner"
                width="450"
              />
              <img
                src={`${BASE_URL.BASE_URL}img/images/Sign.png`}
                className="img-fluid dark-theme-img"
                alt="banner"
                width="450"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="basic_pages d-flex justify-content-center">
        <div className="form-access my-auto">
          <Formik
            initialValues={{
              phone: history?.location?.state?.request?.phone,
              otpNumber: otp,
              device_type: "1",
              device_token: "deviceTokenForNotificationLogin",
            }}

            validationSchema={Yup.object().shape({
              otpNumber: Yup.string()

                .min(6, "Min 6 Digit")
                .max(6, "Otp Not valid"),
            })}

            onSubmit={async (data) => {
              try {
                handleError();
                setOtp("");
                if (otp) {

                  let formData = new FormData();

                  formData.append("otp", otp);
                  dispatch(mobileOtpVerifyAction(formData, history));
                  user_kyc_Details();
                  setLoader(false);
                } else {
                  setOtp("");
                }
              } catch (err) {
                setOtp("");
              }
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <span className="mb-0">OTP Verification</span>
                <p className="text-center mb-4">
                  One time password has been sent to your email
                </p>
                <div className="form-group mb-0">
                  <Field
                    placeholder="Enter code here"
                    name="otpNumber"
                    type="text"
                    maxLength="6"
                    value={otp}
                    onChange={handleChange}
                    className={
                      "form-control" +
                      (errors.otpNumber && touched.otpNumber
                        ? " is-invalid"
                        : "")
                    }
                  />
                  {Error ? (<p className="text-danger small mt-2">{ErrorMsg}</p>) : null}
                  <ErrorMessage
                    name="otpNumber"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <button
                  type={loading ? "button" : "submit"}
                  className="btn btn-primary"
                  disabled={(otp.length < 6) || loading}
                >
                  {loading ? "Please wait..." : "Submit"}
                </button>
                <div className="countdown-text">
                  <div className="c_inner">
                    {seconds > 0 || minutes > 0 ? (
                      <p>
                        Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}
                        :{seconds < 10 ? `0${seconds}` : seconds}
                      </p>
                    ) : (
                      <>
                        <p>Didn't receive code?</p>
                        <button className="btn" onClick={resendOTP}>
                          Resend OTP
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div> */}
    </>
  );
};

export default OtpNumber;
