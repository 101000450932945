import React, { useState, useCallback, useEffect } from "react";
import Badge from 'react-bootstrap/Badge';
import {
  Tab,
  Row,
  Col,
  Nav,
  Tabs,
  Modal,
  Button,
  Spinner,
} from "react-bootstrap";

import { toast } from "../components/Toast/Toast";
import validator from "validator";
import qs from "qs";
// import { ThemeConsumer } from "../context/ThemeContext";
import Loader from "../components/loader";
import Moment from "react-moment";
import Select from "react-select";
import QRCode from "react-qr-code";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector, useDispatch } from "react-redux";
import {
  userCoinListAction,
  userCoinTxnListAction,
  CoinNetworksListAction,
  getOtherUserAddress,
  getWithdrawGasFeeAction,
  sendTransactionAction,
} from "../redux/apiActions/user.action";
import WithdrawSection from "../components/wallet/withdraw";
import CoinListItem from "../components/wallet/coinList";
import utill from "../_constant/utill";
import { useHistory, generatePath, useParams } from "react-router-dom";
import BASE_URL from "../_constant/index";


const Wallet = () => {

  let { coin_symbol } = useParams();

  const [urlTab, setUrlTab] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setUrlTab(params.get("to"));
  }, [])
  const activeTab = urlTab ? "withdraw" : "deposit"


  coin_symbol = coin_symbol ? coin_symbol : "";
  const [showPopup, setShowPopup] = React.useState()

  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true)
    setSelectedData(data)
  };



  const userData = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();


  const history = useHistory();
  const [loadData, setLoadData] = useState(1)
  const [selectedCoin, setSelectedCoin] = useState(coin_symbol);
  const [userCoinList, setUserCoinList] = React.useState([]);
  const [coinData, setCoinData] = React.useState({});
  const [txnList, setTxnList] = React.useState([]);

  const [firstActive, setFirstActive] = React.useState("active");
  const [loading, setLoading] = useState(false);
  const [txnLoader, setTxnLoader] = useState(false);
  const [networksList, setNetworkList] = useState([]);
  const [confirm_txn_data, setconfirm_txn_data] = useState({
    date: "",
    status: "",
    Hash: "",
    Amount: "",
    Network_fee: ""
  })

  const [selectedNetwork, setNetwork] = useState(null);
  const [errorMsg, setErrorMsg] = useState({
    addressError: "",
    amountError: "",
    currencyError: "",
    NetworkError: "",
  });
  const [withdrawCoinData, setWithdrawCoinData] = useState({
    type: "address",
    withdrawTo: "",
    withdrawAddress: "",
    amount: "",
    coin: "",
    price: "",
    amount_currency: "",
    withdrawUser: "",
  });
  const [confirmationModal, setConfirmationModal] = React.useState(false);
  const [withdrawGasFeeData, setWithdrawGasFeeData] = React.useState({});
  const [successModal, setSuccessModel] = React.useState(false);
  const [txnSuccessData, setTxnSuccessData] = React.useState({});
  const [successLoader, setSuccessLoader] = React.useState(false);

  const pushPairURL = (coin_symbol) => {
    let myPairPath = history.push(
      generatePath(BASE_URL.BASE_URL + "wallet/" + coin_symbol)
    );
    setSelectedCoin(coin_symbol);
  };


  const handleClickCoinList = (coin) => {
    setLoadData(1)
    setFirstActive("");
    setCoinData(coin);
    pushPairURL(coin?.symbol);
    let formData = new FormData();
    formData.append("coin", coin?.coin);
    formData.append("page", 1);
    getUserTxn(formData);
    let data = new FormData();
    data.append("coin", coin?.coin);
    data.append("type", 1);
    getCoinNetworks(data);
    setSelectValue(null);
    setWithdrawCoinData((preState) => {
      let obj = Object.assign({}, preState);
      obj.price = coin?.rate_currency;
      obj.coin = coin?.coin;
      return obj;
    });
    changeWithdrawType(withdrawCoinData.type);
  };

  const getUserTxn = useCallback(
    async (data) => {
      const userTxn = await userCoinTxnListAction(data, dispatch);
      if (userTxn?.status === 200) {
        setTxnList(userTxn?.data?.listing);

      }
    },
    [dispatch]
  );

  const getCoinNetworks = useCallback(
    async (data) => {
      const networksResponse = await CoinNetworksListAction(data, dispatch);

      if (networksResponse?.status === 200) {
        setNetworkList(
          networksResponse?.data.map((val) => {
            val.value = val.id;
            val.label = val.network_text;
            return val;
          })
        );
      }
    },
    [dispatch]
  );

  const setSelectValue = (val) => {
    setNetwork(val);
  };

  function isNumberKey(value) {
    var validNumber = new RegExp(/^\d*\.?\d*$/);
    if (validNumber.test(value)) {
      return value;
    } else {
      return false;
    }
  }
  const setWithdrawTo = (e) => {
    let value = e.target.value;
    let message = "";
    if (
      value !== "" &&
      withdrawCoinData?.type === "email" &&
      !validator.isEmail(value)
    ) {
      message = "Invalid Email address";
    } else if (value === "") {
      message =
        withdrawCoinData?.type === "phone"
          ? "Invalid Phone Number"
          : withdrawCoinData?.type === "email"
            ? "Invalid Email address"
            : "Invalid address";
    }
    setErrorMsg((preState) => {
      let obj = Object.assign({}, preState);
      obj.addressError = message;
      return obj;
    });
    setWithdrawCoinData((preState) => {
      let obj = Object.assign({}, preState);
      obj.withdrawTo = value;
      return obj;
    });
  };
  const setWithdrawAmount = (e) => {
    let value = isNumberKey(e.target.value);

    if (value !== false) {
      setErrorMsg((preState) => {
        let obj = Object.assign({}, preState);
        obj.amountError = value || value > 0 ? "" : "Invalid value";
        obj.currencyError = value || value > 0 ? "" : "Invalid Currency Value";
        return obj;
      });
      setWithdrawCoinData((preState) => {
        let obj = Object.assign({}, preState);
        obj.amount = value;
        obj.amount_currency = (value * withdrawCoinData.price).toFixed(2);
        return obj;
      });
    }
  };
  const setWithdrawAmountCurrency = (e) => {
    let value = isNumberKey(e.target.value);

    if (value !== false) {
      setErrorMsg((preState) => {
        let obj = Object.assign({}, preState);
        obj.currencyError = value || value > 0 ? "" : "Invalid Currency Value ";
        obj.amountError = value || value > 0 ? "" : "Invalid  Value ";
        return obj;
      });
      setWithdrawCoinData((preState) => {
        let obj = Object.assign({}, preState);
        obj.amount_currency = value;
        obj.amount =
          withdrawCoinData?.price <= 0
            ? ""
            : utill.beautifyNumber(value / withdrawCoinData.price, 4);
        return obj;
      });
    }
  };
  const changeWithdrawType = (type) => {
    setWithdrawCoinData((preState) => {
      let obj = Object.assign({}, preState);
      obj.type = type;
      obj.withdrawTo = "";
      obj.amount_currency = "";
      obj.amount = "";
      return obj;
    });
    setErrorMsg((preState) => {
      let obj = Object.assign({}, preState);
      obj.NetworkError = "";
      obj.addressError = "";
      obj.currencyError = "";
      obj.amountError = "";
      return obj;
    });
  };

  const handelWithdraw = async () => {
    try {
      let isError = false;

      let errorJson = {
        NetworkError: "",
        addressError: "",
        currencyError: "",
        amountError: "",
      };
      //coin validation
      if (!withdrawCoinData?.coin || withdrawCoinData?.coin.trim() === "") {
        toast.error("Invalid coin");
        isError = true;
      } else {
        isError = false;
      }

      // network validation
      if (selectedNetwork === null && withdrawCoinData?.type === "address") {
        errorJson.NetworkError = "Please select Network First";
        isError = true;
      } else {
        errorJson.NetworkError = "";
        isError = false;
      }
      // address validation
      if (
        !withdrawCoinData.withdrawTo ||
        withdrawCoinData.withdrawTo.trim() === ""
      ) {
        errorJson.addressError =
          withdrawCoinData?.type === "phone"
            ? "Invalid Phone Number"
            : withdrawCoinData?.type === "email"
              ? "Invalid Email address"
              : "Invalid address";
        isError = true;
      }
      // else if (withdrawCoinData?.withdrawTo &&withdrawCoinData?.type !== "address") {
      //   if (
      //     withdrawCoinData?.type === "email" &&
      //     !validator.isEmail(withdrawCoinData?.withdrawTo)
      //   ) {
      //     console.log("email check is valid or not");
      //     errorJson.addressError = "Invalid Email address";
      //     isError = true;
      //   } else {
      //     let formData = new FormData();
      //     formData.append("coin", withdrawCoinData?.coin);
      //     formData.append("email", withdrawCoinData?.withdrawTo);
      //     formData.append("phone", withdrawCoinData?.withdrawTo);
      //     formData.append(
      //       "send_mode",
      //       withdrawCoinData?.type === "email" ? "3" : "2"
      //     );
      //     let address_data_res = await getOtherUserAddress(formData, dispatch);
      //     if (
      //       address_data_res.status === "400" ||
      //       address_data_res.status === 400
      //     ) {
      //       errorJson.addressError = address_data_res.message;
      //       isError = true;
      //     } else {
      //       setWithdrawCoinData((preState) => {
      //         let obj = Object.assign({}, preState);
      //         obj.withdrawUser = address_data_res?.data?.full_name_show;
      //         return obj;
      //       });
      //       errorJson.addressError = "";
      //       isError = false;
      //     }
      //   }
      // }
      else {
        // errorJson.addressError = "";
        // isError = false;
        if (
          withdrawCoinData?.withdrawTo &&
          withdrawCoinData?.type !== "address"
        ) {
          // console.log("come he re -1");
          // console.log(validator.isEmail(withdrawCoinData?.withdrawTo),"the email value");
          if (
            withdrawCoinData?.type === "email" &&
            !validator.isEmail(withdrawCoinData?.withdrawTo)
          ) {
            // console.log("email check is valid or not");
            errorJson.addressError = "Invalid Email address";
            isError = true;
          } else {
            let formData = new FormData();
            formData.append("coin", withdrawCoinData?.coin);
            formData.append("email", withdrawCoinData?.withdrawTo);
            formData.append("mobile", withdrawCoinData?.withdrawTo);
            formData.append(
              "send_mode",
              withdrawCoinData?.type === "email" ? "3" : "2"
            );
            let address_data_res = await getOtherUserAddress(
              formData,
              dispatch
            );
            if (
              address_data_res.status === "400" ||
              address_data_res.status === 400
            ) {
              errorJson.addressError = address_data_res.message;
              isError = true;
            } else {
              setWithdrawCoinData((preState) => {
                let obj = Object.assign({}, preState);
                obj.withdrawUser = address_data_res?.data?.full_name_show;
                obj.withdrawAddress = address_data_res?.data?.address;
                return obj;
              });
              errorJson.addressError = "";
              isError = false;
            }
          }
        }
      }

      // validate amount
      if (
        !withdrawCoinData.amount ||
        isNaN(withdrawCoinData.amount) ||
        withdrawCoinData.amount <= 0
      ) {
        errorJson.amountError = "Invalid amount";
        isError = true;
      } else {
        errorJson.amountError = "";
        isError = false;
      }
      // validate currency amount
      if (
        !withdrawCoinData.amount_currency ||
        isNaN(withdrawCoinData.amount_currency) ||
        withdrawCoinData.amount_currency <= 0
      ) {
        errorJson.currencyError = "Invalid amount";
        isError = true;
      } else {
        errorJson.amountError = "";
        isError = false;
      }
      setErrorMsg(errorJson);
      // if (!isError) {

      if (
        errorJson.NetworkError == "" &&
        errorJson.addressError == "" &&
        errorJson.currencyError == "" &&
        errorJson.amountError == ""
      ) {
        // console.log(errorJson, "this is error message ");
        // console.log("why come here with error");
        let requestData, gasUrl, send_mode;

        if (withdrawCoinData?.type === "address") {
          gasUrl = selectedNetwork?.url_gasFee;
          send_mode = 1;
          // requestData.append("coin", withdrawCoinData?.coin);
          // requestData.append("address_input", coinData?.address);
          // requestData.append("address_output", withdrawCoinData?.withdrawTo);
          // requestData.append("value_coin", withdrawCoinData?.amount);
          // requestData.append("send_mode", send_mode);
          requestData = qs.stringify({
            coin: withdrawCoinData?.coin,
            address_input: selectedNetwork?.address,
            address_output: withdrawCoinData.withdrawTo,
            value_coin: withdrawCoinData.amount,
            send_mode: send_mode,
          });
        } else if (
          withdrawCoinData?.type === "email" ||
          withdrawCoinData?.type === "phone"
        ) {
          requestData = new FormData();
          gasUrl = coinData.url_internal_gasFee;
          send_mode = withdrawCoinData?.type == "email" ? "3" : "2";
          requestData.append("coin", withdrawCoinData?.coin);
          //  requestData.append("address_input", coinData?.address);
          //  requestData.append("address_output", withdrawCoinData?.withdrawTo);
          requestData.append("value", withdrawCoinData?.amount);
          requestData.append("send_mode", send_mode);
          // requestData = JSON.stringify({
          //   coin: withdrawCoinData?.coin,
          //   value: withdrawCoinData.amount,
          //   send_mode: send_mode,
          // });
        }
        // if (gasUrl && send_mode && requestData) {
        // console.log(requestData, gasUrl, send_mode);
        let gasFeeResponse = await getWithdrawGasFeeAction(
          requestData,
          gasUrl,
          send_mode,
          dispatch
        );
        // console.log(gasFeeResponse);
        if (gasFeeResponse?.status === 200) {
          setWithdrawGasFeeData(gasFeeResponse?.data);
          setConfirmationModal(true);
        } else {
          toast.error(gasFeeResponse?.message);
        }
      } else {
        toast.error("Please provide all  Valid Details");
      }
    } catch (error) {
      toast.error("Something went wrong, please try again later");
    }
  };
  const confirmTxn = async () => {
    try {
      let requestData, gasUrl, send_mode;
      // setConfirmationModal(false);
      if (successLoader === false && withdrawCoinData.withdrawTo !== "") {
        setSuccessLoader(true);
        setSuccessModel(true);
        // return false;
        if (withdrawCoinData?.type === "address") {
          send_mode = 1;
          gasUrl = selectedNetwork?.url_transfer;
          requestData = qs.stringify({
            coin: withdrawCoinData?.coin,
            address_input: selectedNetwork?.address,
            address_output: withdrawCoinData.withdrawTo,
            value_coin: withdrawCoinData.amount,
            send_mode: send_mode,
          });
          // let txn_response = await sendTransactionAction(
          //   requestData,
          //   gasUrl,
          //   send_mode,
          //   dispatch
          // );
          // // console.log(gasFeeResponse);
          // if (txn_response?.status === 200) {
          //   setSuccessLoader(false);
          //   // setConfirmationModal(txn_response?.data);
          // } else {
          //   setSuccessLoader(false);
          //   setSuccessModel(false);
          //   toast.error(txn_response?.message);
          // }
        } else if (
          withdrawCoinData?.type === "email" ||
          withdrawCoinData?.type === "phone"
        ) {
          requestData = new FormData();
          gasUrl = coinData.url_internal_transfer;
          send_mode = withdrawCoinData?.type == "email" ? "3" : "2";
          requestData.append("coin", withdrawCoinData?.coin);
          requestData.append("address_to", withdrawCoinData?.withdrawAddress);
          requestData.append("value", withdrawCoinData?.amount);
          requestData.append("send_mode", send_mode);
          // requestData = JSON.stringify({
          //   coin: withdrawCoinData?.coin,
          //   value: withdrawCoinData.amount,
          //   send_mode: send_mode,
          // });
        }
        // if (gasUrl && send_mode && requestData) {
        // console.log(requestData, gasUrl, send_mode);
        let txn_response = await sendTransactionAction(
          requestData,
          gasUrl,
          send_mode,
          dispatch
        );

        // console.log(gasFeeResponse);
        if (txn_response?.status === 200) {
          // getUserTxn();
          // setSuccessLoader(false);

          setconfirm_txn_data({
            date: txn_response.created_date,
            status: txn_response.txn_status,
            Hash: txn_response.hash_show,

          })
          setSuccessModel(true);
        } else {
          setSuccessModel(false);
          toast.error(txn_response?.message);
        }
        let formData = new FormData();
        formData.append("coin", withdrawCoinData?.coin);
        formData.append("page", 1);
        getUserTxn(formData);
        changeWithdrawType(withdrawCoinData.type);
        setSuccessLoader(false);
      }
    } catch (error) { }
  };


  React.useEffect(() => {
    if (userData?.access_token) {
      setLoading(true);
      if (!selectedCoin && selectedCoin === "") {
        history.push(generatePath(BASE_URL.BASE_URL + "login"));
      }
      const walletCoinList = async () => {
        const coinListData = await userCoinListAction(dispatch);
        // console.log(coinListData);
        if (coinListData?.status === 200) {
          // console.log(coinListData?.data);
          setUserCoinList(coinListData?.data);
          let current_coin = coinListData?.data?.filter((ele) => {
            if (ele.symbol === selectedCoin) {
              return ele;
            }
          });
          if (current_coin.length === 0) {
            history.push(BASE_URL.BASE_URL + "notfound");
          } else {
            setLoadData(1);
            setCoinData(current_coin[0]);
            let formData = new FormData();
            formData.append("coin", current_coin[0]?.coin);
            formData.append("page", 1);
            getUserTxn(formData);
            let data = new FormData();
            data.append("coin", current_coin[0]?.coin);
            data.append("type", 1);
            await getCoinNetworks(data);
            // const setWithdrawCoin = async (coinListData) => {
            setWithdrawCoinData((preState) => {
              let obj = Object.assign({}, preState);
              obj.price = current_coin[0].rate_currency;
              obj.coin = current_coin[0].coin;
              return obj;
            });
            setLoading(false);
          } 
        }
      };
      walletCoinList();
    } else {
      history.push(generatePath(BASE_URL.BASE_URL + "login"));
    }
  }, [
    userData,
    history,
    setUserCoinList,
    setCoinData,
    dispatch,
    getUserTxn,
    getCoinNetworks,
    setWithdrawCoinData,

  ]);

  const handleIncrement = async (coin_symbol) => {

    let page = loadData + 1;
    setLoadData(page)
    setTxnLoader(true);
    let formData = new FormData();
    formData.append("coin", coin_symbol);
    formData.append("page", page);
    const userTxn = await userCoinTxnListAction(formData, dispatch);
    if (userTxn?.status === 200) {
      if (userTxn?.data?.listing.length > 0) {
        setTxnList([...txnList, ...userTxn?.data?.listing]);
      }
    }
    setTxnLoader(false);
  };
  const handledecrement = (e, coin_symbol) => {
    e.preventDefault()
    if (loadData > 1) {
      let page = loadData - 1;
      setLoadData(page)

      let formData = new FormData();
      formData.append("coin", coin_symbol);
      formData.append("page", page);
      getUserTxn(formData);
    }

  };

  return (
    <>
      {/* <KycVerificationPopUp /> */}
      <div className="settings mtb15">
        <div className="container-fluid">
          {loading ? (
            <Loader />
          ) : (
            <div className="wallet">
              <Row>
                <Col lg={3} className="mb-3" >
                  <Nav variant="pills" className="settings-nav" style={{ maxHeight: "760px", minHeight: "560px", overflowY: "auto", overflowX: "hidden" }}>
                    {userCoinList?.map((coin, index) => {
                      return (
                        <CoinListItem
                          coin={coin}
                          key={index}
                          active={
                            coin?.symbol === selectedCoin ? firstActive : ""
                          }
                          handelClick={handleClickCoinList}
                        />
                      );
                    })}
                  </Nav>
                </Col>


                <Col lg={9}>
                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="coinBTC"
                      role="tabpanel"
                    >
                      <div className="card">
                        <div className="card-body">
                          <div className="settings_top_title d-flex flex-wrap justify-content-between align-items-center">
                            <div>
                              <h5 className="card-title">Available Balance</h5>
                            </div>
                            <div>
                              <h5 className="card-title">Price</h5>
                            </div>
                          </div>

                          <div className="card-estimated-balance-section mtb15 d-flex flex-wrap justify-content-between align-items-center" >
                            <h3 className="card-estimated-balance">{utill.beautifyNumber(
                              coinData?.balance,
                              coinData?.decimal > 5
                                ? 6
                                : coinData?.decimal
                            ) +
                              " " +
                              coinData?.symbol} <span className="text-secondary">= $ {utill.beautifyNumber(
                                coinData?.balance_currency,
                                2
                              )
                              }</span></h3>



                            <h3 className="card-estimated-balance"> $ {utill.valueFix(
                              parseFloat(coinData?.rate_currency)
                            )}</h3>
                          </div>
                        </div>
                      </div>

                      <div className="card wallet card_wallet">
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey={activeTab}
                        // defaultActiveKey="deposit"
                        >
                          <Nav variant="pills">
                            <Nav.Item>
                              <Nav.Link eventKey="deposit">Deposit</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="withdraw">Withdraw</Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane eventKey="deposit">
                              <div className="card-body">
                                <h6 className="card-title">
                                  Select Deposit Network
                                </h6>
                                <div className="row wallet-address">
                                  {/* <div className="col-md-3 mb-4">
                                    <h2>Coin :</h2>
                                  </div>
                                  <div className="col-md-8 mb-4">
                                    <div className="d-flex">
                                      <h2>{coinData?.symbol}</h2>
                                    </div>
                                  </div> */}
                                  {/* <div className="col-md-3 mb-4">
                                    <h2>Deposit Network :</h2>
                                  </div> */}
                                  <div className="col-md-8 mb-4">
                                    <Select
                                      className="wallet_network_select"
                                      classNamePrefix="custom_select"
                                      isSearchable={true}
                                      name="network"
                                      placeholder="Select a network"
                                      value={selectedNetwork}
                                      onChange={(e) => {
                                        setSelectValue(e);
                                      }}
                                      // defaultValue={networksList[0]}
                                      // value={selectedNetwork}
                                      options={networksList}
                                    />
                                    <p className="mb-4 mt-2">
                                      Deposits to this address are unlimited.
                                      Note that you may not be able to withdraw
                                      all of your funds at once if you deposit
                                      more than your daily withdrawal limit.
                                    </p>

                                    {selectedNetwork &&
                                      selectedNetwork.address ? (
                                      <>
                                        <h6 className="card-title">
                                          Address
                                        </h6>
                                        <Row>

                                          <Col md={8}>
                                            <p>
                                              {selectedNetwork.address}
                                              <span className="ml-2">
                                                <CopyToClipboard
                                                  text={
                                                    selectedNetwork &&
                                                      selectedNetwork.address
                                                      ? selectedNetwork?.address
                                                      : ""
                                                  }
                                                  onCopy={() =>
                                                    toast.success("copied")
                                                  }
                                                >
                                                  <i
                                                    title="Copy address to clipboard"
                                                    className="icon ion-md-copy btn btn-secondary"
                                                  ></i>
                                                </CopyToClipboard>
                                              </span>
                                            </p>
                                            <p>
                                              Minimum Deposit Amount :{" "}
                                              {selectedNetwork?.min_deposit +
                                                " " +
                                                selectedNetwork?.symbol}
                                            </p>
                                          </Col>
                                          <Col md={4}>
                                            <QRCode
                                              size={256}
                                              style={{
                                                height: "auto",
                                                maxWidth: "100%",
                                                width: "100%",
                                                border: "5px solid #f5f5f5",
                                                // border: "5px solid #555",

                                              }}
                                              value={selectedNetwork?.address}
                                              viewBox={`0 0 256 256`}
                                            />
                                          </Col>
                                        </Row>


                                      </>
                                    ) : null}

                                  </div>
                                  <div className="col-md-4">
                                    {/* <h2>Address :</h2> */}
                                  </div>
                                  {/* <div className="col-md-6">
                                    {selectedNetwork &&
                                      selectedNetwork.address ? (
                                      <>
                                        <p>
                                          {selectedNetwork.address}
                                          <span className="ml-2">
                                            <CopyToClipboard
                                              text={
                                                selectedNetwork &&
                                                  selectedNetwork.address
                                                  ? selectedNetwork?.address
                                                  : ""
                                              }
                                              onCopy={() =>
                                                toast.success("copied")
                                              }
                                            >
                                              <i
                                                title="Copy address to clipboard"
                                                className="icon ion-md-copy btn btn-secondary"
                                              ></i>
                                            </CopyToClipboard>
                                          </span>
                                        </p>
                                        <p>
                                          Minimum Deposit Amount :{" "}
                                          {selectedNetwork?.min_deposit +
                                            " " +
                                            selectedNetwork?.symbol}
                                        </p>
                                      </>
                                    ) : null}
                                  </div> */}

                                  <div className="col-md-3">
                                    {/* {selectedNetwork &&
                                      selectedNetwork.address ? (
                                      <QRCode
                                        size={256}
                                        style={{
                                          height: "auto",
                                          maxWidth: "100%",
                                          width: "100%",
                                          border: "5px solid #f5f5f5",
                                          // border: "5px solid #555",

                                        }}
                                        value={selectedNetwork?.address}
                                        viewBox={`0 0 256 256`}
                                      />
                                    ) : null} */}
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="withdraw">
                              <div className="card-body">
                                {/* <h5 className="card-title">
                                  Withdraw {coinData?.symbol}
                                </h5> */}
                                <div className="row wallet-address">
                                  {/* <div className="col-md-3 mb-3">
                                    <h2>Coin</h2>
                                  </div>
                                  <div className="col-md-8 mb-3">
                                    <div className="d-flex">
                                      <h2>{coinData?.symbol}</h2>
                                    </div>
                                  </div> */}
                                  <div className="col-md-12 mb-3" >

                                    <Tabs
                                      defaultActiveKey="address"
                                      onSelect={(key) => {
                                        changeWithdrawType(key);
                                      }}
                                      className="Wallet_tab"
                                    >
                                      {[
                                        {
                                          name: "Address",
                                          value: "address",
                                        },
                                        {
                                          name: "Email Address",
                                          value: "email",
                                        },
                                        {
                                          name: "Phone Number",
                                          value: "phone",
                                        },
                                      ].map((val, key) => {
                                        return (
                                          <Tab
                                            eventKey={val?.value}
                                            title={val?.name}
                                            key={val?.value}
                                          >
                                            <div className="justify-content-between">
                                              <WithdrawSection
                                                sendType={val}
                                                selectedNetwork={
                                                  selectedNetwork
                                                }
                                                networksList={networksList}
                                                coinData={coinData}
                                                withdrawCoinData={
                                                  withdrawCoinData
                                                }
                                                changeWithdrawTo={setWithdrawTo}
                                                changeWithdrawAmount={
                                                  setWithdrawAmount
                                                }
                                                changeWithdrawCurrency={
                                                  setWithdrawAmountCurrency
                                                }
                                                changeSelectValue={
                                                  setSelectValue
                                                }
                                                error={errorMsg}
                                                setErrorMsg={setErrorMsg}
                                                handelWithdrawTxn={
                                                  handelWithdraw
                                                }
                                              />
                                            </div>
                                          </Tab>

                                        );
                                      })}
                                    </Tabs>
                                  </div>

                                </div>
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </div>


                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Latest Transactions</h5>
                      <div className="wallet-history" style={{ maxHeight: "450px", overflowY: "auto" }}>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Type</th>
                              <th>Txn Type</th>
                              <th>Hash/Invoice No</th>
                              <th>Amount</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {txnList.length == 0 ? (<>
                              <tr>
                                <td colSpan={7} style={{ textAlign: "center" }}>
                                  <i className="icon ion-md-document" style={{ color: "#374254", fontSize: "100px" }}></i>
                                  <br />
                                  <p style={{ marginTop: "-30px" }}> No data</p>
                                </td>
                              </tr>

                            </>) :
                              <>

                                {txnList.map((txn, index) => {
                                  let status_icon =
                                    txn.status == 0
                                      ? " icon ion-md-checkmark-circle-outline green"
                                      : txn.status == 5
                                        ? " icon ion-md-time yellow"
                                        : " icon ion-md-close-circle-outline red";
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <Moment format="DD/MM/YYYY hh:mm A">
                                          {txn?.created_date}
                                        </Moment>
                                      </td>

                                      <td
                                        className={
                                          txn.type == 1 ? "green" : "red"
                                        }
                                      >
                                        {txn.type_text}
                                      </td>
                                      <td>
                                        {txn.txn_type}
                                      </td>
                                      <td
                                      >
                                        {txn?.hash_show}{" "}
                                        {txn?.send_mode == 1 &&
                                          <Badge pill style={{ background: "cornflowerblue" }} >
                                            BNB
                                          </Badge>
                                        }
                                      </td>
                                      <td>
                                        {utill.beautifyNumber(txn?.amount, 6)}{" "}
                                        {txn.coin.toUpperCase()}
                                      </td>
                                      <td title={txn?.status_text}>
                                        <i className={status_icon}></i>
                                      </td>

                                      <td >
                                        <button
                                          className="order_cancel_btn"
                                          onClick={() => handleShow(txn)}
                                          style={{ padding: "0px" }}
                                        >
                                          Details
                                        </button>

                                        {/* <TransactionConfirmationModal
                                              txnList={txnList}
                                            /> */}
                                      </td>
                                    </tr>
                                  )
                                })}

                              </>}
                          </tbody>
                        </table>
                        {txnLoader ? (
                          <Loader />
                        ) :
                          txnList &&
                            txnList?.length !== 0 &&
                            txnList?.length % 10 === 0 ? (
                            <div
                              className="loadmore_data"
                              style={{ display: "block" }}
                            >
                              <button
                                disabled={txnLoader ? true : false}
                                onClick={() => {
                                  handleIncrement(selectedCoin);
                                }}
                              >
                                {txnLoader ? "Loading" : "Load More"}{" "}
                                {txnLoader ? (
                                  <Spinner
                                    size="sm"
                                    animation="border"
                                    role="status"
                                  ></Spinner>
                                ) : (
                                  <i className="icon ion-md-arrow-down"></i>
                                )}
                              </button>
                            </div>
                          ) : (
                            ""
                          )
                        }
                        <Modal
                          size="md"
                          aria-labelledby="contained-modal-title-center"
                          centered
                          animation
                          scrollable={true}
                          backdrop="static"
                          show={show}
                          onHide={handleClose}
                        >
                          <Modal.Header className="card-title justify-content-center">
                            <Modal.Title className="card-title justify-content-center">
                              <h3 style={{ textAlign: "center" }}> Transaction Details</h3>
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body className="pl-4 pr-4">
                            <div className="card-title justify-content-center">
                              <div className="d-flex justify-content-center">
                                <h4>{selectedData?.amount} {selectedData?.coin}</h4>
                              </div>
                              <div className="d-flex justify-content-center">
                                ${selectedData?.amount_currency}
                              </div>
                              <div className="mt-4 pop_up_style">
                                <ul style={{ padding: "10px 10px 0px 10px" }} className="list_border">
                                  {selectedData?.send_mode == 4 ? null :
                                    <li>

                                      <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                          {selectedData?.type_text == "Credited" ? "From" : selectedData?.type_text == "Debited" ? "To" : selectedData?.type_text == "Debited" ? "To" : null}
                                        </div>
                                        <div className="d-flex justify-content-center">
                                          {selectedData?.address_output}
                                        </div>
                                      </div>
                                    </li>
                                  }
                                  <li>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div className="d-flex align-items-center">
                                        Date
                                      </div>
                                      <div className="d-flex justify-content-center">
                                        <Moment format="DD/MM/YYYY hh:mm A">
                                          {selectedData?.date}
                                        </Moment>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div className="d-flex align-items-center">
                                        Status
                                      </div>
                                      <div className={
                                        selectedData?.status == 0 ? "green" : "yellow"
                                      }>
                                        {selectedData?.status_text}
                                      </div>
                                    </div>

                                  </li>
                                  <li>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div className="d-flex align-items-center">
                                        Hash/Invoice No
                                      </div>
                                      <div className="d-flex justify-content-center">
                                        {selectedData?.hash_show}
                                        {selectedData?.send_mode == 1 ? <a href={selectedData?.hash_view_url} target="_blank">
                                          <i className="icon ion-md-open ml-2">
                                          </i>
                                        </a> : null}

                                      </div>
                                    </div>

                                  </li>
                                  <li>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div className="d-flex align-items-center">
                                        Transaction Type
                                      </div>
                                      <div className={
                                        selectedData?.type == 1 ? "green" : "red"
                                      }>
                                        {selectedData?.type_text}
                                      </div>
                                    </div>

                                  </li>
                                  {selectedData?.type_text === "Credited" ? null :
                                    <li>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                          Network Fee
                                        </div>
                                        <div className="d-flex justify-content-center">
                                          ${utill.beautifyNumber(selectedData?.fee_coin, 6)}{" "}
                                          {selectedData?.fee_coin_name.toUpperCase()}
                                          {/* ${selectedData?.fee_coin}
                                                    {selectedData?.fee_coin_name} */}
                                        </div>
                                      </div>
                                    </li>
                                  }
                                </ul>
                              </div>
                            </div>
                          </Modal.Body>
                          <Modal.Footer >
                            <div className="d-flex justify-content-between align-items-center m-auto">
                              <Button className="btn btn-success" style={{ padding: "0.5rem 5rem" }} onClick={handleClose}>
                                OK
                              </Button>
                            </div>
                          </Modal.Footer>
                        </Modal>

                        {/* <ul style={{ display: "flex", justifyContent: "center" }}>
                          <li className="d-flex align-item-center" style={{ cursor: "pointer", border: "none" }} onClick={(e) => { handledecrement(e, selectedCoin) }}>
                            <i className="icon ion-md-arrow-back"></i>
                          </li>
                          <li className="d-flex align-item-center">
                            <p className="mt-2 p-1">{loadData}</p>
                          </li>
                          {txnList.length < 10 ? <li className="d-flex align-item-center" style={{ cursor: "pointer" }} onClick={(e) => { toast.error("No More Data") }}>
                            <i className="icon ion-md-arrow-forward">
                            </i>
                          </li> : (<>
                            <li className="d-flex align-item-center" style={{ cursor: "pointer" }} onClick={(e) => { handleIncrement(e, selectedCoin) }}>
                              <i className="icon ion-md-arrow-forward">
                              </i>
                            </li>
                          </>)}
                        </ul> */}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
      <ConfirmationModalView
        show={confirmationModal}
        onHide={() => setConfirmationModal(false)}
        gas_fee_data={withdrawGasFeeData}
        withdraw_data={withdrawCoinData}
        coin_data={coinData}
        confirm_txn={confirmTxn}
        success_loader={successLoader}
      />

      <TxnSuccessModalView
        confirm_txn_data={confirm_txn_data}
        show={successModal}
        onHide={() => setSuccessModel(false)}
        txn_data={txnSuccessData}
        coin_data={coinData}
        gas_fee_data={withdrawGasFeeData}
        success_loader={successLoader}
      />
    </>
  );
};

function ConfirmationModalView(props) {
  const {
    gas_fee_data,
    withdraw_data,
    coin_data,
    confirm_txn,
    success_loader,
  } = props;
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation
      scrollable={true}
      backdrop="static"
    >
      <Modal.Header className="d-flex justify-content-center">
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-center align-items-center">
            WITHDRAW
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pl-4 pr-4">
        {/* <div className="card">
          <div className="card-body"> */}
        <div className="card-title justify-content-center">
          <div className="d-flex justify-content-center">
            <img
              src={coin_data?.image}
              height={"70px"}
              width={"70px"}
              alt={withdraw_data?.coin?.toUpperCase()}
            />
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <h5>{`${gas_fee_data?.value_coin
              }  ${withdraw_data?.coin?.toUpperCase()}`}</h5>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <h5> {`$ ${gas_fee_data?.value_coin_currency}`}</h5>
          </div>
        </div>
        <div className="mt-4 pop_up_style">
          <ul style={{ padding: "10px 10px 0px 10px" }} className="list_border">
            <li className="p-2">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  Send Mode
                </div>
                <div className="d-flex justify-content-center">
                  {gas_fee_data?.send_mode_text}
                </div>
              </div>
            </li>

            <li className="p-2">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  To
                </div>
                <div className="justify-content-center text-right">

                  {gas_fee_data?.send_mode == 1
                    ? withdraw_data?.withdrawTo.substring(0, 5) +
                    "..." +
                    withdraw_data?.withdrawTo.substring(
                      withdraw_data?.withdrawTo.length - 5
                    )
                    : withdraw_data?.withdrawTo}
                  <br />
                  <small className="text-right">
                    {gas_fee_data?.send_mode != 1
                      ? withdraw_data?.withdrawUser
                      : null}
                  </small>
                </div>
              </div>

            </li>
            <li className="p-2">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  Network Fee
                </div>
                <div className="text-right">
                  {`${gas_fee_data?.gas_coin} ${gas_fee_data?.gas_coin_name}`}
                  <br />
                  $ {gas_fee_data?.gas_currency}
                </div>
              </div>

            </li>
            <li className="d-flex justify-content-between align-items-center p-2">
              <div className="d-flex align-items-center">
                Total Amount
              </div>
              <div className="text-right">
                {`${gas_fee_data?.total_coin}`}
                <br />
                $ {gas_fee_data?.total_coin_currency}

              </div>
            </li>
          </ul>
        </div>
        {/* </div>
        </div> */}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-success"
          onClick={() => {
            confirm_txn();
            props.onHide();
          }}
          disabled={success_loader}
        >
          Confirm
        </Button>
        <Button className="btn btn-danger" onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function TxnSuccessModalView(props) {
  const { gas_fee_data, withdraw_data, coin_data, confirm_txn_data } = props;
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-center"
      centered
      animation
      scrollable={true}
      backdrop="static"
    >
      <Modal.Body className="pl-4 pr-4">
        {/* <div className="card">
          <div className="card-body"> */}
        {props?.success_loader ? <div variant="dark" className="d-flex justify-content-center align-items-center">
          <div >
            <div className="d-flex justify-content-center">
              <Spinner
                as="span"
                animation="grow"
                role="status"
                aria-hidden="true"
                style={{ height: "2rem", width: "2rem", display: "block" }}
              />
            </div>
            <br />

            <p style={{ fontSize: "1.1rem" }}>In Process... </p>
          </div>
        </div> : (
          <>

            <div className="card-title justify-content-center">
              <div className="d-flex justify-content-center">
                <h1>
                  <img width="50px" src={BASE_URL.BASE_URL + "img/success_icon.png"} alt="success_icon" />
                </h1>
              </div>

              <div className="d-flex justify-content-center">
                <h3>Withdrawal Successful</h3>
              </div>
              <div className="mt-4 pop_up_style">
                <ul style={{ padding: "10px 10px 0px 10px" }} className="list_border">
                  <li>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        Date
                      </div>
                      <div className="d-flex justify-content-center">

                        <Moment format="DD/MM/YYYY hh:mm A">
                          {confirm_txn_data.date}
                        </Moment>

                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        Status
                      </div>
                      <div className="d-flex justify-content-center text-warning">
                        Pending
                      </div>
                    </div>

                  </li>
                  <li>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        Hash
                      </div>
                      <div className="d-flex justify-content-center">
                        {confirm_txn_data.Hash}
                      </div>
                    </div>

                  </li>
                  <li>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        Amount
                      </div>
                      <div className="text-right">
                        {`${gas_fee_data?.total_coin}`}
                        <br />
                        $ {gas_fee_data?.total_coin_currency}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        Network Fee
                      </div>
                      <div className="text-right">
                        {`${gas_fee_data?.gas_coin} ${gas_fee_data?.gas_coin_name}`}
                        <br />
                        $ {gas_fee_data?.gas_currency}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      {props?.success_loader ? (
        null
      ) : (
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button className="btn btn-success" style={{ padding: "0.5rem 5rem" }} onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}


export default Wallet;
