import React from "react";
import BASE_URL from "../../_constant/index";
const Featured = () => {
  return (
    <>
      <section className="featured_in_section_v dark-gray-bg-v py-5">
        <div className="container">
          <div className="row row-cols-1">
            <div className="col pr-lg-5 text-center">
              <div className="banner_main_heading_v">
                <h1>
                  As <span className="text-orange">Featured In</span>
                </h1>
              </div>
              <ul className="d-flex flex-wrap justify-content-center mt-5">
                <li>
                  <img
                    src={BASE_URL.BASE_URL + "img/images/featured-1.svg"}
                    className="img-fluid dark-theme-img"
                    alt="icon"
                  />
                  <img
                    src={
                      BASE_URL.BASE_URL +
                      "img/images/light-theme/featured-1.svg"
                    }
                    className="img-fluid light-theme-img"
                    alt="icon"
                  />
                </li>
                <li>
                  <img
                    src={BASE_URL.BASE_URL + "img/images/featured-2.svg"}
                    className="img-fluid dark-theme-img"
                    alt="icon"
                  />
                  <img
                    src={
                      BASE_URL.BASE_URL +
                      "img/images/light-theme/featured-2.svg"
                    }
                    className="img-fluid light-theme-img"
                    alt="icon"
                  />
                </li>
                <li>
                  <img
                    src={BASE_URL.BASE_URL + "img/images/featured-3.svg"}
                    className="img-fluid dark-theme-img"
                    alt="icon"
                  />
                  <img
                    src={
                      BASE_URL.BASE_URL +
                      "img/images/light-theme/featured-3.svg"
                    }
                    className="img-fluid light-theme-img"
                    alt="icon"
                  />
                </li>
                <li>
                  <img
                    src={BASE_URL.BASE_URL + "img/images/featured-4.svg"}
                    className="img-fluid dark-theme-img"
                    alt="icon"
                  />
                  <img
                    src={
                      BASE_URL.BASE_URL +
                      "img/images/light-theme/featured-4.svg"
                    }
                    className="img-fluid light-theme-img"
                    alt="icon"
                  />
                </li>
                <li>
                  <img
                    src={BASE_URL.BASE_URL + "img/images/featured-5.svg"}
                    className="img-fluid dark-theme-img"
                    alt="icon"
                  />
                  <img
                    src={
                      BASE_URL.BASE_URL +
                      "img/images/light-theme/featured-5.svg"
                    }
                    className="img-fluid light-theme-img"
                    alt="icon"
                  />
                </li>
                <li>
                  <img
                    src={BASE_URL.BASE_URL + "img/images/featured-6.svg"}
                    className="img-fluid dark-theme-img"
                    alt="icon"
                  />
                  <img
                    src={
                      BASE_URL.BASE_URL +
                      "img/images/light-theme/featured-6.svg"
                    }
                    className="img-fluid light-theme-img"
                    alt="icon"
                  />
                </li>
                <li>
                  <img
                    src={BASE_URL.BASE_URL + "img/images/featured-7.svg"}
                    className="img-fluid dark-theme-img"
                    alt="icon"
                  />
                  <img
                    src={
                      BASE_URL.BASE_URL +
                      "img/images/light-theme/featured-7.svg"
                    }
                    className="img-fluid light-theme-img"
                    alt="icon"
                  />
                </li>
                <li>
                  <img
                    src={BASE_URL.BASE_URL + "img/images/featured-8.svg"}
                    className="img-fluid dark-theme-img"
                    alt="icon"
                  />
                  <img
                    src={
                      BASE_URL.BASE_URL +
                      "img/images/light-theme/featured-8.svg"
                    }
                    className="img-fluid light-theme-img"
                    alt="icon"
                  />
                </li>
                <li>
                  <img
                    src={BASE_URL.BASE_URL + "img/images/featured-9.svg"}
                    className="img-fluid dark-theme-img"
                    alt="icon"
                  />
                  <img
                    src={
                      BASE_URL.BASE_URL +
                      "img/images/light-theme/featured-9.svg"
                    }
                    className="img-fluid light-theme-img"
                    alt="icon"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default Featured;
