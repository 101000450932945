import React from "react";
import Slider from "react-slick";
import BASE_URL from "../../_constant/index";
const OurApps = () => {
  const settings = {
    infinite: true,
    dots: true,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="our_apps_section_v dark-gray-bg-v">
        <div className="container">
          <div className="row row-cols-1 row-cols-lg-2 row-cols-md-1 row-cols-sm-1 align-items-center gap-v-4">

            <div className="col pr-lg-5">
              <div className="banner_main_heading_v">
                <h1 className="mb-4">
                  Vynk <span className="text-orange fw-1"> Ecosystem</span>
                </h1>
              </div>
              <p className="my-4">
                Utilise our wide range of applications to keep an eye on your finances. Our services include spot trading, margin trading, copy trading, launchpad, p2p, swap, staking , airdrops, crypto savings account, bill payment services, payment gateway services, ecommerce and blockchain.
              </p>

              {/*<button className="btn btn-gray-v text-white px-4 btn-custom">
                Explore All Apps
              </button>*/}
            </div>

            <div className="col">
              <div className="our_app_slider_v">
                <h5 className="px-3 pb-5">Apps</h5>
                <div className="our_app_slider_logos_v">
                  <Slider {...settings}>
                    <div className="text-center">
                      {/* <a href="#"> */}
                      <img
                        src={
                          BASE_URL.BASE_URL +
                          "img/images/appslider/vynkchain.png"
                        }
                        alt="icon"
                        className="p-2 gray_bg_v dark-theme-img"
                      />
                      <img
                        src={BASE_URL.BASE_URL + "img/images/light-theme/appslider/vynkchain.png"}
                        alt="icon"
                        className="p-2 gray_bg_v light-theme-img"
                      />
                      <p className="fs-5v mt-2">Vynkchain</p>
                      {/* </a> */}
                    </div>

                    <div className="text-center">
                      {/* <a href="#"> */}
                      <img
                        src={
                          BASE_URL.BASE_URL +
                          "img/images/appslider/vynkpay_dapp.png"
                        }
                        alt="icon"
                        className="p-2 gray_bg_v dark-theme-img"
                      />
                      <img
                        src={
                          BASE_URL.BASE_URL +
                          "img/images/light-theme/appslider/vynkpay_dapp.png"
                        }
                        alt="icon"
                        className="p-2 gray_bg_v light-theme-img"
                      />
                      <p className="fs-5v mt-2">Vynkpay (Dapp)</p>
                      {/* </a> */}
                    </div>

                    <div className="text-center">
                      {/* <a href="#"> */}
                      <img
                        src={
                          BASE_URL.BASE_URL +
                          "img/images/appslider/vynksafe.png"
                        }
                        alt="icon"
                        className="p-2 gray_bg_v dark-theme-img"
                      />
                      <img
                        src={
                          BASE_URL.BASE_URL +
                          "img/images/light-theme/appslider/vynksafe.png"
                        }
                        alt="icon"
                        className="p-2 gray_bg_v light-theme-img"
                      />
                      <p className="fs-5v mt-2">Vynk safe</p>
                      {/* </a> */}
                    </div>

                    <div className="text-center">
                      {/* <a href="#"> */}
                      <img
                        src={
                          BASE_URL.BASE_URL +
                          "img/images/appslider/vynkpay_payments_app.png"
                        }
                        alt="icon"
                        className="p-2 gray_bg_v dark-theme-img"
                      />
                      <img
                        src={
                          BASE_URL.BASE_URL +
                          "img/images/light-theme/appslider/vynkpay_payments_app.png"
                        }
                        alt="icon"
                        className="p-2 gray_bg_v light-theme-img"
                      />
                      <p className="fs-5v mt-2">vynkpay (payments app)</p>
                      {/* </a> */}
                    </div>
                    <div className="slide text-center">
                      {/* <a href="#"> */}
                      <img
                        src={
                          BASE_URL.BASE_URL +
                          "img/images/appslider/vynk_ultra_chain.png"
                        }
                        alt="icon"
                        className="p-2 gray_bg_v dark-theme-img"
                      />
                      <img
                        src={
                          BASE_URL.BASE_URL +
                          "img/images/light-theme/appslider/vynk_ultra_chain.png"
                        }
                        alt="icon"
                        className="p-2 gray_bg_v light-theme-img"
                      />
                      <p className="fs-5v mt-2">vynk ultra chain</p>
                      {/* </a> */}
                    </div>
                    <div className="text-center">
                      {/* <a href="#"> */}
                        <img
                          src={
                            BASE_URL.BASE_URL +
                            "img/images/appslider/vynkstores.png"
                          }
                          alt="icon"
                          className="p-2 gray_bg_v dark-theme-img"
                        />
                        <img
                          src={
                            BASE_URL.BASE_URL +
                            "img/images/light-theme/appslider/vynkstores.png"
                          }
                          alt="icon"
                          className="p-2 gray_bg_v light-theme-img"
                        />
                        <p className="fs-5v mt-2">vynkstores</p>
                      {/* </a> */}
                    </div>
                  </Slider>
                </div>
              </div>
            </div>


          </div>

          <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 align-items-center our_apps_v_road position-relative gap-v-4 justify-content-center">
            <div className="col pr-lg-5">
              <div className="our_apps_v_road_card text-center">
                <div className="our_apps_v_road_card_img mx-auto">
                  <img
                    src={BASE_URL.BASE_URL + "img/images/system.svg"}
                    className="img-fluid"
                  />
                </div>
                <h4 className="mt-4 mb-3">System</h4>
                <p>
                  At Vynk Safe, both centralised and decentralised systems let our users have a seamless experience throughout their journey with us.
                </p>
              </div>
            </div>

            <div className="col our_apps_v_road_card_center px-lg-2">
              <div className="our_apps_v_road_card text-center">
                <div className="our_apps_v_road_card_img mx-auto">
                  <img
                    src={BASE_URL.BASE_URL + "img/images/services.svg"}
                    className="img-fluid"
                  />
                </div>
                <h4 className="mt-4 mb-3">Services</h4>
                <p>
                  VynkSafe offers a plethora of great services such as Trading, Savings Accounts, Launchpad, and many more.
                </p>
              </div>
            </div>

            <div className="col pl-lg-5">
              <div className="our_apps_v_road_card text-center">
                <div className="our_apps_v_road_card_img mx-auto">
                  <img
                    src={BASE_URL.BASE_URL + "img/images/24x7support.svg"}
                    className="img-fluid"
                  />
                </div>
                <h4 className="mt-4 mb-3">Support</h4>
                <p>
                  We believe in the theory of post-support after providing the services. You shall find us at every step behind you with VynkSafe.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurApps;
