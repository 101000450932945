import React, { useRef, useEffect } from 'react'
import { widget } from "../../charting_library";
import BASE_URL from "../../_constant/index";
import { useParams } from 'react-router-dom'
import Datafeed from "./datafeed";



function getLanguageFromURL() {
    const regex = new RegExp("[\\?&]lang=([^&#]*)");
    const results = regex.exec(window.location.search);
    return results === null
        ? null
        : decodeURIComponent(results[1].replace(/\+/g, " "));
}


const Trading_View_full = ({ pair, theme, order_Price }) => {
    const params = useParams();
    let pair_symbol = params?.pair.replaceAll("_", "/");
 
    const ref = useRef();

    useEffect(() => {




        const widgetOptions = {
            symbol: "vyncsafe:BTC/USDT",

            symbol: "VYNKSAFE:" + pair_symbol,

            datafeed: Datafeed,

            interval: "1D",

            container: ref?.current,
            library_path: BASE_URL.BASE_URL + "charting_library/",

            locale: getLanguageFromURL() || "en",
            disabled_features: [
                "use_localstorage_for_settings",
                "header_symbol_search",
                "header_compare",
                "compare_symbol",
                "header_saveload",
                'study_templates',
                "go_to_date"
            ],

            charts_storage_url: "https://saveload.tradingview.com",
            charts_storage_api_version: "1.1",
            client_id: "tradingview.com",
            user_id: "public_user_id",
            fullscreen: false,
            autosize: true,
            theme: params?.theme,
            height: 550,

            studies_overrides: {},

        };
        const tvWidget = new widget(widgetOptions);

        tvWidget.onChartReady(() => {
            // tvWidget.headerReady().then(() => {
            //   const button = tvWidget.createButton();
            //   button.setAttribute("title", "Click to show a notification popup");
            //   button.classList.add("apply-common-tooltip");
            //   button.addEventListener("click", () =>
            //     tvWidget.showNoticeDialog({
            //       title: "Notification",
            //       body: "TradingView Charting Library API works correctly",
            //       callback: () => {
            //         console.log("Noticed!");
            //       },
            //     })
            //   );

            //   button.innerHTML = "Check API";
            // });
        });

    }, [])

    return (
        <div>
            <div ref={ref} style={{ minHeight: '75vh', height: '410px' }} />
        </div>
    )
}

export default Trading_View_full