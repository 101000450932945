import React, { useState, useEffect, useMemo } from "react";
import { InputGroup, Form, Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import BASE_URL from "../../_constant/index";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import utill from "../../_constant/utill";
import EmptyData from "../../components/emptyData";

const pagination = paginationFactory({});

const Funding = ({ funding, createDoller, btcCoinPrice }) => {
  const [searchText, setSearchText] = useState("");
  const [lowBalance, setLowBalance] = useState(false);
  const [fundingData, setFundingData] = useState();

  useEffect(() => {
    const lowBalanceList = lowBalance
      ? funding?.filter((coin) => coin.total_coin != 0)
      : funding;
    setFundingData(lowBalanceList);
  }, [funding, lowBalance]);
  const hideLowBalance = () => {
    setLowBalance(!lowBalance);
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    firstPageText: "First",
    prePageText: "<",
    nextPageText: ">",
  };
  const filteredData = useMemo(() => {
    return searchText
      ? fundingData?.filter(
          (item) =>
            item?.coin?.toLowerCase().includes(searchText.toLowerCase()) ||
            item?.symbol?.toLowerCase().includes(searchText.toLowerCase())
        )
      : fundingData;
  }, [searchText, fundingData]);

  const myData = useMemo(() => {
    if (!filteredData) return [];

    return filteredData?.map((item) => ({
      id: [item?.image_url, item?.id],
      coin: [item?.image_url, item?.symbol, item?.name],
      total: item?.total_coin,
      available: [item?.available_coin],
      in_order: [item?.frozen_coin],
      Btc_value: [item?.total_coin_currency, item?.btc_coin],
      action: item?.symbol,
      frozen_coin: item?.frozen_coin,
    }));
  });

  const columns = [
    {
      dataField: "coin",
      text: "Assets",

      formatter: (cell) => {
        return (
          <>
            <div className="d-flex pl-4">
              <div className="mr-2" style={{ width: "25%" }}>
                <img src={cell[0]} width="30px" height="30px" />
              </div>
              <div style={{ textAlign: "left" }}>
                {cell[1]} <br />{" "}
                <span
                  className="text-secondary"
                  style={{ fontSize: "11px", wordWrap: "normal" }}
                >
                  <b>{cell[2]}</b>
                </span>
              </div>
            </div>
          </>
        );
      },
      headerStyle: () => {
        return { width: "18%", textAlign: "center" };
      },
      style: () => {
        return { width: "18%" };
      },
    },
    {
      dataField: "total",
      text: "Total Amount",
      headerStyle: () => {
        return { width: "15%" };
      },
      style: () => {
        return { width: "15%" };
      },
      formatter: (cell) => {
        return <>{utill.beautifyNumber(cell, 12)}</>;
      },
    },

    {
      dataField: "available",
      text: "Available",
      formatter: (cell) => {
        return <>{utill.beautifyNumber(cell, 12)}</>;
      },
      headerStyle: () => {
        return { width: "13%" };
      },
      style: () => {
        return { width: "13%" };
      },
    },
    {
      dataField: "frozen_coin",
      text: "Frozen Coin",
      formatter: (cell) => {
        return <>{utill.beautifyNumber(cell, 12)}</>;
      },
      headerStyle: () => {
        return { width: "13%" };
      },
      style: () => {
        return { width: "13%" };
      },
    },

    {
      dataField: "Btc_value",
      text: "BTC Value",
      formatter: (cell) => {
        return (
          <>
            <div>
              {utill.beautifyNumber(cell[1], 6)}
              <p className="text-secondary">
                ≈ ${utill.beautifyNumber(cell[0], 2)}
              </p>
            </div>
          </>
        );
      },
      headerStyle: () => {
        return { width: "13%" };
      },
      style: () => {
        return { width: "13%" };
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell) => {
        let redirectUrl = `${BASE_URL.BASE_URL}Mywallet/Overview`;
        return (
          <>
            <div className="estimated-balance-btn justify-content-left">
              <Link
                to={redirectUrl}
                style={{ color: "#fa501c", textDecoration: "none" }}
                className="p2"
              >
                Buy/Sell
              </Link>
              <Link
                to={`${redirectUrl}?to=deposit?coin=${cell}`}
                style={{ color: "#fa501c", textDecoration: "none" }}
                className="p2 ml-4 mr-4"
              >
                Deposit
              </Link>
              <Link
                to={`${redirectUrl}?to=transfer?coin=${cell}`}
                style={{ color: "#fa501c", textDecoration: "none" }}
                className="p2"
              >
                Transfer
              </Link>
              {/* <Link to={redirectUrl} style={{ color: "#1ec6f6", textDecoration: "none" }} className="p2">
              Convert
            </Link> */}
              {/* <Link to={redirectUrl} style={{ color: "#1ec6f6", textDecoration: "none" }} className="p2">
              Earn
            </Link>
            <Link to={redirectUrl} style={{ color: "#1ec6f6", textDecoration: "none" }} className="p2">
              Convert
            </Link> */}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      {/* <div className="card-body">
        <div className="settings_top_title d-flex flex-wrap justify-content-between align-items-center">
          <div>
            <h5 className="card-title">Funding</h5>
          </div>
          <div className="estimated-balance-btn">
            <button className="btn deposit-btn">Deposit</button>
            <button className="btn withdraw-btn">Withdraw</button>
            <button className="btn withdraw-btn">Send</button>
            <button className="btn withdraw-btn">Transfer</button>
            <button className="btn withdraw-btn">History</button>
          </div>
        </div>
      </div> */}
      <div className="card">
        <div className="card-body">
          <div className="settings_top_title d-flex flex-wrap justify-content-between align-items-center">
            <div
              className="spot-balance-section mtb15 pb-3 d-flex flex-wrap justify-content-between"
              style={{ borderBottom: "1px solid #5e728f4d" }}
            >
              <div>
                <h5 className="card-title">Estimated Balance</h5>
                <div className="card-estimated-balance-section mt15 pb-3">
                  <h3 className="card-estimated-balance">
                    {utill.beautifyNumber(createDoller / btcCoinPrice, 4)} BTC{" "}
                    <span className="text-secondary">
                      ≈ ${utill.beautifyNumber(createDoller, 2)}
                    </span>
                  </h3>
                </div>
              </div>
              {/* <div className="funding_estimated_balance_right">
                <button className="btn buy-sell-btn"><span><img src={`${BASE_URL.BASE_URL}img/wallet-overview/buy-sell.png`} alt="buy-sell" /> </span><p className="mt-1 mb-0">Buy/Sell</p></button>
                <button className="btn pay-btn"><span><img src={`${BASE_URL.BASE_URL}img/wallet-overview/pay.png`} alt="pay" /></span><p className="mt-1 mb-0">Pay</p></button>
                <button className="btn giftcard-btn"><span><img src={`${BASE_URL.BASE_URL}img/wallet-overview/gift-card.png`} alt="Gift Card" /></span><p className="mt-1 mb-0">Gift Card</p></button>
              </div> */}
            </div>
          </div>
        </div>
        <div className="card-body pt-0">
          <Row className="mb-3">
            <Col md={6}>
              <div>
                <h5 className="card-title">Crypto Balance</h5>
              </div>
            </Col>
            <Col md={6}>
              <div className="d-sm-flex align-items-center">
              
                <div className="ml-sm-5 w-100 hidesmallbalances_checkbox mt-3 mt-sm-0">
                  <Form.Group controlId="formBasicCheckbox" className="mb-0">
                    <label className="form__check  check">
                      <input
                        className="check__input visually-hidden"
                        onChange={hideLowBalance}
                        type="checkbox"
                      />
                      <span className="check__box"></span>
                      <span className="check__text">Hide 0 balance Assets</span>
                    </label>
                  </Form.Group>
                </div>
                <div className="w-100">
                  <InputGroup className="wallet_crypto_balance_search">
                    <Form.Control
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      value={searchText || ""}
                      // bordered={false}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                    />
                    <InputGroup.Text
                      id="basic-addon2"
                      className="explore_search_bar"
                      style={{ borderRadius: "0px 4px 4px 0px" }}
                    >
                      <img
                        src={`${BASE_URL.BASE_URL}img/dashboard/Search.png`}
                        alt=""
                        width="30"
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </div>
              </div>
            </Col>
          </Row>

          <div className="crypto_balance_table_main Funding_wallet">
            <BootstrapTable
              bootstrap4
              noDataIndication={<EmptyData />}
              keyField="id"
              data={myData}
              columns={columns}
              pagination={paginationFactory(options)}
              className="table"
              classes="crypto_balance_table"
              headerClasses="crypto_balance_table_header"
              bodyClasses="crypto_balance_table_body"
              bordered={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Funding;
