import React, { useState, useEffect } from "react";
import BASE_URL from "../../_constant/index";
import {
  nickNameUpdate,
  getUser_NickName,
  notification_disable,
  manage_trading_fee,
} from "../../redux/apiActions/user.action";
import { Modal, Form, Button } from "react-bootstrap";
import { toast } from "../../components/Toast/Toast";
import { useSelector } from "react-redux";
import Loader from "../loader";

const Settings = ({ getDashBoardData }) => {
  let userData = useSelector((state) => state.user.userData);
  // console.log("userData", userData);
  const [nickName, setNickName] = useState(false);
  const [nick_name, setNick_Name] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [value, setValue] = useState(true);
  const [feeEnable, setEeeEnable] = useState(true);
  const [loading, setLoading] = useState(false);

  // console.log(feeEnable, "value");

  useEffect(() => {
    // const feeEnableData = userData.enable_vync_trade_fee
    if (userData.enable_vync_trade_fee == 1) {
      setEeeEnable(true);
    } else {
      setEeeEnable(false);
    }
  }, [userData]);


  const getUserNickName = async () => {
    const response = await getUser_NickName();
    if (response?.message === "success") {
      const newUserData = { ...userData, nick_name: response?.data?.nick_name };
      setNick_Name(response?.data?.nick_name);
      return newUserData;
    }
  };

  const NickName = async () => {
    setLoading(true);
    const data = new FormData();
    data.append("nickname", nick_name);
    const response = await nickNameUpdate(data);
    if (response?.data) {
      setNickName(false);
      getUserNickName();
      getDashBoardData();
      setLoading(false);
    } else {
      setErrorMsg(response?.message);
      setLoading(false);
    }
  };

  const UserNotification = async () => {
    setLoading(true);
    const step = value ? 0 : 1;
    localStorage.setItem("notificationEnabled", step);
    const data = new FormData();
    data.append("step", step);
    const response = await notification_disable(data);
    if (response) {
      setLoading(false);
      if (response?.status === 200) {
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
    }
  };

  const manageTradingFee = async () => {
    setLoading(true);
    const step = feeEnable ? 0 : 1;
    // localStorage.setItem("notificationEnabled", step);
    const data = new FormData();
    data.append("fee_enable", step);
    const response = await manage_trading_fee(data);
    if (response) {
      setLoading(false);
      if (response?.status === 200) {
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
    }
  };

  const handleChange = (e) => {
    setNick_Name(e.target.value);
    setErrorMsg(null);
  };

  useEffect(() => {
    getUserNickName();
    const storedNotificationValue = localStorage.getItem("notificationEnabled");
    if (storedNotificationValue !== null) {
      if (storedNotificationValue == 1) {
        setValue(true);
      } else {
        setValue(false);
      }
    }
  }, []);

  return (
    <>
      <div className="settings_top_title d-flex flex-wrap justify-content-between align-items-center mt-4">
        <div>
          <h5 className="card-title mb-3">Settings</h5>
          <p>
            You can now manage your profile and preferences to set the
            notifications alert and more.
          </p>
        </div>
        <div>
          <img
            src={`${BASE_URL.BASE_URL}img/dashboard/Setting.png`}
            alt="settings"
            width="70"
          />
        </div>
      </div>

      <div className="card mt15">
        <div className="card-body">
          <h5 className="card-title">My Profile</h5>
          <div className="settings-notification">
            <ul>
              <li>
                <div className="notification-info notification-info-w50 d-flex align-items-start">
                  <img
                    src={`${BASE_URL.BASE_URL}img/dashboard/Nickname.png`}
                    alt="nickname"
                    className="mr-3 mt-1"
                    width="30px"
                  />
                  <div>
                    <p>Nickname</p>
                    <span>Set a customized nickname for your profile.</span>
                  </div>
                </div>
                <p className="notification-info-p-w30">{nick_name}</p>
                <div className="custom-control custom-switch notification-info-button-w20">
                  <button
                    onClick={() => setNickName(true)}
                    className="order_cancel_btn"
                  >
                    Change
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="card mt15">
        <div className="card-body">
          <h5 className="card-title">Prefrences</h5>
          <div className="settings-notification">
            <ul>
              <div className="notification-info-inner-section pl-0">
                <div className="notification-info notification-info-w50 d-flex align-items-start">
                  <div>
                    <div
                      className="notification-info notification-info-w50 d-flex align-items-start"
                      style={{ width: "100%" }}
                    >
                      <img
                        src={`${BASE_URL.BASE_URL}img/dashboard/Notifications.png`}
                        alt="notifications"
                        className="mr-3 mt-1"
                        width="30px"
                      />
                      <div>
                        <p>Notifications</p>
                        <span>
                          Show a notification when funds send/received in your
                          wallet
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <span className="d-flex align-items-start notification-info-p-w30">
                  <img
                    src={`${BASE_URL.BASE_URL}img/settings/done.png`}
                    alt="done"
                    className="mr-2 mt-1"
                    width="13"
                  />
                  <p>{value ? "On" : "Off"}</p>
                </span>
                <div className="custom-control custom-switch notification-info-button-w20">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="apiStatus2"
                      disabled={loading ? true : false}
                      checked={value}
                      onChange={(e) => {
                        setValue(e.target.checked);
                        UserNotification(e);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="apiStatus2"
                    ></label>
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div className="card mt15">
        <div className="card-body">
          <h5 className="card-title">Trading Fee Settings</h5>
          <div className="settings-notification">
            <ul>
              <div className="notification-info-inner-section pl-0">
                <div className="notification-info notification-info-w50 d-flex align-items-start">
                  <div>
                    <div
                      className="notification-info notification-info-w50 d-flex align-items-start"
                      style={{ width: "100%" }}
                    >
                      <img
                        src={`${BASE_URL.BASE_URL}img/dashboard/login.png`}
                        alt="notifications"
                        className="mr-3 mt-1"
                        width="30px"
                      />
                      <div>
                        <p>Fee Deduction Preference</p>
                      </div>
                    </div>
                  </div>
                </div>
                <span className="d-flex align-items-start notification-info-p-w30">
                  <img
                    src={`${BASE_URL.BASE_URL}img/settings/done.png`}
                    alt="done"
                    className="mr-2 mt-1"
                    width="13"
                  />
                  <p>{feeEnable ? "Enabled" : "Disabled"}</p>
                </span>
                <div className="custom-control custom-switch notification-info-button-w20">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="fee_enable"
                      disabled={loading ? true : false}
                      checked={feeEnable}
                      onChange={(e) => {
                        setEeeEnable(e.target.checked);
                        manageTradingFee(e);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="fee_enable"
                    ></label>
                  </div>
                </div>
              </div>
              <span className="notification-info-w50">
                For VYNC (Polygon) trades, there’s a fixed fee of $0.40 when
                enabled, directly deducted from VYNC tokens, and if disabled,
                fees shift to 0.25% of the trade volume.
              </span>
            </ul>
          </div>
        </div>
      </div>
      <Modal
        show={nickName}
        onHide={() => setNickName(false)}
        backdrop="static"
        keyboard={false}
        contentClassName="Nick_Name_Popup"
        centered
      >
        <Modal.Header className="PopUp_Header" closeButton>
          <div>
            <h5 className="card-title mb-0">Edit Nickname</h5>
            <p className="text-secondary">
              set a customized nickname for your profile
            </p>
          </div>
        </Modal.Header>
        <Modal.Body className="PopUp_Body pr-0 pl-0">
          <Form>
            <Form.Label htmlFor="Nick_Name5 text-secondary">
              Nickname
            </Form.Label>
            <Form.Control
              type="text"
              name="nickname"
              placeholder="Enter your nick name"
              id="Nick_Name5"
              value={nick_name}
              onChange={handleChange}
              style={{ fontSize: "12px" }}
              aria-describedby="passwordHelpBlock"
              className="Nick_Name"
            />
            <div className="error-message text-danger">{errorMsg}</div>
            <div className="mt-3 d-flex justify-content-center">
              <Button
                variant="secondary"
                className="pl-4 pr-4"
                style={{ width: "50%" }}
                onClick={() => setNickName(false)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={loading ? true : false}
                onClick={NickName}
                style={{ width: "50%" }}
                className="ml-3 pl-4 pr-4"
              >
                {loading ? "Loading.." : "Save"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Settings;
