import React from 'react'
import {
    Row,
    Col,
    Container,
    Tab,
    Nav,
    Sonnet
} from 'react-bootstrap'
import BASE_URL from "../../_constant/index";

const OderCompleted = () => {
    return (
        <div>

            <section className="p2p_main_banner_section pt-5 p2p_mainTable">
                <Container>
                    <div className="p2p_my_offers_page_main w-100 p-0 pb-5">
                        <div className="row">
                            <div className="col-lg-7 col-12 pr-lg-5">
                                <div className="row p2p_offer_cancelled d-md-flex justify-content-between align-items-start pt-3">
                                    <div className="col-md-12 col-12">
                                        <ul className="d-sm-flex align-items-start align-items-center">

                                            <li>
                                                <div className="countdown_order_status_head d-flex align-items-center">
                                                    <h5 id="order_status_head">Order Completed</h5>
                                                    <div className="ml-2 countdown"></div>
                                                </div>
                                                <p id="order_status_subhead">Sucessfully sold 416.00000000 USDT<img src={`${BASE_URL.BASE_URL}img/images/tick.svg`} height="15px" className="ml-1"></img>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <hr />
                                <h6 className="mt-3">Order info</h6>
                                <div className="p2p_offer_cancelled_buying_section">
                                    <ul className="d-flex justify-content-between pt-2 mb-0">
                                        <li>
                                            <p>Amount</p>
                                            <h6 title="1"> $1</h6>
                                        </li>
                                        <li>
                                            <p>Price</p>
                                            <h6 title="1993.4934">$1993.4934</h6>
                                        </li>
                                        <li>
                                            <p>Quantity</p>
                                            <h6 title="0.000501631959253038"> 0.000501 ETH </h6>
                                        </li>
                                    </ul>
                                </div>
                                <hr />

                                <div className="order_completed_payment_method">
                                    <h6 className="mb-3">Payment method</h6>
                                    <div className="d-flex justify-content-between flex-wrap">
                                        <label className="basic-label" htmlFor="payment_method_1">Bank transfer</label>
                                        <div className="d-flex align-items-center reference_message_section flex-wrap">
                                            <div className="d-flex align-items-center">
                                                <p className="white_space_nowrap">Reference Message</p>
                                                <div className="tooltip ml-1"> <i className="icon ion-md-information-circle-outline tooltip" aria-hidden="true"></i>
                                                    <span className="tooltiptext">text</span>
                                                </div>
                                            </div>
                                            <p className="white_space_nowrap text-white">12345678925845656466 <a href="#" className="ml-1 text-orange"><i className="icon ion-md-copy"></i></a></p>

                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3 mt-sm-5">
                                    <div className="order_completed_right_btn d-sm-flex">
                                        <button className="p2p_dlt_confirm_btn theme-btn-2 theme-btn text-center mb-3 mt-1 " type="button">Reorder</button>
                                        <button className="p2p_cancel_btn_bg theme-btn-2 theme-btn text-center  mb-3 mt-1" type="button">Have a Question?</button>
                                    </div>
                                    <hr />
                                    <div className="">
                                        <h5 className="mb-3">My Feedback</h5>
                                        <div className="d-flex">
                                            <img className="mr-4" src={`${BASE_URL.BASE_URL}img/images/user.png`} height="40px"></img>
                                            <div className="">
                                                <div className="d-flex align-items-baseline justify-content-between">
                                                    <h6>DjBravo</h6>
                                                    <p className="white_space_nowrap">2023-08-07 10:24:58</p>
                                                </div>
                                                <p className="pr-sm-5 mr-sm-5">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                <ul className="d-flex align-items-center my_feedback_edit_section mt-3">
                                                    <li><a href=""> <img src={`${BASE_URL.BASE_URL}img/images/like_icon.png`} width="15"></img></a></li>
                                                    <li><a href=""> <img src={`${BASE_URL.BASE_URL}img/images/delete_icon.png`} width="15"></img></a></li>
                                                    <li><a href=""> <img src={`${BASE_URL.BASE_URL}img/images/edit_icon.png`} width="15"></img></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <hr />
                                    <div className="mt-4">
                                        <h6>Counterparty,s Feedback</h6>
                                        <div className="mt-2">
                                            <p>No Feedback</p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="col-lg-5 col-12 justify-content-start justify-content-lg-end">
                                <div className="order_status_chat_section">
                                    <div className="row p-3">
                                        <div className="col-md-12 col-12">
                                            <ul className="p-3 p2p_cancelled_user_pro">
                                                <li><img className="mr-2" src={`${BASE_URL.BASE_URL}img/images/p2p_cancelled_user.png`} height="30px"></img>Tester105</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card" id="chat2">
                                        <div className="card-body" data-mdb-perfect-scrollbar="true">
                                            <div id="messages">
                                            </div>
                                            <div className="card-footer text-muted d-flex justify-content-start align-items-center" id="chat_input_div">
                                                <span className="chat_not">Chat no longer available.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </div>
    )
}

export default OderCompleted