import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from "react-router-dom"
import BASE_URL from '../../_constant/index'
import BASE_URL_Tow from '../../_constant/index'
import { Accordion, Card, Button, Modal ,Row  , Container} from 'react-bootstrap'
import utill from "../../_constant/utill";
import TransferFind from './TransferFind'
import { walletBalance_transfer, add_Payment_Details } from '../../redux/apiActions/p2p.action'
import { useSelector } from "react-redux";
import { toast } from "../../components/Toast/Toast";
import Select from "react-select";
import Spinner from 'react-bootstrap/Spinner';


const Alloffertable = ({ checkPayment, setUser, balance, handle_Open_Trade, handleAllCoinBalance, setReceive, setPay, handleAllBalance, setPriceData, userOffer, LoadMore, getUserBalance, handlePay, handleReceive, pay, receive, error, loading, setError, page, perfectPage, image, paymentMethod, CheckPaymentMethods }) => {


    let user_Data = useSelector((state) => state.user.userData);

    const history = useHistory();
    const location = useLocation();
    const routeName = location.pathname;
    const returnToUrl = `${BASE_URL.BASE_URL.slice(0, -1)}${routeName}`;

    const [coinList, setCoinList] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [openAccordion, setOpenAccordion] = useState(null);
    const [showTransfer, setShowTransfer] = useState(false);
    const [selectedCoin, setSelectedCoin] = useState({
        coin: "",
        price: "",
    });


    const handleTransfer = (coin, price) => {
        setSelectedCoin({
            coin: coin,
            price: price,
        });
        setShowTransfer(true)
    }

    useEffect(() => {
        if (userOffer?.length !== 10) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [userOffer])

    const return_to_URL = btoa(`${returnToUrl}`)
    let return_to =
    return_to_URL && return_to_URL != ""
            ? `?return_to=${return_to_URL}`
            : "";


   

    const handleToggle = (eventKey, coin, item) => {

        if (!user_Data?.access_token) {
            // Redirect to login page
            history.push(`${BASE_URL.BASE_URL}login${return_to}`)
            return;
        }

        setOpenAccordion(openAccordion === eventKey ? null : eventKey);
        setError("")
        setPriceData(item);
        getUserBalance(coin);
        setReceive("");
        setPay("");
    };

    useEffect(async () => {
        const fetchData = async () => {
            try {
                const response = await walletBalance_transfer();
                if (response.status === 200) {

                    const formattedPaymentMethods = response?.data?.map((Methods) => ({
                        value: Methods?.code,
                        code: Methods?.code,
                        image: Methods?.image,
                        type: Methods?.coin_type,
                        min_trade_amount: Methods?.min_trade_amount,
                        max_trade_amount: Methods?.max_trade_amount,
                        price: Methods?.price,
                        type: Methods?.coin_type,
                        label: Methods?.symbol,
                        coin_currency_price: Methods?.coin_currency_price,
                        contract_address: Methods?.contract_address,
                        network_type: Methods?.network_type,
                        symbol: Methods?.symbol
                    }));
                    setCoinList(formattedPaymentMethods);

                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    const [showAddBank, setShoAddBank] = useState(false)
    const [addBank, setAddBank] = useState(false);
    const [errors, setErrors] = useState({});
    const [addNewBankSelectedMethod, setAddNewBankSelectedMethod] = useState();
    const [upiId, setUpiId] = useState("")
    const [BankData, setBankData] = useState({
        name: "",
        bankName: "",
        accountNo: "",
        ifscCode: "",
        // upiID: ""
    })

    const setAddCoinValue = async (val) => {
        setAddNewBankSelectedMethod(val)
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setBankData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const addNewBankPaymentMethod = async () => {
        var reg = /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/;
        try {
            const validationErrors = {};
            if (!addNewBankSelectedMethod) {
                validationErrors.paymentMethod = 'Please select a payment method.';
            }

            if (!BankData.name) {
                validationErrors.fullName = 'Please enter your full name.';
            }
            if (addNewBankSelectedMethod?.label === 'UPI') {
                if (!upiId) {
                    validationErrors.upiId = 'Please enter your UPI id.';
                } else if (!/^[\w.-]+@[\w.-]+$/.test(upiId)) {
                    validationErrors.upiId = 'Invalid UPI ID format.';
                }
            } else {
                if (!BankData?.bankName) {
                    validationErrors.bankName = 'Please enter your bank name.';
                }
                if (!BankData?.accountNo) {
                    validationErrors.accountNo = 'Please enter your account no';
                }
                if (!BankData?.ifscCode) {
                    validationErrors.ifscCode = 'Please enter IFSC code.';
                } else if (!BankData?.ifscCode.match(reg)) {
                    validationErrors.ifscCode = 'Invalid IFSC Code format.';
                }
            }

            if (Object.keys(validationErrors)?.length > 0) {
                setErrors(validationErrors);
                return;
            }

            const data = new FormData();
            data.append("payment_type", addNewBankSelectedMethod?.type || "")
            data.append("bank_name", BankData?.bankName || "")
            data.append("bank_account_no", BankData?.accountNo || "")
            data.append("bank_ifsc_code", BankData?.ifscCode || "")
            data.append("full_name", BankData?.name)
            data.append("upi_id", upiId || "")

            const response = await add_Payment_Details(data)
            if (response.status == 200) {
                toast?.successes(response?.message || response?.data?.message);
                CheckPaymentMethods();
                setUpiId("");
                setAddNewBankSelectedMethod(null);
                setBankData({
                    name: "",
                    bankName: "",
                    accountNo: "",
                    ifscCode: "",
                });
                setErrors({})
                setAddBank(false)
            } else {
                toast?.errors(response?.message || response?.data?.message)

                setErrors({})
                setAddBank(false)
            }
        } catch (error) {
            console.log(error)

        }
    }


    return (
        <>
            <Modal
                centered
                show={showAddBank}
                onHide={() => setShoAddBank(false)}
                className="p2pOfferViewModal"
            >
                <Modal.Body className="text-center pt-4">
                    <img src={`${BASE_URL.BASE_URL}img/images/sure-icon.png`} className="dark-theme-img" width="120"></img>
                    <img src={`${BASE_URL.BASE_URL}img/images/sure-icon-light.png`} className="light-theme-img" width="120"></img>
                    <h4 className="my-3">Information</h4>
                    <h6 className="gray_colour">Please add payment method to proceed.</h6>
                </Modal.Body>
                <Modal.Footer className="pb-4">
                    <Button variant="secondary"
                        onClick={() => setShoAddBank(false)}
                        className="btn disable_btn_bg harvest_btn_pools theme-btn-2 theme-btn"
                    >
                        Cancel
                    </Button>
                    <Button variant="primary"
                        // disabled={loading ? true : false} 
                        onClick={() => {
                            setShoAddBank(false)
                            setAddBank(true)
                        }}
                        className="p2p_dlt_confirm_btn theme-btn-2 theme-btn">
                        Proceed
                    </Button>

                </Modal.Footer>
            </Modal>
            <Modal show={addBank} centered onHide={() => { setAddBank(false) }} animation={false} className="p2pOfferViewModal">
                <Modal.Header closeButton>
                    <Modal.Title><h5>Add payment Method</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group text-left">
                        <label>Select Payment Method</label>
                        <Select
                            placeholder='Select Payment Method'
                            className="wallet_network_select custom_Curency-box"
                            classNamePrefix="custom_select"
                            isSearchable={true}
                            name='Assets'
                            options={paymentMethod}
                            value={addNewBankSelectedMethod}
                            onChange={(e) => {
                                setAddCoinValue(e);
                            }}

                            getOptionValue={(option) => option?.value}
                            getOptionLabel={(option) => (
                                <div className='d-flex align-items-center'>
                                    <img src={`${BASE_URL_Tow.BASE_URL_Tow}${option?.image}`} alt={option?.label} width='25' className='mr-2' />
                                    {option.label}
                                </div>
                            )}

                        />
                        <p className="text-danger" id="p2p_location_error">{errors?.paymentMethod}</p>
                        {addNewBankSelectedMethod &&
                            <>
                                <div className="border my-4"></div>
                                <div className="form-group text-left">
                                    <label>Full Name</label>
                                    <input
                                        type="text"
                                        value={BankData.name}
                                        name="name"
                                        onChange={handleChange}
                                        className="form-control"
                                        placeholder="Full Name"
                                        autoComplete="off"
                                        maxLength="30" />
                                    <p className="text-danger" id="p2p_location_error">{errors?.fullName}</p>
                                </div>
                                {addNewBankSelectedMethod?.label == "UPI" ? (<>
                                    <div className="form-group text-left">
                                        <label>UPI ID</label>
                                        <input
                                            type="text"
                                            value={upiId}
                                            name="upiID"
                                            onChange={(e) => { setUpiId(e.target.value) }}
                                            className="form-control"
                                            id="edit_upi_id" placeholder="UPI ID" autoComplete="off" maxLength="45" />
                                        <p className="text-danger" id="p2p_location_error">{errors?.upiId}</p>
                                    </div>
                                </>) : (<>
                                    <div className="form-group text-left">
                                        <label>Bank Name</label>
                                        <input type="text"
                                            name="bankName"
                                            onChange={handleChange}
                                            value={BankData.bankName}
                                            className="form-control" placeholder="Bank Name" autoComplete="off" maxLength="30" />
                                        <p className="text-danger" id="p2p_location_error">{errors?.bankName}</p>
                                    </div>

                                    <div className="form-group text-left">
                                        <label>Bank Account Number</label>
                                        <input type="text"
                                            name="accountNo"
                                            onChange={handleChange}
                                            value={BankData.accountNo}
                                            className="form-control"

                                            id="edit_upi_id"
                                            placeholder="Bank Account Number"
                                            autoComplete="off"
                                            maxLength="45" />
                                        <p className="text-danger" id="p2p_location_error">{errors?.accountNo}</p>
                                    </div>
                                    <div className="form-group text-left">
                                        <label>IFSC Code</label>
                                        <input
                                            type="text"
                                            name="ifscCode"
                                            onChange={handleChange}
                                            value={BankData.ifscCode}
                                            className="form-control"
                                            id="edit_upi_id"
                                            placeholder="IFSC Code"
                                            autoComplete="off"
                                            maxLength="45"
                                        />
                                        <p className="text-danger" id="p2p_location_error">{errors?.ifscCode}</p>
                                    </div>

                                </>)}
                            </>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary"
                        disabled={loading ? true : false}
                        onClick={addNewBankPaymentMethod}
                        className="p2p_dlt_confirm_btn theme-btn-2 theme-btn">
                        {loading ? "Please wait" : "Add"}
                    </Button>

                    <Button variant="secondary" onClick={() => { setAddBank(false) }} className="btn disable_btn_bg harvest_btn_pools theme-btn-2 theme-btn">
                        Cancel
                    </Button>

                </Modal.Footer>
            </Modal>

            <Modal
                centered
                show={showTransfer}
                onHide={() => setShowTransfer(false)}
                className="p2pOfferViewModal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Transfer</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <TransferFind
                        getUserBalance={getUserBalance}
                        coinList={coinList}
                        // selectCoinData={coinData}
                        selectedCoin={selectedCoin}
                        setShowTransfer={setShowTransfer}
                    // currency={currency}
                    // selectedCurrency={selectedCurrency}
                    />
                </Modal.Body>

            </Modal>


            <section className="p2p_main_banner_section pt-5 p2p_mainTable">
                <Container>
                    <Row>
                        <div className="col-12">
                            <div className="accordion p2p_accordion offer_list" id="accordionExample">
                                <div className="p2p_table_heading w-100 ">
                                    <div className="row ">
                                        <div className="col-md-3 col-6">
                                            <p>Advertisers</p>
                                        </div>
                                        <div className="col-md-2 col-6">
                                            <p>Price</p>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <p>Quantity/ Limit</p>
                                        </div>
                                        <div className="col-md-2 col-6">
                                            <p>Payment Method</p>
                                        </div>
                                        <div className="col-md-2 col-6">
                                            <p className="text-right">Trade</p>
                                        </div>
                                    </div>
                                </div>

                                <div id="offer_list_below"></div>

                            </div>
                            <Accordion>
                                {userOffer?.length > 0 ? (<>
                                    {userOffer?.map((item, index) => {

                                        let Restricted = parseFloat(item?.total_amount) *
                                            parseFloat(item?.offer_price) <
                                            parseFloat(item?.min_trade_size);
                                        var separatedStrings = item?.payment_type?.split(",");
                                        return (
                                            < Card key={item?.id}>
                                                {openAccordion !== index && (
                                                    <Card.Header onClick={() => handleToggle(item?.id, item?.coin, item)} className="offer_list_head">
                                                        <div className='row justify-content-center align-items-center'>
                                                            <div className="col-lg-3 col-md-6 col-12 mt-3 mt-lg-0">
                                                                <div className="d-flex">

                                                                    {(item?.profile_pic_path || image) ? (<>
                                                                        <img className="mr-2"
                                                                            src={`${BASE_URL_Tow.BASE_URL_Tow}${image || item?.profile_pic_path} `}
                                                                            style={{
                                                                                borderRadius: "50%",
                                                                                height: "40px",
                                                                                width: "40px"
                                                                            }}
                                                                        /></>) : (<>
                                                                            <img className="mr-2"
                                                                                src="https://mlm.pixelsoftwares.com/vynksafe/vynksafe.com/assets/img/user.png"

                                                                                style={{
                                                                                    borderRadius: "50%",
                                                                                    height: "40px",
                                                                                    width: "40px"
                                                                                }}
                                                                            />
                                                                        </>)}

                                                                    <h6>{item?.nick_name}<br /><span className="">{item?.total_trade} orders | {item?.completion_rate} % Completion </span>
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-6 col-12 mt-3 mt-lg-0">
                                                                <div className="align-items-center">
                                                                    <p className='m-0'><span className="gray_colour"></span>
                                                                        {item?.offer_price}{" "} {item?.currency_code}</p>
                                                                    <span className="gray_colour">{item?.margin_percentage}% above market</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-6 col-12 mt-3 mt-lg-0">
                                                                <div className="d-flex justify_content_mobile_between">
                                                                    <div className="p2p_quantity_limit_first mr-2">
                                                                        <p className="m-0">Available : </p>
                                                                        <p className="m-0">Limit :</p>
                                                                    </div>
                                                                    <div className="p2p_quantity_limit_sec">
                                                                        <p className="safelist_text_colour m-0" title="6.0354694783307">
                                                                            {/* {parseFloat(item?.total_amount)} */}
                                                                            {utill.beautifyNumber(parseFloat(item?.total_amount))}
                                                                            {" "}
                                                                            {item?.coin?.toUpperCase()}</p>
                                                                        <p className="safelist_text_colour m-0">
                                                                            {item?.min_trade_size}{" "} {item?.currency_code} - {item?.max_trade_size}{" "} {item?.currency_code}                                </p>
                                                                    </div>
                                                                    <input type="hidden" value="1813.8994" id="price_3" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-6 col-12 mt-3 mt-lg-0">
                                                                <div className="p2p_list_style p2p_list_style_banktransfer">
                                                                    {separatedStrings?.map((item, index) => {
                                                                        return (
                                                                            <label key={index} data-toggle="tooltip" data-placement="top" title="" data-original-title="Bank transfer">
                                                                                {item}
                                                                            </label>
                                                                        )
                                                                    })}
                                                                </div>

                                                            </div>
                                                            <div className="col-lg-2 col-md-6 col-12 mt-3 mt-lg-0 d-flex align-items-center justify-content-lg-end  justify-content-center">
                                                                {Restricted ?
                                                                    <Button disabled className='Restricted'>
                                                                        Restricted
                                                                    </Button> :
                                                                    <a type="button" className={`theme - btn - 2 ${item?.trade_type == "1" ? `p2p_buy_btn_bg` : `p2p_sell_btn_bg`} `} data-id="3" data-tradetype={item?.trade_type}>
                                                                        {item?.trade_type == "1" ? `Buy` : "Sell"}{" "}{item?.coin?.toUpperCase()
                                                                        }</a>
                                                                }

                                                            </div>
                                                        </div>
                                                    </Card.Header>
                                                )}


                                                <Accordion.Collapse eventKey={item?.id} style={{ display: openAccordion === item?.id ? 'block' : 'none' }}>
                                                    <Card.Body className="p2p_collapse_body ">
                                                        <div className="row align-items-center">
                                                            <div className="col-xl-7 col-lg-12 mt-lg-0 mt-3">
                                                                <ul className="d-flex align-items-center">
                                                                    <Link onClick={() => { setUser(item?.username) }}
                                                                        to={`${BASE_URL.BASE_URL}p2p/All?to=profile`}>

                                                                        {(item?.profile_pic_path || image) ? (<>
                                                                            <img className="mr-2"
                                                                                src={`${BASE_URL_Tow.BASE_URL_Tow}${image || item?.profile_pic_path} `}
                                                                                style={{
                                                                                    borderRadius: "50%",
                                                                                    height: "40px",
                                                                                    width: "40px"
                                                                                }}
                                                                            /></>) : (<>
                                                                                <img className="mr-2"
                                                                                    src="https://mlm.pixelsoftwares.com/vynksafe/vynksafe.com/assets/img/user.png"

                                                                                    style={{
                                                                                        borderRadius: "50%",
                                                                                        height: "40px",
                                                                                        width: "40px"
                                                                                    }}
                                                                                />
                                                                            </>)}
                                                                    </Link>
                                                                    <h6>
                                                                        <Link onClick={() => { setUser(item?.username) }}
                                                                            to={`${BASE_URL.BASE_URL}p2p/All?to=profile`}>{item?.nick_name}</Link>
                                                                        <span className="ml-3 gray_colour">{item?.total_trade} orders{" "}|{" "}
                                                                            {item?.completion_rate}% Completion </span>
                                                                    </h6>
                                                                </ul>
                                                                <div className="pl-md-5">
                                                                    <div className="row p2p_manuel_inner mt-4 mb-2">
                                                                        <div className="col-sm-5 col-12">
                                                                            <div className="d-flex align-items-center justify_content_mobile_between">
                                                                                <p className="gray_colour m-0">Price : </p>
                                                                                <h6 className="ml-2"><span>
                                                                                    {parseFloat(item?.offer_price)}
                                                                                    {" "} {item?.currency_code}
                                                                                </span></h6>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-sm-7 col-12">
                                                                            <div className="d-flex align-items-center justify_content_mobile_between">
                                                                                <p className="gray_colour m-0">Available : </p>
                                                                                <h6 className="ml-2 text-right" title="6.0354694783307">
                                                                                    {/* {utill.valueFix(item?.total_amount[2])} */}
                                                                                    {utill.beautifyNumber(parseFloat(item?.total_amount))}
                                                                                    {/* {parseFloat(item?.total_amount)} */}
                                                                                    {" "}
                                                                                    {item?.coin?.toUpperCase()}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-5 col-12">
                                                                            <div className="d-flex align-items-center justify_content_mobile_between">
                                                                                <p className="gray_colour m-0">Payment Time Limit : </p>
                                                                                <h6 className="ml-2">{item?.payment_time}</h6>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-7 col-12">
                                                                            <div className="d-flex align-items-start justify_content_mobile_between">
                                                                                <p className="gray_colour white-space-nowrap m-0">Buyer’s
                                                                                    payment method :</p>
                                                                                <h6 className="ml-2 text-left mt-1">{item?.payment_type}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <h6 className="mt-4">Terms &amp; Conditions</h6>
                                                                    <p className="mt-2 gray_colour" style={{ maxHight: "150px", overflow: "auto" }}>
                                                                        {item?.terms_trade}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-5 col-lg-12 mt-lg-0 mt-3">
                                                                {item?.trade_type == "1" ?
                                                                    <form className="p2p_form_section">
                                                                        <div className="form-group mb-4">
                                                                            <label for="exampleInputEmail1">I want to pay</label>
                                                                            <div className="p2p_will_receive_input">
                                                                                <span className="pr-5 mr-2"
                                                                                    onClick={() => handleAllBalance(item?.total_amount)} style={{ right: "4px" }}>
                                                                                    <button type="button"
                                                                                        className="all_coin_btn"
                                                                                        data-totalAmount={item?.coin_rate_USD}
                                                                                        data-id={item?.id}
                                                                                        data-decimal={item?.decimal}
                                                                                        data-decimlmath={item?.decimal_math}>
                                                                                        All
                                                                                    </button>
                                                                                </span>

                                                                                <span>{item?.currency_code}</span>
                                                                                <input
                                                                                    className="form-control pay_amount_txt"
                                                                                    type="text"
                                                                                    value={pay}
                                                                                    onChange={handlePay}
                                                                                    data-id={item?.id}
                                                                                    data-tradetype={item?.trade_type}
                                                                                    data-mintrade={item?.min_trade_size}
                                                                                    data-maxtrade={item?.max_trade_size}
                                                                                    data-currency={item?.currency_code}
                                                                                    name="pay_amount_txt_3"
                                                                                    id="pay_amount_txt_3"
                                                                                    placeholder={`${item?.min_trade_size} - ${item?.max_trade_size} `}
                                                                                    max="20"
                                                                                    data-decimal={item?.decimal}
                                                                                    data-decimalmath={item?.decimal_math}
                                                                                />
                                                                            </div>
                                                                            <div className="text-danger offer_c_err mt-1" id="coin_error_3">{error}</div>
                                                                            <div id="bal_div_3"></div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label for="exampleInputPassword1">I will receive</label>
                                                                            <div className="p2p_will_receive_input">
                                                                                <span>{item?.coin?.toUpperCase()}</span>
                                                                                <input
                                                                                    className="form-control pay_coin_txt"
                                                                                    type="number"
                                                                                    value={receive}
                                                                                    onChange={handleReceive}
                                                                                    data-id={item?.id}
                                                                                    data-tradetype={item?.trade_type}
                                                                                    data-mintrade={item?.min_trade_size}
                                                                                    data-maxtrade={item?.max_trade_size}
                                                                                    data-currency={item?.currency_code}
                                                                                    name="pay_coin_txt_3"
                                                                                    id="pay_coin_txt_3"
                                                                                    placeholder="0.00"
                                                                                    max="20"
                                                                                    data-decimal={item?.decimal}
                                                                                    data-decimalmath={item?.decimal_math}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="p2p_form_inner_btn d-flex">
                                                                            <button onClick={() => {
                                                                                setOpenAccordion(null)
                                                                                setReceive("")
                                                                                setPay("")
                                                                            }
                                                                            }
                                                                                className="theme-btn-2 theme-btn text-center p2p_cancel_btn p2p_collapse_close_btn"
                                                                                type="button"
                                                                                data-toggle="collapse"
                                                                                data-target={`#collapse - ${item?.id} `}
                                                                                aria-controls={`collapse - ${item?.id} `}
                                                                                aria-expanded="true"
                                                                            >Cancel</button>
                                                                            {Restricted ?
                                                                                <Button disabled className='Restricted'>
                                                                                    Restricted
                                                                                </Button> :
                                                                                <button
                                                                                    className={`theme - btn - 2 ${item?.trade_type == "1" ? `p2p_buy_btn_bg` : `p2p_sell_btn_bg`} `}
                                                                                    type="button"
                                                                                    onClick={() => handle_Open_Trade(item?.id)}
                                                                                    id={`purchaseBtn_${item?.id} `}
                                                                                    data-id={item?.id}
                                                                                    data-tradetype={item?.trade_type}
                                                                                    data-coin={item?.coin}
                                                                                    data-networkid="2"
                                                                                    disabled={loading ? true : false}
                                                                                    style={{ border: "none" }}
                                                                                >
                                                                                    {loading ? "Please wait" :
                                                                                        (<>
                                                                                            {item?.trade_type == "1" ? `Buy` : "Sell"}{" "}{item?.coin?.toUpperCase()}
                                                                                        </>)
                                                                                    }

                                                                                </button>
                                                                            }
                                                                        </div>
                                                                    </form>
                                                                    : <form className="p2p_form_section">
                                                                        <div className="form-group">
                                                                            <label for="exampleInputEmail1">I want to sell</label>
                                                                            <div className="p2p_will_receive_input">
                                                                                <span className="pr-5 mr-2"
                                                                                    onClick={() => handleAllCoinBalance(item?.total_amount, balance)}
                                                                                    style={{ right: "4px" }}
                                                                                >
                                                                                    <button
                                                                                        type="button"
                                                                                        className="all_coin_btn" data-totamount={item?.coin_rate_USD}
                                                                                        data-id={item?.id}
                                                                                        data-decimal={item?.decimal}
                                                                                        data-decimalmath={item?.decimal_math}
                                                                                    >
                                                                                        All
                                                                                    </button>

                                                                                </span>
                                                                                <span>{item?.coin?.toUpperCase()}</span>
                                                                                <input
                                                                                    className="form-control pay_coin_txt"
                                                                                    type="number"
                                                                                    value={receive}
                                                                                    onChange={handleReceive}
                                                                                    data-id={item?.id}
                                                                                    data-tradetype={item?.trade_type}
                                                                                    data-mintrade={item?.min_trade_size}
                                                                                    data-maxtrade={item?.max_trade_size}
                                                                                    data-currency={item?.currency_code}
                                                                                    name="pay_coin_txt_3"
                                                                                    id="pay_coin_txt_3"
                                                                                    placeholder="0.00"
                                                                                    max="20"
                                                                                    data-decimal={item?.decimal}
                                                                                    data-decimalmath={item?.decimal_math}
                                                                                />

                                                                            </div>

                                                                            <div id="bal_div_4" style={{ textAlign: "right" }}>
                                                                                <span className="w-100 text-right small">
                                                                                    Balance:
                                                                                    {utill.beautifyNumber(parseFloat(balance?.total_coin))}
                                                                                    {/* {parseFloat(balance?.total_coin)} {" "} */}
                                                                                    {balance?.coin?.toUpperCase()}{" "}
                                                                                    <a

                                                                                        className="transfer_btn"
                                                                                        data-id="4"
                                                                                        onClick={() => {
                                                                                            handleTransfer(
                                                                                                balance?.coin,
                                                                                                item?.offer_price
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        Transfer
                                                                                    </a>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group mb-4">
                                                                            <label for="exampleInputPassword1">I will receive</label>
                                                                            <div className="p2p_will_receive_input">
                                                                                <span>{item?.currency_code}</span>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control pay_amount_txt"
                                                                                    value={pay}
                                                                                    onChange={handlePay}
                                                                                    data-id={item?.id}
                                                                                    data-tradetype={item?.trade_type}
                                                                                    data-mintrade={item?.min_trade_size}
                                                                                    data-maxtrade={item?.max_trade_size}
                                                                                    data-currency={item?.currency_code}
                                                                                    name="pay_amount_txt_3"
                                                                                    id="pay_amount_txt_3"
                                                                                    placeholder={`${item?.min_trade_size} - ${item?.max_trade_size} `}
                                                                                    max="20"
                                                                                    data-decimal={item?.decimal}
                                                                                    data-decimalmath={item?.decimal_math}
                                                                                />
                                                                            </div>
                                                                            <div className="text-danger offer_c_err mt-1" id="coin_error_3">{error}</div>
                                                                        </div>
                                                                        <div className="p2p_form_inner_btn d-flex">
                                                                            <button onClick={() => {
                                                                                setOpenAccordion(null)
                                                                                setReceive("")
                                                                                setPay("")
                                                                            }
                                                                            }
                                                                                className="theme-btn-2 theme-btn text-center p2p_cancel_btn p2p_collapse_close_btn"
                                                                                type="button"
                                                                                data-toggle="collapse"
                                                                                data-target={`#collapse - ${item?.id} `}
                                                                                aria-controls={`collapse - ${item?.id} `}
                                                                                aria-expanded="true"
                                                                            >
                                                                                Cancel
                                                                            </button>

                                                                            {(checkPayment !== null && checkPayment?.length > 0) ?
                                                                                <button
                                                                                    onClick={() => handle_Open_Trade(item?.id)}
                                                                                    disabled={loading ? true : false}
                                                                                    className=" theme-btn-2 theme-btn text-center purchaseBtn p2p_formsell_btn_bg "
                                                                                    type="button"
                                                                                    id="purchaseBtn_4"
                                                                                    data-id="4"
                                                                                    data-tradetype="2"
                                                                                    data-coin={item?.coin}
                                                                                    data-networkid="2"
                                                                                >
                                                                                    {loading ? "Please wait" : `Sell ${item?.coin?.toUpperCase()} `}

                                                                                </button> :
                                                                                <Button
                                                                                    onClick={() => setShoAddBank(true)}
                                                                                    className=" theme-btn-2 theme-btn text-center purchaseBtn p2p_formsell_btn_bg">
                                                                                    {`Sell ${item?.coin?.toUpperCase()}`}
                                                                                </Button>
                                                                            }
                                                                        </div>
                                                                    </form>}
                                                            </div>

                                                        </div>

                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        )
                                    })}
                                </>) : (<>
                                    <div className="col-12 mb-5 d-flex justify-content-center mt-5" style={{ height: "100px" }}>
                                        {loading ? (<>
                                        <Spinner animation="border" variant="secondary" />
                                        </>) : (<>
                                            <div className="no-data" style={{ color: "#9ea4ab" }}>
                                                <i className="icon ion-md-document" style={{ color: "#9ea4ab" }}></i>
                                                No data
                                            </div>
                                        </>)}
                                    </div>
                                </>)}

                            </Accordion>
                        </div>


                        {userOffer?.length > 0 &&
                            <div className="col-12 mb-5 d-flex justify-content-between mt-3">

                                {page == 1 ? (<>
                                    <button
                                        disabled={disabled || loading ? true : false}
                                        type="button"
                                        onClick={LoadMore}
                                        className="load_more_btn theme-btn-2 theme-btn mt-3 float-left"
                                        id="loadOffer">
                                        {loading ? "Loading..." : "Next"}

                                    </button>
                                </>) : (<>
                                    <button
                                        disabled={loading ? true : false}
                                        type="button"
                                        onClick={perfectPage}
                                        className="load_more_btn theme-btn-2 theme-btn mt-3 float-left"
                                        id="loadOffer">
                                        Previous
                                    </button>
                                    {userOffer?.length > 9 &&
                                        < button
                                            disabled={disabled || loading ? true : false}
                                            type="button"
                                            onClick={LoadMore}
                                            className="load_more_btn theme-btn-2 theme-btn mt-3 float-left"
                                            id="loadOffer">
                                            Next
                                        </button>
                                    }
                                </>)}

                            </div>
                        }
                    </Row>
                </Container>
            </section >
        </>
    )
}

export default Alloffertable