import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Nav,
    Container,
    Modal,
    Button
} from 'react-bootstrap'
import Select from 'react-select';
import BASE_URL from "../../_constant/index";
import FeedBack from './FeedBack';
import Spinner from 'react-bootstrap/Spinner';
import { paymentDetailsByUser, updatePaymentDetails, deletePayment_Method, add_Payment_Details } from '../../redux/apiActions/p2p.action'
import { toast } from "../../components/Toast/Toast";
import { useHistory, Link } from 'react-router-dom';

const AddPayMethod = ({ paymentMethods, handleFeedBackTabChange, feedbackTab, userFeedBack, setFeedBack, feedBack, userData, activeMyFeedBackTab, handleMyTabChange, loading }) => {

    const history = useHistory();

    const [feedBackAvg, setFeedBackAvg] = useState({
        total: "",
        positivePercentage: "",
        negativePercentage: "",
    })
    const [buttonDisable, setButtonDisable] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState();
    const [path, setPath] = useState("");

    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [addBank, setAddBank] = useState(false);
    const [editBank, setEditBank] = useState([]);
    const [upiId, setUpiId] = useState("")
    const [selectedMethod, setSelectedMethod] = useState();
    const [addSelectedMethod, setAddSelectedMethod] = useState();
    const [deleteId, setDeleteID] = useState("")
    const [loadingg, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [BankData, setBankData] = useState({
        name: "",
        bankName: "",
        accountNo: "",
        ifscCode: "",
        // upiID: ""
    })


    useEffect(() => {
        if (userData) {
            let feedBackTotal = userData?.user_data?.positive_feedback_tot + userData?.user_data?.negative_feedback_tot
            setFeedBackAvg(
                {
                    total: feedBackTotal,
                    positivePercentage: (userData?.user_data?.positive_feedback_tot / feedBackTotal) * 100,
                    negativePercentage: (userData?.user_data?.negative_feedback_tot / feedBackTotal) * 100
                }
            )

        }
    }, userData)

    const handleClose = () => setShow(false);
    const paymentDetails = async () => {
        setPaymentMethod(null)
        try {
            const response = await paymentDetailsByUser();
            if (response?.status == 200) {
                setPaymentMethod(response?.data)
                setPath(response?.path);
            }
        } catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        paymentDetails();
    }, [])

    const update_Payment_Details = async () => {
        var reg = /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/;
        try {
            const validationErrors = {};
            if (!selectedMethod) {
                validationErrors.paymentMethod = 'Please select a payment method.';
            }


            if (!BankData.name) {
                validationErrors.fullName = 'Please enter your full name.';
            }

            if (selectedMethod?.label === 'UPI') {
                // Only validate UPI-related fields if the selected method is UPI
                if (!upiId) {
                    validationErrors.upiId = 'Please enter your UPI id.';
                } else if (!/^[\w.-]+@[\w.-]+$/.test(upiId)) {
                    // Validate UPI ID using a regex pattern
                    validationErrors.upiId = 'Invalid UPI ID format.';
                }
            } else {
                // Validate other bank-related fields if the selected method is not UPI
                if (!BankData.bankName) {
                    validationErrors.bankName = 'Please enter your bank name.';
                }
                if (!BankData.accountNo) {
                    validationErrors.accountNo = 'Please enter your account no';
                }
                if (!BankData.ifscCode) {
                    validationErrors.ifscCode = 'Please enter IFSC code.';
                } else if (!BankData.ifscCode.match(reg)) {
                    // Validate IFSC code using a regex pattern
                    validationErrors.ifscCode = 'Invalid IFSC Code format.';
                }
            }

            if (Object.keys(validationErrors).length > 0) {
                // If there are validation errors, update the errors state and return
                setErrors(validationErrors);
                return;
            }

            setLoading(true);
            const data = new FormData();
            data.append("payment_id", selectedMethod?.id || "");
            data.append("payment_type", selectedMethod?.type || "");
            data.append("bank_name", BankData?.bankName || "");
            data.append("bank_account_no", BankData?.accountNo || "");
            data.append("bank_ifsc_code", BankData?.ifscCode || "");
            data.append("full_name", BankData?.name);
            data.append("upi_id", upiId || "");

            const response = await updatePaymentDetails(data);

            if (response.status === 200) {
                toast?.successes(response?.message || response?.data?.message);
                paymentDetails();
                setLoading(false);
                setShow(false);
                paymentDetails();
                setUpiId("");
                setSelectedMethod(null);
                setBankData({
                    name: "",
                    bankName: "",
                    accountNo: "",
                    ifscCode: "",
                });
                setErrors({})
            } else {
                toast?.errors(response?.message || response?.data?.message);
                paymentDetails();
                setUpiId("");
                setSelectedMethod(null);
                setBankData({
                    name: "",
                    bankName: "",
                    accountNo: "",
                    ifscCode: "",
                });
                setErrors({})
                setLoading(false);
                setShow(false);
            }

            // Reset errors state after successful submission
            setErrors({});

            handleClose();
        } catch (error) {
            console.log(error)

        }
    }

    const addPaymentMethod = async () => {
        var reg = /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/;
        try {
            const validationErrors = {};
            if (!addSelectedMethod) {
                validationErrors.paymentMethod = 'Please select a payment method.';
            }

            if (!BankData.name) {
                validationErrors.fullName = 'Please enter your full name.';
            }
            if (addSelectedMethod?.label === 'UPI') {
                // Only validate UPI-related fields if the selected method is UPI
                if (!upiId) {
                    validationErrors.upiId = 'Please enter your UPI id.';
                } else if (!/^[\w.-]+@[\w.-]+$/.test(upiId)) {
                    // Validate UPI ID using a regex pattern
                    validationErrors.upiId = 'Invalid UPI ID format.';
                }
            } else {
                // Validate other bank-related fields if the selected method is not UPI
                if (!BankData.bankName) {
                    validationErrors.bankName = 'Please enter your bank name.';
                }
                if (!BankData.accountNo) {
                    validationErrors.accountNo = 'Please enter your account no';
                }
                if (!BankData.ifscCode) {
                    validationErrors.ifscCode = 'Please enter IFSC code.';
                } else if (!BankData.ifscCode.match(reg)) {
                    // Validate IFSC code using a regex pattern
                    validationErrors.ifscCode = 'Invalid IFSC Code format.';
                }
            }

            if (Object.keys(validationErrors).length > 0) {
                // If there are validation errors, update the errors state and return
                setErrors(validationErrors);
                return;
            }


            setLoading(true)
            const data = new FormData();
            data.append("payment_type", addSelectedMethod?.type || "")
            data.append("bank_name", BankData?.bankName || "")
            data.append("bank_account_no", BankData?.accountNo || "")
            data.append("bank_ifsc_code", BankData?.ifscCode || "")
            data.append("full_name", BankData?.name)
            data.append("upi_id", upiId || "")
            const response = await add_Payment_Details(data);

            if (response.status == 200) {
                toast?.successes(response?.message || response?.data?.message);
                // history.push(`${BASE_URL.BASE_URL}p2p/Orders?to=p2p_create_offer&bank=bankdata`);
                paymentDetails();
                setUpiId("");
                setAddSelectedMethod(null);
                setBankData({
                    name: "",
                    bankName: "",
                    accountNo: "",
                    ifscCode: "",
                });
                setErrors({})
                setLoading(false)
                setAddBank(false)
            } else {
                toast?.errors(response?.message || response?.data?.message)
                setUpiId("");
                setAddSelectedMethod(null);
                setBankData({
                    name: "",
                    bankName: "",
                    accountNo: "",
                    ifscCode: "",
                });
                setErrors({})
                setLoading(false)
                setAddBank(false)
            }
            handleClose()
        } catch (error) {
            console.log(error)

        }
    }
    const handleShow = (data) => {
        setShow(true)
        setEditBank(data)
        const defaultOption = paymentMethods.find((method) => method.label == data?.payment_method);
        setSelectedMethod(defaultOption);
        setBankData({
            name: data?.full_name,
            bankName: data?.bank_name,
            accountNo: data?.bank_account_no,
            ifscCode: data?.bank_ifsc_code,
        })
        setUpiId(data?.upi_id)
    }

    const handleCloseDelete = () => setShowDelete(false);

    const handleShowDelete = (id) => {
        setShowDelete(true);
        setDeleteID(id);
    };





    const deletePaymentMethod = async () => {
        try {
            setLoading(true)
            const data = new FormData()
            data.append("payment_detail_id", deleteId)
            const response = await deletePayment_Method(data)
            if (response.status == 200) {
                toast?.successes(response?.message || response?.data?.message)
                paymentDetails();
                setShowDelete(false);
                setLoading(false);
            } else {
                toast?.errors(response?.message || response?.data?.message)

                setShowDelete(false)
                setLoading(false)
            }

        } catch (error) {
            console.log(error)
        }

    }

    const loadMoreButton = () => {
        if (userFeedBack?.length < 10) {
            setButtonDisable(true)

        } else if (userFeedBack?.length >= 10) {
            setFeedBack(feedBack + 1)
        }

    }


    const loadPreButton = () => {
        if (feedBack > 1) {
            setFeedBack(feedBack - 1)
        }
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setBankData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const setCoinValue = async (val) => {
        setSelectedMethod(val)
    };
    const setAddCoinValue = async (val) => {
        setAddSelectedMethod(val)
    };

    return (
        <div>

            <Modal show={show} centered onHide={handleClose} animation={false} className="p2pOfferViewModal">
                <Modal.Header closeButton>
                    <Modal.Title><h5>Edit payment Method</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form>
                        <div className="form-group text-left">
                            <label>Select Payment Method</label>
                            <Select
                                placeholder='Select Payment Method'
                                className="wallet_network_select custom_Curency-box"
                                classNamePrefix="custom_select"
                                isSearchable={true}
                                name='Assets'
                                options={paymentMethods}
                                value={selectedMethod}
                                onChange={(e) => {
                                    setCoinValue(e);
                                }}

                                getOptionValue={(option) => option?.value}
                                getOptionLabel={(option) => (
                                    <div className='d-flex align-items-center'>
                                        <img src={`${path}${option?.image}`} alt={option?.label} width='25' className='mr-2' />
                                        {option.label}
                                    </div>
                                )}

                            />
                            <p className="text-danger" id="p2p_location_error">{errors?.paymentMethod}</p>

                        </div>

                        <div className="form-group text-left">
                            <label>Full Name</label>
                            <input
                                type="text"
                                value={BankData.name}
                                name="name"
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Full Name"
                                autoComplete="off"
                                maxLength="30" />
                            <p className="text-danger" id="p2p_location_error">{errors?.fullName}</p>
                        </div>
                        {editBank?.payment_method == "UPI" || selectedMethod?.label == "UPI" ? (<>
                            <div className="form-group text-left">
                                <label>UPI ID</label>
                                <input
                                    type="text"
                                    value={upiId}
                                    name="upiID"
                                    onChange={(e) => {

                                        setUpiId(e.target.value)

                                    }}
                                    className="form-control" id="edit_upi_id"
                                    placeholder="UPI ID"
                                    autoComplete="off"
                                    maxLength="45" />
                                <p className="text-danger" id="p2p_location_error">{errors?.upiId}</p>
                            </div>
                        </>) : (<>
                            <div className="form-group text-left">
                                <label>Bank Name</label>
                                <input type="text"
                                    name="bankName"
                                    onChange={handleChange}
                                    value={BankData.bankName}
                                    className="form-control"
                                    placeholder="Bank Name"
                                    autoComplete="off"
                                    maxLength="30"
                                />
                                <p className="text-danger" id="p2p_location_error">{errors?.bankName}</p>
                            </div>

                            <div className="form-group text-left">
                                <label>Bank Account Number</label>
                                <input type="text"
                                    name="accountNo"
                                    onChange={handleChange}
                                    value={BankData.accountNo}
                                    className="form-control"

                                    id="edit_upi_id"
                                    placeholder="Bank Account Number"
                                    autoComplete="off"
                                    maxLength="45" />
                                <p className="text-danger" id="p2p_location_error">{errors?.accountNo}</p>
                            </div>
                            <div className="form-group text-left">
                                <label>IFSC Code</label>
                                <input
                                    type="text"
                                    name="ifscCode"
                                    onChange={handleChange}
                                    value={BankData.ifscCode}
                                    className="form-control"
                                    id="edit_upi_id"
                                    placeholder="IFSC Code"
                                    autoComplete="off"
                                    maxLength="45"
                                />
                                <p className="text-danger" id="p2p_location_error">{errors?.ifscCode}</p>
                            </div>

                        </>)}
                    </form>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" disabled={loadingg ? true : false} onClick={update_Payment_Details} className="p2p_dlt_confirm_btn theme-btn-2 theme-btn">
                        {loadingg ? "Loading.." : "Update"}
                    </Button>
                    <Button variant="secondary" onClick={handleClose} className="btn disable_btn_bg harvest_btn_pools theme-btn-2 theme-btn">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showDelete} centered onHide={handleCloseDelete} animation={false} className="p2pOfferViewModal">

                <Modal.Body className="text-center pt-4">
                    <img src={`${BASE_URL.BASE_URL}img/images/sure-icon.png`} className="dark-theme-img" width="120"></img>
                    <img src={`${BASE_URL.BASE_URL}img/images/sure-icon-light.png`} className="light-theme-img" width="120"></img>
                    <h4 className="my-3">Are you sure?</h4>
                    <h6 className="gray_colour">You wan't able to recover this.</h6>
                </Modal.Body>
                <Modal.Footer className="pb-4">
                    <Button variant="primary" disabled={loadingg ? true : false} onClick={deletePaymentMethod} className="p2p_dlt_confirm_btn theme-btn-2 theme-btn">
                        {loadingg ? "Loading.." : "Confirm"}
                    </Button>
                    <Button variant="secondary" onClick={handleCloseDelete} className="btn disable_btn_bg harvest_btn_pools theme-btn-2 theme-btn">
                        Cancel
                    </Button>
                </Modal.Footer>

            </Modal>

            <Modal show={addBank} centered onHide={() => { setAddBank(false) }} animation={false} className="p2pOfferViewModal">
                <Modal.Header closeButton>
                    <Modal.Title><h5>Add payment Method</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group text-left">
                        <label>Select Payment Method</label>
                        <Select
                            placeholder='Select Payment Method'
                            className="wallet_network_select custom_Curency-box"
                            classNamePrefix="custom_select"
                            isSearchable={true}
                            name='Assets'
                            options={paymentMethods}
                            value={addSelectedMethod}
                            onChange={(e) => {
                                setAddCoinValue(e);
                            }}

                            getOptionValue={(option) => option?.value}
                            getOptionLabel={(option) => (
                                <div className='d-flex align-items-center'>
                                    <img src={`${path}${option?.image}`} alt={option?.label} width='25' className='mr-2' />
                                    {option.label}
                                </div>
                            )}

                        />
                        <p className="text-danger" id="p2p_location_error">{errors?.paymentMethod}</p>
                        {addSelectedMethod &&
                            <>
                                <div className="border my-4"></div>
                                <div className="form-group text-left">
                                    <label>Full Name</label>
                                    <input
                                        type="text"
                                        value={BankData.name}
                                        name="name"
                                        onChange={handleChange}
                                        className="form-control"
                                        placeholder="Full Name"
                                        autoComplete="off"
                                        maxLength="30" />
                                    <p className="text-danger" id="p2p_location_error">{errors?.fullName}</p>
                                </div>
                                {addSelectedMethod?.label == "UPI" ? (<>
                                    <div className="form-group text-left">
                                        <label>UPI ID</label>
                                        <input
                                            type="text"
                                            value={upiId}
                                            name="upiID"
                                            onChange={(e) => { setUpiId(e.target.value) }}
                                            className="form-control"
                                            id="edit_upi_id" placeholder="UPI ID" autoComplete="off" maxLength="45" />
                                        <p className="text-danger" id="p2p_location_error">{errors?.upiId}</p>
                                    </div>
                                </>) : (<>
                                    <div className="form-group text-left">
                                        <label>Bank Name</label>
                                        <input type="text"
                                            name="bankName"
                                            onChange={handleChange}
                                            value={BankData.bankName}
                                            className="form-control" placeholder="Bank Name" autoComplete="off" maxLength="30" />
                                        <p className="text-danger" id="p2p_location_error">{errors?.bankName}</p>
                                    </div>

                                    <div className="form-group text-left">
                                        <label>Bank Account Number</label>
                                        <input type="text"
                                            name="accountNo"
                                            onChange={handleChange}
                                            value={BankData.accountNo}
                                            className="form-control"

                                            id="edit_upi_id"
                                            placeholder="Bank Account Number"
                                            autoComplete="off"
                                            maxLength="45" />
                                        <p className="text-danger" id="p2p_location_error">{errors?.accountNo}</p>
                                    </div>
                                    <div className="form-group text-left">
                                        <label>IFSC Code</label>
                                        <input
                                            type="text"
                                            name="ifscCode"
                                            onChange={handleChange}
                                            value={BankData.ifscCode}
                                            className="form-control"
                                            id="edit_upi_id"
                                            placeholder="IFSC Code"
                                            autoComplete="off"
                                            maxLength="45"
                                        />
                                        <p className="text-danger" id="p2p_location_error">{errors?.ifscCode}</p>
                                    </div>

                                </>)}
                            </>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" disabled={loadingg ? true : false} onClick={addPaymentMethod} className="p2p_dlt_confirm_btn theme-btn-2 theme-btn">
                        {loadingg ? "Please wait" : "Add"}
                    </Button>

                    <Button variant="secondary" onClick={() => { setAddBank(false) }} className="btn disable_btn_bg harvest_btn_pools theme-btn-2 theme-btn">
                        Cancel
                    </Button>

                </Modal.Footer>
            </Modal>


            <section className="p2p_main_banner_section dark-gray-bg-v py-5">
                <div className="container">
                    <div className="d-flex my-4">
                        <img className="mr-3" src={`${BASE_URL.BASE_URL}img/images/user.png`} height="60px"></img>
                        <h5>{userData?.user_data?.nick_name || userData?.user_data?.full_name}<span title="edit nickname" className="edit_nick_btn" data-id="10" data-nickname="Asdas"><img src={`${BASE_URL.BASE_URL}img/images/p2p_edit.svg`} className="ml-2" width="18"></img></span>
                            <br />
                            <span className="gray_colour align-items-center d-flex text-green"><img className="mr-1" src={`${BASE_URL.BASE_URL}img/images/verify.svg`}></img>Verified User </span>
                        </h5>
                    </div>
                    <Row className="p2p_user_merchant">
                        <div className="col-12">
                            <ul className="d-flex flex-wrap p2p_user_merchant_cards">
                                <li>
                                    <p>All Trades</p>
                                    <h6 className="mt-1">{userData?.user_data?.Trades_30} Time(s)</h6>
                                </li>
                                <li>
                                    <p>Completion Rate</p>
                                    <h6 className="mt-1">{userData?.user_data?.completion_rate}%</h6>
                                </li>
                                <li>
                                    <p>Avg. Release Time</p>
                                    <h6 className="mt-1">{userData?.user_data?.coin_release_time} Second(s) </h6>
                                </li>
                                <li>
                                    <p>Avg. Pay Time</p>
                                    <h6 className="mt-1">{userData?.user_data?.payment_release_time} Minute(s)</h6>
                                </li>
                                <li>
                                    <p>Registered</p>
                                    <h6 className="mt-1">{userData?.user_data?.register} Day(s)</h6>
                                </li>
                                <li>
                                    <p>First Trade</p>
                                    <h6 className="mt-1">{userData?.user_data?.first_trades} Day(s)</h6>
                                </li>
                                <li>
                                    <p>Completed Trades</p>
                                    <h6 className="mt-1">{userData?.user_data?.tot_trade} Time(s)</h6>
                                    <i className="gray_colour"><span className="text-green">Buy {userData?.user_data?.buy_tot}</span>
                                        | <span className="text-red">Sell
                                            {userData?.user_data?.sell_tot}</span></i>
                                </li>
                            </ul>

                        </div>
                    </Row>
                </div>
            </section>

            <section className="p2p_main_banner_section py-5 p2p_mainTable">
                <Container>
                    <Row>
                        <div className="accordion p2p_user_accordion" id="accordionExample">
                            <h3 className="online_ads_heading mb-3">Payment Method</h3>
                            <div className="card_p2p_online_ads">
                                <div className="p2p_user_table_heading w-100">
                                    <Row id="payment_detail_list">
                                        {paymentMethod?.length > 0 &&
                                            paymentMethod?.map((item, index) => {

                                                return (
                                                    <div key={index} className="col-lg-3 col-sm-6 col-12 mt-4" id="payment_38">

                                                        <div className="user_payment_method_box">
                                                            <div className="user_payment_method_box_upi d-flex justify-content-between mb-3 align-items-center">
                                                                <span className="d-flex align-items-center">
                                                                    <div className="user_payment_method_box_img_circle">
                                                                        <img src={`${path}${item?.payment_image}`} alt="paytm"></img>
                                                                    </div>
                                                                    <h6 className="ml-2">{item?.payment_method}</h6>
                                                                </span>
                                                                <div className="user_payment_method_box_upi_edit d-flex">
                                                                    <a onClick={() => handleShow(item)} className="edit_payment" data-id="38">
                                                                        <img src={`${BASE_URL.BASE_URL}img/images/p2p_p_edit.svg`} alt="edit"></img>
                                                                    </a>
                                                                    <a onClick={() => handleShowDelete(item?.id)} className="delete_payment" data-id="38">
                                                                        <img src={`${BASE_URL.BASE_URL}img/images/p2p_p_delete.svg`} alt="delete"></img>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <p className="gray_colour">{item?.full_name}</p>
                                                            <h5>{item?.bank_name || item?.bank_account_no || item?.upi_id}</h5>
                                                        </div>
                                                    </div>
                                                )
                                            })

                                        }


                                    </Row>
                                    <div className="mt-4">
                                        <button className="theme-btn-2 theme-btn text-center px-4"
                                            onClick={() => {
                                                setAddBank(true)
                                                setSelectedMethod("");
                                                setBankData({
                                                    name: "",
                                                    bankName: "",
                                                    accountNo: "",
                                                    ifscCode: "",
                                                });
                                                setErrors({})
                                            }}
                                            id="addPaymentModalBtn" type="button">Add Payment Method</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </section>

            <section className="dark-gray-bg-v py-2"></section>

            <section className="p2p_main_banner_section py-5 p2p_mainTable">
                <Container>
                    <Row>
                        <div className="accordion p2p_user_accordion" id="accordionExample">
                            <div className="d-flex flex-wrap justify-content-between align-items-center mb-3 gap-v-5">
                                <h3 className="online_ads_heading mr-3">Feedback</h3>
                                <Nav variant="tabs" className="profile_feedbacks_right">
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="my_FeedBack"
                                            className={activeMyFeedBackTab === 'my_FeedBack' ? 'active' : ''}
                                            onClick={() => handleMyTabChange('my_FeedBack')}
                                        >
                                            My Feedbacks
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="other_feedback"
                                            className={activeMyFeedBackTab === 'other_feedback' ? 'active' : ''}
                                            onClick={() => handleMyTabChange('other_feedback')}
                                        >
                                            CounterParty's Feedback
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>

                            </div>

                            <div className="card_p2p_online_ads">
                                <div className="p2p_user_table_heading w-100">
                                    <div className="row w-100">
                                        <div className="tab-content w-100">
                                            <div className={`tab-pane ${activeMyFeedBackTab === 'my_FeedBack' ? 'active' : ''}`} id="my_FeedBack">
                                                <div className="col-12">
                                                    <div className="reviews_block">
                                                        <ul className="nav nav-tabs mb-4">
                                                            <li className="nav-item">
                                                                <a
                                                                    className={`nav-link ${feedbackTab === 'all_feedback' ? 'active' : ''} typeTab`}
                                                                    onClick={() => handleFeedBackTabChange('all_feedback')}
                                                                >All</a>

                                                            </li>
                                                            <li className="nav-item">
                                                                <a
                                                                    className={`nav-link ${feedbackTab === 'positive_feedback' ? 'active' : ''} typeTab`}
                                                                    onClick={() => handleFeedBackTabChange('positive_feedback')}
                                                                >Positive</a>

                                                            </li>
                                                            <li className="nav-item">
                                                                <a
                                                                    className={`nav-link ${feedbackTab === 'negative_feedback' ? 'active' : ''} typeTab`}
                                                                    onClick={() => handleFeedBackTabChange('negative_feedback')}
                                                                >Negative</a>

                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="mt-3 user_payment_method_box_bg_none">

                                                        {loading ? (
                                                            <div className='d-flex justify-content-center'>
                                                                <Spinner animation="border" variant="secondary" />
                                                            </div>
                                                        ) :
                                                            <>

                                                                <div className="tab-content">
                                                                    <div className={`tab-pane ${feedbackTab === 'all_feedback' ? 'active' : ''}`} id="all_feedback">
                                                                        {activeMyFeedBackTab === 'my_FeedBack' &&
                                                                            <div className=" p2p_user_payment_feedback d-sm-flex d-flow-root mb-3">
                                                                                <div className="reviews">
                                                                                    <h3>
                                                                                        {(parseFloat(feedBackAvg?.positivePercentage) > 0) ?
                                                                                            parseFloat(parseFloat(feedBackAvg?.positivePercentage)).toFixed() : "0"
                                                                                        }%
                                                                                    </h3>
                                                                                    <p className="gray_colour">{feedBackAvg?.total} Reviews
                                                                                    </p>
                                                                                </div>
                                                                                <div className="progress_bar">
                                                                                    <p>Positive</p>
                                                                                    <div className="progress progressbar_height">
                                                                                        <div className="progress-bar progress-bar-green"
                                                                                            style={{ width: `${feedBackAvg?.positivePercentage}%` }}
                                                                                        >

                                                                                        </div>
                                                                                    </div>
                                                                                    <p className="mt-2">Negative</p>

                                                                                    <div className="progress progressbar_height">
                                                                                        <div
                                                                                            style={{ width: `${feedBackAvg?.negativePercentage}%` }} className="progress-bar progress-bar-red"></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        <FeedBack userFeedBack={userFeedBack} feedbackType={0} />
                                                                    </div>
                                                                    <div className={`tab-pane ${feedbackTab === 'positive_feedback' ? 'active' : ''}`} id="positive_feedback">
                                                                        <FeedBack userFeedBack={userFeedBack} feedbackType={1} />
                                                                    </div>
                                                                    <div className={`tab-pane ${feedbackTab === 'negative_feedback' ? 'active' : ''}`} id="negative_feedback">
                                                                        <FeedBack userFeedBack={userFeedBack} feedbackType={2} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }


                                                    </div>
                                                    {/* <div className="float-right mt-3"> */}
                                                    {/* loadPreButton */}
                                                    {(userFeedBack !== null && userFeedBack?.length > 0) &&
                                                        <div className="d-flex justify-content-between mt-3">
                                                            {feedBack > 1 &&
                                                                < button
                                                                    type="button"
                                                                    onClick={loadPreButton}
                                                                    disabled={loading ? true : false}
                                                                    className="load_more_btn theme-btn-2 theme-btn mt-3 mx-1 float-right" id="loadFeed">
                                                                    Previous
                                                                </button>
                                                            }
                                                            {userFeedBack?.length > 9 &&
                                                                <button
                                                                    type="button"
                                                                    onClick={loadMoreButton}
                                                                    disabled={buttonDisable ? true : false}
                                                                    className="load_more_btn theme-btn-2 theme-btn mt-3 mx-1 float-right" id="loadFeed">
                                                                    Next
                                                                </button>
                                                            }
                                                        </div>
                                                    }
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                            <div className={`tab-pane ${activeMyFeedBackTab === 'other_feedback' ? 'active' : ''}`} id="other_feedback">
                                                <div className="col-12">
                                                    <div className="reviews_block">
                                                        <ul className="nav nav-tabs mb-4">
                                                            <li className="nav-item">
                                                                <a
                                                                    className={`nav-link ${feedbackTab === 'all_feedback' ? 'active' : ''} typeTab`}
                                                                    onClick={() => handleFeedBackTabChange('all_feedback')}
                                                                >All</a>

                                                            </li>
                                                            <li className="nav-item">
                                                                <a
                                                                    className={`nav-link ${feedbackTab === 'positive_feedback' ? 'active' : ''} typeTab`}
                                                                    onClick={() => handleFeedBackTabChange('positive_feedback')}
                                                                >Positive</a>

                                                            </li>
                                                            <li className="nav-item">
                                                                <a
                                                                    className={`nav-link ${feedbackTab === 'negative_feedback' ? 'active' : ''} typeTab`}
                                                                    onClick={() => handleFeedBackTabChange('negative_feedback')}
                                                                >Negative</a>

                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="mt-5 user_payment_method_box_bg_none">

                                                        {loading ? (
                                                            <div className='d-flex d-flex justify-content-center'>
                                                                <Spinner animation="border" variant="secondary" />
                                                            </div>
                                                        ) : (<>
                                                            <div className="tab-content">

                                                                <div className={`tab-pane ${feedbackTab === 'all_feedback' ? 'active' : ''}`} id="all_feedback">
                                                                    <FeedBack userFeedBack={userFeedBack} feedbackType={0} />
                                                                </div>
                                                                <div className={`tab-pane ${feedbackTab === 'positive_feedback' ? 'active' : ''}`} id="positive_feedback">
                                                                    <FeedBack userFeedBack={userFeedBack} feedbackType={1} />
                                                                </div>
                                                                <div className={`tab-pane ${feedbackTab === 'negative_feedback' ? 'active' : ''}`} id="negative_feedback">
                                                                    <FeedBack userFeedBack={userFeedBack} feedbackType={2} />
                                                                </div>
                                                            </div>


                                                        </>)}

                                                    </div>
                                                    {userFeedBack !== null || userFeedBack?.length > 0 &&
                                                        <div className="float-right mt-3">
                                                            <button type="button"
                                                                onClick={loadMoreButton}
                                                                disabled={buttonDisable ? true : false}
                                                                className="load_more_btn theme-btn-2 theme-btn mt-3 mx-1 float-right" id="loadFeed">Load More</button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </section>



        </div>
    )
}

export default AddPayMethod