import React, { useState, useEffect, useRef } from "react";
import BASE_URL from "../_constant/index";
import ShortFooter from "./ShortFooter";
import {
  userCoinListAction,
  apyTransaction,
  User_APY_Balance,
  calculate_APY,
} from "../redux/apiActions/user.action";
import { Button, Overlay, Popover } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, generatePath } from "react-router-dom";
import utill from "../_constant/utill";
import moment from "moment";

import CustomDateRangePicker from "../components/CustomDateRangePicker";

const Saving = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const [userCoinList, setUserCoinList] = useState([]);
  const [apy, setApy] = useState("");
  const [amount, setAmount] = useState("");
  const [apyCalculate, setApyCalculate] = useState();
  const [apyCalculateErrro, setApyCalculateError] = useState("");
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [transactionsHistory, setTransactionsHistory] = useState();
  const [radioValue, setRadioValue] = useState("");
  const [TimePeriod, setTimePeriod] = useState("");
  const [dateRange, setDateRange] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };
  const startDate = dateRange ? dateRange[0] : "";

  const updateDateRange = (dateRange) => {
    setDateRange(dateRange);
  };

  const createSum = userCoinList?.reduce((total, item) => {
    return total + parseFloat(item?.balance_currency);
  }, 0);

  const handleRadioChange = (event) => {
    setRadioValue(event.target.id);
  };

  const handleTimeRadioChange = (event) => {
    setTimePeriod(event.target.id);
  };

  const calculateApy = async (value) => {
    setApyCalculateError("");
    if (value) {
      let formData = new FormData();
      formData.append("amount", value || amount);
      let calculate = await calculate_APY(formData);
      if (calculate.status == 200) {
        setApyCalculate(calculate?.data);
        if (calculate?.error != "") {
          setApyCalculateError(calculate?.error);
        }
      } else if (calculate.status == 400) {
        setApyCalculateError(calculate?.message);
      } else {
        setApyCalculateError(calculate?.error);
      }
    } else {
      setApyCalculate();
    }
  };

  const transactionHistory = async () => {
    let filterDate =
      dateRange != null
        ? {
            startDate: moment(dateRange[0]).format("YYYY-M-D"),
            endDate: moment(dateRange[1]).format("YYYY-M-D"),
          }
        : null;

    try {
      const formData = new FormData();
      formData.append("page", currentPage);
      formData.append(
        "status",
        radioValue === "pending" ? 1 : radioValue === "released" ? 0 : ""
      );
      formData.append(
        "type",
        TimePeriod === "Daily" ? 1 : TimePeriod === "Monthly" ? 2 : ""
      );
      formData.append(
        "start_date",
        filterDate?.startDate ? filterDate?.startDate : ""
      );
      formData.append(
        "end_date",
        filterDate?.endDate ? filterDate?.endDate : ""
      );

      const response = await apyTransaction(formData);
      if (response.status === 200) {
        setTransactionsHistory(response.data);
      }
    } catch (error) {
      // Handle error (e.g., show a message to the user)
      console.error("Error fetching transaction history:", error);
    }
  };

  useEffect(() => {
    transactionHistory();
  }, [currentPage]);

  useEffect(async () => {
    let response = await User_APY_Balance();
    setApy(response);
  }, []);

  const applyFilter = () => {
    if (!radioValue == "" || !TimePeriod == "" || !dateRange == "") {
      transactionHistory();
    }
    setShow(false);
  };

  const resetFilter = async () => {
    setRadioValue("");
    setTimePeriod("");
    setDateRange(null);
    if (!radioValue == "" || !TimePeriod == "") {
      try {
        const formData = new FormData();
        formData.append("page", 1);
        formData.append("status", "");
        formData.append("type", "");
        formData.append("startdate", "");
        formData.append("enddate", "");

        const response = await apyTransaction(formData);
        if (response.status === 200) {
          setTransactionsHistory(response.data);
        }
      } catch (error) {
        // Handle error (e.g., show a message to the user)
        console.error("Error fetching transaction history:", error);
      }
    }
    setShow(false);
  };

  useEffect(() => {
    if (userData?.access_token) {
      const walletCoinList = async () => {
        const coinListData = await userCoinListAction(dispatch);
        if (coinListData?.status === 200) {
          setUserCoinList(coinListData?.data);
        }
      };
      walletCoinList();
    } else {
      history.push(generatePath(BASE_URL.BASE_URL + "login"));
    }
  }, [userData, history, setUserCoinList, dispatch]);

  function isNumberKey(value) {
    var validNumber = new RegExp(/^\d*\.?\d*$/);
    if (validNumber.test(value)) {
      return value;
    } else {
      return false;
    }
  }

  const handleAmount = (e) => {
    let value = isNumberKey(e.target.value);
    setAmount(value);

    // Clear the previous debounce timer
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    // Set a new debounce timer
    const newDebounceTimer = setTimeout(() => {
      calculateApy(value);
    }, 500);
    setDebounceTimer(newDebounceTimer);
  };

  return (
    <div id="content">
      <section className="markets_overview_section_v dark-gray-bg-v">
        <div className="container">
          <div className="row">
            <div className="col">
              <h3 className="d-flex flex-wrap align-items-center">
                Safe <span className="text-orange ml-2" style={{fontSize:"2rem"}}> Pro </span>{" "}
                <p className="fs-4v mb-0 ml-2">
                  {" "}
                  <i> (Crypto Savings Account)</i>
                </p>
              </h3>
              <p className="mt-3 mb-4">
                Earn your APY by just holding the amount in your spot wallet.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="benefits_main_section py-4">
        <div className="container">
          <div className="row row-cols-1 row-cols-lg-2 row-cols-md-1 row-cols-sm-1 gap-v-4 mt-5">
            <div className="col">
              {/* <div className="normal_safepro_user_section">
                <h4 className="mb-4">Benefits in Trading Fee:</h4>

                <p className="mb-0">
                  <span className="text-white mr-2">Normal User :</span>
                  <i className="fs-4v">$0.39 in VYNC or 0.25% on Trade Volume.</i>
                </p>

                <p className="mb-0">
                  <span className="text-white mr-2">SafePro User :</span>
                  <i className="fs-4v">$0.25 in VYNC or 0.15% on Trade Volume.</i>
                </p>
              </div> */}

              <div className="apy_portfolio_details_v mt-5">
                <h4>APY you can get from your portfolio</h4>

                <form className="apy_portfolio_form_v mt-3">
                  <div className="form-group mb-0 d-flex align-items-center">
                    <label for="number">Enter Amount to get an idea</label>
                    <input
                      type="numbre"
                      className="form-control gray_bg_v"
                      value={amount ? amount : ""}
                      onChange={handleAmount}
                      id="amount"
                      placeholder="$0.00"
                      name="amount"
                    />
                  </div>
                  <small
                    className="red mt-1"
                    style={{
                      textAlign: "right",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {apyCalculateErrro}
                  </small>
                </form>

                <ul className="upper_header pt-4 mt-2">
                  <li className="d-flex align-items-center justify-content-between">
                    Daily Interest{" "}
                    <span className="text-white">
                      ${" "}
                      {apyCalculate?.daily_interest
                        ? apyCalculate?.daily_interest
                        : "0.00"}
                    </span>
                  </li>
                  <li className="d-flex align-items-center justify-content-between">
                    Annual Interest{" "}
                    <span className="text-white">
                      ${" "}
                      {apyCalculate?.annual_interest
                        ? apyCalculate?.annual_interest
                        : "0.00"}
                    </span>
                  </li>
                  <li className="d-flex align-items-center justify-content-between">
                    APY <span className="text-white">{apy?.apy}%</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col">
              <h4>Savings</h4>
              <div className="gray_bg_v p-3 d-flex align-items-center company_savings_section_v  mt-4">
                <div className="company_savings_left_img_v">
                  <img
                    src={BASE_URL.BASE_URL + "img/images/savings/apy_taken.svg"}
                    alt="icon"
                  />
                </div>

                <div className="ml-3">
                  <p className="fs-4v mb-0">Portfolio Amount</p>
                  <h3>${utill.beautifyNumber(createSum, 2)}</h3>
                </div>
              </div>

              <div className="gray_bg_v p-3 d-flex align-items-center company_savings_section_v mt-3">
                <div className="company_savings_left_img_v">
                  <img
                    src={
                      BASE_URL.BASE_URL +
                      "img/images/savings/portfolio_amount.svg"
                    }
                    alt="icon"
                  />
                </div>

                <div className="ml-3">
                  <p className="fs-4v mb-0">APY already taken</p>
                  <h3>${apy?.interest_received}</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-cols-1 row-cols-xl-2 row-cols-md-2 row-cols-sm-1 gap-v-4 mt-5">
            <div className="col"></div>
          </div>
        </div>
      </section>
      <section className="transactions_section_v py-5">
        <div className="container">
          <div className="row mb-4">
            <div className="col">
              <div className="d-flex flex-wrap justify-content-between align-items-center gap-v-4">
                <h3>Transactions</h3>
                <div className="filters_section_v">
                  <Button
                    className="d-flex align-items-center filter_button"
                    onClick={handleClick}
                  >
                    <img
                      src={BASE_URL.BASE_URL + "img/images/filter-icon.svg"}
                      alt="icon"
                      className="mr-1 dark-theme-img"
                      width="15"
                    />
                    <img
                      src={
                        BASE_URL.BASE_URL +
                        "img/images/light-theme/filter-icon.svg"
                      }
                      alt="icon"
                      className="mr-1 light-theme-img"
                      width="15"
                    />{" "}
                    Filters
                  </Button>
                  <Overlay
                    show={show}
                    target={target}
                    placement="bottom"
                    container={ref.current}
                    containerPadding={20}
                  >
                    <Popover
                      id="popover-contained popover_contained"
                      className="popover_contained"
                    >
                      <div className="d-flex align-items-start justify-content-between">
                        <h3 className="m-2">Filters</h3>
                        <button
                          id="close"
                          onClick={handleClick}
                          className="filter_button m-2"
                        >
                          <img
                            src={BASE_URL.BASE_URL + "img/images/close-btn.svg"}
                            alt="close"
                            width="15"
                          />
                        </button>
                      </div>
                      <Popover.Content>
                        <div id="TransactionFilters">
                          <div className="row row-cols-1 row-cols-md-2 mt-4">
                            <div className="col">
                              <p>Transaction Type</p>
                              <div
                                className="btn-group markets_watch_table_tabs_content_radiobtn mb-3"
                                role="group"
                                aria-label="Basic radio toggle button group"
                              >
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="btnradio11"
                                  id="pending"
                                  autoComplete="off"
                                  checked={radioValue === "pending"}
                                  onChange={handleRadioChange}
                                />
                                <label
                                  style={{ borderRadius: "100px" }}
                                  className="btn mr-2 filter_check btn-gray-v"
                                  htmlFor="pending"
                                >
                                  Pending
                                </label>

                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="btnradio11"
                                  id="released"
                                  autoComplete="off"
                                  checked={radioValue === "released"}
                                  onChange={handleRadioChange}
                                />
                                <label
                                  className="btn mr-2 filter_check btn-gray-v"
                                  htmlFor="released"
                                >
                                  Released
                                </label>
                              </div>
                            </div>

                            <div className="col">
                              <p>Time Period</p>
                              <div
                                className="btn-group markets_watch_table_tabs_content_radiobtn mb-3"
                                role="group"
                                aria-label="Basic radio toggle button group"
                              >
                                {/*  */}
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="timePeriod"
                                  id="Daily"
                                  checked={TimePeriod === "Daily"}
                                  onChange={handleTimeRadioChange}
                                />
                                <label
                                  className="btn filter_check btn-gray-v"
                                  htmlFor="Daily"
                                >
                                  Daily
                                </label>

                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="timePeriod"
                                  id="Monthly"
                                  checked={TimePeriod === "Monthly"}
                                  onChange={handleTimeRadioChange}
                                />
                                <label
                                  className="btn filter_check btn-gray-v"
                                  htmlFor="Monthly"
                                >
                                  Monthly
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row row-cols-1">
                            <div className="col">
                              <p>Date of Transaction</p>
                            </div>
                          </div>
                          <div className="row row-cols-1 row-cols-md-2 date_transaction_v pl-4">
                            <CustomDateRangePicker
                              dateRange={dateRange}
                              updateDateRange={updateDateRange}
                            />
                          </div>
                          <div className="row row-cols-1 justify-content-center d-flex mt-4">
                            <div className="col justify-content-center d-flex flex-wrap">
                              <button
                                className="btn btn-primary-v px-4 mr-2"
                                onClick={applyFilter}
                              >
                                Apply Filter
                              </button>
                              <button
                                className="btn btn-primary-v btn-gray-v px-4"
                                onClick={resetFilter}
                              >
                                Reset Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      </Popover.Content>
                    </Popover>
                  </Overlay>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-cols-1">
            <div className="col">
              <div className="pricing_table_v transactions_pricing_table_v">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>APY Amount</th>
                      <th>APY</th>
                      <th>Portfolio Value</th>
                      <th className="text-right">Status</th>
                    </tr>
                  </thead>
                  {transactionsHistory?.length > 0 ? (
                    <>
                      <tbody>
                        {transactionsHistory?.map((item) => {
                          return (
                            <tr>
                              <td>
                                {" "}
                                {moment(item?.DATE).format("Do MMM YY h:mm a")}
                              </td>
                              <td>{item?.APY_amount}</td>
                              <td>
                                <span
                                  className="bg-red-v fs-4v px-2 py-1"
                                  style={{ color: "#04b98e" }}
                                >
                                  {item?.APY}
                                </span>
                              </td>
                              <td>{item?.DATE}</td>
                              <td className="text-right">
                                <button className="btn bg-green-v text-white w-75 p-1">
                                  {item?.status}
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  ) : (
                    <tbody style={{ height: "200px" }}>
                      {/* <tr colSpan="8"> */}
                        <td colSpan="8" className="no-data">
                          <i className="icon ion-md-document"></i>
                          Data Not Available
                        </td>
                      {/* </tr> */}
                    </tbody>
                    // <div className="flex flex-wrap align-items-center">
                    //   <h3 style={{ textAlign: "center" }}>No Data</h3>
                    // </div>
                  )}
                </table>
                <NextPreButtons
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  transactionsHistory={transactionsHistory}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ShortFooter />
    </div>
  );
};

function NextPreButtons(props) {
  const { currentPage, setCurrentPage, transactionsHistory } = props;
  return (
    <row className="mt-3 d-flex justify-content-between pl-3 pr-3">
      {/* <Col  md={6}> */}
      {currentPage !== 1 && (
        <button
          className="pagination_button"
          disabled={currentPage == 1}
          onClick={() => {
            setCurrentPage(currentPage - 1);
          }}
        >
          <i className="icon ion-md-arrow-dropleft mr-2"></i>Previous
        </button>
      )}

      {transactionsHistory?.length < 10 ? null : (
        <Button
          className="pagination_button"
          onClick={() => {
            setCurrentPage(currentPage + 1);
          }}
        >
          Next<i className="icon ion-md-arrow-dropright ml-2"></i>
        </Button>
      )}
    </row>
  );
}

export default Saving;
