import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Moment from "react-moment";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import validator from "validator";
import { toast } from "../../components/Toast/Toast";
import { changePassword, gAuthEnableDisable, disableAccount, logout, googleAuthVerifyEnable, login_activity, setUserActivityApi, googleAuthGetQR, verifyGoogleAuth } from '../../redux/apiActions/user.action';
import BASE_URL from "../../_constant/index";
import EmptyData from '../emptyData';
import { CopyToClipboard } from "react-copy-to-clipboard";
import Verification from './Verification';
import Loader from '../../components/loader'


const Security = ({ dashBoardUserData, getDashBoardData }) => {

  const history = useHistory()
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  let return_to_url = queryParameters.get("return_to");
  return_to_url = return_to_url ? atob(return_to_url) : null;
  let userData = useSelector((state) => state.user.userData);

  const gAuth_active = dashBoardUserData?.gAuth_active
  const google_auth_verify = dashBoardUserData?.google_auth_verify
  const login_token = userData?.login_token ? userData?.login_token : dashBoardUserData?.login_token

  const [password, setPassword] = useState(false);
  const [c_passwordShown, setC_passwordShown] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [errorsMsg, setErrorsMsg] = useState(null);
  const [show, setShow] = useState(false);
  const [otp, setOtp] = useState("");
  const [passwordd, setPasswordd] = useState("")
  const [C_passwordd, setC_Passwordd] = useState("")
  const [disable, setDisable] = useState(false);
  const [activity, setActivity] = useState({})
  const [userActivity, setUserActivity] = useState(false)
  const [userActivityData, setUserActivityData] = useState()
  const [showActivity, setShowActivity] = useState(false)
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);
  const [qrOtp, setQrOtp] = useState("");
  const [googleQr, setGoogleQr] = useState(null)
  const [showQr, setShowQr] = useState(false)
  const [otpError, setOtpError] = useState(null)
  const [loading, setLoading] = useState(false)

  const getUserActivity = async () => {
    const response = await (setUserActivityApi())
    setUserActivityData(response?.data?.user_activity?.slice(0, 10));
  }

  const getGoogleAuthQR = async () => {
    if (login_token) {
      var data = new FormData();
      data.append("login_token", login_token);
      data.append("device_type", 0);
      const response = await googleAuthGetQR(data)
      if (response.status == 200) {
        setGoogleQr(response?.data?.data);
        setShowQr(true)
      }
    }
  }


  const onLogoutBtnClick = async () => {
    history.push(BASE_URL.BASE_URL + "login");
    dispatch(logout());
    // window.location.reload(false);
    // return <Redirect to="/login" />
  };

  const disableUserAccount = async () => {
    if (login_token) {
      setLoading(true)
      const response = await disableAccount()
      if (response.status == 200) {
        onLogoutBtnClick();
        toast.success(response.message)
        setLoading(false)
      } else {
        toast.error(response.message);
        setLoading(false);
      }
    }
  };

  const googleAuthEnable = async () => {
    if (login_token) {
      const status = gAuth_active == 1 ? 0 : 1
      var data = new FormData();
      data.append("login_token", login_token);
      data.append("status", status);
      const response = await googleAuthVerifyEnable(data)
      if (response.status == 200) {
        getDashBoardData();
        toast.successes("Verification Enabled", response?.data?.message)
      } else {
        toast.errors("Invalid Details", response?.data?.message)
      }
    }
  }

  const googleAuthDisable = async () => {
    if (login_token) {
      if (!qrOtp || qrOtp == "") {
        setOtpError("Please enter Valid Details")
      } else if (qrOtp.length < 6) {
        setOtpError("Min 6 digit required")
      } else {
        const status = gAuth_active == 1 ? 0 : 1
        var data = new FormData();
        data.append("login_token", login_token);
        data.append("status", status);
        data.append("code", qrOtp);
        const response = await gAuthEnableDisable(data);
        if (response.status == 200) {

          getDashBoardData();
          setShowQr(false);
          toast.successes("Verification Disable", response?.message);
          setQrOtp("");

        } else {
          toast.errors("Invalid Details", response?.message);
          setOtpError(response?.message);
        }
      }
    }
  }

  const googleAuthVerify = async () => {
    if (!qrOtp || qrOtp === "") {
      toast.error("Please enter Valid Details");
      setOtpError("Please enter Valid Details");
    } else if (qrOtp.length < 6) {
      setOtpError("Min 6 digit required")
    } else {
      let formData = new FormData();
      formData.append("login_token", login_token);
      formData.append("code", qrOtp);
      formData.append("device_type", 0);
      formData.append("device_token", "deviceTokenForNotificationLogin");
      const response = await dispatch(verifyGoogleAuth(formData, history, userData, return_to_url,));

      if (response?.status === 200) {
        onLogoutBtnClick();
        toast.success(response?.message);
        setShowQr(false);
        setQrOtp("")
        // getDashBoardData();
      } else {
        setOtpError(response?.message)
        toast.error(response?.message)
        setQrOtp("")
      }
    }
  };

  useEffect(() => {
    getUserActivity();

  }, [])

  const handleUserActivity = () => {
    if (userActivity) {
      setUserActivity(false)
    } else {
      setUserActivity(true)
      getUserActivity();
    }
  }

  const handleLoginActivity = () => {
    if (showActivity) {
      setShowActivity(false)
      setErrorsMsg(null)
    } else {
      setShowActivity(true)
      setErrorsMsg(null)
    }
  }

  const handleClose = () => setDisable(false);
  const handleShow = () => setDisable(true);

  const UserActivity = async () => {
    const response = await (login_activity())
    setActivity(response?.data?.login_activity)
  }
  useEffect(() => {
    UserActivity()
  }, [])

  const changeUserPasswordOTP = async (data, e) => {

    if (!otp || otp === "") {
      toast.error("Please enter Valid Details");
      setErrorsMsg("Please enter Valid Details")
    } else {
      setLoading(true)
      var data = new FormData();
      data.append("email", userData?.email);
      data.append("step", 2);
      data.append("otp", otp);
      const response = await (changePassword(data))
      if (response) {
        setLoading(false)
        if (response.status === 200) {
          setShow(true)
          setOtp("")
          setC_Passwordd("")
          setPasswordd("")
          setErrorsMsg(null)
          toast.success(response?.message);
        }
        else {
          toast.error(response?.message);
          setErrorsMsg(response?.message)
        }
      }

    }
  }

  const UserPassword = async (e) => {
    e.preventDefault();
    if (!passwordd || passwordd === "") {
      setErrorsMsg("Password is required");
      return;
    }
    if (!C_passwordd || C_passwordd === "") {
      setErrorsMsg("Confirm password is required");
      return;
    }
    if (
      passwordd !== "" &&
      !validator.isStrongPassword(passwordd, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setErrorsMsg("Password must contain at least 8 characters including uppercase, lowercase, numbers and special characters")
    } else if (passwordd !== C_passwordd) {
      toast.error("Passwords do not match");
      setErrorsMsg("Passwords do not match")
    } else {
      setLoading(true)
      var data = new FormData();
      data.append("email", userData?.email);
      data.append("step", 3);
      data.append("otp", otp);
      data.append("password", passwordd);

      const response = await (changePassword(data))
      if (response) {
        setLoading(false)
        if (response.status === 200) {
          setPassword(false)
          setShow(false)
          setPasswordd(false)
          setC_Passwordd(false)
          setErrorsMsg(null);
          toast.success(response?.message);
        }
      }
    }
  }

  const changeUserPassword = async () => {
    setErrorsMsg(null)
    var data = new FormData();
    data.append("email", userData?.email);
    data.append("step", 1);
    await (changePassword(data))

    setShow(false)
    setPasswordd(false)
    setC_Passwordd(false)
  }


  const handleClosepassword = () => setPassword(false);
  const handleShowPassword = () => {
    setPassword(true)
    setSeconds(30)
    changeUserPassword()
  }

  const resendOTP = async () => {
    setMinutes(0);
    setSeconds(30);
    setOtp("")
    changeUserPassword();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);

  const togglePassword = (e, type) => {
    if (type === "password") {
      setPasswordShown(passwordShown ? false : true);
    } else if (type === "c_password") {
      setC_passwordShown(c_passwordShown ? false : true);
    }
    e.preventDefault();
  };

  const handleSubmitOtp = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setOtp(value);
    setErrorsMsg("")
  };
  const handlePasswordOtp = (e) => {
    const value = e.target.value;
    setPasswordd(value);
    setErrorsMsg(null)
  };
  const handleCPasswordOtp = (e) => {
    const value = e.target.value;
    setErrorsMsg(null)
    setC_Passwordd(value);
  };
  // google_auth_verify == 0 ? googleAuthVerify : googleAuthDisable
  const onVerifyOtpSubmit = (e) => {
    e.preventDefault()
    google_auth_verify == 0 ? googleAuthVerify() : googleAuthDisable()
  }
  const showEye =
    "M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z";
  const hideEye =
    "M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z";

  return (
    <>
      <div className="settings_top_title d-flex flex-wrap justify-content-between align-items-center mt-4">
        <div>
          <h5 className="card-title mb-3">Security</h5>
          <p>Manage your security settings like login password, device management, login activity, and others. </p>
        </div>
        <div>
          <img src={`${BASE_URL.BASE_URL}img/security/security.png`} alt="security" width="50" />
        </div>
      </div>
      
      <div className="card mt15">
        <div className="card-body">
          <h5 className="card-title">Advanced Security & Activities</h5>
          <div className="settings-notification mt-4">
            <ul>
              <li>
                <div className="notification-info notification-info-w50 d-flex align-items-start">
                  <img src={`${BASE_URL.BASE_URL}img/dashboard/login.png`} alt="login" className="mr-3 mt-1" width="30px" />
                  <div>
                    <p>Change Password</p>
                    <span>
                      Change password is used to change your account password.
                    </span>
                  </div>
                </div>
                <div className="custom-control custom-switch notification-info-button-w20">
                  <button onClick={handleShowPassword} className="order_cancel_btn">Change</button>
                </div>
              </li>
              <li>
                <div className="notification-info notification-info-w50 d-flex align-items-start">
                  <img src={`${BASE_URL.BASE_URL}img/dashboard/biometric.png`} alt="biometric" className="mr-3 mt-1" width="30px" />
                  <div>
                    <p>Google Authenticator</p>
                    <span>
                      Protect your account and Transactions
                    </span>
                  </div>
                </div>
                {/* <span className="d-flex align-items-start notification-info-p-w30">
                  <img src={`${BASE_URL.BASE_URL}img/security/off.png`} alt="done" className="mr-2 mt-1" width="13" />
                  <p>Not Linked</p>
                </span> */}
                <div className="custom-control custom-switch notification-info-button-w20">
                  {gAuth_active != 1 ? <button onClick={google_auth_verify == 1 ? googleAuthEnable : getGoogleAuthQR} className="order_cancel_btn">{"Enable"}</button> :
                    // google_auth_verify
                    // googleAuthEnable
                    <button onClick={() => setShowQr(true)} className="order_cancel_btn">{"Disable"}</button>
                  }
                </div>
              </li>
              <li>
                <div className="notification-info notification-info-w50 d-flex align-items-start">
                  <img src={`${BASE_URL.BASE_URL}img/dashboard/device.png`} alt="Analytics" className="mr-3 mt-1" width="30px" />
                  <div>
                    <p>Disable Account</p>
                    <span>
                      Manage devices allowed to access your account.
                    </span>
                  </div>
                </div>
                <span className="d-flex align-items-start notification-info-p-w30">
                </span>
                <div className="custom-control custom-switch notification-info-button-w20">
                  <button className="order_cancel_btn" onClick={handleShow}>
                    Disable
                  </button>
                </div>
              </li>
              <li>
                <div className="notification-info notification-info-w50 d-flex align-items-start">
                  <img src={`${BASE_URL.BASE_URL}img/dashboard/account-activity.png`} alt="account-activity" className="mr-3 mt-1" width="30px" />
                  <div>
                    <p>Login Activity</p>
                    {activity ? <>
                      <span>
                        Last login:  {activity && <Moment format="DD/MM/YYYY hh:mm A">
                          {activity[0]?.login_time}
                        </Moment>}
                      </span>
                      <br />
                      <span>
                        Device: {activity && activity[0]?.device}
                      </span>
                    </> :
                      <span>
                        No activity log found!
                      </span>
                    }
                  </div>
                </div>
                {/* <span className="d-flex align-items-start notification-info-p-w30">
                  <img src={`${BASE_URL.BASE_URL}img/security/disable.png`} alt="disable" className="mr-2 mt-1" width="13" />
                  <p className="disable-color">Disable Account</p>
                </span> */}
                <div className="custom-control custom-switch notification-info-button-w20">
                  <button className="order_cancel_btn" onClick={handleLoginActivity}>
                    More
                    {showActivity ? <i className="icon ion-ios-arrow-down mt-1 ml-2"></i> : <i className="icon ion-ios-arrow-forward mt-1 ml-2"></i>}
                  </button>
                </div>
              </li>
              <div className={`activity-table ${showActivity ? 'show' : 'hide'}`}>
                {showActivity &&
                  <Table striped className="mt-4">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Source</th>
                        <th>Status</th>
                        <th>IP Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {activity ? <>
                        {activity?.map((item, index) => {
                          return <tr key={index}>
                            <td width="200px">

                              <Moment format="DD/MM/YYYY hh:mm A">
                                {item?.login_time}
                              </Moment>
                            </td>
                            <td width="200px">{item?.device}</td>
                            <td width="200px" className="text-success">Completed</td>
                            <td className="text-secondary">{item?.ip_address}</td>
                          </tr>
                        })}
                      </>
                        :
                        <tr>
                          <td colSpan="6">
                            <EmptyData />
                          </td>
                        </tr>
                      }
                    </tbody>
                  </Table>
                }
              </div>
              <li>
                <div className="notification-info notification-info-w50 d-flex align-items-start">
                  <img src={`${BASE_URL.BASE_URL}img/dashboard/account.png`} alt="account" className="mr-3 mt-1" width="30px" />
                  <div>
                    <p>User Activity</p>
                    {userActivityData ? <>
                      <span>
                        Last Activity: {userActivityData && userActivityData[0]?.remarks}
                      </span>
                      <br />
                      <span>
                        Activity Time :{userActivityData && <Moment format="DD/MM/YYYY hh:mm A">
                          {userActivityData[0]?.created_at}
                        </Moment>}
                      </span>
                    </> :
                      <span>
                        No activity log found!
                      </span>
                    }
                  </div>
                </div>
                <div className="custom-control custom-switch notification-info-button-w20">
                  <button className="order_cancel_btn" onClick={handleUserActivity}>
                    More
                    {userActivity ? <i className="icon ion-ios-arrow-down mt-1 ml-2"></i> : <i className="icon ion-ios-arrow-forward mt-1 ml-2"></i>}
                  </button>
                </div>
              </li>
              <div className={`activity-table ${userActivity ? 'show' : 'hide'}`}>
                <Table striped className="mt-4">
                  <thead>
                    <tr>
                      <th>Date & Time</th>
                      <th>Activities</th>
                      <th>Source</th>
                      <th>IP Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userActivityData ? <>
                      {userActivityData?.map((item, index) => {
                        return <tr key={index}>
                          <td width="200px">
                            <Moment format="DD/MM/YYYY hh:mm A">
                              {item?.created_at}
                            </Moment>
                          </td>
                          <td width="250px">{item?.remarks}</td>
                          <td width="200px">{item?.platform == 0 ? "Window" : "Android"}</td>
                          <td className="text-secondary">{item?.ip_address}</td>
                        </tr>
                      })}
                    </>
                      :
                      <tr>
                        <td colSpan="6">
                          <EmptyData />
                        </td>
                      </tr>
                    }
                  </tbody>
                </Table>
              </div>
            </ul>
          </div>
        </div>
      </div >


      {/************ Disable Account Modal*********** */}
      <Modal
        show={disable}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="withdrawal_confirmation_modal"
        scrollable={true}
        backdrop="static"
        animation
        centered
      >
        <Modal.Header className="PopUp_Header pb-0">
          <div className='pl-4'>
            <h5 className="card-title mb-0" >Disable Account</h5>
            <p className="text-secondary mb-0 mt-1">Disabling your account will cause the following</p>
          </div>
        </Modal.Header>
        <Modal.Body className="text-center pt-3">
          {/* <div className="text-center">
            <h4 className="mt-4 mb-2">Disable Account</h4>
            <p className="mb-2">Disabling your account will cause the following</p>
          </div> */}
          <div className="disAble_popup pl-4">
            <ul className="mywallet-address-liststyle text-left">
              <li> All pending withdrawal will be cancelled.</li>
              <li> All trading capacities for your account will be disabled.</li>
              <li> All login device will be logout.</li>
              <li> You can reactivate your account at any time by login back in VYNKSAFE App.</li>
              <p className="text-danger text-center">if you failed to reactivate your account, please contact customer service</p>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mt-0 pt-0">
          <Button variant="secondary" className='btn_size' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" disabled={loading ? true : false} className='btn_size' onClick={disableUserAccount}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/************ Change Password Modal*********** */}

      <Modal
        show={password}
        onHide={handleClosepassword}
        backdrop="static"
        keyboard={false}
        contentClassName="Chane_Password_Popup"
        centered
      >
        <Modal.Header className="PopUp_Header pb-0 mb-0" closeButton>
          <div>
            <h5 className="card-title mb-0" >Change Password</h5>
            {!show && <p className="text-secondary mb-0 mt-2">An OTP code sent to your registered email address. Please fill the same in below box to proceed.</p>}
          </div>
        </Modal.Header>
        <Modal.Body className="PopUp_Body pr-0 pl-0 pt-1 mt-2">
          <Form style={{ boxShadow: "none", padding: "0px" }} onSubmit={(e) => {
            e.preventDefault();
          }}>
            {show ? (<>
              <div className="form-access" style={{ boxShadow: "none" }}>
                {/* <h5 className="card-title mb-2 mt-0 text-center" >Password</h5> */}
                <Form.Group>
                  <div className="password_block">
                    <Form.Control
                      id="password"
                      type={passwordShown ? "text" : "password"}
                      name="New_Password"
                      style={{ height: "36px", fontSize: "12px" }}
                      placeholder="New password"
                      className={`form-control Nick_Name Emial_popup ${errorsMsg?.passwordError &&
                        errorsMsg?.passwordError !== ""
                        ? "error-active"
                        : ""
                        }`}
                      onChange={handlePasswordOtp}
                      value={passwordd}

                    />
                    <div
                      onClick={(e) => {
                        togglePassword(e, "password");
                      }}
                      className="password_hideshow_btn btn sm-btn"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path fill="#fff" d={passwordShown ? showEye : hideEye} />
                      </svg>
                    </div>
                  </div>
                  <small className="error-message text-danger text-wrap">
                    <div className="error-message text-danger">
                      {errorsMsg}
                    </div>
                  </small>
                </Form.Group>

                <Form.Group>
                  <div className="password_block confirm_password">
                    <Form.Control
                      type={c_passwordShown ? "text" : "password"}
                      name="confirm_password"
                      placeholder="Confirm password"
                      className={`form-control Nick_Name Emial_popup ${errorsMsg?.cm_passwordError &&
                        errorsMsg?.cm_passwordError !== ""
                        ? "error-active"
                        : ""
                        }`}
                      onChange={handleCPasswordOtp}
                      value={C_passwordd}
                      style={{ height: "36px", fontSize: "12px" }}
                    />
                    <div
                      onClick={(e) => {
                        togglePassword(e, "c_password");
                      }}
                      className="password_hideshow_btn btn sm-btn"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#fff"
                          d={c_passwordShown ? showEye : hideEye}
                        />
                      </svg>
                    </div>

                  </div>
                  <div className="error-message text-danger">
                    {passwordd !== C_passwordd && (
                      "Passwords do not match"
                    )}
                  </div>
                </Form.Group>

              </div>
            </>)
              : (<>
                <div className="Nick_NameTow">
                  <Form.Group>
                    <Form.Control
                      type="email"
                      className="form-control Nick_Name Emial_popup"
                      placeholder="Email address"
                      required
                      id="email"
                      readOnly
                      style={{ height: "36px", fontSize: "12px" }}
                      value={userData?.email}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Control
                      type="text"
                      className="form-control Nick_Name Emial_popup"
                      placeholder="Enter OTP"
                      name="otpNumber"
                      maxLength="6"
                      value={otp}
                      style={{ height: "36px", fontSize: "12px" }}
                      onChange={handleSubmitOtp}
                    />
                    <div className="error-message text-danger">
                      {errorsMsg}
                    </div>
                  </Form.Group>
                  <div className="countdown-text mb-2">
                    <div className="c_inner ">
                      {seconds > 0 || minutes > 0 ? (
                        <p>
                          Resend OTP in  {minutes < 10 ? `` : minutes}{seconds < 10 ? `0${seconds}` : seconds} seconds
                        </p>
                      ) : (
                        <>
                          <p>Didn't receive code?</p>
                          <button className="btn" onClick={resendOTP}>
                            Resend OTP
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>)}
            <Button variant="primary" onClick={show ? UserPassword : changeUserPasswordOTP} style={{ width: "100%" }} disabled={loading ? true : false} className="pl-4 pr-4">{show ? "Reset Password" : "Verify OTP"} </Button>
          </Form>
        </Modal.Body>
      </Modal >

      <Modal
        show={showQr}
        onHide={() => setShowQr(false)}
        backdrop="static"
        keyboard={false}
        contentClassName="Chane_Password_Popup"
        centered
      >
        <Modal.Header className="PopUp_Header pb-0" closeButton>
          <div>
            <h5 className="card-title mb-0" >Google Authentication</h5>
            <p className="text-secondary mb-0 mt-1">Enter 6-Digit code from your Authenticator app</p>
          </div>
        </Modal.Header>
        <Modal.Body className="text-center p-2">
          <div className="mt-3">
            {gAuth_active == 1 ? null :
              (<>
                {googleQr?.qrCodeUrl ? <img src={googleQr?.qrCodeUrl} alt="Qr Code Image" width="100" /> : <Loader />}
                <ul className="mywallet-address-liststyle text-left mt-2 mb-2">
                  <li> Install google Authenticator.</li>
                  <li> Scan QR code OR <CopyToClipboard
                    text={
                      googleQr &&
                        googleQr.google_auth_secret
                        ? googleQr?.google_auth_secret
                        : ""
                    }
                    onCopy={() =>
                      toast.success("copied")
                    }
                  >
                    <span className="copy_Auth">
                      copy setup key  {" "}
                      <i
                        title="Copy address to clipboard"
                        className="icon ion-md-copy"
                      ></i>
                    </span>
                  </CopyToClipboard></li>
                  <li> Enter 6 digit code.</li>
                </ul>
              </>)
            }
            <Form onSubmit={onVerifyOtpSubmit}>
              <Form.Group>
                <Form.Control
                  type="text"
                  className="form-control Nick_Name Emial_popup"
                  name="qrotp"
                  maxLength="6"

                  placeholder="Enter 6 digit code"
                  required
                  onChange={(e) => {
                    setQrOtp(e.target.value.replace(/\D/g, ""))
                    setOtpError(null)
                  }}
                  value={qrOtp}
                />
                <div className="error-message text-danger text-left pt-1">
                  {otpError}
                </div>
              </Form.Group>
            </Form>
            <Button variant="primary" onClick={google_auth_verify == 0 ? googleAuthVerify : googleAuthDisable} style={{ width: "100%" }} className="pl-4 pr- mb-3">{show ? "Reset Password" : "Verify OTP"} </Button>
          </div>

        </Modal.Body>
      </Modal >
    </>

  )
}

export default Security