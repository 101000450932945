import React from "react";
import BASE_URL from "../../_constant/index";
const SecurityTow = () => {
  return (
    <div>
      <section className="security_v py-5 dark-gray-bg-v">
        <div className="container">
          <div className="row row-cols-1 row-cols-lg-2 row-cols-md-1 row-cols-sm-1 align-items-center gap-v-4">
            <div className="col pr-lg-5">
              <h1 className="text-orange fw-1 mb-4"> Security</h1>

              <p className="my-4">
                Vynk Group guarantees superior safety for your digital assets while also offering an exceptional user experience. The foundation of Vynk Group is blockchain technology, which improves security and enables simple fund trading throughout the whole cryptocurrency market.
              </p>
            </div>
            <div className="col text-center">
              <img
                src={BASE_URL.BASE_URL + "img/images/security_vector.svg"}
                className="img-fluid dark-theme-img"
                alt="banner"
                width="560"
              />
              <img
                src={
                  BASE_URL.BASE_URL +
                  "img/images/light-theme/security_vector.svg"
                }
                className="img-fluid light-theme-img"
                alt="banner"
                width="560"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="featured_section_v dark-gray-bg-v">
        <div className="container">
          <div className="row row-cols-1 row-cols-lg-2 row-cols-md-1 row-cols-sm-1 align-items-center gap-v-4">
            <div className="col pr-lg-5 order-lg-2-v">
              <img
                 src={BASE_URL.BASE_URL + "img/images/featured_vector.svg"}
                className="img-fluid dark-theme-img"
                alt="banner"
                width="560"
              />
              <img
                src={BASE_URL.BASE_URL + "img/images/light-theme/featured_vector.svg"}
                className="img-fluid light-theme-img"
                alt="banner"
                width="560"
              />
            </div>

            <div className="col pl-lg-5 d-flex justify-content-lg-end justify-content-center mb-5 mb-lg-0">
              <ul className="text-white featured_ul_section_v">
                <li className="manage_profits_v position-relative">
                  Manage profits with multi layered security.
                </li>
                <li className="ml-lg-5 my-5 exchange_freedome_v position-relative">
                  Trade with freedom over the crypto markets.
                </li>
                <li className="experienced_engineers position-relative">
                  Backed with a team of experienced engineers.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SecurityTow;
