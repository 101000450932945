import React from "react";
import BASE_URL from "../_constant/index";
import { Link } from "react-router-dom";

const LoginFirst = (props) => {
  
  let return_to =
    props?.return_to && props?.return_to != ""
      ? `?return_to=${props?.return_to}`
      : "";

  return (
    <div className="without_login_fav">
      <div className="inner_login">
        <Link to={BASE_URL.BASE_URL + `login${return_to}`}>Log In</Link>
        <p className="">or</p>
        <Link to={BASE_URL.BASE_URL + `signup`}>Register Now</Link>
        <p>{props.title}</p>
      </div>
    </div>
  );
};

export default LoginFirst;
