import React, { useState, useEffect, useMemo, useRef } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MarketTable from "./marketTable";

import { allSymbolsAction } from "../../../redux/apiActions/api.action";
import { useDispatch } from "react-redux";

const Marketswatch = ({ socket }) => {
  const dispatch = useDispatch();
  const tableRef = useRef()

  const scroolTable = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({
        behavior: 'smooth', // You can use 'auto' instead of 'smooth' for an instant jump
      });
    }
  }

  const [loading, setLoading] = useState(false);
  const [activeTabPair, _activeTabPair] = useState("USDT");
  const [symbols, setSymbols] = useState({});
  const [symbolsData, setSymbolsData] = useState([]);
  const [currPairList, setCurrPairList] = useState([]);
  const [symbolsCoinListPrice, setSymbolsCoinListPrice] = useState([]);

  const handleSearch = () => {
    const filtered = symbolsData.filter((item) =>
      item.symbol.toLowerCase().includes(activeTabPair.toLowerCase())
    );
  };

  useEffect(() => {
    handleSearch();
  }, [activeTabPair]);

  useEffect(() => {
    const prices = symbolsData?.map((ele) => {
      return {
        symbol: ele.symbol,
        price: ele.price,
        last_price: ele.last_price,
        usd_price: ele.usd_price,
        changes: ele.changes,
      };
    });
    setSymbolsCoinListPrice(prices);
  }, [symbolsData]);

  useEffect(async () => {
    setLoading(true);
    let result = await allSymbolsAction();
    if (result !== false) {
      setSymbols(result);
      setSymbolsData(result?.data);
    }
    setLoading(false);
    //  getUserFavPair();
  }, []);

  useMemo(() => {
    let filterRes = symbolsData?.filter((pair) => {
      return pair?.quote === activeTabPair;
    });

    if (filterRes !== undefined) {
      setCurrPairList(filterRes);
    }
  }, [activeTabPair, symbols, symbolsData]);

  const tempPriceData = symbolsCoinListPrice;
  // useEffect(() => {
  //   socket?.emit("get_pairprice", {
  //     pair_id: "ALL",
  //   });

  //   socket?.on("received_pairs_price", (socketData) => {
  //     if (tempPriceData?.length > 0) {
  //       tempPriceData.map((item, index) => {
  //         socketData.map((data) => {
  //           if (item.symbol === data.symbol && parseFloat(data.price) > 0 ) {
  //             tempPriceData[index].price = data.price;
  //             tempPriceData[index].usd_price = data.usd_price;
  //             tempPriceData[index].last_price = data.last_price;
  //             tempPriceData[index].changes = data.changes;
  //           }
  //         });
  //       });
  //     }
  //     setSymbolsCoinListPrice(tempPriceData);
  //   });
  // }, [socket, tempPriceData, setSymbolsCoinListPrice]);
  useEffect(() => {
    socket?.emit("get_pairprice", {
      pair_id: "ALL",
    });

    socket?.on("received_pairs_price", (socketData) => {
      if (tempPriceData?.length > 0) {
        const updatedPriceData = tempPriceData.map((item) => {
          const foundData = socketData.find((data) => item.symbol === data.symbol && parseFloat(data.price) > 0);

          if (foundData) {
            return {
              ...item,
              price: foundData.price,
              usd_price: foundData.usd_price,
              last_price: foundData.last_price,
              changes: foundData.changes,
            };
          }

          return item;
        });

        console.log(updatedPriceData);
        setSymbolsCoinListPrice(updatedPriceData);
      }
    });
  }, [socket, tempPriceData]);


  return (
    <>
      <section className="pricing_section_v dark-gray-bg-v markets_overview" ref={tableRef}>
        <div className="container">
          <div className="row mb-3">
            <div className="col">
              <div className="d-flex flex-wrap justify-content-between align-items-center gap-v-4">
                <h3 className="mt-2">Markets Watch</h3>
                {/* <form className="markets_watch_table_search_v">
                  <div className="input-group align-items-center">
                    <button type="submit">
                      <i className="fa fa-search"></i>
                    </button>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      name="usrname"
                    />
                  </div>
                </form> */}
              </div>
            </div>
          </div>

          <div className="row row-cols-1">
            <div className="col markets_watch_table_tabsv">
              {/* <Tabs
                defaultActiveKey="All"
                id="uncontrolled-tab-example"
                className="mb-3"
              > */}
              {/* <Tab eventKey="Favorites" title="FAVORITES" disabled>
                  Tab content for Contact
                </Tab> */}
              {/* <Tab eventKey="All" title="All"> */}
              <MarketTable
                scroolTable={scroolTable}
                symbolsCoinListPrice={symbolsCoinListPrice}
                loading={loading}
                activeTabPair={activeTabPair}
                _activeTabPair={_activeTabPair}
                symbols={symbols}
                currPairList={currPairList}
              />
              {/* </Tab> */}
              {/* <Tab eventKey="Spot" title="Spot">
                  <MarketTable
                    symbolsCoinListPrice={symbolsCoinListPrice}
                    loading={loading}
                    activeTabPair={activeTabPair}
                    _activeTabPair={_activeTabPair}
                    symbols={symbols}
                    currPairList={currPairList}
                  />
                </Tab> */}
              {/* <Tab eventKey="Future" title="Future">
                  Tab content for Future
                </Tab>
                <Tab eventKey="New Listings" title="New Listings">
                  Tab content for New Listings
                </Tab>
                <Tab eventKey="Rankings" title="Rankings">
                  Tab content for Rankings
                </Tab> */}
              {/* </Tabs> */}
              {/* <div className="markets_watch_table_tabs">
                <ul className="nav nav-tabs">
                  <li className="text-uppercase mr-3">Favorites</li>
                  <li>
                    <a data-toggle="tab" href="#allv" className="active">
                      All
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#spotv">
                      Spot
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#futurev">
                      Future
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#newlistingsv">
                      New Listings
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#rankingsv">
                      Rankings
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Marketswatch;
