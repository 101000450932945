import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import utill from "../../../_constant/utill";
import BASE_URL from "../../../_constant/index";
import Loader from "../../../components/loader";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import { Link } from "react-router-dom";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

const { SearchBar } = Search;

const MarketTable = ({
  loading,
  activeTabPair,
  _activeTabPair,
  symbols,
  currPairList,
  symbolsCoinListPrice,
  scroolTable
}) => {


  return (
    <>
      {loading ? (
        <div style={{ minHeight: "580px", position: "relative" }}>
          <Loader />
        </div>
      ) : (
        <div className="new_markets pb70">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="markets-pair-list markets_pair_pricing_section">
                  <Tabs
                    defaultActiveKey={activeTabPair}
                    onClick={(e) => {
                      _activeTabPair(e.target.innerText);
                    }}
                  >
                    {symbols?.pair
                      ?.filter(
                        (pair) => pair.symbol == "USDT" || pair.symbol == "VYNC"
                      )
                      ?.map((pair, key) => {
                        return (
                          <Tab
                            eventKey={pair?.symbol}
                            title={pair?.symbol}
                            value={pair?.symbol}
                            key={key}
                          >
                            <MarketsTabList
                              scroolTable={scroolTable}
                              symbolsData={currPairList}
                              symbolsCoinListPrice={symbolsCoinListPrice}
                              activeTabPair={activeTabPair}
                            />
                          </Tab>
                        );
                      })}
                  </Tabs>
                  {/* <div className="text-center">
                <a href="#!" className="load-more btn">
                  Load More <i className="icon ion-md-arrow-down"></i>
                </a>
              </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

function MarketsTabList({ symbolsData, symbolsCoinListPrice ,scroolTable}) {
  const SpotData = symbolsData.map((item) => {
    let priceObj = symbolsCoinListPrice.find(
      (obj) => obj.symbol === item.symbol
    );
    return {
      id: item?.id,
      pair: item?.symbol,
      coin: [item?.base_image_url, item?.base, item?.basename],
      last_price: utill.valueFix(priceObj?.price, 4),
      changes: priceObj?.changes,
      high_24: item?.high_24,
      low_24: item?.low_24,
      volume_24: item?.volume_24,
      URL: [item?.base, item.quote],
    };
  });

  const SpotColumns = [
    {
      dataField: "pair",
      text: "Pairs",
      sort: true,
    },
    {
      dataField: "coin",
      text: "Coin",
      sort: true,
      formatter: (cell) => {
        return (
          <>
            <div className="d-flex d-flex align-items-center justify-content-start">
              <div className="mr-1" style={{ width: "20%" }}>
                <img
                  src={cell[0]}
                  className="img-fluid mr-1"
                  alt="coin"
                  width="25px"
                />
              </div>
              <div style={{ width: "80%" }}>
                {cell[2]}
                <span className="text-gray-v fs-5v ml-1">{cell[1]}</span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "last_price",
      text: `Last Price(USDT)`,
      sort: true,
      formatter: (cell) => {
        return <>{utill.beautifyNumber(utill.valueFix(Number(cell)), 10)}</>;
      },
      style: () => {
        return { textAlign: "right" };
      },
      headerStyle: () => {
        return { textAlign: "right" };
      },
    },

    {
      dataField: "changes",
      text: "24h Changes",
      sort: true,
      formatter: (cell) => {
        return (
          <>
            <div>
              <span
                className={
                  parseFloat(cell) >= 0
                    ? `text-green-v fs-4v px-2 py-1     `
                    : `text-red-v fs-4v px-2 py-1`
                }
              >
                {parseFloat(cell) >= 0 ? `+` : ``}
                {utill.beautifyNumber(Number(cell), 2)} %
              </span>
            </div>
          </>
        );
      },
      style: () => {
        return { textAlign: "right" };
      },
      headerStyle: () => {
        return { textAlign: "right" };
      },
    },

    {
      dataField: "high_24",
      text: "High 24H",
      sort: true,
      formatter: (cell) => {
        return (
          <>
            <div>{utill.valueFix(Number(cell))}</div>
          </>
        );
      },
      style: () => {
        return { textAlign: "right" };
      },
      headerStyle: () => {
        return { textAlign: "right" };
      },
    },

    {
      dataField: "low_24",
      text: "Low 24H",
      sort: true,
      formatter: (cell) => {
        return (
          <>
            <div>{utill.valueFix(Number(cell))}</div>
          </>
        );
      },
      style: () => {
        return { textAlign: "right" };
      },
      headerStyle: () => {
        return { textAlign: "right" };
      },
    },
    {
      dataField: "volume_24",
      text: "Volume 24h",
      sort: true,
      formatter: (cell) => {
        return (
          <>
            <div>{utill.valueFix(Number(cell))}</div>
          </>
        );
      },
      style: () => {
        return { textAlign: "right" };
      },
      headerStyle: () => {
        return { textAlign: "right" };
      },
    },

    {
      dataField: "URL",
      text: "Action",

      formatter: (cell) => {
        // let redirectUrl = `https://io.pixelsoftwares.com/exchange/v1/exchange/${cell[0]}_${cell[1]}`;
        let redirectUrl = `${BASE_URL.BASE_URL}exchange/${cell[0]}_${cell[1]}`;
        return (
          <>
            <Link to={redirectUrl} style={{ borderBottom: "1px solid" }} className="tableTrade">
              {/* <button className="btn btn-gray-v text-white w-100 p-1"> */}
              Trade
              {/* </button> */}
            </Link>
          </>
        );
      },
    },
  ];

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    onPageChange:(ppage, sizePerPage)=>{
      scroolTable();
    },
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "<",
    nextPageText: ">",
    // lastPageText: "Last",
    // nextPageTitle: "First page",
    // prePageTitle: "Pre page",
    // firstPageTitle: "Next page",
    // lastPageTitle: "Last page",
    // btnGroupClass: "custom-btn-group",
    // sizePerPageDropdownClass: "custom-size-per-page",
    // firstPageLinkClass: "custom-first-link",
    // prePageLinkClass: "custom-pre-link",
    // nextPageLinkClass: "custom-next-link",
    // lastPageLinkClass: "custom-last-link",
    // showTotal: true,
    // disablePageTitle: true,
    // sizePerPageList: [
    //   {
    //     text: "5",
    //     value: 5,
    //   },
    //   {
    //     text: "10",
    //     value: 15,
    //   },
    //   {
    //     text: "All",
    //     value: symbolsData.length,
    //   },
    // ], // A numeric array is also available. the purpose of above example is custom the text
  };
  return (
    <>
      <ToolkitProvider
        bootstrap4
        keyField="id"
        data={SpotData}
        columns={SpotColumns}
        rowStyle={{ cursor: "default" }}
        search
      >
        {(props) => (
          <div className="pricing_table_v">
            <SearchBar
              {...props.searchProps}
              style={{ width: "200px", height: "40px", position: 'absolute', right: "0px", top: "-68px" }}
            />
            <BootstrapTable
              {...props.baseProps}
              headerClasses="PricerHeader"
              filter={filterFactory()}
              // pagination={ paginationFactory()}
              pagination={paginationFactory(options)}
              // pagination={paginationFactory({ sizePerPage: rowCount })} // Use rowCount to control the number of rows displayed
              noDataIndication="There is no data"
              className="price_table"
              classes="crypto_price_table"
              wrapperClasses="table-responsive"
              striped
              condensed
              loading={true}
              bordered={false}
            />
          </div>
        )}
      </ToolkitProvider>
      {/* <BootstrapTable
        bootstrap4
        keyField="id"
        data={SpotData}
        columns={SpotColumns}
        defaultSorted={defaultSorted}
        // pagination={paginationFactory()}
        className="table"
        bordered={false}
        wrapperClasses="table-responsive"
      /> */}
    </>
  );
}

export default MarketTable;
