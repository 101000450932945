import React, { useState, useEffect } from "react";
import BASE_URL from "../../_constant/index";
import BASE_URL_Tow from "../../_constant/index";
import {
  user_Kyc,
  user_user_userInfo,
} from "../../redux/apiActions/user.action";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import CloseButton from "react-bootstrap/CloseButton";

const Verification = ({ selectedCoin, dashBoardUserData }) => {
  const userData = useSelector((state) => state.user.userData);
  const countryImage = userData.country_image;

  const [kyc, setKyc] = useState(false);
  const [kycStatus, setKycStatus] = useState("");
  const [show, setShow] = useState(false);
  const [countryImageFromUser, setcountryImage] = useState();
  const getUserInfo = async () => {
    try {
      const responce = await user_user_userInfo();
      if (responce) {
        setcountryImage(responce?.data?.country_image);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   useEffect(() => {

  //   }, []);

  useEffect(() => {
    if (selectedCoin === "identification") {
      const user_kyc_Details = async () => {
        const response = await user_Kyc({});
        if (response?.message === "Success") {
          if (response?.data?.status_message === "KYC Completed") {
            setKyc(true);
          } else {
            setKyc(false);
            setKycStatus(response?.data?.status_message);
          }
          localStorage.setItem("User_kyc", JSON.stringify(response?.data));
        }
      };
      user_kyc_Details();
      getUserInfo();
    }
  }, [selectedCoin]);

  useEffect(() => {
    if (selectedCoin === "identification") {
      const kycData = localStorage.getItem("User_kyc");
      const user_kyc_data = JSON.parse(kycData);
      if (user_kyc_data?.status_message === "KYC Completed") {
        setKyc(true);
      } else {
        setKyc(false);
        setKycStatus(user_kyc_data?.status_message);
      }
    }
  }, [selectedCoin]);

  return (
    <>
      <div className="settings_top_title d-flex flex-wrap justify-content-between align-items-center mt-4">
        <div>
          <h5 className="card-title mb-3">Personal Verification</h5>
          <p>The KYC process for identity verification is now completed. </p>
        </div>
        <div>
          <p>
            Residential country/region:{" "}
            <img
              src={`${BASE_URL_Tow.BASE_URL_Tow}uploads/flags/${
                countryImageFromUser || countryImage
              }`}
              alt="india"
              className="ml15"
              width="30px"
            />
          </p>
        </div>
      </div>
      <Row className="mt15">
        <Col md={4} sm={6}>
          <div className="advanced-verification-body">
            <div className="card-body text-center">
              {kyc ? (
                <>
                  <img
                    src={`${BASE_URL.BASE_URL}img/dashboard/Personal Verification.png`}
                    alt="advanced verification completed"
                    className="ml15 mt-4"
                  />
                  <div className="identification-notification d-flex mt-3">
                    <h5 className="card-title text-center mt15 m-auto">
                      <img
                        src={`${BASE_URL.BASE_URL}img/dashboard/profile-verify.png`}
                        alt=""
                        className="mr-1 mb-1"
                        width="20"
                      />
                      Verification completed
                    </h5>
                  </div>
                </>
              ) : (
                <>
                  <img
                    src={`${BASE_URL.BASE_URL}img/verification/advanced-verification-pending.png`}
                    alt="advanced verification pending"
                    className="ml15 mb-3"
                  />

                  <div className="identification-notification">
                    <h5 className="card-title text-center mt-3 mb-0">
                      {kycStatus}
                    </h5>
                  </div>
                </>
              )}
            </div>
          </div>
        </Col>

        <Col md={4} sm={6}>
          <div className="card">
            <div className="card-body requirements-body">
              <h5 className="card-title mb-2">Requirements</h5>
              {/* <p>Vulputate vel libero sed</p> */}
              <div className="identification-notification mt-4">
                <ul>
                  <li className="d-flex align-items-center mb15">
                    <img
                      src={`${BASE_URL.BASE_URL}img/dashboard/Personal information.png`}
                      alt="personal information"
                      className="mr-3"
                      width={"30px"}
                    />
                    <p className="mb-0">Personal information</p>
                  </li>

                  <li className="d-flex align-items-center mb15">
                    <img
                      src={`${BASE_URL.BASE_URL}img/dashboard/Government-issued ID.png`}
                      alt="government issued id"
                      className="mr-3"
                      width={"30px"}
                    />
                    <p className="mb-0">Government-issued ID</p>
                  </li>

                  <li className="d-flex align-items-center mb15">
                    <img
                      src={`${BASE_URL.BASE_URL}img/dashboard/Facial recognition.png`}
                      alt="facial recognition"
                      className="mr-3"
                      width={"30px"}
                    />
                    <p className="mb-0 ml-1">Facial recognition</p>
                  </li>

                  <li className="d-flex align-items-center mb15">
                    <img
                      src={`${BASE_URL.BASE_URL}img/dashboard/Review time_ 5 Hours.png`}
                      alt="review time"
                      className="mr-3"
                      width={"30px"}
                    />
                    <p className="mb-0" style={{ marginLeft: "4px" }}>
                      Review time: 5 Hours
                    </p>
                  </li>
                </ul>
                {/* <a href="https://play.google.com/store/apps/details?id=com.vynksafe.app" target="_blank"> */}
                <button
                  onClick={() => {
                    setShow(true);
                  }}
                  disabled={kyc}
                  className="btn verification-btn w-100 mt15"
                >
                  {kyc ? "Verified" : "Start Verification"}{" "}
                </button>
                {/* </a> */}
              </div>
            </div>
          </div>
        </Col>
        <Col md={4} sm={6}>
          <div className="card">
            <div className="card-body requirements-body">
              {kyc ? (
                <>
                  <h5 className="card-title mb-2">Current Features</h5>
                  {/* <p>Vulputate vel libero sed</p> */}
                  <div className="identification-notification mt-4">
                    <ul>
                      <li className="d-flex align-items-center mb15">
                        <img
                          src={`${BASE_URL.BASE_URL}img/dashboard/Daily Withdrawal Limit.png`}
                          width={"30px"}
                          alt="personal information"
                          className="mr15"
                        />
                        <p className="mb-0">
                          Daily Withdrawal Limit <br />{" "}
                          <span className="card-title">
                            {" "}
                            ${dashBoardUserData.maximum_withdraw_limit}
                          </span>{" "}
                          daily
                        </p>
                      </li>

                      <li className="d-flex align-items-center mb15">
                        <img
                          src={`${BASE_URL.BASE_URL}img/dashboard/Fiat and Spot.png`}
                          width={"30px"}
                          alt="government issued id"
                          className="mr15"
                        />
                        <p className="mb-0">
                          Crypto Deposit Limit <br />{" "}
                          <span className="card-title"> Unlimited</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </>
              ) : (
                <>
                  <h5 className="card-title mb-2">Current Features</h5>
                  <div className="identification-notification mt-4">
                    <div className="verified-img w-100 text-center mb15">
                      <img
                        src={`${BASE_URL.BASE_URL}img/verification/not-verified.png`}
                        alt="not verified"
                      />
                    </div>

                    <h6 className="mb-2 text-center">
                      Your account is currently not verified.
                    </h6>
                    <p className="text-center">
                      Please verify your account from VYNKSAFE APP
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        backdrop="static"
        keyboard={false}
        centered
        className="withdrawal_confirmation_modal"
        contentClassName="content_Size"
      >
        <Modal.Body className="text-center">
          <CloseButton
            onClick={() => {
              setShow(false);
            }}
          />
          <div>
            <img
              src={BASE_URL.BASE_URL + "img/complete_your_kyc.png"}
              alt="withdrawal confirmation"
              width="65"
              className="mb-2"
            />
          </div>
          <Modal.Title className="d-flex justify-content-center mb-3">
            Complete your KYC
          </Modal.Title>
          <div className="transaction_details_Successful d-flex">
            <ul className="mywallet-address-liststyle text-left">
              <li className="text-secondary">
                A user can complete the KYC process by submitting their
                official&nbsp; &nbsp;valid document ID numbers such as AADHAAR
                and PAN.
              </li>
              <li className="text-secondary">
                Users must complete the KYC via their mobile device only.
                Web&nbsp; &nbsp;doesn’t allow a user to upload documents for KYC
                completion.
              </li>
            </ul>
          </div>
          <div className="d-flex justify-content-center  mt-3">
            <Button
              className="Android mr-2"
              style={{ fontSize: "11px" }}
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.vynksafe.app",
                  "_blank"
                );
                setShow(false);
              }}
            >
              <img
                src={`${BASE_URL.BASE_URL}img/dashboard/Playstore.png`}
                className="mr-2"
                width="25"
              />
              {/* <i className="icon ion-logo-android mt-1 ml-2 mr-2"></i> */}
              Download On Google Play
            </Button>
            <Button
              className="Android"
              style={{ fontSize: "11px" }}
              onClick={() => {
                window.open(
                  "https://apps.apple.com/us/app/vynksafe-crypto-app/id1626971774",
                  "_blank"
                );
                setShow(false);
              }}
            >
              {/* Apple */}
              <img
                src={`${BASE_URL.BASE_URL}img/dashboard/Apple.png`}
                className="mr-2"
                width="25"
              />
              Download On App Store
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Verification;
