  import React, { useEffect, useState } from "react";
  import Layout from "../components/Layout";
  import { Switch, Route } from "react-router-dom";
  import Exchange from "../pages/exchange";
  // import Markets from "../pages/markets";
  import Profile from "./profile";
  import Wallet from "./wallet";
  import Settings from "./settings";
  import Login from "./login";
  import Reset from "./reset";
  import OtpVerify from "./otp-verify";
  import OtpNumber from "./otp-number";
  import VerifyNumber from "./update-phone";
  import Lock from "./lock";
  import TermsAndConditions from "./terms-and-conditions";
  import NewsDetails from "./news-details";
  import Signup from "./signup";
  import Notfound from "./notfound";
  import BASE_URL from "../_constant/index";
  import ResetPassword from "./ResetPassword";
  import Reset_Password_otp from "./Reset_Password_otp";
  import Trading_View_full from "../components/trading_chart/Trading_view_full";
  import Mywallet from "./Mywallet";
  import {
    notifications,
    notificationRead,
  } from "../redux/apiActions/user.action";
  import { useDispatch, useSelector } from "react-redux";
  import Home from "../VynksafeNew/Home";
  import Market from "../VynksafeNew/Market";
  import Commingsoon from "../VynksafeNew/Commingsoon";
  import Company from "../VynksafeNew/company";
  import { Product } from "../VynksafeNew/Product";
  import Saving from "../VynksafeNew/Saving";
  import P2P from "../components/P2P/P2P";
  import CreateOffer from "../components/P2P/CreateOffer";
  import UserProfile from "../components/P2P/UserProfile";
  import OderCompleted from "../components/P2P/OderCompleted";
  import Terms from "../components/Terms";
  import VynkPayApi from "../components/apiDocumentation/VynkPayApi";
  // import UpdateCountre from "./Update-Countre";

  export default function Index({ socket, notificationToken }) {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user.userData);

    const [show, setShow] = useState(false);
    const [notificationData, setNotificationData] = useState([]);

    const notification = async () => {
      if (userData.login_token) {
        const notification = await notifications(dispatch);
        setNotificationData(notification);
        if (notification?.notification.length > 0) {
          setShow(true);
        } else {
          setShow(false);
        }
      }
    };

    useEffect(() => {
      notification();
    }, [show]);

    const readNotification = async (id) => {
      try {
        let data = new FormData();
        data.append("notification_id", id);
        const response = await notificationRead(data);

        if (response?.data?.status === 200) {
          notification();
        }
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <>
        <Layout
          notificationData={notificationData}
          show={show}
          readNotification={readNotification}
          notification={notification}
        >
          <Switch>
            <Route exact path={BASE_URL.BASE_URL + `home`}>
              <Home />
            </Route>
            <Route exact path={BASE_URL.BASE_URL + `vynksafe-api`}>
              <VynkPayApi />
            </Route>
            <Route exact path={BASE_URL.BASE_URL + `p2p/:tab`}>
              <P2P />
            </Route>
            <Route exact path={BASE_URL.BASE_URL + `Terms`}>
              <Terms />
            </Route>
            <Route exact path={BASE_URL.BASE_URL + `OderCompleted`}>
              <OderCompleted />
            </Route>
            <Route exact path={BASE_URL.BASE_URL + `userprofile`}>
              <UserProfile />
            </Route>
            <Route exact path={BASE_URL.BASE_URL + `createoffer`}>
              <CreateOffer />
            </Route>
            <Route exact path={BASE_URL.BASE_URL + `p2p/All`}>
              <P2P />
            </Route>

            <Route exact path={BASE_URL.BASE_URL + `savings`}>
              <Saving />
            </Route>

            <Route exact path={BASE_URL.BASE_URL + `company`}>
              <Commingsoon />
            </Route>

            <Route exact path={BASE_URL.BASE_URL + `market`}>
              <Market socket={socket} />
            </Route>

            <Route exact path={BASE_URL.BASE_URL + `product`}>
              <Product socket={socket} />
            </Route>

            <Route exact path={BASE_URL.BASE_URL}>
              <Home />
            </Route>

            <Route exact path={BASE_URL.BASE_URL + `exchange/:pair_symbol`}>
              <Exchange socket={socket} />
            </Route>
            
            <Route exact path={BASE_URL.BASE_URL + `Mywallet/:active_path`}>
              <Mywallet socket={socket} />
            </Route>

            <Route exact path={BASE_URL.BASE_URL + "profile/:active_path"}>
              <Profile
                socket={socket}
                notificationData={notificationData}
                show={show}
                readNotification={readNotification}
                notification={notification}
              />
            </Route>

            <Route
              exact
              path={BASE_URL.BASE_URL + "lib/trading-view/:theme/:pair"}
            >
              <Trading_View_full />
            </Route>

            <Route exact path={BASE_URL.BASE_URL + "wallet/:coin_symbol"}>
              <Wallet />
            </Route>
            <Route exact path={BASE_URL.BASE_URL + "settings"}>
              <Settings />
            </Route>
            <Route exact path={BASE_URL.BASE_URL + "login"}>
              <Login notificationToken={notificationToken} />
            </Route>
            <Route exact path={BASE_URL.BASE_URL + "signup"}>
              <Signup />
            </Route>
            <Route exact path={BASE_URL.BASE_URL + "reset"}>
              <Reset />
            </Route>
            <Route exact path={BASE_URL.BASE_URL + "conform-email-otp"}>
              <Reset_Password_otp />
            </Route>
            <Route exact path={BASE_URL.BASE_URL + "conform-password"}>
              <ResetPassword />
            </Route>
            <Route exact path={BASE_URL.BASE_URL + "otp-verify"}>
              <OtpVerify />
            </Route>
            <Route exact path={BASE_URL.BASE_URL + "verify-number"}>
              <VerifyNumber />
            </Route>
            <Route exact path={BASE_URL.BASE_URL + "otp-number"}>
              <OtpNumber />
            </Route>
            <Route exact path={BASE_URL.BASE_URL + "lock"}>
              <Lock />
            </Route>
            <Route exact path={BASE_URL.BASE_URL + "terms-and-conditions"}>
              <TermsAndConditions />
            </Route>
            <Route exact path={BASE_URL.BASE_URL + "news-details"}>
              <NewsDetails />
            </Route>

            <Route exact path={BASE_URL.BASE_URL + "notfound"}>
              <Notfound />
            </Route>

            <Route path="*">
              <Notfound />
            </Route>
          </Switch>
        </Layout>
      </>
    );
  }
