import React from 'react';
import BASE_URL from "../_constant/index";
import { Link } from 'react-router-dom';

export default function notfound() {
  return (
    <>
      <div className="error-page basic_pages d-flex justify-content-center text-center">
        <div className="my-auto">
          <h2>404</h2>
          <p>Oops something went wrong</p>
                  
          <Link to={BASE_URL.BASE_URL}  className="btn">
            Back to Home <i className="icon ion-md-home"></i>
          </Link>
        </div>
      </div>
    </>
  );
}
