import {
  makeApiRequest,
  generateSymbol,
  parseFullSymbol,
  getKlineBars,
  getSymbol,
} from "./helpers.js";
import { subscribeOnStream, unsubscribeFromStream } from "./streaming.js";
import moment from "moment";

const lastBarsCache = new Map();

// console.log("lastBarsCache", lastBarsCache);

let cachedBars = [];
const configurationData = {
  supported_resolutions: ["15", "30", "60", "D", "W", "M"],
  exchanges: [
    {
      value: "VYNKSAFE",
      name: "VYNKSAFE",
      desc: "VYNKSAFE",
    },
  ],
  symbols_types: [
    {
      name: "crypto",
      value: "crypto",
    },
    // ...
  ],
};

async function getSymbolDetails(symbol) {
  const allSymbols = await getSymbol(symbol);
  return allSymbols.data;
}

export default {
  onReady: async (callback) => {
    setTimeout(() => callback(configurationData));
  },

  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback,
    extension
  ) => {
    // console.log(extension, "Method call");

    let symbol = symbolName.split(":")[1] || symbolName;
    const symbolDetail = await getSymbolDetails(symbol);
    const symbolItem = symbolDetail || {};
    if (Object.keys(symbolItem).length === 0) {
      // console.log("[resolveSymbol]: Cannot resolve symbol", symbolName);
      onResolveErrorCallback("cannot resolve symbol");
      return;
    }
    const symbolInfo = {
      ticker: symbolItem.full_name,
      id: symbolItem.id,
      name: symbolItem.symbol,
      description: symbolItem.description,
      type: symbolItem.type,
      exchange: symbolItem.exchange,
      session: "24x7",
      timezone: "Asia/Kolkata",
      minmov: 1,
      pricescale: 10000,
      has_intraday: true,
      has_daily: true,
      has_weekly_and_monthly: true,
      paneButtonsVisibility: "visibleOnMouseOver",
      supported_resolutions: configurationData.supported_resolutions,
      volume_precision: 2,
      data_status: "streaming",
    };
    onSymbolResolvedCallback(symbolInfo);
  },

  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) => {
    let { from, to, countBack, firstDataRequest } = periodParams;
    try {
      let pair = symbolInfo?.id;
      const data = await getKlineBars(pair, resolution, 500, from, to);
      if (
        (data.Response && data.Response === "Error") ||
        data.Data.length === 0
      ) {
        onHistoryCallback([], {
          noData: true,
        });
        return [];
      }
      let bars = [];

      data.Data.forEach((bar) => {
        if (
          parseFloat(bar.time) >= parseFloat(from) &&
          parseFloat(bar.time) <= parseFloat(to)
        ) {
          bars = [
            ...bars,
            {
              time: bar.time * 1000,
              low: bar.low,
              high: bar.high,
              open: bar.open,
              close: bar.close,
            },
          ];
        }
      });

      if (firstDataRequest) {
        lastBarsCache.set(symbolInfo.full_name, {
          ...bars[bars.length - 1],
        });
      }
      onHistoryCallback(bars, {
        noData: !bars.length,
      });
      return bars;
    } catch (error) {
      console.log("[getBars]: Get error", error);
      onErrorCallback(error);
      return [];
    }
  },

  subscribeBars: async (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback
  ) => {

    console.log(
      symbolInfo,
      "[subscribeBars]: Method call with subscriberUID:",
      subscriberUID
    );


    subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscriberUID,
      onResetCacheNeededCallback,
      lastBarsCache.get(`${symbolInfo.exchange}:${symbolInfo.name}`)
      // lastBarsCache.get(symbolInfo.full_name)
    );
  },

  unsubscribeBars: async (subscriberUID) => {
    unsubscribeFromStream(subscriberUID);
  },
};

// import { makeApiRequest, generateSymbol, parseFullSymbol, getKlineBars, getSymbol } from "./helpers.js";
// import { subscribeOnStream, unsubscribeFromStream } from "./streaming.js";
// import moment from 'moment';

// const lastBarsCache = new Map();
// let cachedBars = [];
// // const timezone = (timestamp) => {
// //   const date = new Date(timestamp);

// //   // Get the timezone offset for UTC+5:30 in minutes
// //   const timezoneOffset = date.getTimezoneOffset(); // returns 330

// //   // Subtract the timezone offset from the timestamp to get the equivalent UTC time
// //   return timestamp - (timezoneOffset * 60 * 1000);
// // };

// const configurationData = {
//   // supported_resolutions: ["1D", "1W", "1M"],
//   supported_resolutions: ['15', '30', '60', 'D', 'W', 'M'],
//   // supported_resolutions: ['5', '15', '30', '60', '120', '240', '360', '480', '720', '1D', '3D', '1W', '1M'],
//   exchanges: [
//     {
//       // `exchange` argument for the `searchSymbols` method, if a user selects this exchange
//       value: "VYNKSAFE",
//       // filter name
//       name: "VYNKSAFE",
//       // full exchange name displayed in the filter popup

//       desc: "VYNKSAFE",
//     },
//     // {
//     //   value: "Bitfinex",
//     //   name: "Bitfinex",
//     //   desc: "Bitfinex",
//     // },
//     // {
//     //   value: "Kraken",
//     //   name: "Kraken",
//     //   desc: "Kraken",
//     // },
//   ],
//   symbols_types: [
//     {
//       name: "crypto",
//       // `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
//       value: "crypto",
//     },
//     // ...
//   ],
// };

// // async function getAllSymbols() {
// //   const data = await makeApiRequest("data/v3/all/exchanges");
// //   let allSymbols = [];

// //   for (const exchange of configurationData.exchanges) {
// //     const pairs = data.Data[exchange.value].pairs;

// //     for (const leftPairPart of Object.keys(pairs)) {
// //       const symbols = pairs[leftPairPart].map((rightPairPart) => {
// //         const symbol = generateSymbol(
// //           exchange.value,
// //           leftPairPart,
// //           rightPairPart
// //         );
// //         return {
// //           symbol: symbol.short,
// //           full_name: symbol.full,
// //           description: symbol.short,
// //           exchange: exchange.value,
// //           type: "crypto",
// //         };
// //       });
// //       allSymbols = [...allSymbols, ...symbols];
// //     }
// //   }

// //   console.log(allSymbols, "this is all symbol");
// //   return allSymbols;
// // }

// async function getSymbolDetails(symbol) {
//   const allSymbols = await getSymbol(symbol);
//   // console.log(allSymbols, "this is all symbol");
//   return allSymbols.data;
// }

// export default {
//   onReady: async (callback) => {
//     // console.log("[onReady]: Method call");

//     setTimeout(() => callback(configurationData));

//   },

//   // searchSymbols: async (
//   //   userInput,
//   //   exchange,
//   //   symbolType,
//   //   onResultReadyCallback
//   // ) => {
//   //   console.log("[searchSymbols]: Method call");
//   //   const symbols = await getAllSymbols();
//   //   const newSymbols = symbols.filter((symbol) => {
//   //     const isExchangeValid = exchange === "" || symbol.exchange === exchange;
//   //     const isFullSymbolContainsInput =
//   //       symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
//   //     return isExchangeValid && isFullSymbolContainsInput;
//   //   });
//   //   onResultReadyCallback(newSymbols);
//   // },

//   resolveSymbol: async (
//     symbolName,
//     onSymbolResolvedCallback,
//     onResolveErrorCallback,
//     extension
//   ) => {

//     let symbol = symbolName.split(':')[1] || symbolName;
//     const symbolDetail = await getSymbolDetails(symbol);
//     const symbolItem = symbolDetail || {};
//     // console.log('symbolName', symbolItem)
//     // const symbols = await getSymbolDetails();
//     // const symbols = await getAllSymbols();
//     // let symbols = [{
//     //   symbol: "BNB/USDT",
//     //   full_name: "Vyncsafe:BNB/USDT",
//     //   description: "BNB/USDT",
//     //   exchange: "Vyncsafe",
//     //   type: "crypto"
//     // }]

//     // const symbolItem = symbols.find(
//     //   ({ full_name }) => full_name === symbolName
//     // );
//     if (Object.keys(symbolItem).length === 0) {
//       console.log("[resolveSymbol]: Cannot resolve symbol", symbolName);
//       onResolveErrorCallback("cannot resolve symbol");
//       return;
//     }
//     const symbolInfo = {
//       // new one for test
//       // ticker: "vync_ticker",
//       // name: "vyncsafe",
//       // description: "vync_exchange",
//       // type: "crypto",
//       // exchange: "vync_safe",

//       // @this is old

//       ticker: symbolItem.full_name,
//       id: symbolItem.id,
//       name: symbolItem.symbol,
//       description: symbolItem.description,
//       type: symbolItem.type,
//       exchange: symbolItem.exchange,
//       session: "24x7",
//       timezone: 'Asia/Kolkata',
//       minmov: 1,
//       pricescale: 10000,
//       has_intraday: true,
//       has_daily: true,
//       has_weekly_and_monthly: true,
//       // has_no_volume: true,
//       paneButtonsVisibility: 'visibleOnMouseOver',
//       // intraday_multipliers: ['1','60'],
//       // supported_resolution: ["1", "3", "5", "15", "30", "60", "120", "240", "M"],
//       supported_resolutions: configurationData.supported_resolutions,

//       volume_precision: 2,
//       data_status: "streaming",
//     };
//     // console.log("[resolveSymbol]: Method call222", symbolItem);
//     // console.log("[resolveSymbol]: Symbol resolved", symbolName);
//     onSymbolResolvedCallback(symbolInfo);
//   },

//   getBars: async (
//     symbolInfo,
//     resolution,
//     periodParams,
//     onHistoryCallback,
//     onErrorCallback
//   ) => {
//     // console.log("[getBars]: periodParams", periodParams);
//     let { from, to, countBack, firstDataRequest } = periodParams;

//     // console.log(from, to, "this is symbol info")
//     // const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
//     // const urlParameters = {
//     //   e: parsedSymbol.exchange,
//     //   // e: "Kraken",
//     //   fsym: parsedSymbol.fromSymbol,
//     //   tsym: parsedSymbol.toSymbol,
//     //   toTs: to,
//     //   limit: 300,
//     // };

//     // console.log("[getBars]: urlParameters", urlParameters);
//     // let from = 1649721600,
//     // let to=1675728000
//     // const query = Object.keys(urlParameters)
//     //   .map((name) => `${name}=${encodeURIComponent(urlParameters[name])}`)
//     //   .join("&");
//     // console.log("[getBars]: query", query);
//     try {
//       // const data = await makeApiRequest(`data/histoday?${query}`);
//       // @ after this pair make dynamic
//       let pair = symbolInfo?.id
//       // if (/firstDataRequest) {
//         // from = new Date('2022-01-01').getTime() / 1000;
//         // to = Math.floor(new Date().getTime() / 1000);
//       // }
//       const data = await getKlineBars(pair, resolution, 500, from, to);
//       // const data = await getKlineBars('1','1d',to);
//       // console.log("[getBars]: Our kline data", data)

//       // console.log("[getBars]: data", data);
//       if (
//         (data.Response && data.Response === "Error") ||
//         data.Data.length === 0
//       ) {
//         // "noData" should be set if there is no data in the requested period.
//         onHistoryCallback([], {
//           noData: true,
//         });
//         return [];
//       }
//       let bars = [];

//       // bars = data.Data.map(bar => {
//       //   if (bar.time >= from && bar.time <= to) {
//       //     return {
//       //       time: bar.time * 1000,
//       //       low: bar.low,
//       //       high: bar.high,
//       //       open: bar.open,
//       //       close: bar.close,
//       //     };
//       //   }
//       // })

//       data.Data.forEach((bar) => {
//         if (parseFloat(bar.time) >= parseFloat(from) && parseFloat(bar.time) <= parseFloat(to)) {
//           bars = [
//             ...bars,
//             {
//               time: bar.time * 1000,
//               low: bar.low,
//               high: bar.high,
//               open: bar.open,
//               close: bar.close,
//             },
//           ];
//         }
//       });

//       if (firstDataRequest) {
//         lastBarsCache.set(symbolInfo.full_name, {
//           ...bars[bars.length - 1],
//         });
//       }
//       // console.log(`[getBars]: returned bars`, bars[bars.length - 1]);
//       // console.log(`[getBars]: returned ${bars.length} bar(s)`);
//       onHistoryCallback(bars, {
//         // noData: false,
//         noData: !bars.length,
//       });
//       return bars;
//     } catch (error) {
//       console.log("[getBars]: Get error", error);
//       onErrorCallback(error);
//       return [];
//     }
//   },

//   subscribeBars: async (
//     symbolInfo,
//     resolution,
//     onRealtimeCallback,
//     subscriberUID,
//     onResetCacheNeededCallback
//   ) => {
//     // console.log(
//     //   "[subscribeBars]: Method call with subscriberUID:",
//     //   subscriberUID
//     // );
//     subscribeOnStream(
//       symbolInfo,
//       resolution,
//       onRealtimeCallback,
//       subscriberUID,
//       onResetCacheNeededCallback,
//       lastBarsCache.get(symbolInfo.full_name)
//     );
//   },

//   unsubscribeBars: async (subscriberUID) => {
//     unsubscribeFromStream(subscriberUID);
//     // console.log(
//     //   "[unsubscribeBars]: Method call with subscriberUID:",
//     //   subscriberUID
//     // );
//   },
// };
