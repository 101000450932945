import React, { useEffect, useState } from 'react'
import Select from "react-select";
import { Form, Row, Col } from 'react-bootstrap'
import BASE_URL from "../../_constant/index";
import { Link } from 'react-router-dom'

const WithdrawSection = ({ withdrawLimit, loading, setErrors, sendType, selectedNetwork, networksList, coinData, withdrawCoinData, changeWithdrawTo, changeWithdrawAmount, changeWithdrawCurrency, changeSelectValue, error, setErrorMsg, handelWithdrawTxn, userEmailConformation, Emailerror }) => {
  const [errors, setError] = useState(false)


  const handleError = () => {
    if (parseFloat(withdrawCoinData?.amount) > parseFloat(coinData.balance)) {
      setError(true)
    } else if (parseFloat(withdrawCoinData?.amount) <= 0) {
      setError(false)
    }
    else {
      setError(false)
    }
  }

  const setMaxValue = () => {
    changeWithdrawAmount({
      target: {
        value: coinData?.balance
      }
    });
  };
  useEffect(() => {
    handleError()
  }, [withdrawCoinData?.amount])

  let minWithdraw = 0.00;
  let maxWithdraw = 0.00;
  if (sendType.value === "address" && selectedNetwork !== null) {
    minWithdraw = selectedNetwork?.min_withdraw;
    maxWithdraw = selectedNetwork?.max_withdraw;
  } else if (sendType.value === "address" && selectedNetwork === null) {
    minWithdraw = 0.00;
    maxWithdraw = 0.00;
  }
  else {
    minWithdraw = coinData?.internal_min_value;
    maxWithdraw = coinData?.internal_max_value;
  }

  const pasteButton = async () => {
    try {
      const pasteContent = await window.navigator.clipboard.readText();
      changeWithdrawTo({ target: { value: pasteContent.trim() } });
    } catch (error) {
      console.error('Failed to read clipboard text:', error);
    }
  };

  return (
    <div className="with_draw">
      <Row>
        <Col md={3}>
          <div className="mb-2 withdeow_title pl-4" style={{ position: "absolute", top: "-28px" }}>
            <h5 className="card-small-title pl-2">Withdraw To</h5>
          </div>
        </Col>
        <Col md={9}>
          <div className=" mt-3" >
            <div className={`form-group market_trade_input_block withdraw-amount-input-block position-relative`}>
              <input
                type={sendType?.value === "email" ? "email" : "text"}
                className="form-control autofill"
                id="withdrawTo"
                name="withdrawTo"
                placeholder={`Enter ${sendType?.name} here...`}
                value={
                  withdrawCoinData?.withdrawTo ? withdrawCoinData?.withdrawTo : ""
                }
                onChange={(e) => {
                  changeWithdrawTo(e);
                  localStorage.setItem("withdrawTo", e.target.value);
                  setErrors(null)
                }}
                onBlur={sendType?.value !== "address" ? () => userEmailConformation() : null}

              />

              {sendType.value !== "phone" &&
                < button className="btn btn-info" title='Paste' onClick={pasteButton} style={{ right: "16px", top: "6px" }}>
                  <i className="ion-ios-document mt-0" style={{ cursor: "pointer", fontSize: "20px" }}></i>
                </button>
              }
            </div>
            <small className="red">{Emailerror ? Emailerror : error?.addressError}</small>
          </div>

          {sendType.value === "address" ? (
            <div className=" mt-3" >
              <div className="form-group">
                {/* <label htmlFor="withdrawAmount">Withdraw Network</label> */}
                <div
                  className={`form-group  ${error?.NetworkError != "" ? "active" : ""
                    }`}
                >
                  <Select
                    className="wallet_network_select"
                    classNamePrefix="custom_select"
                    isSearchable={true}
                    name="network"
                    placeholder="Select a Network"
                    value={selectedNetwork}
                    onChange={(e) => {
                      changeSelectValue(e);
                      setErrorMsg((preState) => {
                        let obj = Object.assign({}, preState);
                        obj.NetworkError = "";
                        return obj;
                      });
                    }}
                    defaultValue={networksList[0]}
                    options={networksList}
                  />
                </div>
              </div>
              <small className="red"> {error?.NetworkError}</small>
            </div>
          ) : null}
          {/* {sendType.value !== "address" ? (<>SHOW DIV</>) : (<>DONT SHOW DIV</>)} */}
          {/* {sendType.value === "address" ? (<>
            {selectedNetwork !== null ? (<>
              <div className="elect-coin-avaialble-balance mt-2">
                <div className="d-flex align-items-start flex-wrap justify-content-between">
                  <p className="text-secondary">Minimum Withdrawal<br /><h6 className="card-avaialble-balance mt-1 text-uppercase">$ {selectedNetwork?.min_withdraw}</h6></p>
                  <p className="text-right text-secondary">Maximum Withdrawal<br /><h6 className="card-avaialble-balance text-uppercase mt-1">$ {selectedNetwork?.max_withdraw}</h6></p>
                </div>
                <div className="d-flex align-items-start flex-wrap justify-content-between">
                </div>
              </div>
            </>) : null}
          </>) : (<>
            <div className="elect-coin-avaialble-balance mt-2">
              <div className="d-flex align-items-start flex-wrap justify-content-between">
                <p className="text-secondary">Minimum Withdrawal<br /><span className="card-avaialble-balance mt-1 mb-2 text-uppercase" >$ {coinData?.internal_min_value}</span></p>
                <p className="text-right text-secondary">Maximum Withdrawal<br /><span className="card-avaialble-balance mt-1 mb-2 text-uppercase">$ {coinData?.internal_max_value}</span></p>
              </div>
              <div className="d-flex align-items-start flex-wrap justify-content-between">
                 </div>
            </div>
          </>)} */}
        </Col>
      </Row>
      {/* <div className="wallet-address pt-4" style={{ borderTop: "1px solid rgba(94, 114, 143, 0.3)" }}> */}
      <Row className="wallet-address pt-4" style={{ borderTop: "1px solid rgba(94, 114, 143, 0.3)" }}>
        <Col md={3} className="pl-4">
          <h5 className="card-small-title pl-4">Withdraw Amount</h5>
        </Col>
        <Col md={9}>
          <div className="form-group">
            <label className="d-flex flex-wrap justify-content-between" htmlFor="withdrawAmount">Amount <span>$ {withdrawLimit?.remaining_withdraw_limit}/$ {withdrawLimit?.daily_withdraw_limit}{" "} Daily remaining limit</span></label>
            <div
              className={`form-group market_trade_input_block withdraw-amount-input-block position-relative`}
            >
              <input
                type="text"
                className="form-control"
                id="withdrawAmount"
                name="withdrawAmount"
                placeholder={`Enter Amount (${coinData?.symbol})`}
                value={withdrawCoinData?.amount ? withdrawCoinData?.amount : ""}
                onChange={(e) => {
                  changeWithdrawAmount(e);
                  handleError()
                }}

              />
              <button className="btn btn-info" onClick={setMaxValue}>
                MAX
              </button>
            </div>
            {error?.amountError ? (<>
              <small className="red"> {error?.amountError}</small>
            </>) : (<>
              {errors && <Form.Text className="text-danger">
                Your Balance is low to transfer, your Balance is {coinData.balance}
              </Form.Text>}

            </>)}
            <div className="withdraw-amount-select-wallet d-flex justify-content-between">
              <div>
                {/* <Form.Check
                    type="radio"
                    label="Spot Wallet"
                    value="option1"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios1"
                    defaultChecked // Use defaultChecked instead of checked
                    onChange={handleRadioChange}
                  /> */}
                {/* <Form.Check
                    className="mt-3"
                    type="radio"
                    label="Funding Wallet"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios2"
                  /> */}
              </div>
              <div className="transfer_icon_section" ><Link to={`${BASE_URL.BASE_URL}Mywallet/Overview?to=transfer`}><img src={`${BASE_URL.BASE_URL}img/dashboard/Transfer 2.png`} alt="transfer" className="mr-1" width={"25px"}/>Transfer</Link></div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="withdrawAmount">Amount ($)</label>
            <div className={`form-group market_trade_input_block ${error?.amountError != "" ? "active" : ""}`}>
              <input
                type="text"
                className="form-control"
                id="withdrawAmount"
                name="withdrawAmount"
                placeholder="Enter Amount ($)"
                value={
                  withdrawCoinData?.amount_currency
                    ? withdrawCoinData?.amount_currency
                    : ""
                }
                onChange={(e) => {
                  changeWithdrawCurrency(e);
                }}
              />
            </div>
            <small className="red"> {error?.currencyError}</small>
          </div>
        </Col>

        {/* <div className="" >
          <div className="form-group">
                <label htmlFor="withdrawAmount">Amount ({coinData?.symbol})</label>

                <div
                  className={`form-group market_trade_input_block ${error?.amountError != "" ? "active" : ""
                    }`}
                >
                  <input
                    type="text"
                    className="form-control"
                    id="withdrawAmount"
                    name="withdrawAmount"
                    placeholder="Enter Amount"
                    value={withdrawCoinData?.amount ? withdrawCoinData?.amount : ""}
                    onChange={(e) => {
                      changeWithdrawAmount(e);
                      handleError()
                    }}
                  />
                </div>
                {error?.amountError ? (<>
                  <small className="red"> {error?.amountError}</small>
                </>) : (<>
                  {errors && <Form.Text className="text-danger">
                    Your Balance is low to transfer, your Balance is {coinData.balance}
                  </Form.Text>}

                </>)}
              </div>
        </div> */}

        {/* <div className=" " >
            <div className="form-group">
              <label htmlFor="withdrawAmountCurrency">Amount ($)</label>
              <div
                className={`market_trade_input_block ${error?.currencyError != "" ? "active" : ""
                  }`}
              >
                <input
                  type="text"
                  className={`form-control`}
                  id="withdrawAmount"
                  name="withdrawAmount"
                  placeholder="0.00"
                  value={
                    withdrawCoinData?.amount_currency
                      ? withdrawCoinData?.amount_currency
                      : ""
                  }
                  onChange={(e) => {
                    changeWithdrawCurrency(e);
                  }}
                />
              </div>

              <small className="red"> {error?.currencyError}</small>
            </div>
          </div> */}


        {/* <Col md={4} className="mt-4">
          < div className="settings_top_title mt-4 d-flex flex-wrap justify-content-between align-items-center WithDrawal_Right_Card">
            <div>
              <p className="card-title" style={{ fontSize: "14px" }}>Minimum Withdrawal :</p>
            </div>
            <div>
              <span className="card-title" style={{ fontSize: "14px", color: 'grey' }}>$ {minWithdraw}</span>
            </div>
            <div>
              <p className="card-title" style={{ fontSize: "14px" }}>Maximum Withdrawal :</p>
            </div>
            <div>
              <span className="card-title" style={{ fontSize: "14px", color: 'grey' }}>$ {maxWithdraw}</span>
            </div>
          </div >
        </Col> */}
      </Row>

      <Row className="wallet-address pt-4 mt-3" style={{ borderTop: "1px solid rgba(94, 114, 143, 0.3)" }}>
        <Col md={3} className="md-3 mb-2 pr-1">
          <h5 className="card-small-title pl-4 ml-2">Total Amount</h5>
        </Col>

        <Col md={9}>
          <div className="withdraw-total-amount">
            <h5>{withdrawCoinData?.amount ? withdrawCoinData?.amount : "0.00"} {coinData.symbol}</h5>
            <p>${withdrawCoinData?.amount_currency ? withdrawCoinData?.amount_currency : "0.00"}</p>
          </div>

          <div className="mt-3">
            {/* handelWithdrawTxn */}
            <button className="btn btn-primary btn-withdraw-w" disabled={loading ? true : false} variant="primary" onClick={handelWithdrawTxn}>
              {loading ? ("Please Wait...") : "Withdraw"}
            </button>
          </div>

        </Col>
      </Row>

      {/* </div> */}

    </div >
  );
};

export default WithdrawSection;

























