import { useState, useEffect, useMemo } from "react";
import { orderBookAction } from "../../redux/apiActions/api.action";
// import { orderBookAction } from "../redux/apiActions/api.action";
import utill from "../../_constant/utill";
import Loader from "../loader";
// import io from 'socket.io-client';

export default function OrderBook({
  pair,
  setTradePrice,
  setTradeAmount,
  // setTradeType,
  order_Price,
  socket,
}) {
  useMemo(() => {
    if (pair?.id) {
      socket?.emit("orderbook_leave", {
        pair_id: Number(pair?.id),
      });
    }
  }, [pair]);

  const [tradeData, setTradeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [askBgData, setAskBgData] = useState([]);
  const [bidBgData, setBidBgData] = useState([]);
  // Orderbook

  useEffect(() => {
    async function fetchOrder() {
      setLoading(true);
      if (pair?.id) {
        const result = await orderBookAction(pair?.id);
        if (result !== false) {
          setTradeData(result?.data?.data);
          if (result?.data?.data?.ask) setAskBgData(setPerCentage(result?.data?.data?.ask));
          if (result?.data?.data?.bid) setBidBgData(setPerCentage(result?.data?.data?.bid));
        }
        setLoading(false);
      }
    }
    fetchOrder();
  }, [pair, setTradeData, setAskBgData, setBidBgData]);

  const setPerCentage = (dataArr) => {
    const valueArray = dataArr?.map(array => array[2]);
    const maxValue = Math.max(...valueArray);
    const Percentage = valueArray?.map((number) => {
      let i = (number) / (maxValue) * 100
      return (Math.round(i / 5) * 5)
    });
    return Percentage;
  }

  useEffect(() => {
    if (pair?.id) {
      socket?.emit("get_orderbook", {
        pair_id:
          pair?.symbol === "BNBETH TEST" ? Number(pair?.id) : Number(pair?.id),
      });
      socket?.on("received_orderbook", (data) => {
        if (data?.data?.data) setTradeData(data?.data?.data);
        if (data?.data?.data?.ask) setAskBgData(setPerCentage(data?.data?.data?.ask));
        if (data?.data?.data?.bid) setBidBgData(setPerCentage(data?.data?.data?.bid));
      });
    }
  }, [pair, setTradeData, setAskBgData, setBidBgData]);

  let change = String(order_Price?.changes).charAt(0);

  return (
    <>
      <div className="order-book">
        <h2 className="heading" style={{ padding: "10px 10px 3px 16px" }}>Order Book</h2>
        <div >
          <div className="order_book order_book_left_top">
            <table className="table">
              <thead>
                <tr>
                  <th>Price({pair?.quote})</th>
                  <th>Amount({pair?.base})</th>
                  <th>Total({pair?.quote})</th>
                </tr>
              </thead>
            </table>
            <div className="order_book orderbook_scroll" >
            <OrderBookSection
              loading={loading}
              tradeData={tradeData?.ask}
              bgColor={askBgData}
              type="SELL"
              setTradePrice={setTradePrice}
              setTradeAmount={setTradeAmount}
            />
            </div>
          </div>
          <div className="order_book order_book_left_top">
          <table className="table" >
            <tbody className="ob-heading">
              <tr>
                <td>
                  <span>Price({pair?.quote})</span>
                  {utill.valueFix(order_Price?.usd_price)}
                </td>
                <td className="ob_align_td">
                  <span>Price($)</span>
                  {utill.valueFix(order_Price?.price)}
                </td>
                <td className={change === "-" ? `red` : "green"}>
                  <span>Change</span>
                  {change === "-" ? "" : "+"}
                  {utill.beautifyNumber(order_Price?.changes, 2)}%
                </td>
              </tr>
            </tbody>
          </table>

          <div className="order_book orderbook_scroll" >
            <OrderBookSection
              loading={loading}
              tradeData={tradeData?.bid}
              bgColor={bidBgData}
              type="BUY"
              setTradePrice={setTradePrice}
              setTradeAmount={setTradeAmount}
            />
          </div>
          </div>
        </div>
      </div>
    </>
  );
}

const OrderBookSection = ({ tradeData, bgColor, type, setTradePrice, setTradeAmount, loading }) => {
  let typetext = type == 'BUY' ? "SELL" : "BUY"
  return <table className="table">
    <tbody className={tradeData?.length > 0 ? "" : "f_tbody"}>
      {loading ?
        (<Loader />) :
        (<>
          {tradeData?.length > 0 ? (
            tradeData?.map((dta, key) => {
              return (
                <tr className={type == 'BUY' ? `green-bg-${bgColor[key]}` : `red-bg-${bgColor[key]}`} key={key + type}>
                  <td
                    className={type === "BUY" ? "green" : "red"}
                    onClick={() => {
                      setTradePrice(utill.valueFix(dta[0]));
                      // setTradePrice(dta[0]);
                    }}
                  >
                    {/* {utill.valueFix(dta[0])} */}
                    {dta[0]}

                  </td>
                  {/* {console.log("dta[0]",dta[0])} */}
                  <td
                    onClick={() => {
                      setTradeAmount(
                        // utill.beautifyNumber(dta[1]),
                        // utill.valueFix(dta[0]),
                        dta[1],
                        dta[0],
                        typetext
                      );
                    }}
                  >
                    {utill.beautifyNumber(dta[1])}
                  </td>

                  <td>{utill.beautifyNumber(dta[2])}</td>
                </tr>
              );
            })
          ) : (
            <tr className="no_data_tr">
              <td colSpan="12" className="no-data">
                <i className="icon ion-md-document"></i>
                No data
              </td>
            </tr>
          )}
        </>)
      }
    </tbody>
  </table>
}
