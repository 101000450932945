import React from "react";
import Header from "./Header";
import NewHeader from "./NewHeader";
import Footer from "../VynksafeNew/pages/Footer";
import ShortFooter from "../VynksafeNew/ShortFooter";

export default function Layout({ children, notificationData, show, readNotification, notification }) {
  return (
    <>
      {/* <Header notificationData={notificationData} show={show} readNotification={readNotification} notification={notification} /> */}
      <NewHeader notificationData={notificationData} show={show} readNotification={readNotification} notification={notification} />
      <div style={{ marginTop: "65px" }}>
        {children}
      </div>
      {/* <ShortFooter /> */}
    </>
  );
}
