import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BASE_URL from "../../_constant/index";
import { Link } from "react-router-dom";

const Connectivity = () => {
  return (
    <Container>
      <div className="d-flex justify-content-center">
        <div>
          <div className="d-flex justify-content-center">
            <img
              src={
                BASE_URL.BASE_URL +
                "/img/Vynksafe_Api/Trading Connectivity Icon.png"
              }
              className="img-fluid dark-theme-img"
            />
            <img
              src={
                BASE_URL.BASE_URL +
                "/img/Vynksafe_Api/Trading Connectivity Icon light.png"
              }
              className="img-fluid light-theme-img"
            />
          </div>

          <div className="d-flex justify-content-center">
            <h1 className="mb-2 text-center">
            Trade Beyond {" "}
              <span className="text-orange fw-1" style={{ fontSize: "2.5rem" }}>
                {" "}
                Limits{" "}
              </span>
            </h1>
          </div>
          <div className="d-flex justify-content-center text-center">
            <p>
            We provide connectivity for Spot. API trading across 300+ digital and fiat currencies. Our API trading features <br/> a testing  environment, comprehensive API documentation, and sample code  available in various programming <br/>   languages. Tailored to meet all your trading requirements, making it easy and accessible.
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <img
              src={
                BASE_URL.BASE_URL +
                "/img/Vynksafe_Api/Trading Connectivity Vector.png"
              }
              className="img-fluid dark-theme-img"
              width={"500px"}
            />
            <img
              src={
                BASE_URL.BASE_URL +
                "/img/Vynksafe_Api/Trading Connectivity Vector light.png"
              }
              className="img-fluid light-theme-img"
              width={"500px"}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <div style={{ width: "80%" }}>
          <Row>
            <Col md={6} className="Spot_Trading p-4">
              <h4 className="mb-4">Spot Trading</h4>
              <div className="d-flex flex-wrap">
                <Link className="Spot_Trading_link mr-3 mb-3 d-flex align-items-center">
                  <div className="image_background">
                    <img
                      src={BASE_URL.BASE_URL + "img/Vynksafe_Api/Spot.png"}
                      width={"40px"}
                      className="img-fluid dark-theme-img"
                    />
                    <img
                      src={
                        BASE_URL.BASE_URL + "img/Vynksafe_Api/Spot light.png"
                      }
                      width={"40px"}
                      className="img-fluid light-theme-img"
                    />
                  </div>
                  Spot
                </Link>
                <Link className="Spot_Trading_link mr-3 mb-3 d-flex align-items-center">
                <div className="image_background">
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/Margin.png"}
                    width={"40px"}
                    className="img-fluid dark-theme-img"
                  />
                  <img
                    src={
                      BASE_URL.BASE_URL + "img/Vynksafe_Api/Margin light.png"
                    }
                    width={"40px"}
                    className="img-fluid light-theme-img"
                  />
                  </div>
                  Margin
                </Link>
                <Link className="Spot_Trading_link mr-3 mb-3 d-flex align-items-center">
                <div className="image_background">
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/Convert.png"}
                    width={"40px"}
                    className="img-fluid dark-theme-img"
                  />
                  <img
                    src={
                      BASE_URL.BASE_URL + "img/Vynksafe_Api/Convert light.png"
                    }
                    width={"40px"}
                    className="img-fluid light-theme-img"
                  />
                  </div>
                  Convert
                </Link>
              </div>
            </Col>
            <Col md={6} className="Derivatives_Trading p-4">
              <h4 className="mb-4">Derivatives Trading</h4>
              <div className="d-flex flex-wrap">
                <Link className="Spot_Trading_link mr-3 mb-3 d-flex align-items-center">
                <div className="image_background">
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/Fiat.png"}
                    width={"40px"}
                    className="img-fluid dark-theme-img"
                  />
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/Fiat light.png"}
                    width={"40px"}
                    className="img-fluid light-theme-img"
                  />
                  </div>
                  USDⓈ-M
                </Link>
                <Link className="Spot_Trading_link mr-3 mb-3 d-flex align-items-center">
                <div className="image_background">
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/COIN-M.png"}
                    width={"40px"}
                    className="img-fluid dark-theme-img"
                  />
                  <img
                    src={
                      BASE_URL.BASE_URL + "img/Vynksafe_Api/COIN-M light.png"
                    }
                    width={"40px"}
                    className="img-fluid light-theme-img"
                  />
                  </div>
                  COIN-M
                </Link>
                <Link className="Spot_Trading_link mr-3 mb-3 d-flex align-items-center">
                <div className="image_background">
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/BLVT.png"}
                    width={"40px"}
                    className="img-fluid dark-theme-img"
                  />
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/BLVT light.png"}
                    width={"40px"}
                    className="img-fluid light-theme-img"
                  />
                  </div>
                  BLVT
                </Link>
                <Link className="Spot_Trading_link mr-3 mb-3 d-flex align-items-center">
                <div className="image_background">
                  <img
                    src={
                      BASE_URL.BASE_URL + "img/Vynksafe_Api/Vanilla Options.png"
                    }
                    width={"40px"}
                    className="img-fluid dark-theme-img"
                  />
                  <img
                    src={
                      BASE_URL.BASE_URL +
                      "img/Vynksafe_Api/Vanilla Options light.png"
                    }
                    width={"40px"}
                    className="img-fluid light-theme-img"
                  />
                  </div>
                  Vanila Options
                </Link>
                <Link className="Spot_Trading_link mr-3 mb-3 d-flex align-items-center">
                <div className="image_background">
                  <img
                    src={
                      BASE_URL.BASE_URL + "img/Vynksafe_Api/Futures Algo.png"
                    }
                    width={"40px"}
                    className="img-fluid dark-theme-img"
                  />
                  <img
                    src={
                      BASE_URL.BASE_URL +
                      "img/Vynksafe_Api/Futures Algo light.png"
                    }
                    width={"40px"}
                    className="img-fluid light-theme-img"
                  />
                  </div>
                  Futures Algo
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default Connectivity;
