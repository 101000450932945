import React from 'react'
import BASE_URL from "../../_constant/index";
import utill from "../../_constant/utill";
import { Link } from 'react-router-dom'

const Overview = ({ totalBtc, createDoller, totaldoller, btcCoinPrice }) => {

    const createSumm = createDoller / btcCoinPrice

    return (

        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-body">
                        <div className="settings_top_title d-flex flex-wrap justify-content-between align-items-center">
                            <div>
                                <h5 className="card-title">Estimated Balance</h5>
                            </div>
                        </div>
                        <div className="card-estimated-balance-section mtb15 pb-3 d-flex" style={{ borderBottom: "1px solid #5e728f4d" }}>
                            <h3 className="card-estimated-balance" style={{ textTransform: "uppercase" }}>
                                {utill.beautifyNumber(createSumm + totalBtc, 4)}
                                {" "}BTC
                                <span className="text-secondary">≈ ${utill.beautifyNumber(totaldoller + createDoller, 2)}</span>
                            </h3>
                        </div>

                        <div>
                            <h5 className="card-title">My Assets</h5>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="myassets_card card-body">
                                    <p>Fiat and Spot
                                        <Link to={`${BASE_URL.BASE_URL}Mywallet/Spot`} className="arrow_button" style={{ color: "#fa501d" }}>
                                            <i className="ion ion-ios-arrow-forward"></i>
                                        </Link>
                                    </p>
                                    <h5>{utill.beautifyNumber(totalBtc, 4)} BTC</h5>
                                    <p>≈ {utill.beautifyNumber(totaldoller, 2)} USD</p>
                                    <img src={`${BASE_URL.BASE_URL}img/dashboard/Fiat and Spot.png`} alt="fiat_and_spot" width={"50px"}/>
                                </div>
                            </div>

                            <div className="col-md-6 mt-3 mt-md-0">
                                <div className="myassets_card card-body">
                                    <p>Funding  <Link to={`${BASE_URL.BASE_URL}Mywallet/Funding`}  className="arrow_button" style={{ color: "#fa501d" }}><i className="ion ion-ios-arrow-forward"></i> </Link></p>
                                    <h5>{utill.beautifyNumber(createDoller / btcCoinPrice, 4)} BTC</h5>
                                    <p>≈ {utill.beautifyNumber(createDoller, 2)} USD</p>
                                    <img src={`${BASE_URL.BASE_URL}img/dashboard/Funding.png`}width={"50px"} alt="Funding" />
                                </div>
                            </div>
                            {/* <div className="col-md-6 mt-3">
                                    <div className="myassets_card card-body">
                                        <p>Cross Margin <i className="ion ion-ios-arrow-forward"></i></p>
                                        <h5>0.000000000 BTC</h5>
                                        <p>≈ 0.054564 USD</p>
                                        <img src={`${BASE_URL.BASE_URL}img/wallet-overview/cross-margin.png`} alt="Cross Margin" />
                                    </div>
                                </div>

                                <div className="col-md-6 mt-3">
                                    <div className="myassets_card card-body">
                                        <p>Isolated Margin <i className="ion ion-ios-arrow-forward"></i></p>
                                        <h5>0.000000000 BTC</h5>
                                        <p>≈ 0.054564 USD</p>
                                        <img src={`${BASE_URL.BASE_URL}img/wallet-overview/isolated-margin.png`} alt="Isolated Margin" />
                                    </div>
                                </div>

                                <div className="col-md-6 mt-3">
                                    <div className="myassets_card card-body">
                                        <p>USD-M-Futures <i className="ion ion-ios-arrow-forward"></i></p>
                                        <h5>0.000000000 BTC</h5>
                                        <p>≈ 0.054564 USD</p>
                                        <img src={`${BASE_URL.BASE_URL}img/wallet-overview/usd-futures.png`} alt="usd-futures" />
                                    </div>
                                </div>

                                <div className="col-md-6 mt-3">
                                    <div className="myassets_card card-body">
                                        <p>COIN-M-Futures <i className="ion ion-ios-arrow-forward"></i></p>
                                        <h5>0.000000000 BTC</h5>
                                        <p>≈ 0.054564 USD</p>
                                        <img src={`${BASE_URL.BASE_URL}img/wallet-overview/coin_futures.png`} alt="coin_futures" />
                                    </div>
                                </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="col-md-4">
                    <div className="card-body px-0">
                        <div className="settings_top_title overview_right_recenttransactions">
                            <h5 className="card-title">Recent Transactions</h5>
                            <button className="order_cancel_btn">View All</button>
                        </div>
                    </div>

                    <div className="card mt-4">
                        <div className="card-body">
                            <div className="overview_no_open_orders text-center">
                                <div>
                                    <img src={`${BASE_URL.BASE_URL}img/wallet-overview/no-open-order.png`} alt="no-open-order" />
                                    <p className="mt-2">No open orders</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                */}

        </div>




    )
}

export default Overview