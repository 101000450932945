import React, { useEffect, useState } from "react";
import { Row, Container } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import CreateNewOrder from "./CreateNewOrder";
// import EmptyData from '../emptyData';
import { Link, useLocation } from "react-router-dom";
import BASE_URL from "../../_constant/index";
import moment from "moment";
import LoginFirst from "../loginFirst";
import { useSelector } from "react-redux";
import utill from "../../_constant/utill";

const Order = ({
  loading,
  paymentMethods,
  tradeData,
  setTradeActive,
  handlePage,
  handlePerPage,
  page,
  showSpinner,
  toValue,
  countryList,
  currency,
}) => {
  let user_Data = useSelector((state) => state.user.userData);
  const outputFormat = "DD MMM YYYY HH:mm a";
  const [activeTab, setActiveTab] = useState("ActiveTrades");
  const [showOrder, setShowOrder] = useState(false);
  const location = useLocation();

  const routeName = location.pathname;
  const returnToUrl = `${BASE_URL.BASE_URL.slice(0, -1)}${routeName}`;

  const [activeNumberNumber, setActiveNumber] = useState(1);
  const itemsPerPage = 10;
  const startIndex = (activeNumberNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const ActiveData = tradeData?.active_trade?.slice(startIndex, endIndex);

  const handleNextButton = () => {
    if (ActiveData?.length > 9) {
      setActiveNumber(activeNumberNumber + 1);
    }
  };
  const handlePreviusButton = () => {
    setActiveNumber(activeNumberNumber - 1);
  };

  useEffect(() => {
    if (toValue == "p2p_create_offer") {
      setShowOrder(true);
    } else {
      setShowOrder(false);
    }
  }, [toValue]);

  useEffect(() => {
    setTradeActive(activeTab);
  }, []);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setTradeActive(tabName);
  };

  return (
    <>
      {!showOrder ? (
        <>
          <section className="dark-gray-bg-v">
            <Container>
              <Row>
                <div className="p2p_filter_section justify-content-between d-md-flex w-100 mt-1 align-items-center pb-5">
                  <div className="tab active_trades_left_btn">
                    <button
                      className={`tablinks ${
                        activeTab === "ActiveTrades" ? "active" : ""
                      } mb-2`}
                      onClick={() => handleTabClick("ActiveTrades")}
                    >
                      Active Trades
                    </button>
                    <button
                      className={`tablinks ${
                        activeTab === "PastTrades" ? "active" : ""
                      } mb-2`}
                      onClick={() => handleTabClick("PastTrades")}
                    >
                      Past Trades
                    </button>
                  </div>

                  <div className="active_trades_right_btn d-flex flex-wrap">
                    <Link
                      to={`${BASE_URL.BASE_URL}p2p/All`}
                      className="find_an_offer_btn theme-btn-2 theme-btn text-center mr-2"
                    >
                      {" "}
                      Find an offer
                    </Link>
                    {user_Data?.access_token && (
                      <Link
                        to={`${BASE_URL.BASE_URL}p2p/Orders?to=p2p_create_offer`}
                        className="create_new_offer_btn theme-btn-2 theme-btn text-center create_offer_btn"
                      >
                        {" "}
                        Create new offer{" "}
                      </Link>
                    )}
                  </div>
                </div>
              </Row>
            </Container>
          </section>
          <section className="p2p_main_banner_section pt-5 p2p_mainTable">
            <Container>
              <Row
                className={`row tabcontent ${
                  activeTab === "ActiveTrades" ? "show" : "hide"
                }`}
                id="ActiveTrades"
              >
                <div className="p2p_filter_section mb-4">
                  <h5>Active Trades</h5>
                </div>

                <div className="active_trades_table_mobile_scroll">
                  <table
                    id="PastTradesTable"
                    className="table table-striped w-100"
                  >
                    <thead>
                      <tr>
                        <th>Date Opened</th>
                        <th>Offer UID</th>
                        <th>Type</th>
                        <th>Crypto</th>
                        <th>Amount</th>
                        <th>Trade Partner</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody id="activeTradesTable">
                      {!tradeData?.active_trade?.length > 0 ? (
                        <tr>
                          <td colSpan={8}>
                            <div
                              className="overview_no_open_orders"
                              style={{ width: "100%" }}
                            >
                              {!user_Data?.access_token ? (
                                <LoginFirst
                                  return_to={btoa(`${returnToUrl}`)}
                                />
                              ) : (
                                <>
                                  {showSpinner ? (
                                    <Spinner
                                      animation="border"
                                      variant="secondary"
                                    />
                                  ) : (
                                    <>
                                      <div
                                        className="col-12 mb-5 d-flex justify-content-center mt-5"
                                        style={{ height: "100px" }}
                                      >
                                        <div
                                          className="no-data"
                                          style={{ color: "#9ea4ab" }}
                                        >
                                          <i
                                            className="icon ion-md-document"
                                            style={{ color: "#9ea4ab" }}
                                          ></i>
                                          No data
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      ) : (
                        ActiveData?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {moment(
                                  data?.created_date,
                                  "YYYY-MM-DD HH:mm:ss"
                                ).format(outputFormat)}
                                {/* {data?.created_date} */}
                              </td>
                              {/* <td>2023-11-08 04:56 PM</td> */}
                              <td>
                                {" "}
                                {`${data?.offer_uid?.substring(
                                  0,
                                  5
                                )}...${data?.offer_uid?.slice(-7)}`}
                              </td>
                              <td>{data?.type}</td>
                              <td>
                                {utill.beautifyNumber(
                                  parseFloat(data?.coin_value),
                                  18
                                )}{" "}
                                {data?.coin?.toUpperCase()}
                              </td>
                              <td>
                                {data?.currency_symbol}{" "}
                                {parseFloat(data?.value_currency)}
                              </td>
                              <td>
                                <a href="#" target="_blank">
                                  {data?.trade_partner}
                                </a>
                              </td>
                              <td
                                className={`${
                                  data?.status_msg == "Payment made"
                                    ? "payment_made"
                                    : "pending"
                                }`}
                              >
                                {data?.status_msg}
                              </td>
                              <td>
                                <Link
                                  to={`${BASE_URL.BASE_URL}p2p/Orders?to=p2p_my_trade&tradeid=${data?.id}`}
                                  className="text-white trade_view d-flex align-items-center"
                                >
                                  View{" "}
                                  <i
                                    className="fa fa-angle-right ml-2"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
            
                {ActiveData?.length >9 && (
                  <div className="col-12 mb-5 d-flex justify-content-between mt-3 pb-3">
                    {activeNumberNumber == 1 ? (
                      <>
                        <button
                          type="button"
                          // disabled={loading ? true : false}
                          onClick={handleNextButton}
                          className="load_more_btn theme-btn-2 theme-btn mt-3 float-left"
                          id="loadPastTrans"
                        >
                          Next
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          // disabled={loading ? true : false}
                          type="button"
                          onClick={handlePreviusButton}
                          className="load_more_btn theme-btn-2 theme-btn mt-3 float-left"
                          id="loadOffer"
                        >
                          Previous
                        </button>
                        {ActiveData?.length > 9 && (
                          <button
                            // disabled={(ActiveData.length < 9) ? true : false}
                            type="button"
                            onClick={handleNextButton}
                            className="load_more_btn theme-btn-2 theme-btn mt-3 float-left"
                            id="loadOffer"
                          >
                            Next
                          </button>
                        )}
                      </>
                    )}
                  </div>
                )}
              </Row>

              <div
                id="PastTrades"
                className={`row tabcontent ${
                  activeTab === "PastTrades" ? "show" : "hide"
                }`}
              >
                <div className="p2p_filter_section mb-4">
                  <h5>Past Trades</h5>
                </div>
                <div className="past_trades_table_main">
                  <div className="active_trades_table_mobile_scroll">
                    <table
                      id="PastTradesTable"
                      className="table table-striped w-100"
                    >
                      <thead>
                        <tr>
                          <th>Date Opened</th>
                          <th>Offer UID</th>
                          <th>Type</th>
                          <th>Crypto</th>
                          <th>Amount</th>
                          <th>Trade Partner</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody id="activeTradesTable">
                        {tradeData?.complete_trade?.length > 0 ? (
                          tradeData?.complete_trade?.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {moment(
                                    data?.created_date,
                                    "YYYY-MM-DD HH:mm:ss"
                                  ).format(outputFormat)}
                                  {/* {data?.created_date} */}
                                </td>
                                {/* <td>2023-11-08 04:56 PM</td> */}
                                <td>
                                  {" "}
                                  {`${data?.offer_uid?.substring(
                                    0,
                                    5
                                  )}...${data?.offer_uid?.slice(-7)}`}
                                </td>
                                <td>{data?.type}</td>
                                <td>
                                  {utill.beautifyNumber(
                                    parseFloat(data?.coin_value),
                                    18
                                  )}{" "}
                                  {data?.coin?.toUpperCase()}
                                </td>
                                {/* <td>{data?.coin_value} {data?.coin}</td> */}
                                <td>
                                  {data?.currency_symbol} {data?.value_currency}
                                </td>
                                <td>
                                  <a href="#" target="_blank">
                                    {data?.trade_partner}
                                  </a>
                                </td>
                                <td
                                  className={`${
                                    data?.status_msg == "Payment made"
                                      ? "payment_made"
                                      : data?.status_msg == "Success"
                                      ? "completed"
                                      : data?.status_msg == "Coin on Escrow"
                                      ? "pending"
                                      : "cancelled"
                                  }`}
                                >
                                  {data?.status_msg}
                                </td>
                                <td>
                                  <Link
                                    to={`${BASE_URL.BASE_URL}p2p/Orders?to=p2p_my_trade&tradeid=${data?.id}`}
                                    className="text-white trade_view d-flex align-items-center"
                                  >
                                    View{" "}
                                    <i
                                      className="fa fa-angle-right ml-2"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={8}>
                              <div
                                className="overview_no_open_orders"
                                style={{ width: "100%" }}
                              >
                                {!user_Data?.access_token ? (
                                  <LoginFirst
                                    return_to={btoa(`${returnToUrl}`)}
                                  />
                                ) : loading ? (
                                  <Spinner
                                    animation="border"
                                    variant="secondary"
                                  />
                                ) : (
                                  <>
                                    <div
                                      className="col-12 mb-5 d-flex justify-content-center mt-5"
                                      style={{ height: "100px" }}
                                    >
                                      <div
                                        className="no-data"
                                        style={{ color: "#9ea4ab" }}
                                      >
                                        <i
                                          className="icon ion-md-document"
                                          style={{ color: "#9ea4ab" }}
                                        ></i>
                                        No data
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {tradeData?.complete_trade?.length > 0 && (
                    <div className="col-12 mb-5 d-flex justify-content-between mt-2 pb-3">
                      {page == 1 ? (
                        <>
                          <button
                            type="button"
                            disabled={loading ? true : false}
                            onClick={handlePage}
                            className="load_more_btn theme-btn-2 theme-btn mt-3 float-left"
                            id="loadPastTrans"
                          >
                            Next
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            disabled={loading ? true : false}
                            type="button"
                            onClick={handlePerPage}
                            className="load_more_btn theme-btn-2 theme-btn mt-3 float-left"
                            id="loadOffer"
                          >
                            Previous
                          </button>
                          {tradeData?.complete_trade?.length > 9 && (
                            <button
                              disabled={
                                tradeData?.complete_trade.length < 10 || loading
                                  ? true
                                  : false
                              }
                              type="button"
                              onClick={handlePage}
                              className="load_more_btn theme-btn-2 theme-btn mt-3 float-left"
                              id="loadOffer"
                            >
                              Next
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Container>
          </section>
        </>
      ) : (
        <CreateNewOrder
          addNewPaymentMethods={paymentMethods}
          countryList={countryList}
          currency={currency}
        />
      )}
    </>
  );
};

export default Order;
