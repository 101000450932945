import * as p2pService from "../../services/P2P_API/p2p.service";
import { storeInstance } from "../../index";
import { toast } from "../../components/Toast/Toast";
import _constant from "../../_constant";
// import BASE_URL from "../../_constant/index";
import { logout } from "./user.action";

const CONSTNAT = _constant.TOKEN;


export const getHeaders = () => {
    let userToken = storeInstance.getState().user?.userData?.access_token;
    return {
        accesstoken: userToken,
        Token: CONSTNAT.LOGINTOKEN,
    };
};


export const walletBalance_transfer = async (data) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.getCoinList(data, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const currencyList = async (data) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.getCurrencyList(headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const paymentMethods = async (data) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.payment_methods(data, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const getCountryList = async (data) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.countryList(headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const getUserOfferList = async (formData) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.searchOffer(formData, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const getFundWalletBalance = async (formData) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.fundWalletBalance(formData, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const handleOpenTrade = async (formData) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.openTrade(formData, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const get_MyOffer_List = async (formData) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.getMyOfferList(formData, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const offerDetailsByID = async (formData) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.getOfferDetailsByID(formData, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const update_Pause_Status = async (formData) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.updatePauseStatus(formData, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const delete_Offer = async (formData) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.deleteOffer(formData, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const get_Trade_List = async (formData) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.getTradeList(formData, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const gtePaymentMethods = async (formData) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.paymentMethods(formData, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const create_Offer = async (formData) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.createOffer(formData, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const user_Payment_Details = async (formData) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.userPaymentDetailsById(formData, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const getUserData = async (formData) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.userData(formData, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const getFeedback = async (formData) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.getFeedbackList(formData, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const userFeedbackList = async (formData) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.getUserFeedbackList(formData, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const paymentDetailsByUser = async (formData) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.getPaymentDetailsByUser(headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};


export const updatePaymentDetails = async (formData) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.editPaymentDetails(formData, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const deletePayment_Method = async (formData) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.deletePaymentDetails(formData, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const add_Payment_Details = async (formData) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.addPaymentDetails(formData, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const get_payment_TimeList = async () => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.paymentTimeList(headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const edit_offer = async (data) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.editOffer(data, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const get_TradeDetails_By_ID = async (data) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.getTradeDetailsByID(data, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const cancel_Trade = async (data) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.cancelTrade(data, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const get_Payment_Details_ByTradeID = async (data) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.getPaymentDetailsByTradeID(data, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const getTradePaymentDetails = async (data) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.get_TradePayment_Details(data, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const payment_Released = async (data) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.paymentReleased(data, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const create_Appeal = async (data) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.createAppeal(data, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};


export const getTradeAppeal = async (data) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.getTrade_appeal(data, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};


export const coin_Released = async (data) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.coinReleased(data, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const trade_Feedback = async (data) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.tradeFeedback(data, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const get_Trade_Status = async (data) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.getTradeStatus(data, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const chat_Push_Notification = async (data) => {
    let headerData = getHeaders();
    try {
        const response = await p2pService?.chatPushNotification(data, headerData);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
