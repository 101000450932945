import React, { useState, useEffect, useCallback } from 'react';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import AccountStatement from '../components/mywallet/accountStatement';
import Funding from '../components/mywallet/funding';
import Overview from '../components/mywallet/overview';
import Spot from '../components/mywallet/spot';
import TrasnsictionHistory from '../components/mywallet/trasnsictionHistory';
import { userCoinListAction, findingCoin } from "../redux/apiActions/user.action";
import { getOrderBalanceAction } from "../redux/apiActions/api.action";
import BASE_URL from "../_constant/index";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, generatePath, useParams } from "react-router-dom";
import { Link } from 'react-router-dom'
import Deposit from '../components/mywallet/deposit';
import Withdraw from '../components/mywallet/withdraw';
import Transfer from '../components/mywallet/Transfer';
import KycVerificationPopUp from '../components/KycVerificationPopUp';


const Mywallet = ({ socket }) => {
    const getTitle = () => {
        if (window.matchMedia('(max-width: 513px)').matches) {
            return true
        } else {
            return false;
        }
    };
    const [tabTitle, setTabTitle] = useState(getTitle());

    useEffect(() => {
        const handleResize = () => {
            setTabTitle(getTitle());
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let activePath = useParams();
    let parmpath = activePath.active_path
    activePath = parmpath ? parmpath : "Overview"

    const dispatch = useDispatch();
    const history = useHistory();
    const userData = useSelector((state) => state.user.userData);
    const [userCoinList, setUserCoinList] = useState();

    useEffect(() => {
        socket?.emit("get_coin", {
            coin: "ALL",
        });
        socket?.on("received_coins_price", (socketData) => {
            setUserCoinList(prevCoins => prevCoins.map((coin) => {
                const updatedCoin = socketData.find((data) => data.symbol === coin.symbol);
                if (updatedCoin) {
                    return {
                        ...coin,
                        // price: updatedCoin.price,
                        rate_currency: updatedCoin.latest_price,
                        // changes: updatedCoin.changes
                    }
                }
                return coin;
            }));
        });
    }, [socket, userCoinList]);


    const [activetab, setactivetab] = useState(activePath);
    const [topActiveTab, setTopActiveTab] = useState(null);
    const [orderBalance, setOrderBalance] = useState([]);
    const [funding, setFunding] = useState([]);
    const [btcCoinPrice, setBtcCoinPrice] = useState(null);
    const [spotPrice, setSpotPrice] = useState("");
    const [coinPrice, setCoinPrice] = useState("");

    const params = new URLSearchParams(window.location.search);
    const toParam = params?.get("to");

    let redirect_url = userData?.access_token
        ? `${BASE_URL.BASE_URL}Mywallet/${activetab}`
        : `${BASE_URL.BASE_URL}login?return_to=${btoa(
            BASE_URL.BASE_URL + "Mywallet"
        )}`;

    // console.log(btcCoinPrice, 'btcCoinPrice')
    const btcPrice = () => {
        const btcCoinPrice = userCoinList?.find(coin => coin.coin == "btc")
        if (btcCoinPrice) {
            setBtcCoinPrice(btcCoinPrice?.rate_currency);
        }
    }

    useEffect(() => {
        const coinValue = toParam?.split("=")[1];
        if (!coinValue) {
            if (topActiveTab == null) {
                history.push(generatePath(BASE_URL.BASE_URL + `Mywallet/${activetab}`))
            }
        }
    }, [topActiveTab])

    useEffect(() => {
        const toValue = toParam?.split("?")[0];
        if (toValue) {
            setTopActiveTab(toValue)
        }
    }, [toParam]);


    const toggleTopTab = tab => {
        setTopActiveTab(tab === topActiveTab ? null : tab);
        if (!topActiveTab) {
            history.push(generatePath(`${BASE_URL.BASE_URL}Mywallet/${activetab}`));
        }
    };

    const hideToptab = useCallback(() => {
        setTopActiveTab(null)
        history.push(generatePath(BASE_URL.BASE_URL + `Mywallet/Overview`))
    }, [setTopActiveTab])

    useEffect(() => {
        btcPrice();
        if (userData?.access_token) {
            findingCoin()
                .then((coinListData) => {
                    if (coinListData?.status === 200) {
                        setFunding(coinListData?.data);
                    }
                })
                .catch((error) => {
                    // handle error
                });
        }

    }, [])

    const createSum = funding?.reduce((total, item) => { return total + parseFloat(item?.available_coin) }, 0)
    const createDoller = funding?.reduce((total, item) => { return total + parseFloat(item?.total_coin_currency) }, 0)
    const totalBtc = orderBalance?.reduce((total, item) => { return total + parseFloat(item?.amount_btc) }, 0)
    const totaldoller = userCoinList?.reduce((total, item) => { return total + parseFloat(item?.balance_currency) }, 0)



    useEffect(async (pair) => {
        if (userData?.access_token) {
            const response = await getOrderBalanceAction(pair, dispatch);
            setOrderBalance(response?.data)
        }
    }, [spotPrice])



    const pushPairURL = (activePath) => {
        if (userData?.access_token) {
            let myPairPath = history.push(
                generatePath(BASE_URL.BASE_URL + "Mywallet/" + activePath)
            );
            setactivetab(activePath);
        } else {
            history.push(generatePath(BASE_URL.BASE_URL + `exchange/BNB_USDT`));
        }
    };

    useEffect(() => {
        setactivetab(activePath)
    }, [activePath])

    useEffect(() => {
        if (!userData.access_token) {
            history.push(generatePath(`${BASE_URL.BASE_URL}login?return_to=${btoa(
                BASE_URL.BASE_URL + "Mywallet/" + activePath
            )}`));
        };
        const path = ["Overview", "Spot", "Funding", "TransactionHistory"]
        if (!path.includes(activePath)) {
            history.push(generatePath(`${BASE_URL.BASE_URL}notfound`));
        }
    }, [])

    useEffect(() => {
        if (userData?.access_token) {
            userCoinListAction(dispatch)
                .then((coinListData) => {
                    if (coinListData?.status === 200) {
                        setUserCoinList(coinListData?.data)
                    }

                    // if (coinListData?.status === 200) {
                    //     setUserCoinList(coinListData?.data.map((val) => {
                    //         val.value = val.id;
                    //         val.label = val.symbol;
                    //         val.label = (
                    //             <div className="option_block">
                    //                 <img
                    //                     src={val.image}
                    //                     alt={val?.symbol}
                    //                 />
                    //                 <span>{val.symbol}</span>
                    //             </div>
                    //         );
                    //         return val;
                    //     }));
                    // }
                })
                .catch((error) => {
                });
        }
    }, [userData, coinPrice, setUserCoinList, dispatch]);

    return (
        <div>
            <KycVerificationPopUp />
            <div className="settings mb15">
                <div className="container-fluid">
                    <Tab.Container defaultActiveKey="Overview" activeKey={activetab} onSelect={pushPairURL}>
                        <Row>
                            <Col lg={2} className="profile-left-tab pr-0 pl-0">
                                <Nav variant="pills" className="settings-nav">
                                    <Nav.Item onClick={() => { setTopActiveTab(null) }}>
                                        <Nav.Link eventKey="Overview" className="d-flex align-items-center"><img src={`${BASE_URL.BASE_URL}img/wallet-overview/overview.png`} alt="Overview" className="deactivate mr-2" /><img src={`${BASE_URL.BASE_URL}img/wallet-overview/overview_w.png`} alt="Overview" className="active mr-2" />
                                            Overview
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => { setTopActiveTab(null) }}>
                                        <Nav.Link eventKey="Spot" className="d-flex align-items-center"><img src={`${BASE_URL.BASE_URL}img/wallet-overview/spot.png`} alt="spot" className="deactivate mr-2" /><img src={`${BASE_URL.BASE_URL}img/wallet-overview/spot_w.png`} alt="spot" className="active mr-2" />
                                            Spot
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => { setTopActiveTab(null) }}>
                                        <Nav.Link eventKey="Funding" className="d-flex align-items-center"><img src={`${BASE_URL.BASE_URL}img/wallet-overview/funding.png`} alt="funding" className="deactivate mr-2" /><img src={`${BASE_URL.BASE_URL}img/wallet-overview/funding_w.png`} alt="funding" className="active mr-2" />
                                            Funding
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item onClick={() => { setTopActiveTab(null) }}>
                                        <Nav.Link eventKey="TransactionHistory" className="d-flex align-items-center"><img src={`${BASE_URL.BASE_URL}img/wallet-overview/transaction-history.png`} alt="transaction-history" className="deactivate mr-2" /><img src={`${BASE_URL.BASE_URL}img/wallet-overview/transaction-history_w.png`} alt="transaction-history" className="active mr-2" />
                                            Transaction History
                                        </Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item onClick={() => { setTopActiveTab(null) }}>
                                        <Nav.Link eventKey="AccountStatement" className="d-flex align-items-center"><img src={`${BASE_URL.BASE_URL}img/wallet-overview/account-statement.png`} alt="account-statement" className="deactivate mr-2" /><img src={`${BASE_URL.BASE_URL}img/wallet-overview/account-statement_w.png`} alt="account-statement" className="active mr-2" />
                                            Account Statement
                                        </Nav.Link>
                                    </Nav.Item> */}
                                </Nav>
                            </Col>
                            <Col lg={9} className="mx-auto">
                                <Row className="card-body px-0">
                                    <Col lg={12}>
                                        {tabTitle &&
                                            <h5 className="card-title">
                                                {activetab === "TransactionHistory" ? "Transaction History" : activetab === "AccountStatement" ? "Account Statement" : activetab === "Overview" ? " Wallet Overview" : activetab}
                                                {/* {activetab} */}
                                            </h5>
                                        }
                                        <Tab.Container
                                            id="left-tabs-example"
                                            activeKey={topActiveTab}
                                        // defaultActiveKey="deposit"
                                        >
                                            <div className="d-flex justify-content-between">
                                                {!tabTitle &&
                                                    <div>
                                                        <h5 className="card-title">
                                                            {activetab === "TransactionHistory" ? "Transaction History" : activetab === "AccountStatement" ? "Account Statement" : activetab === "Overview" ? " Wallet Overview" : activetab}
                                                            {/* {activetab} */}
                                                        </h5>
                                                    </div>
                                                }
                                                <div>
                                                    <Nav className='estimated-balance-btn'>
                                                        <Nav.Item>

                                                            <Nav.Link eventKey="deposit" className="btn deposit-btn hovered" onClick={() => {
                                                                toggleTopTab('deposit');
                                                                history.push(generatePath(`${redirect_url}?to=deposit`));
                                                            }}>Deposit
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="withdraw" className="btn withdraw-btn hovered"
                                                                onClick={() => {
                                                                    toggleTopTab('withdraw')
                                                                    history.push(generatePath(`${redirect_url}?to=withdraw`));
                                                                }} >Withdraw</Nav.Link>
                                                        </Nav.Item>

                                                        <Nav.Item>
                                                            <Nav.Link eventKey="transfer" className="btn withdraw-btn hovered"
                                                                onClick={() => {
                                                                    toggleTopTab('transfer')
                                                                    history.push(generatePath(`${redirect_url}?to=transfer`));
                                                                }}>Transfer</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item onClick={() => { setTopActiveTab(null) }}>
                                                            <Link className="btn history-btn hovered" to={`${BASE_URL.BASE_URL}Mywallet/TransactionHistory`}>
                                                                History
                                                            </Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </div>
                                            </div>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="deposit">
                                                    <Deposit userCoinList={userCoinList} topActiveTab={topActiveTab} hideToptab={hideToptab} />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="withdraw">
                                                    <Withdraw socket={socket} userCoinList={userCoinList} setCoinPrice={setCoinPrice} topActiveTab={topActiveTab} hideToptab={hideToptab} />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="transfer">
                                                    <Transfer setSpotPrice={setSpotPrice} userCoinList={userCoinList} funding={funding} hideToptab={hideToptab} />
                                                </Tab.Pane>
                                            </Tab.Content>

                                        </Tab.Container>
                                    </Col>
                                </Row>

                                {topActiveTab === null && <Tab.Content>
                                    <Tab.Pane eventKey="Overview" className="mt-4">
                                        <Overview totalBtc={totalBtc} totaldoller={totaldoller} createDoller={createDoller} btcCoinPrice={btcCoinPrice} />
                                    </Tab.Pane>


                                    <Tab.Pane eventKey="Spot">
                                        <Spot userCoinListt={userCoinList} totalBtc={totalBtc} totaldoller={totaldoller} orderBalance={orderBalance} />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="Funding">
                                        <Funding createSum={createSum} createDoller={createDoller} btcCoinPrice={btcCoinPrice} funding={funding} />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="TransactionHistory">
                                        <TrasnsictionHistory userCoinListt={userCoinList} />
                                    </Tab.Pane>
                                    {/* <Tab.Pane eventKey="AccountStatement">
                                        <AccountStatement />
                                    </Tab.Pane> */}
                                </Tab.Content>}

                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </div>
    )
}

export default Mywallet