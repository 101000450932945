import React from "react";
import BASE_URL from "../../_constant/index";
const Footer = ({ logo }) => {
  const currentYear = new Date().getFullYear()
  return (
    <>
      <footer className="footer_v pt-3">
        <div className="container">
          {/*{!logo && (
            <div className="row pb-3 pt-5">
              <div className="col">
                <div className="footer_logo_v text-center">
                  <a href="index.html">
                    <img
                      src={BASE_URL.BASE_URL + "img/images/footer_logo_v.svg"}
                      className="img-fluid dark-theme-img"
                      alt="logo"
                      width="200"
                    />
                    <img
                      src={
                        BASE_URL.BASE_URL +
                        "img/images/light-theme/footer_logo_v.svg"
                      }
                      className="img-fluid light-theme-img"
                      alt="logo"
                      width="200"
                    />
                  </a>
                </div>
              </div>
            </div>
          )}*/}
          <div className="row row-cols-2 row-cols-lg-6 row-cols-md-3 row-cols-sm-2 pt-5 pb-2 gap-v-4">
            <div className="col">
              <h5>About Us</h5>
              <ul className="mt-3">
                <li>
                  <a href="#">About</a>
                </li>
                <li>
                  <a href="#">Announcements</a>
                </li>
                <li>
                  <a href="#">Blog</a>
                </li>
                <li>
                  <a href="#">Careers</a>
                </li>
                <li>
                  <a href="#">Community</a>
                </li>
                <li>
                  <a href="#">Legal</a>
                </li>
                <li>
                  <a href="#">News</a>
                </li>
                <li>
                  <a href="#">Notices</a>
                </li>
                <li>
                  <a href="#">Press</a>
                </li>
                <li>
                  <a href="#">Privacy</a>
                </li>
                <li>
                  <a href="#">Risk Warning</a>
                </li>
                <li>
                  <a href="#">Terms</a>
                </li>
                <li>
                  <a href="#">Trust</a>
                </li>
              </ul>
            </div>

            <div className="col">
              <h5>Products</h5>
              <ul className="mt-3">
                <li>
                  <a href="#">Exchange</a>
                </li>
                <li>
                  <a href="#">Banking</a>
                </li>
                <li>
                  <a href="#">Payments</a>
                </li>
                <li>
                  <a href="#">Launchpad</a>
                </li>
                <li>
                  <a href="#">Airdrops</a>
                </li>
                <li>
                  <a href="#">Swap</a>
                </li>
                <li>
                  <a href="#">Staking</a>
                </li>
              </ul>
            </div>

            <div className="col">
              <h5>VYNK Ecosystem</h5>
              <ul className="mt-3">
                <li>
                  <a href="#">VYNKSAFE</a>
                </li>
                <li>
                  <a href="#">VYNK CHAIN</a>
                </li>
                <li>
                  <a href="#">VynkPay</a>
                </li>
                <li>
                  <a href="#">VYNK Ultra Chain</a>
                </li>
                <li>
                  <a href="#">VYNK STORES</a>
                </li>
              </ul>
            </div>

            <div className="col">
              <h5>Services</h5>
              <ul className="mt-3">
                <li>
                  <a href="#">Affiliate</a>
                </li>
                <li>
                  <a href="#">Referral</a>
                </li>
                <li>
                  <a href="#">Proof of Reserves</a>
                </li>
              </ul>
            </div>

            <div className="col">
              <h5>Support</h5>
              <ul className="mt-3">
                <li>
                  <a href="#">Chat support</a>
                </li>
                <li>
                  <a href="#">Support Centre</a>
                </li>
                <li>
                  <a href="#">Request a feature</a>
                </li>
                <li>
                  <a href="#">Fees</a>
                </li>
                <li>
                  <a href="#">VYNK Verified</a>
                </li>
              </ul>
            </div>

            <div className="col">
              <h5>Social Links</h5>
              <ul className="mt-3">
                <li>
                  <a href="https://www.facebook.com/VYNKGroup/" target="_blank">
                    Facebook
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/vynkgroup" target="_blank">
                    Twitter
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/vynkchain-community-59b283208"
                    target="_blank"
                  >
                    Linkedin
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/vynkgroup/"
                    target="_blank"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a href="https://t.me/VYNKCHAIN" target="_blank">
                    Telegram
                  </a>
                </li>
                <li>
                  <a
                    href="https://discord.com/invite/RBCRGZVsQx"
                    target="_blank"
                  >
                    Discord
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <hr className="mb-0 dark-gray-bg-v" />

          <div className="row py-3 footer_v_bottom">
            <div className="col">
              <div className="d-flex flex-wrap justify-content-center align-items-center">
                {/* <ul className="d-flex flex-wrap align-items-center text-center justify-content-center">
                        <li><a href="#">Terms & Conditions</a></li>
                        <li className="mx-2">|</li>
                        <li><a href="#">Privacy Policy</a></li>
                        <li className="mx-2">|</li>
                        <li><a href="#">FAQ's</a></li>
                    </ul>  */}

                <p className="mb-0 mt-3 mt-lg-0 text-center">
                  Copyright © 2023-{currentYear} VYNKSAFE. All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
