import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Form, Row, Col, Button, Modal, Tab, Nav } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch } from 'react-redux';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import utill from '../../_constant/utill';
import { userCoinTxnListAction, fundWalletHistory } from '../../redux/apiActions/user.action';
import Moment from "react-moment";
import EmptyData from '../../components/emptyData'
import CustomDateRangePicker from "../CustomDateRangePicker";
import moment from "moment";
import Spinner from 'react-bootstrap/Spinner';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from '../Toast/Toast';

const TrasnsictionHistory = ({ userCoinListt }) => {


  const getTitle = () => {
    if (window.matchMedia('(max-width: 513px)').matches) {
      return true
    } else {
      return false;
    }
  };
  const [tabTitle, setTabTitle] = useState(getTitle());

  useEffect(() => {
    const handleResize = () => {
      setTabTitle(getTitle());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const params = new URLSearchParams(window.location.search);
  const toParams = params.get("coin")
  const dispatch = useDispatch();
  const [userList, setUserList] = useState();
  const [selectedAsset, setSelectedAsset] = useState('');

  const [fundHistoryData, setFundHistoryData] = useState([]);
  const [txnList, setTxnList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchType, setSearchType] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({})
  const [topActiveTabHistory, setTopActiveTabHistory] = useState("fund");
  const [dateRange, setDateRange] = useState(null);
  const [showSpinner, setShowSpinner] = useState(true)

  useEffect(() => {
    if (userCoinListt || userCoinListt?.length > 0) {
      setUserList(userCoinListt)
    }
  }, [userCoinListt, toParams])

  useEffect(() => {
    if (toParams) {
      const searchCoin = userCoinListt?.find((coin) =>
        coin?.coin?.toLowerCase() === toParams?.toLowerCase()
      );
      if (searchCoin) {
        const updatedList = [searchCoin, ...userCoinListt?.filter(coin => coin?.coin?.toLowerCase() !== toParams?.toLowerCase())];
        setUserList(updatedList);
      }
    } else {
      setUserList(userCoinListt);
    }
  }, [toParams]);

  const updateDateRange = (dateRange) => setDateRange(dateRange)

  const toggleTopTabHistory = (tab) => {
    setTopActiveTabHistory(tab === topActiveTabHistory ? "fund" : tab);
    setCurrentPage(1);
    setSearchType(0);
    setSearchText('');
    setSelectedAsset(userList[0]?.coin);
  };

  const fundHistory = async () => {
    setFundHistoryData([])
    setShowSpinner(true)
    const filterDate = dateRange
      ? {
        startDate: moment(dateRange[0]).format("YYYY-M-D"),
        endDate: moment(dateRange[1]).format("YYYY-M-D"),
      }
      : null;
    const formData = new FormData();
    formData.append('from', filterDate ? filterDate?.startDate : "");
    formData.append("to", filterDate ? filterDate?.endDate : "");
    formData.append("type", searchType);
    formData.append("coin", selectedAsset);
    formData.append("page", currentPage);

    const response = await fundWalletHistory(formData)
    if (response.status === 200) {
      setFundHistoryData(response?.data?.data)
      setShowSpinner(false);
    }
  }

  const handelShow = (cell) => {
    setShow(true)
    setData(cell)
  };

  useEffect(() => {
    if (toParams) {
      setSelectedAsset(toParams)
    } else if (!toParams) {
      if (userList || userList?.length > 0) setSelectedAsset(userList[0]?.coin);
    }
  }, [userList, toParams]);

  const fetchTxnList = () => {
    setTxnList([])
    setShowSpinner(true)
    const formData = new FormData();
    formData.append('coin', selectedAsset);
    formData.append("page", currentPage);
    formData.append("type", searchType);
    userCoinTxnListAction(formData, dispatch)
      .then((userTxn) => {
        if (userTxn?.status === 200) {
          setTxnList(userTxn?.data?.listing);
          setShowSpinner(false);
        }
      })
      .catch((error) => {
        // handle error
      });
  };

  useLayoutEffect(() => {
    if (topActiveTabHistory == "fund") {
      if (selectedAsset && selectedAsset?.length > 0) fetchTxnList()
    } else {
      fundHistory()
    }
  }, [selectedAsset, currentPage, searchType, dateRange, userCoinListt, topActiveTabHistory]);

  const handleAssetChange = (event) => {
    setSelectedAsset(event.target.value);
    setSearchText("")
    setCurrentPage(1)
  };
  const filteredData = searchText.trim()
    ? txnList?.filter((item) =>
      item?.txn_type?.toLowerCase().includes(searchText.toLowerCase()) || item?.hash?.toLowerCase().includes(searchText.toLowerCase())
    ) : txnList;

  const myData = filteredData?.map((item) => (
    {
      id: item?.created_date,
      created_date: item?.created_date,
      type_text: [item?.type, item?.type_text],
      asset: [item?.coin],
      txn_type: [item?.txn_type],
      hash_show: [item?.hash_show, item?.hash_view_url],
      amount: [item?.amount, item?.coin],
      status_text: [{ amount_currency: item.amount_currency, hash_copy: item.hash, address_show: item.address_show, address_view_url: item?.address_view_url, send_mode: item?.send_mode, address_output: item?.address_output, hash_view_url: item?.hash_view_url, status: item?.status, statusText: item?.status_text, amount: item?.amount, coin: item?.coin, hash: item?.hash_show, date: item?.created_date, txn: item?.txn_type, type: item?.type, typeText: item?.type_text }],
    }));

  let fundData = []
  if (fundHistoryData) {
    fundData = fundHistoryData?.map((item) => ({
      id: item?.date,
      created_date: item?.date,
      type_text: item?.type,
      asset: item?.coin,
      txn_type: item?.txnType,
      hash_show: item?.hash,
      amount: item?.amount,
      status_text: item?.status,
    }));

  }
  const columns = [
    {
      dataField: 'created_date',
      text: 'Date',
      headerStyle: () => {
        return { width: "18%" };
      },
      style: () => {
        return { width: "18%" };
      },
      formatter: (cell) => {
        return <>
          <div className="d-flex">
            <Moment format="DD/MM/YYYY  &nbsp;&nbsp; hh:mm A">
              {cell}
            </Moment>
          </div>
        </>
      },
    },
    {
      dataField: 'type_text',
      text: 'Type',

      formatter: (cell) => {
        return <>
          <div className={
            cell[0] == 1 ? "green" : "red"
          }>
            {cell[1]}
          </div>
        </>
      },
      headerStyle: () => {
        return { width: "10%" };
      },
      style: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: 'txn_type',
      text: 'Txn Type',
      formatter: (cell) => {
        return <>
          {cell}
        </>
      },
    },
    {
      dataField: 'hash_show',
      text: 'Hash',
      headerStyle: () => {
        return { width: "20%" };
      },
      style: () => {
        return { width: "20%" };
      },
      formatter: (cell) => {
        return <>
          <div>
            {cell[1] ?
              <a href={cell[1]} target="blank" style={{ textDecoration: "none", color: "#fa501c" }}>
                <i className="ion ion-ios-link" style={{ color: "#fa501c" }}></i> {cell[0]}
              </a> :
              cell[0]
            }
          </div>
        </>
      },
    },
    {
      dataField: 'amount',
      text: 'Amount',
      formatter: (cell) => {
        return <>
          <div>
            {utill.beautifyNumber(cell[0], 12)}{" "}
          </div>
        </>
      },
      headerStyle: () => {
        return { width: "12%" };
      },
      style: () => {
        return { width: "12%" };
      },
    },
    {
      dataField: 'asset',
      text: 'Asset',
      formatter: (cell) => {
        return <>
          {cell}
        </>
      },
      headerStyle: () => {
        return { width: "10%" };
      },
      style: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: 'status_text',
      text: 'Status',

      formatter: (cell) => {
        return <>
          <div className={`${cell[0]?.status == 0 ? "green" : cell[0]?.status == 5 ? "yellow" : "red"}`} style={{ position: "relative" }}>
            {cell[0]?.statusText}
            <button onClick={() => handelShow(cell)} className="arrow_button" style={{ position: "absolute", right: "5px", top: "-4px" }}>
              <i className="ion ion-ios-arrow-forward" style={{ color: "#fa501c" }}></i>
            </button>
          </div>
        </>
      },
    },
  ];

  const FundColumns = [
    {
      dataField: 'created_date',
      text: 'Date',
      headerStyle: () => {
        return { width: "17%" };
      },
      style: () => {
        return { width: "17%" };
      },
      formatter: (cell) => {
        return <>
          <div className="d-flex">
            <Moment format="DD/MM/YYYY &nbsp;&nbsp; hh:mm A">
              {cell}
            </Moment>
          </div>
        </>
      },
    },
    {
      dataField: 'type_text',
      text: 'Type',
      formatter: (cell) => {
        return <>
          <div className={
            cell !== "Credited" ? "red" : "green"
          }>
            {cell}
          </div>

        </>
      },
      headerStyle: () => {
        return { width: "10%" };
      },
      style: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: 'txn_type',
      text: 'Txn Type',
      headerStyle: () => {
        return { width: "20%" };
      },
      style: () => {
        return { width: "20%" };
      },
      formatter: (cell) => {
        return <>
          {cell}
        </>
      },
    },
    {
      dataField: 'hash_show',
      text: 'Hash',
      headerStyle: () => {
        return { width: "17%" };
      },
      style: () => {
        return { width: "17%" };
      },
      formatter: (cell) => {
        return <>
          <div>
            { }
            {cell.substring(0, 10) + "...." + cell.substring(-0, 5)}
          </div>
        </>
      },
    },
    {
      dataField: 'amount',
      text: 'Amount',
      formatter: (cell) => {
        return <>
          <div>
            {utill.beautifyNumber(cell, 4)}{" "}
          </div>
        </>
      },
      headerStyle: () => {
        return { width: "12%" };
      },
      style: () => {
        return { width: "12%" };
      },
    },
    {
      dataField: 'asset',
      text: 'Asset',
      formatter: (cell) => {
        return <>
          {cell.toUpperCase()}
        </>
      },
      headerStyle: () => {
        return { width: "10%" };
      },
      style: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: 'status_text',
      text: 'Status',
      formatter: (cell) => {
        return <>
          <div
            className={
              cell == "Success" ? "green" : cell == "Pending" ? "yellow" : "red"
            }
          >
            {cell}

          </div>
        </>
      },
    },
  ];

  const onTableChange = () => {
    return <div className="overview_no_open_orders">
      {showSpinner ? <Spinner animation="border" variant="secondary" /> : <EmptyData />}
    </div>
  }

  return (
    <>
      <div className="card">
        <div className="card-body pt-0 pt-3">
          <Tab.Container
            id="left-tabs-example"
            activeKey={topActiveTabHistory}
          >
            <Nav className='estimated-balance-btn'>
              <Nav.Item>
                <Nav.Link eventKey="fund" className="btn deposit-btn hovered" onClick={() => { toggleTopTabHistory('fund') }}>
                  Spot
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="wallet" className="btn withdraw-btn hovered" onClick={() => { toggleTopTabHistory('wallet') }}>
                  Fund
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Row className="mt-2">
              <Col md={9}>
                <Form className="d-flex transaction_history_filter">
                  <Form.Group controlId="exampleForm.ControlSelect1" className="transaction_history_form_filter mr-3">
                    <Form.Label className='text-secondary Select_font'>Type</Form.Label>
                    <Form.Control
                      className="Select_font"
                      as="select"
                      value={searchType || ""}
                      onChange={(e) => {
                        setSearchType(e.target.value)
                        setSearchText("")
                        setCurrentPage(1)
                      }}>
                      <option value={0}>All</option>
                      <option value={2}>Debited</option>
                      <option value={1}>Credited</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlSelect1" className="transaction_history_form_filter mr-3">
                    <Form.Label className='text-secondary Select_font'>Asset</Form.Label>
                    <Form.Control className="Select_font" as="select" style={{ textTransform: "uppercase" }} value={selectedAsset || ""} onChange={handleAssetChange}>
                      {userList ? (
                        <>
                          {userList?.map((item, key) => {
                            return (
                              <option key={key} value={item.coin} style={{ textTransform: "uppercase" }}>
                                {item.coin}
                              </option>
                            );
                          })}
                        </>
                      ) : (
                        <option value="" disabled>
                          Loading...
                        </option>
                      )}
                    </Form.Control>
                  </Form.Group>

                  {topActiveTabHistory === "fund" ?
                    // <Form.Group controlId="exampleForm.ControlSelect1" className="transaction_history_form_filter">
                    //   <Form.Label className='text-secondary Select_font'>Txn Type</Form.Label>
                    //   <Form.Control
                    //     className="Select_font"
                    //     placeholder="Search"
                    //     aria-label="Search"
                    //     aria-describedby="basic-addon2"
                    //     value={searchText || ""}
                    //     // bordered={false}
                    //     onChange={(e) => {
                    //       setSearchText(e.target.value)
                    //       setCurrentPage(1)
                    //     }}
                    //   />
                    // </Form.Group> 
                    ""
                    :
                    <>
                      {!tabTitle &&
                        < Form.Group controlId="exampleForm.ControlSelect1" className="transaction_history_form_filter">
                          <Form.Label className='text-secondary Select_font'> Filter Date :</Form.Label>
                          <CustomDateRangePicker
                            className="Select_font"
                            dateRange={dateRange}
                            updateDateRange={updateDateRange}
                          />
                        </Form.Group>
                      }
                    </>
                  }
                </Form>
              </Col>
            </Row>
            <Tab.Content>
              <Tab.Pane eventKey="fund">
                <div className="crypto_balance_table_main">
                  < BootstrapTable
                    bootstrap4
                    keyField="id"
                    noDataIndication={onTableChange}
                    data={myData}
                    columns={columns}
                    headerClasses="Transiction_header"
                    bodyClasses="Table_Body "
                    className="table"
                    classes="crypto_balance_table"
                    wrapperClasses="table-responsive"
                    loading={true}
                    bordered={false}
                  />
                </div>
                <NextPreButtons currentPage={currentPage} setCurrentPage={setCurrentPage} fundHistoryData={myData} />
              </Tab.Pane>
              <Tab.Pane eventKey="wallet">
                <div className="crypto_balance_table_main">
                  < BootstrapTable
                    bootstrap4
                    keyField="id"
                    noDataIndication={onTableChange}
                    data={fundData}
                    columns={FundColumns}
                    headerClasses="Transiction_header"
                    bodyClasses="Table_Body "
                    className="table"
                    classes="crypto_balance_table"
                    wrapperClasses="table-responsive"
                    loading={true}
                    bordered={false}
                  />
                </div>
                <NextPreButtons currentPage={currentPage} setCurrentPage={setCurrentPage} fundHistoryData={fundHistoryData} />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            className="transaction_details_Successful_modal"
            contentClassName="modal_content_Size"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Transaction Details</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center pt-2">
              <div className="transaction_details_Successful text-center">
                <h5 className={
                  data[0]?.status == 0 ? "green" : data[0]?.status == 5 ? "yellow" : "red"
                }><i className="ion ion-md-checkmark-circle mr-1"></i> {data[0]?.statusText}</h5>
                <h2 className="mb-0">
                  {utill.beautifyNumber(data[0]?.amount, 12)}{" "}
                  {data[0]?.coin}</h2>
                <p>$ {data[0]?.amount_currency}</p>
              </div>
              <ul className="mt-4">

                <li className={`d-flex justify-content-between text-left ${data[0]?.type == 1 ? "green" : "red"}`}><p className="text-secondary">Type</p>{data[0]?.typeText}</li>
                <li className="d-flex justify-content-between text-left"><p className="text-secondary">Time Created</p>
                  <Moment format="DD/MM/YYYY &nbsp; hh:mm A">
                    {data[0]?.date}
                  </Moment>
                </li>
                <li className="d-flex justify-content-between text-left"><p className="text-secondary">Txn Type</p>{data[0]?.txn}</li>

                {(data[0]?.send_mode == 1 || data[0]?.send_mode == 2 || data[0]?.send_mode == 3) &&
                  <li className="d-flex justify-content-between text-left">
                    <p className="text-secondary">{data[0]?.type == 1 ? "From" : "To"}</p>
                    {data[0]?.address_view_url ?
                      (<>
                        <span aria-label={data[0]?.address_show} title={data[0]?.address_show} className='copy_btn_dev'>
                          <CopyToClipboard
                            text={
                              data[0] &&
                                data[0]?.address_show
                                ? data[0]?.address_show
                                : ""
                            }
                            onCopy={() =>
                              toast.success("copied")
                            }
                          >
                            <span className="copy_btn" title={`copy to clipboard ${data[0]?.address_show}`}>
                              <i
                                title="Copy address to clipboard"
                                className="icon ion-md-copy"
                                style={{ fontSize: "18px" }}
                              ></i>
                              {/* <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><rect x="128" y="128" width="336" height="336" rx="57" ry="57" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/><path d="M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg> */}
                            </span>
                            {/* .copy_btn */}
                          </CopyToClipboard>
                          <a href={data[0]?.address_view_url} target="blank" style={{ textDecoration: "none", color: "#fa501c" }}>
                            {/* <i className="ion ion-ios-link" ></i> */}
                            {data[0].address_output}
                            {/* {data[0].address_view_url} */}
                          </a>
                        </span>

                      </>) :
                      data[0]?.address_output}
                  </li>
                }
                <li className="d-flex justify-content-between text-left">
                  <p className="text-secondary">Hash</p>
                  {data[0]?.hash_view_url ?
                    (<>
                      <span title={data[0]?.hash_copy} className='copy_btn_dev'>
                        <CopyToClipboard
                          text={
                            data[0] &&
                              data[0]?.hash_copy
                              ? data[0]?.hash_copy
                              : ""
                          }
                          onCopy={() =>
                            toast.success("copied")
                          }
                        >
                          <span className="copy_btn" aria-label={`copy to clipboard ${data[0]?.hash_copy}`}>
                            <i
                              title="Copy address to clipboard"
                              className="icon ion-md-copy"
                              style={{ fontSize: "18px" }}
                            ></i>
                            {/* <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><rect x="128" y="128" width="336" height="336" rx="57" ry="57" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/><path d="M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg> */}
                          </span>
                          {/* .copy_btn */}
                        </CopyToClipboard>

                        <a href={data[0]?.hash_view_url} target="blank" style={{ textDecoration: "none", color: "#fa501c" }}>
                          <i className="ion ion-ios-link" style={{ color: "#fa501c" }}></i>
                          {data[0].hash}
                        </a>
                      </span>
                    </>)
                    : data[0]?.hash}
                </li>

              </ul>
            </Modal.Body>
          </Modal>
        </div>
      </div >
    </>
  )
}

export default TrasnsictionHistory

function NextPreButtons(props) {
  const { currentPage, setCurrentPage, fundHistoryData } = props
  return (
    <Row className="mt-3 d-flex justify-content-between pl-3 pr-3" >
      {/* <Col  md={6}> */}
        {currentPage !== 1 &&
          <button className="pagination_button"
            disabled={currentPage == 1}
            onClick={() => { setCurrentPage(currentPage - 1) }}
          >
            <i className="icon ion-md-arrow-dropleft mr-2"></i>Previous
          </button>
        }
      {/* </Col> */}
      {/* <Col md={6} className="d-flex flex-row-reverse"> */}

        {fundHistoryData?.length < 10 ? null :
          <Button className="pagination_button"
            onClick={() => { setCurrentPage(currentPage + 1) }}
          >
            Next<i className="icon ion-md-arrow-dropright ml-2"></i>
          </Button>
        }
      {/* </Col> */}
    </Row>
  )
}
