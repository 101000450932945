import React, { useEffect, useState } from 'react'
import { ListGroup, Row } from 'react-bootstrap'
import { walletBalance_transfer, getUserOfferList, getFundWalletBalance, handleOpenTrade } from '../../redux/apiActions/p2p.action'
import Select from "react-select";
import { useHistory } from 'react-router-dom'
import Alloffertable from './Alloffertable';
import { toast } from "../../components/Toast/Toast";
import BASE_URL from "../../_constant/index";
const Alloffer = ({ checkPayment, CheckPaymentMethods, currency, paymentMethod, countryList, setUser }) => {


    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [amount, setAmount] = useState("")
    const [radioValue, setRadioValue] = useState("p2p-buy");
    const [coinList, setCoinList] = useState(null);
    const [coinCheck, setCoinCheck] = useState("All")
    const [selectedCurrency, setSelectedCurrency] = useState(null)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [selectedCountryList, setSelectedCountryList] = useState(null);
    const [userOffer, setUserOffer] = useState(null)
    const [page, setPage] = useState(1)
    const [balance, setBalance] = useState("")
    const [pay, setPay] = useState("")
    const [receive, setReceive] = useState("");
    const [error, setError] = useState("");
    const [priceData, setPriceData] = useState(null)
    const [openTrade, setOpenTrade] = useState(null)
    const [url, setUrl] = useState("")

    function isNumberKey(value) {
        value = value ? value : "";
        // alert(value)
        var validNumber = new RegExp(/^[0-9]*(\.[0-9]*)?$/);
        if (validNumber.test(value)) {
            return value;
        } else {
            return false;
        }
    }


    const handle_Open_Trade = async (id) => {
        // alert("sd")
        try {
            if (!error) {
                if (receive && pay) {
                    setLoading(true)
                    let formData = new FormData();
                    formData.append("payment_method", radioValue == "p2p-buy" ? 1 : 2);
                    formData.append("value", receive);
                    formData.append("value_currency", pay);
                    formData.append("coin_rate", priceData?.coin_rate_USD);
                    formData.append("offer_id", id);
                    const response = await handleOpenTrade(formData);
                    if (response?.status === 200) {
                        history.replace(`${BASE_URL.BASE_URL}p2p/Orders?to=p2p_my_trade&tradeid=${response?.data?.id}`);
                        toast?.successes(response?.message)
                        setOpenTrade(response?.data)
                        setLoading(false)
                    } else {
                        toast?.errors(response?.message || response?.data?.message)
                        setLoading(false)
                        // history.replace(`${BASE_URL.BASE_URL}p2p/Orders?to=p2p_my_trade&tradeid=${response?.data?.id}`);
                    }
                } else {
                    toast?.errors("Please Enter Amount.")
                }
            }

        } catch (error) {
            console.error('Error fetching currency data:', error);
        }
    };

    const handleAllBalance = (balance) => {
        setError("");

        const value = parseFloat(balance); // Convert value to a number
        const payValue = value * parseFloat(priceData?.offer_price);

        // if (value < priceData?.min_trade_size) {
        //     setError(`The minimum order amount is ${priceData?.min_trade_size} ${priceData?.currency_code}.`)
        // } else if (value > parseFloat(priceData?.max_trade_size)) { // Convert max_trade_size to a number
        //     setError(`The maximum order amount is ${priceData?.max_trade_size} ${priceData?.currency_code}.`)
        // }
        setReceive(value)
        setPay(payValue)
    }


    const handleAllCoinBalance = (total_amount, balance) => {
        setError("");

        const value = parseFloat(total_amount); // Convert value to a number

        if (value > parseFloat(balance?.total_coin)) {
            setReceive(parseFloat(balance?.total_coin))
            const payValue = parseFloat(balance?.total_coin) * parseFloat(priceData?.offer_price)

            setPay(payValue)
            if (payValue < parseFloat(priceData?.min_trade_size)) {
                setError(`The minimum order amount is ${priceData?.min_trade_size} ${priceData?.currency_code}.`)
            } else if (payValue > parseFloat(priceData?.max_trade_size)) { // Convert max_trade_size to a number
                setError(`The maximum order amount is ${priceData?.max_trade_size} ${priceData?.currency_code}.`)
                setPay(parseFloat(priceData?.max_trade_size))
            }
        } else {
            setReceive(value);
            const payValue = value * parseFloat(priceData?.offer_price);
            setPay(payValue);
            if (payValue < parseFloat(priceData?.min_trade_size)) {
                setError(`The minimum order amount is ${priceData?.min_trade_size} ${priceData?.currency_code}.`)
            } else if (payValue > parseFloat(priceData?.max_trade_size)) { // Convert max_trade_size to a number
                setError(`The maximum order amount is ${priceData?.max_trade_size} ${priceData?.currency_code}.`)
            }
        }


        // const payValue = value * parseFloat(priceData?.coin_rate_USD)

        // setReceive(value)
        // const payValue = value * parseFloat(priceData?.offer_price)
        // setPay(payValue)
    }

    const handleReceive = (e) => {
        let value = isNumberKey(e.target.value);
        if (value !== false) {
            const payValue = value * parseFloat(priceData?.offer_price)
            if (radioValue !== "p2p-buy") {
                if (parseFloat(payValue) < parseFloat(priceData?.min_trade_size)) {
                    setError(`The minimum order amount is ${priceData?.min_trade_size} ${priceData?.currency_code}.`)

                } else if (parseFloat(payValue) > parseFloat(priceData?.max_trade_size)) { // Convert max_trade_size to a number
                    setError(`The maximum order amount is ${priceData?.max_trade_size} ${priceData?.currency_code}.`)
                }
            }

            setReceive(value)
            setPay(payValue)
        }
        setError("");
    }


    const handlePay = (e) => {
        let value = isNumberKey(e.target.value);
        if (value !== false) {
            setError("");
            if (radioValue !== "p2p-buy") {
                if (parseFloat(value) < parseFloat(priceData?.min_trade_size)) {
                    setError(`The minimum order amount is ${priceData?.min_trade_size} ${priceData?.currency_code}.`)
                } else if (value > parseFloat(priceData?.max_trade_size)) { // Convert max_trade_size to a number
                    setError(`The maximum order amount is ${priceData?.max_trade_size} ${priceData?.currency_code}.`)
                }
            }
            const receive = value / parseFloat(priceData?.offer_price)
            setReceive(receive)
            setPay(value)
        }

    }


    const getUserBalance = async (coin) => {
        try {
            const formData = new FormData()
            formData.append("coin", coin)
            const response = await getFundWalletBalance(formData);
            if (response.status == 200) {
                setBalance(response?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const LoadMore = () => {
        setPage(page + 1)
    }
    const perfectPage = () => {
        setPage(page - 1)
    }

    const getSearchOfferList = async () => {
        try {
            setLoading(true)
            let formData = new FormData();
            formData.append("trade_type", radioValue == "p2p-buy" ? 1 : 2);
            formData.append("coin", coinCheck == "All" ? "" : coinCheck);
            formData.append("country_id", selectedCountryList?.value ? selectedCountryList?.value : "");
            formData.append("currency_id", selectedCurrency?.value ? selectedCurrency?.value : "");
            formData.append("payment_id", selectedPaymentMethod?.value ? selectedPaymentMethod?.value : "");
            formData.append("amount", amount);
            formData.append("page", page);
            // formData.append("network_type", 3);


            const response = await getUserOfferList(formData);
            if (response?.status === 200) {
                setLoading(false)
                setUserOffer(response?.data);
                setUrl()

            } else {
                setLoading(false)
            }
        } catch (error) {

            console.error('Error fetching currency data:', error);
        }
    };

    const refresh = () => {
        getSearchOfferList();
        setCoinCheck("All")
        setPage(1)
        setSelectedCountryList([null])
        setSelectedCurrency([null])
        setSelectedPaymentMethod([null])
        setAmount("")
    }
    useEffect(() => {
        getSearchOfferList();
    }, [radioValue, coinCheck, page, selectedCountryList, selectedCurrency, selectedPaymentMethod, amount])


    const handleRadioChange = (event) => {
        setRadioValue(event.target.id);
        CheckPaymentMethods();
        setPage(1)
    };

    const handleCoinRadioChange = (event) => {
        const selectedValue = event.target.id;
        if (selectedValue == "All") {
            setCoinCheck("All");
        } else {
            setCoinCheck(selectedValue);
        }

        setPage(1);
    };
    const handleAllClick = () => {
        setCoinCheck("All");
        setPage(1);
    }

    useEffect(async () => {
        const fetchData = async () => {
            try {
                const response = await walletBalance_transfer();
                if (response.status === 200) {
                    setCoinList(response.data);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [])

    const handleCurrencyChange = (selectedOption) => {
        setSelectedCurrency(selectedOption); // Update the selected currency state
    };
    const handlePaymentMethodChange = (selectedOption) => {
        setSelectedPaymentMethod(selectedOption); // Update the selected currency state
    };
    const handleCountryListChange = (selectedOption) => {
        setSelectedCountryList(selectedOption); // Update the selected currency state
    };


    return (
        <>
            <section className="dark-gray-bg-v">
                <div className="container">
                    <Row className='p4'>
                        <form className="saving-transactions-form p2p-transactions-form cf mb-3 pb-2 overflow-auto d-flex align-content-center">
                            <div className="p2p-transaction-type transaction-type cf d-flex mr-4 p2p_transactions_form_buy_sell">
                                <input
                                    type="radio"
                                    className="btn-check buy_coin_v buy_btn_p2p_v"
                                    name="buy_coin_v"
                                    id="p2p-buy"
                                    autoComplete="off"
                                    data-networktype=''
                                    checked={radioValue === "p2p-buy"}
                                    onChange={handleRadioChange}
                                />
                                <label className="basic-label btn btn-gray-v" for="p2p-buy">Buy</label>

                                <input
                                    type="radio"
                                    className="btn-check buy_coin_v "
                                    name="buy_coin_v"
                                    id="p2p-sell"
                                    autoComplete="off"
                                    data-networktype=""
                                    checked={radioValue === "p2p-sell"}
                                    onChange={handleRadioChange}
                                />
                                <label className="free-label btn btn-gray-v sell-btn-label" for="p2p-sell">Sell</label>

                            </div>
                            <div className="p2p-transaction-type transaction-type cf d-flex">

                                <input
                                    key={"All"}
                                    type="radio"
                                    className="btn-check available_coin"
                                    name="available_coin"
                                    id={"All"}
                                    autoComplete="off"
                                    checked={coinCheck == "All"}
                                    onChange={handleCoinRadioChange}
                                    onClick={handleAllClick}
                                />
                                <label className="basic-label btn btn-gray-v" onClick={handleAllClick} htmlFor={coinCheck == "All"}>All</label>
                                {coinList &&
                                    coinList?.map((coin, index) => {
                                        return (
                                            <div key={index}>
                                                <input
                                                    key={index}
                                                    type="radio"
                                                    className="btn-check available_coin"
                                                    name="available_coin"
                                                    id={coin?.symbol}
                                                    autoComplete="off"
                                                    data-networktype=""
                                                    checked={coinCheck == coin?.symbol}
                                                    onChange={handleCoinRadioChange}
                                                />
                                                <label className="basic-label btn btn-gray-v" htmlFor={coin?.symbol}>{coin?.symbol}</label>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </form>

                    </Row>
                    <div className="row justify-content-between mb-xl-0 mb-3 pb-5">
                        <div className='col-lg-10 col-md-12 col-12'>
                        <ul className="p2p_search_refresh d-lg-flex align-items-center mt-lg-0 my-3">
                            <li className="p2p_search mt-md-0 mt-3 mb-2">
                                <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} className="form-control" placeholder="Enter Amount" name="search_amount" id="search_amount"
                                />
                            </li>
                            <li className="mx-md-3 mb-2">
                                <Select
                                    isClearable
                                    placeholder=" Select currency"
                                    className="wallet_network_select custom_Curency-box"
                                    classNamePrefix="custom_select"
                                    isSearchable={true}
                                    name="selectCurrency"
                                    options={currency}
                                    value={selectedCurrency}
                                    onChange={handleCurrencyChange}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isFocused ? "none" : "none",
                                        }),
                                    }}
                                />
                            </li>

                            <li className="mt-md-0 mt-3 mb-2">
                                <Select
                                    isClearable
                                    placeholder="Payment Method"
                                    className="wallet_network_select custom_Curency-box"
                                    classNamePrefix="custom_select"
                                    isSearchable={true}
                                    name="selectPaymentMethod"
                                    options={paymentMethod}
                                    value={selectedPaymentMethod}
                                    onChange={handlePaymentMethodChange}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isFocused ? "none" : "none",
                                        }),
                                    }}
                                />
                                {/* <select className="form-control select2" name="search_payment_id" id="search_payment_id">
                                <option value="">Payment Method</option>
                                <option value="<?= $payVal['id'] ?>">Bank Transfer</option>
                            </select> */}
                            </li>

                            <li className="mx-lg-3 mt-lg-0 mt-3 mb-2">
                                <Select
                                    isClearable
                                    placeholder="Available Region(s)"
                                    className="wallet_network_select custom_Curency-box"
                                    classNamePrefix="custom_select"
                                    isSearchable={true}
                                    name="selectPaymentMethod"
                                    options={countryList}
                                    value={selectedCountryList}
                                    onChange={handleCountryListChange}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isFocused ? "none" : "none",
                                        }),
                                    }}
                                />
                                {/* <select className="form-control select2" name="search_country_id" id="search_country_id">
                                <option value="">Available Region(s)</option>

                            </select> */}
                            </li>

                        </ul>
                        </div>
                        <div className='col-lg-2 col-md-12 col-12'>
                        <ul className="mx-auto mx-md-0 mt-lg-0 mt-3">
                            <li>
                                <button onClick={refresh} className="refresh-btn text-center" type="button" id="search_refresh">
                                    Refresh
                                </button>
                            </li>
                        </ul>
                        </div>
                    </div>
                </div>
            </section>
            
            <Alloffertable
                checkPayment={checkPayment}
                setUser={setUser}
                handle_Open_Trade={handle_Open_Trade}
                balance={balance}
                handleAllCoinBalance={handleAllCoinBalance}
                setReceive={setReceive}
                setPay={setPay}
                handleAllBalance={handleAllBalance}
                error={error}
                userOffer={userOffer}
                setPriceData={setPriceData}
                LoadMore={LoadMore}
                loading={loading}
                getUserBalance={getUserBalance}
                handlePay={handlePay}
                receive={receive}
                pay={pay}
                setError={setError}
                handleReceive={handleReceive}
                perfectPage={perfectPage}
                page={page}
                paymentMethod={paymentMethod}
                CheckPaymentMethods={CheckPaymentMethods}
            />
        </>
    )
}

export default Alloffer