import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import BASE_URL from "../_constant/index";
import InputGroup from "react-bootstrap/InputGroup";
import {
  updatePhoneNumber,
  update_user_details,
} from "../redux/apiActions/otp.verify.action";
import { toast } from "../components/Toast/Toast";
import Form from "react-bootstrap/Form";
import BASE_URL from "../_constant/index";
import OtpNumber from "./otp-number";
import Select from "react-select";
import {
  All_CountriesAction,
  All_CurrencyAction,
} from "../redux/apiActions/api.action";
// import { Field } from "formik";
// import * as Yup from "yup";

export default function VerifyNumber() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoader] = useState(false);
  const [phone, setPhone] = useState("");
  const [showOtp, setOTPShow] = useState(false);
  const [errMsg, setEerrMsg] = useState(null);
  const [allCountry, setAllCountryData] = useState([]);
  const [allCurrency, setAllCurrency] = useState([]);

  const [fieldsData, setFieldsData] = useState({
    country: "",
    currency: "",
  });

  let userData = useSelector((state) => state.user.userData);

  useEffect(async () => {
    setLoader(true);
    const all_country_list = async () => {
      const response = await All_CountriesAction();

      const countrys = response?.data?.map((val) => {
        val.value = val.id;
        val.label = (
          <div className="option_block">
            <img
              src={response?.base_url + val.country_image}
              alt={val?.country_name}
            />
            <span>{val.country_name}</span>
          </div>
        );
        // val.text = val.country_name;

        val.icon = (
          <img
            src={response?.base_url + val.country_image}
            alt={val?.country_name}
          />
        );
        return val;
      });

      setAllCountryData(countrys);
      const defaultCountry = countrys?.find(
        (country) => country.country_name == "India"
      );

      if (defaultCountry) {
        setFieldsData((prevState) => ({
          ...prevState,
          country: defaultCountry,
        }));
      }
    };
    // const all_currency_list = async () => {
    //   const response = await All_CurrencyAction();

    //   setAllCurrency(
    //     response?.data.map((val) => {
    //       val.value = val.id;
    //       val.label = (
    //         <div className="option_block">
    //           <span>{`${val.currency_name}(${val.currency_code})`}</span>
    //         </div>
    //       );

    //       return val;
    //     })
    //   );

    //   // setFieldsData({
    //   //   currency: allCurrency[0],
    //   // });
    // };
    await all_country_list();
    // await all_currency_list();
    setLoader(false);
  }, [userData, setLoader, setAllCountryData, setAllCurrency, history]);

  const handleFieldsChange = (val, fieldName) => {
    if (fieldName === "country" || fieldName === "currency") {
      if (val) {
        setFieldsData((preState) => {
          let obj = Object.assign({}, preState);
          if (fieldName === "country") {
            obj.country = val;
          } else {
            obj.currency = val;
          }
          return obj;
        });
      }
    }
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const handleChangePhone = (e) => {
    let phone = e.target.value;
    setPhone(phone);
    setEerrMsg(null);
  };
  // console.log(fieldsData.country)

  const handleUserDataUpdate = () => {
    setLoader(true);
    // localStorage.setItem("CountryData",JSON.stringify(fieldsData.country))
    if (phone === "" || !phoneRegExp.test(phone)) {
      setEerrMsg("Please enter Valid Details");
      setLoader(false);
    } else {
      let formData = new FormData();
      formData.append("phone", phone);
      formData.append("country", fieldsData.country?.id);
   
      // formData.append("currency", fieldsData.currency?.id);
      update_user_details(formData).then((data) => {
        if (data !== false) {
          setOTPShow(true);
        }
      });
      setLoader(false);
    }
  };

  const handleUpdateNumber = () => {
    setLoader(true);

    if (phone === "" || !phoneRegExp.test(phone)) {
      setEerrMsg("Please enter Valid Details");
      setLoader(false);
    } else {
      let formData = new FormData();
      formData.append("phone", phone);
      formData.append("country", fieldsData.country?.id);
      update_user_details(formData).then((data) => {
        if (data !== false) {
          setOTPShow(true);
        }
      });
      // updatePhoneNumber(formData).then((data) => {
      //   if (data !== false) {
      //     setOTPShow(true);
      //   }
      // });
      setLoader(false);
    }
  };
  
  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      // This line disable the blue border
      // boxShadow: "none",
      fontSize: "14px",
      // height: "39px",
    }),
    // valueContainer: (provided, state) => ({
    //   ...provided,
    //   fontSize: "14px",
    //   height: "39px",
    // }),
    // indicatorsContainer: (provided, state) => ({
    //   ...provided,
    //   fontSize: "14px",
    //   height: "39px",
    // }),
  };
  return (
    <>
      {showOtp === false ? (
        <div className="basic_pages">
          <div
            className="row row-cols-1 row-cols-md-2 row-cols-md-1 row-cols-sm-1 align-items-center gap-v-4"
            style={{ height: "100%" }}
          >
            <div className="col d-flex align-items-center justify-content-center">
              <div className="form-access">
                {userData.is_country_updated == "0" ? (
                  <>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <h3 className="mb-3 Welcome" style={{ fontSize: "2rem" }}>
                      Update <span className="text-orange">Details</span>
                      </h3>
                      <p className="text-left mb-4">
                      Please enter your phone number and country
                      </p>
                      <Form.Label>Select country</Form.Label>
                      <Select
                        className="wallet_network_select custom_select-box mb-4"
                        classNamePrefix="custom_select"
                        styles={style}
                        isSearchable={true}
                        filterOption={(options, searchText) => {
                          if (
                            options.data.country_name
                              .toLowerCase()
                              .startsWith(searchText.toLowerCase())
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        }}
                        name="selectCountry"
                        placeholder=" Select country"
                        value={fieldsData?.country ? fieldsData?.country : null}
                        options={allCountry}
                        onChange={(e) => {
                          handleFieldsChange(e, "country");
                        }}
                      />
                      {/* <Select
                        placeholder="Select currency"
                        className="wallet_network_select custom_select-box mb-4"
                        classNamePrefix="custom_select"
                        isSearchable={true}
                        styles={style}
                        name="selectCurrency"
                        value={
                          fieldsData?.currency ? fieldsData?.currency : null
                        }
                        options={allCurrency}
                        filterOption={(options, searchText) => {
                          if (
                            options.data.currency_name
                              .toLowerCase()
                              .startsWith(searchText.toLowerCase()) ||
                            options.data.currency_code
                              .toLowerCase()
                              .includes(searchText.toLowerCase())
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        }}
                        onChange={(e) => {
                          handleFieldsChange(e, "currency");
                          // setErrorMsg((preState) => {
                          //   let obj = Object.assign({}, preState);
                          //   obj.currencyError = "";
                          //   return obj;
                          // });
                        }}
                      /> */}

                      <div className="form-group mb">
                        <Form.Label>Select country</Form.Label>
                        <InputGroup>
                          <InputGroup.Text
                            id="basic-addon1"
                            className="bg-primary"
                            style={{
                              color: "white",
                              margin: "0px",
                              borderRadius: "5px 0px 0px 5px",
                              fontSize: "14px",
                            }}
                          >
                            +
                            {fieldsData?.country?.std_code || userData.std_code}
                          </InputGroup.Text>
                          <Form.Control
                            type="phone"
                            className={"form-control phone_number"}
                            placeholder="Enter your phone number"
                            onChange={handleChangePhone}
                            value={phone}
                            aria-describedby="basic-addon1"
                            style={{ borderLeft: "none", fontSize: "14px" }}
                          />
                        </InputGroup>
                      </div>
                      {errMsg && (
                        <small className="error-message text-danger text-wrap">
                          {errMsg}
                        </small>
                      )}
                      <button
                        type="button"
                        className="btn btn-primary signinButton"
                        onClick={!loading ? handleUserDataUpdate : null}
                        disabled={loading}
                      >
                        Verify
                      </button>
                    </form>
                  </>
                ) : (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <h3 className="mb-3 Welcome" style={{ fontSize: "2rem" }}>
                    Update <span className="text-orange">details</span>
                    </h3>
                    <p className="text-left mb-4">
                    Please enter your phone number and country
                    </p>
                    <Form.Label>Select country</Form.Label>
                    <Select
                      className="wallet_network_select custom_select-box mb-4"
                      classNamePrefix="custom_select"
                      styles={style}
                      defaultValue={"india"}
                      isSearchable={true}
                      filterOption={(options, searchText) => {
                        if (
                          options.data.country_name
                            .toLowerCase()
                            .startsWith(searchText.toLowerCase())
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                      name="selectCountry"
                      placeholder=" Select country"
                      value={fieldsData?.country ? fieldsData?.country : null}
                      options={allCountry}
                      onChange={(e) => {
                        handleFieldsChange(e, "country");
                      }}
                    />
                    <div className="form-group mb">
                      <Form.Label>Mobile No</Form.Label>
                      <InputGroup>
                        <InputGroup.Text
                          id="basic-addon1"
                          className="bg-primary"
                          style={{
                            color: "white",
                            margin: "0px",
                            borderRadius: "5px 0px 0px 5px",
                            fontSize: "14px",
                          }}
                        >
                          +{fieldsData?.country?.std_code || userData.std_code}
                        </InputGroup.Text>
                        <Form.Control
                          type="phone"
                          className={"form-control phone_number"}
                          placeholder="Enter your phone number"
                          onChange={handleChangePhone}
                          value={phone}
                          aria-describedby="basic-addon1"
                          style={{ borderLeft: "none", fontSize: "14px" }}
                        />

                        {/* <InputGroup.Text>@</InputGroup.Text>
                  <input
                    type="phone"
                    className={"form-control"}
                    placeholder="Enter your phone number"
                    onChange={handleChangePhone}
                    value={phone}
                  /> */}
                      </InputGroup>
                    </div>
                    {errMsg && (
                      <small className="error-message text-danger text-wrap">
                        {errMsg}
                      </small>
                    )}
                    <button
                      type="button"
                      className="btn btn-primary signinButton"
                      onClick={!loading ? handleUpdateNumber : null}
                      disabled={loading}
                    >
                      Verify
                    </button>
                  </form>
                )}
              </div>
            </div>
            <div
              className="col px-lg-5 px-3 login-background d-flex align-items-center justify-content-center"
              style={{ height: "100%" }}
            >
              <div className="p-4 d-flex align-items-center justify-content-center w-100">
                <img
                  src={`${BASE_URL.BASE_URL}img/images/SignUp.svg`}
                  className="img-fluid light-theme-img"
                  alt="banner"
                  width="450"
                />
                <img
                  src={`${BASE_URL.BASE_URL}img/images/Sign.png`}
                  className="img-fluid dark-theme-img"
                  alt="banner"
                  width="450"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <OtpNumber
          handleUserDataUpdate={handleUserDataUpdate}
          fieldsData={fieldsData}
          phone={phone}
          setOTPShow={setOTPShow}
          dispatch={dispatch}
          history={history}
        />
      )}
    </>
  );
}
