import React, { useState, useEffect ,useRef } from "react";
import BASE_URL from "../../_constant/index";
import BootstrapTable from "react-bootstrap-table-next";
import utill from "../../_constant/utill";
import { Link } from "react-router-dom";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { allSymbolsAction } from "../../redux/apiActions/api.action";
const { SearchBar } = Search;

const Pricing = () => {

  const sectionRef = useRef(null); 
  
  const [symbolsData, setSymbolsData] = useState([]);
  const [symbolsCoinListPrice, setSymbolsCoinListPrice] = useState([]);

  useEffect(async () => {
    const fetchData = async () => {
      try {
        const result = await allSymbolsAction();
        if (result !== false) {
          setSymbolsData(
            result?.data?.filter((symbol) => symbol.quote === "USDT")
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    const prices = symbolsData?.map((ele) => {
      return {
        symbol: ele?.symbol,
        price: ele?.price,
        last_price: ele?.last_price,
        usd_price: ele?.usd_price,
        changes: ele?.changes,
      };
    });
    setSymbolsCoinListPrice(prices);
  }, [symbolsData]);

  const products = symbolsData?.map((item) => {
    let priceObj = symbolsCoinListPrice?.find(
      (obj) => obj.quote === item.symbol
    );
    
    return {
      id: [item?.id + item?.changes],
      Symbol: [item?.base_image_url, item?.basename, item?.base],
      Last_Price: utill.valueFix(item?.last_price, 4),
      Change: item?.changes,
      Volume: item?.volume_24,
      high_24: item?.high_24,
      Action: [item?.base, item?.quote],
    };
  });

  const columns = [
    {
      dataField: "Symbol",
      text: "Symbol",
      sort: true,
      formatter: (cell) => {
      
        return (
          <>
            <img
              src={cell[0]}
              // src="images/bitcoin.svg"
              className="img-fluid mr-1"
              alt="coin"
              width="25"
            />{" "}
            {cell[1]}
            <span className="text-gray-v fs-5v">{cell[2]}</span>
          </>
        );
      },
      headerStyle: () => {
        return { width: "22%" };
      },
      style: () => {
        return { width: "22%" };
      },
    },
    {
      dataField: "Last_Price",
      text: "Last Price(USDT)",
      sort: true,
      style: () => {
        return { textAlign: "right" };
      },
      headerStyle: () => {
        return { textAlign: "right" };
      },
    },
    {
      dataField: "Volume",
      text: "Volume",
      sort: true,
      style: () => {
        return { textAlign: "right" };
      },
      headerStyle: () => {
        return { textAlign: "right" };
      },
    },
    {
      dataField: "Change",
      text: "24h Change",
      sort: true,
      formatter: (cell) => {
        return (
          <>
            <span
              className={
                parseFloat(cell) >= 0
                  ? `text-green-v fs-4v px-2 py-1`
                  : `text-red-v fs-4v px-2 py-1`
              }
            >
              {parseFloat(cell) >= 0 ? `+` : ``}{" "}
              {utill.beautifyNumber(Number(cell), 2)} %
            </span>
          </>
        );
      },
      style: () => {
        return { textAlign: "center" };
      },
      headerStyle: () => {
        return { textAlign: "center" };
      },
    },
    {
      dataField: "Action",
      text: "Action",
      sort: true,
      formatter: (cell) => {
        let redirectUrl = `${BASE_URL.BASE_URL}exchange/${cell[0]}_${cell[1]}`;
        // let redirectUrl = `https://io.pixelsoftwares.com/exchange/v1/exchange/${cell[0]}_${cell[1]}`;
        //vynksafe.com/saving_account
        return (
          <>
            <Link
              to={redirectUrl}
              style={{ textDecoration: "none" }}
              className="tableTrade"
            >
              Trade
            </Link>
          </>
        );
      },
      style: () => {
        return { textAlign: "right" };
      },
      headerStyle: () => {
        return { textAlign: "right" };
      },
    },
  ];


  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    onPageChange: (page, sizePerPage) => {
      
      if (sectionRef.current) {
        sectionRef.current.scrollIntoView({
          behavior: 'smooth', 
        });
      }
    },
   
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "<",
    nextPageText: ">",
    
  };

  return (
    <>
      <section className="pricing_section_v common_section_v py-5" ref={sectionRef}>
        <div className="container">
          <div className="row gy-5 gx-5">
            <div className="col-md-8 heading text-center w-100 mb-3 mb-lg-3 pb-4 mx-auto">
              <h1 className="text-orange" style={{ color: "#FA501C" }}>
                Markets
              </h1>
            </div>
          </div>
          <div className="row row-cols-1">
            <div className="col">
              <ToolkitProvider
                bootstrap4
                keyField="id"
                data={products}
                columns={columns}
                rowStyle={{ cursor: "default" }}
                search
              >
                {(props) => (
                  <div className="pricing_table_v">
                  
                    <BootstrapTable
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                      headerClasses="PricerHeader"
                      filter={filterFactory()}
                      noDataIndication="There is no data"
                      className="price_table"
                      classes="crypto_price_table"
                      wrapperClasses="table-responsive"
                      striped
                      condensed
                      rowClasses="Row_class"
                      loading={true}
                      bordered={false}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Pricing;
