import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import BASE_URL from "../../_constant/index";
import { Link } from "react-router-dom";
const MarkitData = () => {
  return (
    <div className="Market_Data mt-5 p-4">
      <Container>
        <Row>
          <Col md={4} className="d-flex justify-content-center">
            <div>
              <img
                src={BASE_URL.BASE_URL + "img/Vynksafe_Api/Market Data.png"}
                alt="Market data"
                width={"70px"}
              />
              <h1 className="mb-2" style={{ fontSize: "2rem" }}>
                Market{" "}
                <span
                  className="text-orange fw-1"
                  style={{ fontSize: "2rem" }}
                >
                  {" "}
                  Data{" "}
                </span>
              </h1>
            </div>
          </Col>
          <Col md={4}>
            <div className="markit_data_card p-3">
              <p className="mb-2">
              Enhance your trading strategy with the VYNK Safe API. Trade smarter and more efficiently.
              </p>
              <div className="d-flex flex-wrap">
                
                <Link className="Spot_Trading_link mr-2 mb-2 d-flex align-items-center">
                 <div className="image_background">
                  <img
                    src={
                      BASE_URL.BASE_URL + "img/Vynksafe_Api/Market Data 1.png"
                    }
                    width={"40px"}
                    className="img-fluid img-fluid dark-theme-img"
                  />
                  <img
                    src={
                      BASE_URL.BASE_URL +
                      "img/Vynksafe_Api/Market Data 1 light.png"
                    }
                    width={"40px"}
                    className="img-fluid img-fluid light-theme-img"
                  />{" "}
                  </div>
                  Market Data
                </Link>
                <Link className="Spot_Trading_link mr-2 mb-2 d-flex align-items-center">
                <div className="image_background">
                  <img
                    src={BASE_URL.BASE_URL + "img/Vynksafe_Api/Websocket.png"}
                    width={"40px"}
                    className="img-fluid img-fluid dark-theme-img"
                  />
                  <img
                    src={
                      BASE_URL.BASE_URL + "img/Vynksafe_Api/Websocket light.png"
                    }
                    width={"40px"}
                    className="img-fluid img-fluid light-theme-img"
                  />{" "}
                  </div>
                  Websocket
                </Link>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="markit_data_card p-3 ">
              <p className="mb-2">
              Simplify Your Trading Experience- Download K-lines, Premium Index, Order Book, and much more with just One Click
              </p>
              <div className="d-flex flex-wrap">
                <Link className="Spot_Trading_link mr-2  mb-2 d-flex align-items-center">
                <div className="image_background">
                  <img
                    src={
                      BASE_URL.BASE_URL + "img/Vynksafe_Api/Historical Data.png"
                    }
                    width={"40px"}
                    className="img-fluid img-fluid dark-theme-img"
                  />
                  
                  <img
                    src={
                      BASE_URL.BASE_URL + "img/Vynksafe_Api/Historical Data light.png"
                    }
                    width={"40px"}
                    className="img-fluid img-fluid light-theme-img"
                  />{" "}
                  </div>
                  Historical Data
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MarkitData;
