import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BASE_URL from "../_constant/index";
import { user_Kyc } from '../redux/apiActions/user.action'
import { useHistory, generatePath } from "react-router-dom";
const KycVerificationPopUp = ({ Profile, active_path }) => {
    const history = useHistory();

    const [kycStatus, setKycStatus] = useState([])
    const reasonRegex = /(?<=\+ Reason : ).*/;
    let adharReason = ""
    let adharUpdatedText = ""
    let selfeeReject = ""
    let selfeeRejectUpdatedText = ""
    let panReject = ""
    let panRejectReason = ""
    if (kycStatus && kycStatus.aadhar_id_reject_message || kycStatus.driving_license_reject_message || kycStatus.passport_reject_message) {
        let idRejectReason = kycStatus.aadhar_id_reject_message?.length > 1 ? kycStatus.aadhar_id_reject_message : kycStatus.driving_license_reject_message.length > 1 ? kycStatus.driving_license_reject_message : kycStatus.passport_reject_message
        adharReason = idRejectReason?.match(reasonRegex)[0];
        adharUpdatedText = idRejectReason?.replace("+ Reason : " + adharReason, "");

    }

    if (kycStatus && kycStatus.user_selfie_reject_message) {
        selfeeReject = kycStatus.user_selfie_reject_message.match(reasonRegex)[0];
        selfeeRejectUpdatedText = kycStatus.user_selfie_reject_message.replace("+ Reason : " + selfeeReject, "");
    }
    if (kycStatus && kycStatus.pancard_reject_message) {
        panReject = kycStatus.pancard_reject_message.match(reasonRegex)[0];
        panRejectReason = kycStatus.pancard_reject_message.replace("+ Reason : " + panReject, "");
    }
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false)
        if (!Profile) {
            history.push(generatePath(BASE_URL.BASE_URL))
        }
    };

    useEffect(() => {
        const user_kyc_Details = async (formData) => {
            const response = await (user_Kyc(formData));
            if (response?.status == 200) {
                if (response?.data?.final_status == 2 && response?.data?.status_message == "KYC Completed") {
                    setShow(false)
                } else {
                    setTimeout(() => {
                        if (active_path !== "identification") {
                            setShow(true)
                            setKycStatus(response?.data)
                        }
                    }, 500)
                }
            }
        }
        user_kyc_Details();
    }, [Profile])


    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                className="withdrawal_confirmation_modal"
                contentClassName="content_Size"
            >
                <Modal.Body className="text-center">
                    <div><img src={BASE_URL.BASE_URL + "img/complete_your_kyc.png"} alt="withdrawal confirmation" width="65" className="mb-2" /></div>
                    <Modal.Title className="d-flex justify-content-center mb-3">{kycStatus?.status_message == "Please get your KYC verified." ? "Complete your KYC" : kycStatus?.status_message}</Modal.Title>
                    {/* <p className="text-secondary">{kycStatus && kycStatus?.aadhar_id_reject_message}</p>
                    <p className="text-secondary">{kycStatus && kycStatus?.user_selfie_reject_message}</p>
                    <p className="text-secondary">{kycStatus && kycStatus?.pancard_reject_message}</p> */}
                    <div className="transaction_details_Successful d-flex">
                        <ul className="mywallet-address-liststyle text-left">
                            {/* {kycStatus?.aadhar_id_reject_message || kycStatus.driving_license_reject_message || kycStatus.passport_reject_message && <li className="text-secondary">{adharUpdatedText}<br /><span className="pl-1 mt-0 text-secondary">Reason: {adharReason}</span></li>} */}
                            {(kycStatus?.aadhar_id_reject_message || kycStatus.driving_license_reject_message || kycStatus.passport_reject_message) && <li className="text-secondary">{adharUpdatedText}<br /><span className="pl-1 mt-0 text-secondary">Reason: {adharReason}</span></li>}
                            {kycStatus?.pancard_reject_message && <li className="text-secondary">{panRejectReason}<br /><span className="pl-1 mt-0 text-secondary">Reason: {panReject}</span></li>}

                            {kycStatus?.user_selfie_reject_message && <li className="text-secondary">{selfeeRejectUpdatedText}<br /><span className="pl-1 mt-0 text-secondary">Reason: {selfeeReject}</span></li>}
                            {kycStatus?.pancard_reject_message ||kycStatus?.aadhar_id_reject_message || kycStatus.driving_license_reject_message || kycStatus.passport_reject_message || kycStatus?.user_selfie_reject_message ? null :
                                <>
                                    {kycStatus?.status_message == "KYC Under Review" ?
                                        <>
                                            <li className="text-secondary">We are processing your KYC application.</li>
                                            <li className="text-secondary">Your KYC verification is currently under process and it may take upto 5 hours.</li>

                                            {/* <li className="text-secondary">
                                                Thank you for uploading all of your data. We would like to inform you that your KYC verification is currently under process and may take some time to complete. Please rest assured that we are working diligently to complete the verification process as soon as possible. We appreciate your patience and cooperation in this matter. Thank you for choosing our services.
                                            </li> */}
                                        </>

                                        :
                                        <>
                                            <li className="text-secondary">A user can complete the KYC process by submitting their official&nbsp; &nbsp;valid document ID numbers such as AADHAAR and PAN.</li>
                                            <li className="text-secondary">Users must complete the KYC via their mobile device only. Web&nbsp; &nbsp;doesn’t allow a user to upload documents for KYC completion.</li>
                                        </>
                                    }
                                </>
                            }
                        </ul>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="primary" style={{ height: "44px" }} onClick={() => {
                        history.push(`${BASE_URL.BASE_URL}profile/identification`)
                        setShow(false)
                    }}>
                        Ok
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default KycVerificationPopUp