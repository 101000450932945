import React, { useState, useEffect } from "react";
import { InputGroup, Form, Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import BASE_URL from "../../_constant/index";
import paginationFactory from "react-bootstrap-table2-paginator";
import utill from "../../_constant/utill";
import EmptyData from "../../components/emptyData";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const Spot = ({ userCoinListt, orderBalance, totalBtc, totaldoller }) => {
  const [searchText, setSearchText] = useState("");
  const [combineData, setCombineData] = React.useState([]);
  const [lowBalance, setLowBalance] = useState(false);

  const hideLowBalance = () => {
    setLowBalance(lowBalance ? false : true);
  };

  useEffect(() => {
    if (!orderBalance || !userCoinListt) return;
    const filteredCoins = userCoinListt.filter(
      (coin) => !lowBalance || parseFloat(coin.balance) !== 0
    );
    setCombineData(filteredCoins);
  }, [orderBalance, userCoinListt, lowBalance]);

  const filteredData = searchText
    ? combineData.filter((item) =>
        ["coin", "name", "symbol"].some((prop) =>
          item[prop]?.toLowerCase().includes(searchText.toLowerCase())
        )
      )
    : combineData;

  const myData = filteredData?.map((item) => ({
    id: [item.image, item.name],
    coin: [item.image, item.coin, item.name],
    balance: item.balance,
    price: [item.rate_currency],
    Btc_value: [item.balance_currency, item.amount_btc],
    action: item.symbol,
  }));

  const columns = [
    {
      dataField: "coin",
      text: "Coin",
      formatter: (cell) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div className="mr-2">
                <img src={cell[0]} width="30px" height="30px" />
              </div>
              <div>
                {cell[1].toUpperCase()} <br />{" "}
                <span
                  className="text-secondary"
                  style={{ fontSize: "11px", wordWrap: "normal" }}
                >
                  <b>{cell[2]}</b>
                </span>
              </div>
            </div>
          </>
        );
      },
      headerStyle: () => {
        return { width: "25%" };
      },
      style: () => {
        return { width: "25%" };
      },
    },
    {
      dataField: "balance",
      text: "Balance",
      headerStyle: () => {
        return { width: "13%" };
      },
      style: () => {
        return { width: "13%" };
      },
      formatter: (cell) => {
        return <>{utill?.beautifyNumber(cell, 4)}</>;
      },
    },
    {
      dataField: "price",
      text: "Price",
      formatter: (cell) => {
        return (
          <>
            {utill.valueFix(parseFloat(cell))}
            {/* {utill?.beautifyNumber(cell, 4)} */}
          </>
        );
      },
      headerStyle: () => {
        return { width: "13%" };
      },
      style: () => {
        return { width: "13%" };
      },
    },

    {
      dataField: "Btc_value",
      text: "BTC Value",
      formatter: (cell) => {
        return (
          <>
            <div>
              {utill?.beautifyNumber(cell[1], 6)}
              <p className="text-secondary">
                ≈ ${utill.beautifyNumber(cell[0], 2)}
              </p>
            </div>
          </>
        );
      },
      headerStyle: () => {
        return { width: "13%" };
      },
      style: () => {
        return { width: "13%" };
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell) => {
        let redirectUrl = `${BASE_URL.BASE_URL}Mywallet/Overview?to=`;
        return (
          <>
            <div className="estimated-balance-btn justify-content-left">
              <Link
                to={`${redirectUrl}deposit?coin=${cell}`}
                style={{ color: "#fa501c", textDecoration: "none" }}
                className="p2"
              >
                Deposit
              </Link>
              <Link
                to={`${redirectUrl}withdraw?coin=${cell}`}
                style={{ color: "#fa501c", textDecoration: "none" }}
                className="p2 mr-4 ml-4"
              >
                Withdraw
              </Link>
              <Link
                to={`${BASE_URL.BASE_URL}Mywallet/TransactionHistory?coin=${cell}`}
                style={{ color: "#fa501c", textDecoration: "none" }}
                className="p2"
              >
                History
              </Link>
            </div>
          </>
        );
      },
    },
  ];

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    hideSizePerPage: true, 
    hidePageListOnlyOnePage: true, 
    firstPageText: "First",
    prePageText: "<",
    nextPageText: ">",
    
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="settings_top_title d-flex flex-wrap justify-content-between align-items-center">
            <div
              className="spot-balance-section mtb15 pb-3 d-flex flex-wrap justify-content-between"
              style={{ borderBottom: "1px solid #5e728f4d" }}
            >
              <div>
                <h5 className="card-title">Spot Balance</h5>
                <div className="card-estimated-balance-section mt15 pb-3">
                  <h3 className="card-estimated-balance">
                    {utill.beautifyNumber(totalBtc, 6)} BTC{" "}
                    <span className="text-secondary">
                      ≈ ${utill.beautifyNumber(totaldoller, 2)}
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body pt-0">
          <Row className="mb-3">
          <Col md={6}>
          <div>
                 <h5 className="card-title">Crypto Balance</h5>
                  </div>
            </Col>
            <Col md={6}>
              <div className="d-sm-flex align-items-center">
                <div className="ml-sm-5 w-100 hidesmallbalances_checkbox mt-2 mt-sm-0">
                  <Form.Group controlId="formBasicCheckbox" className="mb-0">
                    <label className="form__check  check">
                      <input
                        className="check__input visually-hidden"
                        onChange={hideLowBalance}
                        type="checkbox"
                      />
                      <span className="check__box"></span>
                      <span className="check__text">Hide Small Balances</span>
                    </label>
                  </Form.Group>
                </div>
                <div className="w-100">
                  <InputGroup className="wallet_crypto_balance_search">
                    <Form.Control
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      value={searchText || ""}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                    />
                    <InputGroup.Text
                      id="basic-addon2"
                      className="explore_search_bar"
                      style={{ borderRadius: "0px 4px 4px 0px" }}
                    >
                      <img
                        src={`${BASE_URL.BASE_URL}img/dashboard/Search.png`}
                        alt=""
                        width="30px"
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </div>
              </div>
            </Col>
          </Row>

          <div className="crypto_balance_table_main Funding_wallet">
            <BootstrapTable
              bootstrap4
              rowStyle={{ cursor: "default" }}
              keyField="id"
              headerClasses="crypto_balance_table_header"
              bodyClasses="crypto_balance_table_body"
              data={myData}
              columns={columns}
              noDataIndication={<EmptyData />}
              pagination={paginationFactory(options)}
              className="table"
              classes="crypto_balance_table"
              bordered={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Spot;
