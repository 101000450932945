import { configureStore } from "@reduxjs/toolkit";
import reducers from "./reducers";
import { persistStore, persistReducer } from "redux-persist";

import thunk from 'redux-thunk'
import storage from "redux-persist/lib/storage";
const persistConfig = {
    key: "root",
    storage: storage,
    whitelist: ['user']
  };

  const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});