import React, { useEffect, useState } from "react";
import BASE_URL from "../_constant/index";
import { Link } from "react-router-dom";
import { useHistory, generatePath } from "react-router-dom";
import { change_forgotPassword } from "../redux/apiActions/otp.verify.action";
import { useDispatch, useSelector } from "react-redux";

import validator from "validator";

const ResetPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoader] = React.useState(false);
  const [email, setemail] = React.useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [c_passwordShown, setC_passwordShown] = useState(false);

  const userData = useSelector((state) => state.user.userData);
  useEffect(() => {
    if (userData?.access_token) {
      history.push(generatePath(BASE_URL.BASE_URL));
    }
  }, [userData]);

  useEffect(() => {
    setemail(localStorage.getItem("E-mail"));
  }, []);

  const togglePassword = (e, type) => {
    if (type === "password") {
      setPasswordShown(passwordShown ? false : true);
    } else if (type === "c_password") {
      setC_passwordShown(c_passwordShown ? false : true);
    }
    e.preventDefault();
  };

  const [errorsMsg, setErrorMsg] = useState({
    passwordError: "",
    cm_passwordError: "",
  });

  const [fieldsData, setFieldsData] = useState({
    password: "",
    cm_password: "",
  });

  const handleFieldsChange = async (val, fieldName, e) => {
    try {
      if (fieldName === "password") {
        let password = val;
        let message = "";
        if (!password) {
          message = "Password address is required.";
        } else if (
          password !== "" &&
          !validator.isStrongPassword(password, {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
          })
        ) {
          message =
            "Password must contain at least 8 characters including uppercase, lowercase, numbers and special characters";
        }
        setErrorMsg((preState) => {
          let obj = Object.assign({}, preState);
          obj.passwordError = message;
          return obj;
        });
        setFieldsData((preState) => {
          let obj = Object.assign({}, preState);
          obj.password = val;
          return obj;
        });
      } else if (fieldName === "c_password") {
        // let cm_password = val?.target?.value;
        let message = "";
        if (!val) {
          message = "Confirm password is required." + fieldsData?.password;
        } else if (val !== "" && fieldsData?.password !== val) {
          message = "Confirm password and password must be the same.";
        }
        setErrorMsg((preState) => {
          let obj = Object.assign({}, preState);
          obj.cm_passwordError = message;
          return obj;
        });
        setFieldsData((preState) => {
          let obj = Object.assign({}, preState);
          obj.cm_password = val;
          return obj;
        });
      } else {
        let message = "";
        if (fieldsData.password !== fieldsData.cm_password) {
          message = "Confirm password is required.";
        } else {
          let formData = new FormData();
          formData.append("email", email);
          formData.append("password", fieldsData?.password);
          await dispatch(change_forgotPassword(formData, history));
          localStorage.removeItem("E-mail");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onClickSubmit = (e) => {
    let message = "Confirm password is required.";

    e.preventDefault();
    setLoader(true);
    if (fieldsData?.password === fieldsData?.cm_password) {
      if (fieldsData?.password != "") {
        handleFieldsChange();
        setLoader(false);
      }
      setFieldsData({
        password: "",
        cm_password: "",
      });
    } else {
      setErrorMsg({
        cm_passwordError: message,
      });

      setLoader(false);
    }
  };
  const showEye =
    "M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z";
  const hideEye =
    "M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z";

  return (
    <>
      <div className="basic_pages">
        <div
          className="row row-cols-1 row-cols-md-2 row-cols-md-1 row-cols-sm-1 align-items-center gap-v-4"
          style={{ height: "100%" }}
        >
          <div className="col d-flex align-items-center justify-content-center">
            <div className="form-access">
              <form>
              <h3 className="mb-3 Welcome" >Change<span className="text-orange" >Password</span></h3>
                <div className="form-group ">
                  <div className="password_block">
                    <input
                      id="password"
                      type={passwordShown ? "text" : "password"}
                      name="password"
                      placeholder="Enter New Password"
                      className={`form-control ${
                        errorsMsg?.passwordError &&
                        errorsMsg?.passwordError !== ""
                          ? "error-active"
                          : ""
                      }`}
                      onChange={(e) => {
                        setErrorMsg((preState) => {
                          let obj = Object.assign({}, preState);
                          obj.passwordError = "";
                          return obj;
                        });
                        handleFieldsChange(e.target.value, "password");
                      }}
                      value={fieldsData?.password}
                    />
                    <div
                      onClick={(e) => {
                        togglePassword(e, "password");
                      }}
                      className="password_hideshow_btn btn sm-btn"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#fff"
                          d={passwordShown ? showEye : hideEye}
                        />
                      </svg>
                    </div>
                  </div>
                  <small className="error-message text-danger text-wrap">
                    {errorsMsg?.passwordError}
                  </small>
                </div>

                <div className="form-group">
                  <div className="password_block confirm_password">
                    <input
                      type={c_passwordShown ? "text" : "password"}
                      name="confirm_password"
                      placeholder="Confirm New Password"
                      className={`form-control ${
                        errorsMsg?.cm_passwordError &&
                        errorsMsg?.cm_passwordError !== ""
                          ? "error-active"
                          : ""
                      }`}
                      onChange={(e) => {
                        setErrorMsg((preState) => {
                          let obj = Object.assign({}, preState);
                          obj.cm_passwordError = "";
                          return obj;
                        });
                        handleFieldsChange(e.target.value, "c_password");
                      }}
                    />
                    <div
                      onClick={(e) => {
                        togglePassword(e, "c_password");
                      }}
                      className="password_hideshow_btn btn sm-btn"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#fff"
                          d={c_passwordShown ? showEye : hideEye}
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="error-message text-danger">
                    {errorsMsg?.cm_passwordError}
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-center justify-content-sm-between flex-wrap gap-v-5">
                  <button
                    className="btn btn-primary signinButton mt-0"
                    disabled={
                      (loading && fieldsData?.password !== "" ? true : false) ||
                      fieldsData?.password.length == 0
                    }
                    onClick={onClickSubmit}
                  >
                    {loading ? "Please wait..." : "Change Password"}
                  </button>
                  <h2>
                    Remember Password?
                    <Link
                      to={BASE_URL.BASE_URL + "login"}
                      className="text-orange"
                    >
                      {" "}
                      Sign in here
                    </Link>
                  </h2>
                </div>
              </form>
            </div>
          </div>
          <div
            className="col px-lg-5 px-3 login-background d-flex align-items-center justify-content-center"
            style={{ height: "100%" }}
          >
            <div className="p-4 d-flex align-items-center justify-content-center w-100">
              <img
                src={`${BASE_URL.BASE_URL}img/images/SignUp.svg`}
                className="img-fluid light-theme-img"
                alt="banner"
                width="450"
              />
              <img
                src={`${BASE_URL.BASE_URL}img/images/Sign.png`}
                className="img-fluid dark-theme-img"
                alt="banner"
                width="450"
              />
              {/* <img alt="Sign page" width="400px" />
              <img alt="Sign page" width="400px" /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ResetPassword;
