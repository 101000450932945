import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import BASE_URL from "../../_constant/index";

const HeroSection = () => {
  return (
    <div className="hero_section">
      <Container>
        <Row className="">
          <Col md={6} className="d-flex align-items-center justify-content-center">
            <div>
              <h1 className="mb-2">
                VYNKSAFE{" "}
                <span
                  className="text-orange fw-1"
                  style={{ fontSize: "2.5rem" }}
                >
                  {" "}
                  API{" "}
                </span>
              </h1>
              <h5 className="">
              One Key Opens a World of <br/> Opportunities!
              </h5>
            </div>
          </Col>
          <Col md={6}>
            <img
              src={BASE_URL.BASE_URL + "img/Vynksafe_Api/Banner Vector.png"}
              className="img-fluid dark-theme-img mt-2"
              alt="VYNKSAFE API"
              width={"100%"}
            />
             <img
              src={BASE_URL.BASE_URL + "img/Vynksafe_Api/Banner Vector light.png"}
              className="img-fluid light-theme-img mt-2"
              alt="VYNKSAFE API"
              width={"100%"}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroSection;
