import React, { useEffect, useState } from "react";
import { Link, useHistory, generatePath, useLocation } from "react-router-dom";
import BASE_URL from "../_constant/index";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { registerAction } from "../redux/apiActions/register.action";
import Loader from "../components/loader";
import {
  All_CountriesAction,
  All_CurrencyAction,
} from "../redux/apiActions/api.action";
import validator from "validator";
import { toast } from "../components/Toast/Toast";
import { Form } from "react-bootstrap";
import LoginWithGoogle from "./LoginWithGoogle";

const SignUp = () => {
  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  const location = useLocation();
  const [paramEmail, setParamEmail] = useState("");
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    let getEmail = searchParams.get("email");
    setParamEmail(getEmail);

    if (getEmail) {
      setFieldsData({
        email: getEmail,
      });
    } else {
      setFieldsData({
        email: "",
      });
    }
  }, []);

  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state.user.userData);
  // const [allCountry, setAllCountryData] = useState([]);
  // const [allCurrency, setAllCurrency] = useState([]);
  const [errorsMsg, setErrorMsg] = useState({
    // countryError: "",
    // currencyError: "",
    emailError: "",
    passwordError: "",
    cm_passwordError: "",
    acceptTermError: "",
  });

  const [fieldsData, setFieldsData] = useState({
    country: "",
    currency: "",
    email: "",
    password: "",
    cm_password: "",
    acceptTerm: "",
  });
  const [loading, setLoading] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);

  const showEye =
    "M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z";
  const hideEye =
    "M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z";
  useEffect(async () => {
    if (!userData?.access_token) {
      setLoading(true);
      // const all_country_list = async () => {
      //   const response = await All_CountriesAction();
      //   setAllCountryData(
      //     response?.data.map((val) => {
      //       val.value = val.id;
      //       val.label = (
      //         <div className="option_block">
      //           <img
      //             src={response?.base_url + val.country_image}
      //             alt={val?.country_name}
      //           />
      //           <span>{val.country_name}</span>
      //         </div>
      //       );
      //       // val.text = val.country_name;

      //       val.icon = (
      //         <img
      //           src={response?.base_url + val.country_image}
      //           alt={val?.country_name}
      //         />
      //       );
      //       return val;
      //     })
      //   );
      // };
      // const all_currency_list = async () => {
      //   const response = await All_CurrencyAction();
      //   setAllCurrency(
      //     response?.data.map((val) => {
      //       val.value = val.id;
      //       val.label = (
      //         <div className="option_block">
      //           <span>{`${val.currency_name}(${val.currency_code})`}</span>
      //         </div>
      //       );
      //       return val;
      //     })
      //   );
      // };
      // await all_country_list();
      // await all_currency_list();
      setLoading(false);
    } else {
      history.push(generatePath(BASE_URL.BASE_URL + `profile/dashboard`));
    }
    // }, [userData, setLoading, setAllCountryData, setAllCurrency, history]);
  }, [userData, setLoading, history]);

  // Hide Password
  const [passwordShown, setPasswordShown] = useState(false);
  const [c_passwordShown, setC_passwordShown] = useState(false);
  const togglePassword = (e, type) => {
    if (type === "password") {
      setPasswordShown(passwordShown ? false : true);
    } else if (type === "c_password") {
      setC_passwordShown(c_passwordShown ? false : true);
    }
    e.preventDefault();
  };

  const handleFieldsChange = (val, fieldName) => {
    // if (fieldName === "country" || fieldName === "currency") {
    //   if (val) {
    //     setFieldsData((preState) => {
    //       let obj = Object.assign({}, preState);
    //       if (fieldName === "country") {
    //         obj.country = val;
    //       } else {
    //         obj.currency = val;
    //       }
    //       return obj;
    //     });
    //   }
    // } else
    if (fieldName === "email") {
      let email = val;
      let message = "";
      if (!email) {
        message = "Email address is required.";
      } else if (email !== "" && !validator.isEmail(email)) {
        message = "Invalid email address";
      }
      setErrorMsg((preState) => {
        let obj = Object.assign({}, preState);
        obj.emailError = message;
        return obj;
      });
      setFieldsData((preState) => {
        let obj = Object.assign({}, preState);
        obj.email = email;
        return obj;
      });
    } else if (fieldName === "password") {
      let password = val;
      let message = "";
      if (!password) {
        message = "Password is required.";
      } else if (
        password !== "" &&
        !validator.isStrongPassword(password, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        message =
          "Password must contain at least 8 characters including Uppercase, Lowercase, Numbers and Special characters";
      }
      setErrorMsg((preState) => {
        let obj = Object.assign({}, preState);
        obj.passwordError = message;
        return obj;
      });
      setFieldsData((preState) => {
        let obj = Object.assign({}, preState);
        obj.password = val;
        return obj;
      });
    } else if (fieldName === "c_password") {
      // let cm_password = val?.target?.value;
      let message = "";
      if (!val) {
        message = "Confirm password is required.";
      } else if (val !== "" && fieldsData?.password !== val) {
        message = "Confirm password and password must be the same.";
      }
      setErrorMsg((preState) => {
        let obj = Object.assign({}, preState);
        obj.cm_passwordError = message;
        return obj;
      });
      setFieldsData((preState) => {
        let obj = Object.assign({}, preState);
        obj.cm_password = val;
        return obj;
      });
    } else if (fieldName === "acceptTerm") {
      // let cm_password = val?.target?.value;
      let message = "";
      if (!val) {
        message = "Please accept the terms and conditions";
      }
      setErrorMsg((preState) => {
        let obj = Object.assign({}, preState);
        obj.acceptTermError = message;
        return obj;
      });
      setFieldsData((preState) => {
        let obj = Object.assign({}, preState);
        obj.acceptTerm = val;
        return obj;
      });
    }
  };

  // const setWithdrawAmount = (e) => {
  //   let value = isNumberKey(e.target.value);

  //   if (value !== false) {
  //     setErrorMsg((preState) => {
  //       let obj = Object.assign({}, preState);
  //       obj.amountError = value || value > 0 ? "" : "Invalid value";
  //       obj.currencyError = value || value > 0 ? "" : "Invalid Currency Value";
  //       return obj;
  //     });
  //     setWithdrawCoinData((preState) => {
  //       let obj = Object.assign({}, preState);
  //       obj.amount = value;
  //       obj.amount_currency = (value * withdrawCoinData.price).toFixed(2);
  //       return obj;
  //     });
  //   }
  // };

  // const allDataHandler = () => {
  //   setAllData({
  //     email: email,
  //     currency: "1",
  //     password: password,
  //     country: countryValue?.std_code,
  //     cm_password: cm_password,
  //     acceptTerm: acceptTerm,
  //   });
  // };

  const submitFormHandler = async () => {
    let errorMessage = {
      // countryError: "",
      // currencyError: "",
      emailError: "",
      passwordError: "",
      // cm_passwordError: "",
    };

    let isError = false;

    // if (!fieldsData?.country) {
    //   errorMessage.countryError = "Please select a country";
    //   isError = true;
    // }

    // if (!fieldsData?.currency) {
    //   errorMessage.currencyError = "Please select a currency";
    //   isError = true;
    // }
    if (!fieldsData?.email) {
      errorMessage.emailError = "Please provide an email address";
      isError = true;
    } else if (
      fieldsData?.email !== "" &&
      !validator.isEmail(fieldsData?.email)
    ) {
      errorMessage.emailError = "Invalid email address";
      isError = true;
    }
    if (!fieldsData?.password) {
      errorMessage.passwordError = "Password is required.";
      isError = true;
    } else if (
      fieldsData?.password !== "" &&
      !validator.isStrongPassword(fieldsData?.password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      errorMessage.passwordError =
        "Password must contain at least 8 characters including uppercase, lowercase, numbers and special characters";
      isError = true;
    }

    // if (!fieldsData?.cm_password) {
    //   errorMessage.cm_passwordError = "Confirm password is required.";
    //   isError = true;
    // } else if (
    //   fieldsData?.cm_password !== "" &&
    //   fieldsData?.password !== fieldsData?.cm_password
    // ) {
    //   errorMessage.cm_passwordError =
    //     "Confirm password and password must be the same.";
    //   isError = true;
    // }

    if (!fieldsData?.acceptTerm) {
      errorMessage.acceptTermError =
        "Please accept the terms and conditions.";
      isError = true;
    }
    setErrorMsg(errorMessage);
    if (isError === false) {
      let formData = new FormData();
      formData.append("email", fieldsData.email);
      formData.append("password", fieldsData.password);
      // formData.append("country", fieldsData.country.id);
      // formData.append("currency", fieldsData.currency.id);
      dispatch(registerAction(formData, history));
      setBtnLoader(true);
      setTimeout(() => {
        setBtnLoader(false);
      }, 2000);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="basic_pages sign_up_page">
          {/* <div className="form-access my-auto">
            <form>
              <span>Create Account</span>
              <div
                className={`form-group ${errorsMsg?.countryError && errorsMsg?.countryError !== ""
                  ? "active"
                  : ""
                  }`}
              >

                <Select
                  className="wallet_network_select custom_select-box"
                  classNamePrefix="custom_select"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? "grey" : "red",
                    }),
                  }}
                  isSearchable={true}
                  filterOption={(options, searchText) => {
                    if (
                      options.data.country_name
                        .toLowerCase()
                        .startsWith(searchText.toLowerCase())
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  }}

                  name="selectCountry"
                  placeholder="Select country"
                  value={fieldsData?.country ? fieldsData?.country : null}
                  options={allCountry}
                  onChange={(e) => {
                    handleFieldsChange(e, "country");
                    setErrorMsg((preState) => {
                      let obj = Object.assign({}, preState);
                      obj.countryError = "";
                      return obj;
                    });
                  }}
                />
                <small className="error-message text-danger">
                  {errorsMsg?.countryError}
                </small>
              </div>

              <div
                className={`form-group ${errorsMsg?.countryError && errorsMsg?.countryError !== ""
                  ? "active"
                  : ""
                  }`}
              >

                <Select
                  placeholder="Select currency"
                  className="wallet_network_select custom_select-box"
                  classNamePrefix="custom_select"
                  isSearchable={true}
                  name="selectCurrency"
                  value={fieldsData?.currency ? fieldsData?.currency : null}
                  options={allCurrency}
                  filterOption={(options, searchText) => {
                    if (
                      options.data.currency_name
                        .toLowerCase()
                        .startsWith(searchText.toLowerCase()) ||
                      options.data.currency_code
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  }}
                  onChange={(e) => {
                    handleFieldsChange(e, "currency");
                    setErrorMsg((preState) => {
                      let obj = Object.assign({}, preState);
                      obj.currencyError = "";
                      return obj;
                    });
                  }}
                />

                <div className="error-message text-danger">
                  {errorsMsg?.currencyError}
                </div>
              </div>

              <div className="form-group">
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Enter email address"
                  className={`form-control ${errorsMsg?.emailError && errorsMsg?.emailError !== ""
                    ? "error-active"
                    : ""
                    }`}
                  value={fieldsData?.email ? fieldsData?.email : ""}
                  onChange={(e) => {
                    setErrorMsg((preState) => {
                      let obj = Object.assign({}, preState);
                      obj.emailError = "";
                      return obj;
                    });
                    handleFieldsChange(e.target.value, "email");
                  }}
                />
                <div className="error-message text-danger">
                  {errorsMsg?.emailError}
                </div>
              </div>

              <div className="form-group ">
                <div className="password_block">
                  <input
                    id="password"
                    type={passwordShown ? "text" : "password"}
                    name="password"
                    placeholder="Enter password"
                    className={`form-control ${errorsMsg?.passwordError &&
                      errorsMsg?.passwordError !== ""
                      ? "error-active"
                      : ""
                      }`}
                    onChange={(e) => {
                      setErrorMsg((preState) => {
                        let obj = Object.assign({}, preState);
                        obj.passwordError = "";
                        return obj;
                      });
                      handleFieldsChange(e.target.value, "password");
                    }}
                    value={fieldsData?.password}
                  />
                  <div
                    onClick={(e) => {
                      togglePassword(e, "password");
                    }}
                    className="password_hideshow_btn btn sm-btn"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path fill="#fff" d={passwordShown ? showEye : hideEye} />
                    </svg>
                  </div>
                </div>
                <small className="error-message text-danger text-wrap">
                  {errorsMsg?.passwordError}
                </small>
              </div>

              <div className="form-group">
                <div className="password_block confirm_password">
                  <input
                    type={c_passwordShown ? "text" : "password"}
                    name="confirm_password"
                    placeholder="Confirm password"
                    className={`form-control ${errorsMsg?.cm_passwordError &&
                      errorsMsg?.cm_passwordError !== ""
                      ? "error-active"
                      : ""
                      }`}
                    onChange={(e) => {
                      setErrorMsg((preState) => {
                        let obj = Object.assign({}, preState);
                        obj.cm_passwordError = "";
                        return obj;
                      });
                      handleFieldsChange(e.target.value, "c_password");
                    }}
                  />
                  <div
                    onClick={(e) => {
                      togglePassword(e, "c_password");
                    }}
                    className="password_hideshow_btn btn sm-btn"
                  // id={
                  //   !cm_password || password != cm_password ? "eye_hide" : ""
                  // }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#fff"
                        d={c_passwordShown ? showEye : hideEye}
                      />
                    </svg>
                  </div>
                </div>
                <div className="error-message text-danger">
                  {errorsMsg?.cm_passwordError}
                </div>
              </div>

              <div className="accept_term_block">
                <div className="custom-control custom-checkbox">
                  <input
                    id="form-checkbox"
                    type="checkbox"
                    className="custom-control-input"
                    name="acceptTerms"
                    checked={fieldsData?.acceptTerm}
                    onChange={(e) => {
                      handleFieldsChange(e.target.checked, "acceptTerm");
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="form-checkbox"
                  >
                    I agree to the{" "}
                    <Link style={{ color: "#FA501C" }} to={BASE_URL.BASE_URL + "terms-and-conditions"} target="_blank" rel="noopener noreferrer">
                      Terms & Conditions
                    </Link>

                  </label>
                </div>

                <div className="error-message text-danger">
                  {errorsMsg?.acceptTermError}
                </div>
              </div>

              <button
                type="button"
                className="btn btn-primary sign_form_submit"
                disabled={
                  btnLoader && (fieldsData?.email !== "") ? true : false
                }
                onClick={() => {
                  submitFormHandler();

                }}
              >
                {btnLoader ? "Please wait..." : "Create Account"}

              </button>
            </form>

            <h2>
              Already have an account?
              <Link to={BASE_URL.BASE_URL + "login"} style={{ color: "#FA501C" }}> Sign in here</Link>
            </h2>
          </div> */}
          <div
            className="row row-cols-1 row-cols-md-2 row-cols-md-1 row-cols-sm-1 align-items-center gap-v-4"
            style={{ height: "100%", overflow: "hidden" }}
          >
            <div
              className="col d-flex align-items-center justify-content-center "
              style={{ height: "100%", overflow: "auto" }}
            >
              <div className="form-access create_account pt-2">
                <form className="mt-0">
                  <h3 className="Welcome mb-3">
                    Create <span className="text-orange set_font">Account</span>
                  </h3>
                  {/*   <div
                    className={`form-group ${errorsMsg?.countryError && errorsMsg?.countryError !== ""
                        ? "active"
                        : ""
                      }`}
                  >
                    <Form.Label>Select country</Form.Label>
                     <Select
                      placeholder=" Select country"
                      className="wallet_network_select custom_Curency-box"
                      classNamePrefix="custom_select"
                      isSearchable={true}
                      name="Assets"
                      options={allCountry}
                      value={fieldsData?.country ? fieldsData?.country : null}
                      onChange={(e) => {
                        handleFieldsChange(e, "country");
                        setErrorMsg((preState) => {
                          let obj = Object.assign({}, preState);
                          obj.countryError = "";
                          return obj;
                        });
                      }}
                      getOptionValue={(option) => option?.value}
                      getOptionLabel={(option) => (
                        <div className="d-flex align-items-center">

                          {option.label || option?.symbol}
                        </div>
                      )}
                    /> */}
                  {/* <Select
                       className="wallet_network_select custom_Curency-box"
                      classNamePrefix="custom_select"
                      isSearchable={true}
                      // styles={style}
                      filterOption={(options, searchText) => {
                        if (
                          options.data.country_name
                            .toLowerCase()
                            .startsWith(searchText.toLowerCase())
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                      name="selectCountry"
                      placeholder=" Select country"
                      value={fieldsData?.country ? fieldsData?.country : null}
                      options={allCountry}
                      getOptionValue={(option) => option?.value}
                      getOptionLabel={(option) => (
                        <div className="d-flex align-items-center"> */}
                  {/* <img src={`${option.image}`}
                                                                                    alt={option?.label || option?.symbol} width='25' className='mr-2' /> */}
                  {/* {option.label || option?.symbol}
                        </div>
                      )}
                      onChange={(e) => {
                        handleFieldsChange(e, "country");
                        setErrorMsg((preState) => {
                          let obj = Object.assign({}, preState);
                          obj.countryError = "";
                          return obj;
                        });
                      }}
                    />
                    <small className="error-message text-danger">
                      {errorsMsg?.countryError}
                    </small>
                  </div> */}

                  {/* <div
                    className={`form-group ${
                      errorsMsg?.countryError && errorsMsg?.countryError !== ""
                        ? "active"
                        : ""
                    }`}
                  >
                    <Form.Label>Select currency</Form.Label>
                    <Select
                      placeholder="Select currency"
                      className="wallet_network_select custom_select-box"
                      classNamePrefix="custom_select"
                      isSearchable={true}
                      styles={style}
                      name="selectCurrency"
                      value={fieldsData?.currency ? fieldsData?.currency : null}
                      options={allCurrency}
                      filterOption={(options, searchText) => {
                        if (
                          options.data.currency_name
                            .toLowerCase()
                            .startsWith(searchText.toLowerCase()) ||
                          options.data.currency_code
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                      onChange={(e) => {
                        handleFieldsChange(e, "currency");
                        setErrorMsg((preState) => {
                          let obj = Object.assign({}, preState);
                          obj.currencyError = "";
                          return obj;
                        });
                      }}
                    />

                    <div className="error-message text-danger">
                      {errorsMsg?.currencyError}
                    </div>
                  </div> */}

                  <div className="form-group">
                    <Form.Label>Enter Email</Form.Label>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Enter email address"
                      className={`form-control ${
                        errorsMsg?.emailError && errorsMsg?.emailError !== ""
                          ? "error-active"
                          : ""
                      }`}
                      value={fieldsData?.email ? fieldsData?.email : ""}
                      onChange={(e) => {
                        setErrorMsg((preState) => {
                          let obj = Object.assign({}, preState);
                          obj.emailError = "";
                          return obj;
                        });
                        handleFieldsChange(e.target.value, "email");
                      }}
                      autoComplete="off"
                    />
                    <div className="error-message text-danger mt-1">
                      {errorsMsg?.emailError}
                    </div>
                  </div>

                  <div className="form-group ">
                    <div className="password_block">
                      <Form.Label>Enter Password</Form.Label>
                      <div className="position-relative">
                        <input
                          id="password"
                          type={passwordShown ? "text" : "password"}
                          name="password"
                          placeholder="Enter password"
                          className={`form-control ${
                            errorsMsg?.passwordError &&
                            errorsMsg?.passwordError !== ""
                              ? "error-active"
                              : ""
                          }`}
                          onChange={(e) => {
                            setErrorMsg((preState) => {
                              let obj = Object.assign({}, preState);
                              obj.passwordError = "";
                              return obj;
                            });
                            handleFieldsChange(e.target.value, "password");
                          }}
                          value={fieldsData?.password}
                          autoComplete="off"
                        />
                        <div
                          onClick={(e) => {
                            togglePassword(e, "password");
                          }}
                          className="password_hideshow_btn btn sm-btn"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d={passwordShown ? showEye : hideEye}
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <small className="error-message text-danger text-wrap m-1">
                      {errorsMsg?.passwordError}
                    </small>
                  </div>

                  {/* <div className="form-group">
                    <div className="password_block confirm_password">
                      <Form.Label>Confirm password</Form.Label>
                      <div className="position-relative">
                        <input
                          type={c_passwordShown ? "text" : "password"}
                          name="confirm_password"
                          placeholder="Confirm password"
                          className={`form-control ${errorsMsg?.cm_passwordError &&
                            errorsMsg?.cm_passwordError !== ""
                            ? "error-active"
                            : ""
                            }`}
                          onChange={(e) => {
                            setErrorMsg((preState) => {
                              let obj = Object.assign({}, preState);
                              obj.cm_passwordError = "";
                              return obj;
                            });
                            handleFieldsChange(e.target.value, "c_password");
                          }}
                          autoComplete="off"
                        />
                        <div
                          onClick={(e) => {
                            togglePassword(e, "c_password");
                          }}
                          className="password_hideshow_btn btn sm-btn"
                        // id={
                        //   !cm_password || password != cm_password ? "eye_hide" : ""
                        // }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d={c_passwordShown ? showEye : hideEye}
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="error-message text-danger">
                      {errorsMsg?.cm_passwordError}
                    </div>
                  </div> */}

                  <div className="accept_term_block">
                    <div className="custom-control custom-checkbox">
                      <input
                        id="form-checkbox"
                        type="checkbox"
                        className="custom-control-input"
                        name="acceptTerms"
                        checked={fieldsData?.acceptTerm}
                        onChange={(e) => {
                          handleFieldsChange(e.target.checked, "acceptTerm");
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="form-checkbox"
                      >
                        I agree to the{" "}
                        <Link
                          style={{ color: "#FA501C" }}
                          to={BASE_URL.BASE_URL + "terms-and-conditions"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms & Conditions
                        </Link>
                      </label>
                    </div>

                    <div className="error-message text-danger mt-1">
                      {errorsMsg?.acceptTermError}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-sm-between justify-content-center mt-4 flex-wrap gap-v-5 mt-2">
                    <button
                      type="button"
                      className="btn btn-primary signinButton mt-0"
                      disabled={
                        btnLoader && fieldsData?.email !== "" ? true : false
                      }
                      onClick={() => {
                        submitFormHandler();
                      }}
                    >
                      {btnLoader ? "Please wait..." : "Create Account"}
                    </button>
                    <h2>
                      Already have an account?
                      <Link
                        to={BASE_URL.BASE_URL + "login"}
                        style={{ color: "#FA501C" }}
                      >
                        {" "}
                        Sign in here
                      </Link>
                    </h2>
                  </div>
                 
                </form>
                <div className="d-flex justify-content-sm-between justify-content-center flex-wrap gap-v-5 mt-2">
                    <LoginWithGoogle />
                    {/* <LoginWithGoogle /> */}
                    {/* <LoginWithApple/> */}
                  </div>
              </div>
            </div>
            <div
              className="col px-lg-5 px-3 login-background d-flex align-items-center justify-content-center"
              style={{ height: "100%", overflow: "hidden" }}
            >
              <div className="p-4 d-flex align-items-center justify-content-center w-100">
                <img
                  src={`${BASE_URL.BASE_URL}img/images/SignUp.svg`}
                  className="img-fluid light-theme-img"
                  alt="banner"
                  width="450"
                />
                <img
                  src={`${BASE_URL.BASE_URL}img/images/Sign.png`}
                  className="img-fluid dark-theme-img"
                  alt="banner"
                  width="450"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignUp;
