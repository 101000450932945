import React from 'react'
import BASE_URL from "../_constant/index";
import Commingsoon from "./Commingsoon";


export const Product = () => {
  return (
    <div id="content">
    <Commingsoon />
  </div>
  )
}
