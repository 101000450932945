import React, { useState, useEffect } from 'react'
import {
    Row,
    Col,
    Container,
    Tab,
    Nav,
    Sonnet
} from 'react-bootstrap'
import BASE_URL from "../../_constant/index";
import BASE_URL_Tow from "../../_constant/index";
import { useHistory } from 'react-router-dom'
import Alloffertable from './Alloffertable';
import { getFundWalletBalance, handleOpenTrade, userFeedbackList } from '../../redux/apiActions/p2p.action'
import { toast } from "../../components/Toast/Toast";
import FeedBack from './FeedBack';
import Spinner from 'react-bootstrap/Spinner';
const UserProfile = ({ userData, setFeedBack, feedBack, setUser, handleFeedBackTabChange, feedbackTab, userFeedBack, loading }) => {

    const [activeTab, setActiveTab] = useState('Online_Ads');
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    const total = userData?.user_data?.positive_feedback_tot + userData?.user_data?.negative_feedback_tot
    // Calculate percentages
    const positivePercentage = (userData?.user_data?.positive_feedback_tot / total) * 100;
    const negativePercentage = (userData?.user_data?.negative_feedback_tot / total) * 100;


    const history = useHistory();
    const [radioValue, setRadioValue] = useState("p2p-buy");
    const [buttonDisable, setButtonDisable] = useState(false);
    const [userOffer, setUserOffer] = useState(null)
    const [userOfferSell, setUserOfferSell] = useState(null)
    const [page, setPage] = useState(1)
    const [balance, setBalance] = useState("")
    const [pay, setPay] = useState("")
    const [receive, setReceive] = useState("");
    const [error, setError] = useState("");
    const [priceData, setPriceData] = useState(null)
    const [openTrade, setOpenTrade] = useState(null)

    useEffect(() => {
        setUserOffer(userData?.buy_data)
        setUserOfferSell(userData?.sell_data)
    }, [userData]);


    const loadMoreButton = () => {
        if (userFeedBack?.length < 10) {
            setButtonDisable(true)

        } else if (userFeedBack?.length >= 10) {
            setFeedBack(feedBack + 1)
        }
    }
    const previusLoadButton = () => {
        if (feedBack == 1) {
            setButtonDisable(true)

        } else {
            setFeedBack(feedBack - 1)
        }

    }


    const handle_Open_Trade = async (id) => {
        // alert("sd")
        try {
            if (!error) {
                if (receive && pay) {
                    let formData = new FormData();
                    formData.append("payment_method", radioValue == "p2p-buy" ? 1 : 2);
                    formData.append("value", receive);
                    formData.append("value_currency", pay);
                    formData.append("coin_rate", priceData?.coin_rate_USD);
                    formData.append("offer_id", id);
                    const response = await handleOpenTrade(formData);
                    if (response?.status === 200) {
                        toast?.successes(response?.message)
                        setOpenTrade(response?.data)
                        history.replace(`${BASE_URL.BASE_URL}p2p/Orders?to=p2p_my_trade&tradeid=${response?.data?.id}`);
                    } else {
                        toast?.errors(response?.message || response?.data?.message)
                    }
                } else {
                    toast?.errors("The Value Currency field is required.")
                }
            }

        } catch (error) {
            console.error('Error fetching currency data:', error);
        }
    };

    const handleAllBalance = (balance) => {
        setError("");
        const value = parseFloat(balance); // Convert value to a number
        if (value < priceData?.min_trade_size) {
            setError(`The minimum order amount is ${priceData?.min_trade_size} ${priceData?.currency_code}.`)
        } else if (value > parseFloat(priceData?.max_trade_size)) { // Convert max_trade_size to a number
            setError(`The maximum order amount is ${priceData?.max_trade_size} ${priceData?.currency_code}.`)
        }
        const payValue = value / parseFloat(priceData?.offer_price)
        setReceive(payValue)
        setPay(value)
    }


    const handleAllCoinBalance = (balance) => {
        setError("");
        const value = parseFloat(balance); // Convert value to a number
        if (value < priceData?.min_trade_size) {
            setError(`The minimum order amount is ${priceData?.min_trade_size} ${priceData?.currency_code}.`)
        } else if (value > parseFloat(priceData?.max_trade_size)) { // Convert max_trade_size to a number
            setError(`The maximum order amount is ${priceData?.max_trade_size} ${priceData?.currency_code}.`)
        }
        const payValue = value * parseFloat(priceData?.offer_price)
        setReceive(value)
        setPay(payValue)
    }

    const handleReceive = (e) => {

        setError("");
        const value = parseFloat(e.target.value); // Convert value to a number
        const payValue = value * parseFloat(priceData?.offer_price)
        if (payValue < priceData?.min_trade_size) {
            setError(`The minimum order amount is ${priceData?.min_trade_size} ${priceData?.currency_code}.`)

        } else if (payValue > parseFloat(priceData?.max_trade_size)) { // Convert max_trade_size to a number
            setError(`The maximum order amount is ${priceData?.max_trade_size} ${priceData?.currency_code}.`)
        }

        setReceive(value)
        setPay(payValue)
        // if (value > balance?.available_coin) {
        //     setError(``)
        // }
    }

    const handlePay = (e) => {
        setError("");
        const value = parseFloat(e.target.value); // Convert value to a number
        if (value < priceData?.min_trade_size) {
            setError(`The minimum order amount is ${priceData?.min_trade_size} ${priceData?.currency_code}.`)
        } else if (value > parseFloat(priceData?.max_trade_size)) { // Convert max_trade_size to a number
            setError(`The maximum order amount is ${priceData?.max_trade_size} ${priceData?.currency_code}.`)
        }
        const receive = value / parseFloat(priceData?.coin_rate_USD)
        setReceive(receive)
        setPay(value)
    }


    const getUserBalance = async (coin) => {
        try {
            const formData = new FormData()
            formData.append("coin", coin)
            const response = await getFundWalletBalance(formData);
            if (response.status == 200) {
                setBalance(response?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }



    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const displaySellData = userOfferSell?.slice(startIndex, endIndex);

    const displayBuyData = userOffer?.slice(startIndex, endIndex);

    const LoadMore = () => {
        setPage(page + 1)
    }
    const perfectPage = () => {
        setPage(page - 1)
    }
    const LoadMoreForBuy = () => {
        setCurrentPage(currentPage + 1)
    }
    const perfectPageForBuy = () => {
        setCurrentPage(currentPage - 1)
    }



    return (
        <div>
            <section className="p2p_main_banner_section py-4 dark-gray-bg-v">
                <Container>

                    <Row className="mt-5 mb-4 gap-v-4">
                        <div className="col-xl-8">

                            <div className="d-flex">
                                {userData?.user_data?.profile_pic_path ?
                                    <img className="mr-3"
                                        src={`${BASE_URL_Tow.BASE_URL_Tow}${userData?.user_data?.profile_pic_path}`}
                                        style={{
                                            borderRadius: "50%",
                                            height: "60px",
                                            width: "60px"
                                        }}
                                    />
                                    :
                                    <img className="mr-3" src={`${BASE_URL.BASE_URL}img/images/user.png`} height="60px" />
                                }


                                <div className="">
                                    <h6 className="align-items-center d-flex">

                                        <h5 className="font-weight-bold" id="username_div">{userData?.user_data?.nick_name || userData?.user_data?.full_name}</h5>

                                        <span className="text-green align-items-center d-flex ml-2">Verified
                                            <img src={`${BASE_URL.BASE_URL}img/images/verify.svg`} className="ml-2"></img> </span>
                                    </h6>

                                    <p className="gray_colour">Last seen 1h ago | {userData?.user_data?.created_date}</p>

                                    <div className="mt-3 d-flex flex-wrap align-items-center gap-v-5">
                                        {/* <ul className="d-flex flex-wrap align-items-center user_follow_section_left">
                                            <li><button type="button" className="btn primary-btn user_follow_btn">+ Follow</button></li>
                                            <li><a href="#" className="text-orange">Block</a></li>
                                            <li className="text-orange">|</li>
                                            <li><a href="#" className="text-orange">Report</a></li>
                                        </ul> */}

                                        {/* <ul className="d-flex flex-wrap align-items-center user_follow_section_right ml-md-2 ml-lg-5">
                                            {userData?.user_data?.email &&
                                                <>
                                                    <li>Email <img className="ml-1" src={`${BASE_URL.BASE_URL}img/images/tick.svg`} height="15px"></img></li>
                                                    <li>SMS <img className="ml-1" src={`${BASE_URL.BASE_URL}img/images/tick.svg`} height="15px"></img></li>
                                                    <li>ID Verification <img className="ml-1" src={`${BASE_URL.BASE_URL}img/images/tick.svg`} height="15px"></img>
                                                    </li>
                                                    <li>Address <img className="ml-1" src={`${BASE_URL.BASE_URL}img/images/tick.svg`} height="15px"></img>
                                                    </li>
                                                </>
                                            }
                                        </ul> */}

                                    </div>

                                </div>
                            </div>

                        </div >

                        <div className="col-xl-4 col-lg-6">
                            <div className="p2p_user_profile_feedback">
                                <div className="reviews">
                                    <p>Feedback</p>


                                    <div className="mt-2 d-flex flex-wrap align-items-baseline justify-content-between">
                                        <h3 className="d-flex align-items-baseline">
                                            {positivePercentage ? positivePercentage.toFixed(0) : null}%
                                            {/* {positivePercentage.toFixed(0)}% */}
                                            <span className="gray_colour ml-1">
                                                ({userData?.user_data?.positive_feedback_tot + userData?.user_data?.negative_feedback_tot})
                                            </span></h3>
                                        <div className="d-flex">
                                            <p className="mr-3">Positive <span className="ml-1 text-green">{userData?.user_data?.positive_feedback_tot} </span></p>
                                            <p>Negative <span className="ml-1 text-red">{userData?.user_data?.negative_feedback_tot}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row >


                    <Row className="p2p_user_merchant py-4">
                        <div className="col-12">

                            <ul className="d-flex flex-wrap justify-content-between gap-v-5">

                                <li className="user_profile_trade_card">
                                    <div className="d-flex align-items-center">
                                        <p>All Trades</p>
                                        <div className="tooltip ml-2">
                                            {/* <i className="icon ion-md-information-circle-outline tooltip" aria-hidden="true"></i> */}
                                            <span className="tooltiptext">  </span>
                                        </div>
                                    </div>
                                    <h6 className="mt-1 mb-2">
                                        <div className="d-inline" id="30d_trade">{userData?.user_data?.tot_trade}</div> Time(s)
                                    </h6>
                                    <div className="d-flex align-items-center mt-2">
                                        <p className="text-green fs-4v">Buy <span className="text-green fs-4v" id="buy_tot_trade">{userData?.user_data?.buy_tot}</span></p>
                                        <p className="mx-2">/</p>
                                        <p className="text-red fs-4v">Sell <span className="text-red fs-4v" id="sell_tot_trade">{userData?.user_data?.sell_tot}</span></p>
                                    </div>
                                </li>

                                <li className="user_profile_trade_card">
                                    <div className="d-flex align-items-center">
                                        <p>Completion Rate</p>
                                        <div className="tooltip ml-2">
                                            {/* <i className="icon ion-md-information-circle-outline tooltip" aria-hidden="true"></i> */}
                                            <span className="tooltiptext">text</span>
                                        </div>
                                    </div>
                                    <h6 className="mt-1 mb-2">
                                        <div className="d-inline" id="30d_completion_rate">{userData?.user_data?.completion_rate}</div>%
                                    </h6>
                                </li>

                                <li className="user_profile_trade_card">
                                    <div className="d-flex align-items-center">
                                        <p>Avg. Release Time</p>
                                        <div className="tooltip ml-2">
                                            {/* <i className="icon ion-md-information-circle-outline tooltip" aria-hidden="true"></i> */}
                                            <span className="tooltiptext">text</span>
                                        </div>
                                    </div>

                                    <h6 className="mt-1 mb-2">
                                        <div className="d-inline" id="avg_release_time">{userData?.user_data?.coin_release_time}</div>
                                        <div className="d-inline" id="avg_release_lbl">{" "}Minute(s)</div>
                                    </h6>

                                </li>

                                <li className="user_profile_trade_card">
                                    <div className="d-flex align-items-center">
                                        <p>Avg. Pay Time</p>
                                        <div className="tooltip ml-2">
                                            {/* <i className="icon ion-md-information-circle-outline tooltip" aria-hidden="true"></i> */}
                                            <span className="tooltiptext">text</span>
                                        </div>
                                    </div>

                                    <h6 className="mt-1 mb-2">
                                        <div className="d-inline" id="avg_pay_time">{userData?.user_data?.payment_release_time}</div>
                                        <div className="d-inline" id="avg_pay_lbl">{" "}Second(s)</div>
                                    </h6>
                                </li>

                                <li className="user_profile_trade_card">
                                    <div className="d-flex align-items-center">
                                        <p>Registered</p>
                                        <div className="tooltip ml-2">
                                            {/* <i className="icon ion-md-information-circle-outline tooltip" aria-hidden="true"></i> */}
                                            <span className="tooltiptext">text</span>
                                        </div>
                                    </div>
                                    <h6 className="mt-1 mb-2">
                                        <div className="d-inline" id="register_days">{userData?.user_data?.register}</div>{" "}Day(s)
                                    </h6>
                                </li>
                            </ul>

                        </div>
                    </Row>
                    <Row>
                        <div className="col-12">
                            <div className="p2p_filter_section w-100 mt-1">
                                <div className="tab active_trades_left_btn flex-wrap">
                                    <button
                                        className={`tablinks ${activeTab === 'Online_Ads' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('Online_Ads')}
                                    >
                                        Online Ads
                                    </button>
                                    <button
                                        className={`tablinks ${activeTab === 'feedback' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('feedback')}>
                                        Feedback {total ? total : "0"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container >
                <Row className={`tabcontent ${activeTab === 'Online_Ads' ? 'show' : 'hide'}`} id="Online_Ads">
                    {(userOffer?.length > 0 && userOffer !== null) &&
                        <div className="p2p_filter_section mb-4 mt-3 p2p_mainTable pt-4">
                            <Container>
                                <Row>
                                    <Col>
                                        <h4 className="mb-2">Online Buy Ads</h4>
                                    </Col>
                                </Row>
                            </Container>
                            <Alloffertable
                                // userProfile={true}
                                image={userData?.user_data?.profile_pic_path}
                                setUser={setUser}
                                handle_Open_Trade={handle_Open_Trade}
                                balance={balance}
                                handleAllCoinBalance={handleAllCoinBalance}
                                setReceive={setReceive}
                                setPay={setPay}
                                handleAllBalance={handleAllBalance}
                                error={error}
                                userOffer={displayBuyData}
                                setPriceData={setPriceData}
                                LoadMore={LoadMoreForBuy}
                                perfectPage={perfectPageForBuy}
                                getUserBalance={getUserBalance}
                                handlePay={handlePay}
                                receive={receive}
                                pay={pay}
                                setError={setError}
                                handleReceive={handleReceive}
                                page={currentPage}
                            />
                        </div>
                    }
                    {(userOfferSell?.length > 0 && userOfferSell !== null) &&
                        <div className="p2p_filter_section mb-4 mt-3 p2p_mainTable pt-4">
                            <Container>
                                <Row>
                                    <Col>
                                        <h4 className="mb-2">Online Sell Ads</h4>
                                    </Col>
                                </Row>
                            </Container>

                            <Alloffertable
                                // userProfile={true}
                                setUser={setUser}
                                image={userData?.user_data?.profile_pic_path}
                                handle_Open_Trade={handle_Open_Trade}
                                balance={balance}
                                handleAllCoinBalance={handleAllCoinBalance}
                                setReceive={setReceive}
                                setPay={setPay}
                                handleAllBalance={handleAllBalance}
                                error={error}
                                userOffer={displaySellData}
                                setPriceData={setPriceData}
                                LoadMore={LoadMore}
                                getUserBalance={getUserBalance}
                                handlePay={handlePay}
                                receive={receive}
                                pay={pay}
                                setError={setError}
                                handleReceive={handleReceive}
                                perfectPage={perfectPage}
                                page={page}
                            />
                        </div>
                    }
                </Row>
                <Row className={`tabcontent ${activeTab === 'feedback' ? 'show' : 'hide'}`} id="Online_Ads">
                    <section className="p2p_main_banner_section py-4 p2p_mainTable mt-3 mb-4">
                        <Container>
                            <Row>
                                <div className="accordion p2p_user_accordion" id="accordionExample">
                                    <div className="d-flex flex-wrap justify-content-between align-items-center mb-3 gap-v-5">
                                        <h3 className="online_ads_heading mr-3">Feedback</h3>

                                    </div>

                                    <div className="card_p2p_online_ads">
                                        <div className="p2p_user_table_heading w-100">
                                            <div className="row">
                                                <div className="tab-content w-100">
                                                    <div className="col-12">
                                                        <div className="reviews_block">
                                                            <ul className="nav nav-tabs mb-4">
                                                                <li className="nav-item">
                                                                    <a
                                                                        className={`nav-link ${feedbackTab === 'all_feedback' ? 'active' : ''} typeTab`}
                                                                        onClick={() => handleFeedBackTabChange('all_feedback')}
                                                                    >All</a>

                                                                </li>
                                                                <li className="nav-item">
                                                                    <a
                                                                        className={`nav-link ${feedbackTab === 'positive_feedback' ? 'active' : ''} typeTab`}
                                                                        onClick={() => handleFeedBackTabChange('positive_feedback')}
                                                                    >Positive</a>

                                                                </li>
                                                                <li className="nav-item">
                                                                    <a
                                                                        className={`nav-link ${feedbackTab === 'negative_feedback' ? 'active' : ''} typeTab`}
                                                                        onClick={() => handleFeedBackTabChange('negative_feedback')}
                                                                    >Negative</a>

                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div className="mt-5 user_payment_method_box_bg_none">
                                                            {loading ? (
                                                                <div className='d-flex justify-content-center'>
                                                                    <Spinner animation="border" variant="secondary" />
                                                                </div>
                                                            ) :
                                                                <div className="tab-content">
                                                                    <div className={`tab-pane ${feedbackTab === 'all_feedback' ? 'active' : ''}`} id="all_feedback">
                                                                        <FeedBack userFeedBack={userFeedBack} feedbackType={0} />
                                                                    </div>
                                                                    <div className={`tab-pane ${feedbackTab === 'positive_feedback' ? 'active' : ''}`} id="positive_feedback">
                                                                        <FeedBack userFeedBack={userFeedBack} feedbackType={1} />
                                                                    </div>
                                                                    <div className={`tab-pane ${feedbackTab === 'negative_feedback' ? 'active' : ''}`} id="negative_feedback">
                                                                        <FeedBack userFeedBack={userFeedBack} feedbackType={2} />
                                                                    </div>
                                                                </div>}


                                                        </div>
                                                        {userFeedBack !== null || userFeedBack?.length > 0 &&
                                                            < div className="d-flex justify-content-between mt-3">
                                                                {feedBack > 1 &&
                                                                    < button
                                                                        type="button"
                                                                        onClick={previusLoadButton}
                                                                        disabled={loading ? true : false}
                                                                        className="load_more_btn theme-btn-2 theme-btn mt-3 mx-1 float-right" id="loadFeed">
                                                                        Previous
                                                                    </button>
                                                                }
                                                                <button
                                                                    type="button"
                                                                    onClick={loadMoreButton}
                                                                    disabled={buttonDisable ? true : false}
                                                                    className="load_more_btn theme-btn-2 theme-btn mt-3 mx-1 float-right" id="loadFeed">
                                                                    Next
                                                                </button>
                                                            </div>
                                                        }
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </Container>
                    </section>
                </Row>


            </section >
        </div >
    )
}

export default UserProfile