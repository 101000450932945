import React, { useState, useEffect } from "react";
import { Link, useHistory, generatePath } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  loginAction,
  activateAccount,
  disableUserOtp,
  verifyGoogleAuth,
  googleAuthReset,
  logout,
  walletBalance_transfer,
  genrateQrCodeForLogin,
  loginWithQrAction,
} from "../redux/apiActions/user.action";
import BASE_URL from "../_constant/index";
import { toast } from "../components/Toast/Toast";
import DisablePop from "../components/DisableAccount/DisablePop";
import { Form } from "react-bootstrap";
// import QRCode from "react-qr-code";
import { QRCode } from "react-qrcode-logo";
// api/googleAuthVerifyQR_enable
// import { GoogleLogin } from '@react-oauth/google';
import { gapi } from "gapi-script";
import LoginWithGoogle from "./LoginWithGoogle";
// import LogoutWithGoogleButton from "./LogoutWithGoogleButton";

import LoginWithApple from "./LoginWithApple";
import { Button } from "react-bootstrap";

export default function Login({ notificationToken }) {
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: BASE_URL.clientId,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  const accessToken = gapi?.auth?.getToken()?.access_token;

  const dispatch = useDispatch();
  const history = useHistory();

  const userData = useSelector((state) => state.user.userData);
  const gAuth_active = userData?.gAuth_active;
  const login_token = userData?.login_token;

  const queryParameters = new URLSearchParams(window.location.search);
  let return_to_url = queryParameters.get("return_to");
  let return_to_php = queryParameters.get("to");

  return_to_url = return_to_url ? atob(return_to_url) : null;
  return_to_php = return_to_php ? atob(return_to_php) : null;

  const [loading, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [showActive, setShowActive] = useState(false);
  const [error, setError] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);
  const [otp, setOtp] = useState("");
  const [qrOtp, setQrOtp] = useState("");
  const [otpError, setOtpError] = useState(null);
  const [gAuth, setGAuth] = useState(gAuth_active);
  const [resetShow, setResetShow] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [qrButtonStatus, setQRButtnStatus] = useState("Get QR Code");
  const [resetRequest, setRestRequest] = useState({
    full_name: "",
    message: "",
  });
  const [showQR, setShowQR] = useState(false);

  const genrateQr = async () => {
    const result = await genrateQrCodeForLogin();
    if (result.status == 200) {
      setShowQR(true);
      LoginWithAuthVerify(result.qr);
      setQrCode(result.qr);
      toast.success(result.message);
    } else {
      toast.error("Please enter Valid Details");
    }
  };

  const LoginWithAuthVerify = async (qrcode) => {
    let formData = new FormData();
    // formData.append("login_token", login_token);
    formData.append("qr_code", qrcode ? qrcode : qrOtp);
    formData.append("device_type", 0);
    formData.append(
      "device_token",
      notificationToken ? notificationToken : "deviceTokenForNotificationLogin"
    );
    const response = await dispatch(
      loginWithQrAction(formData, history, userData, return_to_url)
    );
    if (response?.data?.status === 200) {
      setLoader(false);
      if (response?.status == 200) {
        if (response?.data?.qr_status == 0) {
          setTimeout(() => {
            LoginWithAuthVerify(qrcode);
          }, 2000);
        } else if (response?.data?.qr_status == 1) {
          setShowQR(false);
        } else {
          setShowQR(false);
        }
      }

      // toast.success("Login Successfully");
      // onLogoutBtnClick()
      setQrOtp("");
    } else {
      setLoader(false);
      // setOtpError(response?.message || "Something went wrong");
      toast.error(response?.message || "Something went wrong");
    }
  };

  const getQrTimeOut = () => {
    genrateQr();

    setTimeout(() => {
      setShowQR(false);
    }, 120000);
  };

  const walletDalanceTransfer = async () => {
    const result = await walletBalance_transfer();
  };

  useEffect(() => {
    if (userData?.access_token) {
      walletDalanceTransfer();
    }
  }, [userData?.access_token]);

  useEffect(() => {
    setGAuth(userData?.gAuth_active);
    setRestRequest({
      full_name: "",
      message: "",
    });
    setQrOtp("");
  }, [userData]);

  const onLogoutBtnClick = async () => {
    dispatch(logout());
    history.push(BASE_URL.BASE_URL + "login");
    setRestRequest({
      full_name: "",
      message: "",
    });
    setQrOtp("");
    // window.location.reload(false);
    // return <Redirect to="/login" />
  };

  const handleReset = (e) => {
    const value = e.target.value;
    setRestRequest({
      ...resetRequest,
      [e.target.name]: value,
    });
  };

  useEffect(() => {
    if (userData?.access_token) {
      history.push(BASE_URL.BASE_URL);
    }
  }, []);

  const handleOtpChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setOtp(value);
    setError("");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes, history, otp]);

  const googleAuthVerify = async () => {
    if (!qrOtp || qrOtp === "") {
      toast.error("Please enter Valid Details");
      setOtpError("Please enter Valid Details");
    } else if (qrOtp?.length < 6) {
      setOtpError("Min 6 digit, please provide valid OTP");
    } else {
      setLoader(true);
      let formData = new FormData();
      formData.append("login_token", login_token);
      formData.append("code", qrOtp);
      formData.append("device_type", 0);
      formData.append(
        "device_token",
        notificationToken
          ? notificationToken
          : "deviceTokenForNotificationLogin"
      );
      const response = await dispatch(
        verifyGoogleAuth(formData, history, userData, return_to_url)
      );
      if (response?.status === 200) {
        setLoader(false);
        toast.success("Login Successfully");
        // onLogoutBtnClick()
        setQrOtp("");
      } else {
        setLoader(false);
        setOtpError(response?.message || "Something went wrong");
        toast.error(response?.message || "Something went wrong");
      }
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const showEye =
    "M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z";
  const hideEye =
    "M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z";
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordShown, setPasswordShown] = React.useState(false);
  const togglePassword = (e) => {
    setPasswordShown(passwordShown ? false : true);
    e.preventDefault();
  };

  const handleChange = (val, fieldName) => {
    if (fieldName === "email") setEmail(val);
    if (fieldName === "password") setPassword(val);
  };

  const handleLogin = async () => {
    setLoader(true);
    setResetShow(false);
    if (!email || email === "" || !password || password === "") {
      // toast.error("Please enter Valid Details");
      setLoader(false);
    } else {
      let formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);
      formData.append("device_type", 0); // web login
      formData.append(
        "device_token",
        notificationToken
          ? notificationToken
          : "deviceTokenForNotificationLogin"
      );
      const responce = await dispatch(
        loginAction(formData, history, return_to_url, return_to_php)
      );

      setLoader(false);
      if (responce?.data?.status === 409) {
        setShow(true);
      } else if (responce?.data?.status === 400) {
        toast.error(responce?.data?.message);
      }
    }
  };

  const submitDisableUserOtp = async () => {
    try {
      if (!otp) {
        setError("Otp is is required");
      } else {
        if (otp?.length < 6) {
          setError("Min 6 Digit");
        } else {
          setLoader(true);
          let data = new FormData();
          data.append("email", email);
          data.append("otp", otp);
          const responce = await disableUserOtp(data);
          if (responce?.data?.status === 200) {
            toast.success(responce.data.message);
            history.push(BASE_URL.BASE_URL + "login");
            setShowActive(false);
            setLoader(false);
          } else {
            toast.error(responce.data.message);
            setError(responce.data.message || "Invalid OTP");
            setLoader(false);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [resetRequestError, setRestRequestError] = useState({
    full_nameError: "",
    messageError: "",
  });

  const googleAuthResetRequest = async () => {
    try {
      if (resetRequest?.full_name?.length == 0) {
        setRestRequestError({
          full_nameError: "Name is required",
        });
      } else if (resetRequest?.message?.length == 0) {
        setRestRequestError({
          messageError: "message is required",
        });
      } else {
        setLoader(true);
        let data = new FormData();
        data.append("login_token", login_token);
        data.append("name", resetRequest.full_name);
        data.append("subject", "Reset google auth ");
        data.append("message", resetRequest.message);
        const responce = await googleAuthReset(data);
        if (responce?.status === 200) {
          setLoader(false);
          toast.successes("Request Submitted", responce?.message);
          setGAuth("");
          onLogoutBtnClick("");
        } else {
          setLoader(false);
          onLogoutBtnClick("");
          toast.errors("Invalid Request", responce?.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const reactivateAccount = async () => {
    try {
      let data = new FormData();
      data.append("email", email);
      const responce = await activateAccount(data);
      if (responce?.data?.status === 200) {
        setMinutes(0);
        setSeconds(30);
        setShow(false);
        setShowActive(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const resendOtp = async () => {
    setMinutes(0);
    setSeconds(30);
    try {
      let data = new FormData();
      data.append("email", email);
      const responce = await activateAccount(data);
      if (responce?.data?.status === 200) {
        toast.success(responce?.data?.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {/* <LogoutWithGoogleButton />  */}
      <div className="basic_pages">
        {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
        {/* <div className="container"> */}
        <div
          className="row row-cols-1 row-cols-md-2 row-cols-md-1 row-cols-sm-1 align-items-center gap-v-4"
          style={{ minHeight: "100%" }}
        >
          <div className="col d-flex align-items-center justify-content-center">
            <div className="form-access">
              {gAuth != 1 ? (
                <>
                  {!showActive ? (
                    <>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          // if (loading === false) formik.handleSubmit(e);
                        }}
                      >
                        <h3
                          className="mb-3 Welcome"
                          style={{ fontSize: "2rem" }}
                        >
                          Welcome{" "}
                          <span
                            className="text-orange"
                            style={{ fontSize: "2rem" }}
                          >
                            Back
                          </span>
                        </h3>
                        <div className="form-group active">
                          <Form.Label>Enter Email</Form.Label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email address"
                            required
                            id="email"
                            onChange={(e) =>
                              handleChange(e.target.value, "email")
                            }
                            value={email}
                          />
                        </div>
                        <div className="form-group">
                          <div className="password_block">
                            <Form.Label>Enter Password</Form.Label>
                            <div className="position-relative">
                              <input
                                type={passwordShown ? "text" : "password"}
                                className="form-control"
                                placeholder="Password"
                                required
                                id="password"
                                onChange={(e) =>
                                  handleChange(e.target.value, "password")
                                }
                                value={password}
                                autoComplete="off"
                              />
                              <div
                                onClick={togglePassword}
                                className="password_hideshow_btn btn sm-btn"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="#fff"
                                    d={passwordShown ? showEye : hideEye}
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="text-right text-orange"
                          style={{ color: "#FA501C" }}
                        >
                          <Link
                            to={BASE_URL.BASE_URL + "reset"}
                            style={{ color: "#FA501C" }}
                          >
                            Forgot Password?
                          </Link>
                        </div>
                        <div className="d-flex align-items-center justify-content-sm-between  justify-content-center flex-wrap gap-v-5 mt-2">
                          <button
                            className="btn btn-primary signinButton mt-0"
                            disabled={
                              loading && (email !== "" || password !== "")
                                ? true
                                : false
                            }
                            onClick={loading ? null : handleLogin}
                          >
                            {loading ? "Please wait..." : "Sign In"}
                          </button>
                          <h2 className="m-2">
                            Don't have an account?{" "}
                            <Link
                              to={BASE_URL.BASE_URL + "signup"}
                              style={{ color: "#FA501C" }}
                            >
                              Sign up here
                            </Link>
                          </h2>
                        </div>
                        <div className="d-flex justify-content-sm-between justify-content-center flex-wrap gap-v-5 mt-2">
                          <LoginWithGoogle
                            notificationToken={notificationToken}
                          />
                          {/* <LoginWithGoogle /> */}
                          {/* <LoginWithApple/> */}
                        </div>
                      </form>
                    </>
                  ) : (
                    <>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          // if (loading === false) formik.handleSubmit(e);
                        }}
                      >
                        <span>Reactive Account</span>
                        <p style={{ textAlign: "center" }}>
                          please check your email or mobile for <br /> account
                          Reactivation OTP
                        </p>
                        <div className="form-group active mt-3">
                          <input
                            type="text"
                            name="otpNumber"
                            maxLength="6"
                            value={otp}
                            onChange={handleOtpChange}
                            className="form-control"
                            placeholder="Enter code here"
                            required
                            id="email"
                          />
                        </div>
                        <small className="error-message text-danger text-wrap">
                          {error}
                        </small>
                        <button
                          className="btn btn-primary"
                          disabled={
                            loading && (email !== "" || password !== "")
                              ? true
                              : false
                          }
                          onClick={loading ? null : submitDisableUserOtp}
                        >
                          {loading ? "Please wait..." : "Reactive"}
                        </button>
                        <div className="countdown-text">
                          <div className="c_inner">
                            {seconds > 0 || minutes > 0 ? (
                              <p>
                                Time Remaining:{" "}
                                {minutes < 10 ? `0${minutes}` : minutes}:
                                {seconds < 10 ? `0${seconds}` : seconds}
                              </p>
                            ) : (
                              <>
                                <p>Didn't receive code?</p>
                                <button className="btn" onClick={resendOtp}>
                                  Resend OTP
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </form>
                      <h2 className="m-2">
                        Don't have an account?{" "}
                        <Link
                          to={BASE_URL.BASE_URL + "signup"}
                          style={{ color: "#FA501C" }}
                        >
                          Sign up here
                        </Link>
                      </h2>
                    </>
                  )}
                </>
              ) : (
                <>
                  {resetShow ? (
                    <>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <span className="mb-0">Reset Request</span>
                        <p className="text-secondary mb-3">
                          Provide information to reset your auth credential.
                        </p>
                        <div className="form-group active">
                          <input
                            type="text"
                            className="form-control"
                            name="full_name"
                            placeholder="Your Full Name"
                            required
                            onChange={handleReset}
                            value={resetRequest.full_name}
                          />
                          <small className="error-message text-danger text-wrap">
                            {resetRequestError?.full_nameError}
                          </small>
                        </div>
                        <div className="form-group active">
                          <textarea
                            onChange={handleReset}
                            value={resetRequest?.message}
                            placeholder="message"
                            className="form-control"
                            name="message"
                            rows="3"
                            style={{ minHeight: "70px" }}
                            required
                          />
                          <small className="error-message text-danger text-wrap">
                            {resetRequestError?.messageError}
                          </small>
                        </div>
                        <button
                          className="btn btn-primary mt-0"
                          onClick={googleAuthResetRequest}
                          disabled={loading ? true : false}
                        >
                          {loading ? "Please wait..." : "Continue"}
                        </button>
                        <div
                          className="text-right mt-2"
                          onClick={() => {
                            setGAuth("");
                          }}
                        >
                          <Link>Back to Login</Link>
                        </div>
                      </form>
                    </>
                  ) : (
                    <>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <span className="mb-0">Authenticate</span>
                        <p className="text-secondary mb-3">
                          Please enter 6-Digit code from your authenticator app
                        </p>
                        <div className="form-group active">
                          <input
                            type="text"
                            className="form-control"
                            name="qrOtp"
                            maxLength="6"
                            placeholder="Enter 6 Digit Code"
                            required
                            onChange={(e) => {
                              setQrOtp(e.target.value.replace(/\D/g, ""));
                              setOtpError(null);
                            }}
                            value={qrOtp}
                          />
                          <small className="error-message text-danger text-wrap">
                            {otpError}
                          </small>
                        </div>

                        <button
                          className="btn btn-primary mt-0"
                          onClick={googleAuthVerify}
                          disabled={loading ? true : false}
                        >
                          {loading ? "Please wait..." : "Continue"}
                        </button>
                        <div
                          className="text-right mt-2"
                          onClick={onLogoutBtnClick}
                        >
                          <Link>Back to Login</Link>
                        </div>
                      </form>
                      <h2
                        onClick={() => {
                          setResetShow(true);
                        }}
                      >
                        <Link to={"#"}>Reset</Link>
                      </h2>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div
            className="p-4 col px-lg-5 px-3 login-background"
            style={{ minHeight: "100%" }}
          >
            <div className="p-4">
              <h3 className="mb-3 Welcome text-center">
                Log in with{" "}
                <span className="text-orange" style={{ fontSize: "2rem" }}>
                  QR code
                </span>
              </h3>
              <p className="text-center">
                Scan this code with VYNKSAFE mobile app to log in instantly
              </p>
            </div>
            <div
              className="p-4 d-flex align-items-center justify-content-center w-100"
              style={{ position: "relative" }}
            >
              <img
                src={`${BASE_URL.BASE_URL}img/images/mobile.png`}
                className="img-fluid dark-theme-img"
                alt="banner"
                width="450"
                height="410"
              />
              <img
                src={`${BASE_URL.BASE_URL}img/images/mobilelight.png`}
                className="img-fluid light-theme-img"
                alt="banner"
                width="450"
                height="410"
              />
              <div
                style={{
                  // width: "150px",
                  // height: "150px",
                  // background: "transparent",
                  position: "absolute",
                }}
              >
                {!showQR ? (
                  <>
                    <Button className="getqrcode" onClick={getQrTimeOut}>
                      <i className="ion-ios-qr-scanner mr-1"></i>
                      Get QR Code
                    </Button>
                    <div style={{ opacity: "0.1" }}>
                      <QRCode
                        // size={"150px"}
                        style={{
                          height: "auto",
                          maxWidth: "150px",
                          width: "15px",
                          border: "5px solid #f5f5f5",
                        }}
                        // bgColor="transparent"
                        logoImage={`${BASE_URL.BASE_URL}img/images/QRLight.png`}
                        value={qrCode}
                        logoHeight={40}
                        removeQrCodeBehindLogo={true}
                        logoWidth={40}
                        logoOpacity={1}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <QRCode
                      // size={"150px"}
                      style={{
                        height: "auto",
                        maxWidth: "150px",
                        width: "15px",
                        border: "5px solid #f5f5f5",
                      }}
                      // bgColor="transparent"
                      logoImage={`${BASE_URL.BASE_URL}img/images/QRLight.png`}
                      value={qrCode}
                      logoHeight={40}
                      removeQrCodeBehindLogo={true}
                      logoWidth={40}
                      logoOpacity={1}
                    />
                  </>
                )}
              </div>
              {/* <img alt="Sign page" width="400px" />
              <img alt="Sign page" width="400px" /> */}
            </div>
          </div>
        </div>
      </div>
      <DisablePop
        reactivateAccount={reactivateAccount}
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
      />
    </>
  );
}
