import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BASE_URL from "../../_constant/index";
import utill from "../../_constant/utill";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import EmptyData from "../emptyData";
import {
  allSymbolsAction,
  getFavPairsAction,
} from "../../redux/apiActions/api.action";

const ImageHot = (
  <img
    src={`${BASE_URL.BASE_URL}img/dashboard/hot.png`}
    alt="hot"
    className="deactivate mr-1"
  />
);

const Market = ({ socket, userCoinList }) => {
  const userData = useSelector((state) => state.user.userData);
  const [activeTabPair, _activeTabPair] = useState("USDT");
  const [spotHoldingCoinData, setSpotHoldingCoinData] = useState(userCoinList);
  const [symbols, setSymbols] = useState({});
  const [symbolsData, setSymbolsData] = useState([]);
  const [symbolCoinList, setSymbolCoinList] = useState(symbolsData);
  const [symbolsCoinListPrice, setSymbolsCoinListPrice] = useState([]);
  const [favPairs, setFavPairs] = useState([]);

  useEffect(() => {
    const filtered = symbolsData?.filter(
      (item) => item.quote.toLowerCase() === activeTabPair.toLowerCase()
    );
    setSymbolCoinList(filtered);
  }, [activeTabPair, symbolsData]);

  useEffect(() => {
    setSpotHoldingCoinData(userCoinList);
  }, [userCoinList]);

  useEffect(async () => {
    let result = await allSymbolsAction();
    if (result !== false) {
      setSymbols(result);
      setSymbolsData(result?.data);
    }
  }, []);

  // GET FAVPAIR

  const getUserFavPair = async () => {
    if (userData.access_token) {
      const result = await getFavPairsAction();
      let favData = [];
      if (
        (result?.status === 200 || result?.status === "200") &&
        result?.data?.length > 0
      ) {
        favData = symbolsData?.filter((obj) =>
          result?.data?.some((coin) => obj.symbol === coin?.symbol)
        );
      }
      setFavPairs(favData);
    }
  };

  useEffect(() => {
    getUserFavPair();
    const prices = symbolsData?.map((ele) => {
      return {
        symbol: ele.symbol,
        price: ele.price,
        last_price: ele.last_price,
        usd_price: ele.usd_price,
        changes: ele.changes,
        quote: ele.quote,
        base: ele.base,
      };
    });
    setSymbolsCoinListPrice(prices);
  }, [symbolsData]);

  // Spot Holding Socket
  let SpotHoldingData = spotHoldingCoinData;

  useEffect(() => {
    socket?.emit("get_coin", {
      coin: "ALL",
    });
    socket?.on("received_coins_price", (socketData) => {
      if (SpotHoldingData?.length > 0) {
        SpotHoldingData.map((item, index) => {
          socketData.map((data) => {
            if (item.symbol === data.symbol) {
              SpotHoldingData[index].changes = item.changes;
              // SpotHoldingData[index].balance = data.latest_price;
              SpotHoldingData[index].rate_currency = data.latest_price;
              // tempPriceData[index].image_url = data.usd_price;
              // tempPriceData[index].name = data.name;
              // tempPriceData[index].symbol = data.symbol;
            }
          });
        });
      }
      setSpotHoldingCoinData(SpotHoldingData);
    });
  }, [socket, SpotHoldingData, spotHoldingCoinData]);

  let tempPriceData = symbolsCoinListPrice;
  useEffect(() => {
    // Emit get_pairprice event to the server to get the initial data
    socket?.emit("get_pairprice", {
      pair_id: "ALL",
    });

    // Listen to received_pairprice event from the server
    socket?.on("received_pairs_price", (socketData) => {
      if (tempPriceData?.length > 0) {
        tempPriceData.map((item, index) => {
          socketData.map((data) => {
            if (item.symbol === data.symbol) {
              tempPriceData[index].price = data.price;
              tempPriceData[index].usd_price = data.usd_price;
              tempPriceData[index].last_price = data.last_price;
              tempPriceData[index].changes = data.changes;
            }
          });
        });
      }
      setSymbolsCoinListPrice(tempPriceData);
    });
    // Clean up the event listener
    // return () => {
    //     socket?.off("received_pairs_price");
    // };
  }, [socket, tempPriceData, setSymbolsCoinListPrice]);

  const pagination = paginationFactory({
    page: 2,
    prePageText: false,
    nextPageText: false,
  });

  const filteredData = spotHoldingCoinData?.filter((coin) =>
    symbolsData?.some(
      (symbol) =>
        symbol?.quote === "USDT" &&
        symbol?.base.toUpperCase() === coin?.coin.toUpperCase()
    )
  );

  const myData = filteredData?.map((item) => {
    return {
      id: item?.id,
      name: [item?.image, item?.symbol, item?.name],
      amount: [item?.balance, item?.decimal],
      price: [item?.rate_currency],
      H_Changes: [item?.price_change_color, item?.price_change_percentage],
      Deposit: item?.symbol,
      withDrawal: item?.symbol,
      Trade: item?.symbol,
    };
  });

  const SpotData = symbolCoinList?.map((item) => {
    let priceObj = symbolsCoinListPrice.find(
      (obj) => obj.symbol === item.symbol
    );

    return {
      id: item?.id,
      pair: item?.symbol,
      coin: [item?.base_image_url, item?.base],
      last_price: utill.valueFix(priceObj?.price, 4),
      changes: priceObj?.changes,
      high_24: item?.high_24,
      low_24: item?.low_24,
      volume_24: item?.volume_24,
      Trade: `${BASE_URL.BASE_URL}exchange/${item?.base}_${item?.quote}`,
    };
  });
  const favCoinData = favPairs?.map((item) => {
    let priceObj = symbolsCoinListPrice.find(
      (obj) => obj.symbol === item.symbol
    );

    return {
      id: item?.id,
      pair: item?.symbol,
      coin: [item?.base_image_url, item?.base],
      last_price: utill.valueFix(priceObj?.price, 4),
      changes: priceObj?.changes,
      Trade: `${BASE_URL.BASE_URL}exchange/${item?.base}_${item?.quote}`,
    };
  });

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: (cell) => {
        return (
          <>
            <div
              className="d-flex d-flex align-items-center justify-content-start"
              style={{ textAlign: "left" }}
            >
              <div className="mr-1" style={{ width: "20%" }}>
                <img src={cell[0]} width="30px" height="30px" />
              </div>
              <div style={{ width: "70%" }}>
                {cell[1]}{" "}
                <span
                  className="text-dark"
                  style={{ fontSize: "11px", wordWrap: "normal" }}
                >
                  <b> ({cell[2]})</b>
                </span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      formatter: (cell) => {
        return <>{utill.beautifyNumber(cell[0], cell[1] > 5 ? 6 : cell[1])}</>;
      },
    },
    {
      dataField: "price",
      text: "Price",
      sort: true,
      formatter: (cell) => {
        return <>${utill.valueFix(parseFloat(cell), 2)}</>;
      },
    },
    {
      dataField: "H_Changes",
      text: "24H Changes",
      sort: true,
      formatter: (cell) => {
        return (
          <>
            <div style={{ color: `#${cell[0]}` }}>{cell[1]}</div>
          </>
        );
      },
    },
    {
      dataField: "Trade",
      text: "",
      formatter: (cell) => {
        let redirectUrl = `${BASE_URL.BASE_URL}exchange/${cell}_USDT`;
        return (
          <>
            <div className="estimated-balance-btn">
              <Link
                to={redirectUrl}
                style={{ textDecoration: "none" }}
                className="btn btn-gray-v text-white w-100 p-1"
              >
                Trade
              </Link>
            </div>
          </>
        );
      },
      headerStyle: () => {
        return { width: "18%" };
      },
      style: () => {
        return { width: "18%" };
      },
    },
  ];

  const SpotColumns = [
    {
      dataField: "pair",
      text: "Pairs",
      sort: true,
    },
    {
      dataField: "coin",
      text: "Coin",
      sort: true,
      formatter: (cell) => {
        return (
          <>
            <div className="d-flex d-flex align-items-center justify-content-start">
              <div className="mr-1">
                <img src={cell[0]} width="30px" height="30px" />
              </div>
              <div>{cell[1]}</div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "last_price",
      text: "Last Price",
      sort: true,
      formatter: (cell) => {
        return <>$ {utill.beautifyNumber(utill.valueFix(Number(cell)), 8)}</>;
      },
    },
    {
      dataField: "changes",
      text: "Changes (14H)",
      sort: true,
      formatter: (cell) => {
        return (
          <>
            <div className={parseFloat(cell) >= 0 ? `green` : `red`}>
              {parseFloat(cell) >= 0 ? `+` : ``}
              {utill.beautifyNumber(Number(cell), 2)} %
            </div>
          </>
        );
      },
    },

    {
      dataField: "high_24",
      text: "High (24H)",
      formatter: (cell) => {
        return (
          <>
            <div>{utill.valueFix(Number(cell))}</div>
          </>
        );
      },
    },

    {
      dataField: "low_24",
      text: "Low (24H)",
      formatter: (cell) => {
        return (
          <>
            <div>{utill.valueFix(Number(cell))}</div>
          </>
        );
      },
    },
    {
      dataField: "volume_24",
      text: "Valume (14h)",
      formatter: (cell) => {
        return (
          <>
            <div>{utill.valueFix(Number(cell))}</div>
          </>
        );
      },
    },
    {
      dataField: "Trade",
      text: "",
      formatter: (redirectUrl) => {
        return (
          <>
            <div className="estimated-balance-btn">
              <Link
                to={redirectUrl}
                style={{ textDecoration: "none" }}
                className="btn btn-gray-v text-white w-100 p-1"
              >
                Trade
              </Link>
            </div>
          </>
        );
      },
      headerStyle: () => {
        return { width: "18%" };
      },
      style: () => {
        return { width: "18%" };
      },
    },
  ];

  const FavCoinColumns = [
    {
      dataField: "pair",
      text: "Pairs",
      sort: true,
    },
    {
      dataField: "coin",
      text: "Coin",
      sort: true,
      formatter: (cell) => {
        return (
          <>
            <div className="d-flex d-flex align-items-center justify-content-start">
              <div className="mr-1">
                <img src={cell[0]} width="22px" height="22px" />
              </div>
              <div>{cell[1]}</div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "last_price",
      text: "Last Price",
      sort: true,
      formatter: (cell) => {
        return <>$ {utill.valueFix(Number(cell))}</>;
      },
    },
    {
      dataField: "changes",
      text: "Changes (14H)",
      sort: true,
      formatter: (cell) => {
        return (
          <>
            <div className={parseFloat(cell) >= 0 ? `green` : `red`}>
              {parseFloat(cell) >= 0 ? `+` : ``}
              {utill.beautifyNumber(Number(cell), 2)} %
            </div>
          </>
        );
      },
    },
    {
      dataField: "Trade",
      text: "",
      formatter: (redirectUrl) => {
        return (
          <>
            <div className="estimated-balance-btn">
              <Link
                to={redirectUrl}
                style={{ textDecoration: "none" }}
                className="btn btn-gray-v text-white w-100 p-1"
              >
                Trade
              </Link>
            </div>
          </>
        );
      },
      headerStyle: () => {
        return { width: "18%" };
      },
      style: () => {
        return { width: "18%" };
      },
    },
  ];

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    hideSizePerPage: true, 
    hidePageListOnlyOnePage: true, 
    firstPageText: "First",
    prePageText: "<",
    nextPageText: ">",
    
  };
  return (
    <div className="card-body">
      <h5 className="card-title mb-3" style={{ color: "white" }}>
        Market
      </h5>
      <div className="mt15 market-tabs">
        <Tabs defaultActiveKey="Spot-holding">
          <Tab eventKey="Spot-holding" title={`Spot Holding`}>
            <div>
              <div className="crypto_balance_table_main mt-2">
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  data={myData}
                  columns={columns}
                  pagination={paginationFactory(options)}
                  noDataIndication={<EmptyData />}
                  className="table"
                  classes="crypto_balance_table table_header_centered"
                  bordered={false}
                  striped
                  condensed
                />
              </div>
            </div>
          </Tab>
          <Tab eventKey="Hot" title={<span>{ImageHot} Spot</span>}>
            <Tabs
              defaultActiveKey={activeTabPair}
              onClick={(e) => {
                _activeTabPair(e.target.innerText);
              }}
              className="mb-2 mt-3"
            >
              {symbols?.pair?.map((pair, key) => {
                return (
                  <Tab
                    eventKey={pair?.symbol}
                    title={pair?.symbol}
                    value={pair?.symbol}
                    key={key}
                  >
                    <div className="crypto_balance_table_main">
                      <BootstrapTable
                        bootstrap4
                        keyField="id"
                        data={SpotData}
                        columns={SpotColumns}
                        pagination={paginationFactory(options)}
                        noDataIndication={<EmptyData />}
                        classes="crypto_balance_table"
                        className="table"
                        bordered={false}
                        striped
                        condensed
                      />
                    </div>
                  </Tab>
                );
              })}
            </Tabs>
          </Tab>
          <Tab eventKey="Favorite" title={`Favorite`}>
            <div className="crypto_balance_table_main mt-2">
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={favCoinData}
                columns={FavCoinColumns}
                noDataIndication={<EmptyData />}
                pagination={paginationFactory(options)}
                classes="crypto_balance_table"
                className="table"
                bordered={false}
                striped
                condensed
              />
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Market;
