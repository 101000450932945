import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { ThemeProvider } from "./context/ThemeContext";
import Index from "./pages";
import io from "socket.io-client";
import ApiUrl from "./_constant/index";
import BASE_URL from "./_constant/index";
import { useSelector, useDispatch } from "react-redux";
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router-dom';
import app from "./messaging_init_in_sw";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast } from "./components/Toast/Toast";
import { notificationUpdateDeviceId, logout } from "./redux/apiActions/user.action";
const analytics = getAnalytics(app);

const App = () => {
  // const idleTimerRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleOnIdle = async () => {
    localStorage.removeItem("notificationToken");
    localStorage.removeItem("login_token")
    try {
      await dispatch(logout());
      history.push(BASE_URL.BASE_URL + "login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  useIdleTimer({
    timeout: 1200000, // 20 minute
    // timeout: 60000, // 1 minute
    onIdle: handleOnIdle,
    debounce: 500
  });

  const userData = useSelector((state) => state.user.userData);
  const login_token = userData?.login_token;

  const [notificationToken, setNotificationToken] = useState(null);

  const messaging = getMessaging(app);

  const notificationDeviceId = async (token) => {
    let data = new FormData();
    data.append("device_id", token);
    const response = notificationUpdateDeviceId(data);
  };

  useEffect(() => {

    const storedToken = localStorage.getItem("notificationToken");
    if (storedToken) {
      setNotificationToken(storedToken);
    }

    const activateMessages = async () => {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPID_KEY,
        }).catch((error) => console.log("error generating token", error));

        if (token) {
          localStorage.setItem("notificationToken", token);
          if (login_token && storedToken == null) {
            notificationDeviceId(token);
            setNotificationToken(token);
          } else {
            setNotificationToken(token);
          }
        }
        if (!token) console.log("no token");
      }
    };
    activateMessages();

    onMessage(messaging, (message) => {
      if (message?.data?.type === "trade") {
        const parsedData = JSON.parse(message.data.data);
        const tradePartnerId = parsedData?.id;
        toast.notificationSuccessesTost(message?.data?.title, message?.data?.body, tradePartnerId);
      } else {
        toast.successes(message?.data?.title, message?.data?.body);
      }
    });
  }, [messaging, login_token, notificationToken]);

  // const [state, setState] = useState({ theme: "dark" });

  const [state, setState] = useState({
    theme: localStorage.getItem("theme") || "dark",
  });

  const updateBodyClass = (theme) => {
    if (theme === "dark") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  };

  useEffect(() => {
    localStorage.setItem("theme", state.theme);
    updateBodyClass(state.theme);
  }, [state.theme]);

  const socket = io(ApiUrl.ApiUrl);

  socket.on("connect", () => {
    console.log("[socket main] Connected");
  });

  socket.on("disconnect", (reason) => {
    console.log("[socket Main] Disconnected:", reason);
    // attempt to reconnect after 5 seconds
    setTimeout(() => {
      socket.connect();
    }, 5000);
  });

  socket.on("error", (error) => {
    console.log("[socket main] Error:", error);
  });

  return (
    <>
      {/* <Notification /> */}
      <BrowserRouter>
        <Route
          component={ScrollToTop}

        />
        <ThemeProvider
          value={{
            data: state,
            update: (themeVal) => {
              setState({
                theme: themeVal,
              });
            },
          }}
        >
          <Index socket={socket} notificationToken={notificationToken} />
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
};

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

export default App;
