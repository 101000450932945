import { fetch } from "../axios.services";
import _constant from "../../_constant";
const CONSTNAT = _constant.TOKEN;

const BASE_URL = `${_constant.ApiUrl}/exchange`;

const BASE_URL_2 = `${_constant.BASE_URL_Tow}`;

// const P2P_URL ="https://www.mlm.pixelsoftwares.com/vynksafe_v1/"

const P2P_URL = `${_constant.BASE_URL_Tow}p2p/`;

export const getCoinList = (data, headerData) => {
    return fetch("post", `${P2P_URL}api_p2p/get_coin_list`, data, headerData);
};
export const getCurrencyList = (headerData) => {
    return fetch("post", `${P2P_URL}api_p2p/currency_list`, {}, headerData);
};
export const payment_methods = (data, headerData) => {
    return fetch("post", `${P2P_URL}api_p2p/payment_methods`, data, headerData);
};
export const countryList = (headerData) => {
    return fetch("post", `${P2P_URL}api_p2p/country_list`, {}, headerData);
};
export const searchOffer = (formData, headerData) => {
    return fetch("post", `${P2P_URL}api_p2p/search_offer`, formData, headerData);
};
export const fundWalletBalance = (formData, headerData) => {
    return fetch("post", `${BASE_URL_2}/api_fund/fund_wallet_balance`, formData, headerData);
};
export const openTrade = (formData, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/open_trade`, formData, headerData);
};
export const getMyOfferList = (formData, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/get_my_offer_list`, formData, headerData);
};
export const getOfferDetailsByID = (formData, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/get_offer_details_byID`, formData, headerData);
};
export const updatePauseStatus = (formData, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/update_pause_status`, formData, headerData);
};
export const deleteOffer = (formData, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/delete_offer`, formData, headerData);
};

export const getTradeList = (formData, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/get_trade_list`, formData, headerData);
};
export const paymentMethods = (formData, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/payment_methods`, formData, headerData);
};
export const createOffer = (formData, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/create_offer`, formData, headerData);
};

export const userPaymentDetailsById = (formData, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/user_payment_details_by_id`, formData, headerData);
};

export const userData = (formData, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/get_user_profile`, formData, headerData);
};
export const getFeedbackList = (formData, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/get_feedback_list`, formData, headerData);
};
export const getUserFeedbackList = (formData, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/get_user_feedback_list`, formData, headerData);
};

export const getPaymentDetailsByUser = (headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/get_payment_details_byUser`, {}, headerData);
};
export const editPaymentDetails = (formData, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/edit_payment_details`, formData, headerData);
};
export const deletePaymentDetails = (formData, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/delete_payment_details`, formData, headerData);
};
export const addPaymentDetails = (formData, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/add_payment_details`, formData, headerData);
};
export const paymentTimeList = (headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/payment_time_list`, {}, headerData);
};
export const editOffer = (data, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/edit_offer`, data, headerData);
};
export const getTradeDetailsByID = (data, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/get_trade_details_byID`, data, headerData);
};
export const cancelTrade = (data, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/cancel_trade`, data, headerData);
};
export const getPaymentDetailsByTradeID = (data, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/get_user_offer_payment_details`, data, headerData);
};
export const get_TradePayment_Details = (data, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/get_payment_details_by_tradeID`, data, headerData);
};
export const paymentReleased = (data, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/payment_released`, data, headerData);
};
export const createAppeal = (data, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/create_appeal`, data, headerData);
};
export const getTrade_appeal = (data, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/get_trade_appeal`, data, headerData);
};
export const coinReleased = (data, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/coin_released`, data, headerData);
};
export const tradeFeedback = (data, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/trade_feedback`, data, headerData);
};
export const getTradeStatus = (data, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/get_trade_status`, data, headerData);
};
export const chatPushNotification = (data, headerData) => {
    return fetch("post", `${P2P_URL}/api_p2p/chat_push_notification`, data, headerData);
};

