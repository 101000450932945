import React, { useState, useEffect } from 'react';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import Chart from '.././components/Dashboard/Chart'
import Market from '.././components/Dashboard/Market'
import RightMenu from '../components/Dashboard/RightMenu';
import { useHistory, generatePath, useParams } from "react-router-dom";
import BASE_URL from "../_constant/index";
import Security from '../components/Dashboard/Security';
import Verification from '../components/Dashboard/Verification';
import Settings from '../components/Dashboard/Settings';
import { Link } from 'react-router-dom'
import utill from "../_constant/utill";
import { userCoinListAction, dashboardData } from "../redux/apiActions/user.action";
import KycVerificationPopUp from '../components/KycVerificationPopUp';
import Notifaction from '../components/Dashboard/Notifaction';


export default function Profile({ socket, notificationData, show, readNotification, notification, deleteNotificationFn }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  let { active_path } = useParams();
  active_path = active_path ? active_path : "dashboard";

  const [userCoinList, setUserCoinList] = useState([]);
  const [selectedCoin, setSelectedCoin] = useState(active_path);
  const [totalBtc, setTotalBtc] = useState(null);
  const [dashBoardUserData, setDashBoardUserData] = useState({});

  const createSum = userCoinList?.reduce((total, item) => { return total + parseFloat(item?.balance_currency) }, 0)

  const getDashBoardData = async () => {
    const response = await dashboardData();
    if (response?.data.status == 200) {
      setDashBoardUserData(response?.data?.data?.user);
    }
  }

  useEffect(() => {
    getDashBoardData();
    const path = ["dashboard", "security", "identification", "notification", "settings"]
    if (!path.includes(active_path)) {
      history.push(generatePath(`${BASE_URL.BASE_URL}notfound`));
    }
  }, [])

  const findBtcPrice = () => {
    const btcPrice = userCoinList.find(coin => coin.coin == 'btc')
    if (btcPrice) {
      setTotalBtc(createSum / btcPrice?.rate_currency)
    }
  }

  useEffect(() => {
    findBtcPrice();
  }, [userCoinList])


  useEffect(() => {
    setSelectedCoin(active_path)
  }, [active_path])


  const pushPairURL = (active_path) => {

    let myPairPath = history.push(
      generatePath(BASE_URL.BASE_URL + "profile/" + active_path)
    );
    setSelectedCoin(active_path);
  };

  useEffect(() => {
    if (userData?.access_token) {
      const walletCoinList = async () => {
        const coinListData = await userCoinListAction(dispatch);
        if (coinListData?.status === 200) {
          setUserCoinList(coinListData?.data);
        }
      };
      walletCoinList();
    } else {
      history.push(generatePath(BASE_URL.BASE_URL + "login"));
    }
  }, [userData, history, setUserCoinList, dispatch,]);

  const Profile = active_path == "identification" ? null : "Dashboard"

  return (
    <>
      {active_path !== "identification" && <KycVerificationPopUp Profile={Profile} active_path={active_path} />}
      <div className="settings mb15">
        <div className="container-fluid">
          <Tab.Container defaultActiveKey="dashboard" activeKey={selectedCoin} onSelect={pushPairURL}  >
            <Row>
              <Col lg={2} className="profile-left-tab pr-0 pl-0">
                <Nav variant="pills" className="settings-nav">
                  <Nav.Item>
                    <Nav.Link eventKey="dashboard" className="d-flex align-items-center"><img src={`${BASE_URL.BASE_URL}img/profile/dashboard.png`} alt="Dashboard" className="deactivate mr-2" /><img src={`${BASE_URL.BASE_URL}img/profile/dashboard_w.png`} alt="Dashboard" className="active mr-2" /> Dashboard</Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="security" className="d-flex align-items-center"><img src={`${BASE_URL.BASE_URL}img/profile/security.png`} alt="Dashboard" className="deactivate mr-2" /><img src={`${BASE_URL.BASE_URL}img/profile/security_w.png`} alt="Dashboard" className="active mr-2" />Security</Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="identification" className="d-flex align-items-center"><img src={`${BASE_URL.BASE_URL}img/profile/identification.png`} alt="Identification" className="deactivate mr-2" /><img src={`${BASE_URL.BASE_URL}img/profile/identification_w.png`} alt="Identification" className="active mr-2" />Identification</Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="notification" className="d-flex align-items-center"> <i className="icon ion-md-notifications d-flex align-items-center justify-content-center deactivate" style={{ fontSize: "18px" }}></i>Notifications</Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="settings" className="d-flex align-items-center"><img src={`${BASE_URL.BASE_URL}img/profile/settings.png`} alt="Settings" className="deactivate mr-2" /><img src={`${BASE_URL.BASE_URL}img/profile/settings_w.png`} alt="Settings" className="active mr-2" />Settings</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg={9} className="mx-auto">
                <Tab.Content>
                  <Tab.Pane eventKey="dashboard" className="mt-4">
                    <Row>
                      <Col md={8}>
                        <div className="settings_top_title d-flex flex-wrap justify-content-between align-items-center">
                          <div>
                            <h5 className="card-title mb-3">Estimated Balance</h5>
                          </div>
                          <div className="estimated-balance-btn">
                            <Link to={`${BASE_URL.BASE_URL}Mywallet/Overview?to=deposit`}>
                              <button className="btn btn-primary">Deposit</button>
                            </Link>
                            <Link to={`${BASE_URL.BASE_URL}Mywallet/Overview?to=withdraw`}>
                              <button className="btn withdraw-btn">Withdraw</button>
                            </Link>
                            {/* <button className="btn withdraw-btn">Buy Crypto</button> */}
                          </div>
                        </div>
                        <div className="card-estimated-balance-section mtb15">
                          <h3 className="card-estimated-balance">{utill.beautifyNumber(totalBtc, 4)} BTC <span>= ${utill.beautifyNumber(createSum, 2)}</span></h3>
                        </div>
                        {/* <div className="card">
                          <div className="card-body p-0">
                            <div className="dashboard-notification">
                              <Chart />
                            </div>
                          </div>
                        </div> */}
                      </Col>
                      <Col md={4}>
                        <RightMenu dashBoardUserData={dashBoardUserData} />
                      </Col>
                    </Row>
                    <div className="card">
                      <div className="card-body p-0">
                        <div className="dashboard-notification">
                          <Market socket={socket} setUserCoinList={setUserCoinList} userCoinList={userCoinList} />
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="security">
                    <Security dashBoardUserData={dashBoardUserData} getDashBoardData={getDashBoardData} setDashBoardUserData={setDashBoardUserData} />
                  </Tab.Pane>

                  <Tab.Pane eventKey="identification">
                    <Verification dashBoardUserData={dashBoardUserData} selectedCoin={selectedCoin} />
                  </Tab.Pane>

                  <Tab.Pane eventKey="notification">
                    <Notifaction notificationDataa={notificationData} readNotifications={readNotification} notification={notification} />
                  </Tab.Pane>

                  <Tab.Pane eventKey="settings">
                    <Settings getDashBoardData={getDashBoardData} />
                  </Tab.Pane>

                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>




    </>
  );
}

