// const SYMBOL_ID = {
//   "BNB/ETH": "1",
//   "BNB/USDT": "2",
//   "ETH/USDT": "3",
//   "PIXEL/LINK": "4",
//   "BNB/ETHTEST": "7",
//   "BNB/TRX": "10",
//   "LINK/BNB": "12",
//   "LINK/PAREA": "14",
//   "BTCU/SDT": "15",
//   "BNB/PIXEL": "18",
// };
/** for build */
// const BASE_URL = "/exchange/v1/";

/** for development */
const BASE_URL = "/";

/**
 * Live url end points
 */

const ApiUrl = "https://exchange.vynksafe.com:5670";
const BASE_URL_Tow = "https://www.app.vynksafe.com/";
const phpUrl = "https://vynksafe.com";
const cookies_name = 'vynksafe_login';
const domain = "vynksafe.com";
const clientId = "648642801536-9mh3imued9q02tbi184tokmlcsf9o1r2.apps.googleusercontent.com"

/**
 *  Development url endpoints
 */
// https://www.mlm.pixelsoftwares.com/vynksafe_v1/


// const phpUrl = "https://mlm.pixelsoftwares.com/vynksafe/vynksafe.com/"
// const ApiUrl = "https://io.pixelsoftwares.com:5670";
// const BASE_URL_Tow = "https://www.mlm.pixelsoftwares.com/vynksafe_v1/"
// const cookies_name = 'vynksafe_login';
// const domain = "pixelsoftwares.com";
// const clientId = "921214406581-sqo92ohj0l6uoqegqjqp0up9v7g3rjie.apps.googleusercontent.com"


const PRICEPER = {
  ONE: 25,
  TWO: 50,
  THREE: 75,
  FOUR: 100,
};

const DEVICE_TOKEN = {
  TOKEN: "33c6a6e7431e1db08e80fc3a8d9e0571",
  LOGINTOKEN: "88e1893f659709cad1a8b03cbe306edb", // vynksafe_v1 php
  NODE_TOKEN: "33c6a6e7431e1db08e80fc3a8d9e0571", // vynksafe_v1 node
};


const firebaseConfig = {
  apiKey: "AIzaSyAb1TM4nhaPMTaZzAkg78LyzI4m5R__RGQ",
  authDomain: "vynksafe-8e63e.firebaseapp.com",
  databaseURL: "https://vynksafe-8e63e-default-rtdb.firebaseio.com",
  projectId: "vynksafe-8e63e",
  storageBucket: "vynksafe-8e63e.appspot.com",
  messagingSenderId: "648642801536",
  appId: "1:648642801536:web:1866b3b4e24c00e2775b65",
  measurementId: "G-C6LM4VQMPG"
};

const vapidKey = "BEGldjOE5ohFfKpt49-TvxVtjtp9J73157FV_3C4-gG3_e1zY-KU0rUyGODwNnbiQak5Q259YPxBbmVIofLLDuc"

export default {
  // SYMBOL_ID: SYMBOL_ID,
  firebaseConfig: firebaseConfig,
  vapidKey: vapidKey,
  PRICEPER: PRICEPER,
  TOKEN: DEVICE_TOKEN,
  BASE_URL: BASE_URL,
  ApiUrl: ApiUrl,
  BASE_URL_Tow: BASE_URL_Tow,
  phpUrl: phpUrl,
  cookies_name: cookies_name,
  domain: domain,
  clientId: clientId
};
