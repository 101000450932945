import React, { useEffect, useState } from 'react';
import BASE_URL from "../_constant/index";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, generatePath } from "react-router-dom";
import {
    conformForgetPassword,
    resetPasswordConformMail
} from "../redux/apiActions/otp.verify.action";

const Reset_Password_otp = () => {
    useEffect(() => {
        setemail(localStorage.getItem("E-mail"))
    }, [])


    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoader] = useState(false);
    const [otp, setOtp] = useState("")
    const [email, setemail] = useState("")
    const [error, seterror] = useState(false)
    const [errorMsg, seterrorMsg] = useState("")
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(30);

    const userData = useSelector((state) => state.user.userData);
    useEffect(() => {
        if (userData?.access_token) {
            history.push(generatePath(BASE_URL.BASE_URL));
        }
    }, [userData]);


    const onChangeHandler = (e) => {
        setOtp(e.target.value.replace(/\D/g, ""));
        seterror(false);
    }


    useEffect(() => {

        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds, minutes,]);

    const resendOTP = async () => {
        setMinutes(0);
        setSeconds(30);
        setOtp("")
        if (email == "") {
            seterror(true)
            seterrorMsg("Register again");
            history.push(BASE_URL?.BASE_URL + "signup");
        }
        let formData = new FormData();
        formData.append("email", email);
        dispatch(resetPasswordConformMail(formData));
    };






    const verifyOtp = async (e) => {
        setLoader(true);
        e.preventDefault();
        try {
            if (otp.length == 0) {
                seterror(true)
                seterrorMsg("Invalid otp");
                setLoader(false);
            } else if (otp.length < 6) {
                seterror(true)
                setLoader(false);
                seterrorMsg("Min 6 digit");
            }
            else {
                let formData = new FormData();
                formData.append("email", email);
                formData.append("otp_ver", otp);
                await dispatch(conformForgetPassword(formData, history));
                setLoader(false);
            }

        } catch (err) {
            console.log(err);
        }

    }
    return (
        <div>
            <div className="basic_pages">


                <div className="row row-cols-1 row-cols-md-2 row-cols-md-1 row-cols-sm-1 align-items-center gap-v-4" style={{ height: "100%" }}>
                    <div className="col d-flex align-items-center justify-content-center">
                        <div className="form-access">
                            <form>
                            <h3 className="mb-3 Welcome" >OTP <span className="text-orange" >Verification</span></h3>
                                {/* <span className="mb-0"> </span> */}
                                <p className="text-left mb-4">
                                    One time password has been sent to your email
                                </p>
                                <input
                                    type="text"
                                    maxLength="6"
                                    minLength="6"
                                    className="form-control"
                                    placeholder="Enter your otp"
                                    value={otp}
                                    onChange={onChangeHandler}
                                />
                                {error && <small className="error-message text-danger text-wrap m-1">{errorMsg}</small>}
                                <div className="countdown-text">
                                    <div className="c_inner">
                                        {seconds > 0 || minutes > 0 ? (
                                            <p>
                                                Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}
                                                :{seconds < 10 ? `0${seconds}` : seconds}
                                            </p>
                                        ) : (
                                            <>
                                                <p>Didn't receive code?</p>
                                                <button className="btn text-orange" onClick={resendOTP}>
                                                    Resend OTP
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center justify-content-sm-between mt-4 flex-wrap gap-v-5">
                                    <button
                                        className="btn btn-primary signinButton mt-0"
                                        onClick={verifyOtp}
                                        disabled={
                                            (loading && (otp !== "") ? true : false) || otp.length < 6
                                        }
                                    >
                                        {loading ? "Please wait..." : "Verify OTP"}
                                    </button>

                                    <h2 className=''>
                                        Remember Password?
                                        <Link to={BASE_URL.BASE_URL + "login"} className="text-orange"> Sign in here</Link>

                                    </h2>
                                </div>


                            </form>
                        </div>
                    </div>
                    <div className="col px-lg-5 px-3 login-background d-flex align-items-center justify-content-center" style={{ height: "100%" }}>
                        <div className="p-4 d-flex align-items-center justify-content-center w-100" >
                            <img
                                src={`${BASE_URL.BASE_URL}img/images/SignUp.svg`}
                                className="img-fluid light-theme-img"
                                alt="banner"
                                width="450"
                            />
                            <img
                                src={`${BASE_URL.BASE_URL}img/images/Sign.png`}
                                className="img-fluid dark-theme-img"
                                alt="banner"
                                width="450"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reset_Password_otp