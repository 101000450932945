import React, { useRef, useEffect, useState, useCallback } from "react";
import { widget } from "../../charting_library";
import BASE_URL from "../../_constant/index";
import Datafeed from "./datafeed";
import utill from "../../_constant/utill";
// import TradingView from 'tradingview-library-path';

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window?.location?.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

const TradingChart = ({ pair, theme, order_Price }) => {
  const [isLoading, setIsLoading] = useState(true);
  const ref = useRef(null);
  const onChartReady = useCallback(() => {
    setIsLoading(false);
  }, []);

  const tvWidgetRef = useRef({});

  useEffect(() => {
    let pair_symbol = !pair?.symbol ? pair : pair?.symbol;
    const widgetOptions = {
      onReady: () => {
        tvWidgetRef.current =
          tvWidgetRef.current || tvWidgetRef.current.widget();
      },
      symbol: "VYNKSAFE:" + pair_symbol,
      datafeed: Datafeed,
      interval: "60",
      container: ref.current,
      library_path: BASE_URL.BASE_URL + "charting_library/",
      locale: getLanguageFromURL() || "en",
      disabled_features: [
        "use_localstorage_for_settings",
        "header_symbol_search",
        "header_compare",
        "compare_symbol",
        "header_saveload",
        "study_templates",
        "go_to_date",
        // 'timeframes'
        "timeframes_toolbar",
      ],
      charts_storage_api_version: "1.1",
      client_id: "tradingview.com",
      user_id: "public_user_id",
      fullscreen: false,
      autosize: true,
      theme: theme,
      height: 550,
      timezone: "Asia/Kolkata",
      timeframe: "D",
      enable_publishing: false,
      withdateranges: true,
      studies_overrides: {},
      loading_screen: {
        backgroundColor: theme == "dark" ? "#161a25" : "#ffffff00",
      },
    };
    tvWidgetRef.current = new widget(widgetOptions);
  }, []);

  useEffect(() => {
    tvWidgetRef.current.onChartReady(onChartReady);
    let pair_symbol = !pair?.symbol ? pair : pair?.symbol;
    tvWidgetRef.current.onChartReady(function () {
      tvWidgetRef.current.setSymbol(
        "VYNKSAFE:" + pair_symbol,
        "60",
        function () {}
      );

      tvWidgetRef.current.changeTheme(theme);
    });
  }, [pair, theme]);

  // function updateOrCreatePriceLine(chart, price) {
  //   console.log(chart, price)
  //   // Assuming `chart` is your chart widget instance and `price` is the new price to display
  //   if (!chart.priceLine) {
  //     // If there's no price line, create it.
  //     chart.priceLine = chart.createPriceLine({
  //       price,
  //       color: "#ff0000",
  //       lineWidth: 2,
  //       lineStyle: TradingView.LINESTYLE_DOTTED,
  //       axisLabelVisible: true,
  //       title: "Order Price",
  //     });
  //   } else {
  //     // If a price line exists, update its price.
  //     chart.priceLine.setPrice(price);
  //   }
  // }

//   useEffect(() => {
//     if (tvWidgetRef.current && order_Price) {
//         // Assuming tvWidgetRef.current gives you access to the chart instance.
//         // You might need to adapt this part depending on how you can access the chart instance.
//         const chart = tvWidgetRef.current.chart(); // Get the chart instance from your widget reference.

//         updateOrCreatePriceLine(chart, order_Price);
//     }
// }, [order_Price]);

  return (
    <div>
      <div className="mobile_chart" ref={ref} />
      <p
        className="pr-4 pl-4 mb-0 mt-1"
        style={{ fontSize: "10px", fontWeight: 200, lineHeight: "15px" }}
      >
        VYNKSAFE has partnered with TradingView for its charting, a
        comprehensive investing and trading platform providing global market
        insights, live market data and opportunities for{" "}
        <span style={{ fontSize: "10px" }}>
          <a
            title="https://www.tradingview.com/screener/"
            href="https://www.tradingview.com/screener/"
            target="blank"
          >
            learning how to use stock screeners.
          </a>
        </span>
      </p>
    </div>
  );
};

export default React.memo(TradingChart);














// import React, { useRef, useEffect, useState, useCallback } from 'react';
// import { widget } from '../../charting_library';
// import BASE_URL from '../../_constant/index';
// import Datafeed from './datafeed';
// import utill from '../../_constant/utill';

// function getLanguageFromURL() {
//   const regex = new RegExp('[\\?&]lang=([^&#]*)');
//   const results = regex.exec(window?.location?.search);
//   return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
// }

// // const TradingChart = ({ pair, theme }) => {
// //   const [isLoading, setIsLoading] = useState(true);
// //   const ref = useRef(null);
// //   const tvWidgetRef = useRef(null);

// //   const onChartReady = useCallback(() => {
// //     setIsLoading(false);
// //   }, []);

// //   useEffect(() => {
// //     let pair_symbol = !pair?.symbol ? pair : pair?.symbol;

// //     const widgetOptions = {
// //       symbol: 'VYNKSAFE:' + pair_symbol,
// //       datafeed: Datafeed,
// //       interval: '1D',
// //       container: ref.current,
// //       library_path: BASE_URL.BASE_URL + 'charting_library/',
// //       locale: getLanguageFromURL() || 'en',
// //       disabled_features: [
// //         'use_localstorage_for_settings',
// //         'header_symbol_search',
// //         'header_compare',
// //         'compare_symbol',
// //         'header_saveload',
// //         'study_templates',
// //         'go_to_date',
// //         'timeframes_toolbar',
// //       ],
// //       charts_storage_api_version: '1.1',
// //       client_id: 'tradingview.com',
// //       user_id: 'public_user_id',
// //       fullscreen: false,
// //       autosize: true,
// //       theme: theme,
// //       height: 550,
// //       studies_overrides: {},
// //       loading_screen: {
// //         backgroundColor: '#161a25',
// //       },
// //     };

// //     if (tvWidgetRef.current) {
// //       tvWidgetRef.current.remove();
// //     }

// //     tvWidgetRef.current = new widget(widgetOptions);

// //     tvWidgetRef.current.onChartReady(onChartReady);

// //     return () => {
// //       tvWidgetRef.current.remove();
// //     };
// //   }, [pair, theme, onChartReady]);

// //   return (
// //     <div style={{ background: '#161a25' }}>
// //       <div ref={ref} style={{ minHeight: '75vh', height: '410px' }} />
// //     </div>
// //   );
// // };
// const TradingChart = ({ pair, theme }) => {
//   const [isLoading, setIsLoading] = useState(true);
//   const ref = useRef(null);
//   const onChartReady = useCallback(() => {
//     setIsLoading(false);
//   }, []);

//   const tvWidgetRef = useRef({});

//   useEffect(() => {
//     // document.querySelector('.tv-header__link').remove();
//     let pair_symbol = !pair?.symbol ? pair : pair?.symbol;
//     const widgetOptions = {
//       onReady: () => {
//         tvWidgetRef.current = tvWidgetRef.current || tvWidgetRef.current.widget();
//         // const chart = this.chart();
//         // const priceScale = chart.priceScale();
//         // priceScale.format().priceFormatter().format = function (price) {
//         //   return utill.valueFix(price);
//         //   // return price.toFixed(2);
//         // };
//       },
//       symbol: 'VYNKSAFE:' + pair_symbol,
//       datafeed: Datafeed,
//       interval: '60',
//       container: ref.current,
//       library_path: BASE_URL.BASE_URL + 'charting_library/',
//       locale: getLanguageFromURL() || 'en',
//       disabled_features: [
//         'use_localstorage_for_settings',
//         'header_symbol_search',
//         'header_compare',
//         'compare_symbol',
//         'header_saveload',
//         'study_templates',
//         'go_to_date',

//         // 'timeframes'
//         'timeframes_toolbar',
//       ],
//       charts_storage_api_version: '1.1',
//       client_id: 'tradingview.com',
//       user_id: 'public_user_id',
//       fullscreen: false,
//       autosize: true,
//       theme: theme,
//       height: 550,
//       timezone: 'Asia/Kolkata',
//       timeframe: 'D',
//       enable_publishing: false,
//       withdateranges: true,
//       studies_overrides: {},
//       loading_screen: {
//         backgroundColor: theme == "dark" ? '#161a25' : "#ffffff00",
//       },
//     };
//     tvWidgetRef.current = new widget(widgetOptions);

//   }, []);

//   useEffect(() => {
//     tvWidgetRef.current.onChartReady(onChartReady);
//     let pair_symbol = !pair?.symbol ? pair : pair?.symbol;
//     // const symbol = tvWidgetRef?.current?.getSymbol(); // get the current symbol
//     // console.log(pair_symbol, "Changed symbol");
//     tvWidgetRef.current.onChartReady(function () {
//       // It's now safe to call any other methods of the widget
//       tvWidgetRef.current.setSymbol('VYNKSAFE:' + pair_symbol, "60", function () {
//         // console.log("New data received for", tvWidgetRef.current);
//         // tvWidgetRef.current.mainSeriesPriceFormatter()._priceScale = 1000000;

//         // tvWidgetRef.current.chart().mainSeriesPriceFormatter().format().priceFormatter().format = function (price) {
//         //   return utill.valueFix(price);
//         //   // return price.toFixed(2);
//         // };
//       });

//       tvWidgetRef.current.changeTheme(theme)
//     });
//     // return () => {
//     //   tvWidgetRef.current.remove();
//     // };
//   }, [pair, theme]);

//   return (
//     <div>
//       <div className='mobile_chart' ref={ref} />
//       <p className='pr-4 pl-4 mb-0 mt-1' style={{ fontSize: "10px", fontWeight: 200 , lineHeight:"15px" }}>
//         VYNKSAFE has partnered with TradingView for its charting, a comprehensive investing and trading platform providing global market insights, live market data and opportunities for <span style={{ fontSize: "10px" }}><a title='https://www.tradingview.com/screener/' href='https://www.tradingview.com/screener/' target='blank'>learning how to use stock screeners.</a></span>
//       </p>
//     </div>
//   );
// };

// export default React.memo(TradingChart);
