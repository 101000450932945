import * as userSlice from "../reducers/user";
import * as userServices from "../../services/API/api.service";
import { storeInstance } from "../../index";
import storage from "redux-persist/lib/storage";
import { toast } from "../../components/Toast/Toast";
import BASE_URL from "../../_constant/index";
import _constant from "../../_constant";

import Cookies from "universal-cookie";
const CONSTANT = _constant.TOKEN;
const cookies = new Cookies();
const cookies_name = _constant.cookies_name;
const domain = _constant.domain;

export const getHeaders = () => {
  let userToken = storeInstance.getState().user?.userData?.access_token;
  return {
    accesstoken: userToken,
    // "e71bccada8d4703538b91e627a48f1d3fbe50bde2cab47591d8845f1ab5d1bec1666871656",
    Token: CONSTANT.LOGINTOKEN,
  };
}; 
export const getHeaders_node = () => {
  let userToken = storeInstance.getState().user?.userData?.access_token;
  return {
    accesstoken: userToken,
    Token: CONSTANT.NODE_TOKEN,
    "Content-Type": "application/x-www-form-urlencoded",
  };
};
export const walletBalance_transfer = async () => {
  let headerData = getHeaders();
  try {
    const response = await userServices?.wallet_balance_transfer(
      {},
      headerData
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const loginAction = (userData, history, return_to_url = null, return_to_php = null) =>
  async (dispatch) => {
    try {
      const response = await userServices.login(userData);

      if (response?.data?.status === 200) {
        await dispatch(userSlice.userLogin(response?.data?.data));
        if (response?.data?.phone === false) {
          // window.open(BASE_URL.BASE_URL + "verify-number", "_self");
          history.push(BASE_URL.BASE_URL + "verify-number");
          toast.info(response?.data?.message);
          return false;
        }
        if (
          response?.data?.data.gAuth_active == 1 &&
          !response?.data?.data.access_token
        ) {
          toast.info(response?.data?.message);
          // window.open(BASE_URL.BASE_URL + "verify-number", "_self");
          history.push(BASE_URL.BASE_URL + "login");
          return false;
        } else {
          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 30);
          cookies.set(cookies_name, `${response?.data?.data?.access_token}`, {
            expires: expirationDate,
            domain: domain,
            path: "/",
            // secure: true,
          });
          // console.log(response?.data?.data?.access_token); // Pacman

          toast.success("Login successfully");
          if (return_to_php) {
            window.open(return_to_php, "_self");
          } else {
            history.push(
              return_to_url
                ? return_to_url
                : BASE_URL.BASE_URL + "profile/dashboard"
            );
          }
        }
        // history.push(return_to_url ? return_to_url : BASE_URL.BASE_URL + "profile/dashboard");
        // history.replace(return_to_url ? return_to_url : BASE_URL.BASE_URL + "profile/dashboard");
      } else if (response?.data?.kyc === false) {
        toast.info(response?.data?.message || "");
        history.push(BASE_URL.BASE_URL + "verify-number");
        return false;
      } else {
        return response;
      }
    } catch (err) {
      console.log(" :: ERROR FROM loginAction", err);
      // toast.error(err.data.error.message);
    }
  };

export const loginWithQrAction =
  (userData, history, return_to_url = null, return_to_php = null) =>
  async (dispatch) => {
    try {
      const response = await userServices.loginwithQr(userData);
      if (response?.data?.status === 200) {
        if (response?.data?.qr_status ==2) {
          await dispatch(userSlice.userLogin(response?.data?.data));
          if (response?.data?.phone === false) {
            toast.info(response?.data?.message);
            // window.open(BASE_URL.BASE_URL + "verify-number", "_self");
            history.push(BASE_URL.BASE_URL + "verify-number");
            return false;
          } else {
            history.push(BASE_URL.BASE_URL + "profile/dashboard");
            // const expirationDate = new Date();
            // expirationDate.setDate(expirationDate.getDate() + 30);
            // cookies.set(cookies_name, `${response?.data?.data?.access_token}`, {
            //   expires: expirationDate,
            //   domain: domain,
            //   path: "/",
            //   // secure: true,
            // });
            // console.log(response?.data?.data?.access_token); // Pacman

            toast.success("Login successfully");
            if (return_to_php) {
              window.open(return_to_php, "_self");
            } else {
              history.push(
                return_to_url
                  ? return_to_url
                  : BASE_URL.BASE_URL + "profile/dashboard"
              );
            }
          }
        }

        return response;
        // history.push(return_to_url ? return_to_url : BASE_URL.BASE_URL + "profile/dashboard");
        // history.replace(return_to_url ? return_to_url : BASE_URL.BASE_URL + "profile/dashboard");
      } else if (response?.data?.kyc === false) {
        toast.info(response?.data?.message || "");
        history.push(BASE_URL.BASE_URL + "verify-number");
        return false;
      } else {
        return response;
      }
    } catch (err) {
      console.log(" :: ERROR FROM loginAction", err);
      // toast.error(err.data.error.message);
    }
  };

export const loginActionwithbuttons =
  (userData, history, return_to_url = null, return_to_php = null) =>
  async (dispatch) => {
    try {
      const response = await userServices.loginwith(userData);
      if (response?.status === 200) {
        await dispatch(userSlice.userLogin(response?.data?.data));
        if (response?.data?.phone === false) {
       
          // window.open(BASE_URL.BASE_URL + "verify-number", "_self");
          history.push(BASE_URL.BASE_URL + "verify-number");
          toast.info(response?.data?.message);
          return false;
        }
        if (
          response?.data?.data.gAuth_active == 1 &&
          !response?.data?.data.access_token
        ) {
          toast.info(response?.data?.message);
          // window.open(BASE_URL.BASE_URL + "verify-number", "_self");
          history.push(BASE_URL.BASE_URL + "login");
          return false;
        } else {
          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 30);
          cookies.set(cookies_name, `${response?.data?.data?.access_token}`, {
            expires: expirationDate,
            domain: domain,
            path: "/",
            // secure: true,
          });
          // console.log(response?.data?.data?.access_token); // Pacman

          toast.success("Login successfully");
          if (return_to_php) {
            window.open(return_to_php, "_self");
          } else {
            history.push(
              return_to_url
                ? return_to_url
                : BASE_URL.BASE_URL + "profile/dashboard"
            );
          }
        }
        // history.push(return_to_url ? return_to_url : BASE_URL.BASE_URL + "profile/dashboard");
        // history.replace(return_to_url ? return_to_url : BASE_URL.BASE_URL + "profile/dashboard");
      } else if (response?.data?.kyc === false) {
        toast.info(response?.data?.message || "");
        history.push(BASE_URL.BASE_URL + "verify-number");
        return false;
      } else {
        return response;
      }
    } catch (err) {
      console.log(" :: ERROR FROM loginAction", err);
      // toast.error(err.data.error.message);
    }
  };

export const logout = () => async (dispatch) => {
  let headerData = getHeaders();
  
  try {
    const response = await userServices?.logOutUser(headerData);
    if (response) {
      cookies.remove(cookies_name, {
        // expires: 1,
        domain: domain,
        path: "/",
        // secure: true,
      });

      await dispatch(userSlice.userLogin([]));
      await storage.removeItem("persist:root");
      localStorage.removeItem("notificationToken");
      localStorage.removeItem("User_kyc");
    }
  } catch (error) {
    console.log("error:", error);
  }
};

export const disableAccount = async () => {
  let headerData = getHeaders();
  try {
    const response = await userServices?.disable_account_api(headerData);
    return response.data;
  } catch (error) {
    toast.error("Something went wrong. Please try again later.");
    throw error;
  }
};

export const genrateQrCodeForLogin = async () => {
  let headerData = getHeaders();
  try {
    const response = await userServices?.loginwithQrscane(headerData);
    return response.data;
  } catch (error) {
    toast.error("Something went wrong. Please try again later.");
    throw error;
  }
};

export const userCoinListAction = async (dispatch = null) => {
  try {
    const response = await userServices.getAccountCoinList(getHeaders());
    if (response?.data?.status === 200) {
      return response?.data;
    } else if (response?.data?.status === 603) {
      if (dispatch) {
        dispatch(logout());
        window.location.reload(false);
      }

      return response?.data;
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    toast.error("Something went wrong. Please try again later.");
  }
};

export const userCoinTxnListAction = async (data, dispatch = null) => {
  try {
    const response = await userServices.getCoinTxnList(data, getHeaders());
    if (response?.data?.status === 200) {
      return response?.data;
    } else if (response?.data?.status === 603) {
      if (dispatch) {
        dispatch(logout());

        window.location.reload(false);
      }

      return response?.data;
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    toast.error("Something went wrong. Please try again later.");
  }
};

export const CoinNetworksListAction = async (data, dispatch = null) => {
  try {
    const response = await userServices.getCoinNetworks(data, getHeaders());
    if (response?.data?.status === 200) {
      return response?.data;
    } else if (response?.data?.status === 603) {
      // toast.error(response?.data?.message);
      if (dispatch) {
        dispatch(logout());
        // toast.info("you are logout");
        // history.push(BASE_URL.BASE_URL + "login");
        window.location.reload(false);
      }
      // history.push(BASE_URL.BASE_URL);
      return response?.data;
    } else {
    }
  } catch (error) {
    toast.error("Something went wrong. Please try again later.");
  }
};
export const getOtherUserAddress = async (data, dispatch = null) => {
  try {
    const response = await userServices.getUserAddress(data, getHeaders());
    if (response?.data?.status === 200) {
      return response?.data;
    } else if (response?.data?.status === 400) {
      return response?.data;
    } else if (response?.data?.status === 603) {
      // toast.error(response?.data?.message);
      if (dispatch) {
        dispatch(logout());
        // toast.info("you are logout");
        // history.push(BASE_URL.BASE_URL + "login");
        window.location.reload(false);
      }
      // history.push(BASE_URL.BASE_URL);
      return response?.data;
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    toast.error("Something went wrong. Please try again later.");
  }
};
export const getWithdrawGasFeeAction = async (
  data,
  url,
  send_mode,
  dispatch = null
) => {
  try {
    let headerData = getHeaders();
    if (send_mode == 1) {
      headerData = getHeaders_node();
    }
    const response = await userServices.getWithdrawGasFee(
      url,
      data,
      headerData
    );
    if (response?.data?.status === 200) {
      return response?.data;
    } else if (response?.data?.status === 400) {
      return response?.data;
    } else if (response?.data?.status === 603) {
      // toast.error(response?.data?.message);
      if (dispatch) {
        dispatch(logout());
        // toast.info("you are logout");
        // history.push(BASE_URL.BASE_URL + "login");
        window.location.reload(false);
      }
      // history.push(BASE_URL.BASE_URL);
      return response?.data;
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    toast.error("Something went wrong. Please try again later.");
  }
};
export const sendTransactionAction = async (
  data,
  url,
  send_mode,
  dispatch = null
) => {
  try {
    let headerData = getHeaders();
    if (send_mode == 1) {
      headerData = getHeaders_node();
    }
    const response = await userServices.WithdrawCoin(url, data, headerData);
    if (response?.data?.status === 200) {
      return response?.data;
    } else if (response?.data?.status === 400) {
      return response?.data;
    } else if (response?.data?.status === 603) {
      if (dispatch) {
        dispatch(logout());

        window.location.reload(false);
      }

      return response?.data;
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    toast.error("Something went wrong. Please try again later.");
  }
};

export const user_Kyc = async (data) => {
  let headerData = getHeaders();
  try {
    const response = await userServices.userKyc(data, headerData);
    return response?.data;
  } catch (err) {
    console.log(err, ": user_kyc_errror");
  }
};
export const notifications = async () => {
  try {
    const responce = await userServices.notification(getHeaders());
    return responce.data.data;
  } catch (error) {
    console.log("Error :", error);
  }
};

export const nickNameUpdate = async (data) => {
  let headerData = getHeaders();

  try {
    const response = await userServices.nickName(data, headerData);
    if (response?.data?.data) {
      toast.success(response?.data?.message);
    } else {
      toast.error(response?.data?.message);
    }
    return response.data;
  } catch (error) {
    console.log("Nick Name:", error);
  }
};
export const getUser_NickName = async (data) => {
  let headerData = getHeaders();
  try {
    const response = await userServices.getnickName(data, headerData);
    return response.data;
  } catch (error) {
    console.log("Nick Name:", error);
  }
};

export const notification_disable = async (data) => {
  let headerData = getHeaders();
  try {
    const response = await userServices.notificationDisable(data, headerData);
    return response.data;
  } catch (error) {
    console.log("ERROR :" + error);
  }
};
export const manage_trading_fee = async (data) => {
  let headerData = getHeaders();
  try {
    const response = await userServices.manageTradingFee(data, headerData);
    return response.data;
  } catch (error) {
    console.log("ERROR :" + error);
  }
};
export const changePassword = async (data) => {
  let headerData = getHeaders();
  try {
    const response = await userServices.change_password(data, headerData);
    return response.data;
  } catch (error) {
    console.log("Forget Password :", error);
  }
};
export const findingCoin = async (data) => {
  let headerData = getHeaders();
  try {
    const response = await userServices?.fundingCoin(data, headerData);
    return response.data;
  } catch (error) {
    console.log("finding Coin:", error);
  }
};

export const fundCoinTransferList = async (data) => {
  let headerData = getHeaders();
  try {
    const responce = await userServices?.fund_coin_transfer_list(
      data,
      headerData
    );
    return responce.data;
  } catch (error) {
    console.log(error);
  }
};
export const mainCoinList = async (data) => {
  let headerData = getHeaders();
  try {
    const responce = await userServices?.main_coin_list(data, headerData);
    return responce.data;
  } catch (error) {
    console.log(error);
  }
};
export const fundCoinList = async (data) => {
  let headerData = getHeaders();
  try {
    const responce = await userServices?.fund_coin_list(data, headerData);
    return responce.data;
  } catch (error) {
    console.log(error);
  }
};

export const fundTransfer = async (data) => {
  let headerData = getHeaders();
  try {
    const responce = await userServices?.fund_transfer(data, headerData);
    return responce.data;
  } catch (error) {
    console.log(error);
  }
};

export const login_activity = async () => {
  let headerData = getHeaders();
  try {
    const responce = await userServices?.loginActivity(headerData);
    return responce.data;
  } catch (error) {
    console.log(error);
  }
};
export const setUserActivityApi = async () => {
  let headerData = getHeaders();
  try {
    const responce = await userServices?.user_Activity(headerData);
    return responce.data;
  } catch (error) {
    console.log(error, ":of the user activity");
  }
};

export const user_user_userInfo = async () => {
  let headerData = getHeaders();
  try {
    const responce = await userServices?.user_userInfo(headerData);
    return responce.data;
  } catch (error) {
    console.log(error, ":of the user activity");
  }
};


export const activateAccount = async (data) => {
  let headerData = getHeaders();
  try {
    const responce = await userServices?.reactivateAccount(data, headerData);
    return responce;
  } catch (error) {
    console.log(error, ":of the Reactive Account");
  }
};

export const disableUserOtp = async (data) => {
  let headerData = getHeaders();
  try {
    const responce = await userServices.activationOtp(data, headerData);
    return responce;
  } catch (error) {
    console.log(error);
  }
};

export const notificationRead = async (data) => {
  let headerData = getHeaders();
  try {
    const responce = await userServices.readNotification(data, headerData);
    return responce;
  } catch (error) {
    console.log(error);
  }
};

export const fundWalletHistory = async (data) => {
  let headerData = getHeaders();
  try {
    const responce = await userServices.fund_wallet_history(data, headerData);
    return responce;
  } catch (error) {
    console.log(error);
  }
};

export const deleteNotification = async (data) => {
  let headerData = getHeaders();
  try {
    const response = await userServices.notificationDelete(data, headerData);
    return response;
  } catch (error) {
    console.log("Error form user email confirm :", error);
  }
};

export const googleAuthGetQR = async (data) => {
  let headerData = getHeaders();
  try {
    const response = await userServices.googleAuth(data, headerData);
    return response;
  } catch (error) {
    console.log("Google Auth :", error);
  }
};

export const googleAuthReset = async (data) => {
  let headerData = getHeaders();
  try {
    const response = await userServices.googleAuthResetRequest(
      data,
      headerData
    );
    return response.data;
  } catch (error) {
    console.log("Google Verify Auth:" + error);
  }
};

// googleAuthVerifyEnable

export const gAuthEnableDisable = async (data) => {
  let headerData = getHeaders();
  try {
    const response = await userServices.googleAuthVerifyEnable(
      data,
      headerData
    );
    return response.data;
  } catch (error) {
    console.log("Google Verify Auth:" + error);
  }
};

export const verifyGoogleAuth =
  (formData, history, userData, return_to_url = null) =>
  async (dispatch) => {
    try {
      const headerData = getHeaders();
      const response = await userServices.googleAuthVerify(
        formData,
        headerData
      );
      if (response?.data?.status === 200) {
        let access_token = response?.data?.data?.access_token;
        let newArray = { ...userData, access_token };
        await dispatch(userSlice.userLogin({ ...newArray }));
        history.push(
          return_to_url
            ? return_to_url
            : BASE_URL.BASE_URL + "profile/dashboard",
          "_self"
        );
        return response.data;
      } else {
        return response.data;
      }
    } catch (err) {
      console.log(" :: ERROR FROM loginAction", err);
      // toast.error(err.data.error.message);
    }
  };

export const googleAuthVerifyEnable = async (data) => {
  let headerData = getHeaders();
  try {
    const response = await userServices.googleAuthVerifyQR_enable(
      data,
      headerData
    );
    return response;
  } catch (error) {
    console.log("Error form user email confirm :", error);
  }
};

export const dashboardData = async (data) => {
  let headerData = getHeaders();
  try {
    const responce = await userServices?.dashboard(data, headerData);
    return responce;
  } catch (error) {
    console.log(error, ":of the Dashboard Data");
  }
};

export const otpSendPrivate = async (data) => {
  let headerData = getHeaders();
  try {
    const response = await userServices?.otp_send_private(data, headerData);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const otpVerifyPrivate = async (data) => {
  let headerData = getHeaders();
  try {
    const response = await userServices?.otp_verify_private(data, headerData);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const userDailyWithdrawLimit = async (data) => {
  let headerData = getHeaders();
  try {
    const response = await userServices?.user_daily_withdraw_limit(
      data,
      headerData
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const notificationUpdateDeviceId = async (data) => {
  let headerData = getHeaders();
  try {
    const response = await userServices?.notificationDeviceId(data, headerData);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const userSubscribe = async (data) => {
  let headerData = getHeaders();
  try {
    const response = await userServices?.subscribe(data);
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const UserContactUs = async (data) => {
  let headerData = getHeaders();
  try {
    const response = await userServices?.contactUs(data, headerData);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
// wallet_balance_transfer

//
export const User_APY_Balance = async () => {
  try {
    const responce = await userServices.APY_balance(getHeaders());
    return responce.data.data;
  } catch (error) {
    console.log("Error :", error);
  }
};
export const calculate_APY = async (data) => {
  let headerData = getHeaders();
  try {
    const responce = await userServices.calculate_APY(data, headerData);
    return responce.data;
  } catch (error) {
    console.log("Error :", error);
  }
};
export const apyTransaction = async (data) => {
  let headerData = getHeaders();
  try {
    const responce = await userServices.apyInterestTransaction(
      data,
      headerData
    );
    return responce.data;
  } catch (error) {
    console.log("Error :", error);
  }
};
export const trending_Pairs_List = async (data) => {
  let headerData = getHeaders();
  try {
    const responce = await userServices.trendingPairsList(headerData);
    return responce.data;
  } catch (error) {
    console.log("Error :", error);
  }
};
