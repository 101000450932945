import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import BASE_URL from "../_constant/index";
import BASE_URL_Tow from "../_constant/index";
import { ThemeConsumer } from "../context/ThemeContext";
import {
  Navbar,
  Nav,
  Dropdown,
  Button,
  NavDropdown,
} from "react-bootstrap";
import { logout } from "../redux/apiActions/user.action";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "./Toast/Toast";
import { userCoinListAction, dashboardData } from "../redux/apiActions/user.action";


const NewHeader = () => {
  const isMobile = window.matchMedia("(max-width: 991px)").matches;
  let userData = useSelector((state) => state.user.userData);

  const current_url = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [dashBoardUserData, setDashBoardUserData] = useState({});


  const getDashBoardData = async () => {
    const response = await dashboardData();
    if (response?.data.status == 200) {
      setDashBoardUserData(response?.data?.data?.user);
    }
  }

  useEffect(() => {
    if (
      userData?.access_token &&
      (userData?.isphoneupdated === "0" || userData?.isphoneupdated === 0)
    ) {
      // toast.info("Please Verify your Phone Number");
      setTimeout(() => {
        history.push(BASE_URL.BASE_URL + "verify-number");
      }, 500);
    }
  }, [userData, history, current_url.pathname]);

  useEffect(() => {
    let el = document.querySelector("#darkTheme");
    document.body.classList.add("dark");
    if (el) {
      el.addEventListener("click", function () {
        document.body.classList.toggle("dark");
      });
    }
  }, []);

  const [scrolled, setScrolled] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isEarn, setisEarn] = useState(false);
  const [isNavMore, setIsNavMore] = useState(false);

  const [expanded, setExpanded] = useState(false);

  const closeNavbar = () => setExpanded(false);

  const handleNavEnter = () => {
    setIsNavOpen(true);
  };

  const handleNavLeave = () => {
    setIsNavOpen(false);
    setExpanded(false);
  };

  const handleNavEnterEarn = () => {
    setisEarn(true);
  };

  const handleNavLeaveEARN = () => {
    setisEarn(false);
    setExpanded(false);
  };
  const handleNavEnterMore = () => {
    setIsNavMore(true);
  };

  const handleNavLeaveMore = () => {
    setIsNavMore(false);
    setExpanded(false);
  };
  const newClass = isMobile ? "site_header" : "site_headerrr";

  const onLogoutBtnClick = async () => {
    localStorage.removeItem("notificationToken");
    localStorage.removeItem("login_token")
    
    try {
      await dispatch(logout());
      history.push(BASE_URL.BASE_URL + "login");
    } catch (error) {
      console.error("Logout failed:", error);
    }

    // window.location.reload(false);
    // return <Redirect to="/login" />
  };

  return (
    <>
      <header className={scrolled ? "site_header" : newClass}>
        <div className="row">
          <div className="col-12 header_equal_v header_equal_new_v">
            <Navbar
              onToggle={(expanded) => setExpanded(expanded)}
              expanded={expanded}
              expand="xl"
            >
              <Link to={BASE_URL.BASE_URL}>
                <ThemeConsumer>
                  {({ data }) => {
                    return data.theme === "dark" ? (
                      <img
                        src={`${BASE_URL.BASE_URL}img/images/logo-d.svg`}
                        className="img-fluid"
                        alt="logo"
                        style={{ height: "30px" }}
                      // width="220"
                      />
                    ) : (
                      <img
                        src={`${BASE_URL.BASE_URL}img/images/light-theme/logo-d.svg`}
                        className="img-fluid"
                        alt="logo"
                        style={{ height: "30px" }}
                      // width="220"
                      />
                    );
                  }}
                </ThemeConsumer>
              </Link>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse
                className="justify-content-xl-between vynksafenew_header_main_section ml-xl-3"
                id="responsive-navbar-nav"
                onSelect={closeNavbar}
              >
                <Nav className="me-auto mr-auto vynksafenew_header_main_section_first">
                  <Link
                    to={BASE_URL.BASE_URL}
                    className="nav-link"
                    onClick={closeNavbar}
                  >
                    Home
                  </Link>
                  <Link
                    to={BASE_URL.BASE_URL + "market"}
                    className="nav-link"
                    onClick={closeNavbar}
                  >
                    Market
                  </Link>
                  {isMobile ? (
                    <>
                      <NavDropdown
                        title="Trade"
                        id="collasible-nav-dropdown"
                      >
                        <NavDropdown.Item
                          className="px-0 mb-1"
                          aria-controls="example-collapse-text"
                          dimension="width"
                          onClick={handleNavLeave}
                          style={{ textDecoration: "none" }}
                        >
                          <Link
                            to={BASE_URL.BASE_URL + `exchange/BNB_USDT`}
                            style={{ textDecoration: "none" }}
                            className="textDecoration"
                          >
                            <span className="vynksafenew_header_dropdown d-flex align-items-start">
                              <img
                                src={`${BASE_URL.BASE_URL}img/images/spot.svg`}
                                alt="Spot"
                                className="mr-2"
                                width="34"
                                height="34"

                              />
                              <div className="header_nav_dropdown_v">
                                <h5 className="mb-0">Spot</h5>
                                <p className="mb-0" style={{ lineHeight: "16px" }}>
                                  Buy & sell on the spot market with advanced
                                  tools
                                </p>
                              </div>
                            </span>
                          </Link>
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          href="#"
                          className="px-0 mb-1"
                          onClick={handleNavLeave}
                        >
                          <span className="vynksafenew_header_dropdown d-flex align-items-start">
                            <img
                              src={`${BASE_URL.BASE_URL}img/images/margin-icon.svg`}
                              alt="Spot"
                              className="mr-2"
                            />
                            <div className="header_nav_dropdown_v">
                              <h5 className="mb-0">Margin</h5>
                              <p className="mb-0" style={{ lineHeight: "16px" }}>
                                Increase your profits with leverage
                              </p>
                            </div>
                          </span>
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          // href={BASE_URL.phpUrl}
                          className="px-0 mb-1"
                          onClick={handleNavLeave}
                        >
                          <Link
                            to={BASE_URL.BASE_URL + "p2p/All"}
                          // href={`${BASE_URL.phpUrl}/saving_account`}savings
                          // className="nav-link"
                          >
                            <span className="vynksafenew_header_dropdown d-flex align-items-start">
                              <img
                                src={`${BASE_URL.BASE_URL}img/images/P2P-icon.svg`}
                                alt="Spot"
                                className="mr-2"
                                width="34"
                                height="34"

                              />
                              {/*  */}
                              <div className="header_nav_dropdown_v">
                                <h5 className="mb-0">P2P</h5>
                                <p className="mb-0" style={{ lineHeight: "16px" }}>
                                  Buy & sell cryptocurrencies using bank transfer
                                  and 800+ options
                                </p>
                              </div>
                            </span>
                          </Link>
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          // href="#"
                          className="px-0 mb-1"
                          onClick={handleNavLeave}
                        >
                          <Link to={BASE_URL.BASE_URL + "vynksafe-api"}>
                            <span className="vynksafenew_header_dropdown d-flex align-items-start">
                              <img
                                src={`${BASE_URL.BASE_URL}img/images/apis-icon.svg`}
                                alt="Spot"
                                className="mr-2"
                                width="34"
                                height="34"
                              />
                              <div className="header_nav_dropdown_v">
                                <h5 className="mb-0">APIs</h5>
                                <p className="mb-0" style={{ lineHeight: "16px" }}>
                                  Unlimited opportunities with one key
                                </p>
                              </div>
                            </span>
                          </Link>
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          href="#"
                          className="px-0 mb-1"
                          onClick={handleNavLeave}
                        >
                          <span className="vynksafenew_header_dropdown d-flex align-items-start">
                            <img
                              src={`${BASE_URL.BASE_URL}img/images/copy_trading_icon.svg`}
                              alt="Spot"
                              className="mr-2"
                              width="34"
                              height="34"
                            />
                            <div className="header_nav_dropdown_v">
                              <h5 className="mb-0">Copy Trading</h5>
                              <p className="mb-0" style={{ lineHeight: "16px" }}>
                                Copy experienced traders portfolios.
                              </p>
                            </div>
                          </span>
                        </NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown title="Earn" id="collasible-nav-dropdown">
                        <NavDropdown.Item
                          // href={`${BASE_URL.BASE_URL}/savings`}
                          className="px-0 mb-1"
                          onClick={handleNavLeaveMore}
                        >
                          <Link
                            to={BASE_URL.BASE_URL + "savings"}
                            // href={`${BASE_URL.phpUrl}/saving_account`}savings
                            className="nav-link p-0"
                          >
                            <span className="vynksafenew_header_dropdown d-flex align-items-start">
                              <img
                                src={`${BASE_URL.BASE_URL}img/images/savings-icon.svg`}
                                alt="Spot"
                                className="mr-2"
                                width="34"
                                height="34"
                              />
                              <div className="header_nav_dropdown_v">
                                <h5 className="mb-0">Savings</h5>
                                <p className="mb-0" style={{ lineHeight: "16px" }}>
                                  Discover your savings with Vynksafe here.
                                </p>
                              </div>
                            </span>
                          </Link>
                        </NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown title="More" id="collasible-nav-dropdown">
                        <NavDropdown.Item className="px-0 mb-1">
                          <Link
                            to={BASE_URL.BASE_URL + `company`}
                            className="nav-link p-0"
                            onClick={handleNavLeaveEARN}
                          >
                            <span className="vynksafenew_header_dropdown d-flex align-items-start">
                              <img
                                src={`${BASE_URL.BASE_URL}img/images/company-icon.svg`}
                                alt="Spot"
                                className="mr-2"
                                width="34"
                                height="34"
                              />
                              <div className="header_nav_dropdown_v">
                                <h5 className="mb-0">Company</h5>
                                <p className="mb-0" style={{ lineHeight: "16px" }}>
                                  Know about the company insights here.
                                </p>
                              </div>
                            </span>
                          </Link>
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          className="px-0 mb-1"
                          onClick={handleNavLeaveEARN}
                        >
                          <Link
                            to={BASE_URL.BASE_URL + `product`}
                            className="nav-link p-0"
                          >
                            <span className="vynksafenew_header_dropdown d-flex align-items-start">
                              <img
                                src={`${BASE_URL.BASE_URL}img/images/products-icon.svg`}
                                alt="Spot"
                                className="mr-2"
                                width="34"
                                height="34"
                              />
                              <div className="header_nav_dropdown_v">
                                <h5 className="mb-0">Products</h5>
                                <p className="mb-0" style={{ lineHeight: "16px" }}>
                                  We have an array of products for our users.
                                </p>
                              </div>
                            </span>
                          </Link>
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          href={`${BASE_URL.phpUrl}/safe_pool`}
                          className="px-2 mb-1"
                          onClick={handleNavLeaveEARN}
                        >
                          <span className="vynksafenew_header_dropdown d-flex align-items-start">
                            <img
                              src={`${BASE_URL.BASE_URL}img/images/safe-pools-icon.svg`}
                              alt="Spot"
                              className="mr-2"
                            />
                            <div className="header_nav_dropdown_v">
                              <h5 className="mb-0">Safe Pools</h5>
                              <p className="mb-0" style={{ lineHeight: "16px" }}>
                                The cryptocurrency to facilitate the trade.
                              </p>
                            </div>
                          </span>
                        </NavDropdown.Item>
                      </NavDropdown>
                    </>
                  ) : (
                    <>
                      <NavDropdown
                        title="Trade"
                        id="collasible-nav-dropdown"
                        show={isNavOpen}
                        onMouseEnter={handleNavEnter}
                        onMouseLeave={handleNavLeave}
                      >
                        <NavDropdown.Item
                          className="px-0 mb-1"
                          onClick={handleNavLeave}
                        >
                          <Link to={BASE_URL.BASE_URL + `exchange/BNB_USDT`}>
                            <span className="vynksafenew_header_dropdown d-flex align-items-start">
                              <img
                                src={`${BASE_URL.BASE_URL}img/images/spot.svg`}
                                alt="Spot"
                                className="mr-2"
                                width="34"
                                height="34"
                              />
                              <div className="header_nav_dropdown_v">
                                <h5 className="mb-0">Spot</h5>
                                <p className="mb-0" style={{ lineHeight: "16px" }}>
                                  Buy ans sell on the spot market with advanced
                                  tools
                                </p>
                              </div>
                            </span>
                          </Link>
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          href="#"
                          className="px-0 mb-1"
                          onClick={handleNavLeave}
                        >
                          <Link to={BASE_URL.BASE_URL + `company`}>
                            <span className="vynksafenew_header_dropdown d-flex align-items-start">
                              <img
                                src={`${BASE_URL.BASE_URL}img/images/margin-icon.svg`}
                                alt="Spot"
                                className="mr-2"
                                width="34"
                                height="34"
                              />
                              <div className="header_nav_dropdown_v">
                                <h5 className="mb-0">Margin</h5>
                                <p className="mb-0" style={{ lineHeight: "16px" }}>
                                  Increase your profits with leverage
                                </p>
                              </div>
                            </span>
                          </Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          // href={BASE_URL.phpUrl}
                          className="px-0 mb-1"
                          onClick={handleNavLeave}
                        >
                          <Link
                            to={BASE_URL.BASE_URL + "p2p/All"}
                          // href={`${BASE_URL.phpUrl}/saving_account`}savings
                          // className="nav-link"
                          >
                            <span className="vynksafenew_header_dropdown d-flex align-items-start">
                              <img
                                src={`${BASE_URL.BASE_URL}img/images/P2P-icon.svg`}
                                alt="Spot"
                                className="mr-2"
                                width="34"
                                height="34"
                              />
                              {/*  */}
                              <div className="header_nav_dropdown_v">
                                <h5 className="mb-0">P2P</h5>
                                <p className="mb-0" style={{ lineHeight: "16px" }}>
                                  Buy & sell cryptocurrencies using bank transfer
                                  and 800+ options
                                </p>
                              </div>
                            </span>
                          </Link>
                        </NavDropdown.Item>

                        {/* <NavDropdown.Item
                          // href={`${BASE_URL.phpUrl}/p2p`}
                          className="px-0 mb-3"
                          onClick={handleNavLeave}
                        >
                          <Link
                            to={BASE_URL.BASE_URL + "p2p/All"}
                          // href={`${BASE_URL.phpUrl}/saving_account`}savings
                          // className="nav-link"
                          >
                            <span className="vynksafenew_header_dropdown d-flex align-items-start">
                              <img
                                src={`${BASE_URL.BASE_URL}img/images/P2P-icon.svg`}
                                alt="Spot"
                                className="mr-2"
                                 width="34"
                                height="34"
                              />
                              <div className="header_nav_dropdown_v">
                                <h5 className="mb-0">P2P</h5>
                                <p className="mb-0">
                                  Buy & sell cryptocurrencies using bank transfer
                                  and 800+ options
                                </p>
                              </div>
                            </span>
                          </Link>
                        </NavDropdown.Item> */}

                        <NavDropdown.Item
                          // href="ynkpay-api"
                          className="px-0 mb-1"
                          onClick={handleNavLeave}
                        >
                          <Link to={BASE_URL.BASE_URL + "vynksafe-api"}>
                            <span className="vynksafenew_header_dropdown d-flex align-items-start">
                              <img
                                src={`${BASE_URL.BASE_URL}img/images/apis-icon.svg`}
                                alt="Spot"
                                className="mr-2"
                              />
                              <div className="header_nav_dropdown_v">
                                <h5 className="mb-0">APIs</h5>
                                <p className="mb-0" style={{ lineHeight: "16px" }}>
                                  Unlimited oppotunities with one key
                                </p>
                              </div>
                            </span>
                          </Link>
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          href="#"
                          className="px-0 mb-1"
                          onClick={handleNavLeave}
                        >
                          <Link to={BASE_URL.BASE_URL + `company`}>
                            <span className="vynksafenew_header_dropdown d-flex align-items-start">
                              <img
                                src={`${BASE_URL.BASE_URL}img/images/copy_trading_icon.svg`}
                                alt="Spot"
                                className="mr-2"
                                width="34"
                                height="34"
                              />
                              <div className="header_nav_dropdown_v">
                                <h5 className="mb-0">Copy Trading</h5>
                                <p className="mb-0" style={{ lineHeight: "16px" }}>
                                  Copy experienced traders portfolios.
                                </p>
                              </div>
                            </span>
                          </Link>
                        </NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown
                        title="Earn"
                        id="collasible-nav-dropdown"
                        show={isNavMore}
                        onMouseEnter={handleNavEnterMore}
                        onMouseLeave={handleNavLeaveMore}
                      >
                        <NavDropdown.Item
                          to={BASE_URL.BASE_URL + "savings"}
                          className="px-0"
                          onClick={handleNavLeaveMore}
                        >
                          <Link
                            to={BASE_URL.BASE_URL + "savings"}
                            className="nav-link p-0"
                          >
                            <span className="vynksafenew_header_dropdown d-flex align-items-start">
                              <img
                                src={`${BASE_URL.BASE_URL}img/images/savings-icon.svg`}
                                alt="Spot"
                                className="mr-2"
                                width="34"
                                height="34"
                              />
                              <div className="header_nav_dropdown_v">
                                <h5 className="mb-0">Savings</h5>
                                <p className="mb-0" style={{ lineHeight: "16px" }}>
                                  Discover your savings with Vynksafe here.
                                </p>
                              </div>
                            </span>
                          </Link>
                        </NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown
                        show={isEarn}
                        onMouseEnter={handleNavEnterEarn}
                        onMouseLeave={handleNavLeaveEARN}
                        title="More"
                        id="collasible-nav-dropdown"
                      >
                        <NavDropdown.Item className="px-0">
                          <Link
                            to={BASE_URL.BASE_URL + `company`}
                            className="nav-link p-0"
                            onClick={handleNavLeaveEARN}
                          >
                            <span className="vynksafenew_header_dropdown d-flex align-items-start">
                              <img
                                src={`${BASE_URL.BASE_URL}img/images/company-icon.svg`}
                                alt="Spot"
                                className="mr-2"
                                width="34"
                                height="34"
                              />
                              <div className="header_nav_dropdown_v">
                                <h5 className="mb-0">Company</h5>
                                <p className="mb-0" style={{ lineHeight: "16px" }}>
                                  Know about the company insights here.
                                </p>
                              </div>
                            </span>
                          </Link>
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          className="px-0"
                          onClick={handleNavLeaveEARN}
                        >
                          <Link
                            to={BASE_URL.BASE_URL + `product`}
                            className="nav-link p-0"
                          >
                            <span className="vynksafenew_header_dropdown d-flex align-items-start">
                              <img
                                src={`${BASE_URL.BASE_URL}img/images/products-icon.svg`}
                                alt="Spot"
                                className="mr-2"
                                width="34"
                                height="34"
                              />
                              <div className="header_nav_dropdown_v">
                                <h5 className="mb-0">Products</h5>
                                <p className="mb-0" style={{ lineHeight: "16px" }}>
                                  We have an array of products for our users.
                                </p>
                              </div>
                            </span>
                          </Link>
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          href={`${BASE_URL.phpUrl}/safe_pool`}
                          className="px-0"
                          onClick={handleNavLeaveEARN}
                        >
                          <span className="vynksafenew_header_dropdown d-flex align-items-start">
                            <img
                              src={`${BASE_URL.BASE_URL}img/images/safe-pools-icon.svg`}
                              alt="Spot"
                              className="mr-2"
                            />
                            <div className="header_nav_dropdown_v">
                              <h5 className="mb-0">Safe Pools</h5>
                              <p className="mb-0" style={{ lineHeight: "16px" }}>
                                The cryptocurrency to facilitate the trade.
                              </p>
                            </div>
                          </span>
                        </NavDropdown.Item>
                      </NavDropdown>
                    </>
                  )}
                </Nav>

                <Nav
                  className="me-auto align-items-xl-center mt-5 mt-xl-0"
                  href="javascript:void(0)"
                >
                  <ThemeConsumer>
                    {({ data, update }) => (

                      <Button
                        className="p-0"
                        variant="default"
                        onClick={() => {
                          update(data.theme === "dark" ? "light" : "dark");
                         
                          setExpanded(false);
                        }}
                        id="darkTheme"
                      >
                        {}
                        {data.theme === "dark" ? (
                          <i
                            className="far fa-moon btn btn-gray-v dark_light_theme_btn_v"
                            id="switchTheme"
                            style={{ fontSize: '14px' }}
                          ></i>
                        ) : (
                          <i
                            className="far fa-lightbulb btn btn-gray-v dark_light_theme_btn_v"
                            id="switchTheme"
                            style={{ fontSize: '14px' }}
                          ></i>
                        )}
                      </Button>
                    )}
                  </ThemeConsumer>

                  <Nav.Link href="#" className="p-0">
                    <button
                      className="btn btn-gray-v"
                      style={{ height: "33px", display: "flex" }}
                      onClick={() => {
                        window.open(
                          "https://play.google.com/store/apps/details?id=com.vynksafe.app",
                          "_blank"
                        );
                        // closeNavbar;
                      }}
                    >
                      <img
                        src={`${BASE_URL.BASE_URL}img/images/download-icon.svg`}
                        alt="icon"
                        className="img-fluid"
                        width="16px"
                      />
                    </button>
                  </Nav.Link>
                  {userData?.access_token ? (
                    <>
                      <Dropdown
                        onClick={getDashBoardData}
                        // onClick={() => { getDashBoardData }}
                        className="header-img-icon">
                        <Dropdown.Toggle
                          variant="default"
                          classID="d-flex align-items-center"

                        >
                          {userData?.profile_pic_path ?
                            <img
                              src={BASE_URL_Tow.BASE_URL_Tow + userData?.profile_pic_path}
                              alt="Avtar"
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                background: "transparent",
                                border: "1px solid #13172200 ",
                              }}
                            /> :
                            <img
                              src={BASE_URL.BASE_URL + "img/avatar.svg"}
                              alt="Avtar"
                              style={{
                                background: "transparent",
                                border: "1px solid #13172200 ",
                              }}
                            />
                          }
                          {/* <p className="m-0 pl-2">{userData?.nick_name}</p> */}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          id=""
                          className={
                            isMobile
                              ? "profileLogin"
                              : "userLogout profileLogin pt-1 pb-0"
                          }
                        >
                          <div
                            className="footer_v"
                            style={{ borderRadius: "3px" }}
                          >

                          </div>
                          <div className="dropdown-body">
                            <ul className="profile-nav">
                              {userData?.access_token ? (
                                <>
                                  <Dropdown.Item

                                    className="nav-item p-0 newDesingDrop mt-0"
                                  >
                                    <Link
                                      to={`${BASE_URL.BASE_URL}p2p/All?to=p2p_profile`}
                                      className="nav-link d-flex align-items-center"
                                      onClick={() => setExpanded(false)}
                                    >
                                      {/* <div className="dropdown-header align-items-center"> */}
                                      {userData?.access_token ? (

                                        <div
                                          className="figure d-flex align-items-center ju"
                                          style={{
                                            background: "transparent",
                                            border: "none",
                                          }}
                                        >
                                          {userData?.profile_pic_path ?
                                            <img
                                              src={BASE_URL_Tow.BASE_URL_Tow + userData?.profile_pic_path}
                                              alt="Avtar"
                                              style={{
                                                width: '50px',
                                                height: '50px',
                                                borderRadius: '50%',
                                                background: "transparent",
                                                border: "1px solid #13172200 ",
                                              }}
                                            /> :
                                            <img
                                              src={BASE_URL.BASE_URL + "img/avatar.svg"}
                                              alt="Avtar"
                                              style={{
                                                background: "transparent",
                                                border: "1px solid #13172200 ",
                                              }}
                                            />
                                          }
                                          {userData?.access_token ? (
                                            <div className="info text-center">
                                              <p
                                                className="name font-weight-bold mb-0 ml-2"
                                                style={{ textAlign: "left" }}
                                              >
                                                {dashBoardUserData?.nick_name || userData?.nick_name}
                                              </p>
                                              <p
                                                className="email text-muted ml-2"
                                                style={{ fontSize: "12px" }}
                                              >
                                                {userData?.email}
                                              </p>
                                            </div>
                                          ) : (
                                            null
                                          )}

                                        </div>

                                      ) : (
                                        null
                                      )}

                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item className="nav-item p-0 newDesingDrop">
                                    <Link
                                      to={
                                        BASE_URL.BASE_URL + `profile/dashboard`
                                      }
                                      className="nav-link d-flex align-items-center"
                                      onClick={() => setExpanded(false)}
                                    >
                                      <i
                                        className="icon ion-md-person"
                                        style={{
                                          fontSize: " 20px",
                                          marginRight: "10px",
                                        }}
                                      ></i>
                                      <span>Dashboard</span>
                                    </Link>
                                  </Dropdown.Item>

                                  <Dropdown.Item className="nav-item p-0 newDesingDrop">
                                    <Link
                                      to={
                                        BASE_URL.BASE_URL + `Mywallet/Overview`
                                      }
                                      className="nav-link d-flex align-items-center"
                                      onClick={() => setExpanded(false)}
                                    >
                                      <i
                                        className="icon ion-md-wallet"
                                        style={{
                                          fontSize: " 20px",
                                          marginRight: "10px",
                                        }}
                                      ></i>
                                      <span>My Wallet</span>
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item className="nav-item p-0 newDesingDrop">
                                    <Link
                                      to={
                                        BASE_URL.BASE_URL + `profile/settings`
                                      }
                                      className="nav-link d-flex align-items-center"
                                      onClick={() => setExpanded(false)}
                                    >
                                      <i
                                        className="icon ion-md-settings"
                                        style={{
                                          fontSize: " 20px",
                                          marginRight: "10px",
                                        }}
                                      ></i>
                                      <span>Settings</span>
                                    </Link>
                                  </Dropdown.Item>
                                </>
                              ) : (
                                ""
                              )}
                              {/* {!expanded && ( */}
                                <>
                                  <Dropdown.Item
                                    className="nav-item p-0 newDesingDrop"
                                    // onClick={}
                                    onClick={() => {
                                      onLogoutBtnClick();
                                      setExpanded(false);
                                    }}
                                  >
                                    <p
                                      className="nav-link red d-flex align-items-center"
                                      role="button"
                                    >
                                      <i
                                        className="icon ion-md-power"
                                        style={{
                                          fontSize: " 20px",
                                          marginRight: "10px",
                                        }}
                                      ></i>
                                      <span
                                        style={{
                                          fontWeight: 300,
                                          marginTop: "0px",
                                        }}
                                      >
                                        Log Out
                                      </span>
                                    </p>
                                  </Dropdown.Item>
                                </>
                              {/* )} */}
                            </ul>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  ) : (
                    <>
                      <Link to={BASE_URL.BASE_URL + `login`} className="p-0">
                        <button
                          onClick={closeNavbar}
                          className="Logn_butotn"
                        >
                          LogIn
                        </button>
                      </Link>
                    </>
                  )}
                  {!userData?.access_token && (
                    <Link
                      to={BASE_URL.BASE_URL + "signup"}
                      className="nav-link"
                      onClick={closeNavbar}
                    >
                      Sign Up
                    </Link>
                    // <Link
                    //   to={BASE_URL.BASE_URL + `signup`}
                    //   className="p-0 linkStyle"

                    //   onClick={closeNavbar}
                    // >
                    //   <span className="p-0 linkStyle">
                    //     Sign Up
                    //   </span>
                    // </Link>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
      </header >
    </>
  );
};

export default NewHeader;
