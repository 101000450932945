import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
export const userSlice = createSlice({
  name: "users",
  initialState: {
    loggedUser: "",
    userData: [],
    // id: "0",
    // DB2_id: "0",
    // email: "",
    // password: "448ddd517d3abb70045aea6929f02367",
    // country: "",

    // full_name: "User007",
    // nick_name: "007user_",
    // // seed_phrase_verified: "0",
    // // google_auth_secret: "DxGCak4/DF938fxU9ennbyBR1ez9RoH/1nl1sa6Pwj4=",
    // // google_auth_verify: "0",
    // username: "",
    // phone: "",
    // mobile_token: "0",
    // email_status: "0",
    // isactive: "0",
    // isphoneupdated: "0",
    // // userpin: "111111",
    // // set_pin: "1",
    // gAuth_active: "0",
    // // set_finger_scan: "0",
    // set_notification: "",
    // // set_notification_sound: "",
    // transfer_limit: "",
    // country_id: "",
    // country_code: "",
    // std_code: "",
    // country_name: "",
    // country_image: "",
    //   access_token: "",
    //   login_token: "",
    // },
    userProfileData: [],
    loader: false,
  },
  reducers: {
    setLoggedUser: (state, { payload }) => {
      state.loggedUser = { ...state.loggedUser, ...payload };
    },

    userLogin(state, action) {
      state.userData = action.payload;
    },

    profileData(state, action) {
      state.userProfileData = action.payload;
    },

    loaderData(state, action) {
      state.loader = action.payload;
    },
  },
});

export const { setLoggedUser, userLogin, profileData, loaderData } =
  userSlice.actions;

export default userSlice.reducer;
