import React, { useState, useEffect } from 'react'
import { Row, Container, Modal, Button } from 'react-bootstrap';
import Order from './Order';
import { useHistory, generatePath, useParams, useLocation } from "react-router-dom";
import { currencyList, paymentMethods, getCountryList, get_MyOffer_List, get_Trade_List, getUserData, getFeedback, userFeedbackList } from '../../redux/apiActions/p2p.action'
import BASE_URL from "../../_constant/index";
import AddPayMethod from './AddPayMethod';
import { useSelector } from "react-redux";
import Myoffers from './Myoffers';
import Alloffer from './Alloffer';
import UserProfile from './UserProfile';
import CreateOffer from './CreateOffer';
import ShortFooter from '../../VynksafeNew/ShortFooter';


const P2P = () => {
    let user_Data = useSelector((state) => state.user.userData);


    useEffect(() => {
        if (user_Data) {
            setUserNameDetails(user_Data?.username);
        }
    }, [user_Data]);

    const history = useHistory();
    const { tab } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const toValue = queryParams.get('to')
    const userValue = queryParams.get('user');
    const userValueId = queryParams.get('id');
    const tradeid = queryParams.get('tradeid');
    const bankData = queryParams.get('bank');

    const [activeTab, setActiveTab] = useState('All');
    const [page, setPage] = useState(1);
    const [userProfileType, setUserProfileType] = useState();
    const [showSpinner, setShowSpinner] = useState(false);
    const [userData, setUserData] = useState()
    const [feedBack, setFeedBack] = useState(1)
    const [feedbackType, setFeedBackType] = useState(0)
    const [userFeedBack, setUserFeedBack] = useState()
    const [userName, setUserName] = useState("");
    const [userNameDetails, setUserNameDetails] = useState(userData?.username || "");
    const [feedbackTab, setFeedbackTab] = useState('all_feedback');
    const [activeMyFeedBackTab, SetActiveMyFeedBackTab] = useState('my_FeedBack');
    const [loading, setLoading] = useState(false)
    const [currency, setCurrency] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [offerList, setOfferList] = useState([]);
    const [tradeData, setTradeData] = useState([]);
    const [tradeActive, setTradeActive] = useState("");
    const [path, setPath] = useState("");
    const [user, setUser] = useState(null);
    const [offerPage, setOfferPage] = useState(1);
    const [show, setShow] = useState(false);
    const [fromStep, setFormStep] = useState(1);
    const formStepNextButton = () => {
        setFormStep(fromStep + 1);
    }

    const formStepPreButton = () => {
        setFormStep(fromStep - 1);
    }

    const tradePopUp = () => {
        localStorage.setItem("login_token", "tradePopUp");
        setShow(false)
    }
    useEffect(() => {
        if (user_Data?.login_token) {
            if (!localStorage.getItem("login_token")) {
                setShow(true)
            }
        }
    })
    useEffect(() => {

        if (tab == "undefined" || undefined || null || !tab) {
            setActiveTab("All")
        }
        if (activeTab == "undefined" || activeTab == undefined || activeTab == "") {
            setActiveTab("All")
        }
        if (!tab) {
            setActiveTab("All")
        }

    }, [tab])


    const handleMyTabChange = (tabId) => {
        setUser(null)
        SetActiveMyFeedBackTab(tabId);
        setFeedBack(1)
    };

    const handleFeedBackTabChange = (tabId) => {
        setFeedbackTab(tabId);
        setFeedBack(1)

        if (tabId == "positive_feedback") {
            setFeedBackType(1)
        } else if (tabId == "negative_feedback") {
            setFeedBackType(2)

        } else if (tabId == "all_feedback") {
            setFeedBackType(0)

        }

    };

    const get_User_Data = async () => {
        const user_name = user ? user : userNameDetails || user_Data?.username
        try {
            setUserData()
            setLoading(true)
            const data = new FormData()
            data.append("username", user_name)
            const response = await getUserData(data)
            if (response) {
                if (response.status == 200) {
                    setUserData(response?.data)
                    setTimeout(() => {
                        setLoading(false)
                    }, 200)

                } else {
                    setLoading(false)
                }
            }
        } catch (error) {
            setTimeout(() => {
                setLoading(false)
            }, 200)
            console.log(error)
        } finally {
            setLoading(false);
        }
    }

    const getUserFeedback = async () => {
        setLoading(true)

        const user_name = user ? user : userName
        const URL = user_name ? userFeedbackList : getFeedback
        try {
            const data = new FormData()
            data.append("type", feedbackType);
            data.append("page", feedBack);
            data.append("username", user_name);
            data.append("list_type", activeMyFeedBackTab == "my_FeedBack" ? 1 : 0);
            const response = await URL(data);

            if (response) {
                if (response?.status == 200) {
                    setUserFeedBack(response?.data)
                    setTimeout(() => {
                        setLoading(false)
                    }, 100)
                }
                // setTimeout(() => {
                setLoading(false)
                // }, 100)
            }

        } catch (error) {
            setLoading(false)
            console.log(error)
        }

    }

    useEffect(() => {
        get_User_Data();
    }, [userName, user])

    useEffect(() => {
        getUserFeedback();
    }, [feedBack, feedbackType, activeMyFeedBackTab, user])


    const handleTabClick = (tabName) => {
        history.replace(generatePath(BASE_URL.BASE_URL + "p2p/:tabName", { tabName }));
        setActiveTab(tabName);
        setUser(null)
    };

    useEffect(() => {
        if (toValue) {
            history.replace(`${BASE_URL.BASE_URL}p2p/${tab}?to=${toValue}`);
            setActiveTab(tab);
            setUserProfileType(toValue)
        }
    }, [toValue, tab])

    useEffect(() => {
        if (toValue && tab) {
            history.replace(`${BASE_URL.BASE_URL}p2p/${tab}?to=${toValue}`);
            setActiveTab(tab);
            setUserProfileType(toValue)
        }
        if (toValue && userValue) {
            const url = `${BASE_URL.BASE_URL}p2p/${tab}?to=profile&User=${userValue}`;
            history.replace(url);
            setActiveTab(tab);
            setUserProfileType(toValue);
            setUserName(userValue); // Set the userValue in state
        } else if (toValue && userValueId) {
            const url = `${BASE_URL.BASE_URL}p2p/${tab}?to=p2p_create_offer&id=${userValueId}`;
            history.replace(url);
            setActiveTab(tab);
        }
        else if (toValue && tradeid) {
            const url = `${BASE_URL.BASE_URL}p2p/${tab}?to=p2p_my_trade&tradeid=${tradeid}`;
            history.replace(url);
            setActiveTab(tab);
        }
        else if (tab && bankData) {
            const url = `${BASE_URL.BASE_URL}p2p/${tab}?to=${toValue}&bank=${bankData}`;
            history.replace(url);
            setActiveTab(tab);
        }
    }, [toValue, userValue, tab, tradeid, bankData]);

    const getTradeList = async () => {
        setShowSpinner(true)
        setLoading(true)
        let tradeType = tradeActive == "ActiveTrades" ? 0 : 1
        try {
            const formData = new FormData();
            formData.append('page', page)
            formData.append('past_trade', tradeType)
            const response = await get_Trade_List(formData);
            if (response.status == 200) {
                setTradeData(response?.data)
                setShowSpinner(false)
                setLoading(false)
                setTimeout(() => {
                    //     setLoading(false)
                }, 200)
            } else {

                setShowSpinner(false)
                setLoading(false)

            }
        } catch (error) {
            setShowSpinner(false)
            console.log(error)
        }
    }

    const handlePage = () => {
        if (!tradeData?.complete_trade?.length < 10) {
            setPage(page + 1)
        }
    }

    const handlePerPage = () => {
        if (page > 1) {
            setPage(page - 1)
        }
    }

    const getCurrencyList = async () => {
        try {
            const response = await currencyList();
            if (response?.status === 200) {
                // Format the API data to match react-select options structure

                const formattedCurrencyData = response?.data?.map((currency) => ({
                    value: currency?.id,
                    label: `${currency.currency_code} (${currency.currency_symbol})`,
                }));
                setCurrency(formattedCurrencyData);
            }
        } catch (error) {
            console.error('Error fetching currency data:', error);
        }
    };

    const getPaymentMethods = async () => {
        try {
            const formData = new FormData()
            formData.append("get_all", 1)
            const response = await paymentMethods(formData);

            if (response?.status === 200) {
                // Format the API data to match react-select options structure
                const formattedPaymentMethods = response?.data?.map((Methods) => ({
                    value: Methods.type,
                    image: Methods.image,
                    type: Methods.type,
                    label: `${Methods.name}`,
                }));
                setPath(response?.data?.path)
                setPaymentMethod(formattedPaymentMethods);
            }
        } catch (error) {
            console.error('Error fetching currency data:', error);
        }
    }
    const [checkPayment, setCheckPayment] = useState(null)

    const CheckPaymentMethods = async () => {
        try {
            const formData = new FormData()
            formData.append("get_all", 2)
            const response = await paymentMethods(formData);
            if (response?.status === 200) {
                const filterData = response?.data?.filter(Item => Item?.name !== "UPI")
                setCheckPayment(filterData);
            }
        } catch (error) {
            console.error('Error fetching currency data:', error);
        }
    }

    const fetchCountryList = async () => {
        try {
            const response = await getCountryList();
            if (response?.status === 200) {
                // Format the API data to match react-select options structure
                const formattedPaymentMethods = response?.data?.map((Methods) => ({
                    value: Methods.id,
                    label: `${Methods.country_name}`,
                }));
                setCountryList(formattedPaymentMethods);
            }
        } catch (error) {
            console.error('Error fetching currency data:', error);
        }
    }

    useEffect(() => {
        getCurrencyList();
        getPaymentMethods();
        fetchCountryList();
    }, []);

    const inCressOfferPage = () => {
        if (!offerList?.length < 10) {
            setOfferPage(offerPage + 1)
        }
    }
    const deCressOfferPage = () => {

        setOfferPage(offerPage - 1)

    }

    const handleMyOfferList = async () => {
        try {
            setLoading(true)
            const formData = new FormData();
            formData.append("page", offerPage)
            const response = await get_MyOffer_List(formData)
            if (response.status == 200) {
                setOfferList(response.data)
                setTimeout(() => {
                    setLoading(false)
                }, 100)
            } else {
                setLoading(false)
            }
        } catch (error) {
            console.error(error)
            setLoading(false)
        }

    }

    useEffect(() => {
        if (activeTab == "My_offer") {
            handleMyOfferList()
        }
    }, [activeTab, offerPage])

    useEffect(() => {
        if (activeTab == "Orders") {
            getTradeList()
        }
    }, [activeTab, tradeActive, page]);

    useEffect(() => {
        if (tab == 'My_offer') {
            setActiveTab(tab);
        } else if (activeTab == "Orders") {
            setActiveTab(tab);
        }
    }, [tab]);

    useEffect(() => {
        if (activeTab == 'All' || tab == "All" || tab == "all") {
            setActiveTab(tab);
        } if (tab == "all") {
            setActiveTab("All");
        }
    }, [tab]);

    return (
        <div className='p2p'>
            <div className='p2p_quick_tutorial_steps_modal'>
                <Modal show={show}
                    centered
                    contentClassName="modal-height"
                    onHide={() => { tradePopUp() }}
                    animation={false}
                    className="p2pOfferViewModal">
                    <Modal.Header closeButton>
                        <Modal.Title><h5>Quick Tutorial</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form id="p2pregForm">
                            <div className="p2p_modal_all-steps" id="p2p_modal_all-steps">
                                <span className="p2p_modal_step ml-2 active"></span>
                                <span className="p2p_modal_step ml-2"></span>
                                <span className="p2p_modal_step ml-2"></span></div>
                            {fromStep == 1 &&
                                <div className="p2p_modal_tab text-center">
                                    <h5>Welcome to Vynksafe P2P</h5>
                                    <img className="mt-4 mb-3 img-fluid"
                                        src={`${BASE_URL.BASE_URL}img/vynksafe-p2p-first-step.png`} />
                                    <p>Vynksafe P2P allows you buying and selling of cryptocurrency without the need of any third party or
                                        intermediary.</p>

                                </div>
                            }
                            {fromStep == 2 &&
                                <div className="p2p_modal_tab text-center">
                                    <h5>How to Buy Crypto?</h5>

                                    <ul className="mt-4">
                                        <li className="d-flex p2p_buy_crypto_ul">
                                            <span className="p2p_buy_crypto_ul_after"><img className="img-fluid"
                                                src={`${BASE_URL.BASE_URL}/img/p2p-buy.png`}
                                                width="50px"
                                            /></span>
                                            <div className="p2p_buy_crypto text-left ml-3">
                                                <p style={{ lineHeight: "25px" }}>Create a buy Advertisement (offer) <br /> or Choose from list of <br /> Advertisements of Buy
                                                </p>
                                            </div>
                                        </li>
                                        <li className="d-flex p2p_buy_crypto_ul">
                                            <span className="p2p_buy_crypto_ul_after">
                                                <img className="img-fluid"
                                                    src={`${BASE_URL.BASE_URL}/img/p2p-wallet.png`}
                                                    width="50px"
                                                /></span>
                                            <div className="p2p_buy_crypto text-left ml-3">
                                                <p style={{ lineHeight: "25px" }}>Transfer amount in seller account <br /> and notify seller before the <br /> payment window
                                                    over.</p>
                                            </div>
                                        </li>
                                        <li className="d-flex">
                                            <span className="">
                                                <img className="img-fluid"
                                                    src={`${BASE_URL.BASE_URL}/img/p2p-coin.png`} width="50px"
                                                /></span>
                                            <div className="p2p_buy_crypto text-left ml-3">
                                                <p style={{ lineHeight: "25px" }}>Communicate with seller and Ask for <br /> Releasing coins after verifying the <br />payment.
                                                </p>
                                            </div>
                                        </li>
                                    </ul>

                                </div>
                            }
                            {fromStep == 3 &&
                                <div className="p2p_modal_tab text-center">
                                    <h5>Do’s &amp; Don’ts</h5>

                                    <ul className="mt-4">
                                        <li className="d-flex align-items-center">
                                            <img className="img-fluid"
                                                src={`${BASE_URL.BASE_URL}/img/p2p-do.png`} width="50px" />
                                            <div className="p2p_buy_crypto text-left ml-4">
                                                <p>If payment is correct then release coins to buyer.</p>
                                            </div>
                                        </li>
                                        <li className="d-flex mt-3 align-items-center">
                                            <img className="img-fluid" src={`${BASE_URL.BASE_URL}img/p2p-dont.png`} width="50px" />
                                            <div className="p2p_buy_crypto text-left ml-4">
                                                <p>Please Do not release coins before verifying the payment.</p>
                                            </div>
                                        </li>
                                        <li className="d-flex mt-3 align-items-center">
                                            <img className="img-fluid" src={`${BASE_URL.BASE_URL}img/p2p-do.png`} width="50px" />
                                            <div className="p2p_buy_crypto text-left ml-4">
                                                <p>Please read Terms and Conditions given on the Advertisement(Buy/sell) carefully before placing order.</p>
                                            </div>
                                        </li>
                                    </ul>

                                </div>
                            }
                        </form>
                        <div className='d-flex justify-content-center'>
                            {fromStep > 1 &&
                                <Button variant="secondary"
                                    onClick={formStepPreButton}
                                    style={{ height: "45px" }}
                                    className="btn disable_btn_bg  mt-2 theme-btn-2 theme-btn mr-2">
                                    Previous
                                </Button>
                            }
                            {fromStep === 3 ?
                                <Button
                                    variant="primary"
                                    onClick={() => { tradePopUp() }}
                                    style={{ height: "45px" }}
                                    className="p2p_dlt_confirm_btn theme-btn-2 theme-btn mt-2">
                                    Trade
                                </Button>

                                :
                                <Button
                                    variant="primary"
                                    onClick={formStepNextButton}
                                    style={{ height: "45px" }}
                                    className="p2p_dlt_confirm_btn theme-btn-2 theme-btn mt-2">
                                    Next
                                </Button>

                            }
                        </div>
                    </Modal.Body>

                </Modal>
            </div>

            <section className="dark-gray-bg-v">
                <Container>
                    <Row>
                        <div className="col-12 p2p_filter_section  d-md-flex w-100 mt-1 align-items-center my-5">
                            <h2>
                                P2P
                            </h2>
                            <div className='horizontal_border'>

                            </div>
                            <div className="tab active_trades_left_btn active_trades_main_tabs">

                                <a
                                    className={`tablinks ${activeTab == 'All' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('All')}
                                >
                                    All P2P Offers
                                </a>
                                <a
                                    className={`tablinks ${activeTab === 'My_offer' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('My_offer')}
                                >
                                    My Offers
                                </a>
                                <a
                                    className={`tablinks ${activeTab === 'Orders' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Orders')}
                                >
                                    Orders
                                </a>
                            </div>
                            {/* Your other content here */}
                        </div>
                    </Row>
                </Container>
            </section>

            <section className="p2p_main_banner_section pt-0">
                <Row className={`row tabcontent ${activeTab == 'All' ? 'show' : 'hide'}`} id="All">
                    {toValue == 'p2p_profile' ?
                        (
                            <AddPayMethod
                                userProfileType={userProfileType}
                                feedbackTab={feedbackTab}
                                feedbackType={feedbackType}
                                handleFeedBackTabChange={handleFeedBackTabChange}
                                userFeedBack={userFeedBack}
                                feedBack={feedBack}
                                setFeedBack={setFeedBack}
                                userData={userData}
                                handleMyTabChange={handleMyTabChange}
                                activeMyFeedBackTab={activeMyFeedBackTab}
                                loading={loading}
                                paymentMethods={paymentMethod}
                            />) : user ?
                            (
                                <UserProfile

                                    feedBack={feedBack}
                                    setFeedBack={setFeedBack}
                                    loading={loading}
                                    userFeedBack={userFeedBack}
                                    feedbackTab={feedbackTab}
                                    handleFeedBackTabChange={handleFeedBackTabChange}
                                    setUser={setUser}
                                    userData={userData}
                                />
                            )
                            :
                            (
                                <Alloffer
                                    CheckPaymentMethods={CheckPaymentMethods}
                                    checkPayment={checkPayment}
                                    loadingData={loading}
                                    setUser={setUser}
                                    currency={currency}
                                    paymentMethod={paymentMethod}
                                    countryList={countryList}

                                />
                            )
                    }
                </Row>

                <div id="My_offer" className={`row tabcontent ${activeTab === 'My_offer' ? 'show' : 'hide'}`}>
                    <Myoffers
                        loadingData={loading}
                        inCressOfferPage={inCressOfferPage}
                        deCressOfferPage={deCressOfferPage}
                        offerPage={offerPage}
                        offerList={offerList}
                        handleMyOfferList={handleMyOfferList}
                    />
                </div>
                <div id="Orders" className={`row tabcontent ${activeTab === 'Orders' ? 'show' : 'hide'}`}>
                    {toValue == "p2p_my_trade" ?
                        (<>
                            <CreateOffer />
                        </>) : (
                            <Order
                                currency={currency}
                                toValue={toValue}
                                tradeData={tradeData}
                                showSpinner={showSpinner}
                                setTradeActive={setTradeActive}
                                handlePage={handlePage}
                                handlePerPage={handlePerPage}
                                page={page}
                                loading={loading}
                                countryList={countryList}
                                paymentMethods={paymentMethod}
                            />
                        )
                    }
                </div>

            </section>
            <hr />
            <ShortFooter />
        </div >
    )
}

export default P2P