import React, { useMemo, useCallback, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import HistoryOrder from "../components/exchange/HistoryOrder";
import MarketHistory from "../components/exchange/MarketHistory";
// import MarketNews from "../components/exchange/MarketNews";
import MarketPairs from "../components/exchange/MarketPairs";
import MarketTrade from "../components/exchange/MarketTrade";
import OrderBook from "../components/exchange/OrderBook";
import TradingChart from "../components/trading_chart/TradingChart";
// import TradingChartDark from "../components/exchange/TradingChartDark";
import { ThemeConsumer } from "../context/ThemeContext";
import utill from "../_constant/utill";
import Decimal from "decimal.js";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ShortFooter from "../VynksafeNew/ShortFooter";
// const Decimal = require('decimal.js');

const Exchange = ({ socket: sco }) => {
  const getTitle = () => {
    if (window.matchMedia("(max-width: 500px)").matches) {
      return "Trade";
    } else {
      return "Order Book & Trade";
    }
  };
  const [tabTitle, setTabTitle] = useState(getTitle());

  // Update the title whenever the screen size changes
  useEffect(() => {
    const handleResize = () => {
      setTabTitle(getTitle());
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const dividend = new Decimal(60.1);
  // const divisor = new Decimal(234.98);

  // const dividedResult = dividend.dividedBy(divisor);

  // console.log(dividedResult.toString(), "BNB_USDT");
  // const multipliedResult = dividedResult.times(divisor);

  // console.log(multipliedResult.toString(), "BNB_USDT"); // Output: 60

  let { pair_symbol } = useParams();
  pair_symbol = pair_symbol ? pair_symbol : "VYNC_M_USDT";

  let urlPair = pair_symbol.replaceAll("_", "/");

  if (pair_symbol.includes("VYNC_M")) {
    const coinName = pair_symbol?.split("_");
    if (coinName[0] == "VYNC") {
      urlPair = "VYNC_M/" + coinName[2];
    }else{
      urlPair = coinName[0]+"/VYNC_M";
    }
  }else if (!pair_symbol?.includes("_") || pair_symbol?.split("_").length !== 2) {
    pair_symbol = "";
    urlPair = "";
    //  history.push(generatePath(BASE_URL.BASE_URL + "notfound"));
    // return false;
  }

  const [currentPair, setCurrentPair] = React.useState(urlPair);
  // const [tradePrice, _tradePrice] = React.useState(0);
  // const [tradeAmount, _tradeAmount] = React.useState(0);
  // const [orderTradeType, _orderTradeType] = React.useState("");

  const [order_Price, setorder_price] = React.useState({});
  const [baseBalance, setBaseBalance] = React.useState(0);
  const [quoteBalance, setQuoteBalance] = React.useState(0);
  const [openOrders, _updateOpenOrders] = React.useState("");
  const [recentOrders, _updateRecentOrders] = React.useState("");

  const [spotBuy, _spotBuy] = React.useState({
    type: "SPOT",
    order_type: "",
    currency: "",
    trade_currency: "",
    quantity: 0,
    price: 0,
    amount_type: "QUANTITY",
    limit: 0,
    totalAmount: 0,
  });

  const [spotSell, _spotSell] = React.useState({
    type: "SPOT",
    order_type: "",
    currency: "",
    trade_currency: "",
    quantity: 0,
    price: 0,
    amount_type: "QUANTITY",
    limit: 0,
    totalAmount: 0,
  });
  const [orderType, setOrdertype] = React.useState("Limit");
  const [openOrderCount, setOpenOrderCount] = React.useState(0);
  //  useMemo(() => {
  //    if (orderType !== "Market") {
  //      _spotBuy((prevState) => {
  //        let jasper = Object.assign({}, prevState);
  //        jasper.price = tradePrice;
  //        if (orderTradeType === "BUY") {
  //          jasper.quantity = tradeAmount;
  //          jasper.totalAmount = utill.beautifyNumber(
  //            tradePrice * tradeAmount,
  //            decimalBase
  //          );
  //        }

  //        return jasper;
  //      });

  //      _spotSell((prevState) => {
  //        let jasper = Object.assign({}, prevState);
  //        jasper.price = tradePrice;
  //        if (orderTradeType === "SELL") {
  //          jasper.quantity = tradeAmount;
  //          jasper.totalAmount = utill.beautifyNumber(
  //            tradePrice * tradeAmount,
  //            decimalBase
  //          );
  //        }
  //        return jasper;
  //      });
  //    }
  //  }, [tradePrice, tradeAmount, orderTradeType, decimalBase, orderType]);
  // const [invalidPair, setInvalidPair] = React.useState(false);
  const changeCurrentPair = (pair) => {
    setCurrentPair(pair);
  };
  const updateQuoteBalance = useCallback((bal) => {
    setQuoteBalance(bal);
  }, []);
  const updateBaseBalance = useCallback((bal) => {
    setBaseBalance(bal);
  }, []);

  const updateOrderHistory = useCallback(
    (openOrder) => {
      _updateOpenOrders(openOrder);
    },
    [_updateOpenOrders]
  );

  const updateRecentOrders = useCallback((recentOrder) => {
    _updateRecentOrders(recentOrder);
  }, []);

  const setTradePrice = useCallback((price) => {
    _spotBuy((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.quantity = "";
      jasper.totalAmount = "";
      jasper.price = price;
      return jasper;
    });
    _spotSell((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.totalAmount = "";
      jasper.quantity = "";
      jasper.price = price;
      return jasper;
    });
  }, []);

  const setTradeAmount = useCallback(
    (amount, price, orderTradeType) => {
      // const GetAmount = price * amount
      // const number = new Decimal(price).times(amount)

      if (orderType !== "Market") {
        _spotBuy((prevState) => {
          let jasper = Object.assign({}, prevState);
          jasper.price = price;
          if (orderTradeType === "BUY") {
            jasper.quantity = amount;
            // jasper.quantity = utill.beautifyNumber(amount);
            jasper.totalAmount = utill.beautifyNumber(
              price * amount,
              order_Price?.base_dc > 8 ? 8 : order_Price?.base_dc
            );
            _spotSell({
              totalAmount: "",
            });
          }
          return jasper;
        });

        _spotSell((prevState) => {
          let jasper = Object.assign({}, prevState);
          jasper.price = price;
          if (orderTradeType === "SELL") {
            jasper.quantity = amount;
            // jasper.quantity = utill.beautifyNumber(amount)
            jasper.totalAmount = utill.beautifyNumber(
              price * amount,
              order_Price?.quote_dc > 8 ? 8 : order_Price?.quote_dc
            );
            _spotBuy({
              totalAmount: "",
            });
          }
          return jasper;
        });
      }
    },
    [orderType, _spotBuy, _spotSell, order_Price]
  );
  // const setTradeType = (type) => {
  //   _orderTradeType(type);
  // };

  const setOrderPrice = (orderPrice) => {
    setorder_price(orderPrice);
  };

  let orderookComp = useMemo(() => {
    return (
      <OrderBook
        pair={currentPair}
        setTradePrice={setTradePrice}
        setTradeAmount={setTradeAmount}
        // setTradeType={setTradeType}
        order_Price={order_Price}
        socket={sco}
      />
    );
  }, [currentPair, order_Price, sco, setTradePrice, setTradeAmount]);
  let marketHisComp = useMemo(() => {
    return (
      <MarketHistory
        pair={currentPair}
        setTradePrice={setTradePrice}
        order_Price={order_Price}
        updateRecentOrders={updateRecentOrders}
        recentOrders={recentOrders}
      />
    );
  }, [
    currentPair,
    setTradePrice,
    order_Price,
    updateRecentOrders,
    recentOrders,
  ]);

  let marketTradeComp = useMemo(() => {
    return (
      <MarketTrade
        pair={currentPair}
        // tradePrice={tradePrice}
        // tradeAmount={tradeAmount}
        // orderTradeType={orderTradeType}
        order_Price={order_Price}
        updateQuoteBalance={updateQuoteBalance}
        updateBaseBalance={updateBaseBalance}
        baseBalance={baseBalance}
        quoteBalance={quoteBalance}
        updateOrderHistory={updateOrderHistory}
        updateOpenOrderCount={setOpenOrderCount}
        // setTradePrice={setTradePrice}
        // setTradeAmount={setTradeAmount}
        // updateRecentOrders={updateRecentOrders}
        spotBuy={spotBuy}
        _spotBuy={_spotBuy}
        spotSell={spotSell}
        _spotSell={_spotSell}
        orderType={orderType}
        setOrdertype={setOrdertype}
      />
    );
  }, [
    currentPair,
    // tradePrice,
    // tradeAmount,
    // orderTradeType,
    order_Price,
    baseBalance,
    quoteBalance,
    updateQuoteBalance,
    updateBaseBalance,
    updateOrderHistory,
    setOpenOrderCount,
    // setTradePrice,
    // setTradeAmount,
    // updateRecentOrders,
    spotBuy,
    _spotBuy,
    spotSell,
    _spotSell,
    orderType,
    setOrdertype,
  ]);

  let historyOrderComp = useMemo(() => {
    return (
      <HistoryOrder
        pair={currentPair}
        updateQuoteBalance={updateQuoteBalance}
        updateBaseBalance={updateBaseBalance}
        updateOrderHistory={updateOrderHistory}
        openOrders={openOrders}
        order_Price={order_Price}
        openOrderCount={openOrderCount}
        updateOpenOrderCount={setOpenOrderCount}
      />
    );
  }, [
    currentPair,
    updateQuoteBalance,
    updateBaseBalance,
    updateOrderHistory,
    openOrders,
    order_Price,
    openOrderCount,
    setOpenOrderCount,
  ]);

  let TradingViewChartComp = useMemo(() => {
    // return null;
    return (
      <ThemeConsumer>
        {({ data }) => {
          return data.theme == "dark" ? (
            <>
              {/* <Trading_chart_Tow
                  pair={currentPair}
                  order_Price={order_Price}
                  theme={"dark"} /> */}
              <TradingChart
                pair={currentPair}
                order_Price={order_Price}
                theme={"dark"}
              />
            </>
          ) : (
            <TradingChart
              pair={currentPair}
              order_Price={order_Price}
              theme={"light"}
            />
          );
        }}
      </ThemeConsumer>
    );
  }, [currentPair, order_Price]);

  return (
    <>
      <div className="container-fluid no-fluid">
        <div className="mobile_screen">
          <Tabs
            defaultActiveKey="chart"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="chart" title="Chart">
              <div className="trading_chart_section">
                <table className="table">
                  <tbody className="trade_ctm_header">
                    <tr>
                      <td className="btc_currently_open">
                        {currentPair?.symbol}
                      </td>
                      <td className="ob_align_td" key="1">
                        <span>{utill.valueFix(order_Price?.usd_price)}</span>${" "}
                        {utill.valueFix(order_Price?.price)}
                      </td>
                      <td
                        className={
                          String(order_Price?.changes)?.charAt(0) === "-"
                            ? "red"
                            : "green"
                        }
                      >
                        <span>24h Change</span>{" "}
                        {String(order_Price?.changes)?.charAt(0) === "-"
                          ? ""
                          : "+"}
                        {utill.beautifyNumber(order_Price?.changes, 2)}%
                      </td>
                      <td className="ob_align_td" key="2">
                        <span>24h High</span>
                        {utill.beautifyNumber(currentPair?.high_24, 2)}
                      </td>
                      <td className="ob_align_td" key="3">
                        <span>24h Low</span>
                        {utill.beautifyNumber(currentPair?.low_24, 2)}
                      </td>
                      <td className="ob_align_td" key="4">
                        <span>24h Volume</span>
                        {utill.beautifyNumber(currentPair?.volume_24, 2)}
                      </td>
                    </tr>
                  </tbody>
                </table>

                {/* <ThemeConsumer>
              {({ data }) => {
                return data.theme === "light" ? (
                  <TradingChart pair={currentPair} />
                ) : (
                  <TradingChartDark pair={currentPair} />
                );
              }}
            </ThemeConsumer> */}
                {TradingViewChartComp}
                {/* <MarketTrade /> */}
                {currentPair?.id ? marketTradeComp : ""}
                {/* {} */}
              </div>
            </Tab>
            {tabTitle == "Trade" ? (
              <Tab eventKey="contact" title="Trade">
                <div className="market_pairs_section">
                  <MarketPairs
                    changeCurrentPair={changeCurrentPair}
                    socket={sco}
                    setOrderPrice={setOrderPrice}
                    urlPair={urlPair}
                  />
                  {marketHisComp}
                </div>
              </Tab>
            ) : null}
            <Tab
              eventKey="profile"
              title={tabTitle == "Trade" ? "Order Book" : tabTitle}
            >
              <div className="d-flex justify-content-between">
                <div className="order_book_section pr-md-0">
                  {/* <OrderBook /> */}
                  {currentPair?.id && order_Price !== undefined
                    ? orderookComp
                    : ""}
                  {/* <MarketHistory /> */}
                  {/* {marketHisComp} */}
                </div>
                <div
                  className="market_pairs_section"
                  style={{ display: tabTitle == "Trade" ? "none" : "block" }}
                >
                  <MarketPairs
                    changeCurrentPair={changeCurrentPair}
                    socket={sco}
                    setOrderPrice={setOrderPrice}
                    urlPair={urlPair}
                  />
                  {marketHisComp}
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
        <div className="order_book_main_section">
          <div className="trading_chart_section">
            <table className="table">
              <tbody className="trade_ctm_header">
                <tr>
                  <td className="btc_currently_open">{currentPair?.symbol}</td>
                  <td className="ob_align_td" key="1">
                    <span>{utill.valueFix(order_Price?.usd_price)}</span>${" "}
                    {utill.valueFix(order_Price?.price)}
                  </td>
                  <td
                    className={
                      String(order_Price?.changes)?.charAt(0) === "-"
                        ? "red"
                        : "green"
                    }
                  >
                    <span>24h Change</span>{" "}
                    {String(order_Price?.changes)?.charAt(0) === "-" ? "" : "+"}
                    {utill.beautifyNumber(order_Price?.changes, 2)}%
                  </td>
                  <td className="ob_align_td" key="2">
                    <span>24h High</span>
                    {utill.beautifyNumber(currentPair?.high_24, 2)}
                  </td>
                  <td className="ob_align_td" key="3">
                    <span>24h Low</span>
                    {utill.beautifyNumber(currentPair?.low_24, 2)}
                  </td>
                  <td className="ob_align_td" key="4">
                    <span>24h Volume</span>
                    {utill.beautifyNumber(currentPair?.volume_24, 2)}
                  </td>
                </tr>
              </tbody>
            </table>

            {/* <ThemeConsumer>
              {({ data }) => {
                return data.theme === "light" ? (
                  <TradingChart pair={currentPair} />
                ) : (
                  <TradingChartDark pair={currentPair} />
                );
              }}
            </ThemeConsumer> */}
            {TradingViewChartComp}
            {/* <MarketTrade /> */}
            {currentPair?.id ? marketTradeComp : ""}
            {/* {} */}
          </div>
          <div className="order_book_section pr-md-0">
            {/* <OrderBook /> */}
            {currentPair?.id && order_Price !== undefined ? orderookComp : ""}
            {/* <MarketHistory /> */}
            {/* {marketHisComp} */}
          </div>
          <div className="market_pairs_section">
            <MarketPairs
              changeCurrentPair={changeCurrentPair}
              socket={sco}
              setOrderPrice={setOrderPrice}
              urlPair={urlPair}
            />
            {marketHisComp}
          </div>
          {/* <div className="col-md-3"> */}
          {/* <MarketNews /> */}
          {/* </div> */}
        </div>
        <div className="history_order_comp">
          {currentPair?.id ? historyOrderComp : ""}
          {/* <HistoryOrder /> */}
        </div>
        <ShortFooter />
      </div>
    </>
  );
};

export default Exchange;
