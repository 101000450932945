import React, { useState, useCallback, useEffect } from 'react'
import { Tab, Tabs, Modal, Button, Form } from 'react-bootstrap';
import validator from "validator";
import { useDispatch } from "react-redux";
import utill from "../../_constant/utill";
import { otpSendPrivate, mainCoinList, userDailyWithdrawLimit, otpVerifyPrivate, CoinNetworksListAction, getOtherUserAddress, getWithdrawGasFeeAction, sendTransactionAction } from "../../redux/apiActions/user.action";
import WithdrawSection from "../../components/wallet/withdraw";
import { toast } from "../../components/Toast/Toast";
import Select from "react-select";
import qs from "qs";

export default function Withdraw({ socket, userCoinList, hideToptab, setCoinPrice, topActiveTab }) {
    const dispatch = useDispatch();
    const params = new URLSearchParams(window.location.search);
    const toParam = params?.get("to");
    const [successModal, setSuccessModel] = useState(false);
    const [successLoader, setSuccessLoader] = useState(false);
    const [coinData, setCoinData] = useState({});
    const [gteCoinBalance, setGetCoinBalance] = useState()

    useEffect(() => {
        if (coinData) {
            socket?.emit("get_coin", {
                coin: "ALL",
            });
            socket?.on("received_coins_price", (socketData) => {
                setCoinData((prevCoinData) => {
                    const updatedCoin = socketData?.find((data) => data.symbol === prevCoinData?.symbol);
                    if (updatedCoin) {
                        return {
                            ...prevCoinData,
                            rate_currency: updatedCoin.latest_price,
                        };
                    }
                    return prevCoinData;
                });
            });
        }

    }, [socket, coinData?.symbol]);

    const gteMainCoinList = async (coin) => {

        if (coin) {
            let data = new FormData();
            data.append("coin", coin?.coin);
            const response = await mainCoinList(data);
            setGetCoinBalance(response?.data);
        }
    }


    const [urlCoin, setUrlCoin] = useState("")
    const [networksList, setNetworkList] = useState([]);
    const [selectedNetwork, setNetwork] = useState(null);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [withdrawGasFeeData, setWithdrawGasFeeData] = useState({});
    const [withdrawMsg, setWithdrawMsg] = useState('')
    const [second, setSecond] = useState(59);
    const [minutes, setMinutes] = useState(0);
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");
    const [withdrawLimit, setWithdrawLimit] = useState("")
    const [error, setErrors] = useState(null);
    const [userName, setUserName] = useState("");
    const [coinBalance, setCoinBalance] = useState();
    // SEND OTP FOR WITHDRAW CONFORMATION

    const dailyWithdrawLimit = async () => {
        const response = await userDailyWithdrawLimit()
        if (response.status === 200) {
            setWithdrawLimit(response.data)
        }
    }
    useEffect(() => {
        dailyWithdrawLimit();
    }, [coinBalance])

    useEffect(() => {
        const interval = setInterval(() => {
            if (second > 0) {
                setSecond(second - 1)
            }
            if (second === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSecond(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000)
        return () => {
            clearInterval(interval);
        };
    }, [minutes, second])

    const [errorMsg, setErrorMsg] = useState({
        addressError: "",
        amountError: "",
        currencyError: "",
        NetworkError: "",
    });

    const [withdrawCoinData, setWithdrawCoinData] = useState({
        type: "address",
        withdrawTo: "",
        withdrawAddress: "",
        amount: "",
        coin: "",
        price: "",
        amount_currency: "",
        withdrawUser: "",
    });
    // useEffect(() => {
    //     setCoinPrice(coinBalance || coinData?.balance)
    //     setCoinBalance(coinData?.balance)
    // }, [coinData?.balance])

    const userEmailConformation = async () => {
        const mode = withdrawCoinData?.type === "email" ? 3 : withdrawCoinData?.type === "phone" ? 2 : 0
        if (mode != 0) {
            setLoading(true)
            let data = new FormData();
            data.append("coin", withdrawCoinData?.coin)
            data.append("send_mode", mode);
            { mode == 3 ? data.append("email", withdrawCoinData?.withdrawTo) : mode == 2 ? data.append("mobile", withdrawCoinData?.withdrawTo) : data.append("address", withdrawCoinData?.withdrawTo) }
            if (mode != 0) {
                const response = await getOtherUserAddress(data);
                if (response) {
                    setLoading(false)
                    if (response?.status === 400) {
                        setErrors(response?.message)
                    } else {
                        setUserName(response?.data?.full_name)
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (coinData) {
            setWithdrawCoinData({
                type: withdrawCoinData.type,
                coin: coinData?.coin,
                price: coinData?.rate_currency,
            })
        }
    }, [coinData, toParam])

    useEffect(() => {
        setCoinData(null)
        setNetwork(null)
        setWithdrawCoinData({
            type: withdrawCoinData.type,
            amount: "",
            coin: "",
            price: "",
            amount_currency: "",
        })
    }, [topActiveTab]);

    useEffect(() => {
        const coinValue = toParam?.split("=")[1];
        setUrlCoin(coinValue)
        if (userCoinList) {
            if (coinValue) {
                const selectUrlCoin = async () => {
                    const seletedCoin = userCoinList?.find((coin) => coin?.symbol === coinValue)
                    setCoinData(seletedCoin)
                    if (seletedCoin) {
                        setWithdrawCoinData({
                            coin: seletedCoin?.coin,
                            price: seletedCoin?.rate_currency,
                        })
                    }
                    let data = new FormData();
                    data.append("coin", seletedCoin?.coin);
                    data.append("type", 1);
                    await getCoinNetworks(data);
                }
                selectUrlCoin();
            }
        }
    }, [toParam, urlCoin, userCoinList]);


    const getCoinNetworks = useCallback(
        async (data) => {
            const networksResponse = await CoinNetworksListAction(data, dispatch);

            if (networksResponse?.status === 200) {
                setNetworkList(
                    networksResponse?.data.map((val) => {
                        val.value = val.id;
                        val.label = val.network_text;
                        return val;
                    })
                );
            }
        },
        [dispatch]
    );

    const setSelectValue = (val) => {
        setNetwork(val);
    };

    function isNumberKey(value) {
        var validNumber = new RegExp(/^\d*\.?\d*$/);
        if (validNumber.test(value)) {
            return value;
        } else {
            return false;
        }
    }

    const setWithdrawTo = (e) => {
        let value = e.target.value;
        let message = "";
        if (
            value !== "" &&
            withdrawCoinData?.type === "email" &&
            !validator.isEmail(value)
        ) {
            message = "Invalid Email address";
        } else if (value === "") {
            message =
                withdrawCoinData?.type === "phone"
                    ? "Invalid Phone Number"
                    : withdrawCoinData?.type === "email"
                        ? "Invalid Email address"
                        : "Invalid address";
        }
        setErrorMsg((preState) => {
            let obj = Object.assign({}, preState);
            obj.addressError = message;
            return obj;
        });
        setWithdrawCoinData((preState) => {
            let obj = Object.assign({}, preState);
            obj.withdrawTo = value;
            return obj;
        });
    };


    const setWithdrawAmount = (e) => {
        let value = isNumberKey(e.target.value);
        if (value !== false) {
            setErrorMsg((preState) => {
                let obj = Object.assign({}, preState);
                obj.amountError = value || value > 0 ? "" : "Invalid value";
                obj.currencyError = value || value > 0 ? "" : "Invalid Currency Value";
                return obj;
            });
            setWithdrawCoinData((preState) => {
                let obj = Object.assign({}, preState);
                obj.amount = value;
                obj.amount_currency = (value * withdrawCoinData?.price).toFixed(2);
                return obj;
            });
        }
    };

    const setWithdrawAmountCurrency = (e) => {
        const decimal = coinData?.decimal < 8 ? coinData?.decimal : 8;
        let value = isNumberKey(e.target.value);
        if (value !== false) {
            setErrorMsg((preState) => {
                let obj = Object.assign({}, preState);
                obj.currencyError = value || value > 0 ? "" : "Invalid Currency Value ";
                obj.amountError = value || value > 0 ? "" : "Invalid  Value ";
                return obj;
            });
            setWithdrawCoinData((preState) => {
                let obj = Object.assign({}, preState);
                obj.amount_currency = value;
                obj.amount =
                    withdrawCoinData?.price <= 0 ? ""
                        : utill.beautifyNumber(value / withdrawCoinData.price, decimal);
                return obj;
            });
        }
    };

    const changeWithdrawType = (type) => {
        setErrors(null)
        setWithdrawCoinData((preState) => {
            let obj = Object.assign({}, preState);
            obj.type = type;
            obj.withdrawTo = "";
            obj.amount_currency = "";
            obj.amount = "";
            return obj;
        });
        setErrorMsg((preState) => {
            let obj = Object.assign({}, preState);
            obj.NetworkError = "";
            obj.addressError = "";
            obj.currencyError = "";
            obj.amountError = "";
            return obj;
        });
    };


    const handelWithdraw = async () => {
        try {
            let isError = false;
            let errorJson = {
                NetworkError: "",
                addressError: "",
                currencyError: "",
                amountError: "",
            };
            //coin validation
            if (!withdrawCoinData?.coin || withdrawCoinData?.coin.trim() === "") {
                toast.errors("Invalid coin", "Invalid coin");
                isError = true;
            } else {
                isError = false;
            }

            // network validation

            if (selectedNetwork === null && withdrawCoinData?.type === "address") {
                errorJson.NetworkError = "Please select Network First";
                isError = true;
            } else {
                errorJson.NetworkError = "";
                isError = false;
            }
            // address validation
            if (
                !withdrawCoinData.withdrawTo ||
                withdrawCoinData.withdrawTo.trim() === ""
            ) {
                errorJson.addressError =
                    withdrawCoinData?.type === "phone"
                        ? "Invalid Phone Number"
                        : withdrawCoinData?.type === "email"
                            ? "Invalid Email address"
                            : "Invalid address";
                isError = true;
            }

            else {

                if (
                    withdrawCoinData?.withdrawTo &&
                    withdrawCoinData?.type !== "address"
                ) {

                    if (
                        withdrawCoinData?.type === "email" &&
                        !validator.isEmail(withdrawCoinData?.withdrawTo)
                    ) {

                        errorJson.addressError = "Invalid Email address";
                        isError = true;
                    } else {
                        setLoading(true)
                        let formData = new FormData();
                        formData.append("coin", withdrawCoinData?.coin);
                        formData.append("email", withdrawCoinData?.withdrawTo);
                        formData.append("mobile", withdrawCoinData?.withdrawTo);
                        formData.append(
                            "send_mode",
                            withdrawCoinData?.type === "email" ? "3" : "2"
                        );
                        let address_data_res = await getOtherUserAddress(
                            formData,
                            dispatch
                        );
                        if (address_data_res) {
                            setLoading(false)
                        }
                        if (
                            address_data_res.status === "400" ||
                            address_data_res.status === 400
                        ) {
                            errorJson.addressError = address_data_res.message;
                            isError = true;
                        } else {
                            setWithdrawCoinData((preState) => {
                                let obj = Object.assign({}, preState);
                                obj.withdrawUser = address_data_res?.data?.full_name_show;
                                obj.withdrawAddress = address_data_res?.data?.address;
                                return obj;
                            });
                            errorJson.addressError = "";
                            isError = false;
                        }
                    }
                }
            }

            // validate amount
            if (
                !withdrawCoinData.amount ||
                isNaN(withdrawCoinData.amount) ||
                withdrawCoinData.amount <= 0
            ) {
                errorJson.amountError = "Invalid amount";
                isError = true;
            } else {
                errorJson.amountError = "";
                isError = false;
            }
            // validate currency amount
            if (
                !withdrawCoinData.amount_currency ||
                isNaN(withdrawCoinData.amount_currency) ||
                withdrawCoinData.amount_currency <= 0
            ) {
                errorJson.currencyError = "Invalid amount";
                isError = true;
            } else {
                errorJson.amountError = "";
                isError = false;
            }
            setErrorMsg(errorJson);

            if (
                errorJson.NetworkError == "" &&
                errorJson.addressError == "" &&
                errorJson.currencyError == "" &&
                errorJson.amountError == ""
            ) {
                setLoading(true)
                let requestData, gasUrl, send_mode;
                setLoading(true)
                if (withdrawCoinData?.type === "address") {
                    setLoading(true)
                    gasUrl = selectedNetwork?.url_gasFee;
                    send_mode = 1;
                    requestData = qs.stringify({
                        coin: withdrawCoinData?.coin,
                        address_input: selectedNetwork?.address,
                        address_output: withdrawCoinData.withdrawTo,
                        value_coin: withdrawCoinData.amount,
                        send_mode: send_mode,
                    });
                } else if (
                    withdrawCoinData?.type === "email" ||
                    withdrawCoinData?.type === "phone"
                ) {
                    setLoading(true)
                    requestData = new FormData();
                    gasUrl = coinData.url_internal_gasFee;
                    send_mode = withdrawCoinData?.type == "email" ? "3" : "2";
                    requestData.append("coin", withdrawCoinData?.coin);
                    requestData.append("value", withdrawCoinData?.amount);
                    requestData.append("send_mode", send_mode);
                }
                let gasFeeResponse = await getWithdrawGasFeeAction(
                    requestData,
                    gasUrl,
                    send_mode,
                    dispatch
                );

                if (gasFeeResponse) {
                    setLoading(false)
                }

                if (gasFeeResponse?.status === 200) {
                    sendOtp()
                    setWithdrawGasFeeData(gasFeeResponse?.data);
                    setSecond(59)
                } else {
                    setLoading(false)
                    toast.errors("Invalid Details", gasFeeResponse?.message || "Something went wrong, please try again later");
                }
            } else {
                setLoading(false)
                toast.errors("Invalid Details", "Please provide all  Valid Details");
            }
        } catch (error) {
            setLoading(false)
            toast.errors("Invalid Details", "Something went wrong, please try again later");
        }
    };

    const sendOtp = async () => {
        setOtpError("")
        setOtp("")
        const response = await otpSendPrivate();
        if (response) {
            setLoading(false)
            if (response?.status === 200) {
                setConfirmationModal(true);
                setSecond(59);
            } else {
                setLoading(false)
                toast.errors(response?.message, response?.message)
            }
        }
    }

    const reSendOtp = async () => {
        setLoading(true)
        const response = await otpSendPrivate();
        if (response) {
            setLoading(false)
            if (response?.status === 200) {
                setSecond(59);
                toast.successes("OTP resend", response?.message)
            }
        }
    }


    const setCoinValue = async (val) => {
        setCoinBalance(val.balance)
        setCoinData(val)
        setNetwork(null);
        // setWithdrawCoinData({
        //     type: "address",
        // })
        let data = new FormData();
        data.append("coin", val?.coin);
        data.append("type", 1);
        setWithdrawCoinData((preState) => {
            let obj = Object.assign({}, preState);
            obj.price = val?.rate_currency;
            obj.coin = val?.coin;
            return obj;
        });
        await getCoinNetworks(data);

    };

    const confirmTxn = async () => {

        try {
            let requestData, gasUrl, send_mode;
            if (successLoader === false && withdrawCoinData.withdrawTo !== "") {
                setSuccessLoader(true);
                if (withdrawCoinData?.type === "address") {
                    send_mode = 1;
                    gasUrl = selectedNetwork?.url_transfer;
                    requestData = qs.stringify({
                        coin: withdrawCoinData?.coin,
                        address_input: selectedNetwork?.address,
                        address_output: withdrawCoinData?.withdrawTo,
                        value_coin: withdrawCoinData?.amount,
                        send_mode: send_mode,
                    });

                } else if (
                    withdrawCoinData?.type === "email" ||
                    withdrawCoinData?.type === "phone"
                ) {
                    requestData = new FormData();
                    gasUrl = coinData.url_internal_transfer;
                    send_mode = withdrawCoinData?.type == "email" ? "3" : "2";
                    requestData.append("coin", withdrawCoinData?.coin);
                    requestData.append("address_to", withdrawCoinData?.withdrawAddress);
                    requestData.append("value", withdrawCoinData?.amount);
                    requestData.append("send_mode", send_mode);

                }
                let txn_response = await sendTransactionAction(
                    requestData,
                    gasUrl,
                    send_mode,
                    dispatch
                );
                if (txn_response?.status === 200) {

                    setSuccessModel(true);
                    setLoading(false)
                    setConfirmationModal(false);
                    setCoinBalance(coinBalance - withdrawCoinData?.amount);
                    setWithdrawMsg(txn_response?.message)
                    // console.log(txn_response?.message)
                    // setconfirm_txn_data({
                    //     date: txn_response.created_date,
                    //     status: txn_response.txn_status,
                    //     Hash: txn_response.hash_show,

                    // })
                    setOtp("")
                    setOtpError("")
                    changeWithdrawType(withdrawCoinData.type);
                    setSuccessModel(true);
                } else {
                    setLoading(false)
                    toast.errors("Invalid Details", txn_response?.message || "Something went wrong, please try again later");
                }
                let formData = new FormData();
                formData.append("coin", withdrawCoinData?.coin);
                formData.append("page", 1);
              
                setSuccessLoader(false);
            }
        } catch (error) { }
    };
    const verifyPrivateOtp = async () => {
        try {
            if (!otp || otp == "") {
                setOtpError("Please Enter OTP")
            } else if (otp?.length !== 6) {
                setOtpError("Please Provide Valid OTP")
            } else {
                setLoading(true)
                let formData = new FormData();
                formData.append("otp", otp)
                const response = await otpVerifyPrivate(formData);
                if (response?.status === 200) {
                    confirmTxn();
                } else {
                    setOtpError(response.message || "Invalid OTP");
                    toast.errors("Invalid OTP", response.message || "Invalid OTP")
                    setLoading(false)
                }
            }
        } catch (error) {
            setLoading(false)
        }
    }

    const setSearchCoinValue = async (val) => {
        const seletedCoin = userCoinList?.find((coin) => coin?.symbol === val)
        setCoinData(seletedCoin)
        let data = new FormData();
        data.append("coin", seletedCoin?.coin);
        data.append("type", 1);
        await getCoinNetworks(data);
    }

    return (
        <div>
            <div className="card mtb15">
                <div className="card-body">
                    <h6 className="card-title">
                        <i className="ion ion-ios-arrow-back mr-2 back_icon" style={{ cursor: "pointer" }} onClick={hideToptab}></i> Withdraw Crypto
                    </h6>
                    <div className="row">
                        <div className="col-xl-8 col-lg-10 col-md-10 wallet-address">
                            <div className="row">
                                <div className="col-md-3 mb-2 mt-2 pl-4">
                                    <h5 className="card-small-title pl-4">Select Coin</h5>
                                </div>
                                <div className="col-md-9 mb-2">
                                    <Select
                                        className='wallet_network_select'
                                        classNamePrefix='custom_select'
                                        isSearchable={true}
                                        name='network'
                                        value={coinData}
                                        placeholder='Select a coin'
                                        onChange={(e) => {
                                            setCoinValue(e);
                                            gteMainCoinList(e)
                                        }}
                                        options={userCoinList}
                                        getOptionValue={(option) => option?.coin}
                                        getOptionLabel={(option) => (
                                            <div className='d-flex align-items-center'>
                                                {/* {localStorage.setItem("coinBalance", option?.balance)} */}
                                                <img src={option.image} alt={option.symbol} width='25' className='mr-2' />
                                                {option.symbol}

                                            </div>
                                        )}
                                    />
                                    {coinData?.balance ? (<>
                                        <div className="elect-coin-avaialble-balance mt-2">
                                            <div className="d-flex align-items-start flex-wrap justify-content-between">
                                                <p className="text-secondary">Available Balance<br /><span className="card-avaialble-balance mt-1">{utill.beautifyNumber(gteCoinBalance?.available_balance, 4)} {gteCoinBalance?.coin} <span className="text-secondary">≈ ${utill.beautifyNumber(gteCoinBalance?.available_currency_balance, 2)}</span></span></p>
                                                <p className="text-sm-right text-secondary">Hold Balance<br /><span className="card-avaialble-balance mt-1">{utill.valueFix(parseFloat(gteCoinBalance?.hold_balance), 4)} {gteCoinBalance?.coin}</span></p>

                                            </div>
                                        </div>
                                    </>) :
                                        < ul className="select-main-coins-wallet d-flex align-items-center flex-wrap justify-content-md-between mt-3">
                                            {
                                                userCoinList?.slice(0, 4)?.map((coin, index) => {
                                                    return <li key={index} onClick={() => {
                                                        setSearchCoinValue(coin?.symbol);
                                                        gteMainCoinList(coin)
                                                    }} className="coin_select"><img src={coin.image} alt={coin?.symbol} width="25" className="mr-2" />{coin?.symbol}</li>
                                                })
                                            }
                                        </ul>
                                    }
                                </div>

                            </div>

                            {coinData?.coin &&
                                <div className="row wallet-address pt-4" style={{ borderTop: "1px solid rgba(94, 114, 143, 0.3)" }}>


                                    <div className="col-md-12 mb-2" style={{ position: "relative" }}>
                                        <Tabs
                                            defaultActiveKey="address"
                                            onSelect={(key) => {
                                                changeWithdrawType(key);
                                            }}
                                            className="Wallet_tab justify-content-center pl-4 ml-1"
                                        >
                                            {[
                                                {
                                                    name: "Address",
                                                    value: "address",
                                                },
                                                {
                                                    name: "Email Address",
                                                    value: "email",
                                                },
                                                {
                                                    name: "Phone Number",
                                                    value: "phone",
                                                },
                                            ].map((val, key) => {
                                                return (
                                                    <Tab
                                                        eventKey={val?.value}
                                                        title={val?.name}
                                                        key={val?.value}
                                                    >
                                                        <div className="justify-content-between">

                                                            <WithdrawSection
                                                                setWithdrawCoinData={setWithdrawCoinData}
                                                                loading={loading}
                                                                sendOtp={sendOtp}
                                                                coinBalance={coinBalance}
                                                                userEmailConformation={userEmailConformation}
                                                                Emailerror={error}
                                                                setErrors={setErrors}
                                                                sendType={val}

                                                                selectedNetwork={
                                                                    selectedNetwork
                                                                }
                                                                networksList={networksList}
                                                                coinData={coinData}
                                                                withdrawCoinData={
                                                                    withdrawCoinData
                                                                }

                                                                changeWithdrawTo={setWithdrawTo}
                                                                changeWithdrawAmount={
                                                                    setWithdrawAmount
                                                                }
                                                                changeWithdrawCurrency={
                                                                    setWithdrawAmountCurrency
                                                                }
                                                                changeSelectValue={
                                                                    setSelectValue
                                                                }
                                                                error={errorMsg}
                                                                setErrorMsg={setErrorMsg}
                                                                handelWithdrawTxn={
                                                                    handelWithdraw
                                                                }
                                                                withdrawLimit={withdrawLimit}
                                                            />
                                                        </div>
                                                    </Tab>
                                                );
                                            })}
                                        </Tabs>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <ConfirmationModalView
                show={confirmationModal}
                onHide={() => setConfirmationModal(false)}
                gas_fee_data={withdrawGasFeeData}
                withdraw_data={withdrawCoinData}
                coin_data={coinData}
                confirm_txn={confirmTxn}
                success_loader={successLoader}
                username={userName}
                second={second}
                minutes={minutes}
                setSecond={setSecond}
                otp={otp}
                setOtp={setOtp}
                verifyPrivateOtp={verifyPrivateOtp}
                setOtpError={setOtpError}
                otpError={otpError}
                reSendOtp={reSendOtp}
                loading={loading}
            />

            <TxnSuccessModalView
                // confirm_txn_data={confirm_txn_data}
                show={successModal}
                onHide={() => {
                    setSuccessModel(false);
                    localStorage.removeItem("withdrawTo")
                }}

                // coin_data={coinData}
                gas_fee_data={withdrawGasFeeData}
                success_loader={successLoader}
                withdrawMsg={withdrawMsg}
                selectedNetwork={selectedNetwork}
            // withdrawTo={withdrawCoinData.withdrawTo}
            />

        </div>

    )
}

function ConfirmationModalView(props) {
    const {
        gas_fee_data,
        withdraw_data,
        coin_data,
        // confirm_txn,
        // success_loader,
        username,
        second,
        minutes,
        // setSecond,
        otp,
        setOtp,
        verifyPrivateOtp,
        otpError,
        setOtpError,
        reSendOtp,
        loading
    } = props;

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            scrollable={true}
            backdrop="static"
            className="transaction_details_Successful_modal"
            contentClassName="modal_content_Size"
            animation
            centered
        >
            <Modal.Body className="pt-2 pb-0">
                <h5 className='mt-2'>
                    Confirm Withdrawal
                </h5>

                <div className="transaction_details_Successful mt-3 mb-3">
                    <ul>
                        <li className="d-flex"><p className="text-secondary">Amount</p>  {utill.beautifyNumber(withdraw_data?.amount, 4)}{" "}{coin_data?.symbol}</li>
                        <li className="d-flex"><p className="text-secondary"> Send Mode</p> {gas_fee_data?.send_mode_text}</li>
                        {withdraw_data?.withdrawTo &&
                            <li className="d-flex"><p className="text-secondary">To</p>
                                <div className="justify-content-center text-right">
                                    {gas_fee_data?.send_mode == 1
                                        ? withdraw_data?.withdrawTo?.substring(0, 15) +
                                        "..." +
                                        withdraw_data?.withdrawTo?.substring(
                                            withdraw_data?.withdrawTo.length - 5
                                        )
                                        : withdraw_data?.withdrawTo}
                                </div>
                            </li>
                        }
                        {username && <li className="d-flex"><p className="text-secondary">User Name</p>{username}</li>}
                    </ul>
                </div>
                <p className='withdraw_conf_otp pb-3'>An OTP code has been sent to your registered email address. Please enter the code in the box below to proceed.</p>
                <div className='d-flex justify-content-between'>
                    <label className='mt-2'>Enter Your OTP :</label>
                    <Form.Control
                        placeholder="Enter your OTP"
                        aria-label="otp"
                        aria-describedby="basic-addon"
                        className="Withdraw_otp_conf"
                        value={otp || ""}
                        maxLength="6"
                        minLength="6"
                        onChange={(e) => {
                            setOtp(e.target.value.replace(/\D/g, ""));
                            setOtpError("")
                        }}  
                    />

                </div>
                <small className="red mt-1" style={{ textAlign: "right", display: "flex", justifyContent: "flex-end" }}>
                    {otpError}
                </small>
                <div className="countdown-text mt-2 mb-3">
                    {second > 0 || minutes > 0 ? (
                        <p style={{ textAlign: "right" }}>
                            Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}
                            :{second < 10 ? `0${second}` : second}
                        </p>
                    ) : (
                        <div style={{ textAlign: "right", display: "flex", justifyContent: "flex-end" }} >
                            <p style={{ lineHeight: "2.2" }}>Didn't receive code?</p>
                            <button className="btn" onClick={reSendOtp}>
                                Resend OTP
                            </button>
                        </div>
                    )}

                </div>


                {/* <div className="transaction_details_Successful text-center mt-4">
                    <h5><img src={coin_data?.image} alt="withdrawal confirmation" width="30" /></h5>

                    <h2 className="mb-0">
                        {utill.beautifyNumber(withdraw_data?.amount, 4)}{" "}
                        {coin_data?.symbol}</h2>
                    <p>Amount</p>
                </div>
                <ul className="mt-4">
                    <li className={`d-flex justify-content-between text-left`}><p className="text-secondary">Send Mode</p> {gas_fee_data?.send_mode_text}</li>
                    <li className="d-flex justify-content-between text-left"><p className="text-secondary">To</p>
                        <div style={{ textAlign: "end" }}>
                            {gas_fee_data?.send_mode == 1
                                ? withdraw_data?.withdrawTo?.substring(0, 5) +
                                "..." +
                                withdraw_data?.withdrawTo?.substring(
                                    withdraw_data?.withdrawTo?.length - 5
                                )
                                : withdraw_data?.withdrawTo}
                            <br />
                            {username &&
                                <small className="text-secondary mt-0 pt-0">
                                    ({username})
                                </small>
                            }
                        </div>
                    </li>
                   
                    <li className="d-flex justify-content-between text-left">
                        <p className="text-secondary">Total Amount</p>
                        <div style={{ textAlign: "end" }}>
                            {`${gas_fee_data?.total_coin}`}
                            <br />
                            <small className="text-secondary">
                                $ {gas_fee_data?.total_coin_currency}
                            </small>
                        </div>
                    </li>
                </ul> */}

            </Modal.Body>
            <Modal.Footer className="justify-content-center mt-0 pt-0" style={{ borderTop: "none" }}>
                <Button variant="secondary" onClick={props.onHide} style={{ width: "47%" }}>
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    style={{ width: "47%" }}
                    onClick={() => {
                        verifyPrivateOtp()
                    }}
                    disabled={loading ? true : false}
                >
                    {loading ? "Loading..." : "Confirm"}                  </Button>
            </Modal.Footer>


        </Modal >
    );
}


function TxnSuccessModalView(props) {
    const { gas_fee_data, selectedNetwork, withdrawMsg } = props;
    return (
        <Modal
            {...props}
            size="md"
            centered
            animation
            scrollable={true}
            backdrop="static"
            aria-labelledby="contained-modal-title-center"
            className="transaction_details_Successful_modal"
            contentClassName="modal_content_Size"
        >
            <Modal.Header>
                <Modal.Title>Withdrawal</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center" style={{ overflowY: "clip" }}>
                <div className="transaction_details_Successful text-center">
                    <h5 className="green"><i className="ion ion-md-checkmark-circle mr-1"></i>{withdrawMsg}</h5>
                   
                    <h2 className="mb-0">
                        {`${gas_fee_data?.total_coin}`}</h2>
                    <p>$ {gas_fee_data.total_coin_currency}</p>
                </div>
                <ul className="mt-4">
                    <li className="d-flex justify-content-between text-left"><p className="text-secondary">To</p>
                        {localStorage.getItem("withdrawTo")?.substring(0, 25) + "..."}
                    </li>
                    {selectedNetwork?.label &&
                        <li className="d-flex justify-content-between text-left"><p className="text-secondary">Network</p>{selectedNetwork?.label}</li>
                    }
                    <li className="d-flex justify-content-between text-left"><p className="text-secondary">Source</p>{gas_fee_data?.send_mode_text}</li>
                </ul>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "center", borderTop: "none" }}>
                <Button variant="primary" onClick={props.onHide} style={{ width: '45%' }}>
                    Ok
                </Button>
            </Modal.Footer>


        </Modal>
    );
}
