import React, { useState, useEffect } from 'react'
import BASE_URL from '../_constant/index'

const EmptyData = () => {
    return (
        <div className="overview_no_open_orders text-center">
            <div>
                <img src={`${BASE_URL.BASE_URL}img/wallet-overview/no-open-order.png`} alt="no-open-order" />
                <p className="mt-2">No Records Found</p>
            </div>
        </div>
    )
}

export default EmptyData;