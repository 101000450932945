import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import BASE_URL from '../../_constant/index'
import Select from "react-select";
import utill from "../../_constant/utill";
import Spinner from 'react-bootstrap/Spinner';
import { fundCoinList, fundTransfer, mainCoinList } from '../../redux/apiActions/user.action'
import { toast } from "../../components/Toast/Toast";


const TransferFind = ({ getUserBalance, selectCoinData, coinList, setShowTransfer, selectedCurrency, selectedCoin }) => {





  const [coinData, setCoinData] = useState(null);
  const [loading, setLoading] = useState(false)
  const [transfer, setTransfer] = useState(false)
  const [conform, setConform] = useState(false);
  const [coinFundType, setCoinFundType] = useState(1)
  const [coinDataAmount, setCoinDataAmount] = useState()
  const [CoinPrice, setCoinPrice] = useState()
  const [newCoinPrice, setNewCoinPrice] = useState()
  const [show, setShow] = useState(false);

  const [coinFund, setCoinFund] = useState({
    total_coin: "",
    available_coin: "",
    address: "",
    network_type: "",
    frozen_coin: "",
    coin: "",
  })
  const [errorMsg, setErrorMsg] = useState({
    currencyError: "",
    CoinErro: "",
    selectCoin: ""
  });

  useEffect(() => {
    const selectedCurrencyPrice = coinData?.coin_currency_price.find(
      (currency) => currency.currency_id === selectedCurrency?.value
    );

    setCoinPrice(selectedCurrencyPrice)
    setNewCoinPrice(selectedCurrencyPrice?.price)

  }, [coinData])
  useEffect(() => {
    setCoinData(selectCoinData)
  }, [selectCoinData])

  useEffect(() => {
    if (selectedCoin) {
      setNewCoinPrice(selectedCoin.price)
      const defaultCoin = coinList?.find((coin) => coin?.code == selectedCoin.coin);
      if (defaultCoin) {
        setCoinData(defaultCoin);
      }
    }

  })

  const transferCoin = () => {
    if (!coinData) {
      setErrorMsg((prevState) => ({
        ...prevState,
        selectCoin: "Please Select Coin",
      }));

    } else {
      if (transfer) {
        setCoinFundType(1)
        setTransfer(false)
      } else {
        setCoinFundType(2)
        setTransfer(true)
      }
    }
  }


  const setCoinValue = async (val) => {
    setCoinData(val)
  };

  const setWithdrawAmount = (e) => {
    const value = e.target.value
    if (!coinFund) {
      setErrorMsg((prevState) => ({
        ...prevState,
        amountError: "",
        currencyError: coinFund && parseFloat(coinFund?.available_coin) >= parseFloat(value)
          ? ""
          : (coinFund ? `Your balance is low to transfer, your balance is ${coinFund?.available_coin}` : "Fund is required"),
      }));
    }
    if (value !== false) {
      setErrorMsg((prevState) => ({
        ...prevState,
        amountError: "",
        currencyError: coinFund && parseFloat(coinFund?.available_coin) >= parseFloat(value)
          ? ""
          : (coinFund ? `Your balance is low to transfer, your balance is ${coinFund?.available_coin}` : "Fund is required"),
      }));
    }
    setCoinDataAmount(value);
  };


  useEffect(async () => {
    setErrorMsg({
      currencyError: "",
      CoinErro: "",
      selectCoin: ""
    });


    if (coinData) {
      setLoading(true)
      let data = new FormData();
      data.append("network", coinData?.network_type);
      data.append("coin", coinData?.code);
      data.append("address", coinData?.contract_address);

      let apiEndpoint = coinFundType === 1 ? mainCoinList : fundCoinList;
      const response = await apiEndpoint(data);
      if (response.status == 200) {
        setLoading(false)
        // setSpotPrice(coinFund.total_coin)
        setCoinFund(prevState => {
          if (coinFundType === 1) {
            return {
              ...prevState,
              total_coin: response?.data?.balance,
              available_coin: response?.data?.available_balance,
              coin: response?.data?.coin,
            };
          } else {
            return {
              ...prevState,
              total_coin: response?.data?.total_coin,
              available_coin: response?.data?.available_coin,
              address: response?.data?.address,
              network_type: response?.data?.network_type,
              frozen_coin: response?.data?.frozen_coin,
              coin: response?.data?.coin,
            };
          }
        });
      } else {
        setTimeout(() => {
          toast.errors("Invalid Details", response?.data?.message)
          setLoading(false)
        }, 7000)
      }
    }
  }, [coinData, coinFundType,]);


  const handleShowconform = () => {
    if (!coinDataAmount || !coinFund || Object.keys(coinFund).length === 0) {
      setErrorMsg((prevState) => ({
        ...prevState,
        currencyError: "Amount is required",
      }));
      return;
    }

    if (parseFloat(coinDataAmount) > parseFloat(coinFund?.available_coin)) {
      setErrorMsg((prevState) => ({
        ...prevState,
        currencyError: `Your balance is low to transfer, your balance is ${coinFund?.available_coin}`,
      }));
      return;
    }
    setConform(true)
    // setShowTransfer(false)
  }

  const handleFundTransfer = async () => {

    if (!coinDataAmount || !coinFund || Object.keys(coinFund).length === 0) {
      setErrorMsg((prevState) => ({
        ...prevState,
        currencyError: "Amount is required",
      }));
      return;
    }

    if (parseFloat(coinDataAmount) > parseFloat(coinFund?.available_coin)) {
      setErrorMsg((prevState) => ({
        ...prevState,
        currencyError: `Your balance is low to transfer, your balance is ${coinFund?.available_coin}`,
      }));
      return;
    }
    setLoading(true)
    const data = new FormData();
    data.append("network_type", coinData?.network_type);
    data.append("coin", coinData?.code);
    data.append("type", coinFundType);
    data.append("value", coinDataAmount);
    const response = await fundTransfer(data);

    if (response) {
      setConform(false)
      setLoading(false)
      setShowTransfer(false)
    }
    if (response.status == 200) {
      getUserBalance(coinData?.code);
      setShow(true);
      setShowTransfer(false)
      toast.successes("Transferred Successfully", response?.message);
    } else {
      toast.errors("Invalid Details", response?.message);
      setCoinDataAmount("");
      setShowTransfer(false)
    }
  };


  return (
    <>
      {!conform ? (
        <div className="form-group text-left">
          <label className="d-flex flex-wrap justify-content-between" htmlFor="withdrawAmount">Coin</label>
          <div className={`form-group market_trade_input_block withdraw-amount-input-block position-relative`}>
            <Select
              className="wallet_network_select"
              classNamePrefix="custom_select"
              isSearchable={true}
              name="network"
              placeholder="Select a coin"
              value={coinData}
              onChange={(e) => {
                setCoinValue(e);
                setErrorMsg({
                  CoinErro: "",
                })
              }}
              options={coinList}
              getOptionValue={(option) => option?.value}
              getOptionLabel={(option) => (
                <div className='d-flex align-items-center'>
                  <img src={`${option.image}`}
                    alt={option?.label || option?.symbol} width='25' className='mr-2' />
                  {option.label || option?.symbol}
                </div>
              )}
            />
          </div>
          <div className="coin_transfer">
            {loading ? (<>
              <div className="d-flex align-items-center justify-content-center mt-4 pb-4">
                <Spinner animation="border" variant="secondary" className="mt-3 mb-3" />
              </div>
            </>) : (<>

              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="internal_transfers_icon_w"><img src={`${BASE_URL.BASE_URL}img/wallet-overview/fiat_and_spot.png`} alt="fiat_and_spot" width="25px" /></div>
                  <h6 className="text-secondary ml-4 internal_transfers_form_w">From</h6>
                  <h6 className="card-small-title mb-2 ml-4">{transfer ? "Funding" : "Spot"}</h6>
                </div>
                <div className="d-flex align-items-center justify-content-right">
                  <i className="ion ion-md-arrow-dropdown"></i>
                </div>
              </div>
              <div className="internal-transfers-icons d-flex justify-content-between align-items-center">
                <div className="internal_transfers_icon_w"><i className="icon ion-ios-arrow-round-down"></i></div>
                <img src={`${BASE_URL.BASE_URL}img/icon/transfer.png`} onClick={transferCoin} style={{ cursor: "pointer" }} alt="transfer" />
              </div>

              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center cross_margin_section_inner">
                  <div className="internal_transfers_icon_w"><i className="ion ion-md-shuffle"></i></div>
                  <h6 className="text-secondary ml-4 internal_transfers_form_w">TO</h6>
                  <h6 className="card-small-title mb-2 ml-4">{transfer ? "Spot" : "Funding"}</h6>
                </div>
                <div className="d-flex align-items-center justify-content-right">
                  <i className="ion ion-md-arrow-dropdown"></i>
                </div>
              </div>
            </>)}
          </div>

          <small className="red"> {errorMsg?.selectCoin}</small>
          <div className="mt-3">
            <div className="form-group mt-3">
              <label className="d-flex flex-wrap justify-content-between" htmlFor="withdrawAmount">Amount <span>
                {utill.beautifyNumber(coinFund?.available_coin, 6)}{" "}<span className="text-secondary mr-1">available</span></span></label>
              <div className={`form-group market_trade_input_block withdraw-amount-input-block position-relative mb-0`}>
                <input
                  type="number"
                  className="form-control"
                  id="withdrawAmount"
                  name="withdrawAmount"
                  placeholder="Enter Amount"
                  value={coinDataAmount}
                  onChange={(e) => {
                    if (coinData) {
                      setWithdrawAmount(e);
                      setErrorMsg({
                        CoinErro: "",
                      })
                      if (parseFloat(coinDataAmount) > parseFloat(coinFund?.available_coin)) {
                        setErrorMsg(() => ({
                          currencyError: "Balance is Low!"
                        }));
                      }
                    } else {
                      setErrorMsg({
                        CoinErro: "Coin is required",
                      })
                      setCoinDataAmount("")
                    }

                  }}
                />
                {/* <button className="btn btn-info"
       // onClick={() => { setCoinDataAmount(utill.beautifyNumber(coinFund?.available_coin, 4)) }}
       >MAX</button> */}
              </div>
              <small className="red"> {errorMsg?.currencyError}</small>
              <label className="d-flex flex-wrap justify-content-between pt-2" htmlFor="withdrawAmount">Transfer Amount
                <span>
                  $ {coinData ? utill?.beautifyNumber(parseFloat(newCoinPrice) * parseFloat(coinDataAmount), 2) : "0.00"}
                </span>
              </label>

            </div>
            <Button className="btn btn-info" disabled={loading ? true : false}
              variant="primary"
              onClick={handleShowconform}
            >Confirm</Button>
          </div>

        </div>
      ) : show ? (
        <>
          <div className="transaction_details_Successful text-center">
            <h5><i className="ion ion-md-checkmark-circle mr-1"></i>Transferred Successfully</h5>
            <h2 className="mb-0">{coinDataAmount} {coinData?.symbol}</h2>
          </div>
          <Button className="btn btn-info btn-withdraw-w mt-3" variant="primary" onClick={() => { setShow(false) }}>Ok</Button>
        </>
      ) :
        (
          <>
            <div className="text-center">
              <h4 className="mb-1">Are you sure ?</h4>
              <p className="text-secondary"> Do you really want to add amount to {transfer ? "Spot" : "Fund"} Wallet ? <br /> This process cannot be
                undone.</p>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <Button
                variant="secondary"
                style={{ width: "48%" }}
                onClick={() => { setConform(false) }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                style={{ width: "48%" }}
                onClick={() => {
                  handleFundTransfer()
                }}
                disabled={loading ? true : false}
              >
                {loading ? "Please wait..." : "Confirm"}
              </Button>
            </div>
          </>
        )
      }

    </>
  )
}

export default TransferFind
