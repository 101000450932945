import React, { useEffect, useState, useRef } from 'react';
import { chat_Push_Notification } from '../../redux/apiActions/p2p.action'
import BASE_URL from "../../_constant/index";
import { Modal, Button } from 'react-bootstrap'
import { getDatabase, ref, onValue, set } from 'firebase/database';
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "../../components/Toast/Toast";
import * as firebaseSt from "firebase/storage";
import app from "../../messaging_init_in_sw";
import { getAnalytics } from "firebase/analytics";
const analytics = getAnalytics(app);

const Chat = ({ tradeData }) => {

  const userData = useSelector((state) => state.user.userData);

  const [message, setMessage] = useState("");
  const [chatData, setChatData] = useState({});
  const [chatKeys, setChatKeys] = useState([]);
  const messagesContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatKeys]);


  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {

        setChatData({});
        const initialData = await setupChatListener();
        if (isMounted) {
          setChatData((prevData) => ({ ...prevData, ...initialData }));
        }
      } catch (error) {
        console.error('Error fetching chat data:', error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [tradeData]);


  function storeChatMessages(msg, imageUrl) {
    if (tradeData?.uid) {
      let validateMsg = true;
      if (imageUrl?.length === 0) {
        if (msg.trim()?.length === 0) validateMsg = false;
      }
      if (validateMsg) {
        const db = getDatabase();

        const trade_id = tradeData?.uid;
        const reference = ref(db, `${trade_id}/${Date.now()}`);

        set(reference, {
          username: userData?.full_name,
          user_id: userData?.id,
          type: 'text',
          time: moment().format('hh:mm A'),
          msg: msg,
          imageUrl: imageUrl || '',
        });
        setMessage('');
      }
    }

  }

  const setupChatListener = () => {
    const db = getDatabase();
    const trade_id = tradeData?.uid;
    if (trade_id) {
      const reference = ref(db, trade_id);

      return new Promise((resolve, reject) => {
        onValue(reference, (snapshot) => {
          const data = snapshot.val() || {};
          const chatItems = Object.values(data);
          // setChatData(chatItems);

          const filteredNewChatItems = chatItems?.filter(
            (item) => !chatData[item?.key]
          );

          setChatData((prevData) => ({ ...prevData, ...data }));

          const newKeys = Object.keys(data);
          setChatKeys((prevKeys) => [...new Set([...prevKeys, ...newKeys])]);

          resolve(filteredNewChatItems);

        }, (error) => {
          reject(error);
        });
      });
    }
  };

  const validateSubmitMsg = () => {
    if (message?.length > 0) {
      storeChatMessages(message);
      sendPushNotification(message, '0');
      setMessage('');
    }
  };

  const sendPushNotification = async (message, isMedia) => {
    try {
      if (!tradeData || !tradeData.trade_partner_id || !tradeData.id) {
        return;
      }

      const data = new FormData();
      data.append('partner_user_id', tradeData?.trade_partner_id);
      data.append('trade_id', tradeData?.id);
      data.append('message', message);
      data.append('is_media', isMedia);

      const response = await chat_Push_Notification(data);

      if (response.status === 200) {
      } else {
      }

    } catch (error) {
      console.error('Error sending push notification:', error);
    }
  };


  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      toast.error("Please select a file.");
      return;
    }

    if (selectedFile.size > 2000000) {
      toast.error("Attachment size should not be bigger than 2 MB.");
      return;
    }

    toast.successes("Attachment is being uploading....");

    // Upload the file immediately after it's selected
    await uploadFile(selectedFile);
  };


  const uploadFile = async (selectedFile) => {
    try {
      const imgName = "img-" + new Date().getTime();
      const ext = selectedFile.name.split(".").pop();
      const path = `chat_attachment/${imgName}.${ext}`;

      const storage = firebaseSt.getStorage();
      const storageRef = firebaseSt.ref(storage, path);

      const fileBlob = new Blob([selectedFile], { type: selectedFile.type });

      await firebaseSt.uploadBytes(storageRef, fileBlob);

      const downloadURL = await firebaseSt.getDownloadURL(storageRef);

      toast.success("Attachment has been uploaded.");
      sendPushNotification("Send You an Attachment", "1");
      storeChatMessages("", downloadURL);
    } catch (error) {
      toast.error("Error uploading file.");
    }
  };

  const [image, setImage] = useState(false)
  const [imageUrl, setImageUrl] = useState("")

  const openImageModal = (url) => {
    setImageUrl(url);
    setImage(true)
  }
  return (
    <>
      <Modal
        show={image}
        onHide={() => { setImage(false) }}
        backdrop="static"
        keyboard={false}
        className="p2pOfferViewModal"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Transfer</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 col-12 d-flex justify-content-center">
              <img src={imageUrl} className="img-fluid" title="Chat attachment" id="chat_img_view"
                style={{ maxHeight: "500px" }}
              />
            </div>
          </div>
        </Modal.Body>

      </Modal>
      <div className="col-lg-5 col-12 p-3 justify-content-start justify-content-lg-end">
        <div className="order_status_chat_section">
          <div className="row  p-3">
            <div className="col-md-12 col-12">
              <ul className="p-3 p2p_cancelled_user_pro">
                <li>
                  <img className="mr-2" src={`${BASE_URL.BASE_URL}img/images/p2p_cancelled_user.png`} height="30px">
                  </img>
                  {tradeData?.trade_nick_name
                    ? tradeData?.trade_nick_name
                    : tradeData?.trade_full_name}
                </li>
              </ul>
            </div>
          </div>
          <div className="card" id="chat2">
            <div className="card-body" data-mdb-perfect-scrollbar="true">


              <div id="messages" ref={messagesContainerRef}>

                {chatKeys?.length > 0
                  ? chatKeys?.map((key) =>
                    chatData[key]?.user_id == userData?.id ? (
                      chatData[key]?.msg?.length > 0 ? (
                        <div key={key} className='d-flex flex-row justify-content-end mb-1 pt-1'>

                          <div className="user_chat_chat" >
                            <p className="small p-2 text-white rounded-3">
                              {chatData[key]?.msg
                                ? chatData[key]?.msg.trim()
                                : ""}
                            </p>
                            <p className="chat_timing small rounded-3 text-muted d-flex justify-content-end">
                              {chatData[key]?.time}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div key={key} className='d-flex flex-row justify-content-end mb-1 pt-1'>
                          <div className="user_chat_chat" >
                            <p onClick={() => { openImageModal(chatData[key]?.imageUrl) }}
                              className="small p-0 text-white rounded-3 view_img" data-url={chatData[key]?.imageUrl}>
                              <img
                                className="msg_img p-0"
                                src={chatData[key]?.imageUrl}
                              />
                            </p>
                            <p className="chat_timing small rounded-3 text-muted d-flex justify-content-end">
                              {chatData[key]?.time}
                            </p>

                          </div>
                        </div>
                      )
                    ) : chatData[key]?.msg && chatData[key]?.msg.length > 0 ? (
                      <div key={key} className='d-flex flex-row justify-content-end mb-1 pt-1'>
                        <div className="client_chat_chat" key={key}>
                          <p className="small p-2 text-white rounded-3">{chatData[key]?.msg
                            ? chatData[key]?.msg.trim()
                            : ""}</p>
                          <p className="chat_timing small rounded-3 text-muted d-flex justify-content-start">
                            {chatData[key]?.time}
                          </p>
                        </div>
                      </div>

                    ) : (
                      <div key={key} className='d-flex flex-row justify-content-end mb-1 pt-1'>

                        <div className="client_chat_chat w-100" key={key}>

                          <p onClick={() => { openImageModal(chatData[key]?.imageUrl) }}
                            className="small p-0 text-white rounded-3 view_img"
                            data-url={chatData[key]?.imageUrl}>
                            <img className="msg_img p-0" src={chatData[key]?.imageUrl} />
                          </p>
                          <p className="chat_timing small rounded-3 text-muted d-flex justify-content-start">
                            {chatData[key]?.time}
                          </p>
                        </div>
                      </div>
                    )
                  )
                  : null}

              </div>

              <div
                className="card-footer text-muted d-flex justify-content-start align-items-center"
                id="chat_input_div"
              >
                {(tradeData?.status &&
                  tradeData?.status == "1") ||
                  tradeData?.status == "2" ||
                  tradeData?.status == "3" ? (<>

                    <textarea
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      className="form-control form-control-lg mr-3"
                      rows="1"
                      id="message_txt"
                      placeholder="Type message"
                      spellCheck="false"
                    />
                    <div className="file_attach_div">
                      <input
                        type="file"
                        name="files[]"
                        id="files"
                        className="file_attach_input"
                        onChange={handleFileChange}
                        accept="image/*"
                      />
                      <a className="mr-md-3 mr-2 text-muted file_attach_pin"><i className="fa fa-paperclip"></i></a>
                    </div>
                    <a
                      id="sendMessage_btn"
                      onClick={() => validateSubmitMsg()}
                    ><i className="fa fa-paper-plane"></i></a>
                  </>) : (<>
                    <span className="chat_not">Chat no longer available.</span>
                  </>)}
              </div>

            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default Chat

