import React, { useState } from 'react';
import {
    Col,
    Container,
    Modal,
    Row,
    Button
} from 'react-bootstrap';
import { Link, useLocation } from "react-router-dom"
import BASE_URL from "../../_constant/index";
import CreateNewOrder from './CreateNewOrder';
import { toast } from "../../components/Toast/Toast";
import { offerDetailsByID, update_Pause_Status, delete_Offer } from '../../redux/apiActions/p2p.action'
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import { useSelector } from "react-redux";
import LoginFirst from '../loginFirst';
import utill from "../../_constant/utill";

const Myoffers = ({ offerList, loadingData, handleMyOfferList, deCressOfferPage, inCressOfferPage, offerPage }) => {

    let user_Data = useSelector((state) => state.user.userData);
    // user_Data?.access_token

    const location = useLocation();
    const routeName = location.pathname;
    const returnToUrl = `${BASE_URL.BASE_URL.slice(0, -1)}${routeName}`;


    const outputFormat = 'DD MMM YYYY HH:mm a';
    const [show, setShow] = useState(false);
    const [offerDetails, setOfferDetails] = useState(null);
    const [showOrder, setShowOrder] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [loading, setLoading] = useState(false)
    const handleClose = () => setShow(false);

    const getOfferDetailsByID = async (id) => {
        try {
            const formData = new FormData()
            formData.append("offer_id", id)
            const response = await offerDetailsByID(formData);

            if (response.status == 200) {
                setShow(true);
                setOfferDetails(response.data[0])
            }

        } catch (error) {
            console.log(error)
        }
    }

    const updatePauseStatus = async (id, status) => {
        let CheckStatus = status == 1 ? 0 : 1;
        // setPauseStatus(CheckStatus)
        try {
            const formData = new FormData()
            formData.append("offer_id", id)
            formData.append("pause_status", CheckStatus)
            const response = await update_Pause_Status(formData);
            if (response) {
                handleMyOfferList()
            }
            if (response.status == 200) {
                toast.successes(response?.message || "Update successful")
            } else {
                toast.errors(response?.message || "Something went wrong")
            }
        } catch (error) {
            console.log(error)
        }
    }
    const [showDelete, setShowDelete] = useState(false);
    const showDeletePopUp = (id) => {
        setShowDelete(true)
        setDeleteId(id)
    }
    const handleDeleteOffer = async () => {
        setLoading(true)
        try {
            const formData = new FormData()
            formData.append("offer_id", deleteId)
            const response = await delete_Offer(formData);
            if (response) {
                handleMyOfferList()
                setShowDelete(false)
                setLoading(false)
            }
            if (response.status == 200) {
                toast.successes(response?.message || "Delete successful")
            } else {
                toast.errors(response?.message || "Something went wrong")
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (< >
        <Modal
            show={showDelete}
            onHide={() => setShowDelete(false)}
            backdrop="static"
            animation={false}
            className="p2pOfferViewModal"
            centered
        >

            <Modal.Body className="text-center pt-4">
                <img src={`${BASE_URL.BASE_URL}img/images/sure-icon.png`} className="dark-theme-img" width="120"></img>
                <img src={`${BASE_URL.BASE_URL}img/images/sure-icon-light.png`} className="light-theme-img" width="120"></img>
                <h4 className="my-3">Are you sure?</h4>
                <h6 className="gray_colour">You wan't able to recover this.</h6>
            </Modal.Body>
            <Modal.Footer className="pb-4">
                <Button variant="primary" disabled={loading ? true : false} onClick={handleDeleteOffer} className="p2p_dlt_confirm_btn theme-btn-2 theme-btn">
                    {loading ? "Loading.." : "Confirm"}
                </Button>
                <Button variant="secondary" onClick={() => setShowDelete(false)} className="btn disable_btn_bg harvest_btn_pools theme-btn-2 theme-btn">
                    Cancel
                </Button>
            </Modal.Footer>

        </Modal>
        <Modal className="p2pOfferViewModal" size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>View Offer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="active_trades_table_mobile_scroll">
                    <table className="w-100">
                        <tbody>
                            <tr className="text-left">
                                <td>

                                    <p className="gray_colour">ID </p>
                                    <h6><span id="of_offer_id" title={offerDetails?.uid}>{offerDetails?.uid.substring(0, 5)}...{offerDetails?.uid.substring(offerDetails?.uid.length - 7)}</span></h6>
                                </td>
                                <td>
                                    <p className="gray_colour">Type </p>
                                    <h6><span id="of_trade_type" >{offerDetails?.trade_type === "1" ? "Buying" : "Selling"}</span> <span id="of_coin">{offerDetails?.coin?.toUpperCase()}</span></h6>
                                </td>
                                <td>
                                    <p className="gray_colour">Payment Method </p>
                                    <h6 className="d-flex" id="of_payment_method">{offerDetails?.payment_type}</h6>
                                </td>
                            </tr>

                            <tr className="text-left">
                                <td>
                                    <p className="gray_colour">Rate </p>
                                    <h6><span id="of_margin" >{offerDetails?.margin_percentage}% {" "}
                                        {offerDetails?.margin_type == 1 ? "above" : "below"}
                                    </span>
                                        <span id="of_coin_curr" className="fs-5v gray_colour">
                                            {offerDetails?.currency_code}/{offerDetails?.coin?.toUpperCase()}
                                        </span>
                                    </h6>
                                </td>
                                <td>
                                    <p className="gray_colour" id="quantity_text">Quantity
                                        (I can {offerDetails?.trade_type == 1 ? "buy" : "sell"})</p>
                                    <h6><span id="of_total_amount" >{parseFloat(offerDetails?.total_amount)}</span></h6>
                                </td>
                                <td>
                                    <p className="gray_colour">Location </p>
                                    <h6 id="of_location" >{offerDetails?.location}</h6>
                                </td>
                            </tr>

                            <tr className="text-left">
                                <td>
                                    <p className="gray_colour">Order Limit </p>
                                    <h6><span id="of_min_amount" >{offerDetails?.currency_symbol}{offerDetails?.min_trade_size}</span> - <span id="of_max_amount">{offerDetails?.currency_symbol}{offerDetails?.max_trade_size}</span></h6>
                                </td>
                                <td>
                                    <p className="gray_colour">Payment Time Limit </p>
                                    <h6 id="of_payment_time_limit">{offerDetails?.payment_time}</h6>
                                </td>
                                <td>
                                    <p className="gray_colour pt-2 pb-2">Terms of Trade </p>
                                    <h6 id="of_terms" >{offerDetails?.terms_trade}</h6>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Modal.Body>

        </Modal>
        <section className="dark-gray-bg-v pb-5">
            <Container>
                <Row>
                    <Col>
                        <div className="p2p_filter_section justify-content-end align-items-center d-flex w-100" >
                            <div className="active_trades_right_btn" >
                                {user_Data?.access_token &&
                                    <Link to={`${BASE_URL.BASE_URL}p2p/Orders?to=p2p_create_offer`}>
                                        <a className="create_new_offer_btn theme-btn-2 theme-btn text-center create_offer_btn" type="button" > Create new offer </a>
                                    </Link>
                                }
                            </div >
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        <section className="p2p_main_banner_section p2p_mainTable">
            <Container>
                <Row>
                    <div className="active_trades_table_mobile_scroll w-100">
                        <table id="MyoffersnTable" className="table table-striped w-100">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Payment Method</th>
                                    <th>Rate</th>
                                    <th>Location</th>
                                    <th>Date</th>
                                    <th className="justify-content-end d-flex">Status</th>
                                </tr>
                            </thead>
                            <tbody className="my_offer_list" id="my_offer_list">
                                {offerList?.length > 0 ? (
                                    offerList.map((item, index) => {
                                        const { id, trade_type, coin, offer_price, margin_percentage, margin_type, currency_code, location, created_date, is_paused, total_amount, min_trade_size, payment_type } = item;
                                        const restricted = parseFloat(total_amount) * parseFloat(offer_price) < parseFloat(min_trade_size);
                                        const isBuying = trade_type === "1";

                                        return (
                                            <tr key={`my_offer_id_${id}`}>
                                                <td>{isBuying ? "Buying" : "Selling"} <span className="coin-code">{coin?.toUpperCase()}</span></td>
                                                <td>{payment_type}</td>
                                                <td>
                                                    <div className="p2p_quantity_limit_sec">
                                                        <p className='tableText'>
                                                            {utill.beautifyNumber(parseFloat(offer_price))}{" "}
                                                            <span className="coin-code">{coin?.toUpperCase()}</span>
                                                        </p>
                                                        <p className="safelist_text_colour m-0 tableText">
                                                            {margin_percentage}% {margin_type === 1 ? "above" : "below"}{" "}
                                                            <span className="coin-code">{currency_code}/{coin?.toUpperCase()}</span>
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>{location}</td>
                                                <td>{moment(created_date, 'YYYY-MM-DD HH:mm:ss').format(outputFormat)}</td>
                                                <td className="justify-content-end d-flex">
                                                    <ul className="d-flex align-content-center myoffer_status_btns_section mb-0">
                                                        <li className="view_offer" data-id={id}>
                                                            <button onClick={() => getOfferDetailsByID(id)} className="myoffer_status_btns" type="button">
                                                                <img src={`${BASE_URL.BASE_URL}img/images/view-icon.svg`} alt="View" />
                                                            </button>
                                                        </li>
                                                        <li className="pause_offer" data-type={is_paused} data-id={id}>
                                                            <button onClick={() => updatePauseStatus(id, is_paused)} className="myoffer_status_btns" type="button" disabled={restricted}>
                                                                {is_paused === 0 ? <img src={`${BASE_URL.BASE_URL}img/images/pause-icon.svg`} alt="Pause" /> : <img src={`${BASE_URL.BASE_URL}img/images/play-icon.svg`} alt="Pause" />}
                                                            </button>
                                                        </li>
                                                        <li className="delete_offer" data-id={id}>
                                                            <button className="myoffer_status_btns" type="button" onClick={() => showDeletePopUp(id)} disabled={restricted}>
                                                                <img src={`${BASE_URL.BASE_URL}img/images/delete-icon.svg`} alt="Delete" />
                                                            </button>
                                                        </li>
                                                        {restricted ? (
                                                            <Button disabled>Restricted</Button>
                                                        ) : (
                                                            <li data-id={id}>
                                                                <Link to={`${BASE_URL.BASE_URL}p2p/Orders?to=p2p_create_offer&id=${id}`} className="myoffer_status_edit_btns">
                                                                    <img src={`${BASE_URL.BASE_URL}img/images/edit-icon.svg`} alt="Edit" />
                                                                    Edit
                                                                </Link>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={8}>
                                            <div className="overview_no_open_orders" style={{ width: "100%" }}>
                                                {!user_Data?.access_token ? (
                                                    <LoginFirst return_to={btoa(`${returnToUrl}`)} />
                                                ) : (
                                                    <>
                                                        {loadingData ? (
                                                            <Spinner animation="border" variant="secondary" />
                                                        ) : (
                                                            <div className="col-12 mb-5 d-flex justify-content-center mt-5" style={{ height: "100px" }}>
                                                                <div className="no-data" style={{ color: "#9ea4ab" }}>
                                                                    <i className="icon ion-md-document" style={{ color: "#9ea4ab" }}></i>
                                                                    No data
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {offerList?.length > 0 &&
                            <div className="col-12 mb-5 d-flex justify-content-between mt-3">
                                {offerPage == 1 ? (<>
                                    <button
                                        disabled={offerList?.length < 10 ? true : false}
                                        type="button"
                                        onClick={inCressOfferPage}
                                        className="load_more_btn theme-btn-2 theme-btn mt-3 float-left"
                                        id="loadOffer">
                                        Next
                                    </button>
                                </>) : (<>
                                    <button
                                        disabled={offerPage == 1 ? true : false}
                                        type="button"
                                        onClick={deCressOfferPage}
                                        className="load_more_btn theme-btn-2 theme-btn mt-3 float-left"
                                        id="loadOffer">
                                        Previous
                                    </button>

                                    {offerList?.length > 9 &&
                                        < button
                                            disabled={offerList?.length < 10 ? true : false}
                                            type="button"
                                            onClick={inCressOfferPage}
                                            className="load_more_btn theme-btn-2 theme-btn mt-3 float-left"
                                            id="loadOffer">
                                            Next
                                        </button>
                                    }
                                </>)}
                            </div>
                        }
                    </div>
                </Row>
            </Container>
        </section >

    </>
    )
}

export default Myoffers