import React from "react";
import { GoogleLogin } from "react-google-login";
import BASE_URL from "../_constant/index";
import { ThemeConsumer } from "../context/ThemeContext";
import { loginActionwithbuttons } from "../redux/apiActions/user.action";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
const LoginWithGoogle = ({ notificationToken }) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const queryParameters = new URLSearchParams(window.location.search);
  let return_to_url = queryParameters.get("return_to");
  return_to_url = return_to_url ? atob(return_to_url) : null;


  const onSuccess = async (response) => {
    if (response) {
      let formData = new FormData();
      formData.append("email", response?.profileObj?.email);
      formData.append("token_id", response?.profileObj?.googleId);
      formData.append("login_access_token", response?.tokenId);
      formData.append("device_type", 0); // web login`
      formData.append("login_type", 1); 
      // formData.append("tokenId", response?.tokenId); 
      
      formData.append(

        "device_token",
        notificationToken
          ? notificationToken
          : "deviceTokenForNotificationLogin"
      );
      const responce = await dispatch(
        loginActionwithbuttons(formData, history, return_to_url)
      );
    }
  };

  const onFailure = (response) => {
    console.log("ononFailure", response);
  };
  return (
    <div className="loginwithgoogle" style={{ width: "100%" }}>
      <ThemeConsumer>
        {({ data }) => {
          return data.theme === "dark" ? (
            <GoogleLogin
              clientId={BASE_URL.clientId}
              buttonText="Login with Google"
              render={(
                renderProps //Custom Apple Sign in Button
              ) => (
                <button
                  onClick={renderProps.onClick}
                  style={{
                    backgroundColor: "#1c2028",
                    padding: 10,
                    color: "white",
                    border: "1px solid #282C34",
                    lineHeight: "25px",
                    fontSize: "14px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={`${BASE_URL.BASE_URL}img/images/Group 3156.png`}
                    width={"15px"}
                    className="mr-2"
                  />
                  Continue with Google
                </button>
              )}
              onSuccess={onSuccess}
              onFailure={onFailure}
              cookiePolicy={"single_host_origin"}
              uxMode="popup" // Set ux_mode to "popup"
            />
          ) : (
            <GoogleLogin
              clientId={BASE_URL.clientId}
              buttonText="Login with Google"
              render={(
                renderProps //Custom Apple Sign in Button
              ) => (
                <button
                  onClick={renderProps.onClick}
                  style={{
                    backgroundColor: "#fff4f1",
                    padding: 10,
                    border: "1px solid #fa501c30",
                    // fontFamily: "none",
                    lineHeight: "25px",
                    fontSize: "14px",
                  }}
                >
                  <img
                    src={`${BASE_URL.BASE_URL}img/images/Group 3156.png`}
                    width={"15px"}
                    className="mr-2"
                  />
                  Login with Google
                </button>
              )}
              onSuccess={onSuccess}
              onFailure={onFailure}
              cookiePolicy={"single_host_origin"}
              uxMode="popup" // Set ux_mode to "popup"
            />
          );
        }}
      </ThemeConsumer>
    </div>
  );
};

export default LoginWithGoogle;
