import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Overlay,
  Tooltip,
  Modal,
  Button,
  Clearfix,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import utill from "../../_constant/utill";
import {
  getUserbalanceAction,
  placeOrderAction,
  getOpenOrderAction,
  confirmPlaceOrderAction,
} from "../../redux/apiActions/api.action";
import _constant from "../../_constant";
import LoginFirst from "../loginFirst";
import BASE_URL from "../../_constant/index";
import { useLocation } from "react-router-dom";

export default function MarketTrade({
  pair,
  order_Price,
  updateQuoteBalance,
  updateBaseBalance,
  baseBalance,
  quoteBalance,
  updateOrderHistory,
  updateOpenOrderCount,
  spotBuy,
  _spotBuy,
  spotSell,
  _spotSell,
  orderType,
  setOrdertype,
}) {
  const getTitle = () => {
    if (window.matchMedia("(max-width: 500px)").matches) {
      return "none";
    } else {
      return "block";
    }
  };
  const [tabTitle, setTabTitle] = useState(getTitle());

  // Update the title whenever the screen size changes
  useEffect(() => {
    const handleResize = () => {
      setTabTitle(getTitle());
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const returnUrl = window?.location.href;
  // const returnToUrl = returnUrl?.split("/v1/");

  const location = useLocation();
  const routeName = location.pathname;
  const returnToUrl = `${BASE_URL.BASE_URL.slice(0, -1)}${routeName}`;
  const [load, setLoad] = useState(false);
  let [activePerBuy, _activePerBuy] = useState(0);
  let [activePerSell, _activePerSell] = useState(0);
  const [confirmationModal, showConfirmationModal] = React.useState(false);
  const [disableBuy, _disableBuy] = useState(true);
  const [disableSell, _disableSell] = useState(true);
  const [tradeType, setTradeType] = useState("");
  const [loading, setLoading] = useState(false);
  const [disAbleButton, setDisableButton] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.user?.userData?.access_token);
  const [spotConformationData, setSpotConformationData] = useState();
  useEffect(() => {
    _spotBuy({
      type: "SPOT",
      order_type: "",
      currency: pair?.base.toLowerCase(),
      trade_currency: pair?.quote.toLowerCase(),
      quantity: "",
      price: utill?.valueFix(pair.price),
      amount_type: "QUANTITY",
      limit: 0,
      totalAmount: 0,
    });

    _spotSell({
      type: "SPOT",
      order_type: "",
      currency: pair?.base.toLowerCase(),
      trade_currency: pair?.quote.toLowerCase(),
      quantity: "",
      price: utill?.valueFix(pair.price),
      amount_type: "QUANTITY",
      limit: 0,
      totalAmount: 0,
    });
    _activePerBuy(0);
    _activePerSell(0);
  }, [pair, _spotSell, _spotBuy, _activePerBuy, _activePerSell]);

  function isNumberKey(value) {
    value = value ? value : "";
    // alert(value)
    var validNumber = new RegExp(/^[0-9]*(\.[0-9]*)?$/);
    if (validNumber.test(value)) {
      return value;
    } else {
      return false;
    }
  }

  const placeOrderBuy = async (side) => {
    try {
      setTimeout(() => {
        _disableBuy(true);
      }, 4000);
      _disableBuy(true);
      setDisableButton(true);
      let orderData = {
        side: side,
        type: "SPOT",
        order_type:
          orderType == "Limit"
            ? "LIMIT"
            : orderType === "Market"
            ? "MARKET"
            : orderType === "Stop Limit"
            ? "STOP_LIMIT"
            : "STOP_MARKET",
        currency: spotBuy?.currency
          ? spotBuy?.currency
          : pair?.base.toLowerCase(),
        trade_currency: spotBuy?.trade_currency
          ? spotBuy?.trade_currency
          : pair?.quote.toLowerCase(),
        quantity: spotBuy?.quantity,
        order_value: spotBuy?.totalAmount,
        price: spotBuy?.price,
        amount_type: "QUANTITY",
      };
      if (orderType === "Market") {
        delete orderData?.price;
      }
      if (orderType === "Stop Limit") {
        orderData.stop_price = spotBuy.limit;
      }
      const responce = await placeOrderAction(
        pair?.base + pair?.quote,
        orderData
      );
      if (responce) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    } catch (err) {
    } finally {
      const OrdersResponse = await getOpenOrderAction(Number(pair?.id), 1);
      updateOrderHistory(OrdersResponse?.data);
      updateOpenOrderCount(OrdersResponse?.count);
      const baseBalResponse = await getUserbalanceAction(
        pair?.base.toLowerCase(),
        dispatch
      );
      updateBaseBalance(baseBalResponse?.data?.available_balance);

      const quoteBalResponse = await getUserbalanceAction(
        pair?.quote.toLowerCase(),
        dispatch
      );
      updateQuoteBalance(quoteBalResponse?.data?.available_balance);

      spotBuy.price = "";
      spotBuy.quantity = "";
      spotBuy.totalAmount = "";
      spotBuy.limit = "";
      _activePerBuy(0);
    }
  };

  const placeOrderSell = async (side) => {
    try {
      setTimeout(() => {
        _disableSell(true);
      }, 4000);
      setDisableButton(true);
      let orderData = {
        side: side,
        type: "SPOT",
        order_type:
          orderType == "Limit"
            ? "LIMIT"
            : orderType === "Market"
            ? "MARKET"
            : orderType === "Stop Limit"
            ? "STOP_LIMIT"
            : "STOP_MARKET",
        currency: spotSell?.currency
          ? spotSell?.currency
          : pair?.base.toLowerCase(),
        trade_currency: spotSell?.trade_currency
          ? spotSell?.trade_currency
          : pair?.quote.toLowerCase(),
        // trade_currency: spotSell?.trade_currency,
        quantity: spotSell?.quantity,
        order_value: spotSell?.totalAmount,
        price: spotSell?.price,
        amount_type: "QUANTITY",
      };
      if (orderType === "Market") {
        delete orderData.price;
      }
      if (orderType === "Stop Limit") {
        orderData.stop_price = spotSell.limit;
      }
      const responce = await placeOrderAction(
        pair?.base + pair?.quote,
        orderData
      );
      if (responce) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    } catch (err) {
    } finally {
      const OrdersResponse = await getOpenOrderAction(Number(pair?.id), 1);
      updateOrderHistory(OrdersResponse?.data);
      const baseBalResponse = await getUserbalanceAction(
        pair?.base.toLowerCase(),
        dispatch
      );
      updateBaseBalance(baseBalResponse?.data?.available_balance);

      const quoteBalResponse = await getUserbalanceAction(
        pair?.quote.toLowerCase(),
        dispatch
      );
      updateQuoteBalance(quoteBalResponse?.data?.available_balance);
      spotSell.price = "";
      spotSell.quantity = "";
      spotSell.totalAmount = "";
      spotSell.limit = "";
      _activePerSell(0);
      _disableSell(false);
    }
  };

  const confirm_PlaceOrder_Action = async (side) => {
    setLoad(true);
    const spotType = side === "SELL" ? spotSell : side === "BUY" ? spotBuy : "";
    try {
      // setTimeout(() => {
      //   _disableSell(true);
      // }, 4000);
      setDisableButton(true);
      let orderData = {
        side: side,
        type: "SPOT",
        order_type:
          orderType == "Limit"
            ? "LIMIT"
            : orderType === "Market"
            ? "MARKET"
            : orderType === "Stop Limit"
            ? "STOP_LIMIT"
            : "STOP_MARKET",
        currency: spotType?.currency
          ? spotType?.currency
          : pair?.base.toLowerCase(),
        trade_currency: spotType?.trade_currency
          ? spotType?.trade_currency
          : pair?.quote.toLowerCase(),
        // trade_currency: spotSell?.trade_currency,
        quantity: spotType?.quantity,
        order_value: spotType?.totalAmount,
        price: spotType?.price,
        amount_type: "QUANTITY",
      };
      if (orderType === "Market") {
        delete orderData.price;
      }
      if (orderType === "Stop Limit") {
        orderData.stop_price = spotType.limit;
      }
      const responce = await confirmPlaceOrderAction(
        pair?.base + pair?.quote,
        orderData
      );
      if (responce.status === 200) {
        setSpotConformationData(responce?.data);
        setLoad(false);
        setDisableButton(false);
        showConfirmationModal(true);
      } else {
        setDisableButton(false);
        setLoad(false);
      }
      // if (responce) {
      //   setDisableButton(false);
      // } else {
      //   setDisableButton(true);
      // }
    } catch (err) {
    } finally {
      const OrdersResponse = await getOpenOrderAction(Number(pair?.id), 1);
      updateOrderHistory(OrdersResponse?.data);

      const baseBalResponse = await getUserbalanceAction(
        pair?.base.toLowerCase(),
        dispatch
      );
      updateBaseBalance(baseBalResponse?.data?.available_balance);

      const quoteBalResponse = await getUserbalanceAction(
        pair?.quote.toLowerCase(),
        dispatch
      );
      updateQuoteBalance(quoteBalResponse?.data?.available_balance);
      spotSell.price = "";
      spotSell.quantity = "";
      spotSell.totalAmount = "";
      spotSell.limit = "";
      _activePerSell(0);
      _disableSell(false);
    }
  };

  const calculateBalanceQuote = (percentage, side) => {
    if (side === "Buy" || side === "BUY") {
      _activePerBuy(percentage);
      if (orderType === "Market") {
        if (order_Price?.price) {
          let cal_balance = utill.beautifyNumber(
            (quoteBalance * percentage) / 100 / order_Price?.price,
            decimalBase
          );
          // setTradeAmount(cal_balance);
          _spotBuy((prevState) => {
            let jasper = Object.assign({}, prevState);
            jasper.quantity = cal_balance;
            jasper.totalAmount = utill.beautifyNumber(
              cal_balance * order_Price?.price,
              decimalQuote
            );
            return jasper;
          });
        } else {
          _activePerBuy(0);
        }
      } else {
        if (spotBuy?.price) {
          let cal_balance = utill.beautifyNumber(
            (quoteBalance * percentage) / 100 / spotBuy?.price,
            decimalBase
          );
          // setTradeAmount(cal_balance);
          _spotBuy((prevState) => {
            let jasper = Object.assign({}, prevState);
            jasper.quantity = cal_balance;
            jasper.totalAmount = utill.beautifyNumber(
              cal_balance * spotBuy?.price,
              decimalQuote
            );
            return jasper;
          });
        } else {
          _activePerBuy(0);
        }
      }
    } else {
      _activePerSell(percentage);
      if (orderType === "Market") {
        if (order_Price?.price) {
          let cal_balance = utill.beautifyNumber(
            (baseBalance * percentage) / 100,
            decimalBase
          );
          // setTradeAmount(cal_balance);
          _spotSell((prevState) => {
            let jasper = Object.assign({}, prevState);
            jasper.quantity = cal_balance;
            jasper.totalAmount = utill.beautifyNumber(
              cal_balance * order_Price?.price,
              decimalQuote
            );
            return jasper;
          });
        } else {
          _activePerBuy(0);
        }
      } else {
        if (spotSell?.price) {
          let cal_balance = utill.beautifyNumber(
            (baseBalance * percentage) / 100,
            decimalBase
          );
          // setTradeAmount(cal_balance);
          _spotSell((prevState) => {
            let jasper = Object.assign({}, prevState);
            jasper.quantity = cal_balance;
            jasper.totalAmount = utill.beautifyNumber(
              cal_balance * spotSell?.price,
              decimalQuote
            );
            return jasper;
          });
        } else {
          _activePerSell(0);
        }
      }
    }
  };

  useEffect(() => {
    async function fetchBalance() {
      if (auth) {
        const baseResponse = await getUserbalanceAction(
          pair?.base.toLowerCase(),
          dispatch
        );
        // console.log(baseResponse, "baseResponse?.data");
        updateBaseBalance(baseResponse?.data?.available_balance);
        const quoteResponse = await getUserbalanceAction(
          pair?.quote.toLowerCase(),
          dispatch
        );
        updateQuoteBalance(quoteResponse?.data?.balance);
      }
    }
    fetchBalance();
  }, [pair, auth, dispatch, updateBaseBalance, updateQuoteBalance]);

  const [decimalBase, setDecimalBase] = useState(8);
  const [decimalQuote, setDecimalQuote] = useState(8);
  useEffect(() => {
    setDecimalBase(pair?.base_dc > 8 ? 8 : pair?.base_dc);
    setDecimalQuote(pair?.quote_dc > 8 ? 8 : pair?.quote_dc);
  }, [setDecimalBase, setDecimalQuote, pair]);

  let userData = useSelector((state) => state.user.userData);

  const onChangeSpotPrice = (e, tradeType, quantity) => {
    let value = isNumberKey(e.target.value);
    if (value !== false) {
      // setTradePrice(value);
      if (tradeType === "SELL") {
        _activePerSell(0);
        _spotSell((prevState) => {
          let jasper = Object.assign({}, prevState);
          jasper.price = value;
          jasper.totalAmount = utill.beautifyNumber(
            value * quantity,
            decimalBase
          );
          return jasper;
        });
      } else if (tradeType === "BUY") {
        _activePerBuy(0);
        _spotBuy((prevState) => {
          let jasper = Object.assign({}, prevState);
          jasper.price = value;
          jasper.totalAmount = utill.beautifyNumber(
            value * quantity,
            decimalBase
          );
          return jasper;
        });
      }
    }
  };

  const onChangeSpotQuantity = (e, tradeType, price) => {
    let value = isNumberKey(e.target.value);
    if (value !== false) {
      // value = value.toString();
      // setTradeAmount(value);
      if (tradeType === "SELL") {
        _activePerSell(0);
        _spotSell((prevState) => {
          let jasper = Object.assign({}, prevState);
          jasper.quantity = value;
          jasper.totalAmount = utill?.beautifyNumber(
            value * price,
            decimalQuote
          );
          // jasper.totalAmount = (value * price).toFixed(decimalQuote);
          return jasper;
        });
      } else if (tradeType === "BUY") {
        _activePerBuy(0);
        _spotBuy((prevState) => {
          let jasper = Object.assign({}, prevState);
          jasper.quantity = value;
          jasper.totalAmount = utill?.beautifyNumber(
            value * price,
            decimalQuote
          );
          // jasper.totalAmount = (value * price).toFixed(decimalQuote);
          return jasper;
        });
      }
    }
  };

  const onChangeSpotLimit = (e, tradeType) => {
    let value = isNumberKey(e.target.value);
    if (value !== false) {
      if (tradeType === "SELL") {
        _activePerSell(0);
        _spotSell((prevState) => {
          let jasper = Object.assign({}, prevState);
          jasper.limit = value;
          return jasper;
        });
      } else if (tradeType === "BUY") {
        _activePerBuy(0);
        _spotBuy((prevState) => {
          let jasper = Object.assign({}, prevState);
          jasper.limit = value;
          return jasper;
        });
      }
    }
  };

  const onChangeTotalAmt = (e, tradeType, price) => {
    let value = isNumberKey(e.target.value);

    if (value !== false) {
      let quantity = !price || parseFloat(price) <= 0 ? "" : value / price;
      // utill.beautifyNumber(value / price, decimalBase);

      // setTradeAmount(quantity);
      if (tradeType === "SELL") {
        _activePerSell(0);
        _spotSell((prevState) => {
          let jasper = Object.assign({}, prevState);
          jasper.quantity = quantity;
          jasper.totalAmount = value;
          return jasper;
        });
      } else if (tradeType === "BUY") {
        _activePerBuy(0);
        _spotBuy((prevState) => {
          let jasper = Object.assign({}, prevState);
          jasper.quantity = quantity;
          jasper.totalAmount = value;
          return jasper;
        });
      }
    }
  };

  function handleTabChange(e) {
    // setTradePrice(0);
    // setTradeAmount(0);
    _activePerBuy(0);
    _activePerSell(0);
    setOrdertype((prevState) => {
      prevState = e.target.innerText;
      return prevState;
    });
    _spotBuy((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.price = utill.valueFix(order_Price.price);
      jasper.quantity = "";
      jasper.totalAmount = "";
      return jasper;
    });
    _spotSell((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.price = utill.valueFix(order_Price.price);
      jasper.quantity = "";
      jasper.totalAmount = "";
      return jasper;
    });
  }

  const [activeTab, setActiveTab] = useState("buy"); // You'll need to manage the active tab state
  const handleButtonTabChange = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      <div className="market-trade pt-2">
        <ConfirmationModalView
          show={confirmationModal}
          spotConformationData={spotConformationData}
          onHide={() => showConfirmationModal(false)}
          trade_type={tradeType}
          order_type={orderType}
          pair={pair}
          spot_type={
            tradeType === "SELL" ? spotSell : tradeType === "BUY" ? spotBuy : ""
          }
          place_order={
            tradeType === "SELL"
              ? placeOrderSell
              : tradeType === "BUY"
              ? placeOrderBuy
              : ""
          }
        />
        <Tabs
          defaultActiveKey="limit"
          onClick={(e) => {
            handleTabChange(e);
          }}
        >
          {/* All tab */}
          {[
            { name: "Limit", value: "limit" },
            { name: "Market", value: "market" },
            { name: "Stop Limit", value: "stop-limit" },
          ].map((val, key) => {
            return (
              <Tab eventKey={val?.value} title={val?.name} key={val?.value}>
                <div className="d-flex justify-content-between">
                  {tabTitle == "none" ? (
                    <>
                      <div className="buy_sell">
                        <Tabs
                          activeKey={activeTab}
                          onSelect={handleButtonTabChange}
                          id="fill-tab-example"
                          className={`mb-4 ${val?.name}`}
                          fill
                        >
                          <Tab eventKey="buy" title="BUY">
                            <TradeView
                              spotConformationData={spotConformationData}
                              load={load}
                              returnToUrl={returnToUrl}
                              tab={val?.value}
                              disAbleButton={disAbleButton}
                              setDisableButton={setDisableButton}
                              order_Price={order_Price}
                              tradeType="BUY"
                              setLoading={setLoading}
                              loading={loading}
                              setTradeType={setTradeType}
                              pair={pair}
                              disableType={disableBuy}
                              spotType={spotBuy}
                              changeSpotLimit={onChangeSpotLimit}
                              changeSpotPrice={onChangeSpotPrice}
                              changeSpotQuantity={onChangeSpotQuantity}
                              changeTotalAmt={onChangeTotalAmt}
                              showConfirmModal={showConfirmationModal}
                              userData={userData}
                              coinBalance={utill.beautifyNumber(
                                quoteBalance,
                                decimalQuote
                              )}
                              activePerBuy={activePerBuy}
                              calculateBalance={calculateBalanceQuote}
                              decimalBase={decimalBase}
                              decimalQuote={decimalQuote}
                            />
                          </Tab>
                          <Tab eventKey="sell" title="SELL">
                            <TradeView
                              spotConformationData={spotConformationData}
                              load={load}
                              returnToUrl={returnToUrl}
                              tab={val?.value}
                              disAbleButton={disAbleButton}
                              setDisableButton={setDisableButton}
                              order_Price={order_Price}
                              tradeType="SELL"
                              setTradeType={setTradeType}
                              pair={pair}
                              disableType={disableSell}
                              spotType={spotSell}
                              changeSpotLimit={onChangeSpotLimit}
                              changeSpotPrice={onChangeSpotPrice}
                              changeTotalAmt={onChangeTotalAmt}
                              changeSpotQuantity={onChangeSpotQuantity}
                              showConfirmModal={showConfirmationModal}
                              userData={userData}
                              coinBalance={utill.beautifyNumber(
                                baseBalance,
                                decimalBase
                              )}
                              activePerBuy={activePerSell}
                              calculateBalance={calculateBalanceQuote}
                              decimalBase={decimalBase}
                              decimalQuote={decimalQuote}
                            />
                          </Tab>
                        </Tabs>
                      </div>
                    </>
                  ) : (
                    <>
                      <TradeView
                        spotConformationData={spotConformationData}
                        returnToUrl={returnToUrl}
                        tab={val?.value}
                        disAbleButton={disAbleButton}
                        setDisableButton={setDisableButton}
                        order_Price={order_Price}
                        tradeType="BUY"
                        setLoading={setLoading}
                        loading={loading}
                        setTradeType={setTradeType}
                        pair={pair}
                        disableType={disableBuy}
                        spotType={spotBuy}
                        changeSpotLimit={onChangeSpotLimit}
                        changeSpotPrice={onChangeSpotPrice}
                        changeSpotQuantity={onChangeSpotQuantity}
                        changeTotalAmt={onChangeTotalAmt}
                        showConfirmModal={showConfirmationModal}
                        userData={userData}
                        coinBalance={utill.beautifyNumber(
                          quoteBalance,
                          decimalQuote
                        )}
                        activePerBuy={activePerBuy}
                        calculateBalance={calculateBalanceQuote}
                        decimalBase={decimalBase}
                        decimalQuote={decimalQuote}
                        confirm_PlaceOrder_Action={confirm_PlaceOrder_Action}
                        load={load}
                      />
                      <TradeView
                        spotConformationData={spotConformationData}
                        load={load}
                        confirm_PlaceOrder_Action={confirm_PlaceOrder_Action}
                        returnToUrl={returnToUrl}
                        tab={val?.value}
                        disAbleButton={disAbleButton}
                        setDisableButton={setDisableButton}
                        order_Price={order_Price}
                        tradeType="SELL"
                        setTradeType={setTradeType}
                        pair={pair}
                        disableType={disableSell}
                        spotType={spotSell}
                        changeSpotLimit={onChangeSpotLimit}
                        changeSpotPrice={onChangeSpotPrice}
                        changeTotalAmt={onChangeTotalAmt}
                        changeSpotQuantity={onChangeSpotQuantity}
                        showConfirmModal={showConfirmationModal}
                        userData={userData}
                        coinBalance={utill.beautifyNumber(
                          baseBalance,
                          decimalBase
                        )}
                        activePerBuy={activePerSell}
                        calculateBalance={calculateBalanceQuote}
                        decimalBase={decimalBase}
                        decimalQuote={decimalQuote}
                      />
                    </>
                  )}
                </div>
              </Tab>
            );
          })}
        </Tabs>
      </div>
    </>
  );
}

function TradeView({
  tab,
  returnToUrl,
  order_Price,
  tradeType,
  setTradeType,
  pair,
  disableType,
  spotType,
  changeSpotLimit,
  changeSpotPrice,
  changeSpotQuantity,
  changeTotalAmt,
  showConfirmModal,
  userData,
  coinBalance,
  activePerBuy,
  calculateBalance,
  decimalBase,
  disAbleButton,
  setDisableButton,
  decimalQuote,
  loading,
  confirm_PlaceOrder_Action,
  load,
  spotConformationData,
}) {
  const target = React.useRef(null);

  let price =
    tab === "market"
      ? utill.valueFix(order_Price?.price ? order_Price?.price : 0)
      : spotType?.price;
  let quantity =
    tradeType === "BUY"
      ? utill?.beautifyNumber(
          parseFloat(price) * parseFloat(spotType?.quantity),
          decimalBase
        )
      : utill?.beautifyNumber(parseFloat(spotType?.quantity), decimalBase);
  const { PRICEPER } = _constant;
  let perClassName =
    tradeType === "BUY" ? "active_percentage_buy" : "active_percentage_sell";

  return (
    <div
      className={tradeType === "BUY" ? "market-trade-buy" : "market-trade-sell"}
    >
      <p>
        Available:
        <span>
          {/* {console.log("coinBalance>>>>>>", coinBalance)} */}
          {userData?.access_token
            ? `${coinBalance} ${tradeType === "BUY" ? pair?.quote : pair?.base}`
            : "Login First"}
        </span>
      </p>
      {tab === "stop-limit" ? (
        <>
          <div
            className={`input-group market_trade_input_block 
                    ${
                      spotType?.limit != 0 || spotType?.limit == ""
                        ? ""
                        : "active"
                    }`}
          >
            <div className="input-group-prepend">
              <span className="input-group-text text-secondary">Stop</span>
            </div>
            <input
              type="text"
              className="form-control  text-right"
              placeholder="0.00"
              required
              value={spotType?.limit === 0 ? "" : spotType?.limit}
              onChange={(e) => {
                changeSpotLimit(e, tradeType);
              }}
            />
            <div className="input-group-append">
              <span className="input-group-text">{pair?.quote}</span>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      <div
        className={`input-group market_trade_input_block       
        ${price != 0 || price == "" ? "" : "active"}`}
      >
        <div className="input-group-prepend">
          <span className="input-group-text text-secondary">
            {tab === "stop-limit" ? "Limit" : "Price"}
          </span>
        </div>
        <input
          type="text"
          className="form-control  text-right"
          // placeholder={tab === "stop-limit" ? "Limit" : "Price"}
          placeholder={tab === "market" ? "" : "0.00"}
          disabled={tab === "market" ? true : false}
          required
          value={tab === "market" || price === 0 ? "" : price}
          onChange={(e) => {
            changeSpotPrice(e, tradeType, spotType?.quantity);
          }}
        />
        <div className="input-group-append">
          <span className="input-group-text">
            {tab === "market" ? "Market - " + pair?.quote : pair?.quote}
          </span>
        </div>
      </div>
      <div
        className={`input-group market_trade_input_block
                      ${
                        (spotType?.quantity != "" && spotType?.quantity <= 0) ||
                        parseFloat(quantity) > parseFloat(coinBalance)
                          ? "active"
                          : ""
                      }`}
      >
        <div className="input-group-prepend">
          <span className="input-group-text text-secondary">Amount</span>
        </div>
        <input
          type="text"
          className="form-control  text-right"
          // placeholder="Amount"
          placeholder="0.00"
          required
          value={
            spotType?.quantity >= 10000000
              ? utill.beautifyNumber(spotType?.quantity, decimalBase)
              : spotType?.quantity?.toString().substring(0, decimalBase)
          }
          // value={
          //   spotType?.quantity >= 10000000
          //     ? utill.beautifyNumber(spotType?.quantity, decimalBase)
          //     : spotType?.quantity
          // }
          // value={spotType?.quantity === 0 ? "" : spotType?.quantity}
          onChange={(e) => {
            changeSpotQuantity(e, tradeType, price);
            setDisableButton(false);
          }}
          ref={target}
        />

        <div className="input-group-append">
          <span className="input-group-text">{pair?.base}</span>
        </div>
        <Overlay
          target={target.current}
          show={
            spotType?.quantity != "" &&
            parseFloat(quantity) > parseFloat(coinBalance)
              ? true
              : false
          }
          placement="top"
        >
          {(props) => (
            <Tooltip id="overlay-example" {...props}>
              Insufficient Balance
            </Tooltip>
          )}
        </Overlay>
      </div>
      <ul className="market-trade-list d-flex justify-content-between">
        {Object.entries(PRICEPER).map(([key, value]) => (
          <li
            className="d-flex  align-items-center"
            key={`${key}_${tradeType}`}
          >
            <a
              className={activePerBuy === value ? perClassName : ""}
              onClick={(e) => {
                calculateBalance(value, tradeType);
              }}
            ></a>
            <span
              className={activePerBuy === value ? "active_percentage_text" : ""}
            >
              {value}%
            </span>
          </li>
        ))}
      </ul>

      {userData?.access_token ? (
        <>
          <div className={`input-group market_trade_input_block`}>
            <div className="input-group-prepend">
              <span className="input-group-text text-secondary">
                Total Amount
              </span>
            </div>
            <input
              type="text"
              className="form-control text-right"
              // placeholder="Total Amount"
              placeholder="0.00"
              onChange={(e) => {
                changeTotalAmt(e, tradeType, price);
                setDisableButton(false);
              }}
              // disabled={!price || parseFloat(price) <= 0 ? true : false}
              value={spotType?.totalAmount === 0 ? "" : spotType?.totalAmount}
            />

            <div className="input-group-append">
              <span className="input-group-text">{pair?.quote}</span>
            </div>
          </div>
          <button
            disabled={
              (disableType &&
                (!price ||
                  disAbleButton ||
                  !spotType?.quantity ||
                  spotType?.quantity <= 0 ||
                  price <= 0)) ||
              parseFloat(quantity) > parseFloat(coinBalance) ||
              load
                ? true
                : false
            }
            onClick={() => {
              // placeOrder("SELL");
              confirm_PlaceOrder_Action(tradeType);
              setTradeType(tradeType);
              // showConfirmModal(true);
              // setLoad(true);
              // setTimeout(() => {
              //   setLoad(false);
              // }, 4000);
            }}
            className={tradeType === "BUY" ? "btn buy" : "btn sell"}
          >
            {`${tradeType} ${pair?.base}`}
          </button>
        </>
      ) : (
        <div className="login_first_block">
          <LoginFirst return_to={btoa(`${returnToUrl}`)} />
        </div>
      )}
    </div>
  );
}
function ConfirmationModalView(props) {
  const {
    pair,
    spot_type,
    trade_type,
    place_order,
    order_type,
    spotConformationData,
  } = props;
  // const [isHovering, setIsHovering] = useState(false);
  // const handleMouseEnter = () => {
  //   setIsHovering(true);
  // };

  // const handleMouseLeave = () => {
  //   setIsHovering(false);
  // };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation
      scrollable={true}
      backdrop="static"
      className="withdrawal_confirmation_modal"
      contentClassName="content_Size_disable"
    >
      <Modal.Body className="text-center">
        <div className="d-flex justify-content-between">
          <h4> Order Confirmation</h4>
        </div>
        <div className="transaction_details_Successful text-center">
          <div className="d-flex justify-content-between align-items-cente ">
            <div className="d-flex align-items-center">
              <h6> {pair?.symbol}</h6>
            </div>
            <div className="d-flex justify-content-center">
              <h6>
                {order_type}
                {" - "}
                <span className={trade_type === "SELL" ? "red" : "green"}>
                  {trade_type}
                </span>
              </h6>
            </div>
          </div>
          <ul className="markit_Trade_list">
            {order_type === "Stop Limit" ? (
              <>
                {" "}
                <li>
                  <div className="d-flex justify-content-between align-items-center text-left">
                    <div className="d-flex align-items-center text-left">
                      <p>Stop</p>
                    </div>
                    <div className="justify-content-center text-right">
                      <p>
                        {spot_type?.limit} {pair?.quote}
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center text-left">
                      <p>Limit</p>
                    </div>
                    <div className="justify-content-center text-right">
                      <p>
                        {spot_type?.price} {pair?.quote}
                      </p>
                    </div>
                  </div>
                </li>
              </>
            ) : (
              <li>
                <div className="d-flex justify-content-between align-items-center text-left">
                  <div className="d-flex align-items-left text-left">
                    <p>Price</p>
                  </div>
                  <div className="justify-content-center text-right">
                    <p>
                      {/* {spot_type?.price} {pair?.quote} */}
                      {spotConformationData?.price} {pair?.quote}
                    </p>
                  </div>
                </div>
              </li>
            )}
            <li>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center text-left">
                  <p>Amount</p>
                </div>
                <div className="justify-content-center text-right">
                  <p>
                    {/* {spot_type?.quantity} {pair?.base} */}
                    {spotConformationData?.quantity} {pair?.base}
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center text-left">
                  <p>Order Value</p>
                </div>
                <div className="justify-content-center text-right">
                  <p>
                    {/* {spot_type?.totalAmount} {pair?.quote} */}
                    {spotConformationData?.order_value} {pair?.quote}
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center text-left">
                  <p>Trading Fee ($)</p>
                </div>
                <div className="justify-content-center text-right">
                  <p>
                    {/* {spot_type?.totalAmount} {pair?.quote} */}${" "}
                    {spotConformationData?.fee_currency} <br />
                    {/* {spotConformationData?.is_vync_fee ? (spotConformationData?.fee + " VYNC_M"):""} */}
                  </p>
                </div>
              </div>
            </li>
            {spotConformationData?.is_vync_fee && (
              <li>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center text-left">
                    <p>Trading Fee(VYNC_M)</p>
                  </div>
                  <div className="justify-content-center text-right">
                    <p>
                      {/* {spot_type?.totalAmount} {pair?.quote} */}

                      {/* {spotConformationData?.fee_currency} {pair?.quote} <br/> */}
                      {spotConformationData?.fee + " VYNC_M"}
                    </p>
                  </div>
                </div>
              </li>
            )}
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          variant="secondary"
          onClick={props.onHide}
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={(e) => {
            props.onHide();
            place_order(trade_type);
          }}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>

    // <Modal
    //   {...props}
    //   size="sm"
    //   aria-labelledby="contained-modal-title-vcenter"
    //   centered
    //   animation
    //   scrollable={true}
    //   backdrop="static"
    // >
    //   <Modal.Header className="d-flex justify-content-center">
    //     <Modal.Title id="contained-modal-title-vcenter">
    //       <div className="d-flex justify-content-center align-items-center">
    //         Order Confirmation
    //       </div>
    //     </Modal.Title>
    //   </Modal.Header>
    //   <Modal.Body className="pl-4 pr-4">
    //     <div className="d-flex justify-content-between align-items-center">
    //       <div className="d-flex align-items-center">
    //         <h6> {pair?.symbol}</h6>
    //       </div>
    //       <div className="d-flex justify-content-center">
    //         <h6>
    //           {order_type}
    //           {" - "}
    //           <span className={trade_type === "SELL" ? "red" : "green"}>
    //             {trade_type}
    //           </span>
    //         </h6>
    //       </div>
    //     </div>
    //     <ul className="markit_Trade_list">
    //       {order_type === "Stop Limit" ? (
    //         <>
    //           {" "}
    //           <li>
    //             <div className="d-flex justify-content-between align-items-center">
    //               <div className="d-flex align-items-center">
    //                 <p>Stop</p>
    //               </div>
    //               <div className="justify-content-center">
    //                 <p>
    //                   {spot_type?.limit} {pair?.quote}
    //                 </p>
    //               </div>
    //             </div>
    //           </li>
    //           <li>
    //             <div className="d-flex justify-content-between align-items-center">
    //               <div className="d-flex align-items-center">
    //                 <p>Limit</p>
    //               </div>
    //               <div className="justify-content-center">
    //                 <p>
    //                   {spot_type?.price} {pair?.quote}
    //                 </p>
    //               </div>
    //             </div>
    //           </li>
    //         </>
    //       ) : (
    //         <li>
    //           <div className="d-flex justify-content-between align-items-center">
    //             <div className="d-flex align-items-center">
    //               <p >Price</p>
    //             </div>
    //             <div className="justify-content-center">
    //               <p>
    //                 {spot_type?.price} {pair?.quote}
    //               </p>
    //             </div>
    //           </div>
    //         </li>
    //       )}

    //       <li>
    //         <div className="d-flex justify-content-between align-items-center">
    //           <div className="d-flex align-items-center">
    //             <p>Amount</p>
    //           </div>
    //           <div className="justify-content-center">
    //             <p>
    //               {spot_type?.quantity} {pair?.base}
    //             </p>
    //           </div>
    //         </div>
    //       </li>
    //       <li>
    //         <div className="d-flex justify-content-between align-items-center">
    //           <div className="d-flex align-items-center">
    //             <p>Total</p>
    //           </div>
    //           <div className="justify-content-center">
    //             <p>
    //               {spot_type?.totalAmount} {pair?.quote}
    //             </p>
    //           </div>
    //         </div>
    //       </li>
    //     </ul>
    //   </Modal.Body>
    //   <Modal.Footer className="d-flex justify-content-around align-items-center">
    //     <div className="d-flex justify-content-around align-items-center">

    //       <Button
    //         className="btn order_cancel_btnn  mr-2"
    //         variant="secondary"
    //         onClick={props.onHide}
    //         onMouseEnter={handleMouseEnter}
    //         onMouseLeave={handleMouseLeave}
    //       >
    //         Cancel
    //       </Button>
    //       <Button
    //         className="btn order_cancel_btnn"
    //         style={{ background: "#007bff", color: "white" }}
    //         onClick={(e) => {
    //           props.onHide();
    //           place_order(trade_type);
    //         }}
    //       >
    //         Confirm
    //       </Button>
    //     </div>
    //   </Modal.Footer>
    // </Modal>
  );
}
