import * as userServices from "../../services/API/api.service";
import * as userSlice from "../reducers/user";
import { toast } from "../../components/Toast/Toast";
import BASE_URL from "../../_constant/index";
import _constant from "../../_constant";
import { storeInstance } from "../../index";
const CONSTANT = _constant.TOKEN;

export const getHeaders = () => {
  let userToken = storeInstance.getState().user?.userData?.access_token;
  return {
    accesstoken: userToken,
    // "e71bccada8d4703538b91e627a48f1d3fbe50bde2cab47591d8845f1ab5d1bec1666871656",
    Token: CONSTANT.LOGINTOKEN,
  };
};
export const otpVerifyRegisterAction =
  (userData, history) => async (dispatch) => {
    try {
      const response = await userServices.otpVerifyRegister(userData);
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
        await dispatch(userSlice.userLogin(response?.data?.data));
        history.push(BASE_URL.BASE_URL + "verify-number");
      } else {
        toast.error(response?.data?.message);
      }
    } catch (err) {
      console.log("ERROR FROM RegisterAction", err);
    }
  };

export const resendOTPRegisterAction = (data) => async () => {
  try {
    const response = await userServices.otpSend(data);
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
    } else {
      toast.error(response?.data?.message);
    }
  } catch (err) {
    console.log("ERROR FROM resendOTPRegisterAction", err);
  }
};


export const resetPasswordConformMail = (data, history) => async (dispatch) => {
  try {

    const response = await userServices.resetpassword(data);
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);

      history.push(BASE_URL.BASE_URL + "conform-email-otp");
    } else {
      toast.error(response?.data?.message);
    }
  } catch (err) {
    console.log("ERROR FROM RegisterAction", err);
  }
};



export const conformForgetPassword = (data, history) => async (dispatch) => {
  try {
    const response = await userServices.verifyForgetPassword(data);

    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
      history.push(BASE_URL.BASE_URL + "conform-password");
    } else {
      toast.error(response?.data?.message);
    }
  } catch (err) {
    console.log("ERROR FROM RegisterAction", err);
  }
};
export const change_forgotPassword = (data, history) => async (dispatch) => {
  try {
    const response = await userServices.change_forgot_password(data);
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
      history.push(BASE_URL.BASE_URL + "login");
    } else {
      toast.error(response?.data?.message);
    }
  } catch (err) {
    console.log("ERROR FROM RegisterAction", err);
  }
};

export const updatePhoneNumber = async (data) => {
  try {
    const response = await userServices.phoneUpdate(data, getHeaders());
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
      return response?.data;
    } else {
      toast.error(response?.data?.message);
      return false;
    }
  } catch (err) {
    console.log("ERROR FROM updatePhoneNumber", err);
  }
};

export const update_user_details = async (data) => {
  try {
    const response = await userServices.updateUserDetails(data, getHeaders());
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
      return response?.data;
    } else {
      toast.error(response?.data?.message);
      return false;
    }
  } catch (err) {
    console.log("ERROR FROM Update User Details", err);
  }
};

export const mobileOtpVerifyAction = (data, history) => async (dispatch) => {
  try {
    const response = await userServices.phoneOtpVerify(data, getHeaders());
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
      let userData = storeInstance.getState().user?.userData;
      let newData = Object.assign({}, userData);
      newData["isphoneupdated"] = "1";
      await dispatch(userSlice.userLogin([]));
      await dispatch(userSlice.userLogin(newData));
      history.push(BASE_URL.BASE_URL + "profile/dashboard");
    } else {
      toast.error(response?.data?.message);
    }
  } catch (err) {
    console.log("ERROR FROM mobileOtpVerifyAction", err);
  }
};
export const UpdateUserInfoVerifyAction = (data, history) => async (dispatch) => {
  try {
    const response = await userServices.updateUserOtpVerify(data, getHeaders());
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
      let userData = storeInstance.getState().user?.userData;
      let newData = Object.assign({}, userData);
      newData["isphoneupdated"] = "1";
      await dispatch(userSlice.userLogin([]));
      await dispatch(userSlice.userLogin(newData));
      history.push(BASE_URL.BASE_URL + "profile/dashboard");
    } else {
      toast.error(response?.data?.message);
    }
  } catch (err) {
    console.log("ERROR FROM mobileOtpVerifyAction", err);
  }
};
