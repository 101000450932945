import _constant from '../../_constant';
const Constant = _constant.TOKEN
// Make requests to CryptoCompare API
// export async function makeApiRequest(path) {
//   try {
//     const response = await fetch(`https://min-api.cryptocompare.com/${path}`);
//     // console.log(await response.json(),"crypto compare data -- ")
//     return response.json();
//   } catch (error) {
//     throw new Error(`CryptoCompare request error: ${error.status}`);
//   }
// }

// vynksafe api
export async function getKlineBars(pair, resolution) {
  try {
    const response = await fetch(`${_constant.ApiUrl}/exchange/chart/kline?pair=${pair}&interval=${resolution}`, {
      method: 'GET',
      headers: new Headers({
        'Token': `${Constant.TOKEN}`,
        'Content-Type': 'application/json'
      })
    });
    // const response = await fetch(`http://io.pixelsoftwares.com:5670/exchange/chart/kline?pair=${pair}&interval=${interval}`);
    // const response = await fetch(`http://io.pixelsoftwares.com:5670/exchange/chart/kline?pair=${pair}&interval=${interval}&${ts}`);
    // console.log(await response.json(),"crypto compare data -- ")
    return response.json();

    // if (data.Response && data.Response === 'Error') {
    //   console.log(' API error:', data.Message)
    //   return []
    // }
    // console.log(data.Data)
    // console.log(data)
    // if (data?.Data?.length) {

    // let bars = [];

    // data.Data.forEach((bar) => {
    //   if (bar.time >= from && bar.time < to) {
    //     bars = [
    //       ...bars,
    //       {
    //         time: bar.time * 1000,
    //         low: bar.low,
    //         high: bar.high,
    //         open: bar.open,
    //         close: bar.close,
    //       },
    //     ];
    //   }
    // });

    //   var bars = data.Data.map(el => {
    //     return {
    //       time: el.time * 1000, //TradingView requires bar time in ms
    //       low: el.low,
    //       high: el.high,
    //       open: el.open,
    //       close: el.close,
    //       // volume: el.volume
    //     }
    //   })
    //   return bars
    // } else {
    //   return []
    // }

  } catch (error) {
    throw new Error(`Bar API request error: ${error}`);
  }
}
// symbol list data
export async function getSymbol(symbol) {
  try {
    const response = await fetch(`${_constant.ApiUrl}/exchange/chart/symbol?symbol=${symbol}`);
    // console.log(await response.json(),"crypto compare data -- ")
    return response.json();
  } catch (error) {
    throw new Error(`Symbol API request error: ${error}`);
  }
}
export function generateSymbol(exchange, fromSymbol, toSymbol) {
  const short = `${fromSymbol}/${toSymbol}`;
  return {
    short,
    full: `${exchange}:${short}`,
  };
}

export function parseFullSymbol(fullSymbol) {
  const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
  if (!match) {
    return null;
  }

  return {
    exchange: match[1],
    fromSymbol: match[2],
    toSymbol: match[3],
  };
}
